import React from 'react';
import { Dimensions } from 'react-native';
// import VersionCheck from 'react-native-version-check';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import MyText from '@atoms/MyText';
import {
  removeNonNumeric,
  sortedPointsBp,
  getDynamicAcountListByModule,
  checkIfHasSdAccount,
  syncWithDynamicAcounts,
  findAccountByName,
  findDynamicAccount,
  getSdTransactionReasons,
  getWorkAllowanceReasons,
  sortTransactionDetailsByPriority,
  formatBillPayment,
  returnBpCategoriesByAccount,
  removePointsDisabledBp,
  sliderInputChange,
  getSliderInterval,
  sliderMaximumValue,
  sliderMinValue,
  getChangedField,
  textMinimumValue,
  textMaximumValue,
  formatPhoneNumber,
  sortVouchersByPurchase,
  formatBillPaymentCategories,
  returnInitialSortedStories,
  checkIfAllStoriesViewed,
  refreshStories,
  checkIfLastSubStory,
  checkIfViewingStoryValid,
  setVoucherText,
  numberWithDots,
  numberAmount,
  convertDotsToNum,
  setBillPaymentReason,
  validate,
  flattenObject,
  returnPayslip,
  timeHelper,
  getAttendanceText,
  getIndoDay,
  getIndoMonth,
  getIndoMonthLong,
  dateFormer,
  listDateFormer,
  getAttendanceColor,
  getAttendanceIcon,
  getMulaiSelesai,
  getAttendanceStatus,
  camelToSnake,
  spaceToSnake,
  setRange,
  GET_SELF_ATTENDANCE_CONDITIONS,
  textModalAttendance,
  textStatusAttendance,
  renderButtonAttendance,
  renderTextButton,
  renderIconButton,
  renderButtonHourly,
  renderButton,
  renderButtonText,
  renderButtonIcon,
  renderButtonColor,
  renderSkipBreak,
  renderAfterSubmit,
  renderAfterSubmitText,
  renderConfirmText,
  checkDistanceBetweenCoordinates,
  getStatusAndColor,
  roundOff,
  getCurrentTimeStamp,
  calculatePercentage,
  setImage,
  utmToData,
  isFeatureEnabled,
  getReferralEncryption,
  refferalDencryption,
  validateIfScreenExists,
  getAvailableBalance,
  getAccruedSalary,
  accruedSalaryTransactionHistory,
  getExpirDateText,
  checkExpiryDateTime,
  getExpiredTime,
  subtractFromWorkAllowance,
  formatUnderScoreLowerCase,
  calculateSubTotal,
  masker,
  mapDataSameAsEWA,
  returnSelectedItem,
  getListOfDynamicAccounts,
  accountByRuleProductScreen,
  accountByRuleVoucherScreen,
  setPointsValues,
  getOffSetForGraphql,
  getCurrentActiveMission,
  checkForLoan,
  needForRefetchAppStatus,
  getUserBanksFromQuery,
  createBankAccountCompleteCallBack,
  saveButtonCallBack,
  setSelectedBankCallBack,
  onPressSubmitCallBack,
  onClickSecondaryBottomSheet,
  onCompleteDeleteBank,
  resetNavigationStack,
  getUpdatedCategories,
  checkToNavigateDetails,
  onShowModalHandler,
  onUpdateHandler,
  validateNominal,
  excludeReasonsDisplay,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import {
  ACCOUNT_LIST,
  CATEGORY,
  DYNAMIC_ACCOUNT_NAMES,
  INFO_SHEET_TYPES,
  LOGIN_INFO_SHEET_KEYS,
  TRANSACTION_LIST_DYNAMIC_ACCOUNTS,
} from './Constants';

import { SentryHelper } from './SentryHelper';

export const getImageNameFromUri = (uriString = '') => {
  try {
    let imageName = uriString.split('/');
    imageName = imageName[imageName.length - 1].split('.');
    if (imageName.length > 1) {
      return `Bukti.${imageName[1]}`;
    }
    return 'Bukti';
  } catch (error) {
    SentryHelper.captureException(error);
  }
};

export const CustomSvgMaskPath = (
  { position, canvasSize, size, isFullWidth = true, widthStartAdj = 10, widthEndAdj = 0 },
  step,
) => {
  const br = 0; // border radius
  const sizeX = isFullWidth ? Dimensions.get('window').width : size.x._value + widthEndAdj;
  const startPos = isFullWidth ? 0 : position.x._value - widthStartAdj;
  const sizeY = size.y._value + 7;
  const POS_Y = position.y._value;
  return `M 0 0 H ${Dimensions.get('window').width} V ${
    canvasSize.y
  } H 0 V 0 Z M ${startPos} ${POS_Y} Z h ${sizeX} a ${br} ${br} 0 0 1 ${br} ${br} v ${sizeY} a ${br} ${br} 0 0 1 -${br} ${br} h -${sizeX} a ${br} ${br} 0 0 1 -${br} -${br} v -${sizeY} a ${br} ${br} 0 0 1 ${br} -${br} z`;
};

export const getOffsetByWindow = (offset) => {
  let window = Dimensions.get('window').height;
  if (window < 740 || window >= 780) return offset - 15;
  return offset;
};

export const getAppUpdateStatus = (list) => {
  // let mandatory = 0;
  // let nonMandatory = 0;
  // const currentSemverSplit = VersionCheck.getCurrentVersion().split('.');
  // list.forEach((e) => {
  //   const splitSemver = e.version_number.split('.');
  //   for (let index = 0; index < splitSemver.length; index++) {
  //     let a = parseInt(splitSemver[index]);
  //     let b = null;
  //     if (currentSemverSplit[index] && !isNaN(currentSemverSplit[index])) {
  //       b = parseInt(currentSemverSplit[index]);
  //     }
  //     if (a > b) {
  //       if (e.force_update) {
  //         mandatory++;
  //       } else if (e.recommended_update) {
  //         nonMandatory++;
  //       }
  //     } else {
  //       if (a < b && (index === 0 || index === 1)) {
  //         break;
  //       }
  //     }
  //   }
  // });
  // if (mandatory > 0) {
  //   return UPDATE_TYPE.MANDATORY;
  // } else if (nonMandatory > 0) {
  //   return UPDATE_TYPE.OPTIONAL;
  // } else {
  //   return UPDATE_TYPE.NO_UPDATE;
  // }
};

export const returnBottomSheetContent = (key, content, phoneNumber) => {
  switch (key) {
    case LOGIN_INFO_SHEET_KEYS.RESEND_OTP:
    case LOGIN_INFO_SHEET_KEYS['GG-404']:
      return {
        ...content,
        title: content.title,
        description: (
          <MyText>
            {content.description}
            <MyText isRegular={false}>+{phoneNumber}</MyText>
            {content.description2}
          </MyText>
        ),
      };

    default:
      return {
        ...content,
        title: content.title,
        description: <MyText>{content.description}</MyText>,
      };
  }
};

const checkIfStoryViewed = (viewedStoryLength, singleStory, newStories, findStory, viewedStories, notViewedStories) => {
  if (viewedStoryLength === singleStory.length) {
    newStories[findStory] = {
      ...newStories[findStory],
      viewed: true,
    };
    viewedStories.push(newStories[findStory]);
  } else {
    newStories[findStory] = {
      ...newStories[findStory],
      viewed: false,
    };
    notViewedStories.push(newStories[findStory]);
  }
};

const checkIfStoryExistInLocal = (findStory, newStories, viewedStories, notViewedStories, item) => {
  if (findStory !== -1) {
    let singleStory = newStories[findStory].stories.slice();
    const viewedStoryLength = singleStory.filter((e) => e.hasViewed).length;
    checkIfStoryViewed(viewedStoryLength, singleStory, newStories, findStory, viewedStories, notViewedStories);
  } else notViewedStories.push(item);
};

export const sortViewedStories = (oldStories, newStories) => {
  if (newStories) {
    let viewedStories = [];
    let notViewedStories = [];
    oldStories.forEach((item) => {
      const findStory = newStories.findIndex((e) => e.id === item.id);
      checkIfStoryExistInLocal(findStory, newStories, viewedStories, notViewedStories, item);
    });
    let allStories = [];

    if (checkIfAllStoriesViewed(oldStories, viewedStories)) allStories = returnInitialSortedStories(oldStories);
    else allStories = notViewedStories.concat(viewedStories);

    return allStories;
  } else {
    const unviewedOldStories = oldStories.map((e) => {
      return {
        ...e,
        viewed: false,
      };
    });
    AsyncStorage.setItem('@stories', JSON.stringify(unviewedOldStories));
    return unviewedOldStories;
  }
};

export const getImagePickerItemIcon = (imageUri, MaterialIcon, ActivityIndicator) => {
  if (imageUri?.uploaded) return <MaterialIcon name="remove-red-eye" size={20} color={colors.white} />;

  if (imageUri?.failed) return <MaterialIcon name="refresh" size={20} color={colors.blue} />;

  if (imageUri?.uploading) {
    return <ActivityIndicator color={colors.orange} />;
  }
  return <MaterialIcon name="file-upload" size={20} color={colors.black} />;
};

export const getinfoModalSheetContent = (infoSheetType) => {
  switch (infoSheetType) {
    case INFO_SHEET_TYPES.SD_ACCOUNT_INFO.name:
      return {
        ...INFO_SHEET_TYPES.SD_ACCOUNT_INFO,
      };
    case INFO_SHEET_TYPES.SD_ACCOUNT_OPEN_INFO.name:
      return {
        ...INFO_SHEET_TYPES.SD_ACCOUNT_OPEN_INFO,
      };

    default:
      return null;
  }
};

export const getDynamicAccountTransactionList = (accounts) => {
  const array = [];
  let hasId = false;
  accounts.forEach((e) => {
    if (e?.id) {
      hasId = true;
    }
    if (TRANSACTION_LIST_DYNAMIC_ACCOUNTS.find((x) => x === e.constantAccountName)) {
      array.push({
        ...e,
        text: e.accountName,
      });
    }
  });
  if (!hasId) {
    const findSalaryAccount = accounts.findIndex((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
    return [ACCOUNT_LIST[findSalaryAccount]];
  }
  return array;
};

export const getDynamicAccountListByAccount = (accounts, staticDynamicAccount, dynamicAccountName) => {
  const array = [];
  let hasId = false;
  accounts.forEach((e) => {
    if (e?.id) {
      hasId = true;
    }
    if (staticDynamicAccount.find((x) => x === e.constantAccountName)) {
      array.push({
        ...e,
        text: e.accountName,
      });
    }
  });
  if (!hasId) {
    const findSalaryAccount = accounts.findIndex((e) => e.constantAccountName === dynamicAccountName);
    return [ACCOUNT_LIST[findSalaryAccount]];
  }
  return array;
};

export {
  formatUnderScoreLowerCase,
  sortedPointsBp,
  getDynamicAcountListByModule,
  checkIfHasSdAccount,
  syncWithDynamicAcounts,
  findAccountByName,
  findDynamicAccount,
  getSdTransactionReasons,
  getWorkAllowanceReasons,
  removeNonNumeric,
  sortTransactionDetailsByPriority,
  formatBillPayment,
  returnBpCategoriesByAccount,
  removePointsDisabledBp,
  sliderInputChange,
  getSliderInterval,
  sliderMaximumValue,
  sliderMinValue,
  getChangedField,
  textMinimumValue,
  textMaximumValue,
  formatPhoneNumber,
  sortVouchersByPurchase,
  formatBillPaymentCategories,
  returnInitialSortedStories,
  checkIfAllStoriesViewed,
  refreshStories,
  checkIfLastSubStory,
  checkIfViewingStoryValid,
  setVoucherText,
  numberWithDots,
  numberAmount,
  convertDotsToNum,
  setBillPaymentReason,
  validate,
  flattenObject,
  returnPayslip,
  timeHelper,
  getAttendanceText,
  getIndoDay,
  getIndoMonth,
  getIndoMonthLong,
  dateFormer,
  listDateFormer,
  getAttendanceColor,
  getAttendanceIcon,
  getMulaiSelesai,
  getAttendanceStatus,
  camelToSnake,
  spaceToSnake,
  setRange,
  GET_SELF_ATTENDANCE_CONDITIONS,
  textModalAttendance,
  textStatusAttendance,
  renderButtonAttendance,
  renderTextButton,
  renderIconButton,
  renderButtonHourly,
  renderButton,
  renderButtonText,
  renderButtonIcon,
  renderButtonColor,
  renderSkipBreak,
  renderAfterSubmit,
  renderAfterSubmitText,
  renderConfirmText,
  checkDistanceBetweenCoordinates,
  getStatusAndColor,
  roundOff,
  getCurrentTimeStamp,
  calculatePercentage,
  setImage,
  utmToData,
  isFeatureEnabled,
  getReferralEncryption,
  refferalDencryption,
  validateIfScreenExists,
  getAvailableBalance,
  getAccruedSalary,
  accruedSalaryTransactionHistory,
  getExpirDateText,
  checkExpiryDateTime,
  getExpiredTime,
  subtractFromWorkAllowance,
  calculateSubTotal,
  masker,
  mapDataSameAsEWA,
  returnSelectedItem,
  getListOfDynamicAccounts,
  accountByRuleProductScreen,
  accountByRuleVoucherScreen,
  setPointsValues,
  getOffSetForGraphql,
  getCurrentActiveMission,
  checkForLoan,
  needForRefetchAppStatus,
  getUserBanksFromQuery,
  createBankAccountCompleteCallBack,
  saveButtonCallBack,
  setSelectedBankCallBack,
  onPressSubmitCallBack,
  onClickSecondaryBottomSheet,
  onCompleteDeleteBank,
  resetNavigationStack,
  getUpdatedCategories,
  checkToNavigateDetails,
  onShowModalHandler,
  onUpdateHandler,
  validateNominal,
  excludeReasonsDisplay,
};
