import React, { useMemo } from 'react';
import { Platform, View, Text } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { HeaderBackButton } from '@react-navigation/elements';

import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import AmplitudeHelper from '@services/amplitude';
//import {WithdrawContext} from '../context/WithdrawContext'

/* Screens in stack */
import BillPayment from '@screens/BillPayment';
import Product from '@screens/Product';
import Settlement from '@screens/Settlement';
import PageFailed from '@screens/PageFailed';
import PageWilayah from '@screens/PageWilayah';
import VoucherDetail from '@screens/VoucherDetail';

/* Styles import */
import { ScreenOptions } from '@style/Themes';
import { useDispatch, useSelector } from 'react-redux';
import { normalize } from '@style/helper';
import { Font } from '@style/Fonts';
import ProductScreen from '@screens/ProductScreen';
import PdamRegion from '@screens/PdamRegion';
import { FEATURE_FLAGS } from '@utils/Constants';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import BillPaymentCategoriesStack from './BillPaymentCategoriesStack';
import { clearUserSelectedPhone } from '@redux/action/UserPhoneAction';

const Stack = createStackNavigator();

const BillPaymentStack = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useDispatch();
  const employerId = useSelector((state) => state.Authentication.employerId);
  const [isFeatureAvailale] = useFeatureFlag({ featureName: FEATURE_FLAGS.NEW_BILLPAYMENT });
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: '#3863F0',
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: '#3863F0',
    },
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Billpayment"
        listeners={{
          focus: () => navigation.setOptions({ tabBarVisible: true }),
        }}
        initialParams={{ screenName: route.params?.initialScreen }}
        component={isFeatureAvailale.show ? BillPaymentCategoriesStack : BillPayment}
        options={{
          ...ScreenOptions,
          headerLeft: () => (
            <Text
              style={{
                color: '#FFF',
                fontSize: normalize(17),
                marginLeft: 30,
                fontFamily: Font.Nunito_Bold,
              }}
            >
              {employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}
            </Text>
          ),
        }}
      />
      <Stack.Screen
        name="Product"
        component={isFeatureAvailale.show ? ProductScreen : Product}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : route.params?.item?.name?.toUpperCase(),
          headerBackTitle: route.params?.item?.name?.toUpperCase(),
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
          headerLeft: () => {
            return (
              <View>
                <HeaderBackButton
                  tintColor="white"
                  onPress={() => {
                    dispatch(clearUserSelectedPhone());
                    navigation.dispatch(
                      CommonActions.reset({
                        routes: [{ name: 'Billpayment' }],
                      }),
                    );
                  }}
                />
              </View>
            );
          },
        })}
      />
      <Stack.Screen
        name="PageWilayah"
        component={isFeatureAvailale.show ? PdamRegion : PageWilayah}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'Pilih Wilayah',
          headerBackTitle: 'Pilih Wilayah',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        }}
      />

      {/* <Stack.Screen
        name="VoucherDetail"
        component={VoucherDetail}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle:
            Platform.OS == "ios"
              ? null
              : route.params.data.biller.toUpperCase(),
          headerBackTitle: route.params.data.biller.toUpperCase(),
        })}
      /> */}
      {/* <Stack.Screen name="Settlement" component={Settlement} options={{...ScreenOptions,
          headerLeft: () => 
          <Text style={{ 
            color: '#FFF', 
            fontSize: normalize(17), 
            marginLeft: 30, 
            fontFamily: 'Nunito-Bold' 
            }}>{employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}</Text> 
        }}/> */}

      <Stack.Screen name="PageFailed" component={PageFailed} options={ScreenOptions} />
    </Stack.Navigator>
  );
};

export default BillPaymentStack;
