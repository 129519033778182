import React, { Children, createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, FlatList, Image, Linking, PixelRatio, Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '@style/colors';
import AmplitudeHelper from '@services/amplitude';
const { width: screenWidth, height } = Dimensions.get('screen');
import { styles } from './style';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import LinearGradient from 'react-native-web-linear-gradient';
import MyText from '@atoms/MyText';
import Right from '@expo/vector-icons/Entypo';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AppTourComponentWrapper from '../AppTour/AppTourComponentWrapper';
import withHoc from '../AppTour/CopilotHoc/index';
import { APPTOURMETA, APP_TOUR_TYPE } from '@utils/AppTourConstants';
import { validateIfScreenExists } from '@utils/UtilFunctions';
const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

const RenderAppTour = ({ children, order = 2, showTourReferral, hideLoader, item, index, showTourHome }) => {
  if (item?.title == 'referral_campaign' && showTourReferral) {
    return (
      <AppTourComponentWrapper
        text={APPTOURMETA[APP_TOUR_TYPE.referral][2]}
        order={2}
        name={APP_TOUR_TYPE.referral + 2}
        type={APP_TOUR_TYPE.referral}
      >
        {children}
      </AppTourComponentWrapper>
    );
  }

  return children;
};

const MarketingBanner = ({
  start,
  showTourHome,
  showTourReferral,
  data,
  setData,
  hideLoader,
  coinBalance,
  eventSource,
  navigation,
}) => {
  let scrollRef = useRef(null);

  useEffect(() => {
    if ((showTourHome || showTourReferral) && hideLoader) {
      scrollRef.current.scrollToOffset({ offset: 0, animated: true });
      setTimeout(() => start(), 250);
    }
  }, [showTourHome, showTourReferral, hideLoader]);

  const renderItem = ({ item, index }) => {
    // item?.title &&
    //   AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Banner.BannerAppeared.name, {
    //     [AMPLITUDE_CONSTANTS.Banner.BannerAppeared.attributes.banner_name]: item.title,
    //   });

    const onPressHandler = () => {
      const e = AMPLITUDE_CONSTANTS.Banner.BannerClicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: eventSource ?? null,
        [e.attributes.banner_name]: item.title ?? null,
        [e.attributes.point_balance]: coinBalance ?? null,
      });

      if (item.redirectionUrl) return Linking.openURL(item.redirectionUrl);
      if (validateIfScreenExists(item)) {
        navigation.navigate('TransactionList', {
          screen: item.redirectionScreen,
          params: { source: eventSource === 'poin_page' ? 'poins_campaign_banner' : 'homepage_campaign_banner' },
        });
      }
    };

    return (
      <ShimmerPlaceholder
        visible={hideLoader}
        style={{
          marginHorizontal: 5,
          width: (screenWidth * 60) / 100,
          height: (screenWidth * 25) / 100,
          borderRadius: 10,
        }}
      >
        {/* <RenderAppTour item={item} index={index} showTourHome={showTourHome} showTourReferral={showTourReferral}> */}
        <Pressable
          android_ripple={{ color: colors.lightGrey }}
          style={{ borderRadius: 10, overflow: 'hidden', marginHorizontal: 5, backgroundColor: colors.lightGrey }}
          onPress={onPressHandler}
        >
          <Image
            style={{
              height: (screenWidth * 25) / 100,
              borderRadius: 10,
              overflow: 'hidden',
              backgroundColor: 'transparent',
              resizeMode: 'cover',
              width: (screenWidth * 60) / 100,
            }}
            source={{ uri: item.banner }}
          />
        </Pressable>
        {/* </RenderAppTour> */}
      </ShimmerPlaceholder>
    );
  };
  const keyExtractor = (item) => item.title;

  return (
    // <AppTourComponentWrapper
    //   text={APPTOURMETA[APP_TOUR_TYPE.homepage][6]}
    //   order={6}
    //   name={`${APP_TOUR_TYPE.homepage}6`}
    //   type={APP_TOUR_TYPE.homepage}
    //   active={!showTourReferral}
    // >
    <FlatList
      keyExtractor={keyExtractor}
      showsHorizontalScrollIndicator={false}
      horizontal
      data={data}
      ref={scrollRef}
      style={{ marginTop: 15 }}
      renderItem={renderItem}
    />
    // </AppTourComponentWrapper>
  );
};

// export default withHoc(MarketingBanner, {
//   isArrowVisible: true,
//   svgMaskProps: { isFullWidth: false, widthStartAdj: 0, widthEndAdj: 10 },
//   verticalOffset: 35,
// });

export default MarketingBanner;
