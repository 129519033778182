import MyText from '@atoms/MyText';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

const TransactionDetailItem = ({ title, value, isStatus, statusColor }) => (
  <View style={styles.container}>
    <MyText>{title}</MyText>
    <MyText
      customStyle={{ color: isStatus ? statusColor : colors.black, flex: 1, textAlign: 'right', marginStart: 5 }}
      isRegular={false}
    >
      {value}
    </MyText>
  </View>
);

TransactionDetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isStatus: PropTypes.bool.isRequired,
  statusColor: PropTypes.string.isRequired,
};

TransactionDetailItem.defaultProps = {};

export default TransactionDetailItem;
