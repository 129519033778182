import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import MainParentWrapper from '@components/MainParentWrapper';
import { normalize } from '@style/helper';
import PhoneNumberInput from '@components/PhoneNumberInput';
import TextInputComponent from '@components/TextInputComponent';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import LoadingButton from '@atoms/LoadingButton';
import InfoTextComponent from '@atoms/InfoTextComponent';
import {
  formatPhoneNumber,
  removeNonNumeric,
  addPhnoCompleteCallBack,
  onErrPhoneNoCallbak,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { ADD_PHONE_NUMBER } from '@gaji-gesa/gg-react-shared/src/gqlQuery/phoneEnrollments';
import { useDispatch, useSelector } from 'react-redux';
import { PHONE_ENROLLMENT_STATUS, PHONE_ENROLL_INFO_TEXT } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import {
  BOTTONSHEET_PHONE_ENROLLMENT,
  BOTTONSHEET_PHONE_CONTENT,
} from '@gaji-gesa/gg-ui-shared/src/uiUtils/uiConstants';
import { useNavigation } from '@react-navigation/native';
import { returnBottomSheetContent } from '@utils/UtilFunctions';
import BottomSheet from '@components/LoginInfoSheet';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { styles } from './style';

const AddPhoneNo = ({ route }) => {
  const navigation = useNavigation();

  const [inputPhNo, setInputPhno] = useState('');

  const [inputFieldError, setInputFieldError] = useState({ error: false, errorMessage: 'Nomor handphone tidak valid' });
  const [enableOtpButtons, setEnableOtpButtons] = useState(false);

  const { employeeId } = useSelector((state) => state.Authentication);

  const [ownerName, setOwnerName] = useState('');

  const { phoneNumber } = useSelector((state) => state.Authentication);

  const pageSource = route?.params?.source;
  const txnCategoryId = route?.params?.txnCategoryId;
  const dispatch = useDispatch();

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.add_phonenumber_page;

    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.transaction_category_id]: txnCategoryId ?? null,
    });
  }, []);

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
      BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY],
    ),
    errorCode: BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
  });

  const onChangePhHandler = (text) => {
    inputFieldError.error && setInputFieldError({ error: false, errorMessage: inputFieldError.errorMessage });
    setInputPhno(removeNonNumeric(text));
    if (removeNonNumeric(text).substring(0, 2) === '00') {
      setEnableOtpButtons(false);
      setInputFieldError({ error: true, errorMessage: inputFieldError.errorMessage });
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_input_error;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.error_message]: 'invalid_number',
      });
    }
    if (formatPhoneNumber(removeNonNumeric(text), '62').length >= 8) {
      setEnableOtpButtons(true);
    } else {
      setEnableOtpButtons(false);
    }
  };

  const logEventErr = (errMessage) => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_error;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.error_message]: errMessage,
    });
  };

  const [addPhoneNumber, { loading }] = useMutation(ADD_PHONE_NUMBER, {
    fetchPolicy: 'no-cache',
    variables: {
      userPayload: {
        mobileNumber: formatPhoneNumber(inputPhNo),
        employeeId,
        ownerName: ownerName.trim(),
        status: PHONE_ENROLLMENT_STATUS.PENDING,
      },
    },
    onCompleted: addPhnoCompleteCallBack(dispatch, navigation),
    onError: onErrPhoneNoCallbak(
      BOTTONSHEET_PHONE_ENROLLMENT,
      BOTTONSHEET_PHONE_CONTENT,
      returnBottomSheetContent,
      setInfoModal,
      logEventErr,
    ),
  });

  const submitHandler = () => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.save_phonenumber_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
    });

    if (phoneNumber === formatPhoneNumber(inputPhNo))
      return setInfoModal((prevState) => ({ ...prevState, visible: true }));

    addPhoneNumber();
  };

  const onPressPrimaryHandler = () => setInfoModal((prevState) => ({ ...prevState, visible: false }));
  const onCloseHandler = () => setInfoModal((prevState) => ({ ...prevState, visible: false }));

  const logEvent = (event) => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll[event.name];
    let obj = {
      [e.attributes.source]: pageSource,
    };
    if (
      event.name == AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_input_clicked.name ||
      event.name == AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_filled.name
    ) {
      obj[e.attributes.phone_number] = inputPhNo ?? null;
    }

    if (
      event.name == AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_clicked.name ||
      event.name == AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_filled.name
    ) {
      obj[e.attributes.input] = ownerName ?? null;
    }

    AmplitudeHelper.logEvent(e.name, obj);
  };

  return (
    <MainParentWrapper
      loading={false}
      isError={false}
      onReload={() => {}}
      containerStyle={{ flex: 1, paddingHorizontal: normalize(15) }}
    >
      <PhoneNumberInput
        phoneNumber={inputPhNo}
        setPhoneNumber={onChangePhHandler}
        errorMessage={inputFieldError.errorMessage}
        isError={inputFieldError.error}
        title={'Masukkan Nomor HP'}
        setInputFocused={() => {}}
        textInputContainer={{ paddingVertical: 8 }}
        prefixTextStyle={{ marginTop: normalize(4) }}
        labelTxtStyle={{ color: colors.neutral_darkMist }}
        onFocus={() => logEvent(AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_input_clicked)}
        onBlur={() => logEvent(AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_filled)}
      />

      <TextInputComponent
        title={'Masukkan Nama'}
        textInputProps={{
          placeholder: 'Contoh: Ayah, Ibu, Joko, Siti',
          numberOfLines: 1,
          onChangeText: setOwnerName,
          value: ownerName,
          placeholderTextColor: colors.neutral_darkMist,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_clicked),
          onBlur: () => logEvent(AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_name_filled),
        }}
      />

      <LoadingButton
        container={styles.loadingButton}
        textStyle={styles.loadingButtonText}
        buttonColor={colors.primary_carnation}
        text={'Simpan Nomor'}
        textColor={colors.neutral_cotton}
        textTransform="none"
        disabled={!enableOtpButtons || !ownerName}
        disabledStyle={styles.disabledButton}
        onPress={submitHandler}
        loading={loading}
      />
      <InfoTextComponent
        iconColor={colors.neutral_charcoal}
        infoText={PHONE_ENROLL_INFO_TEXT}
        containerStyle={{ backgroundColor: colors.neutral_mist }}
      />
      <BottomSheet onPressPrimary={onPressPrimaryHandler} loginInfo={infoModal} onClose={onCloseHandler} />
    </MainParentWrapper>
  );
};

export default AddPhoneNo;
