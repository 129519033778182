import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { colors } from '@style/colors';
import Close from '@expo/vector-icons/AntDesign';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import { styles } from './style';

const LoginInfoSheet = ({ loginInfo, onPressPrimary, onPressSecondary, onClose }) => (
  <MyModal onPress={onClose} containerStyle={styles.modal} isVisible={loginInfo?.visible}>
    <View style={styles.innerContainer}>
      <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <Close name="close" size={20} color={colors.black} />
      </TouchableOpacity>
      <View style={styles.icon}>{loginInfo?.data?.icon}</View>
      <View style={styles.textContainer}>
        <MyText isRegular={false} h5 customStyle={styles.title}>
          {loginInfo?.data?.title}
        </MyText>
        {loginInfo?.data?.description}
      </View>

      {loginInfo?.data?.hasTwoButtons ? (
        <View style={styles.buttonContainer}>
          <LoadingButton
            container={{ borderRadius: 30, flex: 1, marginEnd: 10 }}
            buttonColor={colors.white}
            text={loginInfo?.data?.buttonText}
            textColor={colors.orange}
            bordered
            onPress={onPressPrimary}
            textTransform="capitalize"
          />
          <LoadingButton
            container={{ borderRadius: 30, flex: 1 }}
            buttonColor={colors.orange}
            text={loginInfo?.data?.buttonText2}
            textTransform="capitalize"
            onPress={onPressSecondary}
          />
        </View>
      ) : (
        <LoadingButton
          container={styles.cta}
          buttonColor={colors.orange}
          text={loginInfo?.data?.buttonText}
          textTransform="capitalize"
          onPress={onPressPrimary}
        />
      )}
    </View>
  </MyModal>
);

LoginInfoSheet.propTypes = {
  loginInfo: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      hasTwoButtons: PropTypes.bool,
      description: PropTypes.element.isRequired,
      icon: PropTypes.element.isRequired,
      buttonText2: PropTypes.string.isRequired,
    }).isRequired,
    errorCode: PropTypes.string,
  }).isRequired,
  onPressPrimary: PropTypes.func.isRequired,
  onPressSecondary: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(LoginInfoSheet);
