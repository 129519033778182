import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: 10,
  },
  star: {
    color: colors.textRed,
  },
  titleText: {
    color: colors.philippineGray,
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: colors.grey,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginTop: 5,
  },
  textInput: {
    fontFamily: Font.Nunito_Regular,
    paddingVertical: 8,
    outlineColor: 'transparent',
  },
});

export default styles;
