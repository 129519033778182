import React, { useMemo } from 'react';
import { View, Text, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { HeaderBackButton } from '@react-navigation/elements';

import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import AmplitudeHelper from '@services/amplitude';
//import {WithdrawContext} from '../context/WithdrawContext'

/* Screens in stack */
import BillPayment from '@screens/BillPayment';
import Product from '@screens/Product';
import Settlement from '@screens/Settlement';
import PageFailed from '@screens/PageFailed';
import PageWilayah from '@screens/PageWilayah';
import VoucherDetail from '@screens/VoucherDetail';

/* Styles import */
import { ScreenOptions } from '@style/Themes';
import { useSelector } from 'react-redux';
import { normalize } from '@style/helper';
import { Font } from '@style/Fonts';
import VoucherInventoryScren from '@screens/VoucherInventoryScreen';
import { colors } from '@style/colors';
import LoanListing from '@screens/Loan/LoanListing';
import LoanInfo from '@screens/Loan/LoanInfo';
import LoanDetails from '@screens/Loan/LoanDetails';
import LoanTerms from '@screens/Loan/LoanTerms';
const Stack = createStackNavigator();

const ProductStack = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: '#3863F0',
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: '#3863F0',
    },
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="VoucherInventory"
        initialParams={{ screenName: route.params?.initialScreen }}
        component={VoucherInventoryScren}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: 'Konsul',

          headerStyle: {
            elevation: 0,
            backgroundColor: colors.secondaryColor,
            borderBottomColor: colors.secondaryColor,
          },
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />

      <Stack.Screen
        name="LoanListing"
        //  initialParams={{ screenName: route.params?.initialScreen }}
        component={LoanListing}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: 'e-Kasbon',

          headerStyle: {
            elevation: 0,
            backgroundColor: colors.secondaryColor,
            borderBottomColor: colors.secondaryColor,
          },
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />

      <Stack.Screen
        name="LoanInfo"
        //   initialParams={{ screenName: route.params?.initialScreen }}
        component={LoanInfo}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: 'e-Kasbon',

          headerStyle: {
            elevation: 0,
            backgroundColor: colors.secondaryColor,
            borderBottomColor: colors.secondaryColor,
          },
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />

      <Stack.Screen
        name="LoanDetails"
        component={LoanDetails}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: 'e-Kasbon',
          headerStyle: {
            elevation: 0,
            backgroundColor: colors.secondaryColor,
            borderBottomColor: colors.secondaryColor,
          },
          // headerLeft: (props) => {
          //   return (
          //     <View accessibilityLabel="transactionSummaryBackButton">
          //       <HeaderBackButton
          //         tintColor="white"
          //         onPress={() => {

          //           navigation.dispatch(
          //             CommonActions.reset({
          //               routes: [{ name: 'HomeScene' }],
          //             }),
          //           );
          //         }}
          //       />
          //     </View>
          //   );
          // },
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />

      <Stack.Screen
        name="LoanTerms"
        component={LoanTerms}
        options={({ route }) => ({
          headerShown: false,
        })}
      />
    </Stack.Navigator>
  );
};

export default ProductStack;
