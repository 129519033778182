import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';

const styles = StyleSheet.create({
  logoStyle: { paddingTop: 20, alignItems: 'center' },
  artwork: { alignItems: 'center', zIndex: 200, paddingHorizontal: 55, paddingTop: 2 },
  cardContainer: {
    flex: 1,
    alignSelf: 'stretch',
    // , paddingTop: '9%',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  textContainer: { paddingVertical: 12 },
  textStyle: {
    color: colors.white,
    textAlign: 'center',
    zIndex: 2,
    fontSize: normalize(14),
  },
  linearStyle: {
    width: 300,
    alignSelf: 'flex-end',
    zIndex: 1,
    height: 300,
    position: 'absolute',
    borderRadius: 300 / 2,
  },
  rightTop: { right: -140, top: -135 },
  leftBottom: { left: -50, bottom: -155 },
});

export default styles;
