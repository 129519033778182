import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Dimensions, Pressable, ScrollView } from 'react-native';
import FaIcon from '@expo/vector-icons/FontAwesome';
import FaFiveIcon from '@expo/vector-icons/FontAwesome5';
import CheckBox from 'expo-checkbox';
import { useDispatch, useSelector } from 'react-redux';

import { normalize, normalizeHeight } from '@style/helper';
import { setCanContinue, setParentPage } from '@redux/action/Common';
import { setAcceptedTerms, setShowTerms, updateShowModal } from '@redux/action/Common';
import { resetTransaction } from '@redux/action/Transactions';
import { confirmTransaction } from '@services/TransactionApi';
import Terms from '../Terms';
import Loading from '../Loading';
import AmplitudeHelper from '@services/amplitude';
import { Font } from '@style/Fonts';
import { colors } from '@style/colors';
import styles from './style';
import { numberWithDots } from '@utils/UtilFunctions';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { getCurrentTimeStamp } from '@utils/UtilFunctions';
import ReferralApplied from '@components/ReferralApplied';
import MyText from '@atoms/MyText';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const BankDetail = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { isValidCode, isTransacted } = useSelector((state: any) => state.Referral);
  const clickedConfirm = useRef(0);
  const common = useSelector((state: any) => state.Common);
  const {
    requestAmount,
    feeAmount,
    actualAmount,
    bankName,
    accountNumber,
    selectedReason,
    selectedReasonId,
    selectedReasonColor,
    beneficiaryName,
  } = useSelector((state: any) => state.Transactions);
  const { transCategoryId, transSource } = useSelector((state: any) => state.Transactions);
  const { referralPrecheck } = useSelector((state: any) => state.Withdrawal);
  const { currentHomePageSelectedAccount } = useSelector((state: any) => state.DynamicAccounts);
  const { redeemCode } = useSelector((state: any) => state.Referral);

  const selectedProduct = useSelector((state: any) => state.BillPayments.selectedProduct);
  const [loading, setLoading] = useState(false);

  // const isFocused = useIsFocused()

  // useEffect(() => {
  //   if(isFocused){
  //     console.log('IS FOCUSED')
  //     dispatch(setShowTerms(false))
  //   }
  // },[isFocused])

  useEffect(() => {
    const clickedBack = navigation.addListener('blur', () => {
      // AmplitudeHelper.logEvent("userClickedBack", {
      //   screen: `Withdrawal Transaction Summary`,
      // });

      if (clickedConfirm.current == 0) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.attributes.transaction_category_id]: transCategoryId,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.attributes.source]: transSource,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.attributes.timestamp]: getCurrentTimeStamp(),
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.attributes.referral_code]: redeemCode || null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionCancelled.attributes.dynamic_account_name]:
            currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
        });
      }
    });
    return clickedBack;
  }, [navigation, clickedConfirm.current]);

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.requested_amount]: requestAmount ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.transaction_category_id]: transCategoryId,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.reason]: selectedReason ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.reason_id]: selectedReasonId ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.fee_requested]: feeAmount ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.source]: transSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionSummaryPage.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    if (common.acceptedTerms) {
      dispatch(setAcceptedTerms());
      dispatch(setShowTerms(false));
    }
    return () => {
      dispatch(resetTransaction());
      dispatch(setShowTerms(false));
    };
  }, []);

  useEffect(() => {
    if (common.confirmed && common.parentPage === 'dashboard') {
      navigation.navigate('Settlement', {
        timeTaken: '24 jam',
        category: selectedProduct.name ? selectedProduct.name : 'Tarik Gaji',
        productName: selectedProduct.name ? selectedProduct.name : 'Tarik Gaji',
        parentPage: common.parentPage,
        transactionCategoryId: transCategoryId,
        eventSource: transSource,
      });
    }
  }, [common.confirmed, common.parentPage]);

  const handleLanjut = async () => {
    try {
      clickedConfirm.current = 1;
      //AmplitudeHelper.trackEvent('transactionConfirmation')
      setLoading(true);
      dispatch(setParentPage('dashboard'));
      await dispatch(confirmTransaction(actualAmount));
    } catch (error) {
      setLoading(false);
    }
  };

  const returnReferralToolTip = () => {
    if (referralPrecheck.visible) {
      return (
        <MyText h2 customStyle={styles.textItalic}>
          {referralPrecheck.message}
        </MyText>
      );
    }
    return (
      <MyText customStyle={{ width: '80%', marginStart: 5 }} h2>
        Kode referral berhasil dipakai. <MyText isRegular={false}>GajiGesa Poin</MyText> akan ditambahkan setelah
        transaksi ini sukses
      </MyText>
    );
  };

  return (
    <>
      {common.showTerms ? (
        <Terms />
      ) : (
        <>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: '#3863F0',
                height: screenHeight / 4,
                width: screenWidth,
                position: 'absolute',
                right: 0,
              }}
            />
            <ScrollView contentContainerStyle={styles.container}>
              <Text style={styles.cardTitle}>Detail Transfer</Text>
              <View style={styles.amountContainer}>
                <View style={styles.amountDetails}>
                  <Text style={styles.amountText}>Jumlah Yang Diajukan</Text>
                  <Text
                    accessibilityLabel={'requestAmountText'}
                    style={[styles.amountText, { fontFamily: Font.Nunito_Bold }]}
                  >
                    Rp.{numberWithDots(requestAmount)}
                  </Text>
                </View>
                <View style={styles.amountDetails}>
                  <Text style={styles.amountText}>Biaya Admin</Text>
                  <Text
                    accessibilityLabel={'adminFeeText'}
                    style={[styles.amountText, { color: 'red', fontFamily: Font.Nunito_Bold }]}
                  >
                    Rp.{numberWithDots(feeAmount)}
                  </Text>
                </View>
                <View style={styles.amountDetails}>
                  <Text style={styles.amountText}>Jumlah Yang Diterima</Text>
                  <Text
                    accessibilityLabel={'receivedAmountText'}
                    style={[styles.amountText, { color: '#3863F0', fontFamily: Font.Nunito_Bold }]}
                  >
                    Rp.{numberWithDots(actualAmount)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  alignSelf: 'flex-start',
                  marginTop: normalizeHeight(15),
                  marginBottom: normalizeHeight(10),
                  marginLeft: normalize(25),
                }}
              >
                <Text style={styles.cardSubtitle}>
                  <FaIcon name="bank" style={{ fontSize: normalize(13), color: '#3863F0' }} />
                  {'  '}Rekening Bank
                </Text>
                {/* <View style={styles.detailContainer}> */}
                <Text style={styles.bankTitle}>{bankName}</Text>
                <Text style={styles.bankTitle}>
                  ***** **** **** {accountNumber.slice(accountNumber.length - 4, accountNumber.length)}
                </Text>
                <MyText customStyle={{ marginLeft: normalize(25) }}>a/n {beneficiaryName}</MyText>
              </View>
              <View
                style={{
                  alignSelf: 'flex-start',
                  marginTop: normalizeHeight(15),
                  // marginBottom: normalizeHeight(10),

                  width: '75%',
                  marginLeft: normalize(25),
                }}
              >
                <Text style={styles.cardSubtitle}>
                  <FaFiveIcon name="scroll" style={{ fontSize: normalize(13), color: '#3863F0' }} />
                  {'  '}Alasan Penarikan
                </Text>
                <View style={styles.detailSection}>
                  <Text style={styles.selectedReason}>{selectedReason || 'Pilih Alasan'}</Text>
                  <Pressable
                    onPress={() => {
                      {
                        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userChangedReason.name, {
                          [AMPLITUDE_CONSTANTS.Withdrawal.userChangedReason.attributes.source]: common.eventSource,
                          [AMPLITUDE_CONSTANTS.Withdrawal.userChangedReason.attributes.referral_code]:
                            redeemCode || null,
                          [AMPLITUDE_CONSTANTS.Withdrawal.userChangedReason.attributes.dynamic_account_name]:
                            currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                        });
                        dispatch(updateShowModal('reasons'));
                        dispatch(setCanContinue(false));
                      }
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: colors.orange,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: colors.orange,
                          paddingHorizontal: normalize(14),
                          paddingVertical: 2,
                        }}
                      >
                        Ganti
                      </Text>
                    </View>
                  </Pressable>
                </View>
              </View>

              {isValidCode && !isTransacted && (
                <ReferralApplied text={returnReferralToolTip()} toolTip={referralPrecheck} />
              )}

              <View style={styles.termsContainer}>
                <CheckBox
                  onValueChange={() => {
                    if (common.acceptedTerms) {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes
                          .transaction_category_id]: transCategoryId,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.timestamp]:
                          getCurrentTimeStamp(),
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.source]:
                          transSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.referral_code]:
                          redeemCode || null,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes
                          .dynamic_account_name]: currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                      });
                    } else {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.transaction_category_id]:
                          transCategoryId,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.timestamp]:
                          getCurrentTimeStamp(),
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.source]: transSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.referral_code]:
                          redeemCode || null,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes
                          .dynamic_account_name]: currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                      });
                    }
                    dispatch(setAcceptedTerms());
                  }}
                  color={colors.orange}
                  value={common.acceptedTerms}
                  style={{ marginLeft: normalize(5) }}
                />

                <Text
                  onPress={() => {
                    if (common.acceptedTerms) {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes
                          .transaction_category_id]: transCategoryId,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.timestamp]:
                          getCurrentTimeStamp(),
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.source]:
                          transSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes
                          .dynamic_account_name]: currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckboxUnchecked.attributes.referral_code]:
                          redeemCode || null,
                      });
                    } else {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.transaction_category_id]:
                          transCategoryId,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.timestamp]:
                          getCurrentTimeStamp(),
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.source]: transSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.referral_code]:
                          redeemCode || null,
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.dynamic_account_name]:
                          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedTermsCheckbox.attributes.dynamic_account_name]:
                          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
                      });
                    }
                    // AmplitudeHelper.logEvent("userClickedTermsCheckbox");
                    dispatch(setShowTerms(true));
                  }}
                  style={{
                    fontSize: normalize(11),
                    lineHeight: normalizeHeight(13),
                    fontFamily: Font.Nunito_Regular,
                    textDecorationLine: 'underline',
                    marginStart: 15,
                  }}
                >
                  {`Saya menyetujui syarat & ketentuan \nyang berlaku`}
                </Text>
              </View>
              {loading ? (
                <View style={styles.loadingContainer}>
                  <Loading />
                </View>
              ) : (
                <Pressable
                  disabled={!common.acceptedTerms}
                  onPress={handleLanjut}
                  disable={loading}
                  android_ripple={{ color: colors.lightGrey }}
                >
                  <View
                    style={[
                      {
                        elevation: 8,
                        backgroundColor: '#F56B57',
                        borderRadius: 30,
                        paddingHorizontal: normalize(10),
                        paddingVertical: normalizeHeight(9),
                        width: normalize(220),
                        marginBottom: normalize(10),
                        marginTop: normalize(10),
                      },
                      !common.acceptedTerms ? { backgroundColor: '#F89F92' } : null,
                    ]}
                  >
                    <Text
                      style={{
                        color: colors.white,
                        fontFamily: Font.Nunito_Bold,
                        fontSize: normalize(14),
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}
                    >
                      Konfirmasi
                    </Text>
                  </View>
                </Pressable>
              )}
            </ScrollView>
          </View>
        </>
      )}
    </>
  );
};

export default BankDetail;
