import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

/* REDUX */
import { useSelector } from 'react-redux';

/* Styles */
import { BillPaymentProductCard } from '@components/Button';
import { normalize } from '@style/helper';

const PaketData = ({ dataList, onPress, onDetail, show }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);

  if (!show) {
    return <></>;
  }

  return (
    <View style={{ width: '90%', height: '90%' }}>
      <FlatList
        data={dataList}
        renderItem={(row) => {
          return (
            <BillPaymentProductCard
              alignment={false}
              prop={row.item}
              cardStyle={styles.card}
              titleStyle={styles.title}
              subTitleStyle={styles.subTitle}
              onPress={() => {
                onPress(row);
              }}
              onDetail={() => {
                onDetail(row);
              }}
              selected={JSON.stringify(row.item) == JSON.stringify(selectedProduct)}
            />
          );
        }}
        numColumns={1}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  card: { width: '100%', marginBottom: 10 },
  title: { width: '100%', fontSize: normalize(24), fontWeight: 'bold' },
  subTitle: { flex: 1, fontSize: normalize(12) },
});

export default PaketData;
