import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { View, FlatList, Linking, RefreshControl } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import ListPhonenumber from '@atoms/PhoneEnrollments/ListPhoneNumber';
import ListHeader from '@atoms/PhoneEnrollments/ListHeader';
import ListItem from '@atoms/PhoneEnrollments/ListItem';
import EmptyPhoneBook from '@atoms/PhoneEnrollments/EmptyPhoneBook';
import AlertBar from '@atoms/PhoneEnrollments/AlertBar';
import Divider from '@atoms/Divider';
import { normalize } from '@style/helper';
import { styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PHONE_NUMBERS, DELETE_PHONE_NUMBER } from '@gaji-gesa/gg-react-shared/src/gqlQuery/phoneEnrollments';
import {
  filterRejected,
  onClickSecondaryPhonNoBottomSheet,
  getShowValue,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { PHONE_ENROLLMENT_STATUS, PHONE_ENROLL_LINK } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import LoadingModal from '@components/LoadingModal';
import {
  BOTTONSHEET_PHONE_ENROLLMENT,
  BOTTONSHEET_PHONE_CONTENT,
} from '@gaji-gesa/gg-ui-shared/src/uiUtils/uiConstants';
import { colors } from '@style/colors';
import { returnBottomSheetContent } from '@utils/UtilFunctions';
import BottomSheet from '@components/LoginInfoSheet';
import { setUserSelectedPhone } from '@redux/action/UserPhoneAction';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { shouldRenderAddBottom, shouldRenderTransactFlow } from './UserPhoneListUtils';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';

const UserPhoneList = ({ route }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { phoneNumber, userName } = useSelector((state) => state.Authentication);

  const isTransact = route?.params?.isTransact;
  const eventSource = route?.params?.source;
  const txnCategoryId = route?.params?.txnCategory;

  const [listData, setListData] = useState([]);

  const pageSource = isTransact ? AMPLITUDE_EVENT_SOURCES.select_phone_page : AMPLITUDE_EVENT_SOURCES.phone_list_page;
  const eventState = listData.length == 0 ? 'empty' : 'registered';

  const [mainLoading, setMainLoading] = useState(true);

  const isFocused = useIsFocused();

  const initalState = {
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
      BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE],
    ),
    errorCode: BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
    currentId: 0,
    selectedNumber: 0,
    selectedName: '',
  };

  const [infoModal, setInfoModal] = useState(initalState);

  const [getPhoneNumbers, { loading, error }] = useLazyQuery(GET_PHONE_NUMBERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setListData(filterRejected(data?.employee_enrolled_phone, isTransact) ?? []);
      setMainLoading(false);

      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phone_list_page;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: eventSource,
        [e.attributes.state]:
          filterRejected(data?.employee_enrolled_phone, isTransact).length == 0 ? 'empty' : 'registered',
      });
    },
  });

  const [deletePhoneNumber, { loading: deleteLoading }] = useMutation(DELETE_PHONE_NUMBER, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_confirmed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
      });
      getPhoneNumbers();
      setInfoModal(initalState);
    },
  });

  useEffect(() => {
    if (isFocused) getPhoneNumbers();
  }, [isFocused]);

  const noOfPendings = useMemo(
    () => listData.filter((e) => e.status === PHONE_ENROLLMENT_STATUS.PENDING).length,
    [listData],
  );

  const hasPendings = noOfPendings > 0;

  const hasAllPendings = noOfPendings === listData.length;

  function getState(listData, hasPendings, isTransact) {
    if (listData.length > 0) {
      if (!isTransact) return 'registered';

      if (hasPendings) return 'pending';
      return 'approved';
    }
    return 'empty';
  }

  useEffect(() => {
    if (isTransact) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.select_phone_page;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: pageSource,
        [e.attributes.state]: getState(listData, hasPendings, isTransact),
        [e.attributes.transaction_category_id]: txnCategoryId ?? null,
      });
    }
  }, [listData]);

  const onSelected = (item) => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_selected;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.selected_phone]: item?.mobileNumber,
      [e.attributes.transaction_category_id]: txnCategoryId ?? null,
    });

    dispatch(setUserSelectedPhone({ phoneNumber: item?.mobileNumber, name: item?.ownerName }));
    navigation.goBack();
  };

  const onPressHandler = (item) => {
    if (isTransact && item.status === PHONE_ENROLLMENT_STATUS.APPROVED) onSelected(item);
  };

  const onDeletePress = (item) => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.number]: item?.mobileNumber,
      [e.attributes.name]: item?.ownerName,
    });

    if (item.status === PHONE_ENROLLMENT_STATUS.REJECTED) {
      setInfoModal((prevState) => ({
        ...prevState,
        selectedNumber: item?.mobileNumber,
        selectedName: item?.ownerName,
      }));
      return onClickSecondaryPhonNoBottomSheet(
        setInfoModal,
        infoModal,
        deletePhoneNumber,
        item.id,
        BOTTONSHEET_PHONE_ENROLLMENT,
      );
    }

    setInfoModal({
      data: returnBottomSheetContent(
        BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
        BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE],
      ),
      errorCode: BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE,
      visible: true,
      currentId: item.id,
      selectedNumber: item?.mobileNumber,
      selectedName: item?.ownerName,
    });
  };

  const onPressPrimaryBottomSHeet = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      onClickSecondaryPhonNoBottomSheet(
        setInfoModal,
        infoModal,
        deletePhoneNumber,
        infoModal.currentId,
        BOTTONSHEET_PHONE_ENROLLMENT,
      );
    } else {
      setInfoModal(initalState);
    }
  };

  const onPressSecondaryBottomSheet = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_cancelled;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
        [e.attributes.button]: 'cancel',
      });
    }
    setInfoModal(initalState);
  };

  const onPressAddPhoneNo = (buttonPosition) => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.add_phonenumber_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.state]: eventState,
      [e.attributes.button]: buttonPosition,
      [e.attributes.transaction_category_id]: txnCategoryId ?? null,
    });

    if (listData.length < 10) {
      navigation.navigate('AddPhoneNo', { source: pageSource, txnCategoryId: txnCategoryId ?? null });
      return;
    }

    setInfoModal({
      data: returnBottomSheetContent(
        BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
        BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT],
      ),
      errorCode: BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
      visible: true,
      currentId: 0,
    });
  };
  const renderItem = useCallback(
    ({ item }) => (
      <ListItem
        phoneNumber={item.mobileNumber}
        ownerName={item.ownerName}
        status={item.status}
        onPresHandler={() => onPressHandler(item)}
        isTransact={isTransact}
        onDeletePress={() => onDeletePress(item)}
        showTick={item.mobileNumber === route?.params?.phoneNumber}
      />
    ),
    [isTransact],
  );

  const renderHeader = useCallback(() => {
    return <EmptyPhoneBook onPress={() => onPressAddPhoneNo('middle')} />;
  }, []);

  const listHeader = useCallback(() => {
    return (
      <>
        <View style={styles.listTitleStyle}>
          <MyText isRegular={false} customStyle={{ fontSize: normalize(14) }}>
            Nomor HP Kamu (Utama)
          </MyText>
        </View>

        <ListPhonenumber
          ownerName={userName}
          phoneNumber={phoneNumber}
          disabled={!isTransact}
          onPress={() => onSelected({ mobileNumber: phoneNumber, ownerName: userName })}
          showTick={isTransact && phoneNumber === route?.params?.phoneNumber}
        />

        <Divider horizontal customStyle={styles.dividerStyle} />
        <ListHeader onLinkPress={() => onPressAddPhoneNo('top_right')} showLink={isTransact} />
        <AlertBar
          show={hasPendings && isTransact}
          text={'Nomor HP yang kamu daftarkan perlu divalidasi terlebih dahulu sebelum bisa digunakan.'}
        />
      </>
    );
  }, [isTransact, phoneNumber, userName, isTransact, hasPendings]);

  const goBackToTransaction = () => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.transact_now_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
      [e.attributes.transaction_category_id]: txnCategoryId ?? null,
    });

    navigation.goBack();
  };

  const primaryPressBottomButton = () => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.help_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: pageSource,
    });

    Linking.openURL(PHONE_ENROLL_LINK);
  };

  const keyExtractor = (item) => item.id.toString();

  const onBottomSheetCloseHandler = () => {
    if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
      const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phonenumber_delete_cancelled;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.number]: infoModal.selectedNumber,
        [e.attributes.name]: infoModal.selectedName,
        [e.attributes.button]: 'close',
      });
    }

    setInfoModal(initalState);
  };

  return (
    <MainParentWrapper
      loading={mainLoading}
      isError={error}
      onReload={getPhoneNumbers}
      containerStyle={styles.containerStyle}
    >
      <FlatList
        data={listData}
        refreshControl={<RefreshControl colors={[colors.blue]} refreshing={loading} onRefresh={getPhoneNumbers} />}
        ListHeaderComponent={listHeader}
        ListEmptyComponent={renderHeader}
        contentContainerStyle={styles.contantStyle}
        style={isTransact && hasAllPendings && hasPendings && styles.styleListTxn}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />

      {shouldRenderAddBottom(isTransact || listData.length === 0, primaryPressBottomButton, () =>
        onPressAddPhoneNo('bottom'),
      )}

      {shouldRenderTransactFlow(
        isTransact && hasAllPendings && hasPendings && listData.length > 0,
        goBackToTransaction,
      )}

      <BottomSheet
        onPressPrimary={onPressPrimaryBottomSHeet}
        onPressSecondary={onPressSecondaryBottomSheet}
        loginInfo={infoModal}
        hasTwoButtons={infoModal.data.hasTwoButtons}
        onClose={onBottomSheetCloseHandler}
      />

      <LoadingModal loading={deleteLoading} />
    </MainParentWrapper>
  );
};

export default UserPhoneList;
