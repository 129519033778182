import React from 'react';
import { Text, View, Dimensions, StyleSheet, FlatList, ActivityIndicator } from 'react-native';
import { ScrollView } from 'react-native';
import moment from 'moment';
import { numberWithDots } from '@utils/UtilFunctions';

/* Styles */
import { normalize, normalizeHeight } from '@style/helper';

/* Screen Import */
import Loading from '@screens/Loading';

const TransactionFeed = ({ loading, data, setOnEndReachedCalledDuringMomentum, isLoadingFooter, onEndReached }) => {
  const head = ['Tanggal\nTransaksi', 'Jumlah Penarikan\n(Rp)', 'Biaya Admin\n(Rp)', 'Jumlah Diterima\n(Rp)'];

  const renderFooter = () => {
    if (isLoadingFooter) {
      return (
        <View
          style={{
            height: 50,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <ActivityIndicator size="small" color={'#3863F0'} />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <View style={styles.headerContainer}>
            <FlatList
              data={head}
              keyExtractor={(item, index) => item.key}
              contentContainerStyle={{ flexDirection: 'row' }}
              renderItem={(item) => {
                return <Text style={styles.header}>{item.item}</Text>;
              }}
            />
          </View>
          {data && (
            <View style={{ width: '100%', paddingBottom: 20 }}>
              <FlatList
                ListFooterComponent={renderFooter}
                data={data}
                onMomentumScrollBegin={setOnEndReachedCalledDuringMomentum}
                keyExtractor={(item, index) => item.id}
                contentContainerStyle={{ width: '100%' }}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.1}
                renderItem={(item) => {
                  return (
                    <View style={styles.tableRowContainer}>
                      <Text style={styles.createdAt}>{moment(item.item.createdAt).format('DD/MM')}</Text>
                      <View style={styles.tableRow}>
                        <Text accessibilityLabel="requestAmountText" style={styles.rowAmount}>
                          {numberWithDots(item.item.amount)}
                        </Text>
                        <Text
                          accessibilityLabel="reasonText"
                          style={[
                            styles.rowReason,
                            { backgroundColor: item.item.reasonColor, borderColor: item.item.reasonColor },
                          ]}
                        >
                          {item.item.reason.substring(0, 17)}...
                        </Text>
                      </View>
                      <Text accessibilityLabel="adminFeeText" style={styles.rowFee}>
                        {numberWithDots(item.item.fee)}
                      </Text>
                      <View style={styles.tableRowTwo}>
                        <Text accessibilityLabel="receivedAmountText" style={styles.rowTotal}>
                          {numberWithDots(item.item.total)}
                        </Text>
                        <Text
                          style={[
                            styles.rowStatus,
                            {
                              color:
                                item.item.status.toUpperCase() === 'COMPLETED' ||
                                item.item.status.toUpperCase() === 'INPROGRESS'
                                  ? '#19c72a'
                                  : item.item.status.toUpperCase() === 'PENDING'
                                  ? '#3863F0'
                                  : '#f6430a',
                            },
                          ]}
                        >
                          {item.item.status.toUpperCase() === 'INPROGRESS' ? 'COMPLETED' : item.item.status}
                        </Text>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    backgroundColor: '#F7F7F7',
    height: normalizeHeight(55),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    paddingTop: 10,
    paddingLeft: 10,
    textAlign: 'center',
    marginTop: 0,
    minHeight: normalizeHeight(60),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: normalize(11),
    fontFamily: 'Nunito-Bold',
    color: '#454545',
    maxWidth: normalize(70),
    marginHorizontal: 6,
    fontSize: normalize(12),
    flex: 0.25,
    textAlign: 'center',
    marginRight: 10,
  },
  createdAt: { fontSize: normalize(12), alignSelf: 'center', flex: 1, paddingLeft: 20 },
  tableRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingVertical: 10,
    height: normalizeHeight(60),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1.9,
  },
  tableRowTwo: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
    paddingRight: 10,
  },
  rowAmount: { fontSize: normalize(12), alignSelf: 'center' },
  rowReason: {
    fontSize: normalize(15),
    alignSelf: 'center',
    marginTop: 7,
    padding: 3,
    borderRadius: 5,
    borderWidth: 1,
    overflow: 'hidden',
    color: 'white',
    fontSize: normalize(11),
  },
  rowFee: { fontSize: normalize(12), flex: 1, paddingLeft: 20 },
  rowTotal: { fontSize: normalize(12), alignSelf: 'center' },
  rowStatus: {
    fontSize: normalize(10),
    alignSelf: 'center',
    textTransform: 'uppercase',
    marginTop: 7,
    fontFamily: 'Nunito-Bold',
  },
});

export default TransactionFeed;
