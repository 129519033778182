import * as Sentry from '@sentry/browser';

export const SENTRY_BUG_CONSTANTS = {
  refreshTokenFailed: 'refreshTokenFailed',
  marketingBannerFailed: 'marketingBannerFailed',
  storiesError: 'storiesError',
};

export const SentryHelper = {
  setUser: (id, employerId) => {
    Sentry.setUser({ id, username: id, segment: employerId });
  },
  captureException: (exception) => {
    Sentry.captureException(exception);
  },
  captureCustomException: (name = null, params = null) => {
    if (name === null || params === null) return;
    Sentry.captureException(new Error(name), {
      tags: {
        ...params,
      },
    });
  },
  clearUser: () => {
    // Call During logout
    Sentry.configureScope((scope) => scope.setUser(null));
  },
};
