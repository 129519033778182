import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
const styles = StyleSheet.create({
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 15,
  },
  undlerlineText: {
    fontFamily: Font.Nunito_Bold,
    color: colors.blue,
    textDecorationLine: 'underline',
  },
});

export default styles;
