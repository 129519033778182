import React, { useEffect, useMemo, useState } from 'react';
import styles from './style';
import { colors } from '@style/colors';
import InfoTextComponent from '@atoms/InfoTextComponent';
import LoadingButton from '@atoms/LoadingButton';
import { DropDownSelectComponent } from '@atoms/DropDownSelector';
import TextInputComponent from '@components/TextInputComponent';
import MainParentWrapper from '@components/MainParentWrapper';
import { useMutation } from '@apollo/client';
import { CREATE_BANK_ACCOUNT } from '@gqlQuery/BankAccount';
import { useDispatch, useSelector } from 'react-redux';
import { BOTTONSHEET_KEYS } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { BOTTOMSHEET_CONTENT } from '@utils/Constants';
import {
  createBankAccountCompleteCallBack,
  onPressSubmitCallBack,
  returnBottomSheetContent,
  saveButtonCallBack,
  setSelectedBankCallBack,
} from '@utils/UtilFunctions';
import BottomSheet from '@components/LoginInfoSheet';
import { createNewBankAccount } from '@redux/action/UserBanks';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { respWarn } from '@redux/action/Common';
import PropTypes from 'prop-types';
import Arrow from '@expo/vector-icons/MaterialIcons';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';

const BankCreateDetail = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { source } = route.params;
  const [selectedBank, setSelectedBank] = useState(null);
  const [accountNumber, setAccountNumber] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [nickName, setNickName] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const { employeeId } = useSelector((state) => state.Authentication);
  const { userBanks } = useSelector((state) => state.UserBank);
  const dispatch = useDispatch();
  const logEvent = (event) => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration[event.name];
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: source,
    });
  };

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
      BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.DUPLICATE_ENTRY],
    ),
    errorCode: BOTTONSHEET_KEYS.DUPLICATE_ENTRY,
  });

  const [createBank] = useMutation(CREATE_BANK_ACCOUNT, {
    onCompleted: createBankAccountCompleteCallBack(
      userBanks,
      accountNumber,
      beneficiaryName,
      selectedBank,
      nickName,
      dispatch,
      setSubmitLoading,
      () => {
        navigation.navigate('BankStack', {
          screen: 'BankList',
          params: { source: AMPLITUDE_EVENT_SOURCES.others_page },
        });
      },
    ),
    fetchPolicy: 'no-cache',
    onError: (err) => {
      const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
        [e.attributes.failure_message]:
          AMPLITUDE_EVENT_SOURCES.bankRegistration.failureMessage.bank_account_already_stored,
      });
      if (err.message === 'GG-4091' || err.message === 'GG-4092' || err.message === 'GG-4092')
        setInfoModal({ ...infoModal, visible: true });

      setSubmitLoading(false);
    },
  });

  const saveButtonEnabled = useMemo(saveButtonCallBack(selectedBank, accountNumber, beneficiaryName), [
    selectedBank,
    accountNumber,
    beneficiaryName,
    nickName,
  ]);

  useEffect(() => {
    setSelectedBank(route.params?.selectedBank ? JSON.parse(route.params?.selectedBank) : null);
    setAccountNumber(route.params?.accountNumber ?? '');
    setBeneficiaryName(route.params?.beneficiaryName ?? '');
    setNickName(route.params?.nickName ?? '');
  }, [route.params]);

  const onPressSubmit = onPressSubmitCallBack(
    logEvent,
    setSubmitLoading,
    createBank,
    accountNumber,
    selectedBank,
    beneficiaryName,
    nickName,
    employeeId,
  );

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: source,
    });
  }, []);

  return (
    <MainParentWrapper isScrollable containerStyle={{ padding: 0, paddingHorizontal: 20, paddingVertical: 10 }}>
      <DropDownSelectComponent
        onPress={() => {
          logEvent(AMPLITUDE_CONSTANTS.bankRegistration.bank_name_clicked);
          navigation.navigate('BankSelectionList', { source });
        }}
        title="Nama Bank"
        placeholder="Pilih Bank"
        selectedItem={selectedBank?.bankName ?? ''}
        icon={<Arrow name="keyboard-arrow-right" size={25} color={colors.black} />}
      />
      <TextInputComponent
        title={'Nomor Rekening'}
        isMandatory
        textInputProps={{
          placeholder: 'Contoh: 3216549870',
          numberOfLines: 1,
          onChangeText: (text) => {
            navigation.setParams({ accountNumber: text });
            setAccountNumber(text);
          },
          value: accountNumber,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.account_number_clicked),
          onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.account_number_filled),
          placeholderTextColor: colors.philippineGray,
        }}
      />
      <TextInputComponent
        title={'Nama Pemilik Rekening'}
        isMandatory
        textInputProps={{
          placeholder: 'Contoh: Mardiasih',
          numberOfLines: 1,
          onChangeText: (text) => {
            navigation.setParams({ beneficiaryName: text });
            setBeneficiaryName(text);
          },
          value: beneficiaryName,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.beneficiary_name_clicked),
          onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.beneficiary_name_filled),
          placeholderTextColor: colors.philippineGray,
        }}
      />
      <TextInputComponent
        title={'Label Pemilik Rekening (optional)'}
        textInputProps={{
          placeholder: 'Contoh: Rek Ayah, Rek Tagihan',
          numberOfLines: 1,
          onChangeText: (text) => {
            navigation.setParams({ nickName: text });
            setNickName(text);
          },
          value: nickName,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.nickname_clicked),
          onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.nickname_clicked),
          placeholderTextColor: colors.philippineGray,
        }}
      />
      <LoadingButton
        container={styles.loadingButton}
        textStyle={styles.loadingButtonText}
        buttonColor={colors.orange}
        text={'Simpan Data Rekening'}
        textColor={colors.white}
        textTransform="none"
        disabled={!saveButtonEnabled}
        disabledStyle={styles.disabledButton}
        onPress={onPressSubmit}
        loading={submitLoading}
      />
      <InfoTextComponent
        iconColor={colors.black}
        infoText="Data rekeningmu akan diverikasi manual oleh tim GajiGesa. Percepat proses verifikasi dengan menghubungi tim support GajiGesa, atau tunggu maks. 3 x 24 jam."
      />
      <BottomSheet
        onPressPrimary={() => setInfoModal({ ...infoModal, visible: false })}
        loginInfo={infoModal}
        onClose={() => setInfoModal({ ...infoModal, visible: false })}
      />
    </MainParentWrapper>
  );
};

BankCreateDetail.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

BankCreateDetail.defaultProps = {};

export default BankCreateDetail;
