import React, { useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { formatUnderScoreLowerCase, numberWithDots } from '@utils/UtilFunctions';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '@services/BillPaymentApi';

/* Style Import */
import { subTitleText, titleText } from '@style/Layouts';
import { LgButton } from '@components/Button';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';

const PaketContent = ({ handleClose }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const phoneNumber = useSelector((state) => state.Authentication.phoneNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.detailsModal;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: phoneNumber,
      [e.attributes.product_price]: selectedProduct?.item?.amount,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.item?.categoryPrefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.item?.code),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
    });
  }, []);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.section}>
          <Text style={[subTitleText, { marginBottom: 0, fontFamily: 'Nunit-Regular' }]}>Detail Produk</Text>
          <Text style={[titleText, { marginLeft: 25, fontFamily: 'Nunito-Regular' }]}>
            {selectedProduct?.item?.name}
          </Text>
          <Text style={[subTitleText, { marginTop: 20, fontFamily: 'Nunito-Bold' }]}>
            Harga: Rp. {numberWithDots(selectedProduct?.item?.amount)} + GajiGesa Fee
          </Text>
          <LgButton
            title="Konfirmasi"
            onPress={() => {
              handleClose();
              dispatch(selectProduct(selectedProduct?.item));
            }}
            style={styles.button}
          />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: 'white', height: '100%', alignItems: 'center' },
  section: { alignSelf: 'flex-start', maxWidth: '90%' },
  button: { alignSelf: 'center', marginLeft: '15%', marginTop: 35, width: 250 },
});

export default PaketContent;
