import { StyleSheet, Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  text5: {
    marginHorizontal: 50,
    textAlign: 'center',
  },
  buttonStyle: {
    position: 'absolute',
    bottom: 25,
    borderRadius: 30,
  },
  cardTitle: {
    color: '#3B6AC0',
    alignSelf: 'center',
    fontSize: normalize(24),
    marginBottom: 10,
    fontFamily: Font.Nunito_Bold,
  },
  amountText: {
    alignSelf: 'center',
    marginTop: 0,
    color: '#f56b57',
    fontSize: normalize(35),
    marginBottom: 0,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  container: {
    width: screenWidth / 1.1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginTop: normalizeHeight(25),
    flex: 1,
  },
  logoContainer: {
    alignItems: 'center',
  },
  logo: {
    padding: 30,
    alignSelf: 'center',
    marginTop: normalizeHeight(20),
  },
  background: {
    backgroundColor: '#3863F0',
    height: screenHeight / 4,
    width: screenWidth,
    position: 'absolute',
    right: 0,
  },
  scene: {
    flex: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    elevation: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: '#F56B57',
    borderRadius: 30,
    paddingHorizontal: normalize(10),
    paddingVertical: normalizeHeight(7),
    width: normalize(220),
    marginBottom: normalize(10),
    marginTop: normalize(20),
  },
  buttonText: {
    color: colors.white,
    fontFamily: Font.Nunito_Bold,
    fontSize: normalize(14),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  regularBlack: {
    fontSize: normalize(14),
    lineHeight: normalizeHeight(27),
    fontFamily: Font.Nunito_Regular,
    color: colors.black,
  },
  alignRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  boldBlue: {
    fontSize: normalize(24),
    lineHeight: normalizeHeight(33),
    fontFamily: Font.Nunito_Bold,
    color: colors.blue,
  },
  boldBlack: {
    fontSize: normalize(20),
    lineHeight: normalizeHeight(27),
    fontFamily: Font.Nunito_Bold,
    color: colors.black,
  },
  subContainer: {
    alignItems: 'center',
    marginTop: normalizeHeight(15),
  },
  subText: {
    textAlign: 'center',
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(18),
    lineHeight: normalizeHeight(24),
  },
});

export default styles;
