import AsyncStorage from '@react-native-async-storage/async-storage';
import AmplitudeHelper from '@services/amplitude';
import { SentryHelper } from '@utils/SentryHelper';

function logoutCallBack() {
  AsyncStorage.multiRemove([
    '@token',
    '@phoneNumber',
    '@refreshToken',
    '@userName',
    '@employeeId',
    '@employerId',
    '@userRoles',
    '@isGajiTim',
    '@bURL',
    '@apiKey',
    '@userId',
    '@featureFlag',
    '@referralLink',
    '@isRedeemed',
    '@redeemCode',
    '@stories',
    '@dynamicAccounts',
    '@sdInfoShown',
    '@seenProfile',
    '@seenLoan',
    '@seenZakat',
  ]);
  AmplitudeHelper.clearUserProperties();
  SentryHelper.clearUser();
}

export default logoutCallBack;
