import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
import { Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import UserBankList from '@screens/UserBankList';
import BankCreateDetail from '@screens/BankCreateDetail';
import BankSelectionList from '@screens/BankSelectionList';
import SalaryTransfer from '@screens/SalaryTransfer';
import RegisteredBankSelection from '@screens/RegisteredBankSelection';
import { useNavigation } from '@react-navigation/native';
const { height: screenHeight } = Dimensions.get('window');
const Stack = createStackNavigator();

const BankStack = () => {
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: colors.blue,
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: colors.blue,
      height: screenHeight / 15,
      elevation: 0,
    },
    headerTitleStyle: {
      fontFamily: Font.Nunito_Bold,
      fontSize: normalize(14),
    },
  };
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="BankList"
        component={UserBankList}
        options={{ headerTitle: 'Rekening Bank Terdaftar', ...optionStyle }}
      />
      <Stack.Screen
        name="BankCreateDetail"
        component={BankCreateDetail}
        options={{ headerTitle: 'Tambah Rekening Bank', ...optionStyle }}
      />
      <Stack.Screen
        name="BankSelectionList"
        component={BankSelectionList}
        options={{ headerTitle: 'Tambah Rekening Bank', ...optionStyle }}
      />

      <Stack.Screen
        name="SalaryTransfer"
        component={SalaryTransfer}
        options={{ headerTitle: 'Transfer', ...optionStyle }}
      />
      <Stack.Screen
        name="RegisteredBankSelection"
        component={RegisteredBankSelection}
        options={{ headerTitle: 'Pilih Rekening', ...optionStyle }}
      />
    </Stack.Navigator>
  );
};

export default BankStack;
