import React from 'react';
import CardUpdate from '../CardUpdate';

const UpdateApp = ({ updateType }) => {
  if (updateType) {
    return <CardUpdate />;
  }
  return false;
};

export default UpdateApp;
