import React, { useEffect, useState } from "react"
import { View, Text, Dimensions, FlatList, Pressable } from 'react-native'
import { Font } from "@style/Fonts"
import { NoData } from "../shared";
import translate from "../translate";
import { styles } from "./style";
import { useIsFocused } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { setActiveSavingDetailsTab } from "@redux/action/Common";


interface IIData {
  id: number,
  type: string,
  value: string | number,
  style?: any,
  color?: string,
  height?: number,
}

interface IData {
  id: number,
  data: IIData[]
}

const { height: screenHeight, width: screenWidth } = Dimensions.get('window')
const maxHeight = 200;
const t = translate();
const Summary = (props: any) => {
  const [selectedCategory, setSelectCategory] = useState(null);
  const [data, setData] = useState([]);
  const isFocused = useIsFocused();
  const dispatch = useDispatch()
  const colorSet = [{ type: 'tokenpln', title: 'Token PLN', color: '#77BE43' },
  { type: 'pulsa', title: 'Pulsa', color: '#F8B24F' },
  { type: 'campaign', title: 'Campaign', color: '#8F4399' },
  { type: 'voucher', title: 'Voucher', color: '#6AA0F6' },]

  const getMaxValue = (data) => {
    return data?.reduce((max, item) => {
      const sum = item?.data.reduce((a, b) => {
        const _num: number = Number(b.value)
        return b.type === 'chart' ? a + _num : a;
      }, 0) || 0;
      return max < sum ? sum : max;
    }, 0) || 0;
  }

  const getGraphData = (data, maxValue) => {
    return data?.map((item: any, index) => {
      let sum = item.data.reduce((a, b) => {
        const _num: number = Number(b.value)
        return b.type === 'chart' ? a + _num : a;
      }, 0);
      if (item.data.length === 3 && sum / maxValue < .005) {
        sum = sum * maxValue * .005
      }
      const remaining = maxValue - sum;
      item.data.unshift({ id: remaining + index, type: 'chart', value: remaining, color: "#fff", style: {} })
      item.data = item.data.map((_item, index) => {
        if (_item.type === 'chart') {
          const _num: number = Number(_item.value)
          let value = _num / maxValue;
          if (item.data.length === 4 && value < .005) {
            value = _num * .005;
          }
          const height = value * maxHeight
          _item.height = height;
        }
        return _item;
      })
      return item;
    }) || []
  }

  useEffect(() => {
    const data = props.graphData;
    const maxValue = getMaxValue(data);

    const newData = getGraphData(data, maxValue)
    setData(newData);
  }, [props.graphData])



  useEffect(() => {


    if (isFocused) {
      //dispref

      dispatch(setActiveSavingDetailsTab("summary"))

    }

  }, [isFocused])

  const renderItem1 = ({ item, index }: any) => {

    switch (item.type) {
      case 'chart': return (
        <Pressable onPress={() => setSelectCategory((item?.amount && item.id !== selectedCategory) ? item.id : null)}>
          <View style={[{
            height: item.height,
            width: screenWidth / (7),
            marginHorizontal: screenWidth / (25),
            backgroundColor: item.color,
            overflow: 'hidden',
            justifyContent: 'center',
            opacity: selectedCategory && selectedCategory !== item.id ? .2 : 1
          }, item?.style ?? {}]}>
            {selectedCategory && selectedCategory === item.id && <Text style={{
              fontFamily: Font.Nunito_Regular,
              fontSize: 8,
              lineHeight: 10,
              textAlign: 'center',
              color: '#ffffff',
              letterSpacing: .25,
              padding: 4,
            }}>
              {item.amount}
            </Text>}
          </View>
        </Pressable>
      )
      case 'line': return (<View style={{
        height: 1,
        width: "100%",
        backgroundColor: "#D4D4D4",
        color: "#F0F0F0",
        marginVertical: 10,
      }} />)
      case 'label': return (
        <View>
          <Text style={[{
            fontFamily: Font.Nunito_Regular,
            fontSize: 8,
            lineHeight: 10,
            textAlign: 'center',
          }, item?.style ?? {}]}>
            {item.value}
          </Text>
        </View>
      )
      default: return <View style={item?.style}>{item?.value}</View>;
    }
  }


  const renderItem = ({ item, index }: any) => {
    return (
      <View style={{ marginVertical: 0, flex: 1 }}>
        <FlatList
          data={item?.data}
          renderItem={renderItem1}
          keyExtractor={item => `child_${item.id}`}
        // ItemSeparatorComponent={() => <View style={{height: 2, backgroundColor: Colors.grey, width: 200,}}/>}
        />
      </View>
    )
  }
  if (props.loadingSaving) return (<NoData text='`Loading graph...`' />)
  if (!data.length) return (<NoData text={`Kamu tidak memiliki transaksi \npada periode ini.`} />)

  return (
    <View style={styles.scene}>
      <View style={styles.container}>
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={item => `parent_${item.id}`}
          horizontal
        />
      </View>
      <View style={styles.textContainer}>
        <View style={styles.row}>
          {colorSet.map((set, index) => (
            <View key={set.color} style={styles.colorContainer}>
              <View style={[styles.colorBox, { backgroundColor: set.color }]} />
              <Text style={styles.colorTitle}>{set.title}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.colorSubTitle}>{t['graph_warning']}</Text>
      </View>
    </View>
  )
}

export default Summary;