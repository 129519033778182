import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native';
import AmplitudeHelper from '@services/amplitude';

/* Style Import */
import { normalize } from '@style/helper';

const CardUpdate = () => {
  return (
    <View style={styles.container}>
      <View style={styles.titleSection}>
        <Text style={styles.title}>Update GajiGesa versi terbaru!</Text>
        <Text style={styles.subTitle}>Segera update aplikasi untuk menikmati layanan yang lebih baik.</Text>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity testID="btn_update_pwa" style={styles.button}>
          <Text
            onPress={() => {
              AmplitudeHelper.logEvent('buttonUpdate');
              window.location.reload();
            }}
            style={styles.buttonText}
          >
            update
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: '5%',
    marginVertical: '2%',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#FFE58F',
    backgroundColor: '#FFFBE6',
    paddingVertical: '2%',
    paddingHorizontal: '4%',
    flexDirection: 'row',
  },
  titleSection: { justifyContent: 'flex-start', flex: 0.68 },
  title: { color: '#454545', fontSize: normalize(12), fontFamily: 'Nunito-Bold' },
  subTitle: { color: '#454545', fontSize: normalize(12), fontFamily: 'Nunito-Regular' },
  buttonContainer: { flex: 0.3, alignSelf: 'center' },
  button: { backgroundColor: '#F56B57', borderRadius: 5, paddingVertical: '8%' },
  buttonText: {
    color: '#ffffff',
    alignSelf: 'center',
    fontSize: normalize(12),
    textTransform: 'uppercase',
    fontFamily: 'Nunito-Bold',
  },
});

export default CardUpdate;
