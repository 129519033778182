import { StyleSheet, Dimensions } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  text5: {
    marginHorizontal: 50,
    textAlign: 'center',
  },
  buttonStyle: {
    position: 'absolute',
    bottom: normalizeHeight(60),
    marginBottom: '10%',
    borderRadius: 30,
  },
  buttonStylePurchased: {
    position: 'absolute',
    bottom: normalizeHeight(15),
    marginBottom: '10%',
    borderRadius: 30,
  },
  cardTitle: {
    color: colors.secondary_orchid,
    alignSelf: 'center',
    fontSize: normalize(24),
    marginBottom: 10,
    fontFamily: Font.Nunito_Bold,
  },
  amountText: {
    alignSelf: 'center',
    marginTop: 0,
    color: colors.primary_carnation,
    fontSize: normalize(35),
    marginBottom: 0,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  container: {
    width: screenWidth / 1.1,
    backgroundColor: colors.neutral_cotton,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginTop: normalizeHeight(25),
    borderRadius: 15,
    marginBottom: '5%',
    flex: 1,
  },
  logoContainer: {
    flex: 1,
  },
  logo: {
    padding: 30,
    alignSelf: 'center',
    marginTop: normalizeHeight(20),
  },
  background: {
    backgroundColor: colors.secondary_orchid,
    height: screenHeight / 4,
    width: screenWidth,
    position: 'absolute',
    right: 0,
  },
  scene: {
    flex: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    elevation: 8,
    backgroundColor: colors.primary_carnation,
    borderRadius: 30,
    paddingHorizontal: normalize(10),
    paddingVertical: normalizeHeight(7),
    width: normalize(220),
    marginBottom: normalize(10),
    marginTop: normalize(20),
  },
  buttonText: {
    color: colors.white,
    fontFamily: Font.Nunito_Bold,
    fontSize: normalize(14),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  regularBlack: {
    fontSize: normalize(14),
    lineHeight: normalizeHeight(27),
    fontFamily: Font.Nunito_Regular,
    color: colors.neutral_charcoal,
  },
  alignRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  boldBlue: {
    fontSize: normalize(24),
    lineHeight: normalizeHeight(33),
    fontFamily: Font.Nunito_Bold,
    color: colors.secondary_orchid,
  },
  boldBlack: {
    fontSize: normalize(20),
    lineHeight: normalizeHeight(27),
    fontFamily: Font.Nunito_Bold,
    color: colors.neutral_charcoal,
  },
  subContainer: {
    alignItems: 'center',
    marginTop: normalizeHeight(15),
  },
  subText: {
    textAlign: 'center',
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(18),
    lineHeight: normalizeHeight(24),
  },
  voucherClip: {
    borderWidth: 1,
    marginTop: normalize(12),
    borderColor: colors.foundationDarkShade,
    flexDirection: 'row',
    width: '85%',
    alignSelf: 'center',
    borderStyle: 'dashed',
    borderRadius: normalize(12),
  },
  presableContainer: { width: '80%', paddingLeft: normalize(15) },
  voucherLable: { fontSize: normalize(12), marginTop: normalize(12) },
  voucherCodeStyle: { fontSize: normalize(16), marginTop: normalize(4), marginBottom: normalize(12) },
  clipBoardontainer: { width: '20%', alignItems: 'center', justifyContent: 'center' },
  displayContainer: { marginTop: normalize(24), flex: 1 },
  vTextStyle: { color: colors.primary_carnation, textAlign: 'center', fontSize: normalize(16) },
  tagStyle: { fontSize: normalize(14), alignSelf: 'center', marginTop: normalize(12) },
  transkiText: {
    color: colors.neutral_charcoal,
    fontSize: normalize(16),
    textAlign: 'center',
    marginTop: normalize(8),
  },
  vPurchasedContainer: { marginTop: normalize(24), flex: 1 },
});

export default styles;
