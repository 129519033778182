import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import React, { useMemo } from 'react';
import { getImagePickerItemIcon } from '@utils/UtilFunctions';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { View, Pressable, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

const ImagePickerItem = ({ onPress, title, imageUri, onPressCancel }) => {
  const componentTheme = useMemo(() => {
    const theme = {
      pressableStyle: styles.button,
      titleStyle: null,
    };
    if (imageUri?.failed) {
      theme.pressableStyle = styles.failedButton;
    }
    if (imageUri?.uploaded) {
      theme.pressableStyle = styles.completedButton;
      theme.titleStyle = styles.name;
    }
    return theme;
  }, [imageUri]);

  const showCloseButton = imageUri?.uploading || imageUri?.uploaded || imageUri?.failed;

  return (
    <View style={styles.container}>
      <Pressable onPress={onPress} style={componentTheme.pressableStyle} android_ripple={{ color: colors.greyShade }}>
        <MyText customStyle={componentTheme.titleStyle}>
          {imageUri ? 'Bukti.png' : title}{' '}
          {imageUri?.failed && (
            <MyText isRegular={false} customStyle={styles.failedText}>
              (Gagal Upload)
            </MyText>
          )}
        </MyText>
        {getImagePickerItemIcon(imageUri, MaterialIcon, ActivityIndicator)}
      </Pressable>
      {showCloseButton && (
        <MaterialIcon size={20} onPress={onPressCancel} style={styles.cancel} name="close" color={colors.red} />
      )}
    </View>
  );
};

ImagePickerItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  imageUri: PropTypes.shape({
    failed: PropTypes.bool,
    uploaded: PropTypes.bool,
    uploading: PropTypes.bool,
    uri: PropTypes.string,
  }),
  onPressCancel: PropTypes.func.isRequired,
};

ImagePickerItem.defaultProps = {
  imageUri: undefined,
};

export default ImagePickerItem;
