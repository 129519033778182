import React from 'react';
import { Text, View, FlatList, Pressable } from 'react-native';
import { CustomReasons } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { excludeReasonsDisplay } from '@utils/UtilFunctions';
import { colors } from '@style/colors';
import PropTypes from 'prop-types';
import styles from './styles';

const ReasonsGrid = ({ reasons, handleSelect, selectedReason }) => {
  const renderItem = ({ item }) => {
    if (excludeReasonsDisplay(item)) return null;

    const isSelected = item.reason === selectedReason;
    const svgProp = !isSelected ? { fill: colors.blue } : { fill: colors.orange };
    //CORE-1215: Showing default image if reason added to back end
    const Component = CustomReasons[item.id] ? CustomReasons[item.id] : CustomReasons[18];

    return (
      <Pressable
        onPress={() => {
          handleSelect(item);
        }}
        android_ripple={{ color: colors.lightGrey }}
      >
        <View style={[styles.item, isSelected ? styles.highlight : null]}>
          <View
            style={[
              styles.circleBox,
              isSelected
                ? { backgroundColor: colors.orange }
                : {
                    borderWidth: 1,
                    borderColor: colors.grey,
                    backgroundColor: colors.white,
                  },
            ]}
          />
          <View style={[styles.itemIcon, isSelected ? { borderColor: colors.orange } : null]}>
            <Component />
          </View>
          <View>
            <Text numberOfLines={2} style={styles.itemText}>
              {item.reason}
            </Text>
          </View>
        </View>
      </Pressable>
    );
  };
  return (
    <FlatList
      contentContainerStyle={styles.list}
      data={reasons}
      numColumns={3}
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderItem}
    />
  );
};

ReasonsGrid.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelect: PropTypes.func,
  selectedReason: PropTypes.string,
};

ReasonsGrid.defaultProps = {
  reasons: [{}],
  handleSelect: () => {},
  selectedReason: '',
};

export default ReasonsGrid;
