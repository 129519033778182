import React, { useState, useEffect } from 'react';
import { Text, View, Dimensions, StyleSheet } from 'react-native';
import AIcon from '@expo/vector-icons/AntDesign';
import TransactionService from '@services/TransactionService';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import moment from 'moment';

/* Styles */
import { centerView, subTitleText } from '@style/Layouts';
import { background, XlCard } from '@style/Themes';
const { height: screenHeight } = Dimensions.get('window');
import { normalizeHeight } from '@style/helper';

/* Screen Import */
import AmplitudeHelper from '@services/amplitude';
import * as Sentry from '@sentry/browser';

/* Redux */
import { useDispatch } from 'react-redux';
import { respError } from '@redux/action/Common';

/* Components */
import TransactionFeed from '@components/TransactionFeed';
import TransactionMonthlyFeed from '@components/TransactionMonthlyFeed';
import TransactionTabView from '@components/TransactionTabView';
import ParentWrapper from '@components/ParentWrapper';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const Transaksi = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const [currentTab, setCurrentTab] = useState('feed');
  const [data, setData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(false);
  const [isLoadingFooter, setIsLoadingFooter] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    AmplitudeHelper.logEvent('transactionPage');

    getTransactionFeed();
  }, []);

  useEffect(() => {
    getTransactionFeed();
  }, [isFocused]);
  const [error, setError] = useState(false);

  useEffect(() => {
    firstLoad();
  }, []);
  useEffect(() => {
    refreshPage();
  }, [isFocused]);

  const firstLoad = async () => {
    try {
      setError(false);
      AmplitudeHelper.logEvent('transactionPage');
      await getTransactionMonthFeed();
      await getTransactionFeed();
    } catch (error) {
      setError(true);
    }
  };

  const refreshPage = async () => {
    try {
      setError(false);
      setLoading(true);
      await getTransactionFeed();
    } catch (error) {
      setError(true);
    }
  };

  const getTransactionFeed = (limit = 15, page = 1, flag = 'INITAL_LOAD') => {
    flag !== 'LOAD_MORE' && setLoading(true);
    let startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    let endDate = moment().add(1, 'day').format('YYYY-MM-DD');
    TransactionService.getTransactions(startDate, endDate, [], limit, page)
      .then((resp) => {
        setIsLoadingFooter(false);
        if (resp.status == 200 || resp.status == 201) {
          if (resp.data.message == 'Data transaksi tidak ditemukan') {
            setIsLoadingFooter(false);
            setLoading(false);
            return;
          } else {
            setLoading(false);
            if (flag === 'LOAD_MORE') {
              setData(data.concat(resp.data.data.rows));
              setPage(page);
            } else {
              setData(resp.data.data.rows);
            }
            setTotalPages(resp?.data?.data?.totalPage ?? 0);
            setIsLoadingFooter(false);
          }
          setIsLoadingFooter(false);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      })
      .catch((e) => {
        setIsLoadingFooter(false);
      });
  };

  const getTransactionMonthFeed = async () => {
    try {
      setLoading(true);
      let resp = await TransactionService.getTransactionMonthly();
      if (resp.status) {
        setLoading(false);
        setMonthData(resp.data.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      throw error;
    }
  };

  return (
    <View style={[centerView, background, { borderTopWidth: screenHeight / 1.8 }]}>
      <TransactionTabView currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <View style={[styles.renderContainer, XlCard]}>
        <ParentWrapper loading={loading} isError={error} onReload={refreshPage}>
          {currentTab === 'feed' ? (
            <TransactionFeed
              loading={loading}
              data={data}
              setOnEndReachedCalledDuringMomentum={() => setOnEndReachedCalledDuringMomentum(false)}
              isLoadingFooter={isLoadingFooter}
              onEndReached={() => {
                if (!onEndReachedCalledDuringMomentum) {
                  if (!loading && !isLoadingFooter) {
                    if (totalPages !== page) {
                      setIsLoadingFooter(true);
                      getTransactionFeed(15, page + 1, 'LOAD_MORE');
                    }
                  }
                  setOnEndReachedCalledDuringMomentum(true);
                }
              }}
            />
          ) : currentTab === 'monthly' ? (
            <TransactionMonthlyFeed monthData={monthData} navigation={navigation} />
          ) : (
            <Text style={subTitleText}>Ringkasan Transaksi</Text>
          )}
        </ParentWrapper>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  renderContainer: {
    flex: 1,
    alignItems: 'center',
    top: screenHeight > 800 ? -(screenHeight / 1.8) : -(screenHeight / 1.9),
    height: screenHeight > 700 ? normalizeHeight(screenHeight / 1.8) : normalizeHeight(screenHeight / 1.7),
    maxHeight: screenHeight > 700 ? normalizeHeight(480) : normalizeHeight(380),
    overflow: 'hidden',
    paddingBottom: 50,
  },
});

export default Transaksi;
