import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    alignItems: 'flex-start',
  },
  cardContainer: {
    width: '90%',
    marginBottom: 15,
  },
  button: {
    paddingVertical: 10,
    width: '100%',
    justifyContent: 'center',
    borderRadius: 30,
  },
  disabledButton: {
    opacity: 0.5,
  },
  textAmount: { marginTop: 5 },
});

export default styles;
