import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import style from './style';

const VoucherPunch = ({ noOfPunch, pillarStyle, circleStyle }) => (
  <View style={[style.pillarStyle, pillarStyle]}>
    {[...Array(noOfPunch).keys()].map((e) => (
      <View key={e} style={[style.circleStyle, circleStyle]} />
    ))}
  </View>
);

VoucherPunch.propTypes = {
  noOfPunch: PropTypes.number,
  pillarStyle: PropTypes.object,
  circleStyle: PropTypes.object,
};
VoucherPunch.defaultProps = {
  noOfPunch: 4,
};
export default VoucherPunch;
