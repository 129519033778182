import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text, TextInput, View } from 'react-native';
import Close from '@expo/vector-icons/AntDesign';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { styles } from './style';
// import { AMPLITUDE_CONSTANTS } from '../../Utils/AmplitudeConstants';
// import AmplitudeHelper from '../../services/amplitude';

const PhoneNumberInput = ({
  title,
  errorMessage,
  isError,
  phoneNumber,
  setPhoneNumber,
  setInputFocused,
  inputFocused,
  disabled,
  styleTitle,
  mandatory,
  showClear,
  otpLoadingType,
  hasBorderWidth,
  textInputContainer,
  prefixTextStyle,
  labelTxtStyle,
  onFocus,
  onBlur,
}) => {
  const clearInput = () => {
    if (otpLoadingType !== null) return;
    setPhoneNumber('');
  };

  const setFocus = () => {
    setInputFocused(true);
    onFocus();
  };

  const removeFocus = () => {
    setInputFocused(false);
    onBlur();
  };

  const onEndEditing = () => {
    // const e = AMPLITUDE_CONSTANTS.authentication.phone_number_filled;
    // AmplitudeHelper.logEvent(e.name);
  };

  const getBorderColor = useCallback(() => {
    if (inputFocused) return colors.blue;
    if (isError) return colors.brightred;
    return colors.philippineGray;
  }, [inputFocused, isError]);

  return (
    <View style={styles.mainContainer}>
      <MyText customStyle={{ ...styleTitle, ...labelTxtStyle }}>
        {title}
        <MyText customStyle={styles.mandatoryText}>{mandatory ? '*' : ''}</MyText>
      </MyText>
      <View
        style={{
          ...styles.inputContainer,
          borderColor: getBorderColor(),
          backgroundColor: disabled ? colors.gainsboro : colors.white,
          borderWidth: hasBorderWidth ? 1 : 0,
        }}
      >
        <MyText h3 isRegular={false} customStyle={prefixTextStyle}>
          +62
        </MyText>
        <TextInput
          keyboardType="number-pad"
          numberOfLines={1}
          style={[styles.textInput, textInputContainer]}
          onFocus={setFocus}
          onBlur={removeFocus}
          value={phoneNumber}
          onChangeText={setPhoneNumber}
          editable={!disabled}
          onEndEditing={onEndEditing}
        />
        {phoneNumber.length > 0 && showClear && (
          <Close onPress={clearInput} name="closecircleo" color={colors.black} size={20} />
        )}
      </View>
      {isError && <MyText customStyle={styles.errorText}>{errorMessage}</MyText>}
    </View>
  );
};

PhoneNumberInput.defaultProps = {
  errorMessage: '',
  isError: false,
  title: 'Nomor HP',
  disabled: false,
  styleTitle: {},
  mandatory: false,
  showClear: true,
  otpLoadingType: null,
  hasBorderWidth: true,
  onFocus: () => {},
  onBlur: () => {},
};

PhoneNumberInput.propTypes = {
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  setInputFocused: PropTypes.func.isRequired,
  inputFocused: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,

  mandatory: PropTypes.bool,
  showClear: PropTypes.bool,
  otpLoadingType: PropTypes.string,
  hasBorderWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default React.memo(PhoneNumberInput);
