import React, { useState, useEffect } from 'react';
import { Text, View, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct, getRegisteredAccount } from '@services/BillPaymentApi';
import { setUserAccountNumber, setInquiry } from '@redux/action/Transactions';

/* Style Import */
import { smallerText } from '@style/Layouts';
import { LgButton } from '@components/Button';

/* Component Import */
import DropdownInput from '@atoms/DropdownInput';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const ListrikPostpaid = ({ show, dataList, navigation, prefix }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const registeredAccounts = useSelector((state) => state.BillPayments.registeredAccounts);
  const pageFailed = useSelector((state) => state.Common.pageFailed);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('input');
  const [accountNumber, setAccountNumber] = useState('');

  useEffect(() => {
    dispatch(setUserAccountNumber(accountNumber));
  }, [accountNumber]);

  useEffect(() => {
    if (pageFailed == 'Internal Server error') {
      navigation.navigate('PageFailed', {
        timeTaken: '24 jam',
        productName: selectedProduct.name,
        category: `Top-Up ${selectedProduct.biller}`,
      });
    }
  }, [pageFailed]);

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    dispatch(getRegisteredAccount('BP-17'));
  }, []);

  const onChanged = (text) => {
    setAccountNumber(text.replace(/[^0-9]/g, ''));
  };

  if (!show) {
    return <></>;
  }

  const handleInputBaruClicked = () => {
    // AmplitudeHelper.logEvent('userClickedInputBaru')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('input');
  };

  const handleIdTerdaftarClicked = () => {
    // AmplitudeHelper.logEvent('userClickedIdTerdaftar')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.registeredIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('id');
  };

  const handleSelect = (index, value) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.account_number]: registeredAccounts[index]['accountNumber'],
    });
    setAccountNumber(registeredAccounts[index]['accountNumber']);
  };

  const handleFocus = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
  };

  return (
    <>
      <Text style={[smallerText, styles.title]}>ID Pelanggan</Text>
      <View style={styles.container}>
        <TouchableOpacity
          accessibilityLabel="newAccountTab"
          style={[
            styles.button,
            {
              borderBottomWidth: currentTab == 'input' ? 3 : 1,
              borderBottomColor: currentTab == 'input' ? '#f56b57' : '#d4d4d4',
            },
          ]}
          onPress={handleInputBaruClicked}
        >
          <Text style={[smallerText, { color: currentTab == 'input' ? '#f56b57' : '#d4d4d4' }]}>Input Baru</Text>
        </TouchableOpacity>
        <TouchableOpacity
          accessibilityLabel="savedAccountTab"
          style={[
            styles.button,
            {
              borderBottomWidth: currentTab == 'id' ? 3 : 1,
              borderBottomColor: currentTab == 'id' ? '#f56b57' : '#d4d4d4',
            },
          ]}
          onPress={handleIdTerdaftarClicked}
        >
          <Text style={[smallerText, { color: currentTab == 'id' ? '#f56b57' : '#d4d4d4' }]}>ID Terdaftar</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.inputContainer}>
        {currentTab == 'input' ? (
          <View style={{ flexDirection: 'column', width: '90%' }}>
            <TextInput
              accessibilityLabel={'accountInput'}
              style={[styles.input, { borderColor: pageFailed != '' ? 'red' : '#d4d4d4' }]}
              placeholder="Masukkan ID pelanggan"
              placeholderTextColor="#d4d4d4"
              keyboardType="numeric"
              onChangeText={(x) => onChanged(x)}
              onFocus={handleFocus}
              value={accountNumber}
            />
            <Text style={styles.pageFailedText}>{pageFailed}</Text>
          </View>
        ) : (
          <DropdownInput
            dropStyle={{ width: '90%', marginLeft: -12 }}
            style={styles.dropdown}
            styleFocus={{ width: '90%' }}
            textStyle={{ color: accountNumber == '' ? '#d4d4d4' : '#272727' }}
            defaultValue={'Pilih ID pelanggan'}
            options={registeredAccounts && registeredAccounts.map((item) => item.accountNumber)}
            onSelect={(index, value) => handleSelect(index, value)}
            value={accountNumber}
          />
        )}
      </View>
      <LgButton
        title="Konfirmasi"
        disabled={accountNumber == '' ? true : false}
        style={[styles.confirmButton, { backgroundColor: accountNumber == '' ? '#F89F92' : '#F56B57' }]}
        onPress={() => {
          dispatch(setInquiry({}));
          dispatch(selectProduct(dataList[0], accountNumber));
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  title: { alignSelf: 'flex-start', marginLeft: '5%', marginTop: '5%' },
  container: { flex: 1, flexDirection: 'row', width: '100%', marginTop: '5%', maxHeight: '5%', marginLeft: '15%' },
  button: { flex: 0.4, alignItems: 'center' },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: '5%',
    maxHeight: '10%',
    marginLeft: '10%',
    marginBottom: '10%',
  },
  input: {
    borderWidth: 2,
    borderRadius: 12,
    maxHeight: '85%',
    paddingHorizontal: 10,
    minHeight: normalizeHeight(40),
  },
  pageFailedText: {
    fontSize: normalize(12),
    color: '#d40e0b',
    fontFamily: 'Nunito-Regular',
    marginLeft: 10,
    marginTop: 5,
  },
  dropdown: {
    borderWidth: 3.5,
    borderRadius: 12,
    borderColor: '#d4d4d4',
    minHeight: '90%',
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  confirmButton: {
    alignSelf: 'center',
    width: normalize(200),
    marginBottom: normalizeHeight(15),
  },
});

export default ListrikPostpaid;
