import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Image, Pressable, SectionList, StyleSheet, Text, View } from 'react-native';
import BillPaymentWrapper from '@components/BillPaymentWrapper';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';
import { APPTOURMETA, APP_TOUR_TYPE } from '@utils/AppTourConstants';
import { numberWithDots } from '@utils/UtilFunctions';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import PacketDataConfirmSheet from '../PacketDataConfirmSheet';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';

const SimpleList = ({
  list,
  onPress,
  headerText,
  hasImage = false,
  openPacketDataModal,
  selectedItem,
  onClickDetails,
  setOpenPacketDataModal,
}) => {
  const renderItem = ({ item, index }) => {
    if (hasImage) {
      return (
        <View style={[styles.itemContainer]}>
          <Pressable
            onPress={() => onPress(item)}
            style={[styles.pressable]}
            android_ripple={{ color: colors.lightGrey }}
          >
            <Image
              source={{ uri: item.imageUrl }}
              resizeMode={'contain'}
              style={{ height: normalizeHeight(100), width: '100%' }}
            />
          </Pressable>
        </View>
      );
    } else {
      return (
        <View style={[styles.itemContainer]}>
          <Pressable
            onPress={() => onPress(item)}
            style={styles.pressable}
            android_ripple={{ color: colors.lightGrey }}
          >
            <MyText isRegular={false} h5 customStyle={{ color: colors.orange }}>
              {item.name}
            </MyText>
            <MyText h3>Harga: Rp {numberWithDots(item.amount)}</MyText>

            {index == 0 ? (
              <View style={styles.horizontalContainer}>
                <MyText>+ GajiGesa Fee</MyText>

                <View style={{ borderColor: colors.blue, width: '25%' }}>
                  {/* <AppTourComponentWrapper
                    order={2}
                    text={APPTOURMETA[APP_TOUR_TYPE.packetdata][2]}
                    name={`${APP_TOUR_TYPE.packetdata}2`}
                    type={APP_TOUR_TYPE.packetdata}
                  > */}
                  <LoadingButton
                    onPress={() => onClickDetails(item)}
                    bordered
                    text={'Lihat Detail'}
                    buttonColor={colors.white}
                    textColor={colors.blue}
                    textStyle={{
                      fontSize: 10.5,
                      fontFamily: Font.Nunito_Regular,
                    }}
                    textTransform="capitalize"
                    container={{
                      borderColor: colors.blue,
                      width: '100%',
                      paddingVertical: 5,
                    }}
                  />
                  {/* </AppTourComponentWrapper> */}
                </View>
              </View>
            ) : (
              <View style={styles.horizontalContainer}>
                <MyText>+ GajiGesa Fee</MyText>
                <LoadingButton
                  onPress={() => onClickDetails(item)}
                  bordered
                  text={'Lihat Detail'}
                  buttonColor={colors.white}
                  textColor={colors.blue}
                  textStyle={{
                    fontSize: 10.5,
                    fontFamily: Font.Nunito_Regular,
                  }}
                  textTransform="capitalize"
                  container={{
                    borderColor: colors.blue,
                    width: '25%',
                    paddingVertical: 5,
                  }}
                />
              </View>
            )}
          </Pressable>
        </View>
      );
    }
  };

  const keyExtractor = (item) => {
    return item.id?.toString();
  };

  const renderHeader = () => {
    return (
      <MyText h4 isRegular={false} customStyle={{ color: colors.blue }}>
        Pilih {headerText}:
      </MyText>
    );
  };

  const onPressConfirm = (item) => {
    setOpenPacketDataModal(false);
    onPress(item);
  };

  const onCloseHandler = () => {
    setOpenPacketDataModal(false);
    const e = AMPLITUDE_CONSTANTS.BillPayment.detailsModalClosed;
    AmplitudeHelper.logEvent(e.name);
  };
  return (
    <>
      <FlatList
        data={hasImage ? list[0]?.data ?? [] : list}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        contentContainerStyle={styles.flatlist}
        ListHeaderComponent={renderHeader}
        style={styles.flatListContainerStyle}
        ListHeaderComponentStyle={styles.headerStyle}
      />

      <PacketDataConfirmSheet
        visible={openPacketDataModal}
        onPressModal={onCloseHandler}
        item={selectedItem}
        onPressConfirm={onPressConfirm}
      />
    </>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    alignSelf: 'flex-start',
    marginHorizontal: 20,
    marginBottom: 10,
  },
  flatListContainerStyle: { height: 0, paddingBottom: normalize(20) },

  flatlist: {
    paddingTop: 15,
    paddingBottom: 30,
    backgroundColor: colors.white,
    flexGrow: 1,
  },
  itemContainer: {
    overflow: 'hidden',
    elevation: 5,
    backgroundColor: colors.white,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 10,
    minWidth: '90%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    borderWidth: 1,
    alignSelf: 'flex-start',
    borderColor: 'transparent',
  },
  pressable: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: '100%',
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'space-between',
  },
});

export default SimpleList;
