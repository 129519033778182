import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { numberWithDots } from '@utils/UtilFunctions';

/* REDUX */
import { useSelector } from 'react-redux';

/* Styles */
import { BillPaymentProductCard } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';

const Pulsa = ({ dataList, onPress, show }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);

  if (!show) {
    return <></>;
  }
  return (
    <FlatList
      data={dataList}
      contentContainerStyle={{ minWidth: '95%', justifyContent: 'center', alignItems: 'center' }}
      renderItem={(row) => {
        return (
          <BillPaymentProductCard
            alignment={true}
            prop={{
              ...row.item,
              nominal: numberWithDots(Number.parseInt(row.item.code.match(/(\d+)/)) * 1000),
              amount:
                row.item.code == 'PLSID20'
                  ? numberWithDots(Number.parseInt(row.item.code.match(/(\d+)/)) * 1000)
                  : numberWithDots(row.item.amount),
            }}
            cardStyle={styles.card}
            titleStyle={styles.title}
            subTitleStyle={styles.subTitle}
            onPress={() => {
              onPress(row);
            }}
            selected={JSON.stringify(row.item) == JSON.stringify(selectedProduct)}
          />
        );
      }}
      numColumns={2}
    />
  );
};

const styles = StyleSheet.create({
  card: { width: '45%', marginBottom: normalizeHeight(10) },
  title: { flex: 1, fontSize: normalize(24), fontWeight: 'bold', marginBottom: 0 },
  subTitle: { flex: 1, fontSize: normalize(12) },
});

export default Pulsa;
