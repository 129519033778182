import React, { useRef, useState } from 'react';
import { FlatList, Pressable, ScrollView, StyleSheet, TouchableOpacity, View, Text, Dimensions } from 'react-native';
import { colors } from '@style/colors';
import Back from '@expo/vector-icons/MaterialIcons';
import MyText from '@atoms/MyText';
import Divider from '@atoms/Divider';
import Arrow from '@expo/vector-icons/FontAwesome';
import Drop from '@expo/vector-icons/MaterialIcons';
import CheckBox from 'expo-checkbox';
import CategoryItem from './CategoryItem';
import { CustomReasons, Reasons, ReasonsIncreased, SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import Collapsible from 'react-native-collapsible';
import { useDispatch } from 'react-redux';
import { changeExpense, changeIncome, setAllExpense, setAllIncome } from '@redux/action/TransactionDetails';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { chunk, times } from 'lodash';
import { Font } from '@style/Fonts';

const screenWidth = Dimensions.get('window').width;

const ExpandCategory = ({
  icon,
  color,
  isChecked,
  dropdownIcon,
  title,
  setChecked,
  isCollapsed,
  onPress,
  dataArray,
  onMainCheckPress,
  checkedCategoryLength,
}) => {
  const reasonsCarousel = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const renderItem = ({ item }) => {
    const Component = CustomReasons[item.id];
    if (!Component)
      return (
        <Pressable>
          <View style={categoryStyle.item} />
        </Pressable>
      );
    const svgProp = item.isChecked ? { fill: colors.orange } : { fill: colors.blue };
    return (
      <Pressable onPress={() => setChecked(item.id)} android_ripple={{ color: colors.lightGrey }}>
        <View style={[categoryStyle.itemWithBorder, item.isChecked ? categoryStyle.highlight : null]}>
          <View
            style={[
              categoryStyle.circleBox,
              item.isChecked
                ? { backgroundColor: colors.orange }
                : {
                    borderWidth: 1,
                    borderColor: colors.grey,
                    backgroundColor: colors.white,
                  },
            ]}
          />
          <View style={[categoryStyle.itemIcon, item.isChecked ? { borderColor: colors.orange } : null]}>
            <Component {...svgProp} />
          </View>
          <View style={{ height: 28 }}>
            <Text numberOfLines={2} style={categoryStyle.itemText}>
              {item.reason}
            </Text>
          </View>
        </View>
      </Pressable>
    );
  };

  const renderFlatList = ({ item }) => (
    <FlatList
      contentContainerStyle={categoryStyle.list}
      data={item}
      numColumns={3}
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderItem}
    />
  );

  return (
    <>
      <Pressable onPress={onPress} android_ripple={{ color: colors.lightGrey }} style={categoryStyle.container}>
        <View style={categoryStyle.subContainer}>
          <View style={[categoryStyle.circle, { borderWidth: 1, borderColor: color }]}>{icon}</View>
          <View>
            <MyText customStyle={{ marginStart: 10 }}>{title}</MyText>
            <MyText
              customStyle={{
                marginStart: 10,
                color: colors.grey,
                fontSize: 10,
                lineHeight: 14,
              }}
            >{`${checkedCategoryLength} kategori terpilih`}</MyText>
          </View>
        </View>
        <View style={categoryStyle.subContainer}>
          <CheckBox
            onValueChange={onMainCheckPress}
            tintColors={{ true: colors.orange, false: colors.grey }}
            color={{ isChecked: colors.orange, false: colors.grey }}
            value={isChecked}
          />
          {dropdownIcon}
        </View>
      </Pressable>
      <Collapsible collapsed={isCollapsed}>
        <Carousel
          ref={reasonsCarousel}
          itemWidth={screenWidth}
          sliderWidth={screenWidth}
          data={dataArray}
          renderItem={renderFlatList}
          inactiveSlideOpacity={1}
          inactiveSlideScale={1}
          onSnapToItem={(index) => {
            // AmplitudeHelper.logEvent('userSwipesLeftOrRight')
            setActiveIndex(index);
          }}
          enableSnap
        />
        <Pagination
          dotsLength={Math.ceil(dataArray.length)}
          activeDotIndex={activeIndex}
          containerStyle={categoryStyle.pagination}
          dotStyle={{
            backgroundColor: '#3863F0',
            height: 12,
            width: 12,
            borderRadius: 6,
          }}
          inactiveDotStyle={{
            backgroundColor: '#292929',
            height: 12,
            width: 12,
            borderRadius: 6,
          }}
          inactiveDotScale={1}
          animatedDuration={0}
          carouselRef={reasonsCarousel}
        />
      </Collapsible>
    </>
  );
};

const categoryStyle = StyleSheet.create({
  circle: {
    height: 35,
    width: 35,
    borderRadius: 35 / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pagination: {
    marginTop: 0,
    width: 40,
    alignSelf: 'center',
  },
  list: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'center',
  },
  item: {
    margin: 6,
    marginVertical: 8,
    width: 100,
    height: 90,
    padding: 12,
    paddingTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemWithBorder: {
    margin: 6,
    marginVertical: 8,
    width: 100,
    height: 90,
    padding: 12,
    paddingTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#BFBFBF',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  highlight: {
    borderColor: colors.orange,
    borderWidth: 2,
    elevation: 7,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  itemIcon: {
    height: 36,
    width: 36,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    color: '#454545',
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 14,
    marginTop: 5,
    fontFamily: Font.Nunito_Bold,
  },
  circleBox: {
    position: 'absolute',
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colors.orange,
    top: 5,
    right: 5,
  },
});

const TransactionCategory = ({ onBackPress, income, expense, isCheckedReasons }) => {
  const [expenseOpened, setExpenseOpened] = useState(true);
  const [incomeOpened, setIncomeOpened] = useState(true);

  const dispatch = useDispatch();
  const onMainCheckPressExpense = (value) => {
    dispatch(setAllExpense(value));
  };

  const onMainCheckPressIncome = (value) => {
    dispatch(setAllIncome(value));
  };

  const expenseList = chunk(expense, 12);
  const incomeList = chunk(income, 12);
  if (expenseList[expenseList.length - 1].length < 12) {
    const last = expenseList[expenseList.length - 1];
    last.push(
      ...times(12 - last.length, (id) => ({
        id: 100 + id,
      })),
    );
  }
  if (incomeList[incomeList.length - 1].length < 12) {
    const last = incomeList[incomeList.length - 1];
    last.push(
      ...times(12 - last.length, (id) => ({
        id: 100 + id,
      })),
    );
  }
  const checkedExpenseLength = expense.filter((item) => item.isChecked).length;
  const checkedIncomeLength = income.filter((item) => item.isChecked).length;

  return (
    <ScrollView style={styles.mainContainer}>
      <FlatList
        ListHeaderComponent={() => (
          <ExpandCategory
            onMainCheckPress={onMainCheckPressExpense}
            setChecked={(id) => dispatch(changeExpense(id))}
            isChecked={isCheckedReasons.expense}
            isCollapsed={expenseOpened}
            onPress={() => {
              setExpenseOpened(!expenseOpened);
            }}
            dataArray={expenseList}
            checkedCategoryLength={checkedExpenseLength}
            color={colors.red}
            title="Semua Pengeluaran"
            icon={<Arrow name="long-arrow-left" color={colors.red} />}
            dropdownIcon={
              <Drop name={expenseOpened ? 'keyboard-arrow-down' : 'keyboard-arrow-up'} color={colors.blue} size={30} />
            }
          />
        )}
      />

      <Divider horizontal customStyle={{ marginVertical: 5 }} />
      <ExpandCategory
        onMainCheckPress={onMainCheckPressIncome}
        setChecked={(id) => dispatch(changeIncome(id))}
        isChecked={isCheckedReasons.income}
        isCollapsed={incomeOpened}
        onPress={() => {
          setIncomeOpened(!incomeOpened);
        }}
        dataArray={incomeList}
        checkedCategoryLength={checkedIncomeLength}
        color={colors.darkGreen}
        title="Semua Pendapatan"
        icon={<Arrow name="long-arrow-right" color={colors.darkGreen} />}
        dropdownIcon={
          <Drop name={incomeOpened ? 'keyboard-arrow-down' : 'keyboard-arrow-up'} color={colors.blue} size={30} />
        }
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    flex: 1,
    width: '100%',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
});

export default TransactionCategory;
