import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  codeReferralText: {
    marginEnd: 5,
    color: colors.oldSilver,
  },
  button: {
    borderColor: colors.oldSilver,
    borderWidth: 0.5,
  },
  buttonApplied: {
    backgroundColor: colors.azuireishWhite,
  },
  appliedInput: {
    fontFamily: Font.Nunito_Regular,
    flex: 1,
    marginTop: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    paddingVertical: 5,
    backgroundColor: colors.antiFlashWhite,
    color: colors.oldSilver,
    marginEnd: 10,
  },
  container: {
    marginHorizontal: 20,
    marginTop: 20,
    marginBottom: -10,
  },
  horizontalView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    fontFamily: Font.Nunito_Regular,
    flex: 1,
    height: 42,
    paddingHorizontal: 10,
    borderRadius: 10,
    paddingVertical: 5,
    borderWidth: 0.5,
    borderColor: colors.oldSilver,
    marginEnd: 10,
    outlineColor: 'transparent',
  },
});
