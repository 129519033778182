import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize } from '@style/helper';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    alignSelf: 'center',
    borderRadius: 10,
    marginVertical: 8,
    overflow: 'hidden',
    paddingHorizontal: normalize(15),
  },
  pressable: {
    padding: 15,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    paddingHorizontal: normalize(5),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  leftContainer: {
    width: '85%',
  },
  textStyleDark: {
    color: colors.white,
  },
  textStyleLight: {
    color: colors.philippineGray,
  },
  progressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
});

export default styles;
