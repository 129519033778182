import React, { useEffect, useMemo, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import styles from './style';
import MyText from '@atoms/MyText';
import Divider from '@atoms/Divider';
import MainParentWrapper from '@components/MainParentWrapper';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InfoTextComponent from '@atoms/InfoTextComponent';
import BankItemSelect from '@atoms/BankCards/BankItemSelect';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { BOTTONSHEET_KEYS } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { BOTTOMSHEET_CONTENT } from '@utils/Constants';
import BottomSheet from '@components/LoginInfoSheet';
import { returnBottomSheetContent } from '@utils/UtilFunctions';

const RegisteredBankSelection = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { selectedBank } = route.params;
  const { userBanks } = useSelector((state) => state.UserBank);
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(BOTTONSHEET_KEYS.MAX_LIMIT, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.MAX_LIMIT]),
    errorCode: BOTTONSHEET_KEYS.MAX_LIMIT,
  });

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.transfer.select_bank_account_page.name);
  }, []);

  const renderItem = ({ item }) => {
    const onPressBank = () => {
      if (item.status === 'PENDING') return;
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.transfer.bank_account_selected.name);
      navigation.navigate('SalaryTransfer', { selectedBank: item });
    };
    return <BankItemSelect item={item} onPress={onPressBank} isSelected={selectedBank?.id === item.id} />;
  };

  const newUserBanks = useMemo(() => {
    return userBanks.filter((e) => e.status !== 'REJECTED');
  }, [userBanks]);

  const navigateToNewBankCreation = () => {
    if (userBanks.length < 10) {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'SalaryTransfer' },
            { name: 'BankCreateDetail', params: { source: AMPLITUDE_EVENT_SOURCES.transfer_page } },
          ],
        }),
      );
      return;
    }
    setInfoModal({ ...infoModal, visible: true });
  };

  const keyExtractor = (item) => item.id.toString();
  return (
    <MainParentWrapper containerStyle={{ paddingTop: 15, paddingHorizontal: 15, height: 0 }}>
      <View style={styles.titleRow}>
        <MyText isRegular={false}>Rekening Tersimpan</MyText>
        <Text onPress={navigateToNewBankCreation} style={styles.undlerlineText}>
          Tambah Rekening
        </Text>
      </View>
      <InfoTextComponent infoText="Hanya nomor rekening terverifikasi yang bisa digunakan." />
      <FlatList
        keyExtractor={keyExtractor}
        showsVerticalScrollIndicator={false}
        data={newUserBanks}
        renderItem={renderItem}
        style={{ height: 0 }}
      />
      <BottomSheet
        onPressPrimary={() => setInfoModal({ ...infoModal, visible: false })}
        loginInfo={infoModal}
        hasTwoButtons={infoModal.data.hasTwoButtons}
        onClose={() => setInfoModal({ ...infoModal, visible: false })}
      />
    </MainParentWrapper>
  );
};

RegisteredBankSelection.defaultProps = {};

RegisteredBankSelection.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      selectedBank: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }),
};

export default RegisteredBankSelection;
