import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import AmplitudeHelper from '@services/amplitude';
import MainParentWrapper from '@components/MainParentWrapper';
import AccountCard from '@components/AccountCard';
import MyText from '@atoms/MyText';
import { setCoinBalance } from '@redux/action/Coins';
import {
  GET_COINS,
  GET_SAVING_GRAPH_DATA,
  GET_UPDATE_STATUS,
  GET_USER_PROPERTIES,
  TRANSACTION_COUNT,
} from '@gqlQuery/query';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  checkIfHasSdAccount,
  findAccountByName,
  getAppUpdateStatus,
  getAvailableBalance,
  getDynamicAcountListByModule,
  getinfoModalSheetContent,
  numberWithDots,
  sortTransactionDetailsByPriority as sortHomePageAccountCardByPriority,
  sortViewedStories,
  syncWithDynamicAcounts,
  needForRefetchAppStatus,
  checkToNavigateDetails,
  onShowModalHandler,
  onUpdateHandler,
} from '@utils/UtilFunctions';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import {
  ACCOUNT_LIST,
  CHECK_DATA_DROPDOWN_LIST,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  HOMEPAGE_DYNAMIC_ACCOUNTS,
  INFO_SHEET_TYPES,
  JSON_URLS,
  LOAN_APPLICATION_STATUS,
  LOAN_ICON_INFO,
  LOAN_STATUS_TYPES,
  OTHER_ACCOUNT_NAMES,
  SAVINGS_TOUR_TYPES,
  STORIES_ROUTE,
  UPDATE_TYPE,
} from '@utils/Constants';
import MarketingBanner from '@components/MarketingBanner';
import TarikGajiContainer from '@components/dashboard/TarikGajiContainer';
import BillPaymentCategories from '@components/dashboard/BillPaymentCategories';
import { Linking, Text, View } from 'react-native';
import {
  setCanContinue,
  setSource,
  updateShowModal,
  setStoriesCommon,
  setAppTourParams,
  resetAppTourParams,
} from '@redux/action/Common';
import { SentryHelper } from '@utils/SentryHelper';
import { showEWAModal } from '@redux/action/Modals';
import CardUpdate from '@components/CardUpdate';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TourModal from '@components/AppTourSavings/TourModal';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import ReferralCode from '@components/ReferralCode';
import { checkReferralStatus } from '@services/Referral';
import { setRedeemCode, setReferralError, setReferralLoading } from '@redux/action/Referral';
import ConfirmModal from '@components/ConfirmModal';
import { store } from '@enhancedStore/store';
import UpdateApp from '@components/UpdateApp';
import { APP_TOUR_TYPE } from '@utils/AppTourConstants';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import {
  setDynamicAccounts,
  setCurrentHomePageSelectedAccount,
  setDataAccounts,
  setCurrentBpSelectedAccount,
} from '@redux/action/DynamicAccounts';
import { MISSION_SOURCE } from '@gaji-gesa/gg-react-shared/src/utils/MissionUtils';
import DropDownModal from '@atoms/DropDownSelector/DropDownModal';
import InfoModalSheet from '@molecules/InfoModalSheet';
import { removeNonNumeric } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { INSERT_PERMISSION } from '@gqlQuery/PermissionQuery';
import { GET_ELIGIBILTY_APP_STATUS } from '@gqlQuery/loan';
import * as Location from 'expo-location';
import useLoans from '@gaji-gesa/gg-ui-shared/src/customHooks/useLoans';
import LoadingModal from '@components/LoadingModal';
import { setSeenLoan, setSeenZakat } from '@redux/action/Common';

import { Platform } from 'react-native';
import { initialiseUserAttributes } from '@utils/engagementUtils';
import { setTransactionCategoryId, setTransactionSource } from '@redux/action/Transactions';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import styles from './style';
import { ZAKAT_ICON_INFO } from '@gaji-gesa/gg-ui-shared/src/uiUtils/uiConstants';
import InfoTextComponent from '@atoms/InfoTextComponent';
import { FAB } from '@rneui/themed';

let savingsTourPhones = [];

function checkForZakatFeature(isFeatureAvailale) {
  return isFeatureAvailale[FEATURE_FLAGS.ZAKAT]?.show;
}

const NewDashboard = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useDispatch();
  const [showUpdate, setShowUpdate] = useState(false);
  const scrollViewRef = useRef(null);
  const { transCategoryId, transSource } = useSelector((state) => state.Transactions);
  const { loading: loadingReferral, isTransacted, isValidCode } = useSelector((state) => state.Referral);
  const { employeeId, phoneNumber, userId, userName, employerId } = useSelector((state) => state.Authentication);
  const { dynamicAccounts, currentHomePageSelectedAccount, dataAccounts } = useSelector(
    (state) => state.DynamicAccounts,
  );
  const daData = useSelector((state) => state.DynamicAccounts);
  const { appTourParams, seenLoan, seenZakat, latestPWA } = useSelector((state) => state.Common);
  const [accountDropDown, setAccountDropDown] = useState(false);

  const [setPermission, {}] = useMutation(INSERT_PERMISSION, {
    fetchPolicy: 'no-cache',
  });

  const comparePermissionFromLatest = async () => {
    try {
      let { status } = await Location.requestForegroundPermissionsAsync();
      setPermission({
        variables: {
          locationAllowed: status,
          permissionPopUp: 'N/A',
          phonebookAllowed: 'N/A',
          employeeId,
        },
      });
      if (status !== 'granted') {
        const e = AMPLITUDE_CONSTANTS.permissionsUi.location_permission;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.action]: AMPLITUDE_EVENT_SOURCES.permissionUiAttributes.action.deny,
          [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
        });
        return;
      }
      const e = AMPLITUDE_CONSTANTS.permissionsUi.location_permission;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.action]: AMPLITUDE_EVENT_SOURCES.permissionUiAttributes.action.allow,
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
      });

      let location = await Location.getCurrentPositionAsync({});
      AmplitudeHelper.setLocation(location.coords.latitude.toString(), location.coords.longitude.toString());
    } catch (error) {
      AmplitudeHelper.setLocation('null', 'null');
    }
  };

  useEffect(() => {
    if (appTourParams.type == 'referral') {
      if (appTourParams.step == 2) scrollViewRef.current.scrollTo({ x: 0, y: 300, animated: true });
      if (appTourParams.step == 3)
        navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'app_tour' } });
    }
    if (appTourParams.type == 'homepage') {
      if (appTourParams.step == 1) scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
      if (appTourParams.step == 6) scrollViewRef.current.scrollTo({ x: 0, y: 300, animated: true });
      if (appTourParams.step == 7) scrollViewRef.current.scrollToEnd({ animated: true });
      if (appTourParams.step == 5 && appTourParams.isPrevious)
        scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
  }, [scrollViewRef.current, appTourParams]);

  useEffect(() => {
    if (route.params?.missionSource === MISSION_SOURCE.stories) scrollViewRef.current.scrollToEnd({ animated: true });
  }, [route.params]);

  const { tour, stories: storiesCommon, showStories: showStoriesCommon } = useSelector((state) => state.Common);
  const isFocused = useIsFocused();
  const [accountSetupAndFeatureFlagsStatus, setAccountSetupAndFeatureFlagsStatus] = useState({
    accountCardSync: false,
    featureFlag: false,
  }); // Used to determine if the feature flag response is recieved and account cards sync is completed
  const { coinBalance } = useSelector((state) => state.Coins);
  const canContinue = useSelector((state) => state.Common.canContinue);
  const { selectedReason, feeAmount } = useSelector((state) => state.Transactions);
  const common = useSelector((state) => state.Common);
  const [marketingBanners, setMarketingBanners] = useState([{ id: '1' }, { id: '2' }]);
  const [loadingMarketingBanners, setLoadingMarketingBanners] = useState(true);
  const [showInfoModalSheet, setInfoModalSheet] = useState({
    visible: false,
    data: getinfoModalSheetContent(INFO_SHEET_TYPES.SD_ACCOUNT_INFO.name),
    type: 'SD',
  });
  const [loadingStories, setLoadingStories] = useState(true);
  const [stories, setStories] = useState([]);
  const [showStories, setShowStories] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [showReferralConfirmModal, setShowReferralConfirmModal] = useState(false);
  const [tourVisible, setTourVisible] = useState({
    visible: false,
    type: SAVINGS_TOUR_TYPES.TYPE1,
  });
  const [updateType, setUpdateType] = useState(UPDATE_TYPE.NO_UPDATE);

  const {
    withdrawAmount,
    lockPeriod,
    todayBalanceFloor,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    workingDays,
    workedDays,
    usedBalance,
    amount,
  } = useSelector((state) => state.Withdrawal);
  const pointsAccount = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.POINTS);

  const dispatchDataAccounts = (newData) => {
    dispatch(setDataAccounts(newData));
  };

  const [getCoins, { error: errorCoins, loading: loadingCoin }] = useLazyQuery(GET_COINS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: pointsAccount?.id ?? 1,
    },
    onCompleted: (data) => {
      dispatch(setCoinBalance(data?.balance[0]?.availableBalance ?? 0));
      const newData = dataAccounts.slice();
      const findPoinAccount = newData.findIndex((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS);
      if (findPoinAccount !== -1) {
        newData[findPoinAccount] = {
          ...newData[findPoinAccount],
          amount: numberWithDots(data?.points_txn_aggregate?.aggregate.sum?.value ?? 0),
        };
        dispatchDataAccounts(newData);
      }
    },
  });

  const [appUpdate] = useLazyQuery(GET_UPDATE_STATUS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUpdateType(getAppUpdateStatus(data.gajigesa_app_version));
    },
    onError: (error) => {},
  });
  const [getUserProperties, { loading }] = useLazyQuery(GET_USER_PROPERTIES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      AmplitudeHelper.setUserProperties({
        [AMPLITUDE_CONSTANTS.userProperties.salary_amount]: data?.gg_salary_profile[0]?.salaryAmount ?? null,
        [AMPLITUDE_CONSTANTS.userProperties.salary_type]: data?.gg_salary_profile[0].salaryTypeInfo.salaryType ?? null,
        [AMPLITUDE_CONSTANTS.userProperties.gender]: data?.gg_employee[0].gender ?? null,
        [AMPLITUDE_CONSTANTS.userProperties.fee_type]: data?.gg_salary_profile[0].feeType ?? null,
        [AMPLITUDE_CONSTANTS.userProperties.fee_value]: data?.gg_salary_profile[0].fee ?? null,
      });
    },
    onError: (error) => {},
  });

  const checkDynamicAccount = async () => {
    try {
      const dynamicAccount = await AsyncStorage.getItem('@dynamicAccounts');
      const transformedDynamicAccount = JSON.parse(dynamicAccount);
      if (transformedDynamicAccount) {
        dispatch(setDynamicAccounts(transformedDynamicAccount));
      }
    } catch (error) {}
  };

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.dashboardPage.name);
    checkDynamicAccount();
    getUserProperties();
    comparePermissionFromLatest();
  }, []);

  const getMarketingBannerData = async (isFeatureAvailale) => {
    try {
      setAccountSetupAndFeatureFlagsStatus({
        accountCardSync: accountSetupAndFeatureFlagsStatus.accountCardSync,
        featureFlag: true,
      });
      // Every Marketing banner needs to be added inside a feature flag (Mandatory)
      const filterBannerRule = (item) => {
        const findBannerInFeatureFlag = isFeatureAvailale.findIndex((e) => e.code === item.title);
        if (findBannerInFeatureFlag !== -1) return isFeatureAvailale[findBannerInFeatureFlag].show;
        return false;
      };
      const response = await fetch(JSON_URLS.marketingBanner, {
        method: 'get',
      });
      if (response.ok) {
        let jsonData = await response.json();
        jsonData = jsonData.filter(filterBannerRule);
        setMarketingBanners(jsonData);
        setLoadingMarketingBanners(false);
      }
    } catch (error) {
      // setLoadingMarketingBanners(false)
      //SentryHelper.captureCustomException(SENTRY_BUG_CONSTATNS.storiesApiFailed,{...error})
    }
  };
  const [isFeatureAvailale, getFeatureFlags] = useFeatureFlag({
    featureName: [
      FEATURE_FLAGS.REFERRAL,
      FEATURE_FLAGS.GAMIFICATION,
      FEATURE_FLAGS.SAVINGS_HOME,
      FEATURE_FLAGS.STORIES,
      FEATURE_FLAGS.BP_HOMEPAGE,
      FEATURE_FLAGS.LOAN,
      FEATURE_FLAGS.BANK_SEL_TRNSF,
      FEATURE_FLAGS.ZAKAT,
      FEATURE_FLAGS.EWA_MODAL,
      FEATURE_FLAGS.CHAT_GPT,
    ],
    onCompleteWithDynamicFeature: getMarketingBannerData,
  });

  const { getEligibilityAppStatus, updateLoanStatus, showLoan, loanData, isRefetch, setRefetch } = useLoans({
    isLoanFeature: isFeatureAvailale?.[FEATURE_FLAGS.LOAN].show,
    onShowModal: (data) =>
      onShowModalHandler(
        data,
        LOAN_STATUS_TYPES,
        setInfoModalSheet,
        LOAN_APPLICATION_STATUS,
        navigation,
        AMPLITUDE_EVENT_SOURCES.home_page,
      ),
    onUpdate: (data) => onUpdateHandler(data, LOAN_APPLICATION_STATUS, navigation, AMPLITUDE_EVENT_SOURCES.home_page),
  });

  useEffect(() => {
    if (isFocused) getEligibilityAppStatus();
  }, [isFocused]);

  const onPressLoanHandler = useCallback(() => {
    try {
      AsyncStorage.setItem('@seenLoan', true);
      dispatch(setSeenLoan(true));

      if (needForRefetchAppStatus(loanData)) {
        setRefetch(true);
        getEligibilityAppStatus();
        return;
      }
      let e = AMPLITUDE_CONSTANTS.Loans.loan_tile_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'success',
      });
      navigation.navigate('ProductStack', {
        screen: 'LoanListing',
        params: {
          source: AMPLITUDE_EVENT_SOURCES.home_page,
        },
      });
    } catch (err) {
      let e = AMPLITUDE_CONSTANTS.Loans.loan_tile_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'failure',
      });
    }
  }, [loanData]);

  const onPressZakatHandler = () => {
    AsyncStorage.setItem('@seenZakat', JSON.stringify(true));
    dispatch(setSeenZakat(true));

    let e = AMPLITUDE_CONSTANTS.Zakat.product_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
      [e.attributes.transaction_category_id]: 'bp-29',
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      [e.attributes.phone_number]: phoneNumber,
    });

    navigation.navigate('ZakatStack', {
      screen: 'ZakatScreen',
      params: {
        item: { ...ZAKAT_ICON_INFO, menu: 'Zakat & Donasi', name: 'Zakat Fitrah', prefix: 'BP-29', id: 29 },
      },
    });
  };

  useEffect(() => {
    // [CORE-1282]: Removing as part of feedback from product
    //getMarketingBannerData();
    getCoins();

    // [CORE-1282]: Removing as part of feedback from product
    // getStories();
    appUpdate();
  }, []);

  useEffect(() => {
    if (isFocused) {
      getCoins();
      dispatch(setTransactionCategoryId('home_page'));
      getFeatureFlags();
      !isTransacted && checkIfHasTransaction();
    }
  }, [isFocused]);

  const checkIfSdInfoShown = async (sdBalance) => {
    try {
      const response = await AsyncStorage.multiGet(['@sdInfoShown', '@sdBalanceCleared']);
      const sdInfoShown = JSON.parse(response[0][1]);
      const sdBalanceCleared = JSON.parse(response[1][1]);

      if (!sdInfoShown && !sdBalanceCleared) {
        setInfoModalSheet({ visible: true, data: showInfoModalSheet.data });
        AsyncStorage.setItem('@sdInfoShown', JSON.stringify(true));
      } else if (sdInfoShown && sdBalanceCleared && sdBalance > 0) {
        setInfoModalSheet({ visible: true, data: showInfoModalSheet.data });
        AsyncStorage.setItem('@sdBalanceCleared', JSON.stringify(false));
      } else {
        setInfoModalSheet({ visible: false, data: showInfoModalSheet.data });
      }
    } catch (error) {}
  };

  const setSdAccountData = async (newData) => {
    // Setting SD account value

    const findSdAccount = newData.findIndex((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT);
    // Setting default selected account
    const sdBalance = parseInt(removeNonNumeric(newData[findSdAccount]?.amount)) > 0;
    if (lockPeriod && findSdAccount !== -1 && sdBalance) {
      dispatch(setCurrentHomePageSelectedAccount(newData[findSdAccount]));
      await checkIfSdInfoShown(sdBalance);
    } else {
      const findSalaryAccount = newData.findIndex(
        (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
      );
      if (findSalaryAccount !== -1) dispatch(setCurrentHomePageSelectedAccount(newData[findSalaryAccount]));
    }
  };

  const setSalaryAccountData = () => {
    let newData = dataAccounts.slice();
    const findSalaryAccount = newData.findIndex((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
    if (findSalaryAccount !== -1) {
      newData[findSalaryAccount] = {
        ...newData[findSalaryAccount],
        amount: `Rp${numberWithDots(
          getAvailableBalance(
            salaryWithCappedAmount,
            todayBalanceFloor,
            workingDays,
            workedDays,
            maxWithdrawalAmount,
            usedBalance,
          ),
        )}`,
      };
    }

    /*
    Account Cards Priority in EWA
    If Salary = 0, then Work Allowance has Highest priority else
    EWA -> 1
    Work Allowance -> 2
    SD -> 3
    POINTS -> 4
    SAVINGS -> 5
    */

    // Sync Dynamic account data with local accounts
    newData = [...syncWithDynamicAcounts(dynamicAccounts, newData)];

    // Check if salaryAmount = 0, then default account is going to be Work Allowance if available
    const findWorkAllowance = newData.findIndex((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);

    if (amount === 0 && findWorkAllowance !== -1) {
      newData[findWorkAllowance] = {
        ...newData[findWorkAllowance],
        priority: 0,
      };
      dispatch(setCurrentHomePageSelectedAccount(newData[findWorkAllowance]));
      dispatch(setCurrentBpSelectedAccount(newData[findWorkAllowance]));
    } else if (checkIfHasSdAccount(dynamicAccounts, newData)) {
      setSdAccountData(newData);
    } else {
      const findSalaryAccountByName = findAccountByName(newData, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
      if (findSalaryAccountByName) dispatch(setCurrentHomePageSelectedAccount(findSalaryAccountByName));
    }

    sortHomePageAccountCardByPriority(newData);
    dispatchDataAccounts(newData);
    setAccountSetupAndFeatureFlagsStatus({
      accountCardSync: true,
      featureFlag: accountSetupAndFeatureFlagsStatus.featureFlag,
    });
  };

  useEffect(() => {
    setSalaryAccountData();
  }, [usedBalance, dynamicAccounts]);

  const getStories = async () => {
    try {
      const response = await fetch(`${config.MEDIA_STORAGE}${STORIES_ROUTE}`, { method: 'get' });
      if (response.ok) {
        const jsonData = await response.json();
        const getStories = await AsyncStorage.getItem('@stories');
        const transformedStories = JSON.parse(getStories);
        setStories(sortViewedStories(jsonData, transformedStories));
      }
      setLoadingStories(false);
    } catch (error) {
      //SentryHelper.captureCustomException(SENTRY_BUG_CONSTATNS.storiesApiFailed,{...error})
    }
  };
  const hideLoaderForAccounts = useMemo(() => {
    if (dataAccounts[2]?.amount === null) {
      return true;
    }
    if (dataAccounts[3]?.amount === null) {
      return true;
    }

    return true;
  }, [dataAccounts]);

  const onPressAccountCard = useCallback(
    (item, source) => {
      if (item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.PoinClicked.name, {
          [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.employee_id]: employeeId,
          [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.poin_balance]: item.amount,
          [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.source]: item.source,
        });
        navigation.navigate('TransactionList', {
          screen: 'Points',
        });
      } else if (item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.source]: 'salary_card_modal',
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.referral_code]:
            store.getState().Referral?.redeemCode || null,
        });
        batch(() => {
          dispatch(setCurrentHomePageSelectedAccount(item));
          dispatch(setTransactionCategoryId('salary_card_modal'));
          dispatch(showEWAModal('salary_card_modal'));
        });
      } else if (item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.salary_disbursement_account_clicked.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.referral_code]:
            store.getState().Referral?.redeemCode || null,
        });
        batch(() => {
          dispatch(setCurrentHomePageSelectedAccount(item));
          dispatch(setTransactionCategoryId('salary_card_modal'));
          dispatch(showEWAModal('salary_card_modal'));
        });
      } else if (item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.source]: 'salary_card_modal',
          [AMPLITUDE_CONSTANTS.Withdrawal.salaryAccountClicked.attributes.referral_code]:
            store.getState().Referral?.redeemCode || null,
        });
        batch(() => {
          dispatch(setCurrentHomePageSelectedAccount(item));
          dispatch(setTransactionCategoryId('salary_card_modal'));
          dispatch(showEWAModal('salary_card_modal'));
        });
      } else {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.savingsClicked.name, {
          [AMPLITUDE_CONSTANTS.Savings.savingsClicked.attributes.source]: source || item.source,
        });
        navigation.navigate('TransactionList', {
          screen: 'SavingDetails',
          params: { savingAmount: 0, eventSource: source || item.source },
        });
      }

      // dispatch(setModalType("ewa"));
      // dispatch(setShowModal(true));
    },
    [dataAccounts],
  );

  const updateSaving = (totalSaving) => {
    const newData = dataAccounts.slice();
    const findSavings = newData.findIndex((e) => e.constantAccountName === OTHER_ACCOUNT_NAMES.SAVINGS);
    if (findSavings !== -1) {
      newData[findSavings] = {
        ...newData[findSavings],
        amount: `Rp${numberWithDots(totalSaving)}`,
      };
      dispatchDataAccounts(newData);
    }
  };

  const initializeInsider = () => {
    try {
      // let identifiers = new RNInsiderIdentifier();
      // identifiers.addUserID(userId);
      // identifiers.addPhoneNumber(`+${phoneNumber}`);
      // RNInsider.getCurrentUser().login(identifiers, (result) => console.log(result, 'INSIDER_ReSULt===>'));
      // RNInsider.getCurrentUser().setCustomAttributeWithBoolean('is_gajigesa', true);
      // RNInsider.getCurrentUser().setCustomAttributeWithString('employee_id', employeeId.toString());
      // RNInsider.getCurrentUser().setCustomAttributeWithString('full_name', userName);
      // RNInsider.getCurrentUser().setPushOptin(true);
      SentryHelper.setUser(employeeId, employerId);
      initialiseUserAttributes({ userId, phoneNumber, is_gajigea: true, employeeId, userName });
      AmplitudeHelper.setUserId(employeeId, employerId);
      AmplitudeHelper.setUserProperties({ userId });
    } catch (error) {}
  };

  const checkVersion = async () => {
    // try {
    //   let checkedVersion = await VersionCheck.needUpdate();
    //   if (checkedVersion && checkedVersion?.isNeeded) {
    //     setShowUpdate(true);
    //   } else {
    //     setShowUpdate(false);
    //   }
    // } catch (error) {
    //   setShowUpdate(false);
    //   throw error;
    // }
  };

  useEffect(() => {
    initializeInsider();
    checkVersion();
  }, []);

  function getAmountForLogging(daAcc, currentHomePageSelectedAccount, withdrawal) {
    if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT) return withdrawal;
    if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) {
      return daAcc[currentHomePageSelectedAccount.constantAccountName]?.value;
    }
    return daAcc[currentHomePageSelectedAccount.constantAccountName]?.value[1];
  }

  useEffect(() => {
    if (canContinue && isFocused) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.requested_amount]: getAmountForLogging(
          daData,
          currentHomePageSelectedAccount,
          withdrawAmount,
        ),
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.transaction_category_id]: transCategoryId,
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.reason]: selectedReason.reason,
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.reason_id]: selectedReason.reasonId,
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.fee_requested]: feeAmount,
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.source]: transSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
        [AMPLITUDE_CONSTANTS.Withdrawal.applyForWithdrawal.attributes.referral_code]:
          store.getState().Referral?.redeemCode || null,
      });
      if (common.showModal) {
        AmplitudeHelper.logEvent('reasonsModalOpened');
        batch(() => {
          dispatch(updateShowModal('reasons'));
          dispatch(setCanContinue(false));
        });
      }
      // navigation.setOptions({ tabBarVisible: false });

      navigation.navigate('TransactionList', {
        screen: 'BankDetail',
      });
    }
  }, [canContinue, isFocused]);

  useEffect(() => {
    setShowUpdate(!latestPWA);
  }, [latestPWA]);

  const onEndEditing = () => {
    setShowReferralConfirmModal(true);
  };

  const checkReferralCode = async () => {
    try {
      showReferralConfirmModal && setShowReferralConfirmModal(false);
      await dispatch(checkReferralStatus('home_page', true));
    } catch (error) {}
  };

  const checkIfHasTransaction = async () => {
    try {
      await dispatch(checkReferralStatus());
    } catch (error) {}
  };

  const onButtonPressHandler = () => {
    setTourVisible({ visible: false, type: tourVisible.type });
    dispatch(setAppTourParams({ type: APP_TOUR_TYPE.savings, step: 2 }));
    navigation.navigate('Transaksi');
  };

  const onTourCloseHandler = () => {
    setTourVisible({ visible: false, type: tourVisible.type });
    dispatch(resetAppTourParams());
  };

  const findSavings = useCallback(() => {
    return dataAccounts.find((e) => e.constantAccountName === OTHER_ACCOUNT_NAMES.SAVINGS);
  }, [dataAccounts]);

  const onPressAccountDropdown = (item) => {
    setAccountDropDown(!accountDropDown);
    dispatch(setCurrentHomePageSelectedAccount(item));
    const e = AMPLITUDE_CONSTANTS.account_drop_down_selected;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.withdrawal_card,
      [e.attributes.dynamic_account_name]: item?.amplitudeAccountName ?? '',
    });
  };

  const onPrimaryPressSDHandler = () => {
    if (showInfoModalSheet.data.name === INFO_SHEET_TYPES.SD_ACCOUNT_OPEN_INFO.name)
      dispatch(
        setCurrentHomePageSelectedAccount(
          dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT),
        ),
      );

    setInfoModalSheet((prevState) => ({ ...prevState, visible: false }));
  };

  const onPrimaryLoanPressHandler = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
    if (showInfoModalSheet.data?.hasTwoButtons) return;
    updateLoanStatus();
  };
  const onCloseLoanPressHandler = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
  };
  const onPressSecondary = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
    updateLoanStatus();
  };

  const onPressPrimaryInfoModal = () => {
    if (showInfoModalSheet.type === 'loan') return onPrimaryLoanPressHandler();
    onPrimaryPressSDHandler();
  };

  const onCloseSDPrimaryInfoModal = () => {
    const e = AMPLITUDE_CONSTANTS.sd_period_started;
    AmplitudeHelper.logEvent(e.name);
    onPrimaryPressSDHandler();
  };

  const onCloseModalSheet = () => {
    if (showInfoModalSheet.type === 'loan') return onCloseLoanPressHandler();
    onCloseSDPrimaryInfoModal();
  };

  const shouldRenderReferralCodeOnFeatureFlag = useCallback(
    (isReferralFeature) => {
      if (isReferralFeature)
        return (
          <ReferralCode
            onEndEditing={onEndEditing}
            onPressSubmit={checkReferralCode}
            showTour={appTourParams.type === APP_TOUR_TYPE.referral_code_apply && appTourParams.step === 1}
          />
        );
      return null;
    },
    [appTourParams, isFeatureAvailale],
  );
  const onPressTransfer = () => {
    const e = AMPLITUDE_CONSTANTS.transfer.product_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.referral_code]: store.getState().Referral?.redeemCode || null,
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
      [e.attributes.transaction_category_id]: 'wd-02',
    });
    navigation.navigate('BankStack', {
      screen: 'SalaryTransfer',
      params: { source: AMPLITUDE_EVENT_SOURCES.home_page },
    });
  };

  const shouldRenderBillPaymentCategories = useCallback(
    (isBPFeature, isLoan) => {
      if (isBPFeature)
        return (
          <BillPaymentCategories
            dataAccounts={dataAccounts}
            navigation={navigation}
            showTour={appTourParams?.type == 'homepage' && appTourParams?.step == 5}
            isLoan={isLoan}
            onPressLoanHandler={onPressLoanHandler}
            seenLoan={seenLoan}
            shouldShowTransfer={isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF]?.show}
            onPressTransfer={onPressTransfer}
            shouldShowZakat={checkForZakatFeature(isFeatureAvailale)}
            onPressZakatHandler={onPressZakatHandler}
            seenZakat={seenZakat}
          />
        );

      return null;
    },
    [loanData, seenLoan, seenZakat, isFeatureAvailale, appTourParams],
  );

  const isSalaryAccountSelected =
    currentHomePageSelectedAccount?.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT;

  const filterSavings = (accounts) => accounts.filter((e) => e.constantAccountName !== 'SAVINGS');

  return (
    <>
      <MainParentWrapper
        isScrollable
        ref={scrollViewRef}
        containerStyle={{
          borderTopStartRadius: 0,
          borderTopEndRadius: 0,
          paddingTop: 0,
        }}
      >
        <AccountCard
          hideLoader={hideLoaderForAccounts}
          data={filterSavings(dataAccounts)}
          onPress={onPressAccountCard}
          coinBalance={numberWithDots(coinBalance)}
          showTour={appTourParams?.type == 'homepage' && appTourParams?.step == 1}
          showTouronPrevious={appTourParams?.type == 'homepage' && appTourParams?.step == 3}
          nextScroll={appTourParams?.type == 'homepage' && appTourParams?.step == 3}
          prevScroll={appTourParams?.type == 'homepage' && appTourParams?.step == 2}
          tour={tour}
          disableEWAModal={!isFeatureAvailale[FEATURE_FLAGS.EWA_MODAL]?.show}
        />

        <InfoTextComponent
          customTextStyle={styles.lockPeriodBannerTextStyle}
          iconContainer={styles.lockPeriodBannerIconContainerStyle}
          containerStyle={styles.lockperiodBannerContainerStyle}
          iconName="query-builder"
          infoText="Masa transaksimu sedang tidak aktif. Coba cek akun poin, uang kantor atau uang kas kamu jika ada"
          size={22}
          iconColor={colors.primary_carnation}
          hide={!lockPeriod || !isSalaryAccountSelected}
        />
        {shouldRenderReferralCodeOnFeatureFlag(isFeatureAvailale[FEATURE_FLAGS.REFERRAL].show)}
        <TarikGajiContainer
          setInfoModalSheet={setInfoModalSheet}
          onPressAccountSelector={() => {
            setAccountDropDown(!accountDropDown);
            const e = AMPLITUDE_CONSTANTS.account_drop_down_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.withdrawal_card,
            });
          }}
          loadingReferral={loadingReferral}
          showTour={appTourParams?.type == 'homepage' && appTourParams?.step == 4}
        />
        {shouldRenderBillPaymentCategories(isFeatureAvailale[FEATURE_FLAGS.BP_HOMEPAGE]?.show, showLoan)}

        {/* app update component */}
        {/* <MyText>Show this on update - 1</MyText> */}
        <UpdateApp updateType={showUpdate} />
        {/* // [CORE-1282]: Removing as part of feedback from product */}
        {/* <Savings
        onPress={onPressAccountCard}
        data={findSavings}
        update={updateSaving}
        showSaving={marketingBanners.length > 0}
      /> */}
        {/* <MarketingBanner
        hideLoader={!loadingMarketingBanners}
        data={marketingBanners}
        setData={setMarketingBanners}
        eventSource={'home_page'}
        navigation={navigation}
        coinBalance={coinBalance}
        showTourReferral={!!(appTourParams.type == 'referral' && appTourParams.step == 2)}
        showTourHome={!!(appTourParams.type == 'homepage' && appTourParams.step == 6)}
      /> */}

        {/* <MyText customStyle={{ paddingStart: 15, marginTop: 10 }} isRegular={false} h4>
        Kabar Terbaru Untukmu
      </MyText> */}

        {/* <GGStories
        loadingStories={!loadingStories}
        firstOpen={firstOpen}
        scrollIndex={scrollIndex}
        setFirstOpen={setFirstOpen}
        setScrollIndex={setScrollIndex}
        setShowStories={(story) => {
          dispatch(setStoriesCommon(storiesCommon.slice(), false));
          setShowStories(story);
        }}
        setStories={setStories}
        showStoriesCommon={showStoriesCommon}
        showStories={showStoriesCommon ? showStoriesCommon : showStories}
        stories={showStoriesCommon ? storiesCommon : stories}
        showTour={!!(appTourParams.type == 'homepage' && appTourParams.step == 7)}
      /> */}
        <TourModal
          onClose={onTourCloseHandler}
          setTourVisible={setTourVisible}
          onPressButtonColor={onButtonPressHandler}
          hasCongrats
          type={tourVisible.type}
          isVisible={tourVisible.visible}
        />

        <ConfirmModal
          isVisible={showReferralConfirmModal}
          onPressModal={() => {}}
          onPressNo={() => {
            if (!isValidCode) dispatch(setRedeemCode(''));

            setShowReferralConfirmModal(false);

            let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_reminder;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
              [e.attributes.source]: 'home_page',
              [e.attributes.action]: 'cancel',
            });
          }}
          onPressApply={() => {
            checkReferralCode();
            let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_reminder;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
              [e.attributes.source]: 'home_page',
              [e.attributes.action]: 'apply',
            });
          }}
        />
        <DropDownModal
          visible={accountDropDown}
          data={getDynamicAcountListByModule(dataAccounts, HOMEPAGE_DYNAMIC_ACCOUNTS)}
          hasIcon
          onPressItem={onPressAccountDropdown}
          onClose={() => {
            setAccountDropDown(false);
            const e = AMPLITUDE_CONSTANTS.cancel_button_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.cancelButtonSource.account_drop_down_transaction_page,
            });
          }}
          selectedAccount={currentHomePageSelectedAccount}
        />

        <InfoModalSheet
          title={showInfoModalSheet.data?.title}
          description={showInfoModalSheet.data?.description}
          hasTwoButtons={showInfoModalSheet.data?.hasTwoButtons ?? false}
          buttonText={showInfoModalSheet.data?.buttonText}
          buttonText2={showInfoModalSheet.data?.buttonText2}
          icon={showInfoModalSheet.data?.icon}
          visible={showInfoModalSheet.visible}
          onPressPrimary={onPressPrimaryInfoModal}
          onClose={onCloseModalSheet}
          onPressSecondary={onPressSecondary}
        />

        <LoadingModal loading={isRefetch} text={'Mohon menunggu'} />
      </MainParentWrapper>
      {isFeatureAvailale[FEATURE_FLAGS.CHAT_GPT]?.show && (
        <FAB
          style={{
            position: 'absolute',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
          loading={false}
          visible
          color={colors.primary_carnation}
          size="large"
          placement="right"
          onPress={() => {
            navigation.navigate('ChatScreen');
          }}
        >
          {SVG_ICONS.ChatLogo.chatLogo}
        </FAB>
      )}
    </>
  );
};

export default NewDashboard;
