import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: { flex: 1, backgroundColor: colors.secondary_orchid },
  upperContainer: { height: '26%' },
  scrollableContainerStyle: { paddingBottom: normalizeHeight(17), paddingTop: normalizeHeight(10) },
  assetOneStyling: { position: 'absolute', bottom: -70, zIndex: -2 },
  assetTwoStyling: {
    position: 'absolute',
    alignSelf: 'center',
    right: normalize(42),
    bottom: 0,
  },
  assetThreeStyling: { marginLeft: normalize(20), marginTop: normalize(20) },
  assetFourStyling: { bottom: normalize(-28), zIndex: -1 },
  textContainer: { width: '50%', marginLeft: normalize(15), marginTop: normalizeHeight(7) },
  textStyling: { fontSize: normalize(15), color: colors.neutral_cotton },
  tabBarStyle: {
    marginHorizontal: normalize(13),
    marginBottom: normalize(10),
    elevation: 0,
    paddingVertical: 0,
    backgroundColor: colors.grey_tab,
    justifyContent: 'center',
    borderRadius: 5,
  },
  tabStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    minHeight: 45,
  },
  labelStyle: {
    fontFamily: Font.Nunito_Bold,
    padding: 10,
    margin: 0,
    textTransform: 'none',
  },
  indicatorStyle: {
    height: '80%',
    backgroundColor: colors.primary_carnation,
    borderRadius: 7,
    width: '50%',
    marginBottom: 4,
    marginStart: 5,
    marginEnd: 30,
  },
  inActiveTabbarStyle: { color: colors.foundationDarkShade },
  loginButtonStyle: {
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primary_carnation,
    color: colors.black,
    width: '45%',
    marginHorizontal: 0,
    borderRadius: normalize(30),
  },
  loginButtonTextStyle: { textTransform: 'none' },
  loginButtonDisable: { backgroundColor: colors.gainsboro },
  leftButtonStyle: { backgroundColor: colors.neutral_cotton, borderWidth: 1, borderColor: colors.primary_carnation },
  leftButtonText: { color: colors.primary_carnation },
  bottomButtonContainerStyle: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: normalize(12),
    backgroundColor: colors.neutral_cotton,
    flexDirection: 'row',
    width: '100%',
    elevation: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    flex: 1,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingVertical: normalize(12),
  },
  parentStyle: { backgroundColor: 'transparent', flex: 1 },
});

export default styles;
