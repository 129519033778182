import React from 'react';
import { View } from 'react-native';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import styles from './style';

const WelcomCards = ({ item }) => (
  <View style={[styles.cardContainer, { backgroundColor: item.cardColor }]}>
    <View style={styles.logoStyle}>{SVG_ICONS.welcome.whiteGGLogo}</View>
    <View style={styles.artwork}>{SVG_ICONS.welcome.artwork[item.srNo]}</View>
    <View style={styles.textContainer}>
      <MyText isRegular={false} customStyle={styles.textStyle}>
        {item.textContext}
      </MyText>
    </View>
  </View>
);

export default WelcomCards;
