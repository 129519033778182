import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { styles } from './style';
import { useSelector } from 'react-redux';

const Indicator = ({ focused, name }) => {
  const { isTransacted } = useSelector((state) => state.Referral);
  const { seenProfile } = useSelector((state) => state.Common);

  if (isTransacted && !seenProfile && name === 'profile')
    return (
      <View style={styles.newBadgeContainer}>
        <MyText customStyle={styles.textStyle}> Baru</MyText>
      </View>
    );

  if (focused) return <View style={styles.indicatorStyle} />;

  return null;
};

Indicator.propTypes = {
  focused: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
export default Indicator;
