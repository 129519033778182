import React, { useEffect } from 'react';
import BottomSheet from 'reanimated-bottom-sheet';

/* Style Import */
import { normalizeHeight } from '@style/helper';

/* Component Import */
import ReasonsContent from './ReasonsContent';
import Header from '@atoms/Header';
import AmplitudeHelper from '@services/amplitude';
import MyModal from '@atoms/MyModal';
import { View } from 'react-native';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { store } from '@enhancedStore/store';

const ReasonsModal = React.forwardRef(
  ({ handleClose, handleNoReason, updateTextBox, showModal, eventSource, redeemCode, reduceHeight }, ref) => {
    const { currentHomePageSelectedAccount } = store.getState().DynamicAccounts;
    useEffect(() => {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });
    }, []);

    const handleReasonsClose = () => {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModal.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.reasonsModalClose.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });

      handleClose();
    };

    return (
      <MyModal
        isVisible={showModal}
        containerStyle={{ justifyContent: 'flex-end', margin: 0 }}
        onPress={handleReasonsClose}
      >
        <View style={{ paddingTop: 0, height: reduceHeight ? '45%' : '100%' }}>
          <Header
            container={{
              position: 'absolute',
              top: 0,
              right: 10,
              zIndex: 10,
            }}
            handleClose={handleReasonsClose}
          />
          <ReasonsContent
            handleNoReason={(message) => handleNoReason(message)}
            updateTextBox={(val) => updateTextBox(val)}
            eventSource={eventSource}
            handleClose={handleClose}
          />
        </View>
      </MyModal>
    );
  },
);

export default ReasonsModal;
