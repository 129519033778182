import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Pressable, SectionList, Text, TextInput, View } from 'react-native';
import Divider from '@atoms/Divider';
import MyText from '@atoms/MyText';
import AmplitudeHelper from '@services/amplitude';
import { colors } from '@style/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

import { styles } from './style';
import { useNavigation, useRoute } from '@react-navigation/native';

const PdamRegion = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { dataList } = route.params;
  const [list, setList] = useState(dataList.map((e) => e.name));
  const [searchText, setSearchText] = useState('');
  const onPress = (item) => {
    const findIndex = dataList.findIndex((e) => e.name === item);
    if (findIndex !== -1) {
      navigation.navigate('Product', { pdamRegion: dataList[findIndex], item: route.params.itemParams });
      let e = AMPLITUDE_CONSTANTS.BillPayment.PostPaid.pdamAreaSelected;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.product_code]: formatUnderScoreLowerCase(dataList[findIndex]?.code),
      });
    }
  };

  const renderItem = useCallback(({ item, index }) => {
    return (
      <Pressable
        onPress={() => onPress(item)}
        android_ripple={{ color: colors.lightGrey }}
        style={{ paddingVertical: 10, paddingHorizontal: 10 }}
      >
        <MyText>{item}</MyText>
      </Pressable>
    );
  }, []);

  const onChangeText = (text) => {
    setSearchText(text);
    if (text.length === 0) {
      setList(dataList.map((e) => e.name));
      return;
    }
    setList(list.filter((e) => e.toLowerCase().includes(text.toLowerCase())));
  };

  const renderSectionHeader = () => {
    return (
      <View style={styles.inputContainer}>
        <TextInput
          value={searchText}
          onChangeText={onChangeText}
          style={styles.inputText}
          placeholder={'Cari Wilayah'}
        />
      </View>
    );
  };

  const keyExtractor = (item) => item.id;
  return (
    <View style={styles.container}>
      <View style={styles.blueBg} />

      <View style={styles.childContainer}>
        {renderSectionHeader()}
        <FlatList
          data={list}
          initialNumToRender={15}
          maxToRenderPerBatch={15}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <Divider horizontal customStyle={{ marginVertical: 3 }} />}
          keyExtractor={keyExtractor}
          contentContainerStyle={{ paddingHorizontal: 15 }}
        />
      </View>
    </View>
  );
};

export default PdamRegion;
