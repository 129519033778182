import React, { useCallback, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Dimensions, View, Pressable } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import MyText from '@atoms/MyText';
import { TIER_STATUS_TYPE } from '@utils/Constants';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import TierCard from '../TierCard';
import styles from './style';

const { width: SCREEN_WIDTH } = Dimensions.get('window');

const Tiers = ({ tiers, activeTierIdx, setActiveTierIdx, onPressInfo, totalMissions, noOfCompleted }) => {
  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.missions.mission_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.tier_name]: `tier_${activeTierIdx + 1}`,
    });
  }, [activeTierIdx]);

  const renderItem = useCallback(
    ({ item }) => (
      <TierCard
        title={item?.tier?.tierName}
        totalMissions={totalMissions}
        noOfCompleted={noOfCompleted}
        expiryDate={item?.expiryDate}
        isComingSoon={item?.status === TIER_STATUS_TYPE.DISABLED}
        srNo={item?.tier?.displayOrder}
      />
    ),
    [totalMissions, noOfCompleted],
  );

  return (
    <View style={styles.containerStyle}>
      <View style={styles.labelContainer}>
        <MyText customStyle={styles.labelText} isRegular={false}>
          Level
        </MyText>

        <Pressable onPress={onPressInfo} style={styles.infoCircle}>
          <MyText customStyle={styles.infoText} isRegular={false}>
            i
          </MyText>
        </Pressable>
      </View>
      <Carousel
        inactiveSlideScale={1}
        inactiveSlideOpacity={1}
        data={tiers}
        enableSnap
        onSnapToItem={setActiveTierIdx}
        renderItem={renderItem}
        sliderWidth={SCREEN_WIDTH}
        itemWidth={SCREEN_WIDTH}
      />
      <Pagination
        dotsLength={tiers.length}
        activeDotIndex={activeTierIdx}
        containerStyle={styles.paginationContainer}
        dotStyle={styles.dotStyle}
        inactiveDotStyle={styles.inactiveStyle}
        inactiveDotScale={1}
        animatedDuration={0}
      />
    </View>
  );
};

Tiers.propTypes = {
  activeTierIdx: Proptypes.number.isRequired,
  tiers: Proptypes.array.isRequired,
  setActiveTierIdx: Proptypes.func.isRequired,
  onPressInfo: Proptypes.func.isRequired,
  totalMissions: Proptypes.number.isRequired,
  noOfCompleted: Proptypes.number.isRequired,
  eventSource: Proptypes.string.isRequired,
};
Tiers.defaultProps = {};
export default Tiers;
