import React from 'react';
import { Text, View, Pressable } from 'react-native';
import Icon from '@expo/vector-icons/Ionicons';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';

const CloseHeader = ({ handleClose }) => {
  return (
    <View
      style={{
        position: 'absolute',
        backgroundColor: colors.white,
        zIndex: 10,
        top: normalizeHeight(10),
        right: normalize(20),
      }}
    >
      <Pressable onPress={handleClose}>
        <Text>
          <Icon name="close" size={normalize(24)} style={{ color: colors.lightGrey }} />
        </Text>
      </Pressable>
    </View>
  );
};

export default CloseHeader;
