import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';

import { ImageButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';

const EMoney = ({ dataList, onPress, show }) => {
  if (!show) {
    return <></>;
  }
  return (
    <>
      <View style={{ height: '50%' }}>
        <FlatList
          data={Object.keys(dataList)}
          renderItem={(item) => {
            return (
              <View style={styles.item}>
                <ImageButton
                  accessibilityLabel="productNameButton"
                  source={
                    dataList[item.item] && dataList[item.item][0]?.imageUrl ? dataList[item.item][0]?.imageUrl : 'local'
                  }
                  columns={3}
                  onPress={() => onPress(dataList[item.item])}
                />
                <Text style={styles.text}>{item.item}</Text>
              </View>
            );
          }}
          numColumns={3}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  item: { flexDirection: 'column', marginTop: normalizeHeight(10) },
  text: { fontSize: normalize(10), alignSelf: 'center', textAlign: 'center' },
});

export default EMoney;
