import React from 'react'
import {View, StyleSheet} from 'react-native'
import { colors } from '@style/colors'

const TourDot =({active, hasMarginStart})=>{
    return(
        <View
        style={[styles.dot, {backgroundColor:active?colors.blue:colors.lightGrey,marginStart:hasMarginStart?3:0}]}
        >

        </View>
    )
}

const styles = StyleSheet.create({
    dot:{
        height:10, 
        width:10,
        borderRadius:10/2,
  
    }
})

export default TourDot
