import React from 'react';
import { View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { colors } from '@style/colors';

const LoadingModal = ({ loading, text = 'Mohon menunggu' }) => (
  <Modal
    isVisible={loading}
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
    }}
    useNativeDriver={true}
    backdropTransitionOutTiming={0}
  >
    <View style={styles.modal2}>
      <View style={styles.subView}>
        <Text style={styles.text}>{text}</Text>
        <ActivityIndicator size="small" color={colors.orange} />
      </View>
    </View>
  </Modal>
);

const styles = StyleSheet.create({
  subView: {
    padding: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  modal2: {
    borderRadius: 15,
    alignSelf: 'center',
    backgroundColor: 'white',
  },
  text: {
    marginEnd: 25,
    fontFamily: 'Nunito-Bold',
  },
});

export default LoadingModal;
