import React, { useState } from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { colors } from '@style/colors';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import Close from '@expo/vector-icons/AntDesign';
import { formatUnderScoreLowerCase, getCurrentTimeStamp, numberWithDots, removeNonNumeric } from '@utils/UtilFunctions';
import Divider from '@atoms/Divider';
import CheckBox from 'expo-checkbox';
import LoadingButton from '@atoms/LoadingButton';
import { useSelector } from 'react-redux';
import { CATEGORY } from '@utils/Constants';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { store } from '@enhancedStore/store';
import ReferralApplied from '../ReferralApplied';
import AccountList from '../AccountList';
import { styles } from './style';
import Terms from '../Terms';

const InfoDetailRow = ({ title, value, isTitleBold }) => (
  <View style={styles.infoDetailRow}>
    <MyText isRegular={!isTitleBold} customStyle={{ width: '60%', flexWrap: 'wrap' }}>
      {title}
    </MyText>

    <View style={{ width: '42%', alignItems: 'flex-end' }}>
      <MyText customStyle={{ textAlign: 'right' }}>{value}</MyText>
    </View>
  </View>
);

function maxChar(char, maxOut) {
  if (char && char.length > maxOut) {
    return `${char.substring(0, maxOut)}...`;
  }
  return char;
}

const returnInfoRow = (e, transactionReq) => {
  switch (e) {
    case 'Product':
      return (
        <InfoDetailRow
          key={e}
          title={transactionReq?.productName ?? ''}
          value={numberWithDots(transactionReq?.requestAmount ?? 0)}
        />
      );

    case 'Nominal Token':
      return <InfoDetailRow key={e} title={e} value={numberWithDots(transactionReq?.requestAmount ?? 0)} />;

    case 'Nama':
      return <InfoDetailRow key={e} title={e} value={transactionReq?.customerInfo[0]?.value ?? ''} />;

    case 'Pembayaran Zakat':
      return <InfoDetailRow key={e} title={e} value={`Rp${numberWithDots(transactionReq?.totalDonation ?? 0)}`} />;

    case 'Donatur':
      return <InfoDetailRow key={e} title={e} value={maxChar(transactionReq?.donorNames ?? '', 35)} />;

    case 'Tagihan':
      return <InfoDetailRow key={e} title={e} value={numberWithDots(transactionReq?.subtotalPostpaid ?? 0)} />;

    default:
      return <InfoDetailRow key={e} title={e} value={transactionReq?.accountNumber ?? 0} />;
  }
};

const returnDetailInfo = (e, transactionReq) => {
  switch (e) {
    case 'Tagihan PLN':
      return <InfoDetailRow key={e} title={e} value={`Rp${numberWithDots(transactionReq?.subtotalPostpaid ?? 0)}`} />;

    case 'Biaya Admin':
      return <InfoDetailRow key={e} title={e} value={`Rp${numberWithDots(transactionReq?.totalFeePostpaid ?? 0)}`} />;

    case 'Nilai Zakat':
      return <InfoDetailRow key={e} title={e} value={`Rp${numberWithDots(transactionReq?.totalDonation ?? 0)}`} />;
    default:
      return <InfoDetailRow key={e} title={e} value={`Rp${numberWithDots(transactionReq?.subtotal ?? 0)}`} />;
  }
};

const RequestTransactionSheet = ({
  visible,
  onPressModal,
  transactionReq,
  productId,
  prefix,
  onPressConfirm,
  selectedAccount,
  accounts,
  setSelectedAccount,
  isValidCode,
  isTransacted,
  toolTip,
  setTooltip,
  lockPeriod,
}) => {
  const [checkbox, setCheckBox] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { employeeId } = useSelector((state) => state.Authentication);

  const onPressCheckBox = () => {
    if (checkbox) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.termsCheckboxUnchecked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
        [e.attributes.timestamp]: getCurrentTimeStamp(),
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      });
    } else {
      const ampEvent = AMPLITUDE_CONSTANTS.BillPayment.userClickedPembayaranTermsCheckbox;
      const ampObject = {
        [ampEvent.attributes.source]: 'other_product_page',
        [ampEvent.attributes.timestamp]: getCurrentTimeStamp(),
        [ampEvent.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
        [ampEvent.attributes.referral_code]: store.getState().Referral.redeemCode,
      };
      AmplitudeHelper.logEvent(ampEvent.name, ampObject);
    }
    setCheckBox(!checkbox);
  };

  const returnReferralToolTip = () => {
    if (selectedAccount.accountName === 'Poin') {
      return (
        <MyText h2 customStyle={styles.textItalic}>
          Kode promo hanya berlaku untuk pembayaran dengan Gaji Prorata
        </MyText>
      );
    }
    if (toolTip.visible) {
      return (
        <MyText h2 customStyle={styles.textItalic}>
          {toolTip.message}
        </MyText>
      );
    }
    return (
      <MyText customStyle={{ width: '80%', marginStart: 5 }} h2>
        Kode referral berhasil dipakai. <MyText isRegular={false}>GajiGesa Poin</MyText> akan ditambahkan setelah
        transaksi ini sukses
      </MyText>
    );
  };

  const termClose = () => {
    setCheckBox(false);
    setShowTerms(false);
  };

  const termAccept = () => {
    setCheckBox(true);
    setShowTerms(false);
  };

  const onCloseModal = () => {
    setShowTerms(false);
    onPressModal(prefix);
  };

  const onPressTermClose = () => {
    termClose();
    onPressModal(prefix);
  };

  if (showTerms) {
    return (
      <MyModal isVisible={visible} onPress={onPressTermClose} containerStyle={styles.modal}>
        <View style={styles.container}>
          <TouchableOpacity onPress={onPressTermClose} style={{ alignSelf: 'flex-end', padding: 15 }}>
            <Close name="close" size={20} color={colors.grey} />
          </TouchableOpacity>

          <Terms handleTermsClose={termClose} acceptTerms={termAccept} acceptedTerms={checkbox} prefix={prefix} />
        </View>
      </MyModal>
    );
  }

  function shouldReturnTotalRow(transactionReq) {
    if (transactionReq?.totalDonation) {
      return <InfoDetailRow title="Total" isTitleBold value={`Rp${numberWithDots(transactionReq?.totalDonation)}`} />;
    }

    return <InfoDetailRow title="Total" isTitleBold value={`Rp${numberWithDots(transactionReq?.subtotal)}`} />;
  }

  return (
    <MyModal isVisible={visible} onPress={onCloseModal} containerStyle={styles.modal}>
      <View style={styles.container}>
        <TouchableOpacity onPress={onCloseModal} style={{ alignSelf: 'flex-end', padding: 15 }}>
          <Close name="close" size={20} color={colors.grey} />
        </TouchableOpacity>
        <View style={styles.info}>
          <MyText h4 isRegular={false}>
            Informasi Pelanggan
          </MyText>
          {CATEGORY[productId]?.info.map((e) => returnInfoRow(e, transactionReq))}
        </View>
        <Divider horizontal customStyle={{ height: 5, marginVertical: 5 }} />
        <View style={styles.info}>
          <MyText h4 isRegular={false}>
            Detail Pembayaran
          </MyText>
          {CATEGORY[productId]?.detail.map((e) => returnDetailInfo(e, transactionReq))}
        </View>
        <Divider horizontal customStyle={{ height: 2, marginVertical: 5 }} />

        <View style={styles.info}>
          {shouldReturnTotalRow(transactionReq)}
          <MyText h4 customStyle={{ marginTop: 10 }} isRegular={false}>
            Dipotong Dari:
          </MyText>
          <AccountList
            data={accounts}
            defaultAccount={selectedAccount}
            transactionRequest={transactionReq}
            setValue={setSelectedAccount}
            value={selectedAccount}
            prefix={prefix}
            employeeId={employeeId}
            lockPeriod={lockPeriod}
          />
        </View>
        <Divider horizontal customStyle={{ height: 5, marginVertical: 5 }} />

        {isValidCode && !isTransacted && (
          <ReferralApplied
            container={{ marginStart: 10, marginTop: 5 }}
            toolTip={toolTip}
            setTooltip={setTooltip}
            text={returnReferralToolTip()}
          />
        )}

        <Pressable style={styles.checkBoxContainerStyle}>
          <CheckBox
            color={checkbox ? colors.orange : colors.grey}
            onValueChange={onPressCheckBox}
            value={checkbox}
            style={styles.checkBoxStyle}
          />
          <MyText onPress={() => setShowTerms(true)} customStyle={styles.checkBoxTextStyle}>
            Saya mengkonfirmasi rincian transfer.
          </MyText>
        </Pressable>

        <View style={[styles.infoDetailRow, { paddingHorizontal: 25, marginTop: 30 }]}>
          <LoadingButton
            text="Menolak"
            buttonColor={colors.orange}
            loadingColor={colors.white}
            container={{ borderRadius: 40, marginTop: 15, flex: 1, marginEnd: 25 }}
            onPress={() => onPressModal(prefix)}
          />
          <LoadingButton
            text="Konfirmasi"
            buttonColor={colors.orange}
            loadingColor={colors.white}
            disabled={!checkbox || !(parseFloat(transactionReq?.subtotal) <= removeNonNumeric(selectedAccount.amount))}
            container={{ borderRadius: 40, marginTop: 15, flex: 1 }}
            onPress={onPressConfirm}
          />
        </View>
      </View>
    </MyModal>
  );
};

export default React.memo(RequestTransactionSheet);
