import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
const styles = StyleSheet.create({
  pressable: { width: '100%', justifyContent: 'center' },
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    paddingVertical: 10,
    flex: 1,
    justifyContent: 'center',
    borderRadius: 30,
    marginStart: 10,
  },
  buttonStyle: { fontSize: normalize(14), color: colors.tertiaryColor },
  imageButton: {
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.tertiaryColor,
    alignSelf: 'center',
    flex: 1,
  },
  bankNameDefault: {
    marginTop: '10%',
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  outerContainer: {
    flex: 1,
    backgroundColor: colors.blue,
  },
  innerContainer: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    zIndex: 10,
    backgroundColor: colors.white,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    marginTop: 5,
  },
  beneficiaryDefault: {
    marginBottom: 15,
  },
  divider: {
    marginVertical: 10,
    height: 2,
  },
  buttonContainer: {
    position: 'absolute',
    zIndex: 15,
    bottom: 0,
    elevation: 15,
    backgroundColor: colors.white,
    padding: 10,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '30px 30px 30px',
  },
});

export default styles;
