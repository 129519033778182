import React, { useRef } from 'react';
import { Dimensions, View } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import { colors } from '@style/colors';
import WelcomCards from '@components/welcome';

const { width: SCREEN_WIDTH } = Dimensions.get('window');

const WELCOME_DATA = [
  {
    srNo: 0,
    cardColor: colors.secondaryColor,
    textContext: 'Tarik gajimu lebih awal kapan dan di mana pun. \nTanpa bunga dan, pastinya, bukan pinjaman',
    circleGradient: ['rgba(44, 190, 150, 0.9)', 'rgba(44, 190, 150, 0.2)', 'rgba(44, 190, 150 , 0)'],
  },
];

const CustomCarousel = ({ setActiveIdx }) => {
  const carousel = useRef(null);

  return (
    <View style={{ flex: 1, alignSelf: 'stretch', height: '50%' }}>
      <Carousel
        inactiveSlideScale={1}
        inactiveSlideOpacity={1}
        ref={carousel}
        data={WELCOME_DATA}
        enableSnap
        onSnapToItem={setActiveIdx}
        autoplayInterval={4000}
        renderItem={WelcomCards}
        sliderWidth={SCREEN_WIDTH}
        itemWidth={SCREEN_WIDTH}
        showsHorizontalScrollIndicator
      />

      {/* <Pagination
        dotsLength={WELCOME_DATA.length}
        activeDotIndex={activeIdx}
        containerStyle={[styles.paginationContainer, { backgroundColor: WELCOME_DATA[activeIdx].cardColor }]}
        dotStyle={styles.dotStyle}
        inactiveDotStyle={styles.inactiveStyle}
        inactiveDotScale={1}
        animatedDuration={0}
        carouselRef={carousel}
      /> */}
    </View>
  );
};

export default React.memo(CustomCarousel);
