import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import MIcon from '@expo/vector-icons/MaterialIcons';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import {
  accruedSalaryTransactionHistory,
  getAvailableBalance,
  getDynamicAccountListByAccount,
  numberWithDots,
  getOffSetForGraphql,
} from '@utils/UtilFunctions';
import Divider from '@atoms/Divider';
import IncomeExpenseItem from '@components/IncomeExpenseItem';
import TransactionItem from '@molecules/TransactionItem';
import MyModal from '@atoms/MyModal';
import { getReasons, getTransactionHistory, getTransactionSummary } from '@services/TransactionApi';
import { setAllExpense, setAllIncome, setEmpPaymentCycle, setFilter } from '@redux/action/TransactionDetails';
import { DYNAMIC_ACCOUNT_NAMES, SAVINGS_TOUR_TYPES, TRANSACTION_LIST_DYNAMIC_ACCOUNTS } from '@utils/Constants';
import TourModal from '@components/AppTourSavings/TourModal';
import AmplitudeHelper from '@services/amplitude';
import { useLazyQuery } from '@apollo/client';
import { GET_PAYMENT_CYCLES, GET_SAVING_GRAPH_DATA } from '@gqlQuery/query';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { DAILY_BALANCE_PAYMENTCYCLE } from '@gqlQuery/QueryTransactionList';
import { APP_TOUR_TYPE } from '@utils/AppTourConstants';
import { resetAppTourParams } from '@redux/action/Common';
import DropDownModal from '@atoms/DropDownSelector/DropDownModal';
import LoadingModal from '@components/LoadingModal';
import { useNavigation } from '@react-navigation/native';
import { extractMonthlyData, groupByMonthlyData } from '../Saving/utils';
import translate from '../Saving/translate';
import { setCurrentTransactionPageSelectedAccount } from '@redux/action/DynamicAccounts';
import {
  LIMIT,
  MODAL_TYPE,
  balanceComponent,
  onMainParentReload,
  shouldCheckErrorCycle,
  shouldCheckLoading,
  shouldShowDateSelectorForCurrentCycle,
  shouldShowFilterModal,
  shouldShowFlatListOrLoader,
  shouldGetFilterAppliedDate,
  filterTransactionListComponent,
} from './TransactionUtils';
import styles from './styles';

const TransactionList = () => {
  const navigation = useNavigation();
  const [cycleIndex, setCycleIndex] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState(-1);
  const [collapsed, setCollapsed] = useState(true);
  const [page, setPage] = useState(1);
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(false);
  const [isLoadingFooter, setIsLoadingFooter] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [offSet, setOffSet] = useState(-1);
  const { employeeId } = useSelector((state) => state.Authentication);
  const [accountDropDown, setAccountDropDown] = useState(false);
  const { filter, paymentCycles, balance, totalIncome, totalExpense, accuredSalary, transactionList, reasons } =
    useSelector((state) => state.TransactionDetails);
  const { currentTransactionPageSelectedAccount, dataAccounts } = useSelector((state) => state.DynamicAccounts);
  const isDynamicAccountSelected =
    currentTransactionPageSelectedAccount.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT;
  const { todayBalanceFloor, salaryWithCappedAmount, maxWithdrawalAmount, workingDays, workedDays, usedBalance } =
    useSelector((state) => state.Withdrawal);
  const { phoneNumber } = useSelector((state) => state.Authentication);
  const [currentCycle, setCurrentCycle] = useState(paymentCycles[cycleIndex]);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const [modal, setModal] = useState({
    visible: false,
    type: MODAL_TYPE.FILTER,
  });
  const dispatch = useDispatch();
  const [toolTip, setToolTip] = useState({ isVisible: false, type: 'balance' });

  const [tourVisible, setTourVisible] = useState({
    visible: false,
    type: SAVINGS_TOUR_TYPES.TYPE3,
  });
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [savingAmount, setSavingAmount] = useState(0);
  const [loadingSaving, setLoadingSaving] = useState(true);
  const [dailyBalanceInfo, setDailyBalanceInfo] = useState({
    salaryWithCappedAmount,
    todayBalanceFloor,
    workingDays,
    workedDays,
    maxWithdrawalAmount,
    usedBalance,
  });

  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        dailyBalanceInfo.salaryWithCappedAmount,
        dailyBalanceInfo.todayBalanceFloor,
        dailyBalanceInfo.workingDays,
        dailyBalanceInfo.workedDays,
        dailyBalanceInfo.maxWithdrawalAmount,
        dailyBalanceInfo.usedBalance,
      ),
    [dailyBalanceInfo],
  );

  const newAccruedSalary = useMemo(
    () =>
      accruedSalaryTransactionHistory(
        accuredSalary,
        cycleIndex,
        dailyBalanceInfo.salaryWithCappedAmount,
        dailyBalanceInfo.workedDays,
        dailyBalanceInfo.workingDays,
        maxWithdrawalAmount,
      ),
    [accuredSalary, cycleIndex, dailyBalanceInfo, maxWithdrawalAmount],
  );

  const [getDailyBalanceByCycle] = useLazyQuery(DAILY_BALANCE_PAYMENTCYCLE, {
    onCompleted: (data) => {
      if (error) {
        setError(false);
      }
      setDailyBalanceInfo({
        salaryWithCappedAmount: data?.gg_daily_balance[0]?.salaryWithCappedAmount,
        todayBalanceFloor: Math.floor(data?.gg_daily_balance[0]?.availableBalance),
        workingDays: data?.gg_daily_balance[0]?.circleDays,
        workedDays: data?.gg_daily_balance[0]?.totalWorkingDay,
        maxWithdrawalAmount: data?.gg_daily_balance[0]?.maxWithdrawalAmount,
        usedBalance: data?.gg_daily_balance[0]?.usedBalance,
      });
    },
    onError: () => {
      setError(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [getPaymentCycless, { error: errorCycle, loading: loadingCycle }] = useLazyQuery(GET_PAYMENT_CYCLES, {
    variables: {
      id: employeeId,
    },
    onCompleted: (data) => {
      const empCycle = [];
      data.gg_payment_cycle.forEach((item) => {
        empCycle.push({
          id: item.id,
          startDate: item.firstWorkingDate,
          endDate: item.endWorkingDate,
        });
      });
      dispatch(
        setFilter({
          cycles: [
            {
              id: data.gg_payment_cycle[0].id,
              firstWorkingDate: data.gg_payment_cycle[0].firstWorkingDate,
              endWorkingDate: data.gg_payment_cycle[0].endWorkingDate,
            },
          ],
        }),
      );
      dispatch(setEmpPaymentCycle(empCycle));
      setCurrentCycle({
        id: data.gg_payment_cycle[0].id,
        startDate: data.gg_payment_cycle[0].firstWorkingDate,
        endDate: data.gg_payment_cycle[0].endWorkingDate,
      });

      getDailyBalanceByCycle({
        variables: {
          paymentCycleId: data.gg_payment_cycle[0].id,
        },
      });
      getData(true, 1, false, false, true);
    },
    onError: (error) => {},
    fetchPolicy: 'no-cache',
  });
  const [getSavingAmount, { variables }] = useLazyQuery(GET_SAVING_GRAPH_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data) return;
      const info = {};
      const infoCycle = paymentCycles;
      infoCycle.map((cycle, index) => {
        info[`cycle_${index}`] = [];
      });
      data['gg_transaction_campaign_group_view'].forEach((item) => extractMonthlyData(infoCycle, info, item));
      data['gg_transaction_saving_group_view'].forEach((item) => extractMonthlyData(infoCycle, info, item));
      const totalSaving = groupByMonthlyData(info);

      setSavingAmount(totalSaving);
      setLoadingSaving(false);
    },
    onError: (err) => {
      setLoadingSaving(false);
    },
  });
  const t = translate();
  const isCheckedReasons = useMemo(() => {
    const expenseCount = reasons.expense.filter((e) => e.isChecked === true).length;
    const incomeCount = reasons.income.filter((e) => e.isChecked === true).length;

    return {
      expense: expenseCount === reasons.expense.length,
      income: incomeCount === reasons.income.length,
      expenseCount,
      incomeCount,
    };
  }, [reasons]);

  const filterCheck = useMemo(() => {
    let obj = {
      count: 0,
      reasons: false,
      dateRange: false,
      cycles: false,
    };

    if (
      isCheckedReasons.expenseCount + isCheckedReasons.incomeCount !==
      reasons.expense.length + reasons.income.length
    ) {
      obj = {
        ...obj,
        reasons: true,
        count: (obj?.count ?? 0) + 1,
      };
    }
    if (filter['dateRange']?.startDate?.length > 0) {
      obj = {
        ...obj,
        dateRange: true,
        count: (obj?.count ?? 0) + 1,
      };
    }
    if (filter['cycles']?.length > 0) {
      obj = {
        ...obj,
        cycles: true,
        count: (obj?.count ?? 0) + 1,
      };
    }

    return obj;
  }, [filter, isCheckedReasons, reasons]);

  useEffect(() => {
    if (filter?.cycles?.length === 1) {
      if (currentCycle?.id !== filter?.cycles[0]?.id) {
        const findIndex = paymentCycles.findIndex((e) => e.id === filter?.cycles[0]?.id);
        if (findIndex !== -1) {
          setCycleIndex(findIndex);
        }
        setCurrentCycle({
          id: filter.cycles[0].id,
          startDate: filter.cycles[0].firstWorkingDate,
          endDate: filter.cycles[0].endWorkingDate,
        });
      }
    }
  }, [filter]);

  const getReasonsList = useCallback(async () => {
    try {
      const response = await Promise.all(dispatch(getReasons('EXPENSE')), dispatch(getReasons()));
    } catch (error) {}
  }, []);

  const onChange = useCallback(
    (event, selectedDate, type) => {
      try {
        if (event.type !== 'dismissed') {
          const newFilter = { ...filter };
          delete newFilter.cycles;
          if (type === 'START') {
            dispatch(
              setFilter({
                ...newFilter,
                dateRange: {
                  endDate: newFilter?.dateRange?.endDate ?? currentCycle.endDate,
                  startDate: moment(selectedDate).format('YYYY-MM-DD'),
                },
              }),
            );
          } else {
            dispatch(
              setFilter({
                ...newFilter,
                dateRange: {
                  startDate: newFilter?.dateRange?.startDate ?? currentCycle.startDate,
                  endDate: moment(selectedDate).format('YYYY-MM-DD'),
                },
              }),
            );
          }
        } else {
          const newFilter = { ...filter };
          dispatch(
            setFilter({
              ...newFilter,
              cycles: [
                {
                  id: paymentCycles[0].id,
                  firstWorkingDate: paymentCycles[0].startDate,
                  endWorkingDate: paymentCycles[0].endDate,
                },
              ],
            }),
          );
          setSelectedRadio(-1);
        }
      } catch (error) {}
    },
    [filter, currentCycle],
  );

  useEffect(() => {
    getPaymentCycless();

    (reasons.income.length === 0 || reasons.expense.length === 0) && getReasonsList();

    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.transactionPage.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.transactionPage.attributes.source]: 'navbar',
    });
  }, []);

  useEffect(() => {
    if (!paymentCycles?.length) return;
    const getLastCycle = paymentCycles.filter((cycle, index) => {
      if (!index) return false;

      return (
        new Date(cycle.startDate).getFullYear() === 2021 &&
        new Date(paymentCycles[index - 1].startDate).getFullYear() === 2022
      );
    });

    let from = paymentCycles[paymentCycles.length - 1].startDate;
    if (getLastCycle.length) {
      from = getLastCycle[0].startDate;
    }

    getSavingAmount({
      variables: {
        from,
        to: paymentCycles[0].endDate,
      },
    });
  }, [paymentCycles]);

  const onOpen = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.FilterClicked.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.FilterClicked.attributes.source]: 'transaction_page',
    });
    setModal({ visible: true, type: MODAL_TYPE.FILTER });
  };

  const getData = useCallback(
    async (isInitialCall = true, customPage, isRefresh = false, reload = false, fetchWithoutReasons = false) => {
      try {
        error && setError(false);
        !isInitialCall && !isRefresh && setLoadingList(true);
        !isInitialCall && !isRefresh && setLoadingMain(true);
        isRefresh && setRefresh(true);
        reload && setLoadingMain(true);
        reload && setLoadingList(true);
        const balances = await dispatch(getTransactionSummary(fetchWithoutReasons));
        setLoadingMain(false);

        const response = await dispatch(
          getTransactionHistory(
            LIMIT,
            customPage ? customPage : page,
            false,
            fetchWithoutReasons,
            getOffSetForGraphql(isRefresh, customPage, offSet),
            setOffSet,
          ),
        );

        response?.data?.rows.length > 0 && setPage(page + 1);
        isRefresh && setRefresh(false);
        setLoadingList(false);
      } catch (error) {
        setLoadingMain(false);
        setError(true);
      }
    },
    [filter, transactionList, page, reasons, isCheckedReasons],
  );

  const footer = useCallback(() => {
    if (isLoadingFooter) {
      return <ActivityIndicator color={colors.blue} size={'small'} style={{ marginVertical: 10 }} />;
    }
    return null;
  }, [isLoadingFooter]);

  const getFilterParams = () => {
    const filterParam = [];
    for (let key in filterCheck) {
      if (filterCheck[key] && key != 'count') {
        if (key == 'cycles') key = 'siklus';
        if (key == 'dateRange') key = 'tanggal';
        filterParam.push(key);
      }
    }
    return filterParam.join(',');
  };

  const fetchDataByFilter = useCallback(
    (isFromRefresh = false) => {
      setModal({ visible: false, type: modal.type });
      !isFromRefresh &&
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.name, {
          [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.source]: 'transaction_page',
          [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_parameter]: getFilterParams(),
          [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_input]: {
            ...filter,
            kategory:
              isCheckedReasons.expenseCount + isCheckedReasons.incomeCount ===
              reasons.expense.length + reasons.income.length
                ? 'Semua Kategori'
                : `${isCheckedReasons.expenseCount + isCheckedReasons.incomeCount} Kategori`,
          },
        });
      setPage(1);
      getData(
        false,
        1,
        false,
        false,
        isCheckedReasons.expenseCount + isCheckedReasons.incomeCount === reasons.expense.length + reasons.income.length,
      );
    },
    [page, isCheckedReasons, reasons, filter, modal],
  );

  useEffect(() => {
    //This if block optimizes api calls
    //Upon removing this, chrome shows
    //4 calls to transactions-summary (2 Preflight requests, and 2 normal requests)
    //2 calls to transactions-history (1 Preflight request, and 1 normal request)
    if (isDynamicAccountSelected) {
      fetchDataByFilter(true);
    }
  }, [currentTransactionPageSelectedAccount]);

  const loadMore = useCallback(async () => {
    try {
      const response = await dispatch(
        getTransactionHistory(
          LIMIT,
          page,
          true,
          isCheckedReasons.expenseCount + isCheckedReasons.incomeCount ===
            reasons.expense.length + reasons.income.length,
          offSet,
          setOffSet,
        ),
      );
      response?.data?.rows.length > 0 && setPage(page + 1);
      setIsLoadingFooter(false);
    } catch (error) {
      setIsLoadingFooter(false);
    }
  }, [page, isCheckedReasons, reasons]);

  const { appTourParams } = useSelector((state) => state.Common);

  useEffect(() => {
    if (appTourParams.type == APP_TOUR_TYPE.savings && appTourParams.step == 2) {
      setTourVisible({ type: SAVINGS_TOUR_TYPES.TYPE3, visible: true });
    }
  }, [appTourParams]);

  const dateLeft = () => {
    const left = cycleIndex + 1;
    setCycleIndex(left);
    setCurrentCycle(paymentCycles[left]);
    setPage(1);
    const newFilter = { ...filter };
    delete newFilter.dateRange;
    dispatch(
      setFilter({
        ...newFilter,
        cycles: [
          {
            id: paymentCycles[left]?.id,
            firstWorkingDate: paymentCycles[left]?.startDate,
            endWorkingDate: paymentCycles[left]?.endDate,
          },
        ],
      }),
    );

    getDailyBalanceByCycle({
      variables: {
        paymentCycleId: paymentCycles[left].id,
      },
    });

    getData(false, 1, false, false, true);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.source]: 'transaction_page',
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.start_cycle_date]:
        paymentCycles[left]?.startDate ?? '',
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.end_cycle_date]: paymentCycles[left]?.endDate ?? '',
    });
  };

  const dateRight = () => {
    const right = cycleIndex - 1;
    setCycleIndex(right);
    setCurrentCycle(paymentCycles[right]);
    setPage(1);
    const newFilter = { ...filter };
    delete newFilter.dateRange;
    dispatch(
      setFilter({
        ...newFilter,
        cycles: [
          {
            id: paymentCycles[right].id,
            firstWorkingDate: paymentCycles[right].startDate,
            endWorkingDate: paymentCycles[right].endDate,
          },
        ],
      }),
    );

    getDailyBalanceByCycle({
      variables: {
        paymentCycleId: paymentCycles[right].id,
      },
    });
    getData(false, 1, false, false, true);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.source]: 'transaction_page',
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.start_cycle_date]:
        paymentCycles[right]?.startDate ?? '',
      [AMPLITUDE_CONSTANTS.TransactionPage.CycleChanged.attributes.end_cycle_date]: paymentCycles[right]?.endDate ?? '',
    });
  };

  const onSelectFilterCycle = useCallback(
    (item) => {
      setSelectedRadio(-1);
      const findIndex = filter?.cycles?.findIndex((e) => e.id === item.id);
      const newFilter = { ...filter };
      const newCycles = filter?.cycles?.slice() ?? [];
      if (findIndex !== -1) {
        newCycles.splice(findIndex, 1);
      } else {
        newCycles.push({
          id: item.id,
          firstWorkingDate: item.startDate,
          endWorkingDate: item.endDate,
        });
      }
      delete newFilter.dateRange;

      newCycles.length > 1 && setSelectedRadio(-1);
      dispatch(
        setFilter({
          ...newFilter,
          cycles: newCycles,
        }),
      );
    },
    [filter],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <TransactionItem
        item={item}
        onPress={() => {
          AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.TransactionClicked.name, {
            [AMPLITUDE_CONSTANTS.TransactionPage.TransactionClicked.attributes.source]: 'transaction_page',
            [AMPLITUDE_CONSTANTS.TransactionPage.TransactionClicked.attributes.transaction_id]:
              item?.transactionId ?? '',
          });
          navigation.navigate('TransactionList', {
            screen: 'EditTransaction',
            params: { item },
          });
        }}
      />
    ),
    [transactionList],
  );

  const openDate = (type) => {
    // DateTimePickerAndroid.open({
    //   value: date,
    //   onChange: (event, selectedDate) => onChange(event, selectedDate, type),
    //   mode: 'date',
    //   is24Hour: true,
    // });
  };
  const closeModal = () => {
    setModal({ visible: false, type: MODAL_TYPE.FILTER });
  };
  const onCancel = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.userCanceledFilters.name);
    closeModal();
  };

  const onTourCloseHandler = () => {
    setTourVisible({ visible: false, type: tourVisible.type });
    dispatch(resetAppTourParams());
  };

  const openTooltip = (type) => setToolTip({ isVisible: true, type });
  const closeTooltip = () => {
    setToolTip({ isVisible: false });
  };
  const IncomeExpenseDetailsComponent = useCallback(
    () => (
      <View style={styles.twIncExpContainerStyle}>
        <IncomeExpenseItem
          container={{ marginEnd: 5 }}
          isIncome
          title={'Total Pendapatan'}
          amount={`Rp${numberWithDots(totalIncome)}`}
          hasInfo
        />
        <Divider horizontal={false} customStyle={styles.twIncExpDividerStyle} />
        <IncomeExpenseItem hasInfo={false} title={'Total Pengeluaran'} amount={`Rp${numberWithDots(totalExpense)}`} />
      </View>
    ),
    [balance, totalExpense, totalIncome, dailyBalanceInfo, accuredSalary, cycleIndex],
  );

  const onEndReached = useCallback(() => {
    if (!onEndReachedCalledDuringMomentum) {
      if (!loadingList && !isLoadingFooter) {
        setIsLoadingFooter(true);
        loadMore();
      }
      setOnEndReachedCalledDuringMomentum(true);
    }
  }, [onEndReachedCalledDuringMomentum, loadingList, isLoadingFooter, totalPages]);

  const headerModal = () => {
    if (modal.type === MODAL_TYPE.FILTER) {
      return null;
    }
    return (
      <View style={{ paddingVertical: 15 }}>
        <TouchableOpacity
          onPress={() => setModal({ visible: true, type: MODAL_TYPE.FILTER })}
          style={{ marginHorizontal: 10 }}
        >
          <MIcon name="arrow-back" size={25} />
        </TouchableOpacity>

        <MyText h4 customStyle={{ marginVertical: 5, paddingHorizontal: 10 }} isRegular={false}>
          Pilih : Kategori
        </MyText>
        <MyText h2 customStyle={{ paddingHorizontal: 10 }}>
          Bisa pilih lebih dari satu
        </MyText>
        <Divider horizontal customStyle={{ marginTop: 10 }} />
      </View>
    );
  };

  const onReset = useCallback(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterReset.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.filterReset.attributes.source]: 'transaction_page',
    });

    dispatch(setAllExpense(true));
    dispatch(setAllIncome(true));
    dispatch(
      setFilter({
        cycles: [
          {
            id: currentCycle.id,
            firstWorkingDate: currentCycle.startDate,
            endWorkingDate: currentCycle.endDate,
          },
        ],
      }),
    );
    setSelectedRadio(-1);
    getData(false, 1, false, false, true);
    setModal({ visible: false, type: MODAL_TYPE.FILTER });
  }, [filter, currentCycle]);

  const setdateRange = useCallback(
    (radioType) => {
      const newFilter = { ...filter };
      if (radioType === 0) {
        delete newFilter.cycles;
        dispatch(
          setFilter({
            ...newFilter,
            dateRange: {
              startDate: paymentCycles[paymentCycles.length - 1].startDate,
              endDate: paymentCycles[0].endDate,
            },
          }),
        );
      } else if (radioType === 1) {
        delete newFilter.cycles;
        dispatch(
          setFilter({
            ...newFilter,
            dateRange: {
              startDate: newFilter?.dateRange?.startDate ?? currentCycle.startDate,
              endDate: newFilter?.dateRange?.endDate ?? currentCycle.endDate,
            },
          }),
        );
      }
    },
    [filter, currentCycle, paymentCycles],
  );

  const getCycleIds = useMemo(() => filter?.cycles?.map((e) => e.id) ?? [], [filter.cycles]);

  const onRefresh = () => {
    getDailyBalanceByCycle({
      variables: {
        paymentCycleId: currentCycle.id,
      },
    });

    getData(
      false,
      1,
      true,
      false,
      isCheckedReasons.expenseCount + isCheckedReasons.incomeCount === reasons.expense.length + reasons.income.length,
    );
  };

  const listEmpty = () => <MyText customStyle={{ alignSelf: 'center', marginTop: 15 }}>Data tidak tersedia</MyText>;

  const transactionDynamicAccounts = useMemo(
    () =>
      getDynamicAccountListByAccount(
        dataAccounts,
        TRANSACTION_LIST_DYNAMIC_ACCOUNTS,
        DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
      ),
    [dataAccounts],
  );

  const onPressFilter = () => {
    setAccountDropDown(true);
    const e = AMPLITUDE_CONSTANTS.account_drop_down_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
    });
  };

  const onPressAccountDropdown = (item) => {
    if (item.constantAccountName === currentTransactionPageSelectedAccount.constantAccountName) return;
    setAccountDropDown(!accountDropDown);
    dispatch(setCurrentTransactionPageSelectedAccount(item));
    fetchDataByFilter(false);
    const e = AMPLITUDE_CONSTANTS.account_drop_down_selected;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transaction_page,
      [e.attributes.dynamic_account_name]: item?.amplitudeAccountName ?? '',
    });
  };

  return (
    <>
      <MainParentWrapper
        onReload={() => {
          onMainParentReload(paymentCycles, getDailyBalanceByCycle, currentCycle, getData);
        }}
        isError={shouldCheckErrorCycle(error, errorCycle)}
        loading={shouldCheckLoading(loadingMain, loadingCycle)}
        containerStyle={styles.twPaddingTop0}
      >
        {shouldShowDateSelectorForCurrentCycle(
          currentCycle,
          filter,
          filterCheck,
          cycleIndex,
          paymentCycles,
          dateLeft,
          dateRight,
        )}
        <View style={styles.twBalanceStyles}>
          {balanceComponent(
            toolTip,
            closeTooltip,
            openTooltip,
            filter,
            filterCheck,
            balance,
            isDynamicAccountSelected ? balance : newAvailableBalance,
            isDynamicAccountSelected ? currentTransactionPageSelectedAccount.accountName : undefined,
          )}
          {IncomeExpenseDetailsComponent()}
        </View>
        {/* {proratedDetailComponent(newAccruedSalary, accuredSalary, isDynamicAccountSelected)} */}
        <Divider horizontal customStyle={{ marginTop: 10, height: 5 }} />

        {filterTransactionListComponent(onPressFilter, currentTransactionPageSelectedAccount, loadingList)}

        {shouldShowFlatListOrLoader({
          loadingList,
          listEmpty,
          refresh,
          onRefresh,
          setOnEndReachedCalledDuringMomentum,
          transactionList,
          renderItem,
          onEndReached,
          footer,
        })}

        <MyModal
          containerStyle={styles.twModalContainerStyle}
          onPress={onCancel}
          isVisible={modal.visible}
          onModalHide={fetchDataByFilter}
        >
          <View style={styles.twFilterModalParent}>
            {headerModal()}
            {shouldShowFilterModal({
              modal,
              isCheckedReasons,
              reasons,
              onSelectFilterCycle,
              getCycleIds,
              onReset,
              filter,
              selectedRadio,
              currentCycle,
              setSelectedRadio,
              setdateRange,
              onCancel,
              collapsed,
              setCollapsed,
              closeModal,
              setModal,
              paymentCycles,
              openDate,
            })}
          </View>
        </MyModal>

        <TourModal
          totalIncome={totalIncome}
          totalExpense={totalExpense}
          accuredSalary={newAccruedSalary}
          numOfDots={5}
          onClose={onTourCloseHandler}
          onPressButtonColor={onTourCloseHandler}
          setTourVisible={setTourVisible}
          hasCongrats
          type={tourVisible.type}
          isVisible={tourVisible.visible}
          balance={newAvailableBalance}
          date={shouldGetFilterAppliedDate(filter, filterCheck, currentCycle)}
        />

        <DropDownModal
          visible={accountDropDown}
          data={transactionDynamicAccounts}
          hasIcon
          onPressItem={onPressAccountDropdown}
          onClose={() => {
            setAccountDropDown(false);
            const e = AMPLITUDE_CONSTANTS.cancel_button_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.cancelButtonSource.account_drop_down_transaction_page,
            });
          }}
          selectedAccount={currentTransactionPageSelectedAccount}
        />
      </MainParentWrapper>
      <LoadingModal loading={loadingMain} />
    </>
  );
};

export default TransactionList;
