import React from 'react';
import { Text } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
/* Styles import */
import { ScreenOptions } from '@style/Themes';
import { useSelector } from 'react-redux';
import { normalize } from '@style/helper';
import { Font } from '@style/Fonts';
import HeaderRight from '@components/HeaderRight';
import NewDashboard from '@screens/NewDashboard';
import { useNavigation } from '@react-navigation/native';
import ChatScreen from '@screens/ChatScreen';

const Stack = createStackNavigator();

const WithdrawStack = () => {
  const navigation = useNavigation();
  const employerId = useSelector((state) => state.Authentication.employerId);

  return (
    <Stack.Navigator headerMode="float">
      <Stack.Screen
        name="Dashboard"
        listeners={{
          focus: () => navigation.setOptions({ tabBarVisible: true }),
        }}
        component={NewDashboard}
        options={({ route }) => ({
          ...ScreenOptions,
          headerStyle: {
            ...ScreenOptions.headerStyle,
            elevation: 0,
          },
          headerLeft: () => (
            <Text
              style={{
                color: '#FFF',
                fontSize: normalize(17),
                marginLeft: 30,
                fontFamily: Font.Nunito_Bold,
              }}
            >
              {employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}
            </Text>
          ),

          headerRight: () => <HeaderRight appTourVisible />,
        })}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{
          headerStyle: {
            ...ScreenOptions.headerStyle,
            elevation: 0,
          },
          headerTitle: 'Chat Support',
          headerTintColor: 'white',
          headerTitleStyle: { color: 'white' },
          headerBackTitleStyle: { color: 'white' },
        }}
      />
    </Stack.Navigator>
  );
};

export default WithdrawStack;
