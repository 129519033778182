import React from 'react';
import { Text, View, Linking, StyleSheet } from 'react-native';

/* Styles */
import { SmCard } from '@style/Themes';
import IconButton from '@atoms/IconButton';
import { normalize, normalizeHeight } from '@style/helper';
import { useSelector } from 'react-redux';

const Account = ({ show }) => {
  const { phoneNumber, userName } = useSelector((state) => state.Authentication);

  return (
    <>
      {show && (
        <>
          <View style={[SmCard, styles.container]}>
            <IconButton icon={'mobile-alt'} size={30} style={styles.iconButton} iconStyle={{ color: '#F56B57' }} />

            <View style={styles.nameContainer}>
              {userName && <Text style={styles.iconName}>{userName}</Text>}
              <Text accessibilityLabel="accountNumberText" style={styles.iconPhoneNumber}>
                {phoneNumber}
              </Text>
            </View>

            <IconButton
              testId="accountSupportButton"
              icon={'times-circle'}
              onPress={() => Linking.openURL('https://api.whatsapp.com/send?&phone=6281315276948')}
              style={styles.support}
              iconStyle={{ color: '#F56B57' }}
            />
          </View>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 5,
    height: normalizeHeight(60),
    alignItems: 'center',
  },
  nameContainer: { display: 'flex', flexDirection: 'column', flex: 0.7 },
  iconButton: { flex: 0.15, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' },
  iconName: {
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(17),
    color: '#F56B57',
    marginLeft: 10,
  },
  iconPhoneNumber: {
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(17),
    color: '#454545',
    marginLeft: 10,
  },
  support: { flex: 0.15, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' },
});

export default Account;
