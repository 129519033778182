import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { store } from '@enhancedStore/store';
import { useFonts } from 'expo-font';
import { Font } from '@utils/Fonts';
import Constants from 'expo-constants';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import * as Sentry from 'sentry-expo';
import { Integrations } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactPWAInstallProvider from 'react-pwa-install';
import {
  setEnvironmentInShared,
  setXAPIKey,
  setStorageHandler,
  setInterceptorCallBacks,
} from '@gaji-gesa/gg-react-shared';
import WelcomeScene from '@scenes/welcome';
import { setPWAUpdated } from '@redux/action/Common';
import { Alert } from 'react-native';
import PropTypes from 'prop-types';
import { gqClient } from './src/services/api';
import AmplitudeHelper from './src/services/amplitude';
import * as serviceWorkerRegistration from './web/serviceWorkerRegistration';
import { useServiceWorker } from './src/swHook';

const xApiKey = '87654321';
const prefix = Linking.createURL('/');
AmplitudeHelper.init();

Sentry.init({
  dsn: 'https://6e2c73b1ff5648bb95f4798c72c18444@o4505288154021888.ingest.sentry.io/4505310861983744',
  integrations: [new Integrations.BrowserTracing()],
  debug: false,
  maxBreadcrumbs: 150, // Extend from default 100 breadcrumbs.
  environment: config.ENV_SENTRY,
  // Release Health
  enableAutoSessionTracking: true,
  // For testing, session close when 5 seconds (instead of the default 30) in the background.
  sessionTrackingIntervalMillis: 5000,
  tracesSampleRate: 1.0,
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') {
      const data = {
        requestBody: hint?.xhr?.__sentry_xhr__?.body,
        response: hint?.xhr?.response,
        responseUrl: hint?.xhr?.responseURL,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  },
});

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

function onRejectCallBack(axios, axiosInstance) {
  return async (error) => {
    const refreshToken = await localStorage.getItem('refreshToken');
    if (error.response.status == 401 && error.response.data.error == 'UNAUTHORIZED_ACCESS_TOKEN_JWT') {
      let result = await axios({
        method: 'post',
        url: `${env.API_URL}/auth/refresh`,
        headers: {
          'x-api-key': xApiKey,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      const newToken = result.data.data.accessToken;
      await localStorage.setItem('tokens', newToken);
      error.config.headers.Authorization = `Bearer ${newToken}`;
      return axiosInstance(error.config);
    }
    return error;
  };
}

function onFulfilledCallBack() {
  return (response) => response;
}
setEnvironmentInShared(Constants.expoConfig.extra);
setXAPIKey(xApiKey);
setStorageHandler(AsyncStorage);
setInterceptorCallBacks(onFulfilledCallBack, onRejectCallBack);

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.string.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default function App(props) {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const closeToast = () => {
    //this tells that our cached version is the latest, no need to reload
    store.dispatch(setPWAUpdated(true));
  };
  const notifyUpdate = () => {
    //this tells redux, that the cached version of PWA is not the latest, hence please reload to update
    store.dispatch(setPWAUpdated(false));
  };

  // Follow this link to know how to create links in expo with react-native-web
  // https://reactnavigation.org/docs/web-support
  const linking = {
    prefixes: [prefix],
  };
  useFonts({
    [Font.Nunito_Bold]: require('@assets/fonts/Nunito-Bold.ttf'),
    [Font.Nunito_Regular]: require('@assets/fonts/Nunito-Regular.ttf'),
  });
  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      notifyUpdate();
    } else closeToast();
  }, [waitingWorker, showReload, reloadPage]);
  useEffect(() => {
    LogRocket.init('gfvm9c/gajigesaweb');
    setupLogRocketReact(LogRocket);
  }, []);

  return Sentry.Browser.wrap(() => (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <ReactPWAInstallProvider enableLogging>
        <ApolloProvider client={gqClient}>
          <Provider store={store}>
            <NavigationContainer linking={linking}>
              <WelcomeScene />
            </NavigationContainer>
          </Provider>
        </ApolloProvider>
      </ReactPWAInstallProvider>
    </ErrorBoundary>
  ));
}

serviceWorkerRegistration.register({
  onSuccess: () => {
    console.log('Successfully installed');
  },
  onUpdate: (reg) => {
    console.log('registered sw', reg);
    Alert.alert(
      'Update available',
      'Gaji-Gesa updated in the back-end',
      [
        {
          text: 'Update',
          onPress: () => {
            console.log('Should update app here');
          },
        },
        { text: 'Cancel' },
      ],
      {
        cancelable: true,
        onDismiss: () => {
          console.log('Update dismissed');
        },
      },
    );
  },
});
