import React, { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, FlatList, Switch, Pressable } from 'react-native';
import { normalize } from '@style/helper';
import { formatUnderScoreLowerCase, getCurrentTimeStamp, numberWithDots } from '@utils/UtilFunctions';
// import LottieView from 'lottie-react-native';

/* Component Import */
import Terms from './Terms';

/* REDUX */
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateProduct } from '@redux/action/BillPayments';
import { respError, setAcceptedTerms, setParentPage, setShowTerms } from '@redux/action/Common';
import { resetInquiry } from '@redux/action/Transactions';
import { confirmCoinTransaction, confirmTransaction } from '@services/TransactionApi';
import { setGlobalLoading } from '@redux/action/Common';

/* Style Import */
import { LgButton, CheckBox, CheckedBox } from '@components/Button';
import AmplitudeHelper from '@services/amplitude';
import { setUseCoin } from '@redux/action/Coins';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { useLazyQuery } from '@apollo/client';
import { GET_COINS, GET_USER_ID } from '@gqlQuery/query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SentryHelper } from '@utils/SentryHelper';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const PulsaContent = ({ handleClose, modalRef }) => {
  const dispatch = useDispatch();
  const coinBombRef = useRef();

  const trans = useSelector((state) => state.Transactions);
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);

  const common = useSelector((state) => state.Common);
  const { coinBalance, useCoin } = useSelector((state) => state.Coins);
  const { phoneNumber, employeeId } = useSelector((state) => state.Authentication);

  // Amplitude Variable
  let ampEvent = AMPLITUDE_CONSTANTS.BillPayment.userClickedPembayaranTermsCheckbox;
  let ampObject = {
    [ampEvent.attributes.source]: 'other_product_page',
    [ampEvent.attributes.timestamp]: getCurrentTimeStamp(),
    [ampEvent.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
  };

  const [getUserId, { error, loading }] = useLazyQuery(GET_USER_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      empId: employeeId,
    },
    onCompleted: async (data) => {
      await AsyncStorage.setItem('@userId', JSON.stringify(data.gg_employee[0].userId));
      callCoinTransaction(data.gg_employee[0].userId);
    },
  });

  useEffect(() => {
    dispatch(setUseCoin(false));
    if (common.acceptedTerms) {
      dispatch(setAcceptedTerms());
    }
  }, []);

  const callCoinTransaction = async (userId) => {
    try {
      handleClose();
      dispatch(setGlobalLoading(true));
      const response = await dispatch(confirmCoinTransaction(trans.inquiry?.subtotal, userId));
    } catch (error) {
      SentryHelper.captureCustomException('poin_payment_failed', {
        employee_id: employeeId,
        product: selectedProduct.code,
        error_message: error?.response?.status ?? 'network_error',
      });
      // AmplitudeHelper.logEvent("poin_payment_failed", {
      //   employee_id: employeeId,
      //   product: selectedProduct.code,
      //   error_message: error?.response?.status ?? "network_error",
      // });
      let e = AMPLITUDE_CONSTANTS.Poin.PoinPaymentFailed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
        [e.attributes.failure_message]: error?.response?.status ?? 'network_error',
        [e.attributes.point_balance]: coinBalance,
        [e.attributes.point_used]: trans.inquiry?.subtotal,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
        [e.attributes.employee_id]: employeeId,
      });
    }
  };

  const useCoinForTransaction = async () => {
    try {
      const userId = await AsyncStorage.getItem('@userId');
      if (userId) {
        callCoinTransaction(userId);
      } else {
        getUserId();
      }
    } catch (error) {}
  };

  const handleTerms = () => {
    modalRef.current.snapTo(2);
    dispatch(setShowTerms(true));
  };

  const handleTermsClose = () => {
    modalRef.current.snapTo(0);
    dispatch(setShowTerms(false));
    setValidateCheck(false);
  };

  const handleBatal = () => {
    // if (trans.productName == "PLN Postpaid") {
    //   AmplitudeHelper.logEvent("postpaidElectricityPaymentCancelled");
    // } else if (trans.inquiry.category == "PDAM") {
    //   AmplitudeHelper.logEvent("waterBillPaymentCancelled");
    // }

    let e = AMPLITUDE_CONSTANTS.BillPayment.userCanceledBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.button]: 'batal',
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
    });

    batch(() => {
      dispatch(resetInquiry());
      dispatch(updateProduct({}));
    });
    handleClose();
  };

  const [validateCheck, setValidateCheck] = useState(false);

  const renderNameItem = (item) => {
    return (
      <View
        style={{
          ...styles.row,
          borderBottomColor: item.index % 2 === 0 ? 'white' : 'black',
          borderBottomWidth: item.index % 2 === 0 ? 0 : 1,
        }}
      >
        <Text style={styles.rowTitle}>{item.item.key}</Text>
        <Text style={styles.rowValue}>{item.item.value}</Text>
      </View>
    );
  };

  const Footer = () => {
    return (
      <>
        <Text style={styles.title}>Detail Pembayaran</Text>
        <View style={{ alignSelf: 'center' }}>
          <View style={styles.row}>
            <Text style={styles.rowTitleTwo}>
              {trans.productName == 'PLN Postpaid' ? 'Tagihan PLN' : 'Tagihan PDAM'}
            </Text>
            <Text accessibilityLabel="productPriceText" style={styles.productValue}>
              Rp. {numberWithDots(trans.inquiry ? trans.inquiry.subtotalPostpaid : '')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.productTitle}>Biaya Admin</Text>
            <Text accessibilityLabel="adminFeeText" style={{ ...styles.productValue, flex: 0.7 }}>
              Rp. {numberWithDots(trans.inquiry ? trans.inquiry.totalFeePostpaid : '')}
            </Text>
          </View>
          <View
            style={{
              ...styles.row,
              borderBottomWidth: 1,
              borderColor: 'black',
            }}
          >
            <Text style={styles.productTitle}>Total Bayar</Text>
            <Text accessibilityLabel="totalAmountText" style={{ ...styles.productValue, flex: 0.7 }}>
              Rp. {numberWithDots(trans.inquiry ? trans.inquiry.requestAmount : '')}
            </Text>
          </View>
        </View>
        <View style={styles.subTotalRow}>
          <View style={styles.subTotalSection}>
            <Text style={styles.subTotalTitle}>Dipotong Gaji</Text>
            <Text
              accessibilityLabel="salaryDeductionText"
              style={[
                styles.subTotalValue,
                {
                  textDecorationLine: useCoin ? 'line-through' : null,
                  color: useCoin ? 'red' : 'black',
                },
              ]}
            >
              Rp. {numberWithDots(trans.inquiry ? trans.inquiry.requestAmount : '')}
            </Text>
          </View>
        </View>

        {selectedProduct.categoryPrefix !== 'BP-15' && (
          <Pressable
            onPress={() => {
              if (!useCoin) {
                // AmplitudeHelper.logEvent("poin_payment_ticked", {
                //   employee_id: employeeId,
                //   product: selectedProduct.code,
                // });
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.name, {
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.employee_id]: employeeId,
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_balance]: coinBalance,
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_used]:
                    coinBalance === 0 || coinBalance < trans.inquiry.requestAmount,
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.product_code]: selectedProduct.code ?? null,
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.transaction_category_id]:
                    formatUnderScoreLowerCase(selectedProduct.categoryPrefix),
                  [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.source]: 'other_product_page',
                });

                coinBombRef.current.play(0, 71);
                dispatch(setUseCoin(!useCoin));
              } else {
                dispatch(setUseCoin(!useCoin));
              }
            }}
            android_ripple={{ color: colors.lightGrey }}
            disabled={coinBalance === 0 || coinBalance < trans.inquiry.requestAmount}
            style={[
              styles.termsContainer,
              {
                justifyContent: 'space-between',
                marginBottom: 5,
                marginTop: 10,
                paddingHorizontal: 20,
                paddingVertical: 5,
              },
            ]}
          >
            {!useCoin ? (
              <CheckBox
                disabled={coinBalance === 0 || coinBalance < trans.inquiry.requestAmount}
                onPress={() => {
                  coinBombRef.current.play(0, 71);
                  AmplitudeHelper.logEvent('poin_payment_ticked', {
                    employee_id: employeeId,
                    product: selectedProduct.code,
                  });
                  dispatch(setUseCoin(!useCoin));
                }}
              />
            ) : (
              <CheckedBox
                onPress={() => {
                  dispatch(setUseCoin(!useCoin));
                }}
              />
            )}

            <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                }}
              >
                <View>{SVG_ICONS.coin3}</View>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: Font.Nunito_Regular,
                    textAlign: 'right',
                  }}
                >
                  {' '}
                  Bayar pake poin{' '}
                </Text>
              </View>

              <Text
                style={{
                  fontSize: 11,
                  fontFamily: Font.Nunito_Regular,
                  textAlign: 'right',
                  color: '#979797',
                }}
              >{`Jumlah Poin: ${numberWithDots(coinBalance)}`}</Text>
              {coinBalance < trans.inquiry.requestAmount && (
                <MyText h2 customStyle={{ color: 'red' }}>
                  Poinmu tidak mencukupi
                </MyText>
              )}
            </View>
            <View />
            {/* <LottieView
              loop={false}
              source={require('@assets/86143-coin-bust.json')}
              ref={coinBombRef}
              style={{
                position: 'absolute',
                height: 150,
                width: 150,
                bottom:
                  trans.inquiry.category == 'Voucher Digital' || trans.inquiry.category == 'Streaming' ? '35%' : '30%',
                end: '15%',
              }}
            /> */}
          </Pressable>
        )}

        <Pressable
          onPress={() => {
            if (common.acceptedTerms) {
              // AmplitudeHelper.logEvent("userClickedPembayaranTermsCheckbox");

              AmplitudeHelper.logEvent(ampEvent.name, ampObject);
              //acceptTerms(!acceptedTerms)
              dispatch(setAcceptedTerms());
            } else {
              // AmplitudeHelper.logEvent("userClickedPembayaranTermsCheckbox");
              AmplitudeHelper.logEvent(ampEvent.name, ampObject);
              handleTerms();
            }
          }}
          android_ripple={{ color: colors.lightGrey }}
          style={[
            styles.termsContainer,
            {
              justifyContent: 'space-between',
              paddingHorizontal: 20,
              paddingVertical: 5,
            },
          ]}
        >
          {common.acceptedTerms ? (
            <CheckedBox
              testId={'productTncCheckedBox'}
              onPress={() => {
                // AmplitudeHelper.logEvent("userClickedPembayaranTermsCheckbox");
                // AmplitudeHelper.logEvent(ampEvent.name, ampObject)

                let e = AMPLITUDE_CONSTANTS.BillPayment.termsCheckboxUnchecked;
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.source]: 'other_product_page',
                  [e.attributes.timestamp]: getCurrentTimeStamp(),
                  [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
                });
                //acceptTerms(!acceptedTerms)
                dispatch(setAcceptedTerms());
              }}
            />
          ) : (
            <CheckBox
              testId={'productTncCheckBox'}
              onPress={() => {
                // AmplitudeHelper.logEvent("userClickedPembayaranTermsCheckbox");

                AmplitudeHelper.logEvent(ampEvent.name, ampObject);
                handleTerms();
              }}
            />
          )}

          <Text style={{ fontSize: 14, fontFamily: Font.Nunito_Regular }}>Saya Mengkonfirmasi Rincian Transfer</Text>
        </Pressable>

        <View style={styles.buttonContainer}>
          <LgButton
            testId={'productCancelButton'}
            title="Batal"
            style={styles.buttonCancel}
            onPress={handleBatal}
            textStyle={{
              fontSize: normalize(14),
              color: '#f56b57',
            }}
          />
          <LgButton
            testId={'productKonfirmasiButton'}
            title="Konfirmasi"
            loading={loading}
            onPress={() => {
              if (common.acceptedTerms) {
                if (useCoin) {
                  // AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "poin" });
                  let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.payment_method]: 'poin',
                    [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.code),
                    [e.attributes.reason_id]: 2,
                    [e.attributes.reason]: 'tagihan_dan_utilitas',
                    [e.attributes.product_price]: selectedProduct?.amount,
                    [e.attributes.source]: 'other_product_page',
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
                    [e.attributes.fee_charged]: selectedProduct?.adminFee,
                  });
                }
                dispatch(setParentPage('billpayment'));
                // AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "accrued_salary" });
                let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.payment_method]: 'accrued_salary',
                  [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.code),
                  [e.attributes.reason_id]: 2,
                  [e.attributes.reason]: 'tagihan_dan_utilitas',
                  [e.attributes.product_price]: selectedProduct?.amount,
                  [e.attributes.source]: 'other_product_page',
                  [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
                  [e.attributes.fee_charged]: selectedProduct?.adminFee,
                });

                // if (trans.productName == "PLN Postpaid") {
                //   AmplitudeHelper.logEvent("postpaidElectricityPaymentProceed");
                // } else if (trans.inquiry.category == "PDAM") {
                //   AmplitudeHelper.logEvent("waterBillPaymentProceed");
                // }

                if (useCoin) {
                  useCoinForTransaction();
                } else {
                  handleClose();
                  dispatch(confirmTransaction());
                }
              } else {
                dispatch(respError('Tolong setujui konfirmasi Rincian Transfer'));
              }
              setValidateCheck(true);
            }}
            style={common.acceptedTerms ? styles.buttonEnabled : styles.buttonDisabled}
            textStyle={{
              fontSize: normalize(14),
            }}
          />
        </View>
      </>
    );
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.section}>
          {common.showTerms ? (
            // TERMS
            <Terms
              handleTermsClose={handleTermsClose}
              acceptTerms={() => {
                dispatch(setAcceptedTerms());
              }}
              acceptedTerms={common.acceptedTerms}
              prefix={selectedProduct?.categoryPrefix}
            />
          ) : // POSTPAID
          trans.productName == 'PLN Postpaid' || trans.inquiry.category == 'PDAM' ? (
            <>
              <Text style={styles.title}>Informasi Pelanggan</Text>
              <View style={styles.row}>
                <Text style={styles.rowTitle}>{'ID Pelanggan'}</Text>
                <Text accessibilityLabel="accountNumberText" style={styles.rowValue}>
                  {trans.userAccountNumber.length > 9
                    ? `${trans.userAccountNumber.substring(0, 9)}...`
                    : `${trans.userAccountNumber}`}
                </Text>
              </View>
              {trans.inquiry.category == 'PDAM' ? (
                <>
                  <View style={styles.row}>
                    <Text style={styles.rowTitle}>{'Nama PDAM'}</Text>
                    <Text
                      accessibilityLabel="accountNameText"
                      style={styles.rowValue}
                    >{`${trans.inquiry.productName}`}</Text>
                  </View>
                </>
              ) : null}
              <FlatList
                data={trans.inquiry.customerInfo}
                keyExtractor={(item, index) => index}
                renderItem={renderNameItem}
                ListFooterComponent={Footer}
              />
            </>
          ) : (
            // NOT POSTPAID
            <>
              <Text style={styles.title}>Informasi Pelanggan</Text>
              <View style={{ alignSelf: 'center' }}>
                {trans.inquiry.category == 'Voucher Digital' || trans.inquiry.category == 'Streaming' ? null : (
                  <View style={styles.row}>
                    <Text accessibilityLabel={'accountNumberText'} style={styles.rowTitle}>
                      {trans.inquiry.category == 'Listrik' ? 'ID Pelanggan' : 'Nomor Ponsel'}
                    </Text>
                    {trans.inquiry.category == 'Listrik' ? (
                      <Text accessibilityLabel={'accountNameText'} style={styles.accountNumberRow}>
                        {trans.userAccountNumber.length > 9
                          ? `${trans.userAccountNumber.substring(0, 9)}...`
                          : `${trans.userAccountNumber}`}
                      </Text>
                    ) : (
                      <Text accessibilityLabel={'accountNameText'} style={styles.rowValue}>
                        {phoneNumber.length > 9 ? `+${phoneNumber.substring(0, 9)}...` : `+${phoneNumber}`}
                      </Text>
                    )}
                  </View>
                )}
                <View
                  style={{
                    ...styles.row,
                    borderBottomWidth: 1,
                    borderColor: 'black',
                  }}
                >
                  <Text style={styles.productTitle}>{trans.inquiry ? trans.inquiry.productName : ''}</Text>
                  <Text accessibilityLabel={'productPrice'} style={{ ...styles.productValue, flex: 0.7 }}>
                    Rp. {numberWithDots(trans.inquiry ? trans.inquiry.requestAmount : '')}
                  </Text>
                </View>
              </View>
              <Text style={styles.title}>Detail Pembayaran</Text>
              <View style={{ alignSelf: 'center' }}>
                {trans.productName == 'Telkomsel Halo' ||
                trans.productName == 'Indosat Matrix' ||
                trans.productName == 'Smartfren Pascabayar' ||
                trans.productName == 'XL Pascabayar' ||
                trans.productName == 'Tri Pascabayar' ? (
                  <>
                    <View style={styles.row}>
                      <Text style={styles.rowTitleTwo}>Biaya Admin</Text>
                      <Text accessibilityLabel={'adminFeeText'} style={{ ...styles.text, textAlign: 'right' }}>
                        Rp. {numberWithDots(trans.inquiry ? trans.inquiry.totalFeePostpaid : '')}
                      </Text>
                    </View>
                  </>
                ) : null}
                <View style={{ ...styles.row, borderBottomWidth: 1, borderColor: '#c4c4c4', paddingBottom: 15 }}>
                  <Text style={styles.rowTitleTwo}>Total Tagihan</Text>
                  <Text
                    accessibilityLabel={'totalBill'}
                    style={{
                      ...styles.text,
                      textAlign: 'right',
                      textDecorationLine: useCoin ? 'line-through' : null,
                      color: useCoin ? 'red' : 'black',
                    }}
                  >
                    Rp. {numberWithDots(trans.inquiry ? trans.inquiry.requestAmount : '')}
                  </Text>
                </View>
              </View>
              {trans.inquiry.category == 'Voucher Digital' || trans.inquiry.category == 'Streaming' ? null : (
                <View style={styles.subTotalRow}>
                  <View style={styles.subTotalSection}>
                    <Text style={styles.subTotalTitle}>Dipotong Gaji</Text>
                    <Text
                      accessibilityLabel={'deductionSalary'}
                      style={[
                        styles.subTotalValue,
                        {
                          textDecorationLine: useCoin ? 'line-through' : null,
                          color: useCoin ? 'red' : 'black',
                        },
                      ]}
                    >
                      Rp. {numberWithDots(trans.inquiry ? trans.inquiry.requestAmount : '')}
                    </Text>
                  </View>
                </View>
              )}

              {selectedProduct.categoryPrefix !== 'BP-15' && (
                <Pressable
                  onPress={() => {
                    if (!useCoin) {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.name, {
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.employee_id]: employeeId,
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_balance]: coinBalance,
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_used]: coinBalance,
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.product_code]:
                          selectedProduct.code ?? null,
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.transaction_category_id]:
                          formatUnderScoreLowerCase(selectedProduct.categoryPrefix),
                        [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.source]: 'other_product_page',
                      });
                      coinBombRef.current.play(0, 71);
                      dispatch(setUseCoin(!useCoin));
                    } else {
                      dispatch(setUseCoin(!useCoin));
                    }
                  }}
                  android_ripple={{ color: colors.lightGrey }}
                  disabled={coinBalance === 0 || coinBalance < trans.inquiry.requestAmount}
                  style={[
                    styles.termsContainer,
                    {
                      justifyContent: 'space-between',
                      marginBottom: 5,
                      marginTop: 10,
                      paddingHorizontal: 20,
                      paddingVertical: 5,
                    },
                  ]}
                >
                  {!useCoin ? (
                    <CheckBox
                      disabled={coinBalance === 0 || coinBalance < trans.inquiry.requestAmount}
                      onPress={() => {
                        // AmplitudeHelper.logEvent("poin_payment_ticked", {
                        //   employee_id: employeeId,
                        //   product: selectedProduct.code,
                        // });
                        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.name, {
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.employee_id]: employeeId,
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_balance]: coinBalance,
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.point_used]: coinBalance,
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.product_code]:
                            selectedProduct.code ?? null,
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.transaction_category_id]:
                            formatUnderScoreLowerCase(selectedProduct.categoryPrefix),
                          [AMPLITUDE_CONSTANTS.Poin.PoinPaymentTicked.attributes.source]: 'other_product_page',
                        });
                        coinBombRef.current.play(0, 71);
                        dispatch(setUseCoin(!useCoin));
                      }}
                    />
                  ) : (
                    <CheckedBox
                      onPress={() => {
                        dispatch(setUseCoin(!useCoin));
                      }}
                    />
                  )}

                  <View>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'flex-end',
                      }}
                    >
                      <View>{SVG_ICONS.coin3}</View>
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: Font.Nunito_Regular,
                          textAlign: 'right',
                        }}
                      >
                        {' '}
                        Bayar pake poin{' '}
                      </Text>
                    </View>

                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: Font.Nunito_Regular,
                        textAlign: 'right',
                        color: '#979797',
                      }}
                    >{`Jumlah Poin: ${numberWithDots(coinBalance)}`}</Text>
                    {coinBalance < trans.inquiry.requestAmount && (
                      <MyText h2 customStyle={{ color: 'red' }}>
                        Poinmu tidak mencukupi
                      </MyText>
                    )}
                  </View>
                  <LottieView
                    loop={false}
                    source={require('@assets/86143-coin-bust.json')}
                    ref={coinBombRef}
                    style={{
                      position: 'absolute',
                      height: 150,
                      width: 150,
                      bottom:
                        trans.inquiry.category == 'Voucher Digital' || trans.inquiry.category == 'Streaming'
                          ? '35%'
                          : '30%',
                      end: '15%',
                    }}
                  />
                </Pressable>
              )}

              <Pressable
                onPress={() => {
                  if (common.acceptedTerms) {
                    // AmplitudeHelper.logEvent(
                    //   "userClickedPembayaranTermsCheckbox"
                    // );
                    AmplitudeHelper.logEvent(ampEvent.name, ampObject);
                    //acceptTerms(!acceptedTerms)
                    dispatch(setAcceptedTerms());
                  } else {
                    // AmplitudeHelper.logEvent(
                    //   "userClickedPembayaranTermsCheckbox"
                    // );
                    AmplitudeHelper.logEvent(ampEvent.name, ampObject);
                    handleTerms();
                  }
                }}
                android_ripple={{ color: colors.lightGrey }}
                style={[
                  styles.termsContainer,
                  {
                    justifyContent: 'space-between',
                    paddingHorizontal: 20,
                    paddingVertical: 5,
                  },
                ]}
              >
                {common.acceptedTerms ? (
                  <CheckedBox
                    testId={'productTncCheckedBox'}
                    onPress={() => {
                      // AmplitudeHelper.logEvent(
                      //   "userClickedPembayaranTermsCheckbox"
                      // );

                      let e = AMPLITUDE_CONSTANTS.BillPayment.termsCheckboxUnchecked;
                      AmplitudeHelper.logEvent(e.name, {
                        [e.attributes.source]: 'other_product_page',
                        [e.attributes.timestamp]: getCurrentTimeStamp(),
                        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
                          selectedProduct?.categoryPrefix,
                        ),
                      });
                      //acceptTerms(!acceptedTerms)
                      dispatch(setAcceptedTerms());
                    }}
                  />
                ) : (
                  <CheckBox
                    testId={'productTncCheckBox'}
                    onPress={() => {
                      // AmplitudeHelper.logEvent(
                      //   "userClickedPembayaranTermsCheckbox"
                      // );

                      AmplitudeHelper.logEvent(ampEvent.name, ampObject);
                      handleTerms();
                    }}
                  />
                )}

                <Text style={{ fontSize: 14, fontFamily: Font.Nunito_Regular }}>
                  Saya Mengkonfirmasi Rincian Transfer
                </Text>
              </Pressable>

              <View style={styles.buttonContainer}>
                <LgButton
                  testId={'productCancelButton'}
                  title="Batal"
                  onPress={handleBatal}
                  style={styles.buttonDisabled}
                  textStyle={{
                    fontSize: normalize(14),
                    color: '#F56b57',
                  }}
                />
                <LgButton
                  testId={'productKonfirmasiButton'}
                  title="Konfirmasi"
                  loading={loading}
                  // disabled={common.acceptedTerms ? false : true}
                  onPress={() => {
                    // if (trans.productName == "Telkomsel Halo") {
                    //   AmplitudeHelper.logEvent("postpaidPulsaPaymentProceed");
                    // }
                    // if (useCoin) {
                    //   AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "poin" });
                    // }
                    // AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "accrued_salary" });
                    //AmplitudeHelper.trackEvent('userConfirmedBillPaymentTransaction')

                    if (common.acceptedTerms) {
                      if (useCoin) {
                        // AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "poin" });
                        let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
                        AmplitudeHelper.logEvent(e.name, {
                          [e.attributes.payment_method]: 'poin',
                          [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.code),
                          [e.attributes.reason_id]: 2,
                          [e.attributes.product_price]: selectedProduct?.amount,
                          [e.attributes.source]: 'other_product_page',
                          [e.attributes.reason]: 'tagihan_dan_utilitas',
                          [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
                            selectedProduct?.categoryPrefix,
                          ),
                          [e.attributes.fee_charged]: selectedProduct?.adminFee,
                        });
                      }
                      // AmplitudeHelper.logEvent( "userConfirmedBillPaymentTransaction", { payment_method: "accrued_salary" });
                      let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
                      AmplitudeHelper.logEvent(e.name, {
                        [e.attributes.payment_method]: 'accrued_salary',
                        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.code),
                        [e.attributes.reason_id]: 2,
                        [e.attributes.product_price]: selectedProduct?.amount,
                        [e.attributes.source]: 'other_product_page',
                        [e.attributes.reason]: 'tagihan_dan_utilitas',
                        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
                          selectedProduct?.categoryPrefix,
                        ),
                        [e.attributes.fee_charged]: selectedProduct?.adminFee,
                      });

                      batch(() => {
                        dispatch(setParentPage('billpayment'));
                        if (useCoin) {
                          useCoinForTransaction();
                        } else {
                          handleClose();
                          dispatch(confirmTransaction());
                        }
                      });
                    } else {
                      dispatch(respError('Tolong setujui konfirmasi Rincian Transfers'));
                    }
                  }}
                  style={common.acceptedTerms ? styles.buttonEnabled : styles.buttonDisabled}
                  textStyle={{
                    fontSize: normalize(14),
                  }}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 10,
  },
  section: { alignSelf: 'center', maxWidth: '100%' },
  title: {
    fontFamily: Font.Nunito_Bold,
    marginBottom: 0,
    marginTop: 10,
    marginLeft: 10,
  },
  text: { fontFamily: Font.Nunito_Regular, flex: 1, fontSize: normalize(14) },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '85%',
    padding: 5,
  },
  rowTitle: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    alignSelf: 'flex-start',
    flex: 1,
  },
  rowTitleTwo: {
    flex: 0.7,
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(14),
  },
  rowValue: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    alignSelf: 'flex-end',
    flex: 0.7,
    textAlign: 'right',
  },
  accountNumberRow: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    alignSelf: 'flex-end',
    flex: 0.45,
  },
  productTitle: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    marginLeft: 0,
    maxWidth: '60%',
    flex: 1,
  },
  productValue: {
    flex: 1,
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(14),
    textAlign: 'right',
  },
  subTotalRow: {
    alignSelf: 'center',
    marginTop: 0,
    marginTop: 10,
    marginLeft: 4,
    marginBottom: 20,
  },
  subTotalSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '90%',
  },
  subTotalTitle: { width: '60%', marginLeft: 5, fontFamily: Font.Nunito_Bold },
  subTotalValue: {
    width: '30%',
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    textAlign: 'right',
  },
  termsContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',

    marginBottom: 20,
    marginTop: 5,
  },
  validateText: {
    fontSize: normalize(10),
    color: '#d40e0b',
    fontFamily: Font.Nunito_Regular,
  },
  buttonContainer: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    width: '90%',
    alignSelf: 'center',
  },
  buttonDisabled: {
    alignSelf: 'center',
    width: normalize(120),
    backgroundColor: '#F89F92',
  },
  buttonCancel: {
    alignSelf: 'center',
    width: normalize(120),
    backgroundColor: '#FFDBCF',
  },
  buttonEnabled: {
    alignSelf: 'center',
    backgroundColor: '#F56B57',
    width: normalize(120),
    maxWidth: '40%',
  },
  voucherContainer: {
    alignSelf: 'center',
    marginTop: 0,
    marginTop: 10,
    marginLeft: 4,
    marginBottom: 20,
  },
});

export default PulsaContent;
