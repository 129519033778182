import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

const styles = StyleSheet.create({
  amount: {
    fontFamily: Font.Nunito_Regular,
    color: colors.red,
  },
  status: {
    fontFamily: Font.Nunito_Regular,
  },

  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
});

export default styles;
