import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { Reasons, SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import tw from '@utils/tailwind';
import Arrow from '@expo/vector-icons/FontAwesome';
import { getStatusAndColor, numberWithDots } from '@utils/UtilFunctions';
import moment from 'moment';
import styles from './styles';
import { TRANSACTION_SCREEN_ACCOUNT_NAME } from '@utils/Constants';

const twReasonIconStyle = tw`h-40px w-40px rounded-12px items-center justify-center`;
const twRedeemURLParentStyle = tw`bg-error_rose rounded-5px px-5px h-11px ml-5px`;
const twRedeemTextUrlStyle = tw`text-neutral_cotton text-7px leading-10px text-center	font-${Font.Nunito_Regular} items-center justify-center`;
const twDisplayNameStyle = {
  color: colors.textGrey,
  fontSize: 10,
  lineHeight: 14,
  fontFamily: Font.Nunito_Regular,
};

const twMarginLeftFlex1 = tw`ml-10px flex-1`;
const twDateParentStyles = tw`flex-row items-center justify-between`;
const twCoinRedeemStyle = tw`flex-row items-center`;
const twReasonDescriptionContainer = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

function twGetStatusAndColorStyles(item) {
  return {
    height: 5,
    width: 5,
    borderRadius: 5 / 2,
    backgroundColor: getStatusAndColor(item?.transStatus ?? '').color,
    marginEnd: 5,
  };
}

function shouldShowRedeemURL(item) {
  let displayLabel = null;
  if (item.redeemUrl || item.token) displayLabel = 'kode/link tersedia';
  if (item.voucherCode) displayLabel = 'Code/link available';
  return displayLabel ? (
    <View style={twRedeemURLParentStyle}>
      <Text style={twRedeemTextUrlStyle}>{displayLabel}</Text>
    </View>
  ) : null;
}

function shouldShowCoinOrExpenseArrow(isCoinBased, item) {
  return isCoinBased ? (
    <MyText isRegular={false}>
      {numberWithDots(Math.abs(item?.coinValue ?? 0))} {SVG_ICONS.coin2}
    </MyText>
  ) : (
    <MyText h3 isRegular={false}>
      {item.transactionType === 'EXPENSE' ? '-' : '+'}Rp{numberWithDots(parseInt(item?.transAmount ?? 0))}
    </MyText>
  );
}

function shouldShowItemName(item) {
  return (
    item?.displayName && (
      <View>
        <MyText customStyle={twDisplayNameStyle} h2>
          {item?.displayName}
        </MyText>
      </View>
    )
  );
}

const TransactionItem = ({ item, onPress, disabled, container }) => {
  const isCoinBased = TRANSACTION_SCREEN_ACCOUNT_NAME[item.moneyAccount] === TRANSACTION_SCREEN_ACCOUNT_NAME['POINTS'];
  return (
    <Pressable
      android_ripple={{ color: colors.lightGrey }}
      style={[styles.container, container]}
      onPress={onPress}
      disabled={disabled}
    >
      <View style={styles.subContainer}>
        <View style={twReasonIconStyle}>{Reasons[`${item.transactionReasonId}`]}</View>
        <View style={twMarginLeftFlex1}>
          <View style={twDateParentStyles}>
            <View style={twCoinRedeemStyle}>
              {shouldShowCoinOrExpenseArrow(isCoinBased, item)}
              {shouldShowRedeemURL(item)}
            </View>
            <MyText customStyle={{ color: colors.textGrey }} h2>
              {moment(item?.transDate).format('DD MMM YY')}
            </MyText>
          </View>
          <View style={twReasonDescriptionContainer}>
            <MyText numberOfLines={1} customStyle={{ width: '75%' }}>
              {item?.transReason}
              {item?.description?.length > 0 && (
                <MyText customStyle={{ color: '#888888' }} h2>
                  {' '}
                  | {item.description}
                </MyText>
              )}
            </MyText>
            <View style={tw`flex-row items-center`}>
              <View style={twGetStatusAndColorStyles(item)} />
              <MyText customStyle={{ color: colors.textGrey }} h1>
                {getStatusAndColor(item?.transStatus ?? '').text}
              </MyText>
            </View>
          </View>
          {shouldShowItemName(item)}
        </View>
      </View>
    </Pressable>
  );
};

export default TransactionItem;
