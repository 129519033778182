import { StyleSheet, Dimensions } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize } from "@style/helper";
const {width: screenWidth} = Dimensions.get('window')

export const styles = StyleSheet.create({
  scene: { height: "100%", backgroundColor: "#FFFFFF" },
  container: {
    height: 280,
    backgroundColor: "#FFFFFF",
    marginVertical: 18,
    flexDirection:'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: screenWidth - 40
  },
  textContainer: {alignItems: 'flex-end', flexDirection: 'column'},
  row: {flexDirection: 'row'},
  colorContainer: {flexDirection: 'row', alignItems: 'center'},
  colorBox: {
    height: 10,
    width: 10,
    marginLeft: 10,
    marginRight: 5
  },
  colorTitle: {
    fontFamily: Font.Nunito_Regular,
    fontSize: 8,
    lineHeight: 11,
    color: '#979797',
  },
  colorSubTitle: {
    fontFamily: Font.Nunito_Regular,
    fontStyle: 'italic',
    fontSize: 8,
    lineHeight: 11,
    color: '#979797',
    marginTop: 4,
  }
})