import { resetAppTourParams, setAppTourParams } from '@redux/action/Common';
import { store } from '@enhancedStore/store';
import AmplitudeHelper from '@services/amplitude';
import { APPTOURMETA, APP_TOUR_TYPE } from '@gaji-gesa/gg-react-shared/src/utils/sharedAppTourConstants';
import { AMPLITUDE_CONSTANTS } from './AmplitudeConstants';

export const appTourLogEvent = (type, action, page) => {
  let e = AMPLITUDE_CONSTANTS.AppTourInsider[type] ?? null;
  if (!e) return;
  AmplitudeHelper.logEvent(e['name'], {
    [e.attributes.page]: page,
    [e.attributes.action]: action,
  });
};

export const onPressExit = (currentStep, type, tourStep, isFinished) => {
  store.dispatch(resetAppTourParams());

  appTourLogEvent(type, isFinished ? 'finish' : 'close', tourStep);
};

export const onPressBack = (currentStep, type, tourStep) => {
  store.dispatch(
    setAppTourParams({
      type,
      step: currentStep - 1,
      isPrevious: true,
    }),
  );

  appTourLogEvent(type, 'previous', tourStep);
};

export const onPressNext = (currentStep, type, tourStep) => {
  store.dispatch(
    setAppTourParams({
      type,
      step: currentStep + 1,
      isPrevious: false,
    }),
  );

  appTourLogEvent(type, 'next', tourStep);
};

export { APPTOURMETA, APP_TOUR_TYPE };
