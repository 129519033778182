import React, { useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import MaIcon from '@expo/vector-icons/MaterialCommunityIcons';
import { normalize } from '@style/helper';

/* Style Import */
import { subTitleText } from '@style/Layouts';
import { LgButton } from '@components/Button';
import AmplitudeHelper from '@services/amplitude';
import { TERMS } from '@utils/Constants';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase, getCurrentTimeStamp } from '@utils/UtilFunctions';
import { store } from '@enhancedStore/store';

const Terms = ({ handleTermsClose, acceptTerms, acceptedTerms, prefix }) => {
  const { redeemCode } = store.getState().Referral;

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.termsPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.timestamp]: getCurrentTimeStamp(),
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: redeemCode,
    });
  }, []);

  const handleAccept = () => {
    // AmplitudeHelper.logEvent('userAcceptedBillPaymentTerms')

    let e = AMPLITUDE_CONSTANTS.BillPayment.userAcceptedBillPaymentTerms;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.timestamp]: getCurrentTimeStamp(),
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: redeemCode,
    });

    handleTermsClose();
    acceptTerms(!acceptedTerms);
  };

  const handleCancel = () => {
    // AmplitudeHelper.logEvent('userCanceledBillPaymentTerms')

    let e = AMPLITUDE_CONSTANTS.BillPayment.userCancelledBillPaymentTerms;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.timestamp]: getCurrentTimeStamp(),
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: redeemCode,
    });

    handleTermsClose();
  };

  const canceledTermsFunc = () => {
    AmplitudeHelper.logEvent('user_canceled_bill_payment_terms');
    handleTermsClose();
  };

  const acceptedTermsFunc = () => {
    AmplitudeHelper.logEvent('user_accepted_bill_payment_terms', { timestamp: new Date().toString() });
    handleTermsClose();
    acceptTerms(!acceptedTerms);
  };

  return (
    <>
      <View style={styles.container}>
        <Text style={[styles.title]}>
          <MaIcon name="text-box-check" style={styles.icon} />
          {'  '}Ketentuan Layanan
        </Text>
        <Text style={styles.desc}>{TERMS.first}</Text>
        <Text style={styles.descTwo}>{TERMS.second}</Text>
        <Text style={styles.desc}>{TERMS.third}</Text>
      </View>
      <View style={styles.buttonContainer}>
        <LgButton
          testId={'productTermsCancelButton'}
          title="Batal"
          onPress={handleCancel}
          style={styles.button}
          textStyle={{ fontSize: 12 }}
        />
        <LgButton
          testId={'productTermsAcceptButton'}
          title="Setuju"
          onPress={handleAccept}
          style={styles.buttonTwo}
          textStyle={{ fontSize: 12 }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(17),
    paddingVertical: 10,
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '90%',
    marginLeft: '2%',
    textAlign: 'justify',
    alignSelf: 'center',
  },
  icon: { fontSize: 25, color: '#3863F0' },
  desc: { fontSize: normalize(12), marginBottom: 10, lineHeight: 20, fontFamily: 'Nunito-Regular' },
  descTwo: { fontSize: normalize(12), marginBottom: 10, lineHeight: 20, fontFamily: 'Nunito-Regular' },
  buttonContainer: { justifyContent: 'space-between', paddingHorizontal: 10, flexDirection: 'row', marginTop: 30 },
  button: { alignSelf: 'center', flex: 1, backgroundColor: '#F89F92', paddingVertical: 10 },
  buttonTwo: { alignSelf: 'center', flex: 1, marginStart: 10, paddingVertical: 10 },
});

export default Terms;
