import React from 'react';
import { Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import * as Clipboard from 'expo-clipboard';
import { respSuccess } from '@redux/action/Common';
import { useDispatch } from 'react-redux';
import { VOUCHER_INV_ASSETS, VOUCHER_INV_CTA_ICONS, VOUCHER_INV_STATUS } from '@utils/Constants';
import { checkExpiryDateTime, getExpiredTime } from '@utils/UtilFunctions';
import VoucherPunch from '../VoucherPunch';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import styles from './style';

const VoucherCard = ({ voucherCode, status, expiryDate, transactionCategoryId }) => {
  function renderPunchHolePillars(style, circleStyle) {
    return <VoucherPunch pillarStyle={style} circleStyle={circleStyle} />;
  }
  const dispatch = useDispatch();

  const onCopyHandler = () => {
    try {
      Clipboard.setStringAsync(voucherCode);
      dispatch(respSuccess('Kode voucher tersalin!'));
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.code]: voucherCode ?? null,
        [e.attributes.transaction_category_id]: transactionCategoryId ?? null,
        [e.attributes.source]: 'product_page',
        [e.attributes.status]: 'success',
        [e.attributes.feature]: 'milvik',
      });
    } catch (err) {
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.code]: voucherCode ?? null,
        [e.attributes.transaction_category_id]: transactionCategoryId ?? null,
        [e.attributes.source]: 'product_page',
        [e.attributes.status]: 'fail',
        [e.attributes.feature]: 'milvik',
      });
    }
  };

  function returnExpirtyText(expiryDate, status) {
    if (expiryDate || VOUCHER_INV_STATUS.REDEEMED !== status)
      return <MyText customStyle={styles.textOne}>{`Berlaku hingga ${getExpiredTime(expiryDate)}`}</MyText>;
    if (VOUCHER_INV_STATUS.EXPIRED === status || checkExpiryDateTime(expiryDate))
      return <MyText customStyle={styles.textOne}>Kadaluarsa</MyText>;
    return <View style={{ marginTop: 4 }} />;
  }
  function expiryOverLay(status) {
    return (
      (VOUCHER_INV_STATUS.EXPIRED === status || checkExpiryDateTime(expiryDate)) && (
        <>
          <View style={styles.expiryLayer} />
          <View style={styles.expiryOverLayContainer}>
            <MyText isRegular={false} customStyle={styles.expireOverlayText}>
              Voucher Kadaluwarsa
            </MyText>
          </View>
        </>
      )
    );
  }

  return (
    <View style={styles.outerContainer}>
      <View style={[styles.clipContainer, { backgroundColor: VOUCHER_INV_ASSETS[status].voucherColor }]}>
        {expiryOverLay(status)}
        <View style={styles.divOne}>{SVG_ICONS.voucherInventory.icVoucher}</View>
        <View style={styles.divTwo}>
          {returnExpirtyText(expiryDate, status)}
          <MyText customStyle={styles.textTwo} isRegular={false}>
            Kode Voucher
          </MyText>
          <MyText customStyle={styles.textThree} isRegular={false}>
            {voucherCode}
          </MyText>
        </View>
        <Pressable onPress={onCopyHandler} style={styles.divThree}>
          {VOUCHER_INV_CTA_ICONS[status]?.ctaIcon || VOUCHER_INV_CTA_ICONS.default.ctaIcon}
          <MyText
            isRegular
            customStyle={[
              styles.textCopyStyle,
              { color: VOUCHER_INV_CTA_ICONS[status]?.ctaColor || VOUCHER_INV_CTA_ICONS.default.ctaColor },
            ]}
          >
            {VOUCHER_INV_ASSETS[status].ctaText}
          </MyText>
        </Pressable>
        {renderPunchHolePillars(styles.leftPillar, VOUCHER_INV_ASSETS[status].leftCircleStyle)}
        {renderPunchHolePillars(styles.rightPillar, styles.circleRight)}
      </View>
    </View>
  );
};

VoucherCard.propTypes = {
  voucherCode: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  expiryDate: PropTypes.string,
  transactionCategoryId: PropTypes.string.isRequired,
};

VoucherCard.defaultProps = {
  expiryDate: null,
};
export default VoucherCard;
