import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  Dimensions,
  Linking,
  ActivityIndicator,
  Pressable,
} from 'react-native';
import Icon from '@expo/vector-icons/FontAwesome5';
import IonIcon from '@expo/vector-icons/Ionicons';
import FaIcon from '@expo/vector-icons/FontAwesome5';
import AIcon from '@expo/vector-icons/AntDesign';
import { normalize, normalizeHeight } from '@style/helper';
import AmplitudeHelper from '@services/amplitude';
import { SvgUri } from 'react-native-svg';
import tw from '@utils/tailwind';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import Baru from '@atoms/Loan/Baru';
import { useNavigation } from '@react-navigation/native';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const MdButton = ({ onPress, title }) => {
  return (
    <>
      <TouchableOpacity onPress={onPress} style={styles.mdButtonContainer}>
        <Text style={styles.mdButtonText}>{title}</Text>
      </TouchableOpacity>
    </>
  );
};

const SmButton = ({ testId, style, onPress, title }) => {
  return (
    <>
      <TouchableOpacity accessibilityLabel={testId} onPress={onPress} style={{ ...style, ...styles.smButtonContainer }}>
        <Text style={styles.mdButtonText}>{title}</Text>
      </TouchableOpacity>
    </>
  );
};

const LgButton = ({ testId, onPress, title, style, disabled, textStyle, loading, disabledStyle }) => {
  return (
    <Pressable
      accessibilityLabel={testId}
      onPress={onPress}
      disabled={disabled || loading}
      style={[{ ...styles.lgButtonContainer, ...style }, disabled && disabledStyle]}
      android_ripple={{ color: colors.lightGrey }}
    >
      {loading ? (
        <ActivityIndicator color={colors.white} size="small" />
      ) : (
        <Text numberOfLines={1} style={{ ...styles.lgButtonText, ...textStyle }}>
          {title}
        </Text>
      )}
    </Pressable>
  );
};

const LogoutButton = ({ onPress }) => {
  return (
    <>
      <TouchableOpacity accessibilityLabel="logoutButton" onPress={onPress} style={styles.hdButtonContainer}>
        <Text style={styles.hdButtonText}>
          <Icon name="power-off" size={20} />
        </Text>
      </TouchableOpacity>
    </>
  );
};

export const BantuanButton = ({ onPress, source }) => {
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.helpClicked.name, {
            [AMPLITUDE_CONSTANTS.Login.helpClicked.attributes.source]: source,
          });

          onPress();
        }}
        style={{
          marginRight: 30,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {SVG_ICONS.supportIcon}
        <Text
          style={{
            fontSize: normalize(10),
            fontFamily: Font.Nunito_Regular,
            color: '#ffffff',
            marginTop: 5,
          }}
        >
          Bantuan
        </Text>
      </TouchableOpacity>
    </>
  );
};

export const GajiButton = ({ onPress }) => {
  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 20,
          alignSelf: 'center',
          marginTop: 2,
        }}
      >
        <SvgUri uri="https://storage.googleapis.com/gajigesa-public/icons/new-gaji.svg" />
        <Text
          style={{
            fontSize: normalize(10),
            fontFamily: Font.Nunito_Regular,
            color: '#ffffff',
            marginTop: 5,
          }}
        >
          Gaji
        </Text>
      </TouchableOpacity>
    </>
  );
};

const CheckBox = ({ testId, onPress, disabled }) => {
  return (
    <>
      <TouchableOpacity
        disabled={disabled}
        accessibilityLabel={testId}
        onPress={onPress}
        style={styles.checkbox}
      ></TouchableOpacity>
    </>
  );
};

const CheckedBox = ({ testId, onPress }) => {
  return (
    <>
      <TouchableOpacity accessibilityLabel={testId} onPress={onPress} style={styles.checkbox}>
        <FaIcon name="check" style={styles.checkboxText} />
      </TouchableOpacity>
    </>
  );
};

const FALLBACK_IMAGE = "'https://gajigesa.s3-ap-southeast-1.amazonaws.com/Group+294.png";

const CardIconButton = ({ prop, cardStyle, iconStyle, onPress, showNew }) => {
  if (!prop.imageUrl && !prop.isLocalImagePath) {
    return <></>;
  }

  let imgsrc = { uri: prop.imageUrl ?? FALLBACK_IMAGE };

  if (prop.isLocalImagePath) imgsrc = prop.isLocalImagePath;

  return (
    <>
      <Pressable
        accessibilityLabel="productButton"
        onPress={() => onPress(prop)}
        style={{ width: '25%', marginTop: normalizeHeight(10) }}
        android_ripple={{ color: colors.lightGrey }}
      >
        <View style={styles.cardIcon}>
          <Image
            style={{
              height: normalizeHeight(30),
              width: normalize(35),
              alignSelf: 'center',
            }}
            resizeMode={'contain'}
            source={imgsrc}
          />
        </View>
        <Text
          style={{
            ...iconStyle,
            flex: 1,
            alignSelf: 'center',
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(10),
            textAlign: 'center',
            color: '#454545',
          }}
        >
          {prop.name}
        </Text>

        <Baru showBaru={showNew} />
      </Pressable>
    </>
  );
};

const BillPaymentProductCard = ({
  prop,
  alignment,
  cardStyle,
  onPress,
  titleStyle,
  subTitleStyle,
  onDetail,
  selected,
}) => {
  if (alignment) {
    return (
      <TouchableOpacity
        accessibilityLabel={'selectProduct'}
        onPress={onPress}
        style={{
          ...cardStyle,
          ...styles.BillPaymentProductStyle,
          marginLeft: 5,
          paddingHorizontal: 5,
          paddingTop: 10,
          backgroundColor: selected ? '#F56B57' : '#FFFFFF',
          flexDirection: 'column',
        }}
      >
        <Text
          style={{
            ...titleStyle,
            flex: 1,
            alignSelf: 'center',
            color: selected ? '#FFFFFF' : '#F56B57',
            maxHeight: normalizeHeight(30),
            fontFamily: Font.Nunito_Regular,
            fontSize: normalize(20),
          }}
        >
          {prop.nominal}
        </Text>
        <Text
          style={{
            ...subTitleStyle,
            flex: 1,
            alignSelf: 'center',
            color: selected ? '#FFFFFF' : '#000',
            fontFamily: Font.Nunito_Regular,
            fontSize: normalize(11),
          }}
        >
          Harga: Rp{prop.nominal}
        </Text>
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity
        accessibilityLabel={'selectProduct'}
        onPress={onPress}
        style={{
          ...cardStyle,
          ...styles.BillPaymentProductStyle,
          backgroundColor: selected ? '#F56B57' : '#FFFFFF',
          flexDirection: 'column',
        }}
      >
        <Text
          style={{
            ...titleStyle,
            alignSelf: 'center',
            color: selected ? '#FFF' : '#F56b57',
            fontSize: normalize(16),
            marginBottom: normalizeHeight(7),
            textTransform: 'uppercase',
            fontFamily: Font.Nunito_Bold,
          }}
        >
          {prop.name}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'space-between',
            marginTop: normalizeHeight(5),
          }}
        >
          <Text
            style={{
              ...subTitleStyle,
              fontSize: normalize(14),
              flex: 0.6,
              alignSelf: 'flex-start',
              color: selected ? '#FFF' : '#000',
              lineHeight: 17,
              fontFamily: Font.Nunito_Regular,
            }}
          >
            Harga: Rp{prop.amount}
            {'\n'} + GajiGesa Fee
          </Text>
          <TouchableOpacity
            accessibilityLabel={'buttonDetail'}
            style={{
              borderWidth: 0.6,
              borderRadius: 6,
              borderColor: selected ? '#FFFFFF' : '#3863F0',
              backgroundColor: selected ? '#F56b57' : '#FFFFFF',
              flex: 0.4,
              alignSelf: 'flex-end',
            }}
            onPress={onDetail}
          >
            <Text
              style={{
                ...styles.mdButtonText,
                textTransform: 'capitalize',
                fontSize: normalize(14),
                color: selected ? '#FFFFFF' : '#3863f0',
                fontFamily: Font.Nunito_Regular,
              }}
            >
              Lihat Detail
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  }
};

const ButtonIcon = ({ testId, icon, onPress, style, size, iconStyle }) => {
  return (
    <>
      <TouchableOpacity accessibilityLabel={testId} onPress={onPress} style={{ ...style }}>
        <Text style={styles.iconButtonText}>
          <AIcon name={icon} style={{ ...iconStyle }} size={size || normalize(20)} />
        </Text>
      </TouchableOpacity>
    </>
  );
};

const ImageButton = ({ accessibilityLabel, imageStyle, onPress, style, source, columns }) => {
  return (
    <>
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
        style={{
          ...style,
          width: (screenWidth / columns) * 0.75,
          height: (screenWidth / columns) * 0.5,
          padding: 10,
          margin: 5,
          borderColor: '#d1d1d1',
          borderWidth: 1,
          borderBottomWidth: 5,
          borderBottomColor: '#d2d2d2',
          borderRadius: 5,
        }}
      >
        {source != 'local' ? (
          <Image
            style={{
              ...imageStyle,
              width: '100%',
              height: '100%',
              alignSelf: 'center',
            }}
            source={{ uri: source }}
          />
        ) : (
          <Image
            style={{
              ...imageStyle,
              width: '50%',
              height: '100%',
              alignSelf: 'center',
            }}
            source={require('@assets/e-money.png')}
          />
        )}
      </TouchableOpacity>
    </>
  );
};
const VoucherButton = ({ accessibilityLabel, imageStyle, onPress, style, source, columns, selected }) => {
  return (
    <>
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
        style={{
          ...style,
          width: (screenWidth / columns) * 0.75,
          height: (screenWidth / columns) * 0.5,
          padding: 10,
          margin: 5,
          borderColor: '#d1d1d1',
          backgroundColor: 'white',
          shadowColor: '#000',
          borderColor: selected ? '#F6430A' : '#fff',
          borderWidth: selected ? 2 : 0,
          shadowOffset: { width: 0, height: 5 },
          shadowOpacity: 0.34,
          shadowRadius: 6.27,
          elevation: 8,
          borderRadius: 5,
          marginHorizontal: 10,
        }}
      >
        {source != 'local' ? (
          <Image
            resizeMode="center"
            style={{
              ...imageStyle,
              width: '100%',
              height: '57%',
              alignSelf: 'center',
              marginVertical: 10,
            }}
            source={{ uri: source }}
          />
        ) : (
          <Image
            style={{
              ...imageStyle,
              width: '50%',
              height: '100%',
              alignSelf: 'center',
            }}
            source={require('@assets/e-money.png')}
          />
        )}
      </TouchableOpacity>
    </>
  );
};
const BodyIcon = ({ icon }) => {
  return (
    <Text style={styles.iconButtonText}>
      <Icon name={icon} size={normalize(20)} />
    </Text>
  );
};

const BackButton = ({ onPress }) => {
  return (
    <>
      <TouchableOpacity accessibilityLabel="backButton" onPress={onPress} style={styles.hdButtonContainer}>
        <Text style={styles.hdButtonText}>
          <IonIcon name="return-up-back" size={normalize(20)} /> Kembali
        </Text>
      </TouchableOpacity>
    </>
  );
};

const CardBackButton = ({ onPress }) => {
  return (
    <>
      <TouchableOpacity onPress={onPress} style={styles.hdButtonContainer}>
        <Text style={styles.cardButtonText}>
          <IonIcon name="return-up-back" size={normalize(20)} /> Kembali
        </Text>
      </TouchableOpacity>
    </>
  );
};

// const FooterButton = ({ onPress }) => {
const FooterButton = ({ source }) => {
  const navigation = useNavigation();
  return (
    <>
      <View style={styles.wrapperFooterButton}>
        <Text
          accessibilityLabel={'informasiProdukButton'}
          onPress={() => {
            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.productInfoClicked.name, {
              [AMPLITUDE_CONSTANTS.Login.productInfoClicked.attributes.source]: source,
            });
            navigation.navigate('MainInformasi');
          }}
          style={styles.footerButtonText}
        >
          <IonIcon style={{ fontSize: normalize(20), marginLeft: 10 }} name={'chatbubbles'} /> Info Produk
        </Text>
        <Text
          accessibilityLabel={'bantuanButton'}
          onPress={() => {
            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.helpClicked.name, {
              [AMPLITUDE_CONSTANTS.Login.helpClicked.attributes.source]: source,
            });

            Linking.openURL('https://api.whatsapp.com/send?&phone=6281315276948');
          }}
          style={styles.footerButtonText}
        >
          <IonIcon style={{ fontSize: normalize(20), marginLeft: 10 }} name={'logo-whatsapp'} /> Bantuan
        </Text>
      </View>
    </>
  );
};

const ArrowButton = ({ facing, onPress, size, color, disabled, isOrange = false }) => {
  if (isOrange) {
    return (
      <TouchableOpacity disabled={disabled} onPress={onPress}>
        <View
          style={{
            backgroundColor: disabled ? '#D4D4D4' : colors.orange,
            padding: 5,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: normalize(50),
          }}
        >
          <AIcon name={facing} size={size ? size : normalize(14)} style={{ color: 'white' }} />
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <View
        style={{
          backgroundColor: disabled ? '#6d9dea' : 'white',
          padding: 5,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: normalize(50),
        }}
      >
        <AIcon name={facing} size={size ? size : normalize(14)} style={{ color: tw.color('bg-secondary_orchid') }} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  mdButtonContainer: {
    elevation: 8,
    backgroundColor: '#F56B57',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: normalize(100),
    marginBottom: 10,
  },
  smButtonContainer: {
    elevation: 8,
    backgroundColor: '#F56B57',
    borderRadius: 30,
    width: normalize(50),
    padding: normalize(5),
    height: normalizeHeight(25),
    marginBottom: normalizeHeight(10),
  },
  mdButtonText: {
    fontSize: normalize(12),
    color: '#FFF',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontFamily: Font.Nunito_Bold,
  },
  lgButtonContainer: {
    elevation: 8,
    backgroundColor: '#F56B57',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingVertical: 7,
    width: normalize(220),
    marginBottom: normalize(10),
    marginTop: normalize(10),
  },
  lgButtonText: {
    fontSize: normalize(17),
    color: '#FFF',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontFamily: Font.Nunito_Bold,
  },
  hdButtonContainer: {
    backgroundColor: 'transparent',
    marginBottom: 10,
    // marginRight: 15
    marginRight: 30,
  },
  hdButtonText: {
    fontSize: 14,
    color: '#FFF',
    alignSelf: 'center',
    marginRight: 15,
    marginTop: 10,
  },
  BillPaymentProductStyle: {
    // shadowColor: '#0a0a0a',
    borderWidth: 1,
    borderBottomWidth: 5,
    borderBottomColor: '#dedede',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: normalize(14),
    borderColor: '#d1d1d1',
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: .1,
    // shadowRadius: 2,
    // elevation: 1,
    color: '#3863F0',
    fontSize: normalize(14),
    alignSelf: 'flex-start',
    marginTop: normalizeHeight(5),
    display: 'flex',
  },
  // cardIcon: {
  //   borderWidth: 0.5,
  //   borderBottomWidth: 3,
  //   borderRadius: 10,
  //   borderColor: '#c4c4c4',
  //   fontSize: normalize(14),
  //   alignSelf: 'flex-start',
  //   alignContent:'space-around',
  //   marginRight: normalize(10),
  //   marginTop: normalizeHeight(5),
  //   marginLeft:normalize(7),
  //   paddingTop: normalizeHeight(10),
  //   display:'flex',
  //   minHeight:normalizeHeight(70),
  //   maxWidth: '25%'
  // },
  cardIcon: {
    // borderWidth: 0.5,
    // borderBottomWidth: 1,

    // borderColor: '#c4c4c4',
    backgroundColor: 'white',
    fontSize: normalize(14),
    // alignSelf: 'flex-start',
    // alignContent:'space-around',
    marginRight: normalize(10),
    marginLeft: normalize(7),
    paddingTop: normalizeHeight(10),
    // display:'flex',
    height: normalizeHeight(50),
    // maxWidth: '15%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
    borderRadius: normalize(12),
    marginBottom: 5,
  },
  cardButtonText: {
    fontSize: normalize(17),
    color: '#3863F0',
    fontWeight: 'bold',
    alignSelf: 'center',
    marginRight: 15,
    marginTop: normalizeHeight(5),
    textDecorationLine: 'underline',
    fontFamily: Font.Nunito_Regular,
  },
  iconButtonText: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    color: '#292929',
    alignSelf: 'center',
    marginRight: 15,
  },
  footerButton: {
    width: '100%',
    position: 'absolute',
    height: screenHeight / 10,
    bottom: 0,
    backgroundColor: '#3863F0',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonText: {
    color: 'white',
    fontSize: normalize(15),
    fontFamily: Font.Nunito_Regular,
  },
  wrapperFooterButton: {
    flexDirection: 'row',
    backgroundColor: '#3863F0',
    paddingVertical: '6%',
    paddingHorizontal: '10%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkbox: {
    backgroundColor: '#FFDBCF',
    width: 23,
    height: 23,
    borderRadius: 8,
    marginRight: 10,
    padding: 0,
  },
  checkboxText: {
    // color: 'black',
    color: 'white',
    fontSize: normalize(17),
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 3,
  },
  buttonIconStyle: { fontSize: normalize(16), color: '#F56B57' },
});

export {
  MdButton,
  LogoutButton,
  LgButton,
  FooterButton,
  BackButton,
  SmButton,
  CardBackButton,
  CheckBox,
  CheckedBox,
  BodyIcon,
  CardIconButton,
  BillPaymentProductCard,
  ImageButton,
  VoucherButton,
  ArrowButton,
  ButtonIcon,
};
