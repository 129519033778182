import moment from "moment";
import { numberWithDots } from "@utils/UtilFunctions";
import { styles } from "./style";
import translate from "./translate";

const t = translate();
const getTime = (date) => new Date(date).getTime()
const CAMPAIGN = 'campaign';
const SAVING = 'saving';
const extractMonthlyData = (cycles: any[], info, item) => {
  const itemTime = getTime(item.month);
  const cycleIndex = cycles.findIndex(cycle => getTime(cycle.startDate) <= itemTime && getTime(cycle.endDate) >= itemTime )

  const key = `cycle_${cycleIndex}`
  if (!info[key]) info[key] = [];
  info[key].push({
    amount: !!item.compareamount ? item.compareamount - item.amount : item.amount,
    category: item.categoryid,
    month: item.month,
    offerid: item.offerid,
    cycle: cycleIndex,
    color: item.category?.transCategoryColor,
  })
}

const groupByMonthlyData = (info) => {
  let count = 0;
  Object.keys(info).forEach(cycle => {
    const campaignHash = {};
    const newInfo = info[cycle].reduce((acc, val) => {
      if (val.offerid) {
        if (campaignHash[val.offerid]) return acc;
        campaignHash[val.offerid] = 1;
      }
      if (acc[val.category]) {
        acc[val.category].amount = acc[val.category].amount + val.amount
      } else {
        acc[val.category] = val
      }
      count += val.amount;
      return acc;
    }, {})

    info[cycle] = Object.values(newInfo);
  })

  return count;
}

const isSelected = (selectedCycles, item)=>{
  let found = false
  if(selectedCycles.find(cycle => cycle.id === item.id)){
    found = true
  }
  return found
}

const getSelectedCycle =(paymentCycles, selectedCycles)=>{   
  return paymentCycles.find(e=>e.id===selectedCycles[0].id)
}

const productSuggestionData = [
  { id: 13, name: 'Voucher Digital prepaid', prefix: 'BP-13', title: 'Rp13.000', subTitle: t['save_upto'], button: {
    text: `${t['buy_now']}!`,
    }, category: {
      title: 'Voucher',
      icon: 'voucher',
      imageUrl: 'https://storage.googleapis.com/gajigesa-public/icons/listrik.png'
    }
  }, 
  {id: 10, name: 'Pulsa', prefix: 'BP-10', title: 'Rp2.194', subTitle: t['save_upto'], button: {
    text: `${t['buy_now']}!`,
  }, category: {
    title: 'Pulsa',
    icon: 'pulsa',
    imageUrl: 'https://storage.googleapis.com/gajigesa-public/icons/listrik.png'
  }},
  {
    id: 5, name: 'Token PLN', prefix: 'BP-05', title: 'Rp750', subTitle: t['save_upto'], button: {
      text: `${t['buy_now']}!`,
    }, category: {
      title: 'Token PLN',
      icon: 'tokenPLN',
      imageUrl: 'https://storage.googleapis.com/gajigesa-public/icons/listrik.png'
    }},
];

const categoryIndex = {
  'BP-05': 1,
  'BP-10': 2,
  'CM-01': 3,
  'BP-14': 4,
}

const compare = (a, b) => {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
}

const months = {
  '01': "Jan",
  '02': "Feb",
  '03': "Mar",
  '04': "Apr",
  '05': "Mei",
  '06': "Jun",
  '07': "Juli",
  '08': "Agu",
  '09': "Sep",
  '10': "Okt",
  '11': "Nov",
  '12': "Des",
}

const getUpperLabel = (item, index) => ({
  id: `upperLabel-${index}`,
  type: 'label',
  value: `Rp${numberWithDots(parseInt(item?.reduce((acc, x)=> acc + x.amount , 0)) ?? 0)}`,
  style: styles.upperLabel
})

const getLowerLabel = (item, index, monthCycle) => {

  return ({
  id: `lowerLabel-${index}`,
  type: 'label',
  value: `${moment(monthCycle.startDate).format('DD')} ${months[moment(monthCycle.startDate).format('MM')]} - ${moment(monthCycle.endDate).format('DD')} ${months[moment(monthCycle.endDate).format('MM')]}`,
  style: styles.lowerLabel,
})}

const getHorizontalLine = (index) => ({id: `line-${index}`, type: 'line', color: '#77BE43' })

const getChartEmptyBlock = (val) => ({
  id: `chart-${val}`,
  type: 'chart',
  value: 0,
})

const getEmptyUpperLabel = (index) => ({
  id: `upperLabel-${index}`,
  type: 'label',
  value: ` `,
  style: styles.upperLabel
})

const getEmptyBlock = (index) => [getEmptyUpperLabel(index), getChartEmptyBlock(index), getHorizontalLine(index)];

export {
  extractMonthlyData,
  groupByMonthlyData,
  isSelected,
  getSelectedCycle,
  productSuggestionData,
  categoryIndex,
  compare,
  months,
  getLowerLabel,
  getUpperLabel,
  getHorizontalLine,
  getEmptyBlock,
  getEmptyUpperLabel,
  getChartEmptyBlock
}
