import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MainParentWrapper from '@components/MainParentWrapper';
import InfoTextComponent from '@atoms/InfoTextComponent';
import TextInputComponent from '@components/TextInputComponent';
import PhoneNumberInput from '@components/PhoneNumberInput';
import styles from './style';
import DropDownSelectComponent from '@atoms/DropDownSelector/DropDownSelectComponent';
import DropDownModal from '@atoms/DropDownSelector/DropDownModal';
import { CHECK_DATA_DROPDOWN_LIST, LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@utils/Constants';
import { onBoardUnregisterd } from '@services/AuthService';
import { formatPhoneNumber, removeNonNumeric } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { returnBottomSheetContent } from '@utils/UtilFunctions';
import LoginInfoSheet from '@components/LoginInfoSheet';
import { ERROR_CODES } from '@utils/Constants';
import LoadingButton from '@atoms/LoadingButton';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { colors } from '@style/colors';
import { useNavigation, useRoute } from '@react-navigation/native';

const SignupUser = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [phoneNumber, setPhoneNumber] = useState(route.params?.phoneNumber ?? '');
  const [inputFocused, setInputFocused] = useState(false);
  const [inputFieldError, setInputFieldError] = useState({ error: false, errorMessage: 'Nomor handphone tidak valid' });
  const [userUniqueId, setUserUniqueId] = useState('');
  const [userName, setUserName] = useState('');
  const [userCompanyName, setUserCompanyName] = useState('');
  const [showDropDownModal, setShowDropDownModal] = useState(false);
  const [dropdownData, setDropDownData] = useState(CHECK_DATA_DROPDOWN_LIST);
  const [loading, setLoading] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(route.params?.phoneNumber ?? false);
  const selectedUserIdType = useMemo(() => {
    return dropdownData.find((e) => e.isSelected);
  }, [dropdownData]);

  const logEvent = useCallback(
    (val) => {
      const e = AMPLITUDE_CONSTANTS.authentication;
      AmplitudeHelper.logEvent(e[val.name].name, {
        [e.check_data_attributes.phone_number]: formatPhoneNumber(phoneNumber) ?? null,
        [e.check_data_attributes.id_type]: selectedUserIdType?.identityType ?? null,
        [e.check_data_attributes.id_number]: userUniqueId ?? null,
        [e.check_data_attributes.employee_name]: userName ?? null,
        [e.check_data_attributes.employer_name]: userCompanyName ?? null,
      });
    },
    [userCompanyName, userName, userUniqueId, phoneNumber, selectedUserIdType],
  );

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.authentication.login_check_data_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_check_data_page,
    });

    return () => {
      const e1 = AMPLITUDE_CONSTANTS.authentication.login_check_data_closed;
      AmplitudeHelper.logEvent(e.name, {
        [e1.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      });
    };
  }, []);

  useEffect(() => {
    if (selectedUserIdType) {
      logEvent(AMPLITUDE_CONSTANTS.authentication.id_type_chosen);
    }
  }, [selectedUserIdType]);

  const isDisabled = useMemo(() => {
    return !(userCompanyName && userName && userUniqueId && phoneNumber);
  }, [userCompanyName, userName, userUniqueId, phoneNumber]);

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.IN_PROCESS,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.IN_PROCESS],
      phoneNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.IN_PROCESS,
  });

  const onPressItem = (item) => {
    const newDropdownData = dropdownData.slice().map((e) => {
      if (e.text === item.text) {
        return {
          ...e,
          isSelected: !item.isSelected,
        };
      }

      return {
        ...e,
        isSelected: false,
      };
    });
    setDropDownData(newDropdownData);
    setShowDropDownModal(false);
    setUserUniqueId('');
  };

  const onPressDropDown = () => {
    setShowDropDownModal(true);
    logEvent(AMPLITUDE_CONSTANTS.authentication.id_type_clicked);
  };

  const onCloseDropDown = () => {
    setShowDropDownModal(false);
  };

  const handleShowModal = () => {
    setInfoModal((prevState) => ({
      ...prevState,
      visible: true,
    }));
  };

  const onBoardUser = useCallback(async () => {
    try {
      logEvent(AMPLITUDE_CONSTANTS.authentication.login_check_data_requested);
      setLoading(true);
      await onBoardUnregisterd(selectedUserIdType, userCompanyName, userName, userUniqueId, phoneNumber);
      handleShowModal();
    } catch (err) {
      if (err?.response?.data?.error === ERROR_CODES['GG-409']) handleShowModal();
    } finally {
      setLoading(false);
    }
  }, [userCompanyName, userName, userUniqueId, phoneNumber, selectedUserIdType]);

  const onCloseModal = () => {
    setInfoModal({ ...infoModal, visible: false });
    navigation.navigate('Login');
  };

  const onChangePhoneNumber = (text) => {
    inputFieldError.error && setInputFieldError({ error: false, errorMessage: inputFieldError.errorMessage });
    setPhoneNumber(removeNonNumeric(text));
    if (removeNonNumeric(text).substring(0, 2) === '00') {
      setValidPhoneNumber(false);
      setInputFieldError({ error: true, errorMessage: inputFieldError.errorMessage });
    }
    if (formatPhoneNumber(removeNonNumeric(text), '62').length >= 8) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
  };

  const onPhoneNumberFocus = (val) => {
    setInputFocused(val);

    const e = AMPLITUDE_CONSTANTS.authentication;
    if (val) logEvent(e.phone_number_clicked);
    else logEvent(e.phone_number_filled);
  };

  return (
    <MainParentWrapper
      isRounded={false}
      containerStyle={{ paddingHorizontal: 15 }}
      isScrollable
      parentStyle={{ backgroundColor: colors.neutral_cotton }}
    >
      <InfoTextComponent infoText="Isi data berikut ini untuk bantu kami mengecek akun Anda" />

      <DropDownSelectComponent
        onPress={onPressDropDown}
        isMandatory
        title="Tipe Data untuk Pengecekan"
        placeholder="Pilih Data"
        selectedItem={selectedUserIdType?.text ?? ''}
      />

      {selectedUserIdType && (
        <TextInputComponent
          title={selectedUserIdType.text}
          isMandatory
          textInputProps={{
            placeholder: selectedUserIdType.placeHolder,
            numberOfLines: 1,
            onChangeText: setUserUniqueId,
            onFocus: () => logEvent(AMPLITUDE_CONSTANTS.authentication.id_number_clicked),
            onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.authentication.id_number_filled),
          }}
        />
      )}
      <TextInputComponent
        title={'Nama Lengkap'}
        isMandatory
        textInputProps={{
          placeholder: 'Masukkan Nama Lengkap',
          numberOfLines: 1,
          onChangeText: setUserName,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.authentication.employee_name_clicked),
          onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.authentication.employee_name_filled),
        }}
      />
      <TextInputComponent
        title={'Nama Perusahaan'}
        isMandatory
        textInputProps={{
          placeholder: 'Masukkan Nama Tempat Anda Bekerja',
          numberOfLines: 1,
          onChangeText: setUserCompanyName,
          onFocus: () => logEvent(AMPLITUDE_CONSTANTS.authentication.employer_name_clicked),
          onEndEditing: () => logEvent(AMPLITUDE_CONSTANTS.authentication.employer_name_filled),
        }}
      />

      <PhoneNumberInput
        showClear={false}
        mandatory
        styleTitle={styles.phoneInpuTitle}
        setInputFocused={onPhoneNumberFocus}
        inputFocused={inputFocused}
        phoneNumber={phoneNumber}
        setPhoneNumber={onChangePhoneNumber}
        disabled={route.params?.phoneNumber}
        errorMessage={inputFieldError.errorMessage}
        isError={inputFieldError.error}
        hasBorderWidth={!route.params?.phoneNumber}
      />

      <DropDownModal
        onPressItem={onPressItem}
        visible={showDropDownModal}
        data={dropdownData}
        onClose={onCloseDropDown}
      />

      <LoadingButton
        testId="cekData"
        textTransform="none"
        onPress={onBoardUser}
        text={'Cek Data'}
        disabled={isDisabled || loading || !validPhoneNumber}
        disabledStyle={styles.loginButtonDisable}
        textStyle={styles.loginButtonTextStyle}
        container={styles.loginButtonStyle}
      />
      <LoginInfoSheet onPressPrimary={onCloseModal} loginInfo={infoModal} onClose={onCloseModal} />
    </MainParentWrapper>
  );
};

SignupUser.defaultProps = {};

SignupUser.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      phoneNumber: PropTypes.string,
    }),
  }).isRequired,
};

export default SignupUser;
