import { Platform } from 'react-native';
import { camelToSnake, spaceToSnake } from './UtilFunctions';
import LogRocket from 'logrocket';

export function initialiseUserAttributes(userData) {
  const { userId, phoneNumber, is_gajigea, employeeId, userName } = userData;
  Moengage.add_unique_user_id(userId);
  Moengage.add_mobile(`+${phoneNumber}`);
  Moengage.add_user_attribute('is_gajigesa', is_gajigea);
  Moengage.add_user_attribute('employee_id', employeeId.toString());
  Moengage.add_user_attribute('full_name', userName);
  Moengage.add_user_attribute('platform', Platform.OS);
  LogRocket.identify(employeeId, {
    name: userName,
    email: 'jamesmorrison@example.com',
    // Add your own custom user variables here, ie:
    id: employeeId.toString(),
    type: 'premium',
  });
}

export function logoutFromMoengage() {
  Moengage.destroy_session();
}

export function trackEngagement(data, eventName) {
  const objectProperties = {};
  if (data) {
    Object.keys(data).map((key) => {
      if (data[key]) {
        const objKey = spaceToSnake(camelToSnake(key));
        const objValue = data[key].toString();
        objectProperties[objKey] = objValue;
      }
    });
  }

  Moengage.track_event(eventName, objectProperties);
}
