import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Pressable, View } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '@style/helper';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import MyText from '@atoms/MyText';
import ImageButton from '@atoms/ImageButton/index';
import CheckBox from 'expo-checkbox';
import InfoBar from '@atoms/Loan/InfoBar';
import { GET_APPLICATION_DETAILS, SUBMT_FOR_LOAN, UPDATE_LOAN_STATUS } from '@gqlQuery/loan';
import { LOAN_APPLICATION_STATUS, LOAN_ATTENTION_TEXT, LOAN_STATUS_TYPES } from '@utils/Constants';
import InfoModalSheet from '@molecules/InfoModalSheet';
import { useSelector } from 'react-redux';
import TransaksiDetails from '@atoms/Loan/TransaksiDetails';
import LoanMetaDetail from '@atoms/Loan/LoanMetaDetail';
import LoanDetailCard from '@atoms/Loan/LoanDetailCard';
import LoanInstallMents from '@atoms/Loan/LoanInstallments';
import AttentionBoard from '@atoms/Loan/AttentionBoard';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { resetNavigationStack } from '@utils/UtilFunctions';
import { styles } from './style';

const LoanDetails = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [loanDetails, setLoanDetails] = useState(null);
  const [appStatus, setAppStatus] = useState([]);
  const [employeeMeta, setEmployeeMeta] = useState(null);
  const { employeeId, employerId } = useSelector((state) => state.Authentication);
  const { loanProductCode, appId, isNewApply } = route.params;
  const [checked] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [disabledButton, setDisabled] = useState(false);

  const [showInfoModalSheet, setInfoModalSheet] = useState({
    visible: false,
    data: LOAN_STATUS_TYPES[LOAN_APPLICATION_STATUS.PENDING_APPROVAL],
    type: 'loan',
  });

  const [getApplicationDetails] = useLazyQuery(GET_APPLICATION_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: { appld: appId ?? -1, loanProductCode, empId: employeeId },
    onCompleted: (data) => {
      setLoanDetails(data?.loan_eligibility[0]?.loan_product_eligibility);
      setAppStatus(data?.loan_applications?.[0] ?? null);
      setInstallments(data.loan_applications?.[0]?.loan_app_installments ?? null);
      setEmployeeMeta(data?.gg_employee?.[0] ?? null);
      setLoading(false);
    },
    onError: () => {},
  });

  const [submitLoan, { loading: submitLoading }] = useMutation(SUBMT_FOR_LOAN, {
    fetchPolicy: 'no-cache',
    variables: {
      loanPayload: {
        userNotified: false,
        loanProductCode: loanDetails?.loanCode,
        employeeCode: employeeMeta?.employeeCode,
        employerCode: employeeMeta?.employerInfo.employerCode,
        employeeId,
        employerId,
        principal: loanDetails?.principal,
        updatedBy: employeeId,
        fee: loanDetails?.fee,
        applicationStatus: LOAN_APPLICATION_STATUS.PENDING_APPROVAL,
      },
    },
    onCompleted: (data) => {
      getApplicationDetails({
        variables: {
          appld: data.insert_loan_applications?.returning[0].id,
          loanProductCode,
          empId: employeeId,
        },
      });
      setInfoModalSheet((prevState) => ({
        ...prevState,
        visible: true,
      }));
    },
    onError: () => {},
  });

  const [updateLoanStatus] = useMutation(UPDATE_LOAN_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { applicationId: appStatus?.id },
    onCompleted: () => {},
    onError: () => {},
  });

  const onPressHandler = () => {
    let e = AMPLITUDE_CONSTANTS.Loans.loan_requested;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: loanDetails?.loanCode,
    });
    setDisabled(true);
    submitLoan();
  };

  useEffect(() => {
    if (
      appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
      appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
    ) {
      let e = AMPLITUDE_CONSTANTS.Loans.loan_schedule_page;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.loan_product_code]: loanDetails?.loanCode,
      });
    }

    if (appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL) {
      resetNavigationStack(CommonActions, navigation);
      let e = AMPLITUDE_CONSTANTS.Loans.loan_verification;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.loan_product_code]: loanDetails?.loanCode,
      });
    }
  }, [appStatus]);

  useEffect(() => {
    getApplicationDetails();
  }, []);

  const onPressPrimaryInfoModal = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false }));
    updateLoanStatus();
  };
  const onCloseModalSheet = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false }));
  };
  const onPressSecondary = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false }));
  };

  const onPressLinkTNC = () => {
    let e = AMPLITUDE_CONSTANTS.Loans.loan_tnc_checkbox_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: loanDetails?.loanCode,
    });

    navigation.navigate('ProductStack', { screen: 'LoanTerms', params: { ...route.params } });
  };

  function shouleRenderButtonContainer(onPress, show, onPressLinkTNC, checkedValue, disabledButton, submitLoading) {
    if (show) {
      const disabledCheck = !(checkedValue || route?.params?.isAccepted) || disabledButton;
      return (
        <View style={styles.bottomButtonContainer}>
          <Pressable onPress={onPressLinkTNC} style={styles.pressableLinkContainer}>
            <CheckBox value={checkedValue || route?.params?.isAccepted} color={colors.primary_carnation} />
            <MyText isRegular customStyle={styles.pressableTitle}>
              Saya menyetujui Syarat & Ketentuan yang berlaku
            </MyText>
          </Pressable>

          <ImageButton
            testId="salinKode"
            textTransform="none"
            onPress={onPress}
            text="Lanjutkan Proses"
            loading={submitLoading}
            disabled={disabledCheck}
            textStyle={{ color: colors.neutral_cotton }}
            styleContainer={{
              ...styles.copyButtonStyle,
              borderColor: disabledCheck ? '#F4F4F4' : colors.primary_carnation,
            }}
            pressableStyle={{
              ...styles.pressableStyleContainer,
              ...{
                backgroundColor: disabledCheck ? '#DDDDDD' : colors.primary_carnation,
              },
            }}
            iconLeft={<MaterialIcon name="privacy-tip" color={colors.neutral_cotton} size={20} />}
          />
        </View>
      );
    }

    return null;
  }

  const onPressHistory = () => {
    navigation.navigate('HomeScene', { screen: 'Transaksi' });
  };

  return (
    <MainParentWrapper
      isRounded={false}
      isScrollable
      isError={false}
      loading={loading}
      containerStyle={{ flexGrow: 1, paddingTop: 0 }}
    >
      <View style={{ marginVertical: normalize(10) }}>
        <InfoBar
          show={
            appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
            appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED ||
            appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL
          }
          appStatus={appStatus?.applicationStatus}
          onPressLink={onPressHistory}
        />
      </View>

      <LoanDetailCard
        principalAmt={loanDetails?.principal}
        recievable={loanDetails?.principal - loanDetails?.upfrontFee}
        tenure={loanDetails?.tenure}
        installAmt={loanDetails?.installmentValue}
        showTag={appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL}
      />

      <LoanMetaDetail
        upFrontFee={loanDetails?.upfrontFee}
        show={appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL || isNewApply}
      />
      <AttentionBoard
        dataList={LOAN_ATTENTION_TEXT}
        show={appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL || isNewApply}
      />

      {shouleRenderButtonContainer(
        onPressHandler,
        isNewApply && !appStatus,
        onPressLinkTNC,
        checked,
        disabledButton,
        submitLoading,
      )}

      <LoanInstallMents
        show={
          appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
          appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
        }
        installMentsData={installments}
      />

      <TransaksiDetails
        metainfo={employeeMeta}
        fee={loanDetails?.upfrontFee}
        bankName={employeeMeta?.bank_name?.bankName}
        benificiaryName={employeeMeta?.bank_name?.bank_name?.[0]?.beneficiaryName}
        bankAccountNo={employeeMeta?.bank_name?.bank_name?.[0]?.bankAccountNo}
        show={
          appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
          appStatus?.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
        }
      />

      <InfoModalSheet
        title={showInfoModalSheet.data?.title}
        description={showInfoModalSheet.data?.description}
        hasTwoButtons={false}
        buttonText={showInfoModalSheet.data?.buttonText}
        icon={showInfoModalSheet.data?.icon}
        visible={showInfoModalSheet.visible}
        onPressPrimary={onPressPrimaryInfoModal}
        onClose={onCloseModalSheet}
        onPressSecondary={onPressSecondary}
      />
    </MainParentWrapper>
  );
};

export default LoanDetails;
