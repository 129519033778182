import React from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import FaIcon from '@expo/vector-icons/FontAwesome';
import OctIcon from '@expo/vector-icons/Octicons';
import { KETENTUAN_LAYANAN, KETENTUAN_LAYANAN_LIST_1 } from '@utils/Constants';

/* Styles import */
import { centerView, subTitleText } from '@style/Layouts';
import { background, LgCard } from '@style/Themes';
import { ScrollView } from 'react-native';
import { Font } from '@style/Fonts';

const KetentuanLainnya = () => {
  const ListItem = (item) => {
    return (
      <View style={styles.row}>
        <OctIcon name="primitive-dot" />
        <Text style={styles.rowText}>{item.item}</Text>
      </View>
    );
  };

  return (
    <>
      <View style={[centerView, background]}>
        <View style={[LgCard, { paddingBottom: 10 }]}>
          <ScrollView>
            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                {KETENTUAN_LAYANAN.validDate}
              </Text>
            </View>

            <Text style={styles.text}>
              {KETENTUAN_LAYANAN.title1}(<Text style={{ fontFamily: Font.Nunito_Bold }}>"Ketentuan Layanan"</Text>)
              {KETENTUAN_LAYANAN.title2}(<Text style={{ fontFamily: Font.Nunito_Bold }}>"Kita"</Text>,
              <Text style={{ fontFamily: Font.Nunito_Bold }}>"Kami"</Text> atau
              <Text style={{ fontFamily: Font.Nunito_Bold }}>"Milik Kami"</Text>){KETENTUAN_LAYANAN.title3}(
              <Text style={{ fontFamily: Font.Nunito_Bold }}>"Layanan"</Text>).
            </Text>
            <Text style={[styles.textMargin]}>{KETENTUAN_LAYANAN.desc1}</Text>

            {/* Section 1 */}
            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                1. Penerimaan Ketentuan
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info2}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info3}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info4}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info5}</Text>

            {/* Section 2 */}
            <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
              2. Ruang Lingkup Penerapan
            </Text>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info6}</Text>

            {/* Section 3 */}
            <Text
              accessibilityLabel="informasiProductSection"
              style={[subTitleText, { color: '#3863F0', marginRight: 1 }]}
            >
              3. Informasi yang Kami Kumpulkan dan Bagaimana Kami Menggunakannya
            </Text>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info8}</Text>

            {/* Flatlist 1 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info9}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info10}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info11}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info12}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info13}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info14}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info15}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info16}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info17}</Text>

            {/* Flatlist 3 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info18}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info19}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info20}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info21}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info22}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info23}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info24}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info25}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info26}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info27}</Text>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info28}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info29}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info30}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info31}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info32}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info33}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info34}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                4. Biaya Administrasi
              </Text>
            </View>

            {/* Flatlist 4 */}
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info35}</Text>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info36}</Text>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info37}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                5. Kebijakan Cookie Kami
              </Text>
            </View>

            <Text style={styles.text}>{KETENTUAN_LAYANAN.info38}</Text>

            {/* Flatlist 5 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info39}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info40}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info41}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info42}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info43}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info44}</Text>

            {/* Flatlist 6 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info45}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info46}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info47}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info48}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info49}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info50}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                6. Keseluruhan Data
              </Text>
            </View>

            <Text style={styles.text}>{KETENTUAN_LAYANAN.info51}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                7. Kebijakan Berbagi Data Kami
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info52}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info53}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info54}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info55}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info56}</Text>

            {/* Flatlist 8 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info57}</Text>
            </View>

            <View style={styles.row2}>
              <FaIcon name="dot-circle-o" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info58}</Text>
            </View>

            <View style={styles.row2}>
              <FaIcon name="dot-circle-o" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info59}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info60}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info60}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info60}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info60}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info60}</Text>
            </View>

            <View style={styles.row2}>
              <FaIcon name="dot-circle-o" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info61}</Text>
            </View>

            <View style={styles.row2}>
              <FaIcon name="dot-circle-o" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info62}</Text>
            </View>

            <View style={styles.row2}>
              <FaIcon name="dot-circle-o" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info63}</Text>
            </View>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                8. Pilihan Anda Tentang Informasi Pribadi Anda
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info64}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info65}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                9. Material Pemasaran
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info66}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info67}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info68}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                10. Keamanan Informasi Pribadi Anda
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info69}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info70}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                11. Pelanggaran Keamanan Informasi Data Pribadi
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info71}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info72}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info73}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                12. Ganti Rugi GajiGesa
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info74}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info75}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                13. Penyimpanan Data
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info76}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info77}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info78}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                14. Pemberitahuan atau Notifikasi
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info79}</Text>

            {/* Flatlist 10 */}
            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info80}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info81}</Text>
            </View>

            <View style={styles.row}>
              <OctIcon name="primitive-dot" />
              <Text style={styles.rowText}>{KETENTUAN_LAYANAN.info82}</Text>
            </View>

            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info83}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info84}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                15. Perubahan dan Pembaruan pada Kebijakan Privasi
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info85}</Text>
            <Text style={styles.textMargin}>{KETENTUAN_LAYANAN.info86}</Text>

            <View>
              <Text accessibilityLabel="informasiProductSection" style={[subTitleText, { color: '#3863F0' }]}>
                16. Hukum Yang Mengatur dan Penyelesaian Sengketa
              </Text>
            </View>
            <Text style={styles.text}>{KETENTUAN_LAYANAN.info87}</Text>
          </ScrollView>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    maxWidth: '90%',
    marginLeft: 25,
    fontSize: 12,
    lineHeight: 22,
    color: '#454545',
    fontFamily: 'Nuno-Regular',
  },
  textMargin: {
    marginTop: 8,
    maxWidth: '90%',
    marginLeft: 25,
    fontSize: 12,
    lineHeight: 22,
    color: '#454545',
    fontFamily: 'Nuno-Regular',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 5,
    marginHorizontal: '10%',
  },
  row2: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 5,
    marginHorizontal: '15%',
  },
  rowText: {
    flex: 1,
    marginLeft: 8,
    marginTop: -4,
    fontSize: 12,
    alignSelf: 'center',
    fontFamily: Font.Nunito_Regular,
  },
});

export default KetentuanLainnya;
