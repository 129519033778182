import React from 'react'
import {View, StyleSheet} from 'react-native'
import CommonCard from './CommonCard'

const View1 =({description1, title1, title2, description2, hasCongrats, onPressButtonColor, onPressButton, onClose})=>{

    return(
        <View
        style={styles.container}
        >
            <CommonCard 
            customTextWidth={'50%'}
            closeModal={onClose}
            onPressButton={onPressButton}
            onPressButtonColor={onPressButtonColor}
            buttonTitle1={'Kembali'}
            buttonTitle2={'Selengkapnya'}
            description={description1} title={title1}  hasCongrats={hasCongrats} />
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        width:'100%'
    }
})

export default View1
