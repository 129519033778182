import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import BillPaymentWrapper from '@components/BillPaymentWrapper';
import ColumnList from '@components/BpLists/ColumnList';
import MultiColumnList from '@components/BpLists/MultiColumnList';
import SimpleList from '@components/BpLists/SimpleList';
import LoadingButton from '@atoms/LoadingButton';
import LoadingModal from '@components/LoadingModal';
import MyText from '@atoms/MyText';
import NominalListSheet from '@atoms/NominalListSheet';
import RequestTransactionSheet from '@components/RequestTransactionSheet';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import { setCoinBalance } from '@redux/action/Coins';
import { clearUserSelectedPhone } from '@redux/action/UserPhoneAction';
import { respError } from '@redux/action/Common';
import { clearReferral, setIsTransacted, setIsValidReferral, setRedeemCode } from '@redux/action/Referral';
import { store } from '@enhancedStore/store';
import AmplitudeHelper from '@services/amplitude';
import {
  confirmBpTransaction,
  getPreRegisteredAccount,
  getProductListByCategory,
  preCheckBp,
  redeemPoins,
  requestBillPaymentTransaction,
} from '@services/TransactionApi';
import { colors } from '@style/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import {
  BP_DYNAMIC_ACCOUNTS,
  CATEGORY,
  DYNAMIC_ACCOUNTS_TYPE,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  LIST_TYPES,
  TOOLTIP_CONTENT,
} from '@utils/Constants';
import {
  formatUnderScoreLowerCase,
  getAvailableBalance,
  getDynamicAccountListByAccount,
  setBillPaymentReason,
  setImage,
  subtractFromWorkAllowance,
  utmToData,
  returnSelectedItem,
  getListOfDynamicAccounts,
  accountByRuleProductScreen,
} from '@utils/UtilFunctions';
import { setDataAccounts } from '@redux/action/DynamicAccounts';
import { useNavigation, useRoute } from '@react-navigation/native';

const ProductScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { item } = route.params;
  const { isValidCode, redeemCode, isTransacted } = useSelector((state) => state.Referral);
  const { dynamicAccounts, dataAccounts, currentBpSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const { workAllowanceBillPaymentList } = useSelector((state) => state.NewBillPayment);
  const currentSelectedAccountName = currentBpSelectedAccount.constantAccountName;
  const [nominalList, setNominalList] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  const [transactionReq, setTransactionReq] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [loadingTransactionReq, setLoadingTransactionReq] = useState(false);
  const [registeredNumbers, setRegisteredNumbers] = useState([]);
  const [selectedPdam, setSelectedPdam] = useState();
  const [transactionReqModal, setTransactionReqModal] = useState(false);
  const [nominalModal, setNominalModal] = useState(false);
  let { phoneNumber } = useSelector((state) => state.Authentication);
  const { selectedPhoneNumber } = useSelector((state) => state.UserPhone);
  let phoneChanged = false;
  if (selectedPhoneNumber !== '') {
    phoneNumber = selectedPhoneNumber;
    phoneChanged = true;
  }
  const { coinBalance } = useSelector((state) => state.Coins);
  const [accounts, setAccounts] = useState([]);
  const {
    todayBalanceFloor,
    todaySalaryFloor,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    workingDays,
    workedDays,
    usedBalance,
    lockPeriod,
  } = useSelector((state) => state.Withdrawal);
  const [loadingTransactionConfirm, setLoadingTransactionConfirm] = useState(false);
  const [openPacketDataModal, setOpenPacketDataModal] = useState(false);
  const [referralToolTip, setReferralToolTip] = useState({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
  const [referralPrecheckFail, setReferralPrecheckFail] = useState(false);
  const [isFeatureAvailale] = useFeatureFlag({ featureName: FEATURE_FLAGS.DYNAMIC_ACCOUNTS_POINS });

  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);

  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        salaryWithCappedAmount,
        todayBalanceFloor,
        workingDays,
        workedDays,
        maxWithdrawalAmount,
        usedBalance,
      ),
    [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance],
  );

  const accountByRule = useCallback(
    accountByRuleProductScreen(
      dataAccounts,
      lockPeriod,
      newAvailableBalance,
      item,
      dynamicAccountDetails,
      currentSelectedAccountName,
    ),
    [lockPeriod, newAvailableBalance, dynamicAccountDetails, dataAccounts],
  );

  console.log(dynamicAccountDetails.workAllowance?.availableBalance, 'ACCOUNT_BY_RULE_WorkAllowance');

  const [selectedAccount, setSelectedAccount] = useState(accountByRule());
  const dispatch = useDispatch();

  const setAccountList = useCallback(() => {
    //if phone is selected in UserPhone reducer, set EWA as default
    const dynamicAccountList = getDynamicAccountListByAccount(
      dataAccounts,
      BP_DYNAMIC_ACCOUNTS,
      DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    );

    // Checking if selected category is E money (item.id = 15), if yes then remove point account
    if (CATEGORY[item.id].id === 15) {
      // Remove point account
      const findPointsAccount = dynamicAccountList.findIndex(
        (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS,
      );
      if (findPointsAccount !== -1) {
        dynamicAccountList.splice(findPointsAccount, 1);
      }
    }

    // Checking if current selected product category is eligible for work allowance
    let productFoundUnderWorkAllowance = false;
    let accountList = [];
    const findCategory = workAllowanceBillPaymentList.find((e) => e.title === item.menu);
    if (findCategory) {
      const findProduct = findCategory.data[0].list.findIndex((e) => e.prefix === item.prefix);
      if (findProduct !== -1) {
        accountList = dynamicAccountList;
        setAccounts(dynamicAccountList);
        productFoundUnderWorkAllowance = true;
      } else
        accountList = dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
      setAccounts(accountList);
    } else {
      accountList = dynamicAccountList;
      setAccounts(dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE));
    }
    const accountValue = accountByRule(productFoundUnderWorkAllowance, accountList);
    setSelectedAccount(accountValue);
    if (phoneChanged) {
      const salaryAccount = dynamicAccountList.filter(
        (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
      );
      setAccounts(salaryAccount);
      setSelectedAccount(salaryAccount[0]);
    }
  }, [dataAccounts, workAllowanceBillPaymentList, item, phoneChanged]);

  useEffect(() => {
    setAccountList();
  }, [dataAccounts]);

  const getAccoutByTxnResponse = useCallback(
    (response) => {
      let isFromPoins = route?.params?.useCoin && coinBalance > 0;
      let isSubtotalWithinPoin = response.data.data.subtotal <= coinBalance;
      let isSubtotalLessOfEWABalance = response.data.data.subtotal > newAvailableBalance;
      if (lockPeriod || (isFromPoins && isSubtotalWithinPoin) || isSubtotalLessOfEWABalance) return accounts[1];
      return accounts[0];
    },
    [coinBalance, newAvailableBalance],
  );

  const checkBlackoutPeriod = () => {
    if (
      (lockPeriod && dynamicAccountDetails.pointsAccount.availableBalance === 0) ||
      (lockPeriod && CATEGORY[item.id].id == 15)
    ) {
      setLoadingTransactionReq(false);
      dispatch(respError('Transaksi hanya bisa dilakukan pada masa penarikan'));
      return true;
    }
    return false;
  };

  const getRegisteredAccounts = async () => {
    try {
      const response = await getPreRegisteredAccount(item.prefix);
      setRegisteredNumbers(response.data.data);
    } catch (error) {
      throw error;
    }
  };

  const getProductList = useCallback(async () => {
    try {
      error && setError(false);
      const resp = await getProductListByCategory(item.id, phoneNumber);
      if (item.prefix === 'BP-17' || item.prefix === 'BP-06' || item.prefix === 'BP-05') {
        // Means there are saved account numbers for these BPs
        await getRegisteredAccounts();
      }

      if (resp.status == 200 || resp.status == 201) {
        if (typeof resp.data.data == 'object' && !Array.isArray(resp.data.data)) {
          const array = [];
          let isPLNPrepaid = false;
          let concatArray = [];
          for (const key in resp.data.data) {
            if (item.id === 6) {
              //PDAM

              concatArray = concatArray.concat(resp.data.data[key]);
            } else {
              if (key === 'PLN Prepaid') isPLNPrepaid = true;

              const obj = {
                title: key,
                imageUrl: setImage(key, resp.data.data[key][0].imageUrl),
                data: resp.data.data[key],
              };
              array.push(obj);
            }
          }
          setNominalList(isPLNPrepaid ? array[0].data : item.id === 6 ? concatArray : array);
        } else {
          setNominalList(resp.data.data);
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      if (error?.response?.data?.message) dispatch(respError(error.response.data.message));
    }
  }, [item, phoneNumber]);

  const referralPrecheck = useCallback(
    async (selectedNominal) => {
      try {
        referralPrecheckFail && setReferralPrecheckFail(false);
        setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
        if (checkBlackoutPeriod()) return;
        setLoadingTransactionReq(true);
        const response = await preCheckBp(selectedNominal, accountNumber ? accountNumber : phoneNumber, redeemCode);
        requestSelectedTransaction(selectedNominal);
      } catch (error) {
        setReferralPrecheckFail(true);
        setReferralToolTip({ visible: true, message: error?.response?.data?.message });
        requestSelectedTransaction(selectedNominal);
      }
    },
    [
      accountNumber,
      phoneNumber,
      redeemCode,
      referralPrecheckFail,
      maxWithdrawalAmount,
      salaryWithCappedAmount,
      todayBalanceFloor,
      todaySalaryFloor,
      selectedAccount,
    ],
  );

  const requestSelectedTransaction = useCallback(
    async (selectedNominal) => {
      try {
        setLoadingTransactionReq(true);
        // if (checkBlackoutPeriod()) return;

        const response = await requestBillPaymentTransaction(
          selectedNominal,
          accountNumber ? accountNumber : phoneNumber,
          selectedAccount.constantAccountName,
          dynamicAccounts,
        );
        setTransactionReq(response.data.data);
        setLoadingTransactionReq(false);

        // if (CATEGORY[item.id].id !== 15)
        // e-money
        // setSelectedAccount(getAccoutByTxnResponse(response));

        setTransactionReqModal(true);
        let e = AMPLITUDE_CONSTANTS.BillPayment.billPaymentTransactionSummary;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
          [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal.categoryPrefix),
          [e.attributes.product_code]: formatUnderScoreLowerCase(selectedNominal?.code),
          [e.attributes.reason]: 'tagihan_dan_utilitas',
          [e.attributes.reason_id]: 2,
          [e.attributes.source]: 'other_product_page',
          [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        });
      } catch (error) {
        setLoadingTransactionReq(false);
        if (error?.response?.data?.message) {
          if (error?.response?.data?.message === 'Balance tidak tersedia!') {
            dispatch(respError('Saldo Gaji dan Poin belum mencukupi untuk transaksi ini'));
            return;
          }
          dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
        }
      }
    },
    [
      accountNumber,
      phoneNumber,
      maxWithdrawalAmount,
      salaryWithCappedAmount,
      todayBalanceFloor,
      todaySalaryFloor,
      selectedAccount,
    ],
  );
  const confirmTransaction = async () => {
    const selectedNominal = returnSelectedItem(nominalList, item.id);
    try {
      setTransactionReqModal(false);
      setLoadingTransactionConfirm(true);
      setLoadingTransactionReq(true);

      let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.payment_method]: selectedAccount.amplitudeAccountName,
        [e.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e.attributes.reason_id]: 2,
        [e.attributes.reason]: 'tagihan_dan_utilitas',
        [e.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.source]: 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e.attributes.fee_charged]: transactionReq?.adminFee,
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });

      let response = null;
      if (isFeatureAvailale.show) {
        // if dynamic account feature flagged
        if (selectedAccount?.optionChangedByUser) {
          response = await requestBillPaymentTransaction(
            selectedNominal,
            accountNumber ? accountNumber : phoneNumber,
            selectedAccount.constantAccountName,
            dynamicAccounts,
          );
        }
        let newTxnReqId = selectedAccount.optionChangedByUser ? response.data.data.id : transactionReq.id;
        await confirmBpTransaction(
          newTxnReqId,
          selectedNominal?.biller ?? '',
          referralPrecheckFail ? '' : redeemCode,
          selectedAccount.constantAccountName,
        );
        dispatch(clearReferral());
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
          if (coinBalance - transactionReq.subtotal >= 0) {
            dispatch(setCoinBalance(coinBalance - transactionReq.subtotal));
          }
        } else if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) {
          const newData = subtractFromWorkAllowance(dataAccounts, transactionReq.subtotal);
          dispatch(setDataAccounts(newData));
        }
      } else {
        //if No FeatueFlag
        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.POINTS) {
          await dispatch(
            redeemPoins(
              transactionReq.subtotal,
              selectedNominal.categoryPrefix,
              selectedNominal.code,
              accountNumber?.length === 0 ? phoneNumber : accountNumber,
            ),
          );
        } else {
          await confirmBpTransaction(
            transactionReq.id,
            selectedNominal?.biller ?? '',
            referralPrecheckFail ? '' : redeemCode,
            selectedAccount.constantAccountName,
          );
          dispatch(clearReferral());
          AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        }
      }
      let e2 = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;
      navigation.popToTop();
      navigation.navigate('TransactionList', {
        screen: 'BillPaymentConfirm',
        params: {
          productName: transactionReq.productName,
          category: item.name,
          [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
            selectedNominal?.categoryPrefix ?? item.prefix,
          ),
          [e2.attributes.product_code]: formatUnderScoreLowerCase(
            selectedNominal?.code ?? transactionReq?.productCode ?? '',
          ),
          [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
          [e2.attributes.reason]: 'tagihan_dan_utilitas',
          [e2.attributes.reason_id]: 2,
          [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
          [e2.attributes.fee_charged]: transactionReq.fee,
          [e2.attributes.source]: 'other_product_page',
          [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
          [e2.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
        },
      });

      AmplitudeHelper.logEvent(e2.name, {
        [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e2.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e2.attributes.reason]: 'tagihan_dan_utilitas',
        [e2.attributes.reason_id]: 2,
        [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e2.attributes.fee_charged]: transactionReq.fee,
        [e2.attributes.source]: 'other_product_page',
        [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e2.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });

      setLoadingTransactionConfirm(false);
      setLoadingTransactionReq(false);
    } catch (error) {
      setLoadingTransactionReq(false);
      setLoadingTransactionConfirm(false);
      setTransactionReqModal(false);

      if (error?.response?.data?.error === 'REFERRAL_CODE_NOT_APPLICABLE') {
        dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        dispatch(setIsTransacted(true));
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'not first transaction',
        });
      } else if (error?.response?.data?.error === 'INVALID_REFERRAL_CODE') {
        dispatch(setIsTransacted(false));
        dispatch(setIsValidReferral(false, false));
        dispatch(setRedeemCode(''));
        dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'invalid code',
        });
      } else if (isValidCode) {
        if (isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        else dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'system error',
        });
      } else {
        dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
      }

      let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e.attributes.product_price]: transactionReq.requestAmount,
        [e.attributes.reason]: 'tagihan_dan_utilitas',
        [e.attributes.reason_id]: 2,
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.source]: 'other_product_page',
        [e.failure_message]: formatUnderScoreLowerCase(
          error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.',
        ),
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });
    }
  };

  const changeNominalFromList = (data) => {
    const findIndex = nominalList.findIndex((e) => e?.isSelected);
    if (findIndex !== -1) {
      const nominalIndex = nominalList[findIndex].data.findIndex((e) => e.id === data.id);
      if (nominalIndex !== -1) {
        const newNominalList = nominalList[findIndex].data.slice().map((e, i) => {
          if (i === nominalIndex) {
            return { ...e, isSelected: true };
          } else return { ...e, isSelected: false };
        });
        const mainNewNominalList = nominalList.slice();
        mainNewNominalList[findIndex] = {
          ...mainNewNominalList[findIndex],
          isSelected: true,
          data: newNominalList,
        };
        setNominalList(mainNewNominalList);
      }
    }
  };

  const onPressConfirm = () => {
    const findIndex = nominalList.findIndex((e) => e?.isSelected);
    if (findIndex !== -1) {
      const nominalIndex = nominalList[findIndex].data.findIndex((e) => e?.isSelected);

      if (nominalIndex !== -1) {
        if (item.id === 13) {
          navigation.navigate('TransactionList', {
            screen: 'VoucherScreen',
            params: {
              data: nominalList[findIndex],
              useCoin: route?.params?.useCoin ?? false,
            },
          });
          return;
        }
        setLoadingTransactionReq(true);
        if (redeemCode.length > 0 && isValidCode) {
          referralPrecheck(nominalList[findIndex].data[nominalIndex]);
        } else {
          requestSelectedTransaction(nominalList[findIndex].data[nominalIndex]);
        }
      }
    }
  };

  const onClickDetails = (data) => {
    //For Packet Data specifically

    let e1 = AMPLITUDE_CONSTANTS.BillPayment.detailsModal;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.account_number]: phoneNumber,
      [e1.attributes.product_price]: data.amount,
      [e1.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e1.attributes.product_code]: formatUnderScoreLowerCase(data.code),
      [e1.attributes.reason]: 'tagihan_dan_utilitas',
      [e1.attributes.reason_id]: 2,
    });

    let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedLihatDetail;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(data.code),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.product_price]: data.amount,
      [e.attributes.account_number]: phoneNumber,
    });

    const findItem = nominalList.findIndex((e) => e.id === data.id);
    const previouslySelected = nominalList.findIndex((e) => e?.isSelected);
    if (findItem !== -1) {
      let newNominalList = nominalList.slice();
      if (previouslySelected !== -1) {
        newNominalList[previouslySelected] = {
          ...newNominalList[previouslySelected],
          isSelected: false,
        };
      }
      newNominalList[findItem] = {
        ...newNominalList[findItem],
        isSelected: true,
      };
      setOpenPacketDataModal(true);
      setNominalList(newNominalList);
    }
  };

  const onPressItem = (data, triggerForTokenPln = false) => {
    //This is done so that on pressing the nominal app will check if phone # has changed
    //If yes, then only EWA should be selected.
    setAccountList();
    let categoryId = null;
    if (!!data?.title) {
      categoryId = data.data[0]?.categoryId;
    } else {
      categoryId = data?.categoryId;
    }
    switch (categoryId) {
      case 16:
        let e = AMPLITUDE_CONSTANTS.BillPayment.Asuransi.qoalaBannerClick;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.transaction_category_id]: 'bp-16',
          [e.attributes.banner_name]: utmToData(data.description),
        });
        Linking.openURL(data.description);
        break;

      case 6:
      case 17:
      case 8:
        if (redeemCode.length > 0 && isValidCode) {
          referralPrecheck(data);
        } else {
          requestSelectedTransaction(data);
        }

        break;

      case 7:
      case 13:
      case 5:
      case 15:
      case 10:
        try {
          const findItem = nominalList.findIndex((e) => {
            if (categoryId === 10 || categoryId === 5 || categoryId === 7) {
              return e.id === data.id;
            } else if (categoryId === 13 || categoryId === 15) {
              return e.title === data.title;
            }
          });
          const previouslySelected = nominalList.findIndex((e) => e.isSelected);
          if (findItem !== -1) {
            let newNominalList = nominalList.slice();
            if (previouslySelected !== -1) {
              newNominalList[previouslySelected] = {
                ...newNominalList[previouslySelected],
                isSelected: false,
              };
            }
            newNominalList[findItem] = {
              ...newNominalList[findItem],
              isSelected: true,
            };
            if (categoryId === 13 || categoryId === 15) {
              let e = AMPLITUDE_CONSTANTS.BillPayment.providerSelected;
              AmplitudeHelper.logEvent(e.name, {
                [e.attributes.transaction_category_id]:
                  formatUnderScoreLowerCase(newNominalList[findItem]?.data[0]?.categoryPrefix) ?? '',
                [e.attributes.provider]: newNominalList[findItem]?.data[0]?.biller ?? '',
              });
              let data = newNominalList[findItem]?.data?.slice().map((e, i) => {
                if (i === 0) {
                  return {
                    ...e,
                    isSelected: true,
                  };
                } else return e;
              });
              newNominalList[findItem] = {
                ...newNominalList[findItem],
                data,
              };
            }

            setNominalList(newNominalList);
            if (categoryId === 10 || triggerForTokenPln || categoryId === 7) {
              let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
              AmplitudeHelper.logEvent(e.name, {
                [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
                [e.attributes.product_code]: formatUnderScoreLowerCase(data.code),
                [e.attributes.reason]: 'tagihan_dan_utilitas',
                [e.attributes.reason_id]: 2,
                [e.attributes.source]: 'other_product_page',
                [e.attributes.product_price]: data.amount,
                [e.attributes.account_number]: phoneNumber,
                [e.attributes.referral_code]: store.getState().Referral.redeemCode,
              });

              if (redeemCode.length > 0 && isValidCode) {
                referralPrecheck(data);
              } else {
                requestSelectedTransaction(data);
              }
            }
          }
        } catch (error) {}
        break;

      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(clearUserSelectedPhone());
    const clickedBack = navigation.addListener('blur', () => {
      let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedBack;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    });
    return clickedBack;
  }, [navigation]);

  useEffect(() => {
    // Token PLN
    if (item.id === 17) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    }
    let e = AMPLITUDE_CONSTANTS.BillPayment.ProductPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route.params?.source ?? 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setLoading(true);
    getProductList();
  }, [route.params]);

  useEffect(() => {
    setLoading(true);
    getProductList();
  }, [selectedPhoneNumber]);

  useEffect(() => {
    if (!!route.params?.pdamRegion) {
      setSelectedPdam(route.params.pdamRegion);
    }
  }, [route.params]);

  const returnFooter = () => {
    switch (CATEGORY[item.id].listName) {
      case LIST_TYPES.COLUMN_LIST:
        return (
          <>
            {CATEGORY[item.id].hasConfirmButton && (
              <LoadingButton
                text={'Konfirmasi'}
                buttonColor={colors.orange}
                loadingColor={colors.white}
                loading={loadingTransactionReq}
                disabled={accountNumber?.length === 0 || returnSelectedItem(nominalList) === null}
                container={{ borderRadius: 40, marginBottom: 5 }}
                onPress={() =>
                  onPressItem(
                    {
                      ...returnSelectedItem(nominalList),
                    },
                    true,
                  )
                }
              />
            )}
          </>
        );
    }
  };

  const getListTypeByCategoryId = useCallback(() => {
    switch (CATEGORY[item.id].listName) {
      case LIST_TYPES.COLUMN_LIST:
        return (
          <>
            <ColumnList
              list={nominalList}
              onPress={onPressItem}
              headerText={item.name}
              categoryId={item.id}
              hasConfirmButton={CATEGORY[item.id].hasConfirmButton}
              modalNominals={CATEGORY[item.id].modalNominals}
              footer={returnFooter}
            />
          </>
        );
      case LIST_TYPES.SIMPLE_LIST:
        return (
          <SimpleList
            selectedItem={returnSelectedItem(nominalList)}
            setOpenPacketDataModal={setOpenPacketDataModal}
            onClickDetails={onClickDetails}
            openPacketDataModal={openPacketDataModal}
            list={nominalList}
            onPress={onPressItem}
            headerText={item.name}
            categoryId={item.id}
            hasConfirmButton={CATEGORY[item.id].hasConfirmButton}
            modalNominals={CATEGORY[item.id].modalNominals}
            hasImage={CATEGORY[item.id].hasImage}
          />
        );

      case LIST_TYPES.MULTICOLUMN_LIST:
        return (
          <MultiColumnList
            list={nominalList}
            onPress={onPressItem}
            headerText={item.name}
            categoryId={item.id}
            hasConfirmButton={CATEGORY[item.id].hasConfirmButton}
            modalNominals={CATEGORY[item.id].modalNominals}
            showTitle={CATEGORY[item.id].showTitle}
            setNominalModal={(value) => {
              let e = AMPLITUDE_CONSTANTS.BillPayment.dropdownSelected;
              AmplitudeHelper.logEvent(e.name, {
                [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
              });
              setNominalModal(value);
            }}
            onPressConfirm={onPressConfirm}
          />
        );

      case LIST_TYPES.NO_LIST:
        return (
          <View //return this view for Pascabayar
            style={{ padding: 15 }}
          >
            <MyText isRegular={false} h3>
              Nomor HP :
            </MyText>
            <MyText isRegular={false} h5 customStyle={{ marginTop: 5, marginBottom: 15 }}>
              {phoneNumber}
            </MyText>

            <MyText isRegular={false} h3>
              Provider :{' '}
            </MyText>
            <MyText isRegular={false} h5 customStyle={{ marginTop: 5, marginBottom: 15 }}>
              {nominalList[0]?.name ?? 'N/A'}
            </MyText>

            <LoadingButton
              text={'Konfirmasi'}
              buttonColor={colors.orange}
              loadingColor={colors.white}
              loading={loadingTransactionReq}
              container={{ borderRadius: 40, marginTop: 15 }}
              onPress={() => onPressItem(nominalList[0])}
            />
          </View>
        );

      default:
        return (
          <>
            {CATEGORY[item.id].hasConfirmButton && (
              <LoadingButton
                text={'Konfirmasi'}
                buttonColor={colors.orange}
                loadingColor={colors.white}
                loading={loadingTransactionReq}
                disabled={accountNumber?.length === 0 ?? true}
                container={{ borderRadius: 40, marginTop: 15 }}
                onPress={() =>
                  onPressItem(
                    CATEGORY[item.id].id === 17
                      ? nominalList[0]?.data[0]
                      : CATEGORY[item.id].id === 6
                      ? onPressItem(selectedPdam)
                      : nominalList[0],
                  )
                }
              />
            )}
          </>
        );
    }
  }, [item, nominalList, loadingTransactionReq, accountNumber, openPacketDataModal]);

  const onPressNext = () => {
    navigation.navigate('PageWilayah', { dataList: nominalList, itemParams: item });
  };

  const onPressModal = (prefix) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.userCanceledBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.button]: 'batal',
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setTransactionReqModal(false);
  };

  const onPressNominal = (item) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.categoryPrefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(item.code),
      [e.attributes.reason]: setBillPaymentReason(item.biller).reason,
      [e.attributes.reason_id]: setBillPaymentReason(item.biller).id,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.product_price]: item.amount,
      [e.attributes.account_number]: phoneNumber,
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    changeNominalFromList(item);
    setNominalModal(false);
  };

  const changeSelectedAccount = (account) => {
    const sourceAccount = accountByRule();
    account.optionChangedByUser = sourceAccount.constantAccountName !== account.constantAccountName;
    setSelectedAccount(account);
    if (account.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
    }
  };

  return (
    <BillPaymentWrapper
      withBlueBg={CATEGORY[item.id].withBlueBg}
      showAccount={CATEGORY[item.id].showAccount}
      withTabs={CATEGORY[item.id].withTabs}
      isError={error}
      loading={loading}
      accountNumber={accountNumber}
      setAccountNumber={setAccountNumber}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      registeredNumbers={registeredNumbers}
      id={item.id}
      prefix={item.prefix}
      setSelectedPdam={setSelectedPdam}
      selectedPdam={selectedPdam}
      onPressNext={onPressNext}
      onReload={() => {
        setLoading(true);
        getProductList();
      }}
      showTour={route.params?.showTour ?? false}
      tourType={route.params?.tourType}
    >
      {getListTypeByCategoryId()}

      <LoadingModal
        loading={
          !CATEGORY[item.id].hasConfirmButton || item.id === 13 || item.id === 15
            ? loadingTransactionReq
            : loadingTransactionConfirm
        }
      />
      <RequestTransactionSheet
        selectedAccount={selectedAccount}
        setSelectedAccount={changeSelectedAccount}
        accounts={accounts}
        visible={transactionReqModal}
        lockPeriod={lockPeriod}
        transactionReq={transactionReq}
        onPressModal={onPressModal}
        prefix={item.prefix}
        productId={item.id}
        onPressConfirm={confirmTransaction}
        isValidCode={isValidCode}
        isTransacted={isTransacted}
        toolTip={referralToolTip}
        setTooltip={setReferralToolTip}
      />
      {CATEGORY[item.id]?.modalNominals && (
        <NominalListSheet
          list={returnSelectedItem(nominalList)?.data}
          visible={nominalModal}
          onPressModal={() => setNominalModal(false)}
          onPress={onPressNominal}
        />
      )}
    </BillPaymentWrapper>
  );
};

export default ProductScreen;
