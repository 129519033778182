import React, { useCallback, useEffect } from 'react';
import { Linking, Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Loading from '@screens/Loading';
import { colors } from '@style/colors';
import { COMMON_CONSTANTS } from '@utils/Constants';
import Icon from '@expo/vector-icons/Ionicons';
import MyText from '@atoms/MyText';
import DropdownInput from '@atoms/DropdownInput';
import IconButton from '@atoms/IconButton';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';
import CustomTab from '@atoms/CustomTab';
import { store } from '@enhancedStore/store';
import { APPTOURMETA, APP_TOUR_TYPE } from '@utils/AppTourConstants';
import AppTourComponentWrapper from '../AppTour/AppTourComponentWrapper';
import withAppTourHoc from '../AppTour/CopilotHoc';
import { styles } from './style';
import AccountNumberComponent from '../AccountNumberComponent';

const renderLoaderForChildren = (loading, children) => {
  if (loading) return <Loading style={styles.loading} />;
  return children;
};

const BillPaymentWrapper = ({
  withBlueBg = true,
  withTabs = false,
  children,
  loading,
  isError,
  onReload,
  showAccount = true,
  accountNumber,
  setAccountNumber,
  activeTab,
  setActiveTab,
  registeredNumbers,
  id,
  isFullScreen,
  selectedPdam,
  onPressNext,
  prefix,
  start,
  copilotEvents,
  tourType = 'pdam',
  showTour,
}) => {
  let { phoneNumber, userName } = useSelector((state) => state.Authentication);
  const { selectedPhoneNumber, selectedUserName } = useSelector((state) => state.UserPhone);
  if (selectedPhoneNumber !== '') {
    phoneNumber = selectedPhoneNumber;
    userName = selectedUserName;
  }
  const onContact = () => {
    Linking.openURL(COMMON_CONSTANTS.support);
  };

  useEffect(() => {
    copilotEvents?.on('stepChange', (prop) => {
      if (withTabs) {
        if (prop.order === 3) {
          setActiveTab(2);
        } else if (prop.order === 2) {
          setActiveTab(1);
        }
      }
    });

    return () => copilotEvents?.off('stepChange');
  }, []);

  useEffect(() => {
    !loading && !isError && showTour && start();
  }, [tourType, showTour, loading]);

  const selectInputBaru = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setActiveTab(1);
  };

  const selectIdTerdaftar = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.registeredIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setActiveTab(2);
  };

  const onSelectFromDropDown = (index, value) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.account_number]: value,
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setAccountNumber(value);
  };

  const handleFocusInputText = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
  };

  const handleAfterEdit = useCallback(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
  }, []);

  const returnChildren = () => {
    if (withTabs) {
      return (
        <View style={{ paddingTop: 20, paddingHorizontal: 15 }}>
          <MyText isRegular={false}>ID Pelanggan</MyText>
          <View style={styles.tabContainer}>
            <AppTourComponentWrapper
              order={1}
              text={{
                description: APPTOURMETA[tourType][1]?.description,
                tourStep: APPTOURMETA[tourType][1]?.tourStep,
              }}
              props={{
                style: { flex: 1 },
              }}
              type={tourType}
              name="Step1"
            >
              <CustomTab text={'Input Baru'} onPress={selectInputBaru} isActive={activeTab === 1} />
            </AppTourComponentWrapper>
            <AppTourComponentWrapper
              order={3}
              text={{
                description: APPTOURMETA[tourType][3]?.description,
                tourStep: APPTOURMETA[tourType][3]?.tourStep,
              }}
              props={{
                style: { flex: 1 },
              }}
              type={tourType}
              name="Step3"
            >
              <CustomTab text={'ID Terdaftar'} onPress={selectIdTerdaftar} isActive={activeTab === 2} />
            </AppTourComponentWrapper>
          </View>
          <AppTourComponentWrapper
            order={2}
            text={{
              description: APPTOURMETA[tourType][2]?.description,
              tourStep: APPTOURMETA[tourType][2]?.tourStep,
            }}
            props={{
              style: null,
            }}
            type={tourType}
            name="Step2"
          >
            {activeTab === 1 && (
              <TextInput
                value={accountNumber}
                onChangeText={setAccountNumber}
                style={styles.inputText}
                placeholder={'Masukkan ID pelanggan'}
                onFocus={handleFocusInputText}
                onBlur={handleAfterEdit}
              />
            )}

            {id === 6 && activeTab === 1 && (
              <TouchableOpacity onPress={() => onPressNext()} style={styles.selectPdam} activeOpacity={0.6}>
                <TextInput
                  style={[styles.inputText, { borderWidth: 0, flex: 1 }]}
                  placeholder="Pilih Wilayah"
                  editable={false}
                  value={selectedPdam?.name ?? ''}
                  onFocus={handleFocusInputText}
                />
                <IconButton
                  icon={'chevron-right'}
                  style={styles.iconButton}
                  iconStyle={{ color: '#d4d4d4' }}
                  size={15}
                />
              </TouchableOpacity>
            )}
          </AppTourComponentWrapper>

          {activeTab === 2 && (
            <DropdownInput
              style={styles.dropDown}
              dropStyle={{ width: '89%', elevation: 5, paddingHorizontal: 10, paddingVertical: 5, minHeight: '50%' }}
              textStyle={{ color: accountNumber ? colors.black : '#272727', fontSize: 14 }}
              defaultValue={'Pilih ID pelanggan'}
              styleFocus={{ width: '100%' }}
              options={registeredNumbers && registeredNumbers.map((item) => item.accountNumber)}
              onSelect={onSelectFromDropDown}
              value={accountNumber}
            />
          )}

          <AppTourComponentWrapper
            active={
              tourType === APP_TOUR_TYPE.pdam ||
              tourType === APP_TOUR_TYPE.tokenpln ||
              tourType === APP_TOUR_TYPE.tagihanpln
                ? false
                : true
            }
            order={4}
            text={{
              description: APPTOURMETA[tourType][4]?.description,
              tourStep: APPTOURMETA[tourType][4]?.tourStep,
            }}
            props={{
              style: { paddingVertical: 5 },
            }}
            type={tourType}
            name="Step4"
          >
            {id === 6 && activeTab === 2 && (
              <TouchableOpacity onPress={() => onPressNext()} style={styles.selectPdam} activeOpacity={0.6}>
                <TextInput
                  style={[styles.inputText, { borderWidth: 0, flex: 1 }]}
                  placeholder="Pilih Wilayah"
                  editable={false}
                  value={selectedPdam?.name ?? ''}
                  onFocus={handleFocusInputText}
                />
                <IconButton
                  icon={'chevron-right'}
                  style={styles.iconButton}
                  iconStyle={{ color: '#d4d4d4' }}
                  size={15}
                />
              </TouchableOpacity>
            )}
          </AppTourComponentWrapper>

          <AppTourComponentWrapper
            order={5}
            text={APPTOURMETA[tourType][5]}
            props={{
              style: { paddingVertical: 5 },
            }}
            type={tourType}
            name="Step5"
          >
            {children}
          </AppTourComponentWrapper>
        </View>
      );
    } else {
      return (
        <AppTourComponentWrapper
          order={prefix == 'BP-07' ? 3 : 2}
          text={APPTOURMETA[tourType][prefix == 'BP-07' ? 3 : 2]}
          props={{
            style: { paddingVertical: 5, flex: 1 },
          }}
          type={tourType}
          name="Step6"
        >
          {children}
        </AppTourComponentWrapper>
      );
    }
  };

  if (withBlueBg) {
    return (
      <View style={[styles.container, isFullScreen ? { paddingBottom: 0 } : null]}>
        <View style={styles.blueBg} />
        {showAccount && (
          <AppTourComponentWrapper
            order={1}
            text={{
              description: APPTOURMETA[tourType][1]?.description,
              tourStep: APPTOURMETA[tourType][1]?.tourStep,
            }}
            props={{
              style: { paddingVertical: 5 },
            }}
            type={tourType}
            name="Step1"
          >
            <AccountNumberComponent
              name={userName}
              phoneNumber={phoneNumber}
              txnCategory={formatUnderScoreLowerCase(prefix)}
            />
          </AppTourComponentWrapper>
        )}
        <View style={[styles.childContainer, isFullScreen ? styles.fullScreen : null]}>
          {loading && <Loading style={styles.loading} />}
          {isError && (
            <View style={[styles.containerError]}>
              <Text style={styles.text}>Mohon Maaf terjadi kesalahan sistem. Mohon muat ulang halaman.</Text>
              <View style={styles.buttonGroup}>
                <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onContact}>
                  <Text style={styles.buttonText}>
                    <Icon style={[styles.buttonText, { fontSize: 15 }]} name="logo-whatsapp" /> BANTUAN
                  </Text>
                </Pressable>
                <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onReload}>
                  <Text style={styles.buttonText}>
                    <Icon style={[styles.buttonText, { fontSize: 15 }]} name="refresh" /> MUAT ULANG
                  </Text>
                </Pressable>
              </View>
            </View>
          )}
          {returnChildren()}
        </View>
      </View>
    );
  } else {
    return <>{renderLoaderForChildren(loading, children)}</>;
  }
};

const BillPaymentWrapperWithTour = withAppTourHoc(BillPaymentWrapper, {
  onPressNext: () => {},
  svgMaskProps: { isFullWidth: false, widthStartAdj: 0 },
  isArrowVisible: true,
  verticalOffset: 35,
});

export default React.memo(BillPaymentWrapperWithTour);
