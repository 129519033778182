import moment from 'moment';
import React, { useCallback } from 'react';
import { Dimensions, FlatList, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import { listDateFormer } from '@utils/UtilFunctions';
import Close from '@expo/vector-icons/AntDesign';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';
import { Font } from '@style/Fonts';
import Collapsible from 'react-native-collapsible';
import RoundedButton from '@atoms/RoundedButton';
var radio_props = [
  { label: 'Semua Tanggal', value: 0 },
  { label: 'Pilih tanggal', value: 1 },
];

const isSelected = (selectedCycles, item) => {
  let found = false;

  if (selectedCycles.includes(item.id)) {
    found = true;
  }

  return found;
};

const getSelectedCycle = (paymentCycles, selectedCycles) => paymentCycles.find((e) => e.id === selectedCycles[0]);

const DropDown = ({
  isCollapsed,
  title,
  cycles = [],
  description,
  hasCollapsible = false,
  onPress,
  selectedCycles,
  onSelect,
  selectedRadio,
}) => {
  const renderItem = useCallback(
    ({ item }) => {
      return (
        <Pressable
          onPress={() => onSelect(item)}
          android_ripple={{ color: colors.lightGrey }}
          style={[dropDownStyle.item, isSelected(selectedCycles, item) ? { backgroundColor: '#FFDBCF' } : null]}
        >
          <MyText customStyle={[isSelected(selectedCycles, item) ? { color: colors.orange } : null]}>
            {listDateFormer(moment(item.startDate))} - {listDateFormer(moment(item.endDate))}{' '}
            {moment(item.endDate).year()}
          </MyText>
          {isSelected(selectedCycles, item) && <Close name="check" color={colors.orange} size={20} />}
        </Pressable>
      );
    },
    [cycles, selectedCycles],
  );

  return (
    <View>
      <MyText isRegular={false} customStyle={{ marginTop: 10 }}>
        {title}
      </MyText>
      <Pressable android_ripple={{ color: colors.lightGrey }} onPress={onPress} style={dropDownStyle.container}>
        <MyText>{selectedRadio > -1 ? '--' : description}</MyText>
        <Close name="caretdown" color={colors.grey} />
      </Pressable>
      {hasCollapsible && (
        <View style={styles.collapsibleContainer}>
          <Collapsible style={dropDownStyle.collapsible} collapsed={isCollapsed}>
            <FlatList data={cycles} keyExtractor={(item, index) => index.toString()} renderItem={renderItem} />
          </Collapsible>
        </View>
      )}
    </View>
  );
};

const dropDownStyle = StyleSheet.create({
  collapsible: {
    maxHeight: (Dimensions.get('screen').height * 22) / 100,
    backgroundColor: colors.white,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  item: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 0.8,
    borderColor: colors.grey,
    marginTop: 5,
  },
});

const TransactionFilter = ({
  selectedRadio,
  setSelectedRadio,
  collapsed,
  closeModal,
  paymentCycles,
  setCollapsed,
  openDate,
  setModal,
  startDate,
  endDate,
  onReset,
  cycles,
  onSelect,
  reasonCount,
  isAllCategorySelected,
  onCancel,
}) => {
  return (
    <View style={styles.mainContainer}>
      <TouchableOpacity style={{ alignSelf: 'flex-end', marginVertical: 15 }} onPress={onCancel}>
        <Close name="close" size={20} color={colors.black} />
      </TouchableOpacity>
      <MyText isRegular={false}>Filter</MyText>
      <MyText customStyle={{ marginVertical: 10 }} isRegular={false}>
        Tanggal
      </MyText>
      <RadioForm formHorizontal={false} animation={true}>
        {/* To create radio buttons, loop through your array of options */}
        {radio_props.map((obj, i) => (
          <RadioButton labelHorizontal={true} style={{ alignItems: 'center' }} key={i}>
            {/*  You can set RadioButtonLabel before RadioButtonInput */}
            <RadioButtonInput
              obj={obj}
              index={i}
              isSelected={selectedRadio === i}
              onPress={setSelectedRadio}
              borderWidth={1}
              buttonInnerColor={'#e74c3c'}
              buttonOuterColor={selectedRadio === i ? colors.orange : colors.grey}
              buttonSize={8}
              buttonOuterSize={15}
              buttonStyle={{}}
            />
            <RadioButtonLabel
              obj={obj}
              index={i}
              labelHorizontal={true}
              onPress={setSelectedRadio}
              labelStyle={{ fontFamily: Font.Nunito_Regular, color: colors.black }}
              labelWrapStyle={{}}
            />
          </RadioButton>
        ))}
      </RadioForm>

      <View style={{ flexDirection: 'row', alignItems: 'center', marginStart: 25, marginBottom: 10 }}>
        <TouchableOpacity
          onPress={() => {
            setSelectedRadio(1);
            openDate('START');
          }}
        >
          <MyText h2>Mulai Dari</MyText>
          <View style={{ marginTop: 3, borderBottomWidth: 0.5, borderColor: colors.grey }}>
            <MyText>{startDate}</MyText>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelectedRadio(1);
            openDate('END');
          }}
          style={{ marginStart: 20, borderBottomWidth: 0.5, borderColor: colors.grey }}
        >
          <MyText h2>Samapai</MyText>
          <View style={{ marginTop: 3 }}>
            <MyText>{endDate}</MyText>
          </View>
        </TouchableOpacity>
      </View>
      <DropDown
        selectedRadio={selectedRadio}
        onSelect={onSelect}
        selectedCycles={cycles}
        cycles={paymentCycles}
        title={'Siklus'}
        description={
          cycles?.length > 1
            ? 'Multiple Cycles'
            : cycles?.length === 0
            ? '--'
            : `${listDateFormer(moment(getSelectedCycle(paymentCycles, cycles)?.startDate))} - ${listDateFormer(
                moment(getSelectedCycle(paymentCycles, cycles)?.endDate),
              )} ${moment(getSelectedCycle(paymentCycles, cycles)?.endDate).year()}`
        }
        hasCollapsible
        onPress={() => setCollapsed(!collapsed)}
        isCollapsed={collapsed}
      />

      <DropDown
        onPress={() => setModal({ type: 'CATEGORY', visible: true })}
        title={'Kategori'}
        description={isAllCategorySelected ? 'Semua Kategori' : `${reasonCount} Kategori`}
        isCollapsed={collapsed}
      />
      <View style={{ flexDirection: 'row', marginVertical: 20, alignItems: 'center', justifyContent: 'space-between' }}>
        <RoundedButton title={'Reset'} onPress={onReset} isDark={false} container={{ marginEnd: 10 }} />
        <RoundedButton onPress={closeModal} title={'Terapkan'} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingBottom: 10,
    width: '100%',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    height: '100%',
  },
  collapsibleContainer: {
    backgroundColor: colors.white,
    elevation: 5,
    borderRadius: 3,
    overflow: 'hidden',
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    top: 80,
  },
});

export default TransactionFilter;
