import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import * as Sentry from '@sentry/browser';
import { autoLogout } from '@redux/action/Authentication';
import { store } from '@enhancedStore/store';
// import RNInsider from 'react-native-insider';
import { clearReferral } from '@redux/action/Referral';
import logoutCallBack from '@utils/AuthenticationUtils';
import { clearDynamicAccountReducer } from '@redux/action/DynamicAccounts';
import { logoutFromMoengage } from '@utils/engagementUtils';

// Set Default API Params
export const setDefaultParams = async () => {
  try {
    const phone = await AsyncStorage.getItem('@phoneNumber');
    return {
      baseURL: config.API_GAJITIM,
      headers: {
        'x-api-key': '030303',
        'Content-Type': 'application/json',
      },
    };
  } catch (error) {
    AsyncStorage.clear();
  }
};

export const axiosInstance = axios.create({
  baseURL: config.API_GAJITIM,
  headers: {
    'x-api-key': '030303',
  },
});

// MAIN API
const mainAPIGT = async (url, method, payload, header, useRefresh = null) => {
  try {
    //let customRes = await customAxios.post('/asdf')
    //let axiosInstance = axios.create()

    let token = await AsyncStorage.getItem('@token');
    // let refreshToken = await AsyncStorage.getItem('@refreshToken')
    // let phone = await AsyncStorage.getItem('@phoneNumber')

    let options = token
      ? {
          method: method,
          //  url: phone.charAt(0) == '1' ? `${config.API_LOAN_URL}/${url}` : `${config.API_URL}/${url}`,
          url: url,
          headers: {
            //  "x-api-key": phone.charAt(0) == '1' ? "888" : "87654321",
            //  "Content-Type": "application/json",
            ...axiosInstance.defaults.headers,
            Authorization: `Bearer ${token}`,
          },
        }
      : {
          method: method,
          //  url: phone.charAt(0) == '1' ? `${config.API_LOAN_URL}/${url}` : `${config.API_URL}/${url}`,
          url: url,
          headers: {
            ...axiosInstance.defaults.headers,
            //  "x-api-key": phone.charAt(0) == '1' ? "888" : "87654321",
            //  "Content-Type": "application/json",
          },
        };
    options = payload ? { ...options, data: payload } : { ...options };

    let response = await axiosInstance(options);
    return response;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
    //  if(e.response){
    //      if(e.response.status === '401'){
    //         //  await AsyncStorage.removeItem('@token')
    //          return e.response.status
    //      }
    //  return e.response
    //  }
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      (error?.response?.status == 401 && error?.response?.data?.error == 'UNAUTHORIZED_ACCESS_TOKEN_JWT') ||
      (error?.response?.status == 401 && error?.response?.data?.code == 'UNAUTHORIZED_ACCESS')
    ) {
      try {
        const refreshToken = await AsyncStorage.getItem('@refreshToken');
        let result = await axios.post(`${config.API_URL}/auth/refresh`, null, {
          headers: {
            'x-api-key': '87654321',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        if (result?.status == 401) {
          logoutFromMoengage();
          store.dispatch(autoLogout(logoutCallBack));
          store.dispatch(clearReferral());
          store.dispatch(clearDynamicAccountReducer());
          return Promise.reject(error);
        }

        let newToken = result?.data?.data?.accessToken;
        await AsyncStorage.setItem('@token', newToken);
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(error.config);
      } catch (error) {
        if (error?.response?.status == 401) {
          logoutFromMoengage();
          store.dispatch(autoLogout(logoutCallBack));
          store.dispatch(clearReferral());
          store.dispatch(clearDynamicAccountReducer());
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  },
);

export default mainAPIGT;
