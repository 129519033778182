import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { normalize } from '@style/helper';
import { colors } from '@style/colors';
import styles from './style';

const ProgressPath = ({ total, completed, filledColor, containerStyle }) => (
  <View style={[styles.wrapper, containerStyle]}>
    {[...Array(total).keys()].map((e, i) => (
      <View
        key={i}
        style={[
          styles.pathStyle,
          completed >= i + 1 && { backgroundColor: filledColor },
          total > 7 && { width: `${90 / total}%`, marginRight: normalize(4 - total * 0.03) },
        ]}
      />
    ))}
  </View>
);
//   { width: `${90 / total}%`, marginRight: normalize(3.5) },
ProgressPath.propTypes = {
  total: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  filledColor: PropTypes.string,
  containerStyle: PropTypes.any,
};
ProgressPath.defaultProps = {
  filledColor: colors.warning,
  containerStyle: {},
};
export default React.memo(ProgressPath);
