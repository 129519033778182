import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';

const styles = StyleSheet.create({
  containerStyle: { paddingBottom: normalizeHeight(30) },
  paginationContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: normalize(-2),
    width: normalize(30),
  },
  dotStyle: {
    backgroundColor: colors.carnationRed,
    height: 8,
    width: 8,
    borderRadius: 4,
  },
  inactiveStyle: {
    backgroundColor: colors.dimGrey,
    height: 8,
    width: 8,
    opacity: 1,
    borderRadius: 4,
  },
  labelContainer: {
    flexDirection: 'row',
    paddingHorizontal: normalize(20),
    justifyContent: 'space-between',
  },
  labelText: { color: colors.black, fontSize: normalize(15) },
  infoCircle: {
    height: normalize(22),
    width: normalize(22),
    borderRadius: normalize(22) / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondaryColor,
  },
  infoText: { color: colors.white, fontSize: normalize(15) },
});

export default styles;
