import React, { useEffect, useState } from 'react';
import { FlatList, Pressable, TextInput, View } from 'react-native';
import styles from './style';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import Divider from '@atoms/Divider';
import Search from '@expo/vector-icons/Ionicons';
import MainParentWrapper from '@components/MainParentWrapper';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_BANKS } from '@gqlQuery/BankAccount';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import PropTypes from 'prop-types';
import { useNavigation, useRoute } from '@react-navigation/native';
let timeout = null;
const BankSelectionList = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { source } = route.params;
  const [banks, setBanks] = useState([]);
  const [getAllBanks, { loading, data: listData, error }] = useLazyQuery(GET_ALL_BANKS, {
    onCompleted: (data) => {
      setBanks(data.gg_bank);
    },
    fetchPolicy: 'no-cache',
  });

  const searchBankByName = (char) => {
    const arr = [];
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      logEvent(AMPLITUDE_CONSTANTS.bankRegistration.bank_name_searched);
      listData.gg_bank.forEach((e) => {
        if (e.bankName.toLowerCase().includes(char.toLowerCase())) arr.push(e);
      });
      setBanks(arr);
    }, 500);
  };

  const logEvent = (event) => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration[event.name];
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: source,
    });
  };

  useEffect(() => {
    getAllBanks();
    return () => clearTimeout(timeout);
  }, []);

  const renderItem = ({ item }) => (
    <Pressable
      onPress={() => {
        logEvent(AMPLITUDE_CONSTANTS.bankRegistration.bank_name_chosen);
        navigation.navigate('BankCreateDetail', { selectedBank: JSON.stringify(item), source });
      }}
      style={styles.item}
      android_ripple={{ color: colors.greyShade }}
    >
      <MyText>{item.bankName}</MyText>
    </Pressable>
  );

  const keyExtractor = (item) => item.id.toString();
  return (
    <MainParentWrapper
      isError={error}
      loading={loading}
      onReload={() => getAllBanks()}
      containerStyle={{ paddingTop: 0, height: 0 }}
    >
      <View style={styles.blueBg}>
        <View style={styles.inputTextContainer}>
          <TextInput
            onFocus={() => logEvent(AMPLITUDE_CONSTANTS.bankRegistration.bank_name_clicked)}
            style={styles.inputSearch}
            onChangeText={searchBankByName}
            placeholder="Cari nama bank"
          />
          <Search name="search" color={colors.black} size={20} />
        </View>
      </View>
      <FlatList
        keyboardShouldPersistTaps={'always'}
        ItemSeparatorComponent={() => <Divider horizontal customStyle={styles.divider} />}
        style={styles.flatlist}
        data={banks}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </MainParentWrapper>
  );
};

BankSelectionList.defaultProps = {};

BankSelectionList.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default BankSelectionList;
