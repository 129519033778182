import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Text, View, TextInput, Dimensions, StyleSheet, TouchableOpacity, Pressable, FlatList } from 'react-native';
import MIcon from '@expo/vector-icons/MaterialIcons';
import { Slider } from '@miblanchard/react-native-slider';

import AmplitudeHelper from '@services/amplitude';
import {
  numberWithDots,
  convertDotsToNum,
  setRange,
  calculatePercentage,
  getAvailableBalance,
  getSliderInterval,
  sliderMaximumValue,
  sliderMinValue,
  sliderInputChange,
} from '@utils/UtilFunctions';

/* Styles */
import { normalize, normalizeHeight } from '@style/helper';

/* Redux */
import { batch, useDispatch, useSelector } from 'react-redux';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import AccountSelector from '@atoms/AccountSelector';
import LoadingButton from '@atoms/LoadingButton';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { store } from '@enhancedStore/store';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const TarikGaji = ({
  withdrawAmount,
  updateEditable,
  todayBalance,
  minBalance,
  updateAmount,
  maxSalary,
  handleTarik,
  firstWorkingDate,
  endWorkingDate,
  startWithdrawalDate,
  withdrawalLockType,
  numberOfLockPeriod,
  payDateWithdrawal,
  isModal,
  options,
  loading,
  source,
  eventSource,
  todayBalanceFloor,
  dynamicAccounts,
  salaryWithCappedAmount,
  maxWithdrawalAmount,
  workingDays,
  workedDays,
  usedBalance,
  onPressAccountSelector,
  selectedAccount,
  dynamicAccountDetails,
}) => {
  const inputRef = useRef();
  const [isEditable, setEditable] = useState(false);
  const [inputVal, setInputVal] = useState(withdrawAmount);
  const { redeemCode } = store.getState().Referral;
  const hasMoreDynamicAccounts =
    !!dynamicAccountDetails.sdAccount || !!dynamicAccountDetails.workAllowance || !!dynamicAccountDetails.uangKas;
  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        salaryWithCappedAmount,
        todayBalanceFloor,
        workingDays,
        workedDays,
        maxWithdrawalAmount,
        usedBalance,
      ),
    [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance],
  );

  useEffect(() => {
    if (isEditable) {
      inputRef.current.focus();
      setInputVal('');
    }
  }, [isEditable]);

  const updateInput = (x) => {
    setInputVal(numberWithDots(sliderInputChange(newAvailableBalance, minBalance, x)));
  };

  const validate = () => {
    const _val = convertDotsToNum(inputVal);
    if (isNaN(_val)) return false;

    let newMinBalance = newAvailableBalance == 0 ? 0 : newAvailableBalance < minBalance ? 0 : minBalance;
    if (_val < newMinBalance) {
      return false;
    } else if (_val > newAvailableBalance) {
      return false;
    } else if (_val === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!inputVal) {
      setInputVal(withdrawAmount);
    }
  }, [withdrawAmount]);

  const renderOption = ({ item }) => (
    <Pressable
      onPress={() => {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.AmountCardClicked.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.AmountCardClicked.attributes.source]: eventSource,
          [AMPLITUDE_CONSTANTS.Withdrawal.AmountCardClicked.attributes.ppercentage_amount]: calculatePercentage(
            item,
            todayBalance == 0 ? 0 : todayBalance < minBalance ? 0 : todayBalance,
          ),
          [AMPLITUDE_CONSTANTS.Withdrawal.AmountCardClicked.attributes.requested_amount]: item,
          [AMPLITUDE_CONSTANTS.Withdrawal.AmountCardClicked.attributes.referral_code]: redeemCode || null,
        });
        updateAmount(item);
        handleTarik();
      }}
      android_ripple={{ color: colors.lightGrey }}
    >
      <View
        style={{
          elevation: 5,
          borderRadius: 5,
          padding: normalizeHeight(10),
          margin: normalizeHeight(5),
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },

          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          // height: normalizeHeight(40),
          backgroundColor: colors.white,
          justifyContent: 'center',
          marginVertical: normalizeHeight(15),
        }}
      >
        <Text
          style={{
            fontSize: normalize(12),
            fontFamily: Font.Nunito_Bold,
            color: colors.orange,
            textAlign: 'center',
          }}
        >
          Rp{numberWithDots(item)}
        </Text>
      </View>
    </Pressable>
  );

  const renderAmountOption = () => (
    <FlatList
      data={options ?? []}
      renderItem={({ item }) => renderOption({ item })}
      // numColumns={3}
      keyExtractor={(item, index) => item.toString()}
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    />
  );

  const onSlidingComplete = (val) => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.withdrawSliderEvent.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.withdrawSliderEvent.attributes.requested_amount]: val.toString() ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.withdrawSliderEvent.attributes.source]: eventSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.withdrawSliderEvent.attributes.referral_code]: redeemCode || null,
    });
  };

  const onSliderValueChange = (val) => {
    if (isEditable) {
      setEditable(false);
    }

    updateInput(val);
  };

  // EWA Account
  return (
    <View style={{ width: '90%' }}>
      <View style={[{ flexDirection: 'row', justifyContent: 'space-between' }, isModal ? { marginBottom: 15 } : null]}>
        <Text
          style={[
            isModal
              ? {
                  fontSize: normalize(18),
                  color: colors.black,
                  fontFamily: Font.Nunito_Bold,
                }
              : styles.topText,
          ]}
        >
          Nominal Penarikan
        </Text>
        {hasMoreDynamicAccounts && !isModal && (
          <AccountSelector
            selectedAccount={selectedAccount}
            // disabled={dynamicAccountDetails.sdAccount.availableBalance === 0}
            onPress={onPressAccountSelector}
          />
        )}
      </View>
      <View accessibilityLabel="withdrawAmount" style={styles.withdrawAmount}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: Font.Nunito_Bold,
          }}
        >
          <Text style={[styles.withdrawText]}>Rp </Text>
          <TextInput
            ref={inputRef}
            editable={isEditable}
            defaultValue={withdrawAmount}
            style={styles.inputAmount}
            value={inputVal}
            onChangeText={(text) => setInputVal(text)}
            keyboardType="numeric"
          />
        </View>
        <TouchableOpacity
          accessibilityLabel="editButton"
          onPress={() => {
            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.manualWithdrawal.name, {
              [AMPLITUDE_CONSTANTS.Withdrawal.manualWithdrawal.attributes.requested_amount]: inputVal ?? null,
              [AMPLITUDE_CONSTANTS.Withdrawal.manualWithdrawal.attributes.source]: eventSource,
              [AMPLITUDE_CONSTANTS.Withdrawal.manualWithdrawal.attributes.referral_code]: redeemCode || null,
            });
            setEditable(!isEditable);
            // inputRef.current.focus();
          }}
        >
          <MIcon name="edit" style={styles.iconButton} />
        </TouchableOpacity>
      </View>
      {/* Withdraw Slider */}
      <View accessibilityLabel="withdrawAmount" style={{ width: '100%' }}>
        <Slider
          style={{ width: '100%', color: '#ffd8cc' }}
          trackStyle={styles.sliderTrack}
          thumbStyle={styles.sliderThumb}
          minimumTrackTintColor="#f56b57"
          maximumValue={sliderMaximumValue(newAvailableBalance, minBalance)}
          minimumValue={sliderMinValue(newAvailableBalance, minBalance)}
          onValueChange={onSliderValueChange}
          onSlidingComplete={onSlidingComplete}
          step={getSliderInterval(newAvailableBalance)}
          value={convertDotsToNum(inputVal) > maxSalary ? maxSalary : convertDotsToNum(inputVal) || 50000}
        />
      </View>

      {/* Saldo Tersedia */}
      <View style={styles.tersediaContainer}>
        <Text style={styles.saldoTersedia}>
          {/* Rp{minBalance == 0 ? 0 : numberWithDots(minBalance)} */}
          Rp{newAvailableBalance < minBalance ? 0 : numberWithDots(minBalance)}
        </Text>
        <Text style={styles.saldoTersedia}>Rp{newAvailableBalance == 0 ? 0 : numberWithDots(newAvailableBalance)}</Text>
      </View>
      {isModal ? renderAmountOption() : null}
      {/* Confirm Button */}
      <LoadingButton
        container={{
          paddingVertical: 10,
          width: '100%',
          justifyContent: 'center',
          borderRadius: 30,
        }}
        textStyle={{
          fontSize: normalize(16),
        }}
        disabled={!validate()}
        buttonColor={colors.orange}
        disabledStyle={styles.disabledButton}
        text={'Tarik Gaji Sekarang'}
        textColor={colors.white}
        textTransform="none"
        onPress={() => {
          updateAmount(inputVal);
          handleTarik();
        }}
        loading={loading}
      />

      {/* Transaction Range */}
      <Text style={styles.transactionRange}>
        {setRange(
          firstWorkingDate,
          endWorkingDate,
          startWithdrawalDate,
          withdrawalLockType,
          numberOfLockPeriod,
          payDateWithdrawal,
        )}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  disabledButton: {
    opacity: 0.5,
  },
  chooseAmount: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(20),
    fontFamily: Font.Nunito_Bold,
  },
  withdrawAmount: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 5,
    marginBottom: 10,
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
  },
  withdrawText: {
    fontSize: normalize(20),
    fontFamily: Font.Nunito_Bold,
  },
  inputAmount: {
    width: normalize(200),
    fontSize: normalize(20),
    fontFamily: Font.Nunito_Bold,
    color: colors.black,
    fontWeight: 'normal',
  },
  sliderTrack: {
    height: 8,
    borderRadius: 50,
    backgroundColor: '#ffd8cc',
  },
  sliderThumb: {
    width: normalize(20),
    height: normalizeHeight(19),
    borderRadius: 50,
    backgroundColor: '#f56b57',
  },
  saldoTersedia: {
    color: colors.grey,
    fontFamily: Font.Nunito_Regular,
    fontSize: 10,
    lineHeight: 14,
  },
  tersediaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  amountTersedia: {
    maxWidth: normalize(100),
    textAlign: 'right',
  },
  transactionRange: {
    fontFamily: Font.Nunito_Regular,
    textAlign: 'center',
    color: colors.grey,
    fontSize: normalize(10),
    lineHeight: normalize(12),
    width: '100%',
    alignSelf: 'center',
    marginTop: normalizeHeight(5),
  },
  iconButton: {
    fontSize: normalize(18),
    color: colors.grey,
    alignSelf: 'center',
    marginRight: 5,
  },
  topText: {
    flex: 1,
    fontFamily: Font.Nunito_Regular,
    fontSize: 12,
    lineHeight: 14,
    color: colors.grey,
  },
  lgButtonText: {
    textTransform: 'capitalize',
    fontSize: normalize(16),
    paddingVertical: 3,
    fontFamily: Font.Nunito_Bold,
  },
});

export default TarikGaji;
