import React from 'react';
import MyText from '@atoms/MyText';
import { View } from 'react-native';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import PropTypes from 'prop-types';
import { getExpirDateText } from '@utils/UtilFunctions';
import { GAMIFICATION_DE_PALLETE } from '@utils/Constants';
import ProgressPath from '../ProgressPath';
import styles from './style';

function shouldShowNormalCard(isComingSoon, title, noOfCompleted, totalMissions, displayOrder, expiryDate) {
  return (
    !isComingSoon && (
      <>
        <View style={styles.layerStyle}>
          <MyText isRegular={false} customStyle={styles.tierTitle}>
            {title}
          </MyText>
          <MyText isRegular={false} customStyle={styles.textStyle}>
            {`Selesaikan ${noOfCompleted} dari ${totalMissions} misi tersisa untuk \ndapatkan hadiah`}
          </MyText>
          <ProgressPath
            containerStyle={styles.progressContainer}
            total={totalMissions}
            completed={noOfCompleted}
            filledColor={GAMIFICATION_DE_PALLETE[displayOrder].mainTier.progressFiller}
          />
          <MyText isRegular={true} customStyle={styles.expiryText}>
            {`Berlaku sampai ${getExpirDateText(expiryDate)}`}
          </MyText>
        </View>
        <View style={styles.tierIconStyle}>{GAMIFICATION_DE_PALLETE[displayOrder].mainTier.icon}</View>
      </>
    )
  );
}

function shouldShowComingSoon(isComingSoon) {
  return (
    isComingSoon && (
      <View style={styles.comingLayerStyle}>
        <View style={styles.leftContainer}>
          <View style={styles.tierComingSoon}>{SVG_ICONS.gamification.tierIcon.comingSoonTier}</View>
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.comingLockContainer}>
            {SVG_ICONS.gamification.tierIcon.comingSoonLock}
            <MyText isRegular={false} customStyle={styles.comingSoonText}>
              Segera Hadir
            </MyText>
          </View>
          <MyText isRegular={false} customStyle={styles.comingSoonDesc}>
            Nantikan lebih banyak misi dan hadiah di level selanjutnya!
          </MyText>
        </View>
      </View>
    )
  );
}

function showAbsoluteStyleCircle(displayOrder, customStyle) {
  return (
    <View
      style={[
        styles.circle,
        customStyle,
        { backgroundColor: GAMIFICATION_DE_PALLETE[displayOrder].mainTier.circleColor },
      ]}
    />
  );
}

const TierCard = ({ title, totalMissions, noOfCompleted, expiryDate, isComingSoon, srNo }) => {
  let displayOrder = srNo;
  if (isComingSoon) {
    displayOrder = 'soon';
  }
  return (
    <View style={[styles.container, { backgroundColor: GAMIFICATION_DE_PALLETE[displayOrder].mainTier.backColor }]}>
      {shouldShowNormalCard(isComingSoon, title, noOfCompleted, totalMissions, displayOrder, expiryDate)}
      {shouldShowComingSoon(isComingSoon)}
      {showAbsoluteStyleCircle(displayOrder, styles.topCircle)}
      {showAbsoluteStyleCircle(displayOrder, styles.bottomCircle)}
      {showAbsoluteStyleCircle(displayOrder, styles.rightTopCircle)}
      {showAbsoluteStyleCircle(displayOrder, styles.rightBottomCircle)}
    </View>
  );
};

TierCard.propTypes = {
  title: PropTypes.string,
  totalMissions: PropTypes.number,
  noOfCompleted: PropTypes.number,
  expiryDate: PropTypes.string,
  isComingSoon: PropTypes.bool,
  srNo: PropTypes.number.isRequired,
};
TierCard.defaultProps = {
  title: null,
  isComingSoon: true,
  totalMissions: 0,
  noOfCompleted: 0,
  expiryDate: null,
};
export default TierCard;
