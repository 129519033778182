import mainAPI, { gqClient } from './api';
import { TRANSACTION_LIST_DA, TRANSACTION_SUMMARY_DA } from '@gqlQuery/QueryTransactionList';
import { setConfirmed, respError, setGlobalLoading } from '@redux/action/Common';
import { batch } from 'react-redux';
import AmplitudeHelper from './amplitude';
import {
  changeTransaction,
  setAccrual,
  setBalances,
  setReasonsTransaction,
  setTransactionList,
} from '@redux/action/TransactionDetails';
import { setCoinBalance } from '@redux/action/Coins';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import {
  formatUnderScoreLowerCase,
  refferalDencryption,
  setBillPaymentReason,
  findAccountByName,
  subtractFromWorkAllowance,
  mapDataSameAsEWA,
} from '@utils/UtilFunctions';
import { clearReferral, setIsTransacted, setIsValidReferral, setRedeemCode } from '@redux/action/Referral';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { store } from '@enhancedStore/store';
import {
  setCurrentTransactionPageSelectedAccount,
  setDataAccounts,
  setCurrentHomePageSelectedAccount,
} from '@redux/action/DynamicAccounts';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';

async function getSummaryBasedOnAccountSelected(currentSelectedDynamicAccountId, employeeId, filter) {
  let response = {};
  if (currentSelectedDynamicAccountId === null) {
    response = await mainAPI(`/employees/${employeeId}/transactions-summary`, 'post', filter);
  } else {
    response = await gqClient.query({
      query: TRANSACTION_SUMMARY_DA,
      variables: {
        paymentCycleId: filter.cycles[0].id,
        accountNo: currentSelectedDynamicAccountId,
      },
      fetchPolicy: 'no-cache',
    });
    response = {
      data: {
        data: {
          balance: response.data.balance[0].availableBalance,
          totalIncome: response.data.totalIncome.aggregate.sum.amount,
          totalExpense: response.data.totalExpenses.aggregate.sum.amount,
        },
      },
    };
  }
  return response;
}

async function getDataDependingOnAccountType(
  currentSelectedDynamicAccountId,
  transactionHistoryApiUrl,
  filter,
  offSet,
  setOffSet,
) {
  let response = {};
  if (currentSelectedDynamicAccountId == null) {
    response = await mainAPI(transactionHistoryApiUrl, 'post', filter);
  } else {
    response = await gqClient.query({
      query: TRANSACTION_LIST_DA,
      variables: {
        paymentCycleId: filter.cycles[0].id,
        accountNum: currentSelectedDynamicAccountId,
        limit: filter.limit,
        offset: offSet + 1,
      },
      fetchPolicy: 'no-cache',
    });
    response = {
      data: { data: { rows: mapDataSameAsEWA(response.data.account_transaction) } },
    };
    if (response.data.data.rows.length > 0) {
      setOffSet(offSet + filter.limit);
    }
  }
  return response;
}

function logAmplitudeBeforeExecution(data, selectedItem) {
  const e = AMPLITUDE_CONSTANTS.BillPayment.verifyingBillPaymentTransaction;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.account_number]: data.accountNumber,
    [e.attributes.source]: 'other_product_page',
    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
    [e.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
    [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
    [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
    [e.attributes.referral_code]: store.getState().Referral.redeemCode,
  });
}

function logAmplitudeForSuccess(data, selectedItem) {
  const e2 = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionSuccess;
  AmplitudeHelper.logEvent(e2.name, {
    [e2.attributes.account_number]: data.accountNumber,
    [e2.attributes.source]: 'other_product_page',
    [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
    [e2.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
    [e2.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
    [e2.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
    [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
  });
}

function transactionException(data, selectedItem, error) {
  const e3 = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionFailed;
  AmplitudeHelper.logEvent(e3.name, {
    [e3.attributes.account_number]: data.accountNumber,
    [e3.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
    [e3.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
    [e3.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
    [e3.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
    [e3.attributes.failure_message]: error?.message ? error?.message : error,
    [e3.attributes.source]: 'other_product_page',
    [e3.attributes.referral_code]: store.getState().Referral.redeemCode,
  });
}

export const requestTransaction = async (type, data, currentHomePageSelectedAccount) => {
  try {
    let e = AMPLITUDE_CONSTANTS.BillPayment.verifyingBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: data.accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
      [e.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
    });

    let resp = await mainAPI(`transaction/request/${type}`, 'post', data);

    // AmplitudeHelper.logEvent("verifyTransactionSuccess", {
    //   data: data,
    //   type: type,
    // });

    let e2 = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionSuccess;
    AmplitudeHelper.logEvent(e2.name, {
      [e2.attributes.account_number]: data.accountNumber,
      [e2.attributes.source]: 'other_product_page',
      [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
      [e2.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
      [e2.attributes.reason]: 'tagihan_dan_utilitas',
      [e2.attributes.reason_id]: 2,
      [e2.attributes.dynamic_account_name]: currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    return resp;
    // return await mainAPI(`transaction/request/${type}`, 'post', data)
  } catch (error) {
    // AmplitudeHelper.logEvent("verifyTransactionFailed", {
    //   data: data,
    //   type: type,
    // });

    let e3 = AMPLITUDE_CONSTANTS.BillPayment.verifyTransactionFailed;
    AmplitudeHelper.logEvent(e3.name, {
      [e3.attributes.account_number]: data.accountNumber,
      [e3.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.transCategoryId),
      [e3.attributes.product_code]: formatUnderScoreLowerCase(data?.productCode),
      [e3.attributes.reason]: 'tagihan_dan_utilitas',
      [e3.attributes.reason_id]: 2,
      [e3.attributes.failure_message]: error?.message ? error?.message : error,
      [e3.attributes.source]: 'other_product_page',
    });

    throw error;
  }
};

export const getProductListByCategory = async (id, accountNumber) => {
  try {
    const response = await mainAPI('bill-payment/products', 'get', undefined, {
      categoryId: id,
      accountNumber,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPreRegisteredAccount = async (categoryId) => {
  try {
    const response = await mainAPI(`bill-payment/accounts?transactionCategoryId=${categoryId}`, 'get');

    return response;
  } catch (error) {
    throw error;
  }
};

export const preCheckBp = async (selectedItem, accountNumber, referralCode) => {
  try {
    const data = {
      id: selectedItem.categoryId,
      transCategoryId: selectedItem.categoryPrefix,
      accountNumber,
      productCode: selectedItem.code,
      referralCode: refferalDencryption(referralCode),
    };
    const resp = await mainAPI('transaction/request/bill-payment/precheck', 'post', data);
    return resp.data;
  } catch (error) {
    throw error;
  }
};

export const requestBillPaymentTransaction = async (selectedItem, accountNumber, accountTypeName, dynamicAccounts) => {
  const employeeAccountNumber = dynamicAccounts.filter((e) => e.accountTypeName === accountTypeName)[0]?.id ?? null;
  const data = {
    id: selectedItem.categoryId,
    transCategoryId: selectedItem.categoryPrefix,
    accountNumber,
    productCode: selectedItem.code,
    employeeAccountNumber,
  };
  let response = null;
  try {
    logAmplitudeBeforeExecution(data, selectedItem);
    //[CORE-1374]
    //check if employeeAccountNumber !== '' || employeeAccountNumber !== null then transaction is through a dynamic accout
    //in this case, change the API call to transaction/details/bills-payment and pre-check = true
    //otherwise transaction is through an EWA account, flow remains same
    if (employeeAccountNumber !== '' && employeeAccountNumber !== null) {
      response = await mainAPI(
        `transaction/details/bills-payment?employeeAccountNumber=${employeeAccountNumber}&transCategoryId=${data.transCategoryId}&accountNumber=${accountNumber}&productCode=${data.productCode}`,
        'get',
        data,
        null,
        null,
        null,
        config.NEW_GG_URL,
      );
    } else {
      response = await mainAPI(`transaction/request/bill-payment`, 'post', data);
    }

    logAmplitudeForSuccess(data, selectedItem);
    return response;
  } catch (error) {
    transactionException(data, selectedItem, error);
    throw error;
  }
};
export const confirmBpTransaction = async (transactionRequestId, biller, redeemCode, selectedAccount, extraPayload) => {
  try {
    let data = {
      transactionRequestId,
      reason: setBillPaymentReason(biller).reason,
      reasonId: setBillPaymentReason(biller).id,
      description: '',
    };

    if (extraPayload) {
      data = { ...data, ...extraPayload };
    }

    if (redeemCode.length > 0) data.referralCode = refferalDencryption(redeemCode);

    let response = {};
    if (selectedAccount === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT) {
      response = await mainAPI('transaction/confirmation', 'post', data);
    } else {
      response = await mainAPI('transaction/bills-payment', 'post', data, null, null, null, config.NEW_GG_URL);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmTransaction = (actualAmount) => async (dispatch, getState) => {
  const redeemCode = getState().Referral.redeemCode;
  const { referralPrecheckFail } = getState().Withdrawal;
  const state = getState().Transactions;
  const useCoin = getState().Coins;
  const common = getState().Common;
  const { currentHomePageSelectedAccount, dataAccounts } = getState().DynamicAccounts;
  const selectedProduct = getState().BillPayments.selectedProduct;
  const transCategoryId = getState().Transactions.transCategoryId;
  try {
    if (state?.inquiry?.id) {
      // don't request here
    } else {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.transaction_category_id]: transCategoryId,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.requested_amount]: state.requestAmount,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.reason]: state.selectedReason ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.reason_id]: state.selectedReasonId ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.fee_requested]: state.feeAmount,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.payment_method]: useCoin?.useCoin
          ? 'poin'
          : 'accrued_salary',
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.source]: common.eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.transactionRequuested.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });
    }

    let data = {
      transactionRequestId: state.transactionId,
      reason: state.selectedReason,
      reasonId: state.selectedReasonId,
      description: state.description,
    };

    if (redeemCode.length > 0 && !referralPrecheckFail) data.referralCode = refferalDencryption(redeemCode);

    dispatch(setGlobalLoading(true));
    let resp = {};
    if (currentHomePageSelectedAccount.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT) {
      data.transCategoryId = 'WD-01';
      data.amount = actualAmount;
      data.employeeAccountNumber = currentHomePageSelectedAccount.id;
      resp = await mainAPI('transaction/withdraw', 'post', data, null, null, null, config.NEW_GG_URL);
    } else {
      resp = await mainAPI('transaction/confirmation', 'post', data);
    }

    // let resp = {status: 400, response: {data: {message: "Error"}}}
    // let resp = {status: 200}
    dispatch(clearReferral());
    dispatch(setIsTransacted(true));
    AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));

    if (resp.status == 201 || resp.status == 200) {
      // AmplitudeHelper.logEvent("transactionSuccess", {
      //   transactionRequestId: state.transactionId,
      //   reason: state.selectedReason,
      //   reasonId: state.selectedReasonId,
      //   description: state.description,
      // });
      dispatch(setCurrentTransactionPageSelectedAccount(currentHomePageSelectedAccount));
      if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) {
        // Clearing SD account amount
        const newDataAccounts = dataAccounts.slice();
        const findSdAccount = newDataAccounts.findIndex(
          (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
        );
        if (findSdAccount !== -1) {
          newDataAccounts[findSdAccount] = {
            ...newDataAccounts[findSdAccount],
            amount: 'Rp0',
          };
          const findSalaryAccount = findAccountByName(newDataAccounts, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
          if (findSalaryAccount) {
            dispatch(setCurrentHomePageSelectedAccount(findSalaryAccount));
          }
          dispatch(setDataAccounts(newDataAccounts));
          AsyncStorage.setItem('@sdBalanceCleared', JSON.stringify(true));
        }
      } else if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) {
        const newData = subtractFromWorkAllowance(dataAccounts, actualAmount);
        dispatch(setDataAccounts(newData));
        const findSalaryAccount = findAccountByName(newData, DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
        if (findSalaryAccount) {
          dispatch(setCurrentHomePageSelectedAccount(findSalaryAccount));
        }
      }

      if (state?.inquiry?.id) {
        let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
          [e.attributes.product_code]: formatUnderScoreLowerCase(state?.inquiry?.productCode),
          [e.attributes.product_price]: state?.inquiry?.requestAmount,
          [e.attributes.reason]: 'tagihan_dan_utilitas',
          [e.attributes.reason_id]: 2,
          [e.attributes.payment_method]: useCoin?.useCoin ? 'poin' : 'accrued_salary',
          [e.attributes.fee_charged]: state?.inquiry?.fee,
          [e.attributes.source]: 'other_product_page',
        });
      } else {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.transaction_category_id]: transCategoryId,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.requested_amount]: state.requestAmount,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.reason]: state.selectedReason ?? null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.reason_id]: state.selectedReasonId ?? null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.fee_requested]: state.feeAmount,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.payment_method]: useCoin?.useCoin
            ? 'poin'
            : 'accrued_salary',
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.source]: common.eventSource,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.referral_code]: redeemCode || null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.dynamic_account_name]:
            currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
        });
      }

      dispatch(setConfirmed(true));
    } else {
      dispatch(
        respError(
          resp.response.data.validations
            ? resp.response.data.validations[0].message
            : resp.response.data.message
            ? resp.response.data.message
            : 'Server tidak merespon',
        ),
      );
      dispatch(setConfirmed(false));

      if (state?.inquiry?.id) {
        let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
          [e.attributes.product_code]: state?.inquiry?.productCode,
          [e.attributes.product_price]: state?.inquiry?.requestAmount,
          [e.attributes.reason]: 'tagihan_dan_utilitas',
          [e.attributes.reason_id]: 2,
          [e.attributes.payment_method]: useCoin?.useCoin ? 'poin' : 'accrued_salary',
          [e.attributes.fee_charged]: state?.inquiry?.fee,
          [e.attributes.source]: 'other_product_page',
        });
      } else {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.transaction_category_id]: transCategoryId,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.requested_amount]: state.requestAmount,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.reason]: state.selectedReason ?? null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.reason_id]: state.selectedReasonId ?? null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.fee_requested]: state.feeAmount,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.payment_method]: useCoin
            ? 'poin'
            : 'accrued_salary',
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.source]: common.eventSource,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.referral_code]: redeemCode || null,
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionSuccess.attributes.dynamic_account_name]:
            currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
          [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.failure_message]: resp.response.data.validations
            ? formatUnderScoreLowerCase(resp.response.data.validations[0].message)
            : formatUnderScoreLowerCase(resp.response.data.message)
            ? formatUnderScoreLowerCase(resp.response.data.message)
            : formatUnderScoreLowerCase('Server tidak merespon'),
        });
      }
    }
    dispatch(setGlobalLoading(false));
  } catch (error) {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.transaction_category_id]: transCategoryId,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.requested_amount]: state.requestAmount,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.reason]: state.selectedReason ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.reason_id]: state.selectedReasonId ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.fee_requested]: state.feeAmount,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.payment_method]: useCoin
        ? 'poin'
        : 'accrued_salary',
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.source]: common.eventSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.transactionFailure.attributes.failure_message]: formatUnderScoreLowerCase(
        error?.response?.data?.message || 'Terjadi gangguan silahkan coba lagi',
      ),
    });

    batch(() => {
      dispatch(setGlobalLoading(false));
      if (error?.response?.data?.error === 'REFERRAL_CODE_NOT_APPLICABLE') {
        dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        dispatch(setIsTransacted(true));
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'not first transaction',
        });
      } else if (error?.response?.data?.error === 'INVALID_REFERRAL_CODE') {
        dispatch(setIsTransacted(false));
        dispatch(setIsValidReferral(false, false));
        dispatch(setRedeemCode(''));
        dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'invalid code',
        });
      } else if (getState().Referral.isValidCode) {
        const isTransacted = getState().Referral.isTransacted;
        if (isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        else dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'system error',
        });
      } else {
        dispatch(
          respError(
            error.response.data?.message
              ? error.response.data?.message
              : 'Sorey, ada yang tidak beres. Silakan hubungi tim dukungan.',
          ),
        );
      }
    });
    throw error;
  }
};

export const redeemPoins = (amount, transactionCategoryIdPrefix, productCode, accountNumber) => {
  return async (dispatch, getState) => {
    const { coinBalance } = getState().Coins;
    const { employeeId, employerId, phoneNumber, userId } = getState().Authentication;
    try {
      const data = {
        amount: amount * -1,
        userId: userId,
        employerId: employerId,
        transactionCategoryId: transactionCategoryIdPrefix,
        productCode,
        accountNumber,
      };
      const response = await mainAPI(`points/redeem/${employeeId}`, 'post', data);
      if (coinBalance - amount >= 0) {
        dispatch(setCoinBalance(coinBalance - amount));
      }
      return response;
    } catch (error) {
      let e = AMPLITUDE_CONSTANTS.Poin.PoinPaymentFailed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
        [e.attributes.failure_message]: error?.response?.status ?? 'network_error',
        [e.attributes.point_balance]: coinBalance,
        [e.attributes.point_used]: amount,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(transactionCategoryIdPrefix),
        [e.attributes.employee_id]: employeeId,
        [e.attributes.referral_code]: getState().Referral.redeemCode,
      });
      throw error;
    }
  };
};

export const confirmCoinTransaction = (amount, userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setGlobalLoading(true));
      const billPayments = getState().BillPayments;
      const { userAccountNumber } = getState().Transactions;
      const { coinBalance } = getState().Coins;

      const { employeeId, employerId, phoneNumber } = getState().Authentication;
      const data = {
        amount: amount * -1,
        userId: userId,
        employerId: employerId,
        transactionCategoryId: billPayments.selectedProduct.categoryPrefix,
        productCode: billPayments.selectedProduct.code,
        accountNumber: userAccountNumber.length === 0 ? phoneNumber : userAccountNumber,
      };
      const response = await mainAPI(`points/redeem/${employeeId}`, 'post', data);
      if (coinBalance - amount >= 0) {
        dispatch(setCoinBalance(coinBalance - amount));
      }
      dispatch(setConfirmed(true));
      dispatch(setGlobalLoading(false));
    } catch (error) {
      dispatch(setGlobalLoading(false));
      throw error;
    }
  };
};

export const getTransactionHistory = (limit, page, isFetchingMore, fetchWithoutReasons, offSet, setOffSet) => {
  return async (dispatch, getState) => {
    try {
      const employeeId = getState().Authentication.employeeId;
      const filterObj = getState().TransactionDetails.filter;
      const reasons = getState().TransactionDetails.reasons;
      const currentTransactionPageSelectedAccount = getState().DynamicAccounts.currentTransactionPageSelectedAccount;
      const reasonIds = [];
      if (!fetchWithoutReasons) {
        reasons.income.forEach((e) => {
          if (e.isChecked) {
            reasonIds.push(parseInt(e.id));
          }
        });
        reasons.expense.forEach((e) => {
          if (e.isChecked) {
            reasonIds.push(parseInt(e.id));
          }
        });
      }

      let filter = {
        ...filterObj,
        page,
        limit,
        reasonIds,
      };
      if (reasonIds.length === 0) {
        delete filter.reasonIds;
      }

      let transactionHistoryApiUrl = `/employees/${employeeId}/transactions-history`;

      let currentSelectedDynamicAccountId = currentTransactionPageSelectedAccount?.id ?? null;
      transactionHistoryApiUrl = `employees/${employeeId}/accounts/${currentSelectedDynamicAccountId}/transactions-history`;

      const response = await getDataDependingOnAccountType(
        currentSelectedDynamicAccountId,
        transactionHistoryApiUrl,
        filter,
        offSet,
        setOffSet,
      );
      let transactionList = getState().TransactionDetails.transactionList.slice();
      if (isFetchingMore) {
        dispatch(setTransactionList(transactionList.concat(response.data?.data?.rows ?? [])));
      } else {
        dispatch(setTransactionList(response.data?.data?.rows ?? []));
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  };
};

async function getSummaryOfTransactions(getState, fetchWithoutReasons, dispatch) {
  const filterObj = getState().TransactionDetails.filter;
  const reasons = getState().TransactionDetails.reasons;
  const currentTransactionPageSelectedAccount = getState().DynamicAccounts.currentTransactionPageSelectedAccount;
  let currentSelectedDynamicAccountId = currentTransactionPageSelectedAccount?.id ?? null;
  const reasonIds = [];
  if (!fetchWithoutReasons) {
    reasons.income.forEach((e) => {
      if (e.isChecked) {
        reasonIds.push(parseInt(e.id));
      }
    });
    reasons.expense.forEach((e) => {
      if (e.isChecked) {
        reasonIds.push(parseInt(e.id));
      }
    });
  }

  const filter = {
    ...filterObj,
    reasonIds,
  };
  if (reasonIds.length === 0) {
    delete filter.reasonIds;
  }

  if (currentTransactionPageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) {
    filter.employeeAccountNumber = currentTransactionPageSelectedAccount.id;
  }

  const employeeId = getState().Authentication.employeeId;

  const response = await getSummaryBasedOnAccountSelected(currentSelectedDynamicAccountId, employeeId, filter);

  batch(() => {
    dispatch(
      setBalances(
        Math.floor(response.data?.data?.balance) ?? 0,
        Math.round(response.data?.data?.totalIncome) ?? 0,
        Math.round(response.data?.data?.totalExpense) ?? 0,
      ),
    );
    dispatch(setAccrual(response.data?.data?.accruedSalary ?? 0));
  });
  return response;
}

export const getTransactionSummary = (fetchWithoutReasons) => {
  return async (dispatch, getState) => {
    try {
      const response = await getSummaryOfTransactions(getState, fetchWithoutReasons, dispatch);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
};

export const setWorkAllowanceImage = async (fileUri, transactionId, cancelSignal) => {
  try {
    const formData = new FormData();
    formData.append('imageData', fileUri);
    formData.append('transactionId', transactionId);
    const response = await mainAPI(
      '/file',
      'post',
      formData,
      undefined,
      cancelSignal,
      'multipart/form-data',
      config.NEW_GG_URL,
      ``,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkAllowanceImage = async (transactionId) => {
  try {
    const response = await mainAPI(
      `/file`,
      'get',
      null,
      {
        transactionId,
      },
      undefined,
      undefined,
      config.NEW_GG_URL,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkAllowanceImage = async (documentId) => {
  try {
    const response = await mainAPI(
      `/file/${documentId}`,
      'delete',
      undefined,
      undefined,
      undefined,
      undefined,
      config.NEW_GG_URL,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReasons = (filterBy = 'INCOME', getSaving = true) => {
  return async (dispatch, getState) => {
    try {
      const response = await mainAPI(`transactions/reasons`, 'get', null, {
        filterBy,
      });

      if (filterBy === 'INCOME') {
        dispatch(setReasonsTransaction('income', response.data?.data.map((e) => ({ ...e, isChecked: true })) ?? []));
      } else {
        dispatch(setReasonsTransaction('expense', response.data?.data.map((e) => ({ ...e, isChecked: true })) ?? []));
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
};

export const editTransaction = (transactionId, description, transactionReasonId, transReason) => {
  return async (dispatch, getState) => {
    try {
      const employeeId = getState().Authentication.employeeId;
      const data = {
        description,
        transactionReasonId,
      };
      if (!description) {
        delete data.description;
      }
      const response = await mainAPI(`/employees/${employeeId}/transactions/${transactionId}`, 'patch', data);
      dispatch(changeTransaction(transactionReasonId, description, transactionId, transReason));
    } catch (error) {
      throw error;
    }
  };
};
