import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import MyModal from '@atoms/MyModal';
import Close from '@expo/vector-icons/AntDesign';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import styles from './style';

const Popup = ({ onCloseModal, visible }) => (
  <MyModal isVisible={visible} containerStyle={styles.containerStyle}>
    <View style={styles.outerContainer}>
      <TouchableOpacity style={styles.closeContainer} onPress={() => onCloseModal('close')}>
        <Close name="close" size={20} color={colors.black} />
      </TouchableOpacity>
      <MyText customStyle={styles.textStyleOne} isRegular={false}>
        Kamu Belum Bisa Konsultasi
      </MyText>
      <MyText customStyle={styles.textStyleTwo}>
        Mau konsultasi kesehatan dengan dokter umum dari Milvik? Yuk, beli voucher telekonsultasi sekarang!
      </MyText>
      <LoadingButton text="OK" onPress={() => onCloseModal('ok')} container={styles.laodingContainerStyle} />
    </View>
  </MyModal>
);

Popup.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Popup;
