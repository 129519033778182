import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { View, KeyboardAvoidingView } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import Divider from '@atoms/Divider';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';

import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import TextInputComponent from '@components/TextInputComponent';
import { removeNonNumeric, findAccountByName, numberWithDots } from '@utils/UtilFunctions';
import LoadingButton from '@atoms/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { respError } from '@redux/action/Common';

import LoadingModal from '@components/LoadingModal';
import RequestTransactionSheet from '@components/RequestTransactionSheet';

import { clearUserSelectedPhone } from '@redux/action/UserPhoneAction';

import { clearReferral, setIsTransacted, setIsValidReferral, setRedeemCode } from '@redux/action/Referral';
import { store } from '@enhancedStore/store';
import AmplitudeHelper from '@services/amplitude';
import { confirmBpTransaction, preCheckBp, requestBillPaymentTransaction } from '@services/TransactionApi';
import { BP_DYNAMIC_ACCOUNTS, TOOLTIP_CONTENT } from '@utils/Constants';
import {
  formatUnderScoreLowerCase,
  getAvailableBalance,
  getDynamicAccountListByAccount,
  getListOfDynamicAccounts,
  accountByRuleProductScreen,
} from '@utils/UtilFunctions';
import { useNavigation, useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { SELECTED_NOMINATION_ZAKAT } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import LabelTitle from '@atoms/Zakat/LabelTitle';
import DescriptionBanner from '@atoms/Zakat/DescriptionBanner';
import ZakatPoster from '@atoms/Zakat/ZakatPoster';
import SalaryNetworth from '@atoms/Zakat/SalaryNetWorth';
import DonationAdder from '@atoms/Zakat/DonationAdder';
import TotalZakat from '@atoms/Zakat/TotalZakat';
import PoweredBy from '@atoms/Zakat/PoweredBy';
import styles from './style';

const ZakatScreen = () => {
  const [toolTipVisible, setTooltipVisible] = useState(false);
  const { dynamicAccounts, dataAccounts, currentBpSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const [donorNames, setDonarNames] = useState('');
  const [email, setEmail] = useState('');

  const [noOfPeople, setNoOfPeople] = useState(1);

  const DONATION_BASE_AMOUNT = 50000;

  const ZAKAT_REASON = 'Zakat & Donasi';
  const ZAKAT_REASON_ID = 19;

  const dispatch = useDispatch();

  const totalDonation = useMemo(() => DONATION_BASE_AMOUNT * noOfPeople, [noOfPeople]);

  const totalSalary = useMemo(
    () => removeNonNumeric(findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT)?.amount ?? '0'),
    [dataAccounts],
  );

  const onDonation = () => {
    if (totalDonation > totalSalary) return dispatch(respError('Saldo Gaji belum mencukupi untuk transaksi ini'));
    referralPrecheck(SELECTED_NOMINATION_ZAKAT);
  };

  const onAddDonator = () => {
    let e = AMPLITUDE_CONSTANTS.Zakat.add_zakat_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.value]: totalDonation,
    });

    setNoOfPeople((prevState) => prevState + 1);
  };
  const onRemoveDonator = () => {
    let e = AMPLITUDE_CONSTANTS.Zakat.deduct_zakat_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.value]: totalDonation,
    });
    setNoOfPeople((prevState) => prevState - 1);
  };

  const navigation = useNavigation();
  const route = useRoute();
  const { item } = route.params;

  const { isValidCode, redeemCode, isTransacted } = useSelector((state) => state.Referral);

  const { workAllowanceBillPaymentList } = useSelector((state) => state.NewBillPayment);
  const [referralPrecheckFail, setReferralPrecheckFail] = useState(false);
  const currentSelectedAccountName = currentBpSelectedAccount.constantAccountName;

  const [accountNumber, setAccountNumber] = useState('');
  const [transactionReq, setTransactionReq] = useState();

  const [loadingTransactionReq, setLoadingTransactionReq] = useState(false);

  const [transactionReqModal, setTransactionReqModal] = useState(false);

  let { phoneNumber } = useSelector((state) => state.Authentication);
  const { selectedPhoneNumber } = useSelector((state) => state.UserPhone);
  let phoneChanged = false;
  if (selectedPhoneNumber !== '') {
    phoneNumber = selectedPhoneNumber;
    phoneChanged = true;
  }

  const referralPrecheck = useCallback(
    async (selectedNominal) => {
      try {
        referralPrecheckFail && setReferralPrecheckFail(false);
        setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
        if (checkBlackoutPeriod()) return;
        setLoadingTransactionReq(true);
        const response = await preCheckBp(selectedNominal, accountNumber ? accountNumber : phoneNumber, redeemCode);

        requestSelectedTransaction(selectedNominal);
      } catch (error) {
        setReferralPrecheckFail(true);
        setReferralToolTip({ visible: true, message: error?.response?.data?.message });
        requestSelectedTransaction(selectedNominal);
      }
    },
    [
      accountNumber,
      phoneNumber,
      redeemCode,
      referralPrecheckFail,
      maxWithdrawalAmount,
      salaryWithCappedAmount,
      todayBalanceFloor,
      todaySalaryFloor,
      selectedAccount,
      donorNames,
      totalDonation,
    ],
  );

  const [accounts, setAccounts] = useState([]);
  const {
    todayBalanceFloor,
    todaySalaryFloor,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    workingDays,
    workedDays,
    usedBalance,
    lockPeriod,
  } = useSelector((state) => state.Withdrawal);
  const [loadingTransactionConfirm, setLoadingTransactionConfirm] = useState(false);

  const [referralToolTip, setReferralToolTip] = useState({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });

  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);

  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        salaryWithCappedAmount,
        todayBalanceFloor,
        workingDays,
        workedDays,
        maxWithdrawalAmount,
        usedBalance,
      ),
    [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance],
  );

  const accountByRule = useCallback(
    accountByRuleProductScreen(
      dataAccounts,
      lockPeriod,
      newAvailableBalance,
      item,
      dynamicAccountDetails,
      currentSelectedAccountName,
    ),
    [lockPeriod, newAvailableBalance, dynamicAccountDetails, dataAccounts],
  );

  const [selectedAccount, setSelectedAccount] = useState(accountByRule());

  const setAccountList = useCallback(() => {
    //if phone is selected in UserPhone reducer, set EWA as default
    const dynamicAccountList = getDynamicAccountListByAccount(
      dataAccounts,
      BP_DYNAMIC_ACCOUNTS,
      DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    );

    const salaryAccount = dynamicAccountList.filter(
      (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    );

    setAccounts(salaryAccount);
    setSelectedAccount(salaryAccount[0]);
  }, [dataAccounts, workAllowanceBillPaymentList, item]);

  useEffect(() => {
    setAccountList();
  }, [dataAccounts]);

  const checkBlackoutPeriod = () => {
    if (lockPeriod) {
      setLoadingTransactionReq(false);
      dispatch(respError('Transaksi hanya bisa dilakukan pada masa penarikan'));
      return true;
    }
    return false;
  };

  const requestSelectedTransaction = useCallback(
    async (selectedNominal) => {
      selectedNominal.biller = 'Zakat';
      try {
        setLoadingTransactionReq(true);
        if (checkBlackoutPeriod()) return;

        const response = await requestBillPaymentTransaction(
          selectedNominal,
          accountNumber ? accountNumber : phoneNumber,
          selectedAccount.constantAccountName,
          dynamicAccounts,
        );

        setTransactionReq({ ...response.data.data, donorNames: donorNames, totalDonation: totalDonation });
        setLoadingTransactionReq(false);

        setTransactionReqModal(true);
        let e = AMPLITUDE_CONSTANTS.BillPayment.billPaymentTransactionSummary;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
          [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal.categoryPrefix),
          [e.attributes.product_code]: formatUnderScoreLowerCase(selectedNominal?.code),
          [e.attributes.reason]: ZAKAT_REASON,
          [e.attributes.reason_id]: ZAKAT_REASON_ID,
          [e.attributes.source]: 'zakat',
          [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        });
      } catch (error) {
        setLoadingTransactionReq(false);
        if (error?.response?.data?.message) {
          if (error?.response?.data?.message === 'Balance tidak tersedia!') {
            dispatch(respError('Saldo Gaji dan Poin belum mencukupi untuk transaksi ini'));
            return;
          }
          dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
        } else {
          dispatch(respError('Terjadi kesalahan sistem. Harap coba kembali.'));
        }
      }
    },
    [
      accountNumber,
      phoneNumber,
      maxWithdrawalAmount,
      salaryWithCappedAmount,
      todayBalanceFloor,
      todaySalaryFloor,
      selectedAccount,
      donorNames,
      totalDonation,
    ],
  );
  const confirmTransaction = async () => {
    const selectedNominal = SELECTED_NOMINATION_ZAKAT;
    try {
      setLoadingTransactionConfirm(true);
      setLoadingTransactionReq(true);
      setTransactionReqModal(false);

      let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.payment_method]: selectedAccount.amplitudeAccountName,
        [e.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e.attributes.reason_id]: ZAKAT_REASON_ID,
        [e.attributes.reason]: ZAKAT_REASON,
        [e.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.source]: 'zakat',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e.attributes.fee_charged]: transactionReq?.adminFee,
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,

        [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });

      await confirmBpTransaction(
        transactionReq.id,
        'Zakat',
        referralPrecheckFail ? '' : redeemCode,
        selectedAccount.constantAccountName,
        {
          donatorName: donorNames,
          email: email,
          donatorCount: noOfPeople,
        },
      );
      dispatch(clearReferral());
      setTransactionReqModal(false);

      let e2 = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;
      navigation.popToTop();
      navigation.navigate('TransactionList', {
        screen: 'ProductPurchaseConfirm',
        params: {
          productName: transactionReq.productName,
          category: item.name,
          noOfPeople: noOfPeople,
          donorNames: donorNames,
          [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
            selectedNominal?.categoryPrefix ?? item.prefix,
          ),
          [e2.attributes.product_code]: formatUnderScoreLowerCase(
            selectedNominal?.code ?? transactionReq?.productCode ?? '',
          ),
          [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
          [e2.attributes.reason]: ZAKAT_REASON,
          [e2.attributes.reason_id]: ZAKAT_REASON_ID,
          [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
          [e2.attributes.fee_charged]: transactionReq.fee,
          [e2.attributes.source]: 'zakat',
          [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
          [e2.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
        },
      });

      AmplitudeHelper.logEvent(e2.name, {
        [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e2.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e2.attributes.reason]: ZAKAT_REASON,
        [e2.attributes.reason_id]: ZAKAT_REASON_ID,
        [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e2.attributes.fee_charged]: transactionReq.fee,
        [e2.attributes.source]: 'zakat',
        [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e2.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });

      setLoadingTransactionConfirm(false);
      setLoadingTransactionReq(false);
    } catch (error) {
      setLoadingTransactionReq(false);
      setLoadingTransactionConfirm(false);
      setTransactionReqModal(false);

      if (error?.response?.data?.error === 'REFERRAL_CODE_NOT_APPLICABLE') {
        dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        dispatch(setIsTransacted(true));
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'not first transaction',
        });
      } else if (error?.response?.data?.error === 'INVALID_REFERRAL_CODE') {
        dispatch(setIsTransacted(false));
        dispatch(setIsValidReferral(false, false));
        dispatch(setRedeemCode(''));
        dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'invalid code',
        });
      } else if (isValidCode) {
        if (isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        else dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'system error',
        });
      } else {
        dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
      }

      let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e.attributes.product_price]: transactionReq.requestAmount,
        [e.attributes.reason]: ZAKAT_REASON,
        [e.attributes.reason_id]: ZAKAT_REASON_ID,
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.source]: 'zakat',
        [e.failure_message]: formatUnderScoreLowerCase(
          error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.',
        ),
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e.attributes.account_number]: accountNumber ? accountNumber : phoneNumber,
      });
    }
  };

  useEffect(() => {
    dispatch(clearUserSelectedPhone());
    const clickedBack = navigation.addListener('blur', () => {
      let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedBack;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    });
    return clickedBack;
  }, [navigation]);

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.Zakat.product_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route.params?.source ?? 'zakat',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      [e.attributes.phone_number]: phoneNumber,
    });
  }, [route.params]);

  const onPressModal = (prefix) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.userCanceledBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.button]: 'batal',
      [e.attributes.source]: 'zakat',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    setTransactionReqModal(false);
  };

  const changeSelectedAccount = (account) => {
    const sourceAccount = accountByRule();
    account.optionChangedByUser = sourceAccount.constantAccountName !== account.constantAccountName;
    setSelectedAccount(account);
    if (account.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
    }
  };

  const onInfoPress = () => {
    let e = AMPLITUDE_CONSTANTS.Zakat.information_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'zakat',
    });
    setTooltipVisible(true);
  };

  const logEvent = (event) => {
    const e = AMPLITUDE_CONSTANTS.Zakat[event.name];
    let obj = {
      [e.attributes.source]: 'zakat',
    };
    if (event.name == AMPLITUDE_CONSTANTS.Zakat.zakat_name_inputted.name) {
      obj[e.attributes.name] = donorNames ?? null;
    }

    if (event.name == AMPLITUDE_CONSTANTS.Zakat.zakat_email_inputted.name) {
      obj[e.attributes.email] = email ?? null;
    }

    AmplitudeHelper.logEvent(e.name, obj);
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding" enabled keyboardVerticalOffset={-50}>
      <MainParentWrapper isScrollable containerStyle={styles.scrollableContainerStyle} isRounded>
        <DescriptionBanner
          icon={SVG_ICONS.zakat.zakatMain}
          literal={
            'Zakat fitrah ialah zakat diri yang diwajibkan atas diri setiap individu laki-laki maupun perempuan muslim yang berkemampuan sesuai syarat-syarat yang ditetapkan.'
          }
        />

        <ZakatPoster />

        <Divider horizontal customStyle={styles.dividerStyle} />

        <LabelTitle title={'Sumber Dana'} />

        <SalaryNetworth amount={numberWithDots(totalSalary)} />

        <LabelTitle title={'Zakat untuk :'} />

        <DonationAdder
          disabledMinus={noOfPeople == 1}
          noOfPeople={noOfPeople}
          onPressAdd={onAddDonator}
          onPressMinus={onRemoveDonator}
        />

        <TotalZakat
          amount={totalDonation}
          onInfoClose={() => setTooltipVisible(false)}
          onInfoPress={onInfoPress}
          toolTipVisible={toolTipVisible}
        />

        <Divider horizontal customStyle={styles.dividerStyle} />

        <LabelTitle title={'Profil Donatur (Muzakki)'} containerStyle={{ marginTop: normalize(15) }} />

        <View style={{ paddingHorizontal: normalize(15) }}>
          <TextInputComponent
            title={'Masukkan Nama'}
            textInputProps={{
              placeholder: 'Contoh : Agni, Dinda',
              numberOfLines: 1,
              onChangeText: setDonarNames,
              value: donorNames,
              placeholderTextColor: colors.neutral_darkMist,
              onFocus: () => logEvent(AMPLITUDE_CONSTANTS.Zakat.zakat_name_clicked),
              onBlur: () => logEvent(AMPLITUDE_CONSTANTS.Zakat.zakat_name_inputted),
            }}
          />
          <MyText
            customStyle={{
              fontSize: normalize(11),
              marginBottom: normalize(4),
              color: colors.neutral_darkMist,
              marginTop: -6,
            }}
          >
            Catatan: Bisa lebih dari 1 nama
          </MyText>

          <TextInputComponent
            title={'Email (Opsional)'}
            textInputProps={{
              placeholder: 'Contoh : agni@gmail.com',
              numberOfLines: 1,
              onChangeText: setEmail,
              value: email,
              placeholderTextColor: colors.neutral_darkMist,
              onFocus: () => logEvent(AMPLITUDE_CONSTANTS.Zakat.zakat_email_clicked),
              onBlur: () => logEvent(AMPLITUDE_CONSTANTS.Zakat.zakat_email_inputted),
            }}
          />
        </View>

        <PoweredBy
          title={'Didukung oleh'}
          imagePath={require('@gaji-gesa/gg-react-shared/src/assets/Svg/zakat/zakat_powered.png')}
        />

        <LoadingButton
          container={styles.loadingButton}
          textStyle={styles.loadingButtonText}
          buttonColor={colors.primary_carnation}
          text={'Bayar Zakat'}
          textColor={colors.neutral_cotton}
          textTransform="none"
          disabled={!donorNames}
          disabledStyle={styles.disabledButton}
          onPress={onDonation}
          loading={loadingTransactionReq || loadingTransactionConfirm}
        />

        <LoadingModal loading={loadingTransactionReq || loadingTransactionConfirm} />
        <RequestTransactionSheet
          selectedAccount={selectedAccount}
          setSelectedAccount={changeSelectedAccount}
          accounts={accounts}
          visible={transactionReqModal}
          lockPeriod={lockPeriod}
          transactionReq={transactionReq}
          onPressModal={onPressModal}
          prefix={item.prefix}
          productId={item.id}
          onPressConfirm={confirmTransaction}
          isValidCode={isValidCode}
          isTransacted={isTransacted}
          toolTip={referralToolTip}
          setTooltip={setReferralToolTip}
        />
      </MainParentWrapper>
    </KeyboardAvoidingView>
  );
};

export default ZakatScreen;
