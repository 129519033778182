import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: colors.white,
  },
  selectPdam: {
    borderColor: colors.lightGrey,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 0.8,
    marginTop: 15,
  },
  tabContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputText: {
    borderWidth: 0.8,
    borderColor: colors.lightGrey,
    borderRadius: 12,
    paddingVertical: 8,
    paddingHorizontal: 10,
    fontFamily: Font.Nunito_Regular,
    color: colors.black,
    outlineColor: 'transparent',
  },
  container: {
    flex: 1,
    paddingBottom: 10,
    backgroundColor: colors.white,
  },
  fullScreen: {
    width: '100%',
    flex: 1,
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingTop: 20,
    borderRadius: 0,
    borderTopEndRadius: 20,
    borderTopStartRadius: 20,
    overflow: 'hidden',
  },
  childContainer: {
    width: '95%',
    flex: 1,
    backgroundColor: colors.white,
    alignSelf: 'center',
    marginTop: 20,
    elevation: 5,
    borderRadius: 20,
    overflow: 'hidden',
  },
  blueBg: {
    height: '30%',
    width: '100%',
    backgroundColor: colors.blue,
    position: 'absolute',
    zIndex: -5,
  },
  containerError: {
    height: '100%',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    position: 'absolute',
    zIndex: 15,
  },
  text: {
    color: '#9e9e9e',
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(14),
    textAlign: 'center',
    maxWidth: '75%',
  },
  buttonGroup: { marginVertical: '10%', width: '75%', flexDirection: 'row', justifyContent: 'space-evenly' },
  button: { borderWidth: 1, borderColor: '#3863F0', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 4 },
  buttonText: {
    color: '#3863F0',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(12),
    textAlign: 'center',
  },
});
