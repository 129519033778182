const colors = require("@gaji-gesa/gg-react-shared/src/colors");

/** @type {import('tailwindcss').Config} */

module.exports = {
  content: ["./src/**/*.{html,js,jsx,ts,tsx}"],
  important: true,
  theme: {
    extend: {},
    colors,
  },
  plugins: [],
};
