import React, { useEffect } from 'react';
import { Text, View, Dimensions, StyleSheet, ScrollView, Pressable } from 'react-native';
import MaIcon from '@expo/vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from 'react-redux';

import AmplitudeHelper from '@services/amplitude';
import { normalize, normalizeHeight } from '@style/helper';
import { setAcceptedTerms, setShowTerms } from '@redux/action/Common';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { TERMS } from '@utils/Constants';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { getCurrentTimeStamp } from '@utils/UtilFunctions';
import { store } from '@enhancedStore/store';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
const Terms = () => {
  const { transCategoryId, transSource } = store.getState().Transactions;
  const { currentHomePageSelectedAccount } = store.getState().DynamicAccounts;
  const { redeemCode } = store.getState().Referral;

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.transaction_category_id]: transCategoryId,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.timestamp]: getCurrentTimeStamp(),
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.source]: transSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.termsAndConditionsPage.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    return () => {
      //for page leaving
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.attributes.transaction_category_id]:
          transCategoryId,
        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.attributes.timestamp]: getCurrentTimeStamp(),
        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.attributes.source]: transSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.userClickedBackWithdrawalTerms.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });
    };
  }, []);

  const dispatch = useDispatch();
  const common = useSelector((state: any) => state.Common);

  const handleReject = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.transaction_category_id]: transCategoryId,
      [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.timestamp]: getCurrentTimeStamp(),
      [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.source]: transSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.userCanceledTerms.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });
    // AmplitudeHelper.logEvent('user_canceled_withdrawal_payment_terms', {timestamp: new Date().toString()})
    dispatch(setShowTerms(false));
  };

  const handleAccept = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.attributes.transaction_category_id]: transCategoryId,
      [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.attributes.timestamp]: getCurrentTimeStamp(),
      [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.attributes.source]: transSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.userAcceptedTerms.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    if (!common.acceptedTerms) {
      dispatch(setAcceptedTerms());
    }
    dispatch(setShowTerms(false));
  };

  return (
    <>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: '#3863F0',
            height: screenHeight / 4,
            width: screenWidth,
            position: 'absolute',
            right: 0,
          }}
        />
        <View style={styles.container}>
          <View style={{ alignSelf: 'flex-start' }} accessibilityLabel="tncWithdrawText">
            <Text style={styles.cardTitle}>
              <MaIcon name="text-box-check" style={styles.icon} />
              {'  '}Ketentuan Layanan
            </Text>
          </View>
          <ScrollView
            style={{
              maxWidth: '85%',
            }}
            contentContainerStyle={{
              alignItems: 'flex-start',
            }}
            scrollEnabled
          >
            <View style={styles.descriptionContainer}>
              <Text style={styles.desc}>{TERMS.first}</Text>
              <Text style={styles.descTwo}>{TERMS.second}</Text>
              <Text style={styles.desc}>{TERMS.third}</Text>
            </View>
            <View style={styles.buttonContainer}>
              <Pressable onPress={handleAccept} android_ripple={{ color: colors.lightGrey }}>
                <View
                  style={{
                    elevation: 8,
                    backgroundColor: colors.orange,
                    borderRadius: 30,
                    paddingHorizontal: normalize(10),
                    paddingVertical: normalizeHeight(7),
                    width: normalize(220),
                    marginBottom: normalize(10),
                    marginTop: normalize(20),
                  }}
                >
                  <Text
                    style={{
                      color: colors.white,
                      fontFamily: Font.Nunito_Bold,
                      fontSize: normalize(14),
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    Setuju
                  </Text>
                </View>
              </Pressable>

              <Pressable onPress={handleReject} android_ripple={{ color: colors.lightGrey }}>
                <View
                  style={{
                    elevation: 8,
                    backgroundColor: colors.slightLightGrey,
                    borderRadius: 30,
                    paddingHorizontal: normalize(10),
                    paddingVertical: normalizeHeight(7),
                    width: normalize(220),
                    marginBottom: normalize(20),
                    marginTop: normalize(10),
                  }}
                >
                  <Text
                    style={{
                      color: colors.white,
                      fontFamily: Font.Nunito_Bold,
                      fontSize: normalize(14),
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    Batal
                  </Text>
                </View>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  cardTitle: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(17),
    marginTop: 15,
    marginBottom: 10,
    marginLeft: 25,
    fontFamily: Font.Nunito_Regular,
  },
  container: {
    width: screenWidth / 1.1,
    backgroundColor: colors.white,
    borderRadius: 15,
    alignItems: 'center',
    elevation: 5,
    marginTop: normalizeHeight(25),
    height: '100%',
  },
  icon: {
    fontSize: normalize(35),
    color: colors.blue,
  },
  descriptionContainer: {
    maxWidth: '95%',
  },
  desc: {
    fontSize: normalize(12),
    marginBottom: normalizeHeight(5),
    marginTop: normalizeHeight(5),
    lineHeight: normalizeHeight(20),
    fontFamily: Font.Nunito_Regular,
  },
  descTwo: {
    fontSize: normalize(12),
    marginBottom: normalizeHeight(10),
    lineHeight: normalizeHeight(20),
    fontFamily: Font.Nunito_Regular,
  },
  buttonContainer: {
    // flex: 1,
    flexDirection: 'column',
    // justifyContent: "flex-end",
    alignItems: 'center',
    marginBottom: normalizeHeight(20),
  },
});

export default Terms;
