import AsyncStorage from '@react-native-async-storage/async-storage';
import { batch } from 'react-redux';
import {
  setApiKey,
  setbURL,
  setEmployeeId,
  setEmployerId,
  setIsGajitimEmployee,
  setPhoneNumber,
  setTokenOtp,
  setTokens,
  setUserId,
  setUserName,
  setUserRoles,
} from '@redux/action/Authentication';
import AmplitudeHelper from './amplitude';
import { findAccountByName } from '@utils/UtilFunctions';
import mainAPI, { axiosInstance, setDefaultParams } from './api';
import { setDataAccounts } from '@redux/action/DynamicAccounts';
import { SentryHelper } from '@utils/SentryHelper';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { setIsTransacted, setReferralLoading } from '@redux/action/Referral';
import { formatPhoneNumber } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import {
  setDynamicAccounts,
  setCurrentTransactionPageSelectedAccount,
  setCurrentBpSelectedAccount,
} from '@redux/action/DynamicAccounts';
import { ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';

const AuthService = {
  login: function (data) {
    return mainAPI('auth/login', 'post', data);
  },
  reSend: function (data) {
    return mainAPI('auth/login/otp/resend', 'post', data);
  },
  otp: async function (data) {
    return mainAPI('auth/login/otp', 'post', data);
  },
  getProfile: async function () {
    return mainAPI('user/me', 'get');
  },
  refreshToken: async function () {
    return mainAPI('auth/refresh', 'post', useRefresh);
  },
};

//===============> New Api Function ======================>

export const newLogin = async (mobileNumber, messagingType) => {
  try {
    const data = {
      mobileNumber,
      messagingType,
    };
    const response = await mainAPI('auth/login', 'post', data);
    return {
      otpToken: response.data?.data?.token,
    };
  } catch (error) {
    throw error;
  }
};

export const reSend = async (token) => {
  try {
    const data = {
      token,
    };
    const response = await mainAPI('auth/login/otp/resend', 'post', data);
    return {
      otpToken: response.data?.data?.token,
    };
  } catch (error) {
    throw error;
  }
};

export function setStorageForLogin(userNameResponse, response, dispatch, phoneNumber, accessToken, refreshToken) {
  AmplitudeHelper.setUserId(
    userNameResponse.data.data.employees[userNameResponse.data.data.employees.length - 1].id,
    userNameResponse.data.data.employees[userNameResponse.data.data.employees.length - 1].employerId,
  );
  AmplitudeHelper.setUserProperties({ userId: userNameResponse.data.data.id });

  const employeeId = userNameResponse.data.data?.employees[userNameResponse.data.data.employees.length - 1].id;
  const userRoles = response?.data?.data?.userRoles ?? '';
  const isGajitimEmployee = response?.data?.data?.isGajitimEmployee ?? '';
  const employerId = userNameResponse.data.data?.employees[userNameResponse.data.data.employees.length - 1].employerId;
  const userId = userNameResponse.data.data?.employees[userNameResponse.data.data.employees.length - 1].userId;
  const { firstName } = userNameResponse.data.data;
  const dynamicAccounts = response?.data?.data?.employeeAccounts ?? [];
  // Saving data in local storage
  AsyncStorage.multiSet([
    ['@employeeId', JSON.stringify(employeeId)],
    ['@employerId', JSON.stringify(employerId)],
    ['@userRoles', JSON.stringify(userRoles)],
    ['@isGajiTim', JSON.stringify(isGajitimEmployee)],
    ['@userId', JSON.stringify(userId)],
    ['@userName', firstName],
    ['@dynamicAccounts', JSON.stringify(dynamicAccounts)],
  ]);

  SentryHelper.setUser(employeeId, employerId);
  // Dispatching actions
  batch(() => {
    dispatch(setPhoneNumber(phoneNumber));
    dispatch(setUserRoles(userRoles));
    dispatch(setIsGajitimEmployee(isGajitimEmployee));
    dispatch(setUserName(firstName));
    dispatch(setUserId(userId));
    dispatch(setEmployeeId(employeeId));
    dispatch(setEmployerId(employerId));
    dispatch(setTokens({ accessToken, refreshToken }));
    dispatch(setReferralLoading(true));
    dispatch(setIsTransacted(false));
    dispatch(setDynamicAccounts(dynamicAccounts));
    dispatch(setDataAccounts(ACCOUNT_LIST));
    dispatch(
      setCurrentTransactionPageSelectedAccount(findAccountByName(ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT)),
    );
    dispatch(setCurrentBpSelectedAccount(findAccountByName(ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT)));
  });

  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Login.loginSuccessful.name, {
    [AMPLITUDE_CONSTANTS.Login.loginSuccessful.attributes.phone_number]:
      userNameResponse.data.data.mobileNumber.toString() ?? null,
  });
}

export const checkOtp = (otp, phoneNumber, otpToken) => async (dispatch) => {
  try {
    const data = {
      token: otpToken,
      otp: parseInt(otp),
    };

    const response = await mainAPI('auth/login/otp', 'post', data);

    const { accessToken, refreshToken } = response.data.data;
    AsyncStorage.multiSet([
      ['@token', accessToken],
      ['@refreshToken', refreshToken],
      ['@phoneNumber', phoneNumber],
    ]);

    // Calling this api to get user based config
    const userNameResponse = await mainAPI('user/me', 'get');

    setStorageForLogin(userNameResponse, response, dispatch, phoneNumber, accessToken, refreshToken);
  } catch (error) {
    SentryHelper.captureException(error);
    throw error;
  }
};

export const onQueryParamActionLogin =
  (meResponse, otpResponse, phoneNumber, accessToken, refreshToken) => async (dispatch) => {
    setStorageForLogin(meResponse, otpResponse, dispatch, phoneNumber, accessToken, refreshToken);
  };

export const onBoardUnregisterd = async (selectedUserIdType, userCompanyName, userName, userUniqueId, phoneNumber) => {
  try {
    const data = {
      employerName: userCompanyName,
      userName,
      phoneNumber: formatPhoneNumber(phoneNumber),
      identityType: selectedUserIdType.identityType,
      identityId: userUniqueId,
    };
    await mainAPI('employee/unregistered', 'post', data);
    return true;
  } catch (error) {
    throw error;
  }
};

export const logoutApi = async () => {
  try {
    await mainAPI('/auth/logout', 'delete');
    return true;
  } catch (error) {
    return true;
  }
};

export default AuthService;
