import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: { flex: 1, backgroundColor: colors.secondary_orchid },
  upperContainer: { height: '10%', paddingTop: normalize(10) },
  closeStyle: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: normalizeHeight(20),
    marginLeft: normalize(15),
  },
  scrollableContainerStyle: { paddingBottom: normalizeHeight(17), paddingTop: normalizeHeight(10) },
  assetOneStyling: { position: 'absolute', bottom: normalize(-110), zIndex: -2 },
  assetTwoStyling: {
    position: 'absolute',
    alignSelf: 'center',
    right: normalize(42),
    top: normalizeHeight(28),
  },
  textContainer: { width: '50%', marginLeft: normalize(15), marginTop: normalizeHeight(20) },
  textStyling: { fontSize: normalize(15), color: colors.neutral_cotton },
  contentCardContainer: { paddingVertical: normalizeHeight(10), flexDirection: 'row' },
  cardTitleStyle: { marginVertical: normalize(7), fontSize: normalize(14), color: colors.blackColor },
  cardContentStyle: { fontSize: normalize(12), color: colors.blackColor, width: '90%' },
  cardContentSrnStyle: { fontSize: normalize(12), color: colors.blackColor, marginTop: normalize(2) },
  contentScrollStyle: { paddingHorizontal: normalize(16), paddingBottom: normalize(40), height: 0 },
  loginButtonStyle: {
    position: 'absolute',
    bottom: normalize(14),
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primary_carnation,
    color: colors.blackColor,
    width: '85%',
    borderRadius: normalize(30),
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  loginButtonTextStyle: { textTransform: 'none' },
  mainContentStyle: {
    flex: 1,
    paddingBottom: normalize(50),
  },

  titleStyle: {
    color: colors.neutral_cotton,
    marginLeft: normalize(10),
    marginTop: normalize(4),
  },
});

export default styles;
