import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@style/helper';

const styles = StyleSheet.create({
  circle: {
    height: 35,
    width: 35,
    borderRadius: 35 / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  note: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 15,
    borderWidth: 0.8,
    borderColor: '#DDDDDD',
    marginVertical: 15,
  },
  viewHint: {
    backgroundColor: '#F4F4F4',
    paddingHorizontal: 2,
    paddingVertical: 3,
    borderRadius: 20,
    marginVertical: 15,
  },

  contentContainer: {
    paddingVertical: 20,
    height: '100%',
    borderTopStartRadius: 15,
    borderTopEndRadius: 15,
    flex: 1,
    marginTop: 30,
    // // alignSelf: "center",
  },

  pressableContainer: {
    alignSelf: 'flex-end',
    marginRight: normalize(15),
  },
  closeButtonStyling: { color: colors.black, opacity: 0.4 },
});

export default styles;
