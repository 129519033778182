import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

const styles = StyleSheet.create({
  artContainer: {
    marginTop: normalizeHeight(22),
    marginLeft: normalize(20),
  },
  textContainer: {
    marginVertical: normalizeHeight(15),
    marginHorizontal: normalize(18),
  },
  infoText: {
    fontSize: normalize(15),
  },
  otpButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  otpGroup: {
    marginVertical: normalizeHeight(10),
    width: '90%',
    alignSelf: 'center',
    height: normalizeHeight(50),
  },
  otpDefault: {
    borderWidth: 1,
    borderColor: colors.black,
    color: colors.black,
    fontSize: normalize(20),
    width: normalize(57),
    height: normalize(57),
    fontFamily: Font.Nunito_Bold,
    paddingTop: 8,
    borderRadius: 12,
  },
  otpHightLight: { borderColor: colors.secondaryColor },
  otpFailed: { borderColor: colors.roseRed },
  otpDisabled: {
    backgroundColor: colors.gainsboro,
    borderColor: colors.gainsboro,
  },
  errorContainer: {
    marginVertical: normalizeHeight(10),
    marginHorizontal: normalize(18),
  },
  errorTextStyle: {
    fontSize: normalize(12),
    color: colors.roseRed,
    textAlign: 'center',
  },
  resendOtpStyle: {
    textAlign: 'center',
    marginTop: normalizeHeight(6),
    fontSize: normalize(12),
    color: colors.black,
  },
  reLoginContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: normalizeHeight(2),
    marginHorizontal: normalize(18),
  },
  reLoginButtonContainer: {
    borderWidth: 1,
    borderRadius: 12,
    width: '40%',
    alignItems: 'center',
    borderColor: colors.gainsboro,
    backgroundColor: colors.white,
  },
  reLoginLeftAdjustment: {
    borderRightWidth: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  reLoginRightAdjustment: {
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  reLoginPressableStyle: {
    flex: 1,
    paddingVertical: 12,
    justifyContent: 'center',
  },
  reloginTextStyle: { color: colors.black },
  reloginTextDisabledStyle: { color: colors.gainsboro },
  loginButtonStyle: {
    position: 'absolute',
    bottom: normalize(14),
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primaryColor,
    color: colors.black,
    width: '90%',
    borderRadius: normalize(30),
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  loginButtonTextStyle: { textTransform: 'none' },
  loginButtonDisable: { backgroundColor: colors.gainsboro },
  otpContainerStyle: { flex: 1, backgroundColor: colors.white },
});

export default styles;
