import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Pressable, SectionList, Text, TextInput, View } from 'react-native';
import BillPaymentWrapper from '@components/BillPaymentWrapper';
import Divider from '@atoms/Divider';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import AmplitudeHelper from '@services/amplitude';
import { colors } from '@style/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

import { styles } from './style';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';

const BillPaymentConfirm = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { params } = route;
  let { phoneNumber, userName } = useSelector((state) => state.Authentication);
  const { selectedPhoneNumber, selectedUserName } = useSelector((state) => state.UserPhone);
  if (selectedPhoneNumber !== '') {
    phoneNumber = selectedPhoneNumber;
    userName = selectedUserName;
  }
  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.selamatPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.product_price]: params?.product_price ?? null,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
      [e.attributes.reason]: params?.reason ?? null,
      [e.attributes.reason_id]: params?.reason_id ?? null,
      [e.attributes.fee_charged]: params?.fee_charged ?? null,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.payment_method]: params?.payment_method,
      [e.attributes.account_number]: phoneNumber,
    });

    return () => {
      let e = AMPLITUDE_CONSTANTS.Withdrawal.success_page_closed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.product_price]: params?.product_price ?? null,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
        [e.attributes.reason]: params?.reason ?? null,
        [e.attributes.reason_id]: params?.reason_id ?? null,
        [e.attributes.fee_charged]: params?.fee_charged ?? null,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.payment_method]: params?.payment_method,
        [e.attributes.account_number]: phoneNumber,
      });
    };
  }, []);

  return (
    <BillPaymentWrapper withBlueBg={true} showAccount={false} withTabs={false}>
      <View style={styles.logo}>
        {/*
          <SvgUri uri="https://storage.googleapis.com/gajigesa-public/icons/clock.svg" />
        */}
        {SVG_ICONS.clockIcon}
      </View>
      <View style={styles.container}>
        <MyText isRegular={false} h5 customStyle={{ color: colors.blue }}>
          Dalam Proses
        </MyText>
        <MyText h4 customStyle={{ marginTop: 10 }}>
          {moment().format('DD MMM YYYY hh:mm')}
        </MyText>
        <MyText isRegular={false} h5 customStyle={{ color: colors.orange, marginTop: 20, textAlign: 'center' }}>
          {route?.params?.productName}
        </MyText>

        <MyText h4 customStyle={{ marginTop: 25, width: '80%', textAlign: 'center' }}>
          Proses bisa memakan waktu hingga 5 menit. Anda akan menerima SMS saat isi ulang{' '}
          <MyText isRegular={false}>{route?.params?.category}</MyText> berhasil
        </MyText>
        <MyText isRegular={false} h5 customStyle={{ color: colors.orange, marginTop: 20, textAlign: 'center' }}>
          {userName}
        </MyText>
        <MyText isRegular={false}>{phoneNumber}</MyText>
        <LoadingButton
          text={'Ok'}
          buttonColor={colors.orange}
          loadingColor={colors.white}
          container={{ borderRadius: 40, marginTop: '20%' }}
          onPress={() => {
            navigation.popToTop();
            navigation.navigate('Home');
          }}
        />
      </View>
    </BillPaymentWrapper>
  );
};

export default BillPaymentConfirm;
