import React, { useEffect, useRef } from 'react';
import { Linking, Pressable, View, Animated } from 'react-native';
import MyText from '@atoms/MyText';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import { COMMON_CONSTANTS, FEATURE_FLAGS } from '@utils/Constants';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { useNavigation } from '@react-navigation/native';
import { BantuanButton } from '@components/Button';
import { styles } from './style';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AppTourComponentWrapper from '../AppTour/AppTourComponentWrapper';
import withAppTourHoc from '../AppTour/CopilotHoc';
import { useSelector } from 'react-redux';
import { store } from '@enhancedStore/store';
import { resetAppTourParams, setAppTourParams } from '@redux/action/Common';
import { APPTOURMETA, APP_TOUR_TYPE } from '@utils/AppTourConstants';
import { colors } from '@style/colors';

const ReferralButton = ({ start, appTourVisible, onRefferalCta }) => {
  const { appTourParams } = useSelector((state) => state.Common);

  useEffect(() => {
    if (appTourParams?.type == 'referral' && appTourParams?.step == 1 && appTourVisible) start();
  }, [appTourParams]);

  const animation = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.spring(animation, {
          toValue: 1,
          duration: 10,
          speed: 10,
          bounciness: 15,
          useNativeDriver: true,
        }),
        Animated.spring(animation, {
          toValue: 0,
          duration: 10,
          bounciness: 15,
          useNativeDriver: true,
          speed: 10,
        }),
      ]),
    ).start();
  }, []);

  const interpolated = animation.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: ['-30deg', '0deg', '30deg'],
  });

  return (
    <AppTourComponentWrapper
      order={1}
      text={APPTOURMETA[APP_TOUR_TYPE.referral][1]}
      props={{
        style: null,
      }}
      name={`${APP_TOUR_TYPE.referral}1`}
      type={APP_TOUR_TYPE.referral}
    >
      <View style={styles.pressableContainer}>
        <Pressable
          style={styles.referralPressable}
          onPress={onRefferalCta}
          android_ripple={{ color: colors.lightGrey }}
        >
          <View style={styles.iconView}>
            <Animated.View style={{ transform: [{ rotate: interpolated }], marginTop: 5 }}>
              {SVG_ICONS.giftNew}
            </Animated.View>
          </View>

          <MyText isRegular={false} customStyle={styles.textStyle}>
            Ajak Teman
          </MyText>
        </Pressable>
      </View>
    </AppTourComponentWrapper>
  );
};

const onPressNext = (currStep, type) => {
  store.dispatch(
    setAppTourParams({
      type,
      step: currStep + 1,
    }),
  );
};
const onPressExit = (currentStep) => {
  store.dispatch(resetAppTourParams());
};
const ReferralWrapper = withAppTourHoc(ReferralButton, {
  onPressNext,
  onPressExit,
  svgMaskProps: { isFullWidth: false, widthStartAdj: 10, widthEndAdj: 4 },
  isArrowVisible: true,
  verticalOffset: 35,
});

const HeaderRight = ({ appTourVisible }) => {
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: FEATURE_FLAGS.REFERRAL,
  });
  const navigation = useNavigation();

  const onSupportClick = () => Linking.openURL(COMMON_CONSTANTS.support);

  const onRefferalCta = () => {
    navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'tnc_button' } });
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.ReferallButton.name);
  };

  if (isFeatureAvailale.show) {
    return (
      <View style={styles.container}>
        <ReferralWrapper onRefferalCta={onRefferalCta} appTourVisible={appTourVisible} />
        <BantuanButton source={AMPLITUDE_EVENT_SOURCES.header} onPress={onSupportClick} />
      </View>
    );
  }
  return <BantuanButton source={AMPLITUDE_EVENT_SOURCES.header} onPress={onSupportClick} />;
};

export default HeaderRight;
