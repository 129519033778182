import React from 'react';
import { View, Text, Dimensions } from 'react-native';
import { copilot } from 'react-native-copilot';
import { onPressBack, onPressExit, onPressNext } from '@utils/AppTourConstants';
import { CustomSvgMaskPath, getOffsetByWindow } from '@utils/UtilFunctions';
import AppTourToolTip from '../AppTourToolTip';
import styles from './styles';
const MARGIN = 20;
const WIDTH = Dimensions.get('window').width - 40;

const StepNumberComponent = ({ isFirstStep, isLastStep, currentStep, currentStepNumber }) => null;

const withHoc = (
  ReactComponent,
  { svgMaskProps, isArrowVisible = true, verticalOffset = 20, hasIcon = false, tooltipFullWidth },
) => {
  const appTourToolTip = (props) => {
    return (
      <AppTourToolTip
        {...props}
        onPressExit={onPressExit}
        onPressNext={onPressNext}
        hasIcon={hasIcon}
        onPressBack={onPressBack}
      />
    );
  };

  return copilot({
    animated: true,
    tooltipComponent: appTourToolTip,
    verticalOffset: getOffsetByWindow(verticalOffset),
    stepNumberComponent: StepNumberComponent,

    tooltipStyle: [
      {
        paddingBottom: 15,
        borderRadius: 10,
        alignSelf: 'center',
        minWidth: Dimensions.get('window').width / 1.3,
      },
      tooltipFullWidth && { width: WIDTH, maxWidth: WIDTH, left: MARGIN },
    ],
    svgMaskPath: (props) => CustomSvgMaskPath({ ...props, ...svgMaskProps }),
    arrowColor: isArrowVisible ? '#ffffff' : 'transparent',
  })(React.memo(ReactComponent));
};

export default withHoc;
