import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';

/* Styles */
import { LgButton, CheckBox, CheckedBox } from '@components/Button';
import { boxCard, loanWrapper, cardFee, table, checkBoxStyle } from '@style/Layouts';

import { SvgUri } from 'react-native-svg';
import Terms from '@screens/Terms';
import { TouchableOpacity } from 'react-native';
import { loanCalc, loanConfirm } from '@services/LoanService';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setAcceptedTerms, setLoanTerms, setShowTerms } from '@redux/action/Common';
import { useNavigation } from '@react-navigation/native';

const LoanDashboard = ({ dataLoan }) => {
  const navigation = useNavigation();
  const [loanId, setLoanId] = useState(0);
  const [tenureId, setTenureId] = useState(0);
  const [tenureIndex, setTenureIndex] = useState(0);
  const [loanSize, setLoanSize] = useState([
    '500.000',
    '1.000.000',
    '1.500.000',
    '2.000.000',
    '2.500.000',
    '3.000.000',
  ]);
  const [tenure, setTenure] = useState([]);
  const [header, setHeader] = useState(['Cicilan', 'Tanggal Pembayaran', 'Jumlah Tagihan']);
  const [body, setBody] = useState(['-', '-', '-']);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const stateLoan = useSelector((state) => state.Loan);
  const common = useSelector((state) => state.Common);

  useEffect(() => {
    dispatch(setLoanTerms(false));
  }, []);

  const chooseAmount = async (val) => {
    setTenure(val.tenures);
    setLoanId(val.id);
    if (val.tenures[tenureIndex] !== undefined) {
      chooseTenor(val.tenures[tenureIndex], tenureIndex);
    }
  };
  const chooseTenor = async (val, index) => {
    dispatch(setLoanTerms(false));
    setTenureIndex(index);
    setTenureId(val.id);
    setLoading(true);
    try {
      await dispatch(loanCalc({ loanTenureId: val.id }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const confirmationLoan = async () => {
    setLoading(true);
    try {
      let data = {
        employerLoanTenureId: tenureId,
        notes: 'confirmation',
        timeZone: 7,
      };
      await dispatch(loanConfirm(data));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (common.confirmed) {
      navigation.navigate('SettlementLoan', {
        timeTaken: '24 jam',
        productName: stateLoan.amountReceived,
        category: `Pinjaman`,
      });
    }
  }, [common.confirmed]);

  return (
    <>
      {common.showTerms ? (
        <Terms />
      ) : (
        <>
          <View style={boxCard.body}>
            <Text style={boxCard.title}>Pilih jumlah pinjaman:</Text>
            <View style={[loanWrapper.wrapperAmount, { flexWrap: 'wrap' }]}>
              {Object.keys(dataLoan).length == 0 && (
                <>
                  <FlatList
                    data={loanSize}
                    renderItem={(item) => {
                      return (
                        <View
                          key={item.index}
                          style={[loanWrapper.contentLoan, { backgroundColor: '#fff', marginHorizontal: 4 }]}
                        >
                          <View>
                            <Text style={[boxCard.text, { color: '#F56B57' }]}>{item.item}</Text>
                          </View>
                        </View>
                      );
                    }}
                    numColumns={3}
                  />
                </>
              )}
              <FlatList
                data={dataLoan.loan && dataLoan.loan}
                renderItem={(item) => {
                  return (
                    <View
                      key={item.index}
                      style={[
                        loanWrapper.contentLoan,
                        { backgroundColor: loanId == item.item.id ? '#F56B57' : '#fff', marginHorizontal: 4 },
                      ]}
                    >
                      <TouchableOpacity onPress={() => chooseAmount(item.item)}>
                        <Text style={[boxCard.text, { color: loanId == item.item.id ? '#fff' : '#F56B57' }]}>
                          {item.item.requestedAmount &&
                            item.item.requestedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  );
                }}
                numColumns={3}
              />
            </View>
            {tenure.length != 0 && <Text style={boxCard.title}>Pilih jangka waktu pinjaman:</Text>}
            <View style={[loanWrapper.wrapperAmount, { flexWrap: 'wrap' }]}>
              <FlatList
                data={tenure}
                renderItem={(item) => {
                  return (
                    <View
                      key={item.index}
                      style={[
                        loanWrapper.contentLoan2,
                        { backgroundColor: tenureId == item.item.id ? '#F56B57' : '#fff', marginHorizontal: 4 },
                      ]}
                    >
                      <TouchableOpacity onPress={() => chooseTenor(item.item, item.index)}>
                        <Text style={[boxCard.text, { color: tenureId == item.item.id ? '#fff' : '#F56B57' }]}>
                          {item.item.tenure}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  );
                }}
                numColumns={3}
              />
            </View>
          </View>
          {loading ? (
            <View style={styles.loading}>
              <Text style={styles.loadingText}>Loading...</Text>
            </View>
          ) : (
            <>
              <View style={cardFee.container}>
                <View style={[cardFee.wrapper, { marginRight: 10 }]}>
                  <View style={cardFee.flex}>
                    <View>
                      <Text style={cardFee.text}>Biaya Admin: </Text>
                      <Text style={cardFee.text2}>
                        {`Rp. ${stateLoan.adminFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}{' '}
                      </Text>
                    </View>
                    <SvgUri uri="https://storage.googleapis.com/gajitim-assets/biaya-admin.svg" />
                  </View>
                </View>
                <View style={[cardFee.wrapper, { backgroundColor: '#E5F2FF' }]}>
                  <View style={cardFee.flex}>
                    <View>
                      <Text style={[cardFee.text, { color: '#4484CE' }]}>Jumlah Diterima: </Text>
                      <Text style={[cardFee.text2, { color: '#4484CE' }]}>
                        {`Rp. ${stateLoan.amountReceived.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}{' '}
                      </Text>
                    </View>
                    <SvgUri uri="https://storage.googleapis.com/gajitim-assets/amount-loan.svg" />
                  </View>
                </View>
              </View>
              <View style={table.box}>
                <FlatList
                  data={header}
                  columnWrapperStyle={table.header}
                  renderItem={(item) => {
                    return (
                      <Text key={item.index} style={table.text}>
                        {item.item}
                      </Text>
                    );
                  }}
                  numColumns={3}
                />
                {Object.keys(stateLoan.loanCalculate).length == 0 && (
                  <FlatList
                    data={body}
                    columnWrapperStyle={table.body}
                    renderItem={(item) => {
                      return (
                        <Text key={item.index} style={table.text}>
                          {item.item}
                        </Text>
                      );
                    }}
                    numColumns={3}
                  />
                )}
                <View style={{ flexDirection: 'column' }}>
                  {stateLoan.loanCalculate.installments &&
                    stateLoan.loanCalculate.installments.map((row, index) => {
                      return (
                        <>
                          <View
                            key={index}
                            style={[styles.installments, { backgroundColor: index % 2 === 0 ? '#EBE7FF' : '#F1EEFF' }]}
                          >
                            <Text style={{ ...table.column, flex: 0.3 }}>{row.id}</Text>
                            <Text style={{ ...table.column, flex: 0.5 }}>
                              {moment(row.paymentDate).format('YYYY-MM-DD')}
                            </Text>
                            <Text style={{ ...table.column, flex: 0.3 }}>
                              {row.paymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            </Text>
                          </View>
                        </>
                      );
                    })}
                </View>
              </View>
              {Object.keys(stateLoan.loanCalculate).length != 0 && (
                <>
                  <View style={checkBoxStyle.wrapper}>
                    {!common.acceptedTerms ? (
                      <CheckBox onPress={() => dispatch(setShowTerms())} />
                    ) : (
                      <CheckedBox onPress={() => dispatch(setAcceptedTerms())} />
                    )}
                    <Text onPress={() => dispatch(setShowTerms())} style={checkBoxStyle.text}>
                      Saya menyetujui syarat & ketentuan yang berlaku
                    </Text>
                  </View>
                  <LgButton
                    onPress={() => confirmationLoan()}
                    style={{ ...checkBoxStyle.button, backgroundColor: common.acceptedTerms ? '#F56B57' : '#Fab5ab' }}
                    disabled={common.acceptedTerms ? false : true}
                    title="Konfirmasi"
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  loading: { marginVertical: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  loadingText: { color: '#F56B57', fontSize: 30 },
  installments: { flexDirection: 'row', padding: 10 },
});

export default LoanDashboard;
