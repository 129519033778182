import { StyleSheet } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";

export const styles = StyleSheet.create({

    container:{
        paddingBottom:15,
        backgroundColor:colors.blue,
        width:'100%'
    },
    itemContainer:{
        backgroundColor:colors.white,
  
        borderRadius:15,
        overflow:'hidden'
    },
    rowContainer:{
        flexDirection:'row',
        alignItems:'center'
    },
    title:{
        marginStart:10,
        color:colors.grey
    }

})