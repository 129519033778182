import React, { useEffect } from 'react';
import { Text, View, Linking, Dimensions, Image, StyleSheet } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

import { AuthContext } from '../context/AuthContext';

/* Styles */
import { centerView, mdLogo } from '@style/Layouts';
import { LgButton } from '@components/Button';
import { LgCard, background } from '@style/Themes';
import { normalize, normalizeHeight } from '@style/helper';
const { width: screenWidth } = Dimensions.get('window');
import MIcon from '@expo/vector-icons/MaterialIcons';

/* Redux */
import { useSelector } from 'react-redux';

/* Services */
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const Bantuan = () => {
  const isFocused = useIsFocused();
  const { phoneNumber } = useSelector((state) => state.Authentication);

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.NavBar.helpClicked.name, {
      [AMPLITUDE_CONSTANTS.NavBar.helpClicked.attributes.source]: 'others_menu',
    });
  }, [isFocused]);

  return (
    <>
      <View style={[styles.container, centerView]}>
        <View style={{ ...background, bottom: '97%' }}></View>
        <View style={[styles.card, LgCard]}>
          <Text style={styles.question}>Ada Pertanyaan?</Text>
          <Text style={styles.siap}>
            Kami siap membantu Anda kapanpun jika Anda memiliki pertanyaan atau menemukan masalah pada aplikasi GajiGesa
          </Text>
          <Image source={require('@assets/chat_icon.png')} style={mdLogo} />
          <Text style={styles.contact}>
            Bicara lewat Whatsapp dengan GajiGesa{' '}
            <MIcon name="verified" style={{ color: '#25D366', fontSize: normalize(14) }} />
          </Text>
          <LgButton
            testId={'supportButton'}
            style={{ alignSelf: 'center' }}
            title="Hubungi Kami"
            onPress={() => {
              AmplitudeHelper.logEvent('userClickedHubungi', { phone_number: phoneNumber });
              Linking.openURL('https://api.whatsapp.com/send?&phone=6281315276948');
            }}
          />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { justifyContent: 'space-evenly', minWidth: screenWidth * 0.9, top: '27%' },
  card: { paddingTop: normalizeHeight(20), paddingHorizontal: normalize(15) },
  question: {
    color: '#3863f0',
    fontSize: normalize(17),
    fontFamily: 'Nunito-Bold',
    marginBottom: normalizeHeight(10),
  },
  siap: {
    fontSize: normalize(14),
    fontFamily: 'Nunito-Regular',
    marginBottom: normalizeHeight(15),
  },
  contact: {
    fontSize: normalize(14),
    fontFamily: 'Nunito-Bold',
    marginBottom: normalizeHeight(15),
    alignSelf: 'center',
  },
});

export default Bantuan;
