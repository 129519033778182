import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '@style/colors';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import LoadingButton from '@atoms/LoadingButton';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';

const ConfirmModal = ({ isVisible, onPressModal, onPressApply, onPressNo }) => {
  return (
    <MyModal isVisible={isVisible} onPress={onPressModal} containerStyle={{ margin: 0 }}>
      <View style={styles.container}>
        {SVG_ICONS.question}
        <MyText customStyle={styles.title}>{'Jadi pakai kode?'}</MyText>

        <LoadingButton container={styles.button} buttonColor={colors.orange} text="IYA, JADI" onPress={onPressApply} />

        <LoadingButton
          container={styles.button}
          buttonColor={'#FFDBCF'}
          text="TIDAK"
          textColor={colors.orange}
          onPress={onPressNo}
        />
      </View>
    </MyModal>
  );
};

const styles = StyleSheet.create({
  button: { borderRadius: 30, marginVertical: 12, width: '70%' },
  container: {
    backgroundColor: colors.white,
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 25,
    borderRadius: 20,
  },
  title: { textAlign: 'center', marginVertical: 20 },
});

export default ConfirmModal;
