import React, { useState, useEffect, useMemo } from 'react';
import { View, Linking } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import {
  masker,
  returnBottomSheetContent,
  removeNonNumeric,
  findAccountByName,
  numberWithDots,
  getUserBanksFromQuery,
} from '@utils/UtilFunctions';
import { batch, useDispatch, useSelector } from 'react-redux';
import ProfileInfo from '@atoms/Profile/ProfileInfo';
import PoinBadge from '@atoms/Profile/PoinBadge';
import NoticeBar from '@atoms/Profile/NoticeBar';
import InfoRow from '@atoms/Profile/InfoRow';
import ReferralBanner from '@atoms/Profile/ReferralBanner';
import ProfileSection from '@atoms/Profile/ProfileSection';
import AppVersion from '@atoms/Profile/AppVersion';
import LoadingButton from '@atoms/LoadingButton';
import LoginInfoSheet from '@components/LoginInfoSheet';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import Constants from 'expo-constants';
import {
  FEATURE_FLAGS,
  LOGIN_INFO_SHEET_CONTENT,
  LOGIN_INFO_SHEET_KEYS,
  COMMON_CONSTANTS,
  DYNAMIC_ACCOUNT_NAMES,
} from '@utils/Constants';
import Divider from '@atoms/Divider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AmplitudeHelper from '@services/amplitude';
import logoutCallBack from '@utils/AuthenticationUtils';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { clearReferral } from '@redux/action/Referral';
import { clearDynamicAccountReducer } from '@redux/action/DynamicAccounts';
import { autoLogout } from '@redux/action/Authentication';
import { logoutApi } from '@services/AuthService';
import { setSeenProfile } from '@redux/action/Common';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import DefaultBankCard from '@atoms/BankCards/DefaultBankCard/';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ALL_BANKS } from '@gqlQuery/BankAccount';
import { setUserBankData } from '@redux/action/UserBanks';
import MIcon from '@expo/vector-icons/MaterialCommunityIcons';
import styles from './style';

const ProfileScreen = () => {
  const navigation = useNavigation();
  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_EVENT_SOURCES.others_page);
  }, []);

  const dispatch = useDispatch();
  const { phoneNumber, userName, employeeId } = useSelector((state) => state.Authentication);
  const { defaultBankAccount, userBanks, commonUserReadStatus } = useSelector((state) => state.UserBank);
  const { seenProfile } = useSelector((state) => state.Common);
  const { dataAccounts } = useSelector((state) => state.DynamicAccounts);

  const [loading, setLoading] = useState(false);

  const coinBalance = useMemo(
    () =>
      numberWithDots(removeNonNumeric(findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.POINTS)?.amount ?? '0')),
    [dataAccounts],
  );

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.LOGOUT,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.LOGOUT],
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.LOGOUT,
  });

  const { isTransacted } = useSelector((state) => state.Referral);

  const isFocused = useIsFocused();

  const [getUserBanks] = useLazyQuery(GET_USER_ALL_BANKS, {
    onCompleted: (data) => {
      if (data.gg_employee.length > 0) {
        const dispatchData = getUserBanksFromQuery(data);
        dispatch(setUserBankData(dispatchData.defaultBank, dispatchData.userBanks, dispatchData.commonUserReadStatus));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const initiateProfileAppTour = () => {
    if (isTransacted && !seenProfile) {
      AsyncStorage.setItem('@seenProfile', JSON.stringify(true));
      dispatch(setSeenProfile(true));
    }
  };

  useEffect(() => {
    if (isFocused) initiateProfileAppTour();
  }, [isFocused]);

  const onCloseLogoutModal = (type) => {
    const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_cancelled_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
      [e.attributes.button]: type,
    });
    setInfoModal((prevState) => ({ ...prevState, visible: false }));
  };

  const onOpenLogoutModal = () => {
    const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_clicked;
    AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page });

    setInfoModal((prevState) => ({ ...prevState, visible: true }));
  };

  const [isFeatureAvailale] = useFeatureFlag({
    featureName: [
      FEATURE_FLAGS.REFERRAL,
      FEATURE_FLAGS.MUL_BANK_ACC,
      FEATURE_FLAGS.PHONE_ENROLL,
      FEATURE_FLAGS.SALARY_INFO,
    ],
  });

  useEffect(() => {
    if (isFeatureAvailale[FEATURE_FLAGS.MUL_BANK_ACC]?.show) getUserBanks();
  }, [isFocused]);

  const logoutHandler = async () => {
    setLoading(true);
    setInfoModal((prevState) => ({ ...prevState, visible: false }));
    const e = AMPLITUDE_CONSTANTS.ProfilePage.logout_confirm_clicked;
    AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page });
    await logoutApi();
    setLoading(false);
    batch(() => {
      dispatch(clearReferral());
      dispatch(clearDynamicAccountReducer());
      dispatch(autoLogout(logoutCallBack));
    });
  };

  const onSupportPressHandler = () => {
    const e = AMPLITUDE_CONSTANTS.NavBar.helpClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
    Linking.openURL(COMMON_CONSTANTS.support);
  };

  const onNavigatToPoin = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.PoinClicked.name, {
      [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.employee_id]: employeeId,
      [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.poin_balance]: coinBalance,
      [AMPLITUDE_CONSTANTS.Poin.PoinClicked.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
    navigation.navigate('TransactionList', {
      screen: 'Points',
      params: { source: AMPLITUDE_EVENT_SOURCES.others_page },
    });
  };

  const onReferralCta = () => {
    const e = AMPLITUDE_CONSTANTS.ProfilePage.referral_banner_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
    navigation.navigate('TransactionList', {
      screen: 'Referral',
      params: { source: AMPLITUDE_EVENT_SOURCES.others_page },
    });
  };

  const maskedPHoneNo = useMemo(() => masker(phoneNumber), [phoneNumber]);

  const hasPointsAccount = useMemo(() => findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.POINTS), [dataAccounts]);

  const onPhoneEnrollhandler = () => {
    const e = AMPLITUDE_CONSTANTS.PhoneEnroll.phone_list_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
    navigation.navigate('PhoneEnrollment', {
      screen: 'PhoneEnrollmentList',
      params: { source: AMPLITUDE_EVENT_SOURCES.others_page, headerTitle: 'Nomor HP Tersimpan' },
    });
  };

  const onPressSalaryInfoHandler = () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.salary_info_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
    navigation.navigate('SalaryInfoStack', {
      screen: 'SalaryInfo',
      params: { source: AMPLITUDE_EVENT_SOURCES.others_page, headerTitle: 'Informasi Gaji' },
    });
  };

  const renderIcon = () => <MIcon name="wallet" size={20} color={colors.secondary_orchid} />;

  return (
    <MainParentWrapper isRounded isScrollable loading={false} containerStyle={styles.upperContainerStyle}>
      <View style={styles.infoContainer}>
        <ProfileInfo
          userName={userName}
          phoneNumber={maskedPHoneNo}
          profileAvatar={SVG_ICONS.profilePage.profileAvatar}
        />
        <PoinBadge
          poinAmount={coinBalance}
          onPress={onNavigatToPoin}
          icon={SVG_ICONS.profilePage.coin}
          showBadge={!!hasPointsAccount}
        />
      </View>
      <NoticeBar />

      {!!defaultBankAccount?.bankAccountNo && isFeatureAvailale[FEATURE_FLAGS.MUL_BANK_ACC]?.show && (
        <DefaultBankCard
          defaultAccount={defaultBankAccount}
          hasMoreBanks={userBanks.length > 0}
          showRedDot={!commonUserReadStatus}
          onPressCreateBankAccount={() => {
            const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
            });
            navigation.navigate('BankStack', {
              screen: 'BankCreateDetail',
              params: { source: AMPLITUDE_EVENT_SOURCES.add_bank_account_page },
            });
          }}
          onPressMoreBanks={() => {
            const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_page_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
            });
            navigation.navigate('BankStack', {
              screen: 'BankList',
              params: { source: AMPLITUDE_EVENT_SOURCES.others_page },
            });
          }}
        />
      )}

      <Divider
        horizontal
        customStyle={styles.dividerStyle}
        showDivider={
          isFeatureAvailale[FEATURE_FLAGS.GAMIFICATION]?.show && currentTier.length > 0 && tierMissions.length > 0
        }
      />

      <ProfileSection title="Informasi" showSection={isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL]?.show}>
        <InfoRow
          icon={SVG_ICONS.phoneEnroll.phoneIcon}
          title="Daftar Nomor HP"
          onPress={onPhoneEnrollhandler}
          iconBgColor={colors.secondary_softOrchid}
          iconSvgStyle={styles.iconIphne}
          show={isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL]?.show}
        />
        <InfoRow
          icon={renderIcon}
          title="Informasi Gaji"
          onPress={onPressSalaryInfoHandler}
          iconBgColor={colors.secondary_softOrchid}
          iconSvgStyle={styles.iconSVGStyle}
          show={isFeatureAvailale[FEATURE_FLAGS.SALARY_INFO]?.show}
        />
      </ProfileSection>

      <Divider
        horizontal
        customStyle={styles.dividerStyle}
        showDivider={isFeatureAvailale[FEATURE_FLAGS.PHONE_ENROLL]?.show}
      />

      <ProfileSection title="Ajak Teman" showSection={isFeatureAvailale[FEATURE_FLAGS.REFERRAL]?.show}>
        <ReferralBanner
          confettiScrapesBg={SVG_ICONS.profilePage.confettiScrapes}
          confettiGiftIcon={SVG_ICONS.profilePage.referralGift}
          onPress={onReferralCta}
          giftStyle={styles.giftStyle}
        />
      </ProfileSection>

      <Divider
        horizontal
        customStyle={styles.dividerStyle}
        showDivider={isFeatureAvailale[FEATURE_FLAGS.REFERRAL]?.show}
      />

      <ProfileSection title="Bantuan">
        <InfoRow
          icon={SVG_ICONS.profilePage.waBantuan}
          title="Layanan Pelanggan"
          onPress={onSupportPressHandler}
          iconBgColor={colors.variants_softClover}
        />
      </ProfileSection>

      <Divider horizontal customStyle={styles.dividerStyle} />

      <LoadingButton
        onPress={onOpenLogoutModal}
        bordered
        disabled={loading}
        loading={loading}
        text="Keluar Aplikasi"
        loadingColor={colors.error_rose}
        textStyle={styles.logoutText}
        textTransform="capitalize"
        container={styles.logoutContainer}
      />

      <AppVersion version={Constants.manifest.version} />

      <LoginInfoSheet
        onPressPrimary={logoutHandler}
        onPressSecondary={() => onCloseLogoutModal('cancel')}
        loginInfo={infoModal}
        hasTwoButtons
        onClose={() => onCloseLogoutModal('close')}
      />
    </MainParentWrapper>
  );
};

ProfileScreen.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};
export default ProfileScreen;
