import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 0.7,
    borderColor: colors.gainsboro,
    justifyContent: 'center',
  },
  textStyle: {
    color: colors.black,
  },
  pressableStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    borderColor: colors.gainsboro,
  },
});
