import React, { useState } from 'react';
import { Text, View, Dimensions, StyleSheet } from 'react-native';
import AmplitudeHelper from '@services/amplitude';

/* Styles */
import { LgButton } from '@components/Button';
import { centerView, subTitleText } from '@style/Layouts';
import { background, XlCard } from '@style/Themes';
const { height: screenHeight } = Dimensions.get('window');
import { normalize, normalizeHeight } from '@style/helper';
import { SvgUri } from 'react-native-svg';

/* Screens */
import Loading from '@screens/Loading';

/* Redux */
import { useDispatch, batch } from 'react-redux';
import { setCanContinue } from '@redux/action/Common';
import { updateProduct } from '@redux/action/BillPayments';
import { setInquiry, resetTransaction } from '@redux/action/Transactions';
import { getDashboardDetailApi } from '@services/DashboardService';
import { setPageFailed } from '@redux/action/Common';

const PageFailed = ({ route, navigation }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  AmplitudeHelper.logEvent('transactionFailed');

  const handlePress = () => {
    //AmplitudeHelper.logEvent('userLeavesSelamat')
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      batch(() => {
        dispatch(setCanContinue(false));
        dispatch(updateProduct({}));
        dispatch(setInquiry({}));
        dispatch(resetTransaction());
        dispatch(getDashboardDetailApi());
        dispatch(setPageFailed(''));
      });
      navigation.popToTop();
    }, 2000);
  };

  return (
    <>
      <View style={[centerView, background]}>
        <View style={[styles.container, XlCard]}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <View style={styles.section}>
                <View style={styles.icon}>
                  <SvgUri uri="https://storage.googleapis.com/gajigesa-public/icons/failed.svg" />
                </View>
                <Text style={[styles.error, subTitleText]}>
                  Mohon maaf telah terjadi kesalahan sistem. Silakan mencoba bertransaksi kembali.
                </Text>
              </View>
              <LgButton title="OK" onPress={handlePress} />
            </>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: 'center', height: normalizeHeight(screenHeight / 1.2) },
  section: { maxWidth: '85%', flex: 1, alignItems: 'center', marginLeft: '-7%' },
  icon: {
    flex: 0.5,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    marginLeft: normalize(25),
    marginTop: normalizeHeight(20),
  },
  error: {
    color: 'black',
    marginLeft: '10%',
    fontSize: normalize(14),
    textAlign: 'center',
    minWidth: '100%',
  },
});

export default PageFailed;
