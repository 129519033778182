import React, { useState, createContext, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AuthService from '@services/AuthService'
import AmplitudeHelper from '@services/amplitude'
import {setDefaultParams, axiosInstance} from '@services/api'
import { useDispatch } from 'react-redux'
import { respError } from '@redux/action/Common'
import { validate, flattenObject } from '@utils/UtilFunctions'

export const AuthContext = createContext()

const AuthContextProvider = props => {
  const [userToken, setUserToken] = useState(false)
  const [authToken, setAuthToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [loginToken, setLoginToken] = useState('')
  const [userName, setUserName] = useState('user')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOtp] = useState(0)
  const [loading, setLoading] = useState(false)

  // COMMON REDUCER
  const [error, setError] = useState('') // this could also be a string/object and be dynamic
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(true)

  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [roleId, setRoleId] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    if(authToken == ''){
      getToken()
    }    
  }, [])

  /* Gets the token from the asyncstorage */
  const getToken = async () => {
    try {
      const token = await AsyncStorage.getItem('@token')
      const phone = await AsyncStorage.getItem('@phoneNumber')
      const user = await AsyncStorage.getItem('@userName')
      if(token !== null){
        setAuthToken(token)
        setPhoneNumber(phone)
        setUserName(user)
      }
    } catch(e) {
      // do nothing
    }
  } 

  /* Stores the token into the asyncstorage */
  const storeToken = async value => {
    try {
      if(value == null){
        await AsyncStorage.removeItem('@token')
        await AsyncStorage.removeItem('@phoneNumber')
        await AsyncStorage.removeItem('@userName')
      } else {
        await AsyncStorage.setItem('@token', value.accessToken)
        await AsyncStorage.setItem('@refreshToken', value.refreshToken)
        await AsyncStorage.setItem('@phoneNumber', phoneNumber)
      }
    } catch(e) {
      // do nothing
    }
  }

  /* Gets the user name from profile endpoint */
  const getUserName = async () => {
    let resp = await AuthService.getProfile()
    AmplitudeHelper.setUserId(resp.data.data.employees[0].id, resp.data.data.employees[0].employerId)
    setUserName(resp.data.data.firstName)
    
    AsyncStorage.setItem('@userName', resp.data.data.firstName)

    return flattenObject(resp.data.data)
  }

  /* request OTP function */
  const handlePhoneSubmit = async(type) => {
    //setLoading(true)
    AsyncStorage.setItem('@phoneNumber', phoneNumber)

    let errResponse = validate('phone', phoneNumber)
    if(errResponse != ''){
      setLoading(false)
      dispatch(respError(errResponse))
      return
    }
    try {
      const defaultParams = await setDefaultParams()
      axiosInstance.defaults.baseURL = defaultParams.baseURL
      axiosInstance.defaults.headers = defaultParams.headers
      let resp = await AuthService.login({mobileNumber: phoneNumber, messagingType: type})
      if(resp.status == 200 || resp.status == 201) {
        setLoginToken(resp.data.data.token)
        setLoading(false)
        AmplitudeHelper.logEvent('loginSuccess')
        setIsError(false)
        setIsLoggedOut(false)   
        } else {
        setLoading(false)
        AmplitudeHelper.logEvent('loginFail', {phone_no: phoneNumber, error_response: resp.response ? resp.response.data.message : resp.data.error ? resp.data.error : resp.data.message})
      
        dispatch(respError(resp.response ? resp.response.data.message : resp.data.error ? resp.data.error : resp.data.message))
        } 
    } catch(error){
      if(error.response){
        setLoading(false)
        if(error.response.status == 401){
        
          updateError(error.response.data.detail)
        } else if(error.response.status == 500) {
       
          updateError(error.response.data.detail)
        } else {
          updateError(error.response.data.message)
        }
      } else {
        setLoading(false)
        updateError("Tidak dapat menhubungkan anda dengan server kami.")
      }
    }
    }


  /* confirm OTP function */
    const signIn = async () => {
        let errResponse = validate('otp', otp)
        if(errResponse != ''){
          setLoading(false)
          // updateError(errResponse)
          dispatch(respError(errResponse))
          return
        }
        try {
          let resp = await AuthService.otp({token: loginToken, otp: otp})
          if(resp.status == 200 || resp.status == 201) {
            setLoading(false)
            //AmplitudeHelper.setUserId(phoneNumber)
            //setUserName(resp.data.data.employees_first_name)
            storeToken(resp.data.data)
            const defaultParams = await setDefaultParams()
            axiosInstance.defaults.baseURL = defaultParams.baseURL
            axiosInstance.defaults.headers = defaultParams.headers
            setAuthToken(resp.data.data.accessToken)
            setRefreshToken(resp.data.data.refreshToken)
            let userInfo = await getUserName()
            AmplitudeHelper.logEvent('otpSuccess', userInfo)
            setUserToken(true)
          } else {
            if(resp.response.status == 401){
              // updateError('Kode yang anda masukkan tidak valid')
              dispatch(respError('Kode yang anda masukkan tidak valid'))
            }
            setLoading(false)
            // AmplitudeHelper.logEvent('otpFail', {phone_number: phoneNumber, error_response: resp.response ? resp.response : resp.data})
            AmplitudeHelper.logEvent('otpFail', {phone_number: phoneNumber, error_response: resp.response ? resp.response.data.message : resp.data.error ? resp.data.error : resp.data.message})
            // updateError(resp.response.data.message)
            dispatch(respError(resp.response.data.message))
            setIsError(false)
            return
          }
        } catch(error){
            setLoading(false)
           
            if(error.response){
              //updateError(error.response.errorMessage);
            } else {
              updateError("Server is not responding");
            }
        }
    }


    const signOut = async () => {
      setLoading(true)
      setUserToken(false)
      setAuthToken(null)
      storeToken(null)
      setUserName('')
      setPhoneNumber('')
      setOtp('')
      await AsyncStorage.removeItem('@token')
      await AsyncStorage.removeItem('@refreshToken')
      await AsyncStorage.clear()
      setIsLoggedOut(true)
      setIsError(true)
      setTimeout(() => {setLoading(false)}, 1000)
    }

    const kickMe = () => {
      setLoading(true)
      setUserToken(false)
      setIsLoggedOut(true)
      setIsError(true)
      setTimeout(() => {setLoading(false)}, 1000)
    }

    const handlePhoneInput = value => {
      setPhoneNumber(value)
    }

    const handleOtpInput = value => {
      let int = parseInt(value)
      setOtp(int)
    }

    const updateError = msg => {
      setError(msg)
    }

    const updateMessage = msg => {
      setMessage(msg)
    }

    const resetIsError = () => {
      setIsError(true)
    }

  //Resent OTP function 
    const resendOtp = () => {
      /*
      set a counter
      check if count equals 2
      if it does then set an alert, if okay clicked then it opens a whatsapp URL
      if not then AuthService.login() again
        if response status, then set a toast "Otp Berhasil Dikirim"
        else set a true error and set the toast to the error message
      catch any of the following errors:
        if error.response.data set a toast with the error message
        if error.response.status is 401, set a toast with the error message and navigate to Login
        if error.response.status is 500, set a toast with the error message
        else set toast to server is not responding
      */
      setClickCount(clickCount + 1)
      if(clickCount == 2){
        setShowAlert(true)
        return
      } else {
        //setLoading(true)
        AmplitudeHelper.logEvent('resendOtp', {phone_number: phoneNumber})
        AuthService.reSend({token: loginToken}).then(resp => {
          if(resp.status == 200 || resp.status == 201){
            //setLoading(false)
            setLoginToken(resp.data.data.token)
            updateMessage('Otp berhasil dikirim.')
          } else {
            //setLoading(false)
            updateError(resp.response ? resp.response.data.message : resp.data.message)
          }
        }).catch(error => {
          if(error.response) {
            if(error.response.data) {
              //setLoading(false)
       
              updateError(error.response.data.errorMessage)
            }
            if(error.response.status == 401) {
              setIsLoggedOut(true)
   
              updateError(error.response.data.detail)
            }
          } else {
            //setLoading(false)
            updateError("Server tidak merespon")
          }
        })
      }
    }


  /* NOT NEEDED */
  const resetClickCount = () => {
    setClickCount(0)
  }

  const resetShowAlert = () => {
    setShowAlert(false) 
  }

  return (
    <AuthContext.Provider value={{ 
      userToken, 
      authToken,
      phoneNumber,
      otp,
      loading,
      updateLoading: val => setLoading(val),
      error,
      message,
      updateMessage,
      isError,
      resetIsError,
      isLoggedOut,
      handlePhoneSubmit,
      signIn, 
      signOut, 
      handlePhoneInput,
      handleOtpInput,
      updateError,
      resendOtp,
      clickCount,
      resetClickCount,
      resetShowAlert,
      showAlert,
      storeToken,
      getToken,
      kickMe,
      userName,
      roleId,
      updateRoleId: val => setRoleId,
      }}>
      {props.children}
    </AuthContext.Provider>
  ) 
}

export default AuthContextProvider
