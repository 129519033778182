import React from 'react';
import { View } from 'react-native';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import styles from './style';
import MyText from '@atoms/MyText';
import ImageButton from '@atoms/ImageButton';
import Add from '@expo/vector-icons/AntDesign';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
export const getTitle = (selectedBank) => {
  if (selectedBank) return 'Rekening Tujuan';
  return 'Nama Rekening';
};

export const getPlaceholder = (selectedBank) => {
  if (selectedBank) return null;
  return 'Pilih Rekening';
};

export const emptyBankUi = (onPress) => {
  return (
    <View style={styles.bankUiContainer}>
      {SVG_ICONS.bottomSheet.unregistered}
      <MyText h4 isRegular={false}>
        Nomor rekening lainnya masih kosong
      </MyText>
      <MyText customStyle={{ textAlign: 'center', marginTop: 5 }}>
        Gunakan fitur ini untuk transfer dana ke rekening lain yang terdaftar.
      </MyText>
      <ImageButton
        onPress={onPress}
        pressableStyle={styles.pressableStyle}
        styleContainer={styles.imageButton}
        textStyle={styles.buttonStyle}
        text={'Tambah Rekening'}
        iconRight={<Add name="plus" color={colors.black} size={20} />}
      />
    </View>
  );
};
