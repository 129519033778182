import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

const styles = StyleSheet.create({
  savingsArrow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 15,
    paddingVertical: 5,
    marginTop: 10,
  },
  lockPeriodBannerTextStyle: { fontSize: 13, marginLeft: 10 },
  lockPeriodBannerIconContainerStyle: { justifyContent: 'center' },
  lockperiodBannerContainerStyle: { marginBottom: -2, backgroundColor: colors.error_softRose },
});

export default styles;
