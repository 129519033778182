import React, { useEffect, useRef, useState } from 'react';
import { Alert, Dimensions, Text, View, TouchableOpacity, StyleSheet } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import MIcon from '@expo/vector-icons/MaterialIcons';
import Dialog from 'react-native-dialog';
import { Modalize } from 'react-native-modalize';

import AmplitudeHelper from '@services/amplitude';

/* Redux Import */
import { useDispatch, useSelector } from 'react-redux';
import { setCanContinue } from '@redux/action/Common';

/* Style Import */
import { LgButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
const { width: screenWidth } = Dimensions.get('window');

/* Component Import */
import ReasonsGrid from '@molecules/ReasonsGrid';
import { setReason } from '@redux/action/Transactions';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { Font } from '@style/Fonts';
import styles from './style';
import { hideReasonsModal } from '@redux/action/Modals';
import CloseHeader from '../CloseHeader';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';
import { store } from '@enhancedStore/store';

const Reasons = () => {
  const modalizeRef: any = useRef<Modalize>();
  const reasonsCarousel: any = useRef<Carousel>();

  const dispatch: any = useDispatch();

  const { selectedReason, selectedReasonId } = useSelector((state: any) => state.Transactions);
  const { reasonList: reasons } = useSelector((state: any) => state.Withdrawal);
  const withdrawAmount = useSelector((state: any) => state.Withdrawal.withdrawAmount);
  const { currentHomePageSelectedAccount } = store.getState().DynamicAccounts;
  const { eventSource } = useSelector((state: any) => state.Common);
  const { showReasonsModal } = useSelector((state: any) => state.Modals);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showTextBox, setShowTextBox] = useState(false);

  const { redeemCode } = store.getState().Referral;

  const updateTextBox = (val) => {
    handleClose();
    setShowTextBox(val);
  };

  const handleSelect = (obj) => {
    // return;
    if (obj.tag == 'OTHERS') {
      updateTextBox(true);
    }
    // alert("JSON.stringify(obj,null,2)")
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason]: formatUnderScoreLowerCase(obj?.reason ?? null),
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason_id]: obj?.id ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.source]: formatUnderScoreLowerCase(eventSource ?? null),
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    //AmplitudeHelper.trackEvent('selectedReason', {"reason": obj.reason})
    dispatch(
      setReason({
        reason: obj.reason,
        reasonId: obj.id,
        reasonColor: obj.reasonColor,
      }),
    );
  };

  const handleNoReason = (message) => {
    Alert.alert(message, '', [
      {
        text: 'OK',
        style: 'cancel',
      },
    ]);
  };

  const handleChange = (val) => {
    dispatch(
      setReason({
        reason: `Kebutuhan Lainnya (${val})`,
        reasonId: 18,
        reasonColor: '#4292ed',
        description: val,
      }),
    );
  };

  const handleClose = () => {
    dispatch(hideReasonsModal());
  };

  useEffect(() => {
    if (showReasonsModal) {
      modalizeRef?.current?.open();
      setActiveIndex(0);
    } else {
      modalizeRef?.current?.close();
    }
  }, [showReasonsModal]);

  if (showTextBox) {
    return (
      <Dialog.Container visible={showTextBox}>
        <Dialog.Title>Kebutuhan Lainnya</Dialog.Title>
        <Dialog.Input
          accessibilityLabel="otherInput"
          onChangeText={(val) => handleChange(val)}
          autoFocus={true}
          wrapperStyle={styles.wrapper}
        />
        <Dialog.Button
          accessibilityLabel="otherCancelButton"
          color="#F56B57"
          label="Batalkan"
          onPress={() => {
            const e = AMPLITUDE_CONSTANTS.attendancePaySlip.reasons_description_cancelled;
            AmplitudeHelper.logEvent([e.name], { [e.attributes.source]: 'others_reason_description' });
            setShowTextBox(false);
          }}
        />
        <Dialog.Button
          accessibilityLabel="otherLanjutButton"
          color="#F56B57"
          label="Lanjut"
          onPress={() => {
            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.customReason.name, {
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.reason]: formatUnderScoreLowerCase(
                selectedReason ?? null,
              ),
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.reason_id]: formatUnderScoreLowerCase(
                selectedReasonId ?? null,
              ),
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.source]: formatUnderScoreLowerCase(
                eventSource ?? null,
              ),
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.referral_code]: redeemCode || null,
            });
            setShowTextBox(false);
            // dispatch(resetModalType());
            dispatch(setCanContinue(true));
          }}
        />
      </Dialog.Container>
    );
  }

  const Header = () => (
    <>
      <CloseHeader handleClose={() => dispatch(hideReasonsModal())} />
      <MyText
        isRegular={false}
        customStyle={{
          fontSize: normalize(18),
          lineHeight: normalizeHeight(25),
          fontFamily: Font.Nunito_Bold,
          marginLeft: 25,
          marginTop: normalizeHeight(20),
        }}
      >
        Alasan Penarikan Uang:
      </MyText>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          // alignItems: "center",
          marginVertical: 16,
          marginHorizontal: 20,
        }}
      >
        {activeIndex ? (
          <TouchableOpacity
            onPress={() => {
              // reasonsCarousel.current.snapToPrev();
              setTimeout(() => reasonsCarousel.current.snapToItem(activeIndex - 1), 0);
              setActiveIndex((index) => index - 1);
            }}
            style={styles.leftButton}
          >
            <MIcon name="double-arrow" size={19} color={colors.orange} style={{ transform: [{ rotate: '180deg' }] }} />
            <Text style={styles.buttonText}>Sebelumnya</Text>
          </TouchableOpacity>
        ) : (
          <View />
        )}
        {activeIndex !== reasons.length - 1 ? (
          <TouchableOpacity
            onPress={() => {
              // reasonsCarousel.current.snapToNext();
              setTimeout(() => reasonsCarousel.current.snapToItem(activeIndex + 1), 0);
              setActiveIndex((index) => index + 1);
            }}
            style={styles.rightButton}
          >
            <MIcon name="double-arrow" size={18} color={colors.orange} />
            <Text style={styles.buttonText}>Selanjutnya</Text>
          </TouchableOpacity>
        ) : null}
      </View>
    </>
  );

  const Content = () => (
    <View style={styles.background}>
      <View>
        <Carousel
          ref={reasonsCarousel}
          itemWidth={screenWidth}
          sliderWidth={screenWidth}
          data={reasons}
          renderItem={({ item }) => (
            <ReasonsGrid reasons={item} handleSelect={handleSelect} selectedReason={selectedReason} />
          )}
          inactiveSlideOpacity={1}
          inactiveSlideScale={1}
          onSnapToItem={(index) => {
            // AmplitudeHelper.logEvent("userSwipesLeftOrRight");

            setTimeout(() => setActiveIndex(index), 0);
            // setActiveIndex(index);
          }}
          // enableSnap
          snapOnAndroid
          autoplay={false}
          autoplayInterval={1000000}
          // firstItem={0}
        />
        <Pagination
          dotsLength={reasons.length}
          activeDotIndex={activeIndex}
          containerStyle={styles.pagination}
          dotStyle={{
            backgroundColor: '#3863F0',
            height: 12,
            width: 12,
            borderRadius: 6,
            marginHorizontal: 15,
          }}
          inactiveDotStyle={{
            backgroundColor: '#292929',
            height: 12,
            width: 12,
            borderRadius: 6,
            marginHorizontal: 15,
          }}
          inactiveDotScale={1}
          animatedDuration={0}
          carouselRef={reasonsCarousel}
        />
      </View>
      {/* <View style={styles.lanjutContainer}>
        {selectedReason == "" ? (
          <LgButton
            testId="lanjutButtonDisabled"
            style={{ backgroundColor: "#F89F92" }}
            title="Lanjut"
            onPress={() => handleNoReason("Pilih Alasan")}
          />
        ) : (
          <LgButton
            testId="lanjutButton"
            title="Lanjut"
            onPress={() => {
              AmplitudeHelper.logEvent("applyForWithdrawal", {
                withdraw_amount: withdrawAmount,
                reason: selectedReason,
              });
              handleClose();
              dispatch(setCanContinue(true));
            }}
          />
        )}
      </View> */}
    </View>
  );
  return (
    <Modalize
      onClosed={() => {
        dispatch(hideReasonsModal());
      }}
      HeaderComponent={<Header />}
      ref={modalizeRef}
      rootStyle={{
        elevation: 6,
      }}
      snapPoint={Dimensions.get('screen').height / 1.4}
    >
      <Content />
    </Modalize>
  );
};

export default Reasons;
