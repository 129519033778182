import React from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import { ScrollView } from 'react-native';
import moment from 'moment';
import { numberWithDots } from '@utils/UtilFunctions';

/* Styles */
import { normalize, normalizeHeight } from '@style/helper';

/* Screen Import */
import AmplitudeHelper from '@services/amplitude';
import { useNavigation } from '@react-navigation/native';

const TransactionMonthlyFeed = ({ monthData }) => {
  const navigation = useNavigation();
  return (
    <>
      <View style={styles.tabContainer}>
        <Text style={styles.tabLeft}>{'Bulan & Tahun'}</Text>
        <Text style={styles.tabRight}>Jumlah Penarikan</Text>
      </View>
      <View style={{ width: '100%' }}>
        <View style={{ marginTop: 10, paddingBottom: 20 }}>
          <FlatList
            data={monthData}
            keyExtractor={(item, index) => item.key}
            renderItem={(item) => {
              return (
                <View style={styles.feedRow}>
                  <Text style={{ fontSize: normalize(12) }}>{`${moment(item.item.startdate).format(
                    'DD MMM YYYY',
                  )} - ${moment(item.item.enddate).format('DD MMM YYYY')}`}</Text>
                  <Text style={{ fontSize: 12 }}>{numberWithDots(item.item.total)}</Text>
                  <Text
                    style={styles.lihatDetail}
                    onPress={() => {
                      AmplitudeHelper.logEvent('userClickedLihatDetail', {
                        month: moment(item.item.startdate).format('MMM YYYY'),
                      });
                      navigation.push('Transaksi Detail', {
                        month: moment(item.item.startdate).format('MMM YYYY'),
                        startDate: item.item.startdate,
                        endDate: item.item.enddate,
                      });
                    }}
                  >
                    Lihat Detail
                  </Text>
                </View>
              );
            }}
          />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: 'row',
    marginTop: 20,
    paddingLeft: 20,
    backgroundColor: '#F7F7F7',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    paddingVertical: 5,
  },
  tabLeft: {
    flex: 0.45,
    marginHorizontal: 10,
    alignItems: 'flex-start',
    fontFamily: 'Nunito-Bold',
    color: '#454545',
    fontSize: normalize(14),
  },
  tabRight: {
    flex: 0.35,
    alignItems: 'flex-start',
    fontFamily: 'Nunito-Bold',
    color: '#454545',
    fontSize: normalize(14),
  },
  feedRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingVertical: 10,
    height: normalizeHeight(60),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    height: 50,
  },
  lihatDetail: {
    color: '#F56B57',
    textDecorationLine: 'underline',
    fontSize: 12,
  },
});

export default TransactionMonthlyFeed;
