import React, { useState, useRef, useCallback, useEffect } from 'react';
import OTPInputView from '@twotalltotems/react-native-otp-input';
import { KeyboardAvoidingView, View } from 'react-native';
import MyText from '@atoms/MyText';
import styles from './style';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import ImageButton from '@atoms/ImageButton';
import { checkOtp, newLogin } from '@services/AuthService';
import AmplitudeHelper from '@services/amplitude';
import { formatPhoneNumber, formatUnderScoreLowerCase } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { returnBottomSheetContent } from '@utils/UtilFunctions';
import { ERROR_CODES, LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS, MESSAGGE_TYPE } from '@utils/Constants';
import LoginInfoSheet from '@components/LoginInfoSheet';
import { useDispatch } from 'react-redux';
import LoadingModal from '@components/LoadingModal';
import LoadingButton from '@atoms/LoadingButton';

const Otp = ({ route }) => {
  const [otpNew, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errTxtMsg, setErrTxtMsg] = useState('');
  const [otpToken, setOtpToken] = useState(route?.params?.otpToken);
  const otpRef = useRef(null);
  const [disableOTP, setDisableOTP] = useState(false);
  let { phoneNumber } = route?.params;
  let dispatch = useDispatch();
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.RESEND_OTP],
      phoneNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
  });

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.authentication.otp_page.name, {
      [AMPLITUDE_CONSTANTS.Login.otpPage.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  }, []);

  const loginUser = useCallback(async () => {
    try {
      const e = AMPLITUDE_CONSTANTS.authentication.login_attempted;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      });
      setLoading(true);
      await dispatch(checkOtp(otpNew, phoneNumber, otpToken));
    } catch (error) {
      errorHandler(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.');
      const e = AMPLITUDE_CONSTANTS.authentication.login_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
        [e.attributes.failure_message]: formatUnderScoreLowerCase(
          error.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.',
        ),
      });
    } finally {
      setLoading(false);
    }
  }, [otpNew, loading, otpToken]);

  const onPressOtpButton = useCallback(
    async (messagingType) => {
      try {
        const e = AMPLITUDE_CONSTANTS.authentication.otp_resend_clicked;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
          [e.attributes.source]: formatUnderScoreLowerCase(messagingType),
        });
        setLoading(true);
        let { otpToken } = await newLogin(phoneNumber, messagingType);
        setOtpToken(otpToken);
        clearOTp();
        setInfoModal((prevState) => ({ ...prevState, visible: true }));
        const e1 = AMPLITUDE_CONSTANTS.authentication.otp_successful;
        AmplitudeHelper.logEvent(e1.name, {
          [e1.attributes.phone_number]: formatPhoneNumber(phoneNumber),
        });
      } catch (error) {
        if (error?.response?.data?.error === ERROR_CODES['GG-429']) {
          setDisableOTP(true);
          errorHandler(LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS['GG-429']].otpScreenError);
          const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
          AmplitudeHelper.logEvent(e.name, {
            [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
            [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error['GG-429'],
            [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.otp_page,
          });
        } else {
          errorHandler(error.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.');
          const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
          AmplitudeHelper.logEvent(e.name, {
            [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
            [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error['GG-500'],
            [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.otp_page,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [phoneNumber],
  );
  const onCodeChangedHandler = (code) => {
    setOtp(code);
    errTxtMsg.length > 0 && setErrTxtMsg('');
  };

  const clearOTp = () => {
    errTxtMsg.length > 0 && setErrTxtMsg('');
    setOtp('');
  };
  const errorHandler = (errorMessage) => {
    setErrTxtMsg(errorMessage);
    setOtp('');
  };

  const onCloseModal = () => {
    setInfoModal((prevState) => ({ ...prevState, visible: false }));
  };

  const onCodeFilledHandler = () => {
    const e1 = AMPLITUDE_CONSTANTS.authentication.otp_input;
    AmplitudeHelper.logEvent(e1.name, {
      [e1.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  };

  return (
    <KeyboardAvoidingView behavior={'height'} keyboardVerticalOffset={80} style={styles.otpContainerStyle}>
      <View style={styles.artContainer}>{SVG_ICONS.otp.messageNoti}</View>
      <View style={styles.textContainer}>
        <MyText isRegular customStyle={styles.infoText}>
          Masukkan Kode Verifikasi yang kami kirimkan ke <MyText isRegular={false}>+{phoneNumber}</MyText>
        </MyText>
      </View>
      <OTPInputView
        pinCount={4}
        ref={otpRef}
        code={otpNew}
        style={styles.otpGroup}
        autoFocusOnLoad={false}
        onCodeFilled={onCodeFilledHandler}
        codeInputFieldStyle={[
          styles.otpDefault,
          errTxtMsg.length > 0 && styles.otpFailed,
          disableOTP && styles.otpDisabled,
        ]}
        clearInputs={errTxtMsg.length > 0 || infoModal.visible}
        editable={!disableOTP}
        onCodeChanged={onCodeChangedHandler}
        codeInputHighlightStyle={[styles.otpHightLight, disableOTP && styles.otpDisabled]}
      />
      {errTxtMsg.length > 0 && (
        <View style={styles.errorContainer}>
          <MyText isRegular customStyle={styles.errorTextStyle}>
            {errTxtMsg}
          </MyText>
        </View>
      )}
      <MyText isRegular customStyle={styles.resendOtpStyle} accessibilityLabel={'resendOtpLink'}>
        Tidak terima kode? Kirim ulang ke
      </MyText>
      <View style={styles.reLoginContainer}>
        <ImageButton
          textStyle={[styles.reloginTextStyle, disableOTP && styles.reloginTextDisabledStyle]}
          disabled={disableOTP || loading}
          pressableStyle={styles.reLoginPressableStyle}
          styleContainer={{
            ...styles.reLoginButtonContainer,
            ...styles.reLoginLeftAdjustment,
          }}
          text={'Whatsapp'}
          onPress={() => onPressOtpButton(MESSAGGE_TYPE.WHATSAPP)}
          iconLeft={SVG_ICONS.otp.whatsappOTP}
        />
        <ImageButton
          textStyle={[styles.reloginTextStyle, disableOTP && styles.reloginTextDisabledStyle]}
          disabled={disableOTP || loading}
          pressableStyle={styles.reLoginPressableStyle}
          styleContainer={{
            ...styles.reLoginButtonContainer,
            ...styles.reLoginRightAdjustment,
          }}
          text={'SMS'}
          onPress={() => onPressOtpButton(MESSAGGE_TYPE.SMS)}
          iconLeft={SVG_ICONS.otp.smsOTP}
        />
      </View>
      <LoadingButton
        testId="lanjutkanButton"
        textTransform="none"
        onPress={loginUser}
        text={'Lanjutkan'}
        disabled={disableOTP || loading || otpNew.length < 4}
        disabledStyle={styles.loginButtonDisable}
        textStyle={styles.loginButtonTextStyle}
        container={styles.loginButtonStyle}
      />
      <LoginInfoSheet onPressPrimary={onCloseModal} loginInfo={infoModal} onClose={onCloseModal} />
      <LoadingModal loading={loading} />
    </KeyboardAvoidingView>
  );
};

export default Otp;
