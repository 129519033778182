import * as ImagePicker from 'expo-image-picker';

const openImagePicker = async () => {
  try {
    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
    });
  } catch (error) {
    throw error;
  }
};

export default openImagePicker;
