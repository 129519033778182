import React, { useCallback, useEffect, useState } from 'react';
import { View, Pressable, FlatList } from 'react-native';
import { MILVIK_CONST } from '@utils/Constants';
import MyText from '@atoms/MyText';
import Divider from '@atoms/Divider';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import Drop from '@expo/vector-icons/MaterialIcons';
import Collapsible from 'react-native-collapsible';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import VoucherNominal from '../VoucherNominal';
import styles from './style';
import { formatUnderScoreLowerCase, setBillPaymentReason } from '@utils/UtilFunctions';

const DescriptText = ({ item, bulleted }) => (
  <View style={styles.descContainer}>
    {bulleted && <MyText>&#x2022; </MyText>}
    <MyText customStyle={styles.descTextStyle}>{item}</MyText>
  </View>
);

const HeaderText = ({ subTitle }) => {
  if (subTitle) {
    return (
      <View style={styles.headerTextContainer}>
        <MyText customStyle={styles.headerTextStyle}>{`${subTitle}`}</MyText>
      </View>
    );
  }
  return null;
};

const Info = ({ route }) => {
  const { displayNominal, item } = route;

  const [callapseState, setCollapseState] = useState({
    0: true,
    1: true,
  });

  const logEvent = useCallback(
    (Idx, title) => {
      let e = AMPLITUDE_CONSTANTS.VoucherInventory.see_less_clicked;
      if (!callapseState[Idx] === true) {
        e = AMPLITUDE_CONSTANTS.VoucherInventory.see_more_clicked;
      }
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.product_code]: item.product_code ?? null,
        [e.attributes.transaction_category_id]: item.transaction_category_id ?? null,
        [e.attributes.product_price]: displayNominal ?? null,
        [e.attributes.account_number]: item.phoneNumber ?? null,
        [e.attributes.reason]: formatUnderScoreLowerCase(item.reason) ?? null,
        [e.attributes.reason_id]: item.reason_id ?? null,
        [e.attributes.section]: formatUnderScoreLowerCase(title),
      });
    },
    [callapseState, item],
  );

  const onCollapisbleHandler = (Idx, title) => {
    setCollapseState((prevState) => ({ ...prevState, [Idx]: !prevState[Idx] }));
    logEvent(Idx, title);
  };

  const renderCollapisbleSection = ({ item, index }) => (
    <View key={index}>
      <Pressable onPress={() => onCollapisbleHandler(index, item.title)} style={styles.titleStyle}>
        <MyText isRegular={false} customStyle={styles.titleTextStyle}>
          {item.title}
        </MyText>
        <Drop
          name={!callapseState[index] ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
          color={colors.neutral_charcoal}
          size={30}
        />
      </Pressable>
      <Collapsible collapsed={!callapseState[index]}>
        {item.data.map((e) => (
          <View key={e} style={styles.collapsibleInnerContainer}>
            <HeaderText subTitle={e.subTitle} />
            {e.info.map((info) => (
              <DescriptText key={info} item={info} bulleted={e.bulleted} />
            ))}
          </View>
        ))}
      </Collapsible>
      <Divider horizontal customStyle={styles.dividerStyle} />
    </View>
  );

  const renderHeader = () => <VoucherNominal nominalAmount={displayNominal} />;

  return (
    <View style={styles.container}>
      <FlatList
        data={MILVIK_CONST}
        ListHeaderComponent={renderHeader}
        renderItem={renderCollapisbleSection}
        contentContainerStyle={styles.flatlistPadding}
      />
    </View>
  );
};

export default React.memo(Info);
