import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Image, TextInput, Dimensions, View } from 'react-native';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { styles } from './style';
import AntDesign from '@expo/vector-icons/AntDesign';
import ArrowRight from '@expo/vector-icons/FontAwesome';
import Check from '@expo/vector-icons/AntDesign';
import ImageButton from '@atoms/ImageButton';
import AppTourComponentWrapper from '../AppTour/AppTourComponentWrapper';
import withAppTourHoc from '../AppTour/CopilotHoc';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import { setRedeemCode } from '@redux/action/Referral';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import ToolTip from '@atoms/CustomTooltip';
import { TOOLTIP_CONTENT } from '@utils/Constants';
import { APP_TOUR_TYPE } from '@utils/AppTourConstants';

const ReferralCode = ({ onPressSubmit, onEndEditing, fromBp = false }) => {
  const {
    isValidCode,
    loading: loadingReferral,
    error: errorReferral,
    isTransacted,
    redeemCode,
    redeemCodeLoading,
  } = useSelector((state) => state.Referral);
  const [toolTip, setToolTip] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   showTour && !fromBp && start();
  //   if (showTour) {
  //     let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_app_tour;
  //     AmplitudeHelper.logEvent(e.name, {
  //       [e.attributes.referral_code]: redeemCode,
  //       [e.attributes.source]: fromBp ? 'other_product_page' : 'home_page',
  //     });
  //   }
  // }, [showTour]);

  const onChangeReferral = (text) => {
    dispatch(setRedeemCode(text));
  };
  const onCloseTooltip = () => setToolTip(false);

  const onPressInfo = () => {
    setToolTip(true);
    let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_tooltip;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.referral_code]: redeemCode,
      [e.attributes.source]: fromBp ? 'other_product_page' : 'home_page',
    });
  };

  if (!isTransacted && !loadingReferral && !errorReferral) {
    return (
      <>
        {/* <AppTourComponentWrapper
          order={0}
          text={{
            title: 'Hadiah Untung Sekantor Untukmu 🎁',
            description: (
              <MyText>
                Masukkan <MyText isRegular={false}>kode referral</MyText> dari temanmu sekantor, lalu dapatkan untung{' '}
                <MyText isRegular={false}>bonus poin</MyText> setelah transaksi pertamamu
              </MyText>
            ),
            tourStep: 1,
          }}
          props={{
            style: fromBp ? null : styles.container,
          }}
          name="referral"
          type={APP_TOUR_TYPE.referral_code_apply}
        > */}
        <View style={fromBp ? null : styles.container}>
          <View style={styles.horizontalView}>
            <MyText isRegular={false} customStyle={styles.codeReferralText}>
              Kode Referal
            </MyText>
            <ToolTip description={TOOLTIP_CONTENT['referral']} isVisible={toolTip} onClose={onCloseTooltip}>
              <AntDesign name="questioncircle" size={16} onPress={onPressInfo} color={colors.oldSilver} />
            </ToolTip>
          </View>

          <View style={styles.horizontalView}>
            <TextInput
              style={!isValidCode ? styles.input : styles.appliedInput}
              editable={!isValidCode}
              placeholder="Type your code here .."
              value={redeemCode}
              onEndEditing={() => {
                if (redeemCode.length > 0 && !redeemCodeLoading && !isValidCode) {
                  onEndEditing();
                }

                let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_inputted;
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.referral_code]: redeemCode,
                  [e.attributes.source]: fromBp ? 'other_product_page' : 'home_page',
                });
              }}
              onChangeText={onChangeReferral}
            />
            <ImageButton
              loading={redeemCodeLoading}
              disabled={isValidCode || redeemCode.length === 0}
              textStyle={{ color: !isValidCode ? colors.oldSilver : '#28AB87' }}
              styleContainer={!isValidCode ? styles.button : styles.buttonApplied}
              isBorder={!isValidCode ? true : false}
              text={isValidCode ? 'VALID' : 'PAKAI'}
              onPress={() => {
                onPressSubmit();
                let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_submit_clicked;
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.referral_code]: redeemCode,
                  [e.attributes.source]: fromBp ? 'other_product_page' : 'home_page',
                });
              }}
              iconRight={
                isValidCode ? (
                  <Check name="check" color={'#28AB87'} size={18} />
                ) : (
                  <ArrowRight name="long-arrow-right" size={18} color={colors.oldSilver} />
                )
              }
            />
          </View>
        </View>
        {/* </AppTourComponentWrapper> */}
      </>
    );
  }

  return null;
};

//const ReferralCodeWithHoc = withAppTourHoc(ReferralCode, { hasIcon: true, verticalOffset: 35 });

export default React.memo(ReferralCode);
