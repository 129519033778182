import { normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  mainContainer: {
    bottom: 0,
    paddingHorizontal: normalizeHeight(10),
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: colors.white,
  },
  otpButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: normalizeHeight(10),
  },
  otpButton: {
    borderRadius: 30,
    flex: 1,
    marginEnd: normalizeHeight(10),
  },
  signupText: {
    alignSelf: 'center',
    marginBottom: normalizeHeight(15),
  },
  textTerms: {
    textAlign: 'center',
    marginTop: normalizeHeight(5),
    marginBottom: normalizeHeight(12),
  },
});
