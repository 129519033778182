import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform, View, Text, Dimensions, Linking } from 'react-native';
import { HeaderBackButton } from '@react-navigation/elements';
import { CommonActions, useNavigation } from '@react-navigation/native';
import TransactionList from '@screens/TransactionList';
import Saving from '@screens/Saving';
import { Font } from '@style/Fonts';
import Settlement from '@screens/Settlement';
import BankDetail from '@screens/BankDetail';
import Points from '@screens/Points';
import { ScreenOptions } from '@style/Themes';
import { normalize } from '@style/helper';
import { useSelector } from 'react-redux';
import VoucherDetail from '@screens/VoucherDetail';
import { BantuanButton } from '@components/Button';
import { COMMON_CONSTANTS } from '@utils/Constants';
import BillPaymentConfirm from '@screens/BillPaymentConfirm';
import VoucherScreen from '@screens/VoucherScreen';
import Referral from '@screens/Referral';
import Gamification from '@screens/Gamification';
import RewardInfo from '@screens/RewardInfo';
import ProductaPurchaseConfirmation from '@screens/ProductPurchaseConfirm';
import NewEditTransaction from '@screens/NewEditTransaction';

const Stack = createStackNavigator();
const { height: screenHeight } = Dimensions.get('window');

const TransactionStack = () => {
  const navigation = useNavigation();
  const employerId = useSelector((state) => state.Authentication.employerId);
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: '#3863F0',
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: '#3863F0',
      height: screenHeight / 15,
    },
    cardStyle: { flex: 1 },
    headerTitleStyle: {
      fontFamily: Font.Nunito_Bold,
      fontSize: normalize(14),
    },
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TransactionListPage"
        component={TransactionList}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'Detail Transaksi',
          headerBackTitle: 'Detail Transaksi',
          headerTintColor: 'white',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
          },
        }}
      />

      <Stack.Screen
        name="EditTransaction"
        component={NewEditTransaction}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'Edit Transaksi',
          headerBackTitle: 'Edit Transaksi',
        }}
      />
      <Stack.Screen
        name="SavingDetails"
        component={Saving}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'Rincian Penghematanmu',
          headerBackTitle: 'Rincian Penghematanmu',
          headerTintColor: 'white',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
          },
          headerLeft: () => {
            return (
              <View accessibilityLabel="transactionSummaryBackButton">
                <HeaderBackButton
                  tintColor="white"
                  onPress={() => {
                    navigation.dispatch(
                      CommonActions.reset({
                        routes: [{ name: 'HomeScene', params: { screen: 'Transaksi' } }],
                      }),
                    );
                  }}
                />
              </View>
            );
          },
        }}
      />
      <Stack.Screen
        name="Points"
        component={Points}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'GajiGesa',
          headerBackTitle: 'GajiGesa',
          headerLeft: () => {
            return (
              <View accessibilityLabel="transactionSummaryBackButton">
                <HeaderBackButton tintColor="white" onPress={() => navigation.goBack()} />
              </View>
            );
          },
          tabBarVisible: false,
          headerTintColor: 'white',
        }}
      />

      <Stack.Screen
        name="BankDetail"
        component={BankDetail}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'GajiGesa',
          headerBackTitle: 'GajiGesa',
          headerLeft: () => {
            return (
              <View accessibilityLabel="transactionSummaryBackButton">
                <HeaderBackButton tintColor="white" onPress={() => navigation.navigate('Dashboard')} />
              </View>
            );
          },
          headerRight: () => <BantuanButton onPress={() => Linking.openURL(COMMON_CONSTANTS.support)} />,
        }}
      />
      <Stack.Screen
        name="Settlement"
        component={Settlement}
        options={{
          ...ScreenOptions,
          headerLeft: () => (
            <Text
              style={{
                color: '#FFF',
                fontSize: normalize(14),
                marginLeft: 30,
                fontFamily: Font.Nunito_Bold,
              }}
            >
              {employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}
            </Text>
          ),
          headerRight: () => <BantuanButton onPress={() => Linking.openURL(COMMON_CONSTANTS.support)} />,
        }}
      />
      <Stack.Screen
        name="ProductPurchaseConfirm"
        component={ProductaPurchaseConfirmation}
        options={{
          ...ScreenOptions,
          headerLeft: () => (
            <Text
              style={{
                color: '#FFF',
                fontSize: normalize(14),
                marginLeft: 30,
                fontFamily: Font.Nunito_Bold,
              }}
            >
              {employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}
            </Text>
          ),
          headerRight: () => <BantuanButton onPress={() => Linking.openURL(COMMON_CONSTANTS.support)} />,
        }}
      />
      <Stack.Screen
        name="VoucherDetail"
        component={VoucherDetail}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : route.params.data.biller.toUpperCase().replace('VOUCHER', ''),
          headerBackTitle: route.params.data.biller.toUpperCase().replace('VOUCHER', ''),
          headerRight: () => <BantuanButton onPress={() => Linking.openURL(COMMON_CONSTANTS.support)} />,
        })}
      />

      <Stack.Screen
        name="BillPaymentConfirm"
        component={BillPaymentConfirm}
        options={{
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : 'Pilih Wilayah',
          headerBackTitle: 'Pilih Wilayah',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        }}
      />

      <Stack.Screen
        name="VoucherScreen"
        component={VoucherScreen}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: Platform.OS == 'ios' ? null : route.params?.data?.title,
          headerBackTitle: route.params?.data?.title,
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />

      <Stack.Screen
        name="Referral"
        component={Referral}
        options={({ route }) => ({
          ...optionStyle,
          headerTitle: 'Hadiah Poin',
          headerBackTitle: 'Hadiah Poin',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
        })}
      />
      <Stack.Screen
        name="Gamification"
        component={Gamification}
        options={{
          ...optionStyle,
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
            fontSize: normalize(14),
          },
          headerTitle: 'Misi Saya',
          headerBackTitle: 'Misi Saya',
        }}
      />
      <Stack.Screen name="RewardInfo" component={RewardInfo} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

export default TransactionStack;
