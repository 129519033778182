import React, { useMemo, useRef, useState } from 'react';
import { Dimensions, Text, View, TouchableOpacity, StyleSheet } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import MIcon from '@expo/vector-icons/MaterialIcons';

import AmplitudeHelper from '@services/amplitude';

/* Redux Import */
import { useDispatch, useSelector } from 'react-redux';
import { setCanContinue } from '@redux/action/Common';

/* Style Import */
import { LgButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
const { width: screenWidth } = Dimensions.get('window');

/* Component Import */
import ReasonsGrid from '@molecules/ReasonsGrid';
import { setReason } from '@redux/action/Transactions';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase, getSdTransactionReasons, getWorkAllowanceReasons } from '@utils/UtilFunctions';
import { store } from '@enhancedStore/store';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';
const ReasonsContent = ({ handleNoReason, updateTextBox, handleClose, eventSource }) => {
  const reasonsCarousel = useRef();
  const dispatch = useDispatch();

  const { selectedReason, feeAmount, selectedReasonId, transCategoryId, transSource } = useSelector(
    (state) => state.Transactions,
  );
  const common = useSelector((state) => state.Common);
  const { currentHomePageSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const reasonList = useSelector((state) => state.Withdrawal.reasonList);
  const withdrawAmount = useSelector((state) => state.Withdrawal.withdrawAmount);
  const { redeemCode } = store.getState().Referral;
  const [activeIndex, setActiveIndex] = useState(0);

  const dynamicReasons = useMemo(() => {
    if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT)
      return [getSdTransactionReasons(reasonList[1])];

    if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE)
      return [getWorkAllowanceReasons(reasonList[2])];

    return reasonList;
  }, [reasonList, currentHomePageSelectedAccount]);

  const handleSelect = (obj) => {
    if (obj.tag == 'OTHERS') {
      updateTextBox(true);
    }

    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason]: formatUnderScoreLowerCase(obj?.reason ?? null),
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.reason_id]: obj?.id ?? null,
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.source]: formatUnderScoreLowerCase(eventSource ?? null),
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.referral_code]: redeemCode || null,
      [AMPLITUDE_CONSTANTS.Withdrawal.selectedReason.attributes.dynamic_account_name]:
        currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
    });

    //AmplitudeHelper.trackEvent('selectedReason', {"reason": obj.reason})
    dispatch(
      setReason({
        reason: obj.reason,
        reasonId: obj.id,
        reasonColor: obj.reasonColor,
      }),
    );
  };

  return (
    <>
      <View style={styles.background}>
        <MyText
          isRegular={false}
          customStyle={{
            fontSize: normalize(18),
            lineHeight: normalizeHeight(25),
            fontFamily: Font.Nunito_Bold,
            marginLeft: 25,
          }}
        >
          Alasan Penarikan Uang:
        </MyText>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            // alignItems: "center",
            marginVertical: 16,
            marginHorizontal: 20,
          }}
        >
          {activeIndex ? (
            <TouchableOpacity
              onPress={() => {
                reasonsCarousel.current.snapToPrev();
                setActiveIndex((index) => index - 1);
              }}
              style={styles.leftButton}
            >
              <MIcon
                name="double-arrow"
                size={19}
                color={colors.orange}
                style={{ transform: [{ rotate: '180deg' }] }}
              />
              <Text style={styles.buttonText}>Sebelumnya</Text>
            </TouchableOpacity>
          ) : (
            <View />
          )}
          {activeIndex !== dynamicReasons.length - 1 ? (
            <TouchableOpacity
              onPress={() => {
                reasonsCarousel.current.snapToNext();
                setActiveIndex((index) => index + 1);
              }}
              style={styles.rightButton}
            >
              <MIcon name="double-arrow" size={18} color={colors.orange} />
              <Text style={styles.buttonText}>Selanjutnya</Text>
            </TouchableOpacity>
          ) : null}
        </View>
        <Carousel
          ref={reasonsCarousel}
          itemWidth={screenWidth}
          sliderWidth={screenWidth}
          data={dynamicReasons}
          renderItem={({ item }) => (
            <ReasonsGrid reasons={item} handleSelect={handleSelect} selectedReason={selectedReason} />
          )}
          inactiveSlideOpacity={1}
          inactiveSlideScale={1}
          onSnapToItem={(index) => {
            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.name, {
              [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.source]: eventSource,
              [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.referral_code]: redeemCode || null,
            });
            setActiveIndex(index);
          }}
          contentContainerStyle={{ height: 50 }}
          enableSnap
        />
        <Pagination
          dotsLength={dynamicReasons.length}
          activeDotIndex={activeIndex}
          containerStyle={styles.pagination}
          dotStyle={{
            backgroundColor: '#3863F0',
            height: 12,
            width: 12,
            borderRadius: 6,
            marginHorizontal: 15,
          }}
          inactiveDotStyle={{
            backgroundColor: '#292929',
            height: 12,
            width: 12,
            borderRadius: 6,
            marginHorizontal: 15,
          }}
          inactiveDotScale={1}
          animatedDuration={0}
          carouselRef={reasonsCarousel}
        />
        <View style={styles.lanjutContainer}>
          {selectedReason == '' ? (
            <LgButton
              testId="lanjutButtonDisabled"
              style={{ backgroundColor: '#F89F92' }}
              title="Lanjut"
              onPress={() => handleNoReason('Pilih Alasan')}
            />
          ) : (
            <LgButton
              testId="lanjutButton"
              title="Lanjut"
              onPress={() => {
                handleClose();
                dispatch(setCanContinue(true));
              }}
            />
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: 'white',
    padding: 20,
    flex: 1,
  },
  pagination: {
    // flex: 1,
    paddingVertical: 0,
    paddingBottom: 3,
    marginTop: normalize(10),
    width: 40,
    alignSelf: 'center',
  },
  lanjutContainer: {
    height: 70,

    alignItems: 'center',
    marginTop: 5,
    justifyContent: 'center',
    marginBottom: 20,
  },
  buttonText: {
    color: colors.orange,
    textDecorationLine: 'underline',
    fontSize: normalize(14),
    lineHeight: normalizeHeight(19),
    fontFamily: Font.Nunito_Regular,
  },
  leftButton: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.orange,
  },
  rightButton: {
    alignSelf: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.orange,
  },
});

export default ReasonsContent;
