import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral_cotton,
  },
  poinsContainer: { height: '30%', backgroundColor: colors.secondary_orchid },
  parentContainer: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    paddingTop: 0,
  },
  parentStyle: {
    height: '100%',
    backgroundColor: colors.neutral_cotton,
  },
  containerStyle: { backgroundColor: 'transparent' },
  absoluteAdj: { position: 'absolute', marginTop: normalize(25) },
  innerRowStyle: {
    backgroundColor: colors.neutral_mist,
    flexDirection: 'row',
    paddingVertical: normalize(15),
    paddingHorizontal: normalize(23),
    justifyContent: 'space-between',
  },
  innterRowValue: { marginRight: normalize(11), fontSize: normalize(12) },

  absoluteContainer: {
    marginHorizontal: normalize(15),
    borderRadius: normalize(12),
    backgroundColor: colors.neutral_cotton,
    elevation: 5,
  },
  topCotainerRow: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: normalize(12) },
  iconStyle: { alignSelf: 'center', marginRight: normalize(6) },
  ewaBalance: {
    alignSelf: 'center',
    fontSize: normalize(23),
    color: colors.success_cactus,
    marginVertical: normalize(7),
  },

  collapsibleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: normalize(15),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.neutral_mist,
    marginTop: normalize(11),
  },
  collapsibleSubValueRow: { flexDirection: 'row' },
  totalExpsenseRowContainer: {
    flexDirection: 'row',
    paddingVertical: normalize(15),
    paddingHorizontal: normalize(15),
    paddingRight: normalize(23),
    justifyContent: 'space-between',
  },
  totalExpenseValue: { marginRight: normalize(11), fontSize: normalize(12) },
  lockPeriodBannerTextStyle: { fontSize: normalize(11.5), marginLeft: 10 },
  lockPeriodBannerIconContainerStyle: { justifyContent: 'center' },
  lockperiodBannerContainerStyle: {
    backgroundColor: colors.error_softRose,
    marginHorizontal: normalize(15),
    marginTop: normalize(11),
  },
  periodContainer: {
    backgroundColor: colors.secondary_softOrchid,
    marginHorizontal: normalize(15),
    marginTop: normalize(11),
  },
  bannerTextStyle: {
    fontSize: normalize(10.5),
    marginLeft: normalize(10),
  },

  modalIconStyle: { alignSelf: 'center', marginRight: normalize(6) },

  tooltipBannerTextStyle: { fontSize: normalize(11.5), marginLeft: 10 },
  tooltipBannerIconContainerStyle: { justifyContent: 'center' },
  tooltipBannerContainerStyle: {
    backgroundColor: colors.neutral_mist,
    marginHorizontal: normalize(15),
    marginTop: normalize(11),
  },
});

export default styles;
