import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, View } from 'react-native';
import MyText from '@atoms/MyText';
import styles from './style';

const TextInputComponent = ({ title, isMandatory, textInputProps }) => {
  return (
    <View style={styles.mainContainer}>
      <MyText h2 customStyle={styles.titleText}>
        {title}
        <MyText customStyle={styles.star}>{isMandatory ? '*' : ''}</MyText>
      </MyText>
      <View style={styles.inputContainer}>
        <TextInput style={styles.textInput} {...textInputProps} />
      </View>
    </View>
  );
};

TextInputComponent.defaultProps = {
  textInputProps: {},
};

TextInputComponent.propTypes = {
  textInputProps: PropTypes.instanceOf(Object),
};

export default TextInputComponent;
