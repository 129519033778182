import { StyleSheet } from 'react-native';
import { normalize } from '@style/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    paddingVertical: 10,
    justifyContent: 'center',
    borderRadius: 30,
    marginVertical: 15,
    width: '100%',
  },
  disabledButton: { backgroundColor: colors.disabled_smoke },
});
