import React from 'react';
import { Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '@style/colors';
import CircleCheck from '@expo/vector-icons/AntDesign';
import { MISSION_GOAL_TYPE, MISSION_STATUS } from '@utils/Constants';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@utils/UtilFunctions';
import styles from './style';

const GamificationItem = ({ onPress, item }) => {
  const status = item.status === MISSION_STATUS.COMPLETED;
  const isGoalTypeAmount = item.mission.goalType === MISSION_GOAL_TYPE.TOTAL_AMOUNT;
  const textualData = item.mission.description.split('||');

  return (
    <View style={styles.mainContainer}>
      <Pressable
        style={[styles.pressable, { backgroundColor: status ? colors.mist : colors.blue }]}
        android_ripple={{ color: colors.lightGrey }}
        onPress={onPress}
        disabled={status}
      >
        {status ? SVG_ICONS.gamification.missionDark : SVG_ICONS.gamification.missionLight}
        <View style={styles.textContainer}>
          <View style={styles.leftContainer}>
            <MyText isRegular={false} h2 customStyle={status ? styles.textStyleLight : styles.textStyleDark}>
              {textualData[0]}
            </MyText>
            <View style={styles.progressContainer}>
              <MyText isRegular={false} h2 customStyle={status ? styles.textStyleLight : styles.textStyleDark}>
                {isGoalTypeAmount && 'Rp'}
                {isGoalTypeAmount ? numberWithDots(item.goalProgress) : item.goalProgress} /{' '}
              </MyText>
              <MyText customStyle={status ? styles.textStyleLight : styles.textStyleDark} h2>
                {isGoalTypeAmount && 'Rp'}
                {isGoalTypeAmount ? numberWithDots(item.mission.goal) : item.mission.goal}{' '}
                {textualData.length > 1 && textualData[1]}
              </MyText>
            </View>
          </View>

          {status && <CircleCheck name="checkcircle" size={25} color={colors.darkGreen} />}
        </View>
      </Pressable>
    </View>
  );
};

GamificationItem.defaultProps = {};

GamificationItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  item: PropTypes.shape({
    goalProgress: PropTypes.number,
    status: PropTypes.string,
    mission: PropTypes.shape({
      goal: PropTypes.number,
      description: PropTypes.string,
      goalType: PropTypes.string,
    }),
  }).isRequired,
};

export default React.memo(GamificationItem);
