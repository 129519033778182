import React, { useEffect, useState } from 'react';
import { Text, View, Pressable, Linking } from 'react-native';
import { useDispatch } from 'react-redux';
import { formatUnderScoreLowerCase, numberWithDots } from '@utils/UtilFunctions';
import AmplitudeHelper from '@services/amplitude';
import { colors } from '@style/colors';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import { respSuccess } from '@redux/action/Common';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { COMMON_CONSTANTS } from '@utils/Constants';
import * as Clipboard from 'expo-clipboard';
import styles from './style';

const renderVoucherClip = (voucherData, onCopyHandler) =>
  voucherData?.voucherCode && (
    <Pressable onPress={onCopyHandler} android_ripple={colors.grey} style={styles.voucherClip}>
      <View style={styles.presableContainer}>
        <MyText customStyle={styles.voucherLable}>Kode Voucher Telekonsultasi</MyText>
        <MyText isRegular={false} customStyle={styles.voucherCodeStyle}>
          {voucherData?.voucherCode}
        </MyText>
      </View>
      <View style={styles.clipBoardontainer}>{SVG_ICONS.voucherInventory.clipboard}</View>
    </Pressable>
  );

function renderVoucherProductCheckout(showNext, handleBackToHome, params) {
  if (params?.transaction_category_id !== 'BP-18') return null;

  if (!showNext) {
    return (
      <View style={styles.displayContainer}>
        <MyText isRegular={false} customStyle={styles.vTextStyle}>
          {`VOUCHER TELEKONSULTASI\n MILVIK Rp${numberWithDots(params?.product_price)}`}
        </MyText>
        <MyText isRegular={false} customStyle={styles.tagStyle}>
          {'Berhasil'}
        </MyText>
        <MyText h5 customStyle={styles.transkiText}>
          {'Cek status transaksi Anda\n di laman Transaksi'}
        </MyText>
        <LoadingButton
          onPress={handleBackToHome}
          text={'Lihat Voucher Saya'}
          buttonColor={colors.orange}
          container={styles.buttonStyle}
        />
      </View>
    );
  }
  return null;
}

function renderZakatCheckout(handleBackToHome, params) {
  if (params?.transaction_category_id !== 'bp-29') return null;

  function showPeopleCount(peopleCount) {
    if (peopleCount > 1) return `(${peopleCount} Orang)`;
    return '';
  }

  return (
    <View style={styles.displayContainer}>
      <MyText isRegular={false} customStyle={styles.vTextStyle}>
        {`Pembayaran Zakat Fitrah \ntelah ditransfer ke Dompet Dhuafa Rp${numberWithDots(
          params?.product_price * params?.noOfPeople,
        )} ${showPeopleCount(params?.noOfPeople)} `}
      </MyText>
      <MyText isRegular={false} customStyle={styles.tagStyle}>
        {'Berhasil'}
      </MyText>
      <MyText h5 customStyle={styles.transkiText}>
        {'Cek status transaksi Anda\n di laman Transaksi'}
      </MyText>
      <LoadingButton
        onPress={handleBackToHome}
        text={'Ok'}
        buttonColor={colors.orange}
        container={styles.buttonStyle}
      />
    </View>
  );
}

function renderVoucherPurchased(showNext, handlePress, onLoadMilvikWa, voucherData, onCopyHandler, params) {
  if (params?.transaction_category_id !== 'BP-18') return null;
  if (showNext) {
    return (
      <View style={styles.vPurchasedContainer}>
        <MyText isRegular={false} customStyle={styles.vTextStyle}>
          {`VOUCHER TELEKONSULTASI\n MILVIK Rp${params?.product_price ?? ''}`}
        </MyText>

        {renderVoucherClip(voucherData, onCopyHandler)}
        <LoadingButton
          onPress={handlePress}
          text={'Kembali ke Beranda'}
          buttonColor={colors.white}
          bordered
          textColor={colors.primaryColor}
          container={styles.buttonStylePurchased}
        />
        <LoadingButton
          onPress={onLoadMilvikWa}
          text={'Lanjut Konsultasi'}
          onLoadMilvikWa
          buttonColor={colors.orange}
          bordered
          container={styles.buttonStyle}
        />
      </View>
    );
  }
  return null;
}

const ProductaPurchaseConfirmation = ({ route, navigation }) => {
  const { params } = route;
  const { voucherData } = params;

  const ew = AMPLITUDE_CONSTANTS.BillPayment.selamatPage;

  const [showNext, setShowNext] = useState(false);

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.selamatPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.product_price]: params?.product_price ?? null,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
      [e.attributes.reason]: params?.reason ?? null,
      [e.attributes.reason_id]: params?.reason_id ?? null,
      [e.attributes.fee_charged]: params?.fee_charged ?? null,
      [e.attributes.product_code]: params?.product_code ?? null,
      [ew.attributes.source]: params?.source ?? null,
      [ew.attributes.payment_method]: params?.payment_method ?? null,
    });

    return () => {
      let e = AMPLITUDE_CONSTANTS.Withdrawal.success_page_closed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.product_price]: params?.product_price ?? null,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
        [e.attributes.reason]: params?.reason ?? null,
        [e.attributes.reason_id]: params?.reason_id ?? null,
        [e.attributes.fee_charged]: params?.fee_charged ?? null,
        [e.attributes.product_code]: params?.product_code ?? null,
        [ew.attributes.source]: params?.source ?? null,
        [ew.attributes.payment_method]: params?.payment_method ?? null,
      });
    };
  }, []);
  const dispatch = useDispatch();

  const onCopyHandler = () => {
    try {
      Clipboard.setStringAsync(voucherData.voucherCode);
      dispatch(respSuccess('Kode voucher tersalin!'));
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.code]: voucherData.voucherCode ?? null,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
        [e.attributes.source]: 'success_page',
        [e.attributes.status]: 'success',
        [e.attributes.feature]: 'milvik',
      });
    } catch (err) {
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.code]: voucherData.voucherCode ?? null,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
        [e.attributes.source]: 'success_page',
        [e.attributes.status]: 'fail',
        [e.attributes.feature]: 'milvik',
      });
    }
  };

  const handleBackToHome = () => {
    const e = AMPLITUDE_CONSTANTS.VoucherInventory.success_page_cta;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.cta]: 'see_voucher',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
    });
    setShowNext(true);
  };

  const handlePress = () => {
    navigation.navigate('Home');
  };

  const handlegoBack = () => {
    navigation.navigate('Home');
  };

  const onLoadMilvikWa = () => {
    Linking.openURL(COMMON_CONSTANTS.productMIlvik);
    const e = AMPLITUDE_CONSTANTS.VoucherInventory.consult_now_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'success_page',
      [e.attributes.status]: 'success',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params?.transaction_category_id ?? ''),
    });
  };

  return (
    <View style={styles.scene}>
      <View style={styles.background} />
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <View style={styles.logo}>
            {/* <SvgUri uri="https://gg-app-stg.s3.ap-southeast-3.amazonaws.com/svg-icons/PaymentSuccess.svg" /> */}
            {SVG_ICONS.successIcon}
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.cardTitle}>Selamat!</Text>
            {renderVoucherProductCheckout(showNext, handleBackToHome, params)}
            {renderVoucherPurchased(
              showNext && params?.transaction_category_id == 'BP-18',
              handlePress,
              onLoadMilvikWa,
              voucherData,
              onCopyHandler,
              params,
            )}
            {renderZakatCheckout(handlegoBack, params)}
          </View>
        </View>
      </View>
    </View>
  );
};

ProductaPurchaseConfirmation.propTypes = {};
ProductaPurchaseConfirmation.defaultProps = {};
export default ProductaPurchaseConfirmation;
