import React from 'react';
import { StyleSheet } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

/* Screens in stack */

import { Font } from '@utils/Fonts';
import { normalize } from '@style/helper';
import { colors } from '@style/colors';
import NewLogin from '../screens/NewLogin';
import Otp from '../screens/Otp';
import SignupUser from '../screens/SignupUser';
import TermsCondition from '../screens/TermsCondition';

// import InformasiStack from '../stacks/InformasiStack'
// import BantuanStack from '../stacks/BantuanStack'
// import Register from '../screens/Register'

const Stack = createStackNavigator();

const LoginStack = () => (
  <Stack.Navigator initialRouteName={'Login'}>
    <Stack.Screen name="Login" component={NewLogin} options={{ headerShown: false }} />
    <Stack.Screen
      name="Otp"
      component={Otp}
      options={{
        headerStyle: styles.headerStyle,
        headerTitle: 'OTP',
        headerBackTitleStyle: styles.headerBackStyle,
        headerTitleStyle: styles.headerTitleStyle,
      }}
    />
    <Stack.Screen
      name="Signup"
      options={{
        headerTitle: 'Cek Data',
        headerStyle: styles.headerStyle,
        headerTitleStyle: styles.headerTitleStyle,
      }}
      component={SignupUser}
    />
    <Stack.Screen
      name="Terms"
      options={{
        headerTitle: '',
        headerStyle: styles.termsHeaderStyle,
      }}
      component={TermsCondition}
    />
    {/* <Stack.Screen name="Register" component={Register} options={{          
            headerTitle: 'Registrasi',
            headerTintColor: 'white',
            headerTransparent: true,
            headerBackTitleVisible: false,
            headerTitleStyle:{fontFamily:'Nunito-Bold'},
            headerLeftContainerStyle: {
                marginLeft: 15
            }
        }}/>
        <Stack.Screen name="Informasi" component={InformasiStack}/>
        <Stack.Screen name="Bantuan" component={BantuanStack}/> */}
  </Stack.Navigator>
);

const styles = StyleSheet.create({
  headerTitleStyle: {
    marginStart: normalize(-8),
    fontSize: normalize(16),
    fontFamily: Font.Nunito_Bold,
    color: colors.black,
    width: normalize(100),
  },
  headerStyle: { elevation: 0 },
  headerBackStyle: {
    color: colors.black,
  },
  termsHeaderStyle: { elevation: 0 },
});

export default LoginStack;
