import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { MESSAGGE_TYPE } from '@utils/Constants';
import { colors } from '@style/colors';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import ContactSupportButton from '../ContactSupportButton';

import PhoneNumberInput from '../PhoneNumberInput';
import { styles } from './style';

const LoginInputCard = ({
  onPressTerms,
  phoneNumber,
  setPhoneNumber,
  enableOtpButtons,
  otpLoadingType,
  inputFocused,

  setInputFocused,
  onPressOtpButton,
  errorMessage,
  isError,
  onPressSignUp,
}) => {
  const onPressSMS = () => {
    if (otpLoadingType !== null) return;
    onPressOtpButton(MESSAGGE_TYPE.SMS);
  };

  const onPressWhatsapp = () => {
    if (otpLoadingType !== null) return;
    onPressOtpButton(MESSAGGE_TYPE.WHATSAPP);
  };

  return (
    <View style={styles.mainContainer}>
      <MyText customStyle={{ paddingTop: 20 }} isRegular={false} h4>
        Selamat Datang !
      </MyText>

      <PhoneNumberInput
        setInputFocused={setInputFocused}
        inputFocused={inputFocused}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        errorMessage={errorMessage}
        isError={isError}
        otpLoadingType={otpLoadingType}
      />

      <View style={styles.otpButtonContainer}>
        <LoadingButton
          disabled={!enableOtpButtons}
          container={{ ...styles.otpButton, backgroundColor: enableOtpButtons ? colors.orange : colors.gainsboro }}
          text="Whatsapp"
          textTransform="capitalize"
          loading={otpLoadingType === MESSAGGE_TYPE.WHATSAPP}
          onPress={onPressWhatsapp}
        />
        <LoadingButton
          disabled={!enableOtpButtons}
          container={{
            ...styles.otpButton,
            marginEnd: 0,
            backgroundColor: enableOtpButtons ? colors.orange : colors.gainsboro,
          }}
          text="SMS"
          onPress={onPressSMS}
          loading={otpLoadingType === MESSAGGE_TYPE.SMS}
        />
      </View>
      <MyText isRegular={false} customStyle={styles.signupText}>
        Belum punya akun?{' '}
        <MyText isRegular={false} onPress={onPressSignUp} customStyle={{ color: colors.blue }}>
          Daftar Sekarang
        </MyText>
      </MyText>
      <ContactSupportButton />

      <MyText h2 isRegular={false} customStyle={styles.textTerms}>
        Dengan melanjutkan, Anda menyetujui{' '}
        <MyText onPress={onPressTerms} isRegular={false} customStyle={{ color: colors.blue }}>
          Syarat & Ketentuan Lainnya
        </MyText>
      </MyText>
    </View>
  );
};

LoginInputCard.defaultProps = {
  errorMessage: '',
  isError: false,
  otpLoadingType: null,
};

LoginInputCard.propTypes = {
  onPressTerms: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  enableOtpButtons: PropTypes.bool.isRequired,
  otpLoadingType: PropTypes.string,
  inputFocused: PropTypes.bool.isRequired,
  setInputFocused: PropTypes.func.isRequired,
  onPressOtpButton: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  onPressSignUp: PropTypes.func.isRequired,
};

export default React.memo(LoginInputCard);
