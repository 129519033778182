import { StyleSheet, Dimensions } from 'react-native';
const { width: screenWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    width: screenWidth / 1.1,
    backgroundColor: '#FFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 15,
    paddingBottom: 5,
    marginTop: 20,
  },
  modalContainer: {
    width: screenWidth / 1.1,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  slimmer: {
    marginTop: 20,
    marginBottom: 10,
    borderRadius: 16,
    width: screenWidth / 1.1,
    height: 250,
    display: 'flex',
    alignSelf: 'center',
  },
});

export default styles;
