import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { colors } from '@style/colors';
import Close from '@expo/vector-icons/AntDesign';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import ButtonColor from '@atoms/ButtonColor';
import TourDot from './TourDot';

const CommonCard = ({
  closeModal,
  description,
  title,
  hasCongrats,
  activeDot = 1,
  numOfDots,
  hasTwoButtons,
  onPressButton,
  onPressButtonColor,
  buttonTitle1,
  buttonTitle2,
  customTextWidth,
  container,
}) => {
  return (
    <View style={[styles.container, container]}>
      <TouchableOpacity style={{ alignSelf: 'flex-end', marginVertical: 5 }} onPress={closeModal}>
        <Close name="close" size={20} color={colors.grey} />
      </TouchableOpacity>
      <View style={styles.subContainer}>
        {SVG_ICONS.walletColorSmall}
        <View style={{ marginStart: 20 }}>
          <MyText isRegular={false}>
            {title} {hasCongrats ? SVG_ICONS.congrats : null}
          </MyText>
          <MyText h2 customStyle={[styles.description, { width: customTextWidth }]}>
            {description}
          </MyText>
        </View>
      </View>
      <View style={styles.dotContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {numOfDots > 0 &&
            new Array(numOfDots).fill('x').map((e, i) => {
              return <TourDot key={i} hasMarginStart={i > 0} active={activeDot === i + 1} />;
            })}
        </View>
        {hasTwoButtons ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity onPress={onPressButton} style={{ marginEnd: 5 }}>
              <MyText h2>{buttonTitle1}</MyText>
            </TouchableOpacity>
            <ButtonColor textStyle={{ fontSize: 12 }} title={buttonTitle2} onPress={onPressButtonColor} />
          </View>
        ) : (
          <ButtonColor onPress={onPressButtonColor} textStyle={{ fontSize: 12 }} title={buttonTitle2} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  description: { width: '40%', marginTop: 5, flexWrap: 'wrap' },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export default CommonCard;
