import React, { useState, useEffect } from 'react';
import { Text, View, Dimensions, ScrollView, FlatList, StyleSheet } from 'react-native';
import { formatUnderScoreLowerCase, numberWithDots } from '@utils/UtilFunctions';
import { VOUCHER_BILLER_DATA } from '@utils/Constants';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '@services/BillPaymentApi';

/* Styles */
import { LgButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
import Loading from '@screens/Loading';
import { SvgUri } from 'react-native-svg';
import Icon from '@expo/vector-icons/Ionicons';
import EIcon from '@expo/vector-icons/Entypo';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';

const VoucherDetail = ({ route }) => {
  const getLogo = (name) => {
    let i = VOUCHER_BILLER_DATA.length;
    while (i--) {
      if (name == VOUCHER_BILLER_DATA[i].name) {
        return VOUCHER_BILLER_DATA[i].link;
      }
    }
  };

  const dispatch = useDispatch();

  let { data, dataDetail } = route.params;

  const phoneNumber = useSelector((state) => state.Authentication.phoneNumber);
  const globalLoading = useSelector((state) => state.Common.globalLoading);

  const [showText1, setShowText1] = useState(true);
  const [showText2, setShowText2] = useState(true);
  const [showText3, setShowText3] = useState(true);
  const [loading, setLoading] = useState(false);

  const localSelectProduct = async () => {
    setLoading(true);
    await dispatch(selectProduct(data, phoneNumber));
    setLoading(false);
  };

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.detailsProductPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: phoneNumber,
      [e.attributes.product_price]: data?.amount,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
    });
  }, []);

  useEffect(() => {
    setLoading(globalLoading);
  }, [globalLoading]);

  return (
    <>
      <View style={styles.scene}>
        <View style={styles.background} />
        <View style={styles.container}>
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <ScrollView>
                  <View style={styles.wrapperLogo}>
                    <SvgUri uri={getLogo(data.biller)} />
                  </View>
                  <View style={styles.wrapperTitle}>
                    <Text style={styles.text}>{data.name}</Text>
                    <View style={styles.flexWrapper}>
                      <View style={styles.row}>
                        <Text style={styles.amount}>{`Rp. ${numberWithDots(data.amount)}`}</Text>
                        <Icon style={styles.timerIcon} name={'timer-outline'} />
                      </View>
                      <Text style={styles.timerDescription}>
                        Tukarkan sebelum 7 hari dari tanggal pembelian voucher
                      </Text>
                    </View>
                  </View>
                  <View style={styles.box}>
                    <Text style={styles.detailTitle}>Yang Kamu Dapatkan</Text>
                    {showText1 == true ? (
                      <FlatList
                        data={dataDetail?.text1.slice(0, 1)}
                        renderItem={(item) => {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <EIcon name={'dot-single'} style={styles.icon} />
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    ) : (
                      <FlatList
                        data={dataDetail?.text1}
                        renderItem={(item) => {
                          return (
                            <View style={styles.alignStart}>
                              <EIcon name={'dot-single'} style={styles.icon} />
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    )}
                    <Text
                      accessibilityLabel={'dropdownShow'}
                      onPress={() => {
                        if (showText1) {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeMoreClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'what_user_get',
                          });
                          setShowText1(false);
                        } else {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeLessClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'what_user_get',
                          });
                          setShowText1(true);
                        }
                      }}
                      style={styles.textForHidden}
                    >
                      {showText1 ? 'Selengkapnya' : 'Sembunyikan'}
                    </Text>
                  </View>
                  <View style={styles.ketentuanContainer}>
                    <Text style={styles.detailTitle}>{'Syarat & Ketentuan'}</Text>
                    {showText2 ? (
                      <FlatList
                        data={dataDetail?.text2.slice(0, 1)}
                        renderItem={(item) => {
                          return (
                            <View style={styles.alignStart}>
                              <EIcon name={'dot-single'} style={styles.icon} />
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    ) : (
                      <FlatList
                        data={dataDetail?.text2}
                        renderItem={(item) => {
                          return (
                            <View style={styles.alignStart}>
                              <EIcon name={'dot-single'} style={styles.icon} />
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    )}
                    <Text
                      accessibilityLabel={'dropdownShow'}
                      onPress={() => {
                        if (showText2) {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeMoreClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'terms_and_conditions',
                          });
                          setShowText2(false);
                        } else {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeLessClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'terms_and_conditions',
                          });
                          setShowText2(true);
                        }
                      }}
                      style={styles.textForHidden}
                    >
                      {showText2 ? 'Selengkapnya' : 'Sembunyikan'}
                    </Text>
                  </View>
                  <View style={styles.redeemContainer}>
                    <Text style={styles.detailTitle}>Cara Redeem Voucher</Text>
                    {showText3 ? (
                      <FlatList
                        data={dataDetail?.text3.slice(0, 1)}
                        renderItem={(item) => {
                          return (
                            <View style={styles.alignStart}>
                              <Text style={styles.itemIndex}>{++item.index}.</Text>
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    ) : (
                      <FlatList
                        data={dataDetail?.text3}
                        renderItem={(item) => {
                          return (
                            <View style={styles.alignStart}>
                              <Text style={styles.itemIndex}>{++item.index}.</Text>
                              <Text style={styles.itemText}>{item.item}</Text>
                            </View>
                          );
                        }}
                      />
                    )}
                    <Text
                      accessibilityLabel={'dropdownShow'}
                      onPress={() => {
                        if (showText3) {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeMoreClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'how_to_redeem',
                          });
                          setShowText3(false);
                        } else {
                          let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeLessClicked;
                          AmplitudeHelper.logEvent(e.name, {
                            [e.attributes.account_number]: phoneNumber,
                            [e.attributes.product_price]: data?.amount,
                            [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(data?.categoryPrefix),
                            [e.attributes.product_code]: formatUnderScoreLowerCase(data?.code),
                            [e.attributes.reason]: 'tagihan_dan_utilitas',
                            [e.attributes.reason_id]: 2,
                            [e.attributes.section]: 'how_to_redeem',
                          });
                          setShowText3(true);
                        }
                      }}
                      style={styles.textForHidden}
                    >
                      {showText3 ? 'Selengkapnya' : 'Sembunyikan'}
                    </Text>
                  </View>
                </ScrollView>
                <LgButton
                  testId={'VoucherConfirmationButton'}
                  title="Beli Sekarang"
                  style={styles.confirmationButton}
                  onPress={localSelectProduct}
                />
              </>
            )}
          </>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingTop: 10,
    marginTop: normalizeHeight(20),
    height: screenHeight / 1.1,
    bottom: 0,
    elevation: 5,
    borderRadius: 16,
    backgroundColor: colors.white,
    marginHorizontal: normalize(15),
    width: screenWidth / 1.1,
  },
  wrapperLogo: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 20,
  },
  wrapperTitle: { flexDirection: 'column', marginLeft: '5%' },
  text: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Regular,
  },
  flexWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 10,
  },
  row: {
    flex: 0.3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '2%',
  },
  box: {
    borderColor: '#D4D4D4',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingHorizontal: '8%',
    paddingVertical: '5%',
  },
  icon: { fontSize: normalize(20), color: '#454545', marginRight: 5 },
  textForHidden: {
    color: '#3863f0',
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
    marginTop: 10,
  },
  amount: {
    fontSize: normalize(14),
    color: '#F56B57',
    fontFamily: Font.Nunito_Bold,
  },
  timerIcon: {
    fontFamily: Font.Nunito_Regular,
    color: '#C4C4C4',
    fontSize: normalize(18),
    justifyContent: 'flex-end',
  },
  timerDescription: {
    fontFamily: Font.Nunito_Regular,
    flex: 0.6,
    color: '#999999',
    fontSize: normalize(10),
  },
  detailContainer: { flexDirection: 'row', justifyContent: 'flex-start' },
  detailText: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Regular,
    flex: 1,
    marginBottom: 10,
  },
  detailTitle: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Bold,
    marginBottom: 10,
  },
  detailIndex: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Regular,
    marginRight: 5,
  },
  ketentuanContainer: {
    borderColor: '#D4D4D4',
    borderBottomWidth: 1,
    paddingHorizontal: '8%',
    paddingVertical: '5%',
    borderTopWidth: 0,
  },
  redeemContainer: {
    borderColor: '#D4D4D4',
    paddingHorizontal: '8%',
    paddingVertical: '5%',
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  confirmationButton: {
    alignSelf: 'center',
    width: normalize(220),
    marginBottom: normalizeHeight(40),
    zIndex: 10,
  },
  itemText: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Regular,
    flex: 1,
    marginBottom: 10,
  },
  itemIndex: {
    fontSize: normalize(14),
    color: '#454545',
    fontFamily: Font.Nunito_Regular,
    marginRight: 5,
  },
  background: {
    backgroundColor: '#3863F0',
    height: screenHeight / 4,
    width: screenWidth,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  scene: {
    flex: 1,
    alignItems: 'center',
  },
  alignStart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

export default VoucherDetail;
