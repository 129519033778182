import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InteractionManager, SectionList, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import BillPaymentItem from '@atoms/BillPaymentItem';
import ConfirmModal from '@components/ConfirmModal';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import { setRedeemCode } from '@redux/action/Referral';
import { getBillPaymentCategories, getBillPaymentCategoriesByAccount, requestCategory } from '@services/BillPaymentApi';
import { checkReferralStatus } from '@services/Referral';
import { colors } from '@style/colors';
import ReferralCode from '@components/ReferralCode';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import {
  findAccountByName,
  formatUnderScoreLowerCase,
  getDynamicAcountListByModule,
  getListOfDynamicAccounts,
  returnBpCategoriesByAccount,
  getCurrentActiveMission,
  needForRefetchAppStatus,
  onShowModalHandler,
  onUpdateHandler,
} from '@utils/UtilFunctions';
import { store } from '@enhancedStore/store';
import BPAccountSelector from '@atoms/BPAccountSelector';
import DropDownModal from '@atoms/DropDownSelector/DropDownModal';
import {
  BP_DYNAMIC_ACCOUNTS,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  LOAN_APPLICATION_STATUS,
  LOAN_ICON_INFO,
  LOAN_STATUS_TYPES,
  LOAN_CATEGORY_INFO,
  BANK_CATEGORY_INFO,
  ZAKAT_CATEGORY_INFO,
} from '@utils/Constants';
import { setCurrentBpSelectedAccount } from '@redux/action/DynamicAccounts';
import { setpointsBillPayment } from '@redux/action/NewBillPayment';
import useLoans from '@gaji-gesa/gg-ui-shared/src/customHooks/useLoans';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import LoadingModal from '@components/LoadingModal';
import InfoModalSheet from '@molecules/InfoModalSheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setSeenLoan, setSeenZakat } from '@redux/action/Common';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ZAKAT_ICON_INFO } from '@gaji-gesa/gg-ui-shared/src/uiUtils/uiConstants';

const BillPaymentTab = () => {
  const navigation = useNavigation();

  const { billpaymentList, loading, error, pointsBillPaymentList, workAllowanceBillPaymentList } = useSelector(
    (state) => state.NewBillPayment,
  );
  const { currentBpSelectedAccount, dataAccounts, dynamicAccounts } = useSelector((state) => state.DynamicAccounts);
  const [accountDropDown, setAccountDropDown] = useState(false);
  const [interactionState, setInteractionState] = useState(false);
  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);
  const dispatch = useDispatch();

  const isFocused = useIsFocused();
  const phoneNumber = useSelector((state) => state.Authentication.phoneNumber);

  const [showReferralConfirmModal, setShowReferralConfirmModal] = useState(false);
  const { seenLoan } = useSelector((state) => state.Common);

  const getCategory = async (id, accountType) => {
    try {
      const dynamicId = id ? id : currentBpSelectedAccount?.id ?? null;
      const { formattedBp } = await dispatch(getBillPaymentCategoriesByAccount(dynamicId, accountType));
      if (currentBpSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
        dispatch(
          setpointsBillPayment(
            returnBpCategoriesByAccount(
              DYNAMIC_ACCOUNT_NAMES.POINTS,
              billpaymentList,
              dynamicAccountDetails.pointsAccount.availableBalance,
            ),
          ),
        );
      }
      return formattedBp;
    } catch (e) {}
  };

  const [isFeatureAvailale, getFeatureFlags] = useFeatureFlag({
    featureName: [FEATURE_FLAGS.BP_HOMEPAGE, FEATURE_FLAGS.LOAN, FEATURE_FLAGS.BANK_SEL_TRNSF, FEATURE_FLAGS.ZAKAT],
  });

  const [showInfoModalSheet, setInfoModalSheet] = useState({
    visible: false,
    data: LOAN_STATUS_TYPES[LOAN_APPLICATION_STATUS.PENDING_APPROVAL],
    type: 'loan',
  });

  const { getEligibilityAppStatus, updateLoanStatus, showLoan, loanData, isRefetch, setRefetch } = useLoans({
    isLoanFeature: isFeatureAvailale?.[FEATURE_FLAGS.LOAN].show,
    onShowModal: (data) =>
      onShowModalHandler(data, LOAN_STATUS_TYPES, setInfoModalSheet, LOAN_APPLICATION_STATUS, navigation, 'pembayaran'),
    onUpdate: (data) => onUpdateHandler(data, LOAN_APPLICATION_STATUS, navigation, 'pembayaran'),
  });

  useEffect(() => {
    if (isFocused) getEligibilityAppStatus();
  }, [isFocused]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      const findWorkAllowance = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
      if (findWorkAllowance && workAllowanceBillPaymentList.length === 0)
        getCategory(findWorkAllowance.id, findWorkAllowance.constantAccountName);
      if (billpaymentList.length === 0) getCategory();
      setInteractionState(true);
    });
  }, []);

  const currentActiveBillPaymentList = useMemo(
    () =>
      getCurrentActiveMission(
        currentBpSelectedAccount.constantAccountName,
        billpaymentList,
        pointsBillPaymentList,
        workAllowanceBillPaymentList,
        showLoan,
        LOAN_CATEGORY_INFO,
        isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF]?.show,
        BANK_CATEGORY_INFO,
        isFeatureAvailale[FEATURE_FLAGS.ZAKAT]?.show,
        ZAKAT_CATEGORY_INFO,
      ),
    [
      currentBpSelectedAccount,
      billpaymentList,
      pointsBillPaymentList,
      workAllowanceBillPaymentList,
      showLoan,
      isFeatureAvailale[FEATURE_FLAGS.BANK_SEL_TRNSF],
      isFeatureAvailale[FEATURE_FLAGS.ZAKAT],
    ],
  );

  const onPressLoanHandler = () => {
    try {
      AsyncStorage.setItem('@seenLoan', true);
      dispatch(setSeenLoan(true));

      if (needForRefetchAppStatus(loanData)) {
        setRefetch(true);
        getEligibilityAppStatus();
        return;
      }
      let e = AMPLITUDE_CONSTANTS.Loans.loan_tile_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'success',
      });
      navigation.navigate('ProductStack', { screen: 'LoanListing', params: { source: 'pembayaran' } });
    } catch (err) {
      let e = AMPLITUDE_CONSTANTS.Loans.loan_tile_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.status]: 'failure',
      });
    }
  };

  const onPressTransfer = () => {
    navigation.navigate('BankStack', {
      screen: 'SalaryTransfer',
      params: { source: AMPLITUDE_EVENT_SOURCES.other_product_page },
    });
  };
  const onPressZakatHandler = () => {
    AsyncStorage.setItem('@seenZakat', JSON.stringify(true));
    dispatch(setSeenZakat(true));

    let e = AMPLITUDE_CONSTANTS.Zakat.product_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: 'bp-29',
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      [e.attributes.phone_number]: phoneNumber,
    });

    navigation.navigate('ZakatStack', {
      screen: 'ZakatScreen',
      params: {
        item: { ...ZAKAT_ICON_INFO, menu: 'Zakat & Donasi', name: 'Zakat Fitrah', prefix: 'BP-29', id: 29 },
      },
    });
  };

  const onProductPressHandler = (product) => {
    if (product.id === 101) return onPressTransfer();
    if (product.id === 100) return onPressLoanHandler();
    if (product.id === 102) return onPressZakatHandler();

    let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedProduct;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(product?.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
    navigation.setParams({ item: product });

    if (product.id === 18) {
      navigation.navigate('ProductStack', {
        screen: 'VoucherInventory',
        params: { item: product, showTour: false },
      });
      return;
    }
    navigation.navigate('Product', { item: product, showTour: false });
  };

  const renderItem = useCallback(
    ({ item }) => <BillPaymentItem item={item} showNew={!seenLoan} onPress={onProductPressHandler} />,
    [loanData, seenLoan],
  );

  const renderSectionHeader = ({ section }) => (
    <MyText h5 isRegular={false} customStyle={{ color: colors.blue }}>
      {section.title}
    </MyText>
  );

  const keyExtractor = (item) => item.title;

  const onEndEditing = () => {
    setShowReferralConfirmModal(true);
  };

  const checkReferralCode = async () => {
    try {
      showReferralConfirmModal && setShowReferralConfirmModal(false);
      await dispatch(checkReferralStatus('other_product_page', true));
    } catch (error) {}
  };

  const onPressAccountDropdown = (item) => {
    const interactionId = InteractionManager.createInteractionHandle();
    InteractionManager.runAfterInteractions(() => {
      if (item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS && !pointsBillPaymentList.length > 0)
        dispatch(
          setpointsBillPayment(
            returnBpCategoriesByAccount(
              item.constantAccountName,
              billpaymentList,
              dynamicAccountDetails.pointsAccount.availableBalance,
            ),
          ),
        );

      if (
        item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE &&
        !workAllowanceBillPaymentList.length > 0
      ) {
        const findWorkAllowance = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
        if (findWorkAllowance) getCategory(findWorkAllowance.id, findWorkAllowance.constantAccountName);
      }
    });
    setAccountDropDown(!accountDropDown);
    dispatch(setCurrentBpSelectedAccount(item));
    InteractionManager.clearInteractionHandle(interactionId);

    // const e = AMPLITUDE_CONSTANTS.account_drop_down_selected;
    // AmplitudeHelper.logEvent(e.name, {
    //   [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.withdrawal_card,
    //   [e.attributes.dynamic_account_name]: item?.amplitudeAccountName ?? '',
    // });
  };

  const header = () => <ReferralCode onEndEditing={onEndEditing} onPressSubmit={checkReferralCode} fromBp />;

  const openAccountSelector = () => {
    setAccountDropDown(true);
  };

  const onPrimaryLoanPressHandler = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
    if (showInfoModalSheet.data?.hasTwoButtons) return;
    updateLoanStatus();
  };
  const onCloseLoanPressHandler = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
  };
  const onPressSecondary = () => {
    setInfoModalSheet((prevState) => ({ ...prevState, visible: false, type: null }));
    updateLoanStatus();
  };

  return (
    <MainParentWrapper
      isRounded={false}
      isError={error}
      loading={loading || !interactionState}
      containerStyle={{ paddingTop: 10 }}
      onReload={getCategory}
    >
      <BPAccountSelector onPress={openAccountSelector} selectedAccount={currentBpSelectedAccount} />

      <SectionList
        keyboardShouldPersistTaps="never"
        keyExtractor={keyExtractor}
        sections={currentActiveBillPaymentList}
        renderItem={renderItem}
        ListHeaderComponent={header}
        renderSectionHeader={renderSectionHeader}
        contentContainerStyle={{ paddingBottom: 50, paddingHorizontal: 15 }}
      />

      <ConfirmModal
        isVisible={showReferralConfirmModal}
        onPressModal={() => setShowReferralConfirmModal(false)}
        onPressNo={() => setShowReferralConfirmModal(false)}
        onPressApply={checkReferralCode}
      />

      <DropDownModal
        visible={accountDropDown}
        data={getDynamicAcountListByModule(dataAccounts, BP_DYNAMIC_ACCOUNTS)}
        hasIcon
        onPressItem={onPressAccountDropdown}
        onClose={() => {
          setAccountDropDown(false);
          const e = AMPLITUDE_CONSTANTS.cancel_button_clicked;
          AmplitudeHelper.logEvent(e.name, {
            [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.cancelButtonSource.account_drop_down_transaction_page,
          });
        }}
        selectedAccount={currentBpSelectedAccount}
      />

      <InfoModalSheet
        title={showInfoModalSheet.data?.title}
        description={showInfoModalSheet.data?.description}
        hasTwoButtons={showInfoModalSheet.data?.hasTwoButtons ?? false}
        buttonText={showInfoModalSheet.data?.buttonText}
        buttonText2={showInfoModalSheet.data?.buttonText2}
        icon={showInfoModalSheet.data?.icon}
        visible={showInfoModalSheet.visible}
        onPressPrimary={onPrimaryLoanPressHandler}
        onClose={onCloseLoanPressHandler}
        onPressSecondary={onPressSecondary}
      />

      <LoadingModal loading={isRefetch} text={'Mohon menunggu'} />
    </MainParentWrapper>
  );
};

export default BillPaymentTab;
