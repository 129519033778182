import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  loginButtonStyle: {
    bottom: normalize(14),
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primary_carnation,
    color: colors.neutral_charcoal,
    width: '90%',
    borderRadius: normalize(30),
  },
  loginButtonTextStyle: { textTransform: 'none' },
  loginButtonDisable: { backgroundColor: colors.disabled_smoke },
  emptyComponentContainer: { height: normalize(200), justifyContent: 'center', alignItems: 'center' },
  emptyTopTextStyle: { marginTop: normalize(20) },
  flatlistContainerStyle: { height: 0 },
  containerStyle: { flex: 1, paddingBottom: normalize(50) },
});

export default styles;
