import MainParentWrapper from '@components/MainParentWrapper';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, FlatList, Linking, Pressable, View } from 'react-native';
import ReasonSelector from '@molecules/ReasonSelector';
import { CustomReasons, ReasonsIncreased } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import {
  COMMON_CONSTANTS,
  TRANSACTION_DETAILS_KEYS,
  TRANSACTION_DETAIL_CONFIG,
  TRANSACTION_SCREEN_ACCOUNT_NAME,
  TRANSACTION_TYPE,
} from '@utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import * as Clipboard from 'expo-clipboard';
import AmplitudeHelper from '@services/amplitude';
import {
  formatUnderScoreLowerCase,
  getChangedField,
  numberWithDots,
  sortTransactionDetailsByPriority,
  excludeReasonsDisplay,
} from '@utils/UtilFunctions';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import MyText from '@atoms/MyText';
import { respSuccess } from '@redux/action/Common';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import InputComponent from '@atoms/InputComponent';
import InfoTextComponent from '@atoms/InfoTextComponent';
import openImagePicker from '@utils/ImagePicker';
import ImagePickerItem from '@molecules/ImagePickerItem';
import { Modalize } from 'react-native-modalize';
import { colors } from '@style/colors';
import { GET_TRANSACTION_REFERENCE_NUMBER } from '@gqlQuery/QueryTransactionList';
import MyModal from '@atoms/MyModal';
import {
  deleteWorkAllowanceImage,
  editTransaction,
  getWorkAllowanceImage,
  setWorkAllowanceImage,
} from '@services/TransactionApi';
import TransactionDetailItem from '@molecules/TransactionDetailItem';
import LoadingModal from '@components/LoadingModal';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  buttons,
  ModalContent,
  ModalHeader,
  returnTransactionStatus,
  voucherCodeComponent,
} from './EditTransactionUtils';
import styles from './style';

const { width: screenWidth } = Dimensions.get('screen');

const MODAL_TYPE = {
  PICKER: 'PICKER',
  IMAGE: 'IMAGE',
};
const NewEditTransaction = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { item } = route.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { reasons } = useSelector((state) => state.TransactionDetails);
  const [imageUri, setImageUri] = useState();
  const [modal, setModal] = useState({ visible: false, type: MODAL_TYPE.PICKER });
  const [axiosCancel, setAxiosCancel] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(false);
  const modalizeRef = useRef(null);
  const reasonsCarousel = useRef();
  const [getReferenceNumber, { data: referenceNumber }] = useLazyQuery(GET_TRANSACTION_REFERENCE_NUMBER, {
    variables: { transactionHistoryId: item.transHistoryId },
    onCompleted: (data) => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      setError(true);
    },
    fetchPolicy: 'no-cache',
  });
  const hasUangKantorRefReasons = item.transactionReasonId === 40 || item.transactionReasonId === 41;
  const showUangKantorImage =
    item.moneyAccount === TRANSACTION_SCREEN_ACCOUNT_NAME['Uang Kantor'] &&
    item.transactionReasonId !== 40 &&
    item.transactionReasonId !== 41; // Uang Kantor related Reasons
  const [category, setCategory] = useState(
    item.transactionType === TRANSACTION_TYPE.EXPENSE
      ? reasons.expense.find((e) => parseInt(e.id, 10) === item.transactionReasonId)
      : reasons.income.find((e) => parseInt(e.id, 10) === item.transactionReasonId),
  );
  const data =
    item.transactionType === TRANSACTION_TYPE.EXPENSE ? chunk(reasons.expense, 12) : chunk(reasons.income, 12);
  const [description, setDescription] = useState(item.description);

  const getImageFromServer = async () => {
    try {
      if (error) setError(false);
      if (!loading) setLoading(true);

      const { imageUrl } = await getWorkAllowanceImage(item.transactionId);

      if (imageUrl.length > 0)
        setImageUri({
          uploading: false,
          uploaded: true,
          uri: imageUrl[0].imageUrl,
          documentId: imageUrl[0].documentId,
        });
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const uploadImageToServer = async (imageUriLocal) => {
    try {
      const controller = new AbortController();
      setAxiosCancel(controller);
      if (!imageUri?.uploading) {
        setImageUri({
          ...imageUriLocal,
          uploading: true,
        });
      }

      fetch(imageUriLocal.uri).then(async (res) => {
        const blob = await res.blob();

        const response = await setWorkAllowanceImage(
          new File([blob], 'Bukti.png', { type: 'image/png' }),
          item.transactionId,
          controller.signal,
        );
        setImageUri({
          ...imageUriLocal,
          uploaded: true,
          uploading: false,
          documentId: response.uploadedFiles[0]?.documentId,
          failed: false,
        });
      });
    } catch (error) {
      setImageUri({ ...imageUriLocal, uploaded: false, uploading: false, failed: true });
      setTimeout(() => {
        setAxiosCancel();
      }, 100);
    }
  };

  const onCloseImagePicker = async () => {
    const x1 = AMPLITUDE_CONSTANTS.workAllowance.image_chosen;
    let failReason = 'user_cancled';
    try {
      const x = AMPLITUDE_CONSTANTS.workAllowance.upload_clicked;
      AmplitudeHelper.logEvent(x.name, {
        [x.attributes.transaction_id]: item.transactionId,
      });
      setModal({ visible: false, type: modal.type });
      const response = await openImagePicker();

      if (!response.didCancel) {
        AmplitudeHelper.logEvent(x.name, {
          [x1.attributes.status]: 'success',
          [x1.attributes.failure_message]: '',
          [x1.attributes.transaction_id]: item.transactionId,
        });
        setImageUri({ ...response.assets[0], uploaded: false, uploading: true });
        await uploadImageToServer(response.assets[0]);
      } else {
        AmplitudeHelper.logEvent(x1.name, {
          [x1.attributes.status]: 'failed',
          [x1.attributes.failure_message]: failReason,
          [x1.attributes.transaction_id]: item.transactionId,
        });
      }
    } catch (error) {
      failReason = 'others';
      AmplitudeHelper.logEvent(x1.name, {
        [x1.attributes.status]: 'failed',
        [x1.attributes.failure_message]: failReason,
        [x1.attributes.transaction_id]: item.transactionId,
      });
    }
  };

  const deleteImage = async () => {
    try {
      if (imageUri?.uploading) {
        axiosCancel.abort('User Cancelled');
        setImageUri();
        return;
      }
      setLoadingDelete(true);
      await deleteWorkAllowanceImage(imageUri?.documentId);
      const x = AMPLITUDE_CONSTANTS.workAllowance.image_deleted;
      AmplitudeHelper.logEvent(x.name, {
        [x.attributes.image_id]: imageUri?.documentId ?? '',
        [x.attributes.transaction_id]: item.transactionId,
      });
      setImageUri();
      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  const editTransactionItem = useCallback(async () => {
    try {
      setLoadingDelete(true);
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.name, {
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.source]: 'transaction_page',
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.transaction_id]:
          item?.transactionId ?? null,
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.parameter_changed]:
          imageUri?.uploaded && item.description.length === 0
            ? 'image_added'
            : getChangedField(item?.transReason, category?.reason, item?.description, description),
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.old_reason_input]:
          item?.transReason ?? null,
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.new_reason_input]: category?.reason ?? '',
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.old_description_input]:
          item?.description ?? null,
        [AMPLITUDE_CONSTANTS.TransactionPage.transactionEditSaved.attributes.new_description_input]:
          description ?? null,
      });
      await dispatch(
        editTransaction(item.transHistoryId, description, parseInt(category.id, 10), category?.reason ?? ''),
      );
      setLoadingDelete(false);
      navigation.pop();
    } catch (e) {
      setLoadingDelete(false);
    }
  }, [category, description]);

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.detailTransactionPage.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.detailTransactionPage.attributes.source]: 'transaction_page',
      [AMPLITUDE_CONSTANTS.TransactionPage.detailTransactionPage.attributes.transaction_id]:
        item?.transactionId ?? null,
    });
  }, []);

  useEffect(() => {
    if (showUangKantorImage) getImageFromServer();

    if (item.transactionReasonId === 40) getReferenceNumber();
    else
      setTimeout(() => {
        setLoading(false);
      }, 50);

    return () => axiosCancel?.abort();
  }, []);

  const transactionDetailItem = useMemo(() => {
    const arr = [];
    const config =
      TRANSACTION_DETAIL_CONFIG[item.moneyAccount][item.transactionReasonId] ??
      TRANSACTION_DETAIL_CONFIG[item.moneyAccount].default;

    if (hasUangKantorRefReasons && !!config?.referenceNumber) {
      arr.push({
        component: (
          <TransactionDetailItem
            key={config.referenceNumber.name}
            title={config.referenceNumber.name}
            value={referenceNumber?.transaction_history_reference[0]?.referenceNumber ?? '-'}
          />
        ),
        priority: config.referenceNumber.priority,
      });
    }
    Object.keys(item).forEach((key) => {
      if (config[key]) {
        let value = item[key];

        if (config[key].isAmount) value = `Rp${numberWithDots(Math.floor(item[key]))}`;
        if (config[key].isDate) value = moment(item[key]).format('DD-MM-YYYY');
        if (config[key].isDonatorCount) value = `${item[key]} Orang`;

        if (value) {
          arr.push({
            component: <TransactionDetailItem key={config[key].name} title={config[key].name} value={value} />,
            priority: config[key].priority,
          });
        }
      }
    });
    sortTransactionDetailsByPriority(arr);
    return arr;
  }, [item, referenceNumber]);

  const logEventCopy = (e, type) => {
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.code]: item.voucherCode ?? null,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item?.transactionCategoryId ?? null),
      [e.attributes.source]: 'transaction_page',
      [e.attributes.status]: type,
      [e.attributes.feature]: 'milvik',
    });
  };

  const onPressCopy = (displayCode) => {
    try {
      if (item.redeemUrl) {
        Linking.openURL(item.redeemUrl);
        return;
      }
      Clipboard.setStringAsync(displayCode);
      dispatch(respSuccess('Kode voucher tersalin!'));
      if (item?.transactionCategoryId === 'BP-18') {
        logEventCopy(AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_clicked, 'success');
      }
    } catch (err) {
      if (item?.transactionCategoryId === 'BP-18') {
        logEventCopy(AMPLITUDE_CONSTANTS.VoucherInventory.copy_code_failed, 'fail');
      }
    }
  };

  const logClicked = (fieldName) => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.fieldClicked.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.fieldClicked.attributes.source]: 'transaction_page',
      [AMPLITUDE_CONSTANTS.TransactionPage.fieldClicked.attributes.field_name]: fieldName,
    });
  };

  const onPressConsultancy = () => {
    Linking.openURL(COMMON_CONSTANTS.productMIlvik);
    const e = AMPLITUDE_CONSTANTS.VoucherInventory.consult_now_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'transaction_page',
      [e.attributes.status]: 'success',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item?.transactionCategoryId ?? null),
    });
  };

  const renderItem = ({ item: passedItem }) => {
    if (excludeReasonsDisplay(passedItem)) return null;
    const isSelected = passedItem.id === category?.id;
    const svgProp = !isSelected ? { fill: colors.blue } : { fill: colors.orange };
    const Component = CustomReasons[passedItem.id] ? CustomReasons[passedItem.id] : CustomReasons[18];
    const setCategoryFromItem = () => {
      setCategory(passedItem);
      modalizeRef.current?.close();
    };

    return (
      <Pressable onPress={setCategoryFromItem} android_ripple={{ color: colors.lightGrey }}>
        <View style={[styles.item, isSelected ? styles.highlight : null]}>
          <View style={[styles.circleBox, isSelected ? { backgroundColor: colors.orange } : styles.itemCircleBox]} />
          <View style={[styles.itemIcon, isSelected ? { borderColor: colors.orange } : null]}>
            <Component {...svgProp} />
          </View>
          <View style={styles.reasonContainer}>
            <MyText isRegular={false} h1 customStyle={styles.itemText} numberOfLines={2}>
              {passedItem.reason}
            </MyText>
          </View>
        </View>
      </Pressable>
    );
  };

  const renderFlatList = ({ item }) => (
    <FlatList
      contentContainerStyle={styles.list}
      data={item}
      numColumns={3}
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderItem}
    />
  );

  const modalHeader = () => (
    <ModalHeader
      activeIndex={activeIndex}
      data={data}
      modalizeRef={modalizeRef}
      reasonsCarousel={reasonsCarousel}
      setActiveIndex={setActiveIndex}
    />
  );

  const setCarouselActiveIndex = (index) => {
    setActiveIndex(index);
  };

  const openReasonCarousel = () => {
    setActiveIndex(0);
    modalizeRef.current?.open();
    logClicked('reason');
  };

  const onCloseModal = () => {
    setModal({ visible: false, type: modal.type });
  };

  const openModal = (type) => {
    if (imageUri?.uploaded) {
      const x = AMPLITUDE_CONSTANTS.workAllowance.image_viewed;
      AmplitudeHelper.logEvent(x.name, {
        [x.attributes.image_id]: imageUri?.documentId ?? '',
        [x.attributes.transaction_id]: item.transactionId,
      });
    }
    if (imageUri?.failed) {
      uploadImageToServer(imageUri);
      return;
    }
    setModal({ visible: true, type });
  };

  return (
    <MainParentWrapper
      isError={error}
      onReload={getImageFromServer}
      loading={loading}
      isScrollable
      containerStyle={styles.mainWrapper}
      isRounded
    >
      <ReasonSelector
        disabled={item.moneyAccount === TRANSACTION_SCREEN_ACCOUNT_NAME['Uang Kantor']}
        onPress={openReasonCarousel}
        icon={ReasonsIncreased[`${item.transactionReasonId}`]}
        selectedReason={category?.reason}
      />
      {voucherCodeComponent(item.token, item.redeemUrl, item.voucherCode, onPressCopy)}
      <MyText h2 customStyle={styles.title} isRegular={false}>
        Detail Transaksi
      </MyText>

      <View style={styles.detailsContainer}>
        {returnTransactionStatus(item.transactionReasonId, item.transStatus)}
        {transactionDetailItem.map((e) => e.component)}
      </View>

      {showUangKantorImage && (
        <>
          <InfoTextComponent
            infoText={`1. Jangan tutup halaman ini sampai bukti transaksi terupload dan Anda kirim \n2. Hanya bisa mengupload 1 bukti transaksi, jadi gabungkan bukti transaksi menjadi 1 file`}
          />
          <ImagePickerItem
            onPressCancel={deleteImage}
            imageUri={imageUri}
            title="Upload Bukti Pembayaran(JPG, PNG)"
            onPress={() => openModal(MODAL_TYPE.PICKER)}
          />
        </>
      )}
      <InputComponent
        multiline
        titleStyle={styles.descriptionTitle}
        value={description}
        onFocus={() => logClicked('description')}
        onChangeText={setDescription}
        title="Keterangan"
      />

      {buttons(!!item.voucherCode, loadingDelete, editTransactionItem, onPressConsultancy)}

      <Modalize ref={modalizeRef} snapPoint={Dimensions.get('screen').height / 1.4} HeaderComponent={modalHeader}>
        <Carousel
          ref={reasonsCarousel}
          itemWidth={screenWidth}
          sliderWidth={screenWidth}
          data={data}
          renderItem={renderFlatList}
          inactiveSlideOpacity={1}
          inactiveSlideScale={1}
          onSnapToItem={setCarouselActiveIndex}
          enableSnap
        />
        <Pagination
          dotsLength={data.length}
          activeDotIndex={activeIndex}
          containerStyle={styles.pagination}
          dotStyle={styles.paginationDot}
          inactiveDotStyle={styles.paginationInactiveDot}
          inactiveDotScale={1}
          animatedDuration={0}
          carouselRef={reasonsCarousel}
        />
      </Modalize>
      <MyModal onPress={onCloseModal} containerStyle={styles.modal} isVisible={modal.visible}>
        {ModalContent(onCloseModal, onCloseImagePicker, imageUri)}
      </MyModal>
      <LoadingModal loading={loadingDelete} />
    </MainParentWrapper>
  );
};

NewEditTransaction.propTypes = {
  navigation: PropTypes.shape({
    naviagte: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      item: PropTypes.shape({
        coinValue: PropTypes.number,
        description: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        moneyAccount: PropTypes.string.isRequired,
        notes: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        redeemUrl: PropTypes.string.isRequired,
        token: PropTypes.number,
        transAmount: PropTypes.string.isRequired,
        transDate: PropTypes.string.isRequired,
        transFee: PropTypes.string.isRequired,
        transHistoryId: PropTypes.string.isRequired,
        transReason: PropTypes.string.isRequired,
        transReceivedAmount: PropTypes.string.isRequired,
        transStatus: PropTypes.string.isRequired,
        transactionCategoryId: PropTypes.string.isRequired,
        transactionId: PropTypes.string.isRequired,
        transactionReasonId: PropTypes.number.isRequired,
        transactionType: PropTypes.string.isRequired,
        voucherCode: PropTypes.number,
      }),
    }).isRequired,
  }).isRequired,
};

NewEditTransaction.defaultProps = {};

export default NewEditTransaction;
