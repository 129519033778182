import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Pressable, SectionList, StyleSheet, Text, View, Dimensions } from 'react-native';
import BillPaymentWrapper from '@components/BillPaymentWrapper';
import { colors } from '@style/colors';
import { numberWithDots } from '@utils/UtilFunctions';
import MyText from '@atoms/MyText';
const { height } = Dimensions.get('screen');

const Item = React.memo(({ item, onPress }) => {
  const isSelected = item?.isSelected ?? false;
  return (
    <View style={[styles.itemContainer, { backgroundColor: isSelected ? colors.orange : colors.white }]}>
      <Pressable onPress={() => onPress(item)} style={styles.pressable} android_ripple={{ color: colors.lightGrey }}>
        <MyText h5 isRegular={false} customStyle={{ color: isSelected ? colors.white : colors.orange }}>
          {numberWithDots(Number.parseInt(item.code?.match(/(\d+)/)) * 1000)}
        </MyText>
        <MyText customStyle={{ color: isSelected ? colors.white : colors.black }} h2>
          Harga : Rp{numberWithDots(Number.parseInt(item.code?.match(/(\d+)/)) * 1000)}
        </MyText>
      </Pressable>
    </View>
  );
});

const ColumnList = ({ list, onPress, headerText, footer }) => {
  const renderItem = useCallback(
    ({ item }) => {
      return <Item item={item} onPress={onPress} />;
    },
    [list],
  );

  const keyExtractor = (item) => {
    return item.id?.toString();
  };

  const renderHeader = () => {
    return (
      <MyText h4 isRegular={false} customStyle={{ color: colors.blue }}>
        Pilih {headerText}:
      </MyText>
    );
  };

  return (
    <View style={{ height: height / 2 }}>
      <FlatList
        bounces={false}
        data={list}
        numColumns={2}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        contentContainerStyle={styles.flatlist}
        ListHeaderComponent={renderHeader}
        ListHeaderComponentStyle={styles.headerStyle}
        ListFooterComponent={footer}
        ListFooterComponentStyle={{ width: '80%', marginVertical: 25 }}
        style={{ flex: 1, height: 0 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    alignSelf: 'flex-start',
    marginHorizontal: 20,
    marginBottom: 10,
  },
  flatlist: {
    paddingTop: 15,
    paddingBottom: 150,
    alignItems: 'center',
  },
  itemContainer: {
    overflow: 'hidden',
    elevation: 5,
    backgroundColor: colors.white,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 10,
    minWidth: '40%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  pressable: {
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export default ColumnList;
