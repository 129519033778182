import React, { useState, useCallback, useEffect } from 'react';
import { View, TouchableOpacity, StyleSheet, StatusBar, FlatList } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import { colors } from '@style/colors';
import Close from '@expo/vector-icons/AntDesign';
import { normalize, normalizeHeight } from '@style/helper';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import { getRewardInfo } from '@services/s3Service';
//import { SvgFromUri } from 'react-native-svg';
import LoadingButton from '@atoms/LoadingButton';
import styles from './style';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { useNavigation, useRoute } from '@react-navigation/native';

const RewardInfo = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { employeeId } = route.params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const fetchTermsAndConditions = async () => {
    try {
      if (error) setError(false);
      const response = await getRewardInfo();
      setData(response);
    } catch (e) {
      setError(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const navigateBack = () => navigation.goBack();

  const logEvent = (e) => {
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.employee_id]: employeeId,
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.mission_information_page,
    });
  };
  const onCloseHandler = () => {
    logEvent(AMPLITUDE_CONSTANTS.missions.cancel_button_clicked);
    navigateBack();
  };

  const onButtonPressHandler = () => {
    logEvent(AMPLITUDE_CONSTANTS.missions.check_mission_clicked);
    navigateBack();
  };

  const renderItem = useCallback(({ item, index }) => {
    return (
      <View style={styles.contentCardContainer}>
        {/* <SvgFromUri uri={item.iconUri} /> */}
        {SVG_ICONS.gamification.rewardInfo[index]}
        <MyText isRegular={false} customStyle={styles.cardTitleStyle}>
          {item.title}
        </MyText>
        <MyText customStyle={styles.cardContentStyle}>{item.description}</MyText>
      </View>
    );
  }, []);

  return (
    <View style={styles.mainContainer}>
      <StatusBar translucent backgroundColor="transparent" />
      <View style={styles.upperContainer}>
        <TouchableOpacity style={styles.closeStyle} onPress={onCloseHandler}>
          <Close name="close" size={normalize(22)} color={colors.white} />
        </TouchableOpacity>
        {data?.header && (
          <View style={styles.textContainer}>
            <MyText isRegular={false} customStyle={styles.textStyling}>
              {data?.header}
            </MyText>
          </View>
        )}
        <View style={styles.assetOneStyling}>{SVG_ICONS.gamification.groundLayer}</View>
        <View style={styles.assetTwoStyling}>{SVG_ICONS.gamification.rewardAsset}</View>
      </View>
      <MainParentWrapper
        isError={error}
        isScrollable
        scrollContainer={styles.scrollableContainerStyle}
        loading={loading}
        containerStyle={styles.mainContentStyle}
      >
        <FlatList
          data={data?.content || []}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderItem}
          contentContainerStyle={styles.contentScrollStyle}
        />
      </MainParentWrapper>

      <LoadingButton
        testId="cekReward"
        textTransform="none"
        onPress={onButtonPressHandler}
        text={data?.buttontitle}
        disabled={false}
        textStyle={styles.loginButtonTextStyle}
        container={styles.loginButtonStyle}
      />
    </View>
  );
};

export default RewardInfo;
