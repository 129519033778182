import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '@services/BillPaymentApi';
import { setUserAccountNumber } from '@redux/action/Transactions';

/* Style Import */
import { smallerText } from '@style/Layouts';
import { LgButton } from '@components/Button';

/* Screen Import */
import Loading from '@screens/Loading';

const PulsaPostpaid = ({ show, dataList, navigation, providerName }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const pageFailed = useSelector((state) => state.Common.pageFailed);
  const { phoneNumber } = useSelector((state) => state.Authentication);

  const dispatch = useDispatch();

  const [accountNumber, setAccountNumber] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setUserAccountNumber(accountNumber));
  }, [accountNumber]);

  useEffect(() => {
    if (pageFailed == 'Internal Server error') {
      navigation.navigate('PageFailed', {
        timeTaken: '24 jam',
        productName: selectedProduct.name,
        category: `Top-Up ${selectedProduct.biller}`,
      });
    }
  }, [pageFailed]);

  const selectPostPaid = async () => {
    setLoading(true);
    await dispatch(selectProduct(dataList[0], phoneNumber));
    setLoading(false);
  };

  if (!show) {
    return <></>;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {pageFailed ? <Text style={styles.pageFailed}>{pageFailed}</Text> : null}
          <View style={styles.container}>
            <Text style={[smallerText, styles.phoneTitle]}>Nomor HP:</Text>
            <Text style={[smallerText, styles.phoneNumber]}>{phoneNumber}</Text>
          </View>
          <View style={styles.providerContainer}>
            <Text style={[smallerText, styles.providerTitle]}>Provider:</Text>
            <Text style={[smallerText, styles.providerName]}>{providerName}</Text>
          </View>
          <LgButton title="Konfirmasi" style={styles.confirmButton} onPress={selectPostPaid} />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pageFailed: {
    fontSize: normalize(12),
    color: '#d40e0b',
    fontFamily: 'Nunito-Bold',
    alignSelf: 'flex-start',
    marginLeft: '7%',
    marginTop: '5%',
  },
  container: { flexDirection: 'column', marginLeft: '8%', marginTop: '5%', alignSelf: 'flex-start' },
  phoneTitle: { fontSize: normalize(15), fontFamily: 'Nunito-Bold', marginRight: '14%', marginBottom: '3%' },
  phoneNumber: { fontSize: normalize(15), fontFamily: 'Nunito-Regular', color: '#454545' },
  providerContainer: {
    flexDirection: 'column',
    marginLeft: '8%',
    marginTop: '5%',
    alignSelf: 'flex-start',
    marginBottom: '10%',
  },
  providerTitle: { fontSize: normalize(15), fontFamily: 'Nunito-Bold', marginRight: '18%', marginBottom: '3%' },
  providerName: { fontSize: normalize(15), fontFamily: 'Nunito-Regular', color: '#454545', marginRight: '3%' },
  confirmButton: { alignSelf: 'center', width: normalize(200), marginBottom: normalizeHeight(15) },
});

export default PulsaPostpaid;
