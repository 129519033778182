import React from 'react';
import { Linking, Text, TouchableOpacity, View } from 'react-native';
import IconButton from '@atoms/IconButton';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { COMMON_CONSTANTS, FEATURE_FLAGS } from '@utils/Constants';
import MyText from '@atoms/MyText';
import { styles } from './style';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import { useNavigation } from '@react-navigation/native';
import { shouldShowButtonBasedOnFeatureFlag, shouldShowName } from './AccountNumberComponentUtils';

const AccountNumberComponent = ({ phoneNumber, name, txnCategory }) => {
  const navigation = useNavigation();
  const [isFeatureAvailale] = useFeatureFlag({
    featureName: FEATURE_FLAGS.PHONE_ENROLL,
  });
  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <IconButton icon={'mobile-alt'} size={30} style={styles.iconButton} iconStyle={{ color: colors.orange }} />

        <View style={styles.nameContainer}>
          {shouldShowName(name)}
          <MyText isRegular={false}>{phoneNumber}</MyText>
        </View>
      </View>

      {shouldShowButtonBasedOnFeatureFlag(isFeatureAvailale.show, navigation, { phoneNumber, name, txnCategory })}
    </View>
  );
};

export default React.memo(AccountNumberComponent);
