import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import Down from '@expo/vector-icons/Entypo';
import React from 'react';
import PropTypes from 'prop-types';
import { View, Pressable } from 'react-native';
import styles from './styles';

const ReasonSelector = ({ onPress, selectedReason, disabled, icon }) => (
  <View style={styles.container}>
    <Pressable disabled={disabled} onPress={onPress} style={styles.button} android_ripple={{ color: colors.greyShade }}>
      <View style={styles.iconContainer}>
        {icon}
        <MyText
          h3
          isRegular={false}
          customStyle={{ ...styles.textReason, color: disabled ? colors.philippineGray : colors.black }}
        >
          {selectedReason}
        </MyText>
      </View>
      <Down name="chevron-down" size={18} color={colors.black} />
    </Pressable>
  </View>
);

ReasonSelector.propTypes = {
  onPress: PropTypes.func.isRequired,
  selectedReason: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
};

ReasonSelector.defaultProps = {};

export default ReasonSelector;
