import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { normalizeHeight } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import Divider from '@atoms/Divider';
import * as Clipboard from 'expo-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { getReferralEncryption } from '@utils/UtilFunctions';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { respError, respSuccess } from '@redux/action/Common';
import { REFERRAL_TNC } from '@gqlQuery/referral';
import { useLazyQuery } from '@apollo/client';
import MainParentWrapper from '@components/MainParentWrapper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import ImageButton from '@atoms/ImageButton/index';
import ReferralHeader from '@atoms/Referral/ReferralHeader';
import ReferralContainer from '@atoms/Referral/ReferralContainer';
import BenifitBox from '@atoms/Referral/BenifitBox';
import CollapsibleTerms from '@atoms/CollapsibleTerms';
import Flow from '@atoms/Referral/Flow';
import RewardBox from '@atoms/Referral/RewardBox';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import ReferralConfigError from '@gaji-gesa/gg-ui-shared/src/atoms/Referral/ReferralConfigError';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { styles } from './style';

function shouleRenderButtonContainer(onCopyToClipBoard, show) {
  if (show) {
    return (
      <View style={styles.bottomButtonContainer}>
        <ImageButton
          testId="salinKode"
          textTransform="none"
          onPress={onCopyToClipBoard}
          text="Salin Kode"
          textStyle={{ color: colors.primary_carnation }}
          styleContainer={styles.copyButtonStyle}
          pressableStyle={styles.pressableStyleContainer}
          iconLeft={<Icon name="content-copy" color={colors.primary_carnation} size={20} />}
        />
      </View>
    );
  }

  return null;
}

const Referral = ({ route }) => {
  const { source } = route.params;
  const { employeeId, employerId } = useSelector((state) => state.Authentication);

  const referralCode = getReferralEncryption(employeeId.toString());
  const [collapsedUsage, setCollapsedUsage] = useState(true);
  const [referralData, setReferralData] = useState(null);
  const [referralLoading, setReferralLoading] = useState(true);
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [getReferralRulesTNC, { error: errorRule }] = useLazyQuery(REFERRAL_TNC, {
    fetchPolicy: 'no-cache',
    variables: { employerId },
    onCompleted: (data) => {
      setReferralData(data?.referral_config[0]);
      setReferralLoading(false);
    },
  });

  useEffect(() => {
    getReferralRulesTNC();
  }, []);

  const onRefreshPage = () => {
    setReferralLoading(true);
    getReferralRulesTNC();
  };

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.TNC.name, {
      [AMPLITUDE_CONSTANTS.Referral.TNC.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.Referral.TNC.attributes.referral_code]: referralCode || null,
    });
  });

  const onCopyToClipBoard = async () => {
    try {
      await Clipboard.setStringAsync(referralCode);
      dispatch(respSuccess('Kode berhasil tersalin.', { positionValue: 62 }));
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopySuccess.name, {
        [AMPLITUDE_CONSTANTS.Referral.CopySuccess.attributes.referral_code]: referralCode || null,
      });
    } catch (e) {
      dispatch(respError('Terjadi kesalahan sistem. \nHarap coba kembali.', { positionValue: 62 }));
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopyFailed.name, {
        [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.referral_code]: referralCode || null,
        [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.failure_message]: 'system error',
      });
    }
  };

  const onPressDropDown = () => {
    setCollapsedUsage(!collapsedUsage);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Dropdown.name, {
      [AMPLITUDE_CONSTANTS.Referral.Dropdown.attributes.action]: collapsedUsage ? 'collapse' : 'expand',
    });
  };

  const isError = errorRule || (!referralLoading && !referralData) || !referralCode;

  const onPressBack = () => navigation.goBack();

  if (!referralLoading && !referralData && !errorRule) {
    return <ReferralConfigError onButtonPress={onPressBack} />;
  }

  return (
    <>
      <MainParentWrapper
        isRounded={false}
        isError={isError}
        loading={referralLoading}
        isScrollable
        onReload={onRefreshPage}
        containerStyle={styles.container}
      >
        <ReferralHeader headerText={referralData?.referralTnc?.header} />

        <ReferralContainer referralCode={referralCode} />

        <Flow />

        <RewardBox />

        <View style={styles.benifitContainer}>
          <BenifitBox
            poinReward={referralData?.referrerRewardAmount}
            title="Buat Kamu"
            description={'yang berhasil mengajak teman \nkerja pakai GajiGesa'}
          />
          <BenifitBox
            poinReward={referralData?.refereeRewardAmount}
            title="Buat Temanmu"
            description={'setelah berhasil melakukan \ntransaksi pertamanya'}
          />
        </View>

        <Divider horizontal customStyle={styles.dividerStyle} />

        <CollapsibleTerms
          dataList={referralData?.referralTnc?.tnc}
          collapsedUsage={collapsedUsage}
          title="Syarat & Ketentuan Program Referral"
          referrerAmt={referralData?.referrerRewardAmount}
          refereeAmt={referralData?.refereeRewardAmount}
          onPressDropDown={onPressDropDown}
        />

        <Divider horizontal customStyle={[styles.dividerStyle, { marginTop: normalizeHeight(0) }]} />
      </MainParentWrapper>

      {shouleRenderButtonContainer(onCopyToClipBoard, !(isError || referralLoading))}
    </>
  );
};

Referral.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
    }),
  }).isRequired,
};
export default Referral;
