const translate = (language: string = 'id'): any => {
  switch (language) {
    case 'id':
      return {
        you_saved: 'Kamu Hemat',
        tap_to_learn_more: 'Cari tahu selengkapnya',
        select_cycle: 'Pilih Periode',
        saving_info: `Jumlah ini adalah margin harga pasar dibanding harga \npembelianmu di GajiGesa.`,
        multiple_cyle: 'Periode Dipilih',
        year: 'Tahun',
        interested_save_more: 'Pingin Lebih Hemat Lagi?',
        transact: 'Transaksi dengan GajiGesa',
        save_upto: 'Hemat s.d',
        buy_now: 'Beli Sekarang',
        graph_warning: 'Jumlah di atas diperbarui pada akhir minggu',
      };
    case 'en':
      return {
        you_saved: 'You saved',
        tap_to_learn_more: 'Tap to learn more',
        select_cycle: 'Select Cycle',
        saving_info: `This amount is the market price margin compared to your \npurchase price in GajiGesa.`,
        multiple_cyle: 'Multiple Cycle',
        year: 'Year',
        interested_save_more: 'Interested to save more?',
        transact: 'Tranact with GajiGesa',
        buy_now: 'Buy Now',
        graph_warning: 'Amounts above will be updated at the end of the week',
        no_transaction: 'You don’t have any transaction in this cycle.',
      };
    default:
      return {};
  }
};

export default translate;
