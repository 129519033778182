import React from "react"
import { View, Text } from 'react-native'
import { Font } from "@style/Fonts";

const NoData = ({ text }) => (
    <View style={{ height: "100%", backgroundColor: "#FFFFFF", justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <Text style={{
            textAlign: 'center',
            fontFamily: Font.Nunito_Regular,
            fontSize: 12,
            lineHeight: 16,
            color: '#979797',
        }}>{text}</Text>
    </View>
)


export {
    NoData
}