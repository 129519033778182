import {
  SHARED_AMPLITUDE_CONSTANTS,
  SHARED_AMPLITUDE_EVENT_SOURCES,
} from '@gaji-gesa/gg-react-shared/src/utils/amplitudeConstants';

export const AMPLITUDE_CONSTANTS = SHARED_AMPLITUDE_CONSTANTS;

export const AMPLITUDE_EVENT_SOURCES = SHARED_AMPLITUDE_EVENT_SOURCES;

// poin_payment_ticked
// poin_payment_failed
// userConfirmedBillPaymentTransaction
// transactionSuccess
// transactionSuccess
// BillPaymentTransactionFailure
// BillPaymentFailed
// selamatPage

// point_payment_ticked
// point_payment_failed
// transaction_requested
// transaction_successful
// transaction_successful
// transaction_failed
// transaction_failed
// success_page

// xxxxx
