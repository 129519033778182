import { StyleSheet, Dimensions } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize } from "@style/helper";
const {width: screenWidth} = Dimensions.get('window')

export const styles = StyleSheet.create({
  amount:{
    fontFamily:Font.Nunito_Regular,
    color:colors.red
  },
  status:{
    fontFamily:Font.Nunito_Regular,
  },
  container:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    // paddingTop: 8,
    marginBottom: 8,
  },
  subContainer:{
    flexDirection:'row',
    alignItems:'center',
    flex:1,
    marginBottom: 8,
  },
  iconContainer:{ 
    height:40,
    width:40,
    borderRadius: 40/2,
    justifyContent:'center',
    alignItems:'center',
  }
})