import { StyleSheet } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize, normalizeHeight } from "@style/helper";

export const styles = StyleSheet.create({

    container:{
        flexDirection:'row',
    },


})