import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { FlatList } from 'react-native';
import MIcon from '@expo/vector-icons/MaterialIcons';
import { useSelector } from 'react-redux';
import ButtonColor from '@atoms/ButtonColor';
import AmplitudeHelper from '@services/amplitude';
import { normalize } from '@style/helper';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import translate from '../translate';
import { productSuggestionData } from '../utils';
import { styles } from './style';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';

const t = translate();
const Footer = (props: any) => {
  const { navigate } = props;
  const employeeId = useSelector((state: any) => state.Authentication.employeeId);
  const onPress = (item) => {
    // AmplitudeHelper.logEvent("Product_Suggestion_Clicked ", {
    //   product: item?.name,
    //   prefix: item?.prefix,
    //   source: "savings insight page",
    //   employeeId,
    // });
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.ProductSuggestionClicked.name, {
      transaction_category_id: 'bp-13',
      source: 'savings insight page',
    });

    navigate('Pembayaran', {
      screen: 'Product',
      initial: false,
      params: { item, source: AMPLITUDE_EVENT_SOURCES.savings_page },
    });
  };

  const onScroll = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.productSuggestionScrolled.name, {
      [AMPLITUDE_CONSTANTS.Savings.productSuggestionScrolled.attributes.source]: 'savings_insight_page',
    });
  };

  const renderItem = ({ item, navigate }) => (
    <Pressable onPress={() => onPress(item)}>
      <View style={styles.cardContainer}>
        <View style={styles.cardLeft}>
          <Text style={styles.cardLeftSubTitle}>{item.subTitle}</Text>
          <Text style={styles.cardLeftTitle}>{item.title}</Text>
          <ButtonColor
            textStyle={styles.cardLeftButtonText}
            isRounded
            buttonStyle={styles.cardLeftButton}
            title={item?.button?.text}
            onPress={() => onPress(item)}
          />
        </View>
        <View style={styles.cardRight}>
          <View style={styles.cardRightIcon}>{SVG_ICONS[item?.category?.icon]}</View>
          <Text style={styles.cardRightTitle}>{item?.category?.title}</Text>
        </View>
        <View style={styles.cardRightTriangle}></View>
        <MIcon name="star" size={normalize(7)} color="#EFCA2E" style={styles.cardRightTopIcon} />
      </View>
    </Pressable>
  );

  return (
    <View style={styles.scene}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{t['interested_save_more']}</Text>
        <View style={styles.cardLeftIcon}>{SVG_ICONS['sparkleStar']}</View>
      </View>
      <Text style={styles.subTitle}>{t['transact']}</Text>
      <View>
        <FlatList
          data={productSuggestionData}
          renderItem={({ item }) => renderItem({ item, navigate })}
          keyExtractor={(item) => `${item.id}`}
          horizontal
          onResponderMove={onScroll}
        />
      </View>
    </View>
  );
};

export default Footer;
