import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { numberWithDots } from '@utils/UtilFunctions';
import styles from './style';

const RewardPoints = ({ totalReward }) => (
  <View style={styles.rewardContainer}>
    <MyText isRegular={false} customStyle={styles.textStyle}>
      Total Hadiah GajiGesa Poin
    </MyText>
    <View style={styles.gamifyIcons}>
      {SVG_ICONS.gamification.coin}
      <MyText isRegular={false} customStyle={styles.rewardStyle}>
        {numberWithDots(totalReward)}
      </MyText>
    </View>
  </View>
);

RewardPoints.propTypes = {
  totalReward: PropTypes.number,
};
RewardPoints.defaultProps = {
  totalReward: 0,
};
export default React.memo(RewardPoints);
