import React, { useEffect } from 'react';
// import BottomSheet from 'reanimated-bottom-sheet';
import { Dimensions } from 'react-native';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { resetInquiry } from '@redux/action/Transactions';
import { setShowTerms } from '@redux/action/Common';

/* Component Import */
import Header from '@atoms/Header';
import PulsaContent from './PulsaContent';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase, getCurrentTimeStamp } from '@utils/UtilFunctions';

/* Style Import */
const { height: screenHeight } = Dimensions.get('window');

const PulsaModal = React.forwardRef(({ handleClose }, ref) => {
  const productName = useSelector((state) => state.Transactions.productName);
  const inquiry = useSelector((state) => state.Transactions.inquiry);
  const showTerms = useSelector((state) => state.Common.showTerms);
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);

  const dispatch = useDispatch();

  useEffect(() => {
    // if(productName == "PLN Postpaid") {
    //   AmplitudeHelper.logEvent('postpaidElectricityPaymentSummary')
    // }else if(inquiry.category == "PDAM") {
    //   AmplitudeHelper.logEvent('postpaidWaterBillSummary')
    // } else if(productName == "Telkomsel Halo"){
    //   AmplitudeHelper.logEvent('postpaidPulsaPaymentSummary')
    // }else{
    //   AmplitudeHelper.logEvent('billPaymentTransactionSummary')
    // }
    let e = AMPLITUDE_CONSTANTS.BillPayment.billPaymentTransactionSummary;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: inquiry?.accountNumber,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(inquiry?.productCode),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
      [e.attributes.source]: 'other_product_page',
    });
  }, []);

  const handlePulsaClose = () => {
    if (showTerms) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.termsPageClosed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
        [e.attributes.timestamp]: getCurrentTimeStamp(),
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
      });
      ref.current.snapTo(0);
      dispatch(setShowTerms(false));
    } else {
      // AmplitudeHelper.logEvent('userClosedBillPaymentModal')
      let e = AMPLITUDE_CONSTANTS.BillPayment.userClosedBillPaymentModal;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.button]: 'x_button',
        [e.attributes.source]: 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
      });
      handleClose();
      dispatch(resetInquiry());
    }
  };

  return (
    <>
      {/* <BottomSheet
       ref={ref}
       snapPoints={[(screenHeight > 700 ? screenHeight * 0.7 : screenHeight * 0.8), -100, (screenHeight - 25)]}
       initialSnap={1}
       borderRadius={10}
       renderContent={() => <PulsaContent handleClose={handleClose} modalRef={ref}/>}
       renderHeader={() => <Header handleClose={handlePulsaClose}/>}
       />     */}
    </>
  );
});

export default PulsaModal;
