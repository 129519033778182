import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { colors } from '@style/colors';
import MyModal from '@atoms/MyModal';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import Close from '@expo/vector-icons/AntDesign';
import { styles } from './style';

const InfoModalSheet = ({
  title,
  description,
  hasTwoButtons,
  buttonText,
  buttonText2,
  icon,
  visible,
  onPressPrimary,
  onPressSecondary,
  onClose,
  primaryLoading,
  secondaryLoading,
}) => (
  <MyModal onPress={onClose} containerStyle={styles.modal} isVisible={visible}>
    <View style={styles.innerContainer}>
      <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <Close name="close" size={20} color={colors.black} />
      </TouchableOpacity>
      <View style={styles.icon}>{icon}</View>
      <View style={styles.textContainer}>
        <MyText isRegular={false} h5 customStyle={styles.title}>
          {title}
        </MyText>
        <MyText>{description}</MyText>
      </View>

      {hasTwoButtons ? (
        <View style={styles.buttonContainer}>
          <LoadingButton
            container={{ borderRadius: 30, flex: 1, marginEnd: 10 }}
            buttonColor={colors.white}
            text={buttonText}
            textColor={colors.orange}
            bordered
            textTransform="capitalize"
            onPress={onPressPrimary}
            loading={primaryLoading}
          />
          <LoadingButton
            container={{ borderRadius: 30, flex: 1 }}
            buttonColor={colors.orange}
            text={buttonText2}
            textTransform="capitalize"
            onPress={onPressSecondary}
            loading={secondaryLoading}
          />
        </View>
      ) : (
        <LoadingButton
          container={styles.cta}
          textTransform="capitalize"
          buttonColor={colors.orange}
          text={buttonText}
          onPress={onPressPrimary}
        />
      )}
    </View>
  </MyModal>
);

InfoModalSheet.defaultProps = {
  buttonText2: '',
  onPressSecondary: null,
  primaryLoading: false,
  secondaryLoading: false,
};

InfoModalSheet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasTwoButtons: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonText2: PropTypes.string,
  icon: PropTypes.element.isRequired,
  visible: PropTypes.bool.isRequired,
  onPressPrimary: PropTypes.func.isRequired,
  onPressSecondary: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  primaryLoading: PropTypes.bool,
  secondaryLoading: PropTypes.bool,
};

export default React.memo(InfoModalSheet);
