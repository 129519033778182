import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import { REWARD_INFO_ROUTE, TERMS_AND_CONDITIONS_ROUTE } from '@utils/Constants';

export const getTermsAndConditions = async () => {
  try {
    const response = await fetch(`${config.MEDIA_STORAGE}${TERMS_AND_CONDITIONS_ROUTE}`, { method: 'get' });
    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    }
  } catch (error) {
    throw error;
  }
};

export const getRewardInfo = async () => {
  try {
    const response = await fetch(`${config.MEDIA_STORAGE}${REWARD_INFO_ROUTE}`, {
      method: 'get',
    });
    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    }
  } catch (error) {
    throw error;
  }
};
