import React from 'react';
import { Text, View, Dimensions, StyleSheet } from 'react-native';
import AIcon from '@expo/vector-icons/AntDesign';

/* Styles */
const { height: screenHeight } = Dimensions.get('window');
import { normalize } from '@style/helper';

/* Screen Import */
import AmplitudeHelper from '@services/amplitude';

const TransactionTabView = ({ currentTab, setCurrentTab }) => {
  return (
    <View style={styles.container}>
      <View accessibilityLabel="riwayatTransaksiTab" style={{ display: 'flex', flexDirection: 'row' }}>
        <AIcon
          style={currentTab === 'feed' ? styles.iconSelected : styles.icon}
          name={'swap'}
          onPress={() => {
            AmplitudeHelper.logEvent('userClickedRiwayat');
            setCurrentTab('feed');
          }}
        />
        <Text
          style={currentTab === 'feed' ? styles.textSelected : styles.text}
          onPress={() => {
            AmplitudeHelper.logEvent('userClickedRiwayat');
            setCurrentTab('feed');
          }}
        >
          Riwayat Transaksi
        </Text>
      </View>
      <View accessibilityLabel="transaksiBulananTab" style={{ display: 'flex', flexDirection: 'row' }}>
        <AIcon
          style={currentTab === 'monthly' ? styles.iconSelected : styles.icon}
          name={'filetext1'}
          onPress={() => {
            AmplitudeHelper.logEvent('userClickedBulanan');
            setCurrentTab('monthly');
          }}
        />
        <Text
          style={currentTab === 'monthly' ? styles.textSelected : styles.text}
          onPress={() => {
            AmplitudeHelper.logEvent('userClickedBulanan');
            setCurrentTab('monthly');
          }}
        >
          Transaksi Bulanan
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '75%',
    justifyContent: 'space-between',
    top: screenHeight > 800 ? -(screenHeight / 1.78) : -(screenHeight / 1.9),
  },
  icon: {
    color: '#d1d1d1',
    fontSize: normalize(20),
    marginRight: 5,
    marginTop: normalize(5),
  },
  iconSelected: {
    color: '#F56B57',
    fontSize: normalize(20),
    marginRight: 5,
    marginTop: normalize(5),
  },
  text: {
    color: '#d1d1d1',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(13),
    maxWidth: normalize(82),
  },
  textSelected: {
    color: 'white',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(13),
    maxWidth: normalize(82),
  },
});

export default TransactionTabView;
