import React, { useCallback, useMemo } from 'react';
import { FlatList, Image, Pressable, StyleSheet, View } from 'react-native';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';
import MyText from '@atoms/MyText';
import AntDesign from '@expo/vector-icons/AntDesign';
import LoadingButton from '@atoms/LoadingButton';

const formatTitle = (title) => {
  const splitTitle = title?.split(' ') ?? [];
  if (splitTitle[0] === 'Voucher') {
    splitTitle.splice(0, 1);
    return splitTitle.join(' ');
  }
  if (splitTitle[splitTitle.length - 1] === 'Voucher') {
    splitTitle.splice(0, splitTitle.length - 1);
    return splitTitle.join(' ');
  }
  return splitTitle.join(' ');
};

const getSelectedItem = (data) => {
  if (data?.length > 0) {
    const findIndex = data?.findIndex((e) => e.isSelected);
    if (findIndex !== -1) {
      return data[findIndex];
    }
    return data[0];
  }
};

const styles = StyleSheet.create({
  voucherNameText: {
    alignSelf: 'center',
    textAlign: 'center',
    maxWidth: '70%',
  },
  selectProdutContainer: {
    width: '100%',
    backgroundColor: colors.white,
    paddingHorizontal: normalize(20),
    paddingBottom: normalize(45),
    paddingTop: 5,
  },
  footer: {
    alignSelf: 'flex-start',
    marginBottom: 10,
    width: '100%',
    position: 'absolute',
    bottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerStyle: {
    alignSelf: 'flex-start',
    marginHorizontal: 20,
    marginBottom: 10,
  },
  flatlist: {
    paddingTop: 15,
    paddingBottom: 50,
    backgroundColor: colors.white,
    //   flexGrow: 1,
    justifyContent: 'center',
  },
  itemContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: 10,
    flex: 1,
    // minWidth: '30%',
    // maxWidth: '40%',

    // alignSelf: 'center',
    borderColor: colors.orange,
    marginTop: 10,
  },
  pressable: {
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: colors.white,
    marginHorizontal: 2,
    marginVertical: 5,
    height: normalizeHeight(45),
    borderColor: colors.orange,
  },
  multiContainer: { flex: 1, backgroundColor: colors.white },
  imageStyle: { height: '100%', width: '100%', resizeMode: 'contain' },
});

const SelectProduct = ({ selectedProduct, onOpen, onPressConfirm }) => (
  <View style={styles.selectProdutContainer}>
    <MyText h4 customStyle={{ color: colors.blue, paddingHorizontal: 5 }} isRegular={false}>
      Pilih Produk:
    </MyText>
    <Pressable
      onPress={onOpen}
      android_ripple={{ color: colors.lightGrey }}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 10,
        paddingHorizontal: 5,
      }}
    >
      <MyText>{selectedProduct}</MyText>
      <AntDesign name="down" color={colors.black} size={20} />
    </Pressable>
    <LoadingButton
      text={'Konfirmasi'}
      buttonColor={colors.orange}
      loadingColor={colors.white}
      //loading={loadingTransactionReq}
      container={{ borderRadius: 40, marginTop: 15, width: '50%' }}
      onPress={onPressConfirm}
    />
  </View>
);

const MultiColumnList = ({ list, onPress, headerText, showTitle = true, setNominalModal, onPressConfirm }) => {
  const getMultiColumnWidth = (index, list) => {
    if (index >= list.length - (list.length % 3) && list.length % 3 !== 0) {
      if (list.length % 3 === 2) return { alignSelf: 'center', width: '55%' };
      return { alignSelf: 'center', width: '28%' };
    }
    return null;
  };

  const renderItem = ({ item, index }) => {
    const isSelected = item?.isSelected ?? false;
    return (
      <View style={[styles.itemContainer]}>
        <View>
          <Pressable
            onPress={() => onPress(item)}
            style={[
              styles.pressable,
              {
                marginVertical: showTitle ? 5 : 10,
                borderWidth: isSelected ? 1 : 0,
                marginHorizontal: showTitle ? 10 : 15,
              },
              getMultiColumnWidth(index, list),
            ]}
            android_ripple={{ color: colors.lightGrey }}
            disabled={isSelected}
          >
            <Image
              source={{ uri: item.imageUrl }}
              style={[styles.imageStyle, item?.data[0]?.categoryId === 15 && { resizeMode: 'cover' }]}
            />
          </Pressable>
        </View>
        {showTitle && (
          <MyText numberOfLines={1} customStyle={styles.voucherNameText} h2>
            {formatTitle(item.title)}
          </MyText>
        )}
      </View>
    );
  };

  const checkIfSelected = useMemo(() => {
    const findIndex = list.findIndex((e) => e.isSelected);
    if (findIndex !== -1) {
      return {
        index: findIndex,
        value: true,
      };
    }
    return {
      index: findIndex,
      value: false,
    };
  }, [list]);

  const keyExtractor = (item) => item.title;

  const renderHeader = () => (
    <MyText h4 isRegular={false} customStyle={{ color: colors.blue }}>
      Pilih {headerText}:
    </MyText>
  );

  const footer = useCallback(() => {
    if (!checkIfSelected.value) {
      return null;
    }
    const getSelectedItemFromSubList = getSelectedItem(list[checkIfSelected.index].data);

    return (
      <SelectProduct
        selectedProduct={getSelectedItemFromSubList?.name}
        onOpen={() => setNominalModal(true)}
        onPressConfirm={() => onPressConfirm(getSelectedItemFromSubList)}
      />
    );
  }, [list, checkIfSelected]);

  return (
    <View style={styles.multiContainer}>
      <FlatList
        data={list}
        numColumns={3}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        contentContainerStyle={styles.flatlist}
        ListHeaderComponent={renderHeader}
        ListHeaderComponentStyle={styles.headerStyle}
        ListFooterComponentStyle={styles.footer}
      />
      {footer()}
    </View>
  );
};

export default MultiColumnList;
