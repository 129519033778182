import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  outerContainer: { paddingHorizontal: normalize(15), paddingVertical: normalizeHeight(8) },
  clipContainer: {
    backgroundColor: colors.secondary_orchid,
    overflow: 'hidden',
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    minHeight: normalizeHeight(58),
  },
  divOne: { width: '20%', justifyContent: 'center', alignItems: 'center', paddingLeft: normalize(8) },
  divTwo: { width: '50%', justifyContent: 'space-evenly' },
  divThree: {
    width: '30%',
    backgroundColor: colors.neutral_cotton,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.7,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderLeftWidth: 0,
    flexDirection: 'row',
    borderColor: colors.neutral_charcoal,
  },
  textOne: { fontSize: normalize(10), color: colors.neutral_cotton, marginTop: normalizeHeight(8) },
  textTwo: { fontSize: normalize(12), color: colors.neutral_cotton, marginVertical: normalizeHeight(2) },
  textThree: { fontSize: normalize(14), color: colors.neutral_cotton, marginBottom: normalizeHeight(8) },
  leftPillar: { left: -9 },
  rightPillar: { right: -23 },
  circleLeft: { borderColor: colors.secondary_orchid },
  circleRight: { borderColor: colors.neutral_charcoal },
  textCopyStyle: {
    marginLeft: normalize(5),
    color: colors.primary_carnation,
    fontSize: normalize(12),
    marginBottom: normalize(1),
  },
  expiryLayer: {
    backgroundColor: colors.blackColor,
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 9,
    opacity: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expiryOverLayContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  expireOverlayText: {
    color: colors.neutral_charcoal,
    backgroundColor: colors.neutral_cotton,
    borderRadius: normalize(12),
    paddingHorizontal: normalize(9),
    paddingVertical: normalize(5),
    fontSize: normalize(12),
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 11,
  },
});

export default styles;
