import React, { useRef, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getCurrentLocationCoordinate } from '@services/messagePermission';
import AmplitudeHelper from '@services/amplitude';
import * as Sentry from '@sentry/browser';
import SalaryInfoStack from '@stacks/SalaryInfoStack';
import loadable from '@loadable/component';

/* Scene Imports */
// const LoginScene = loadable(() => import('./LoginScene'));
// const HomeScene = loadable(() => import('./HomeScene'));

import {
  setDataAccounts,
  setCurrentTransactionPageSelectedAccount,
  setCurrentBpSelectedAccount,
} from '@redux/action/DynamicAccounts';
/* Component Imports */
import CustomToast, { ToastView } from '@atoms/CustomToast';
import { Fragment } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { hideToast, respReset } from '@redux/action/Common';
import { useCallback } from 'react';
import { setInitalAuthData } from '@redux/action/Authentication';
import { createStackNavigator } from '@react-navigation/stack';

import TransactionStack from '@stacks/TransactionStack';
import { ToastAndroid } from 'react-native';
import Modal from '@components/Modal';
import { setFeatureFlag } from '@redux/action/FeatureFlags';
import { setIsTransacted, setIsValidReferral, setRedeemCode, setReferralLoading } from '@redux/action/Referral';
import { setDynamicAccounts } from '@redux/action/DynamicAccounts';
import ProductStack from '@stacks/ProductStack';
import { ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES, OTHER_ACCOUNT_NAMES } from '@utils/Constants';
import { findAccountByName } from '@utils/UtilFunctions';
import { setSeenProfile, setSeenLoan, setSeenZakat } from '@redux/action/Common';
import BankStack from '@stacks/BankStack';
import PhoneEnrollStack from '@stacks/PhoneEnrollStack';
import ZakatStack from '@stacks/ZakatStack';
import HomeScene from './HomeScene';
import LoginScene from './LoginScene';

const HomeSceneStack = createStackNavigator();

const RootScene = () => {
  // FOR CUSTOM TOAST
  const toastRef = useRef();

  const dispatch = useDispatch();
  const { respMessage } = useSelector((state) => state.Common);
  const { accessToken } = useSelector((state) => state.Authentication);
  const { toast, modalType } = useSelector((state) => state.Common);

  // GET TOKENS ETC FROM LOCAL STORAGE
  const getLocalStorageData = useCallback(() => {
    AsyncStorage.multiGet([
      '@token',
      '@phoneNumber',
      '@refreshToken',
      '@userName',
      '@employeeId',
      '@employerId',
      '@userRoles',
      '@isGajiTim',
      '@bURL',
      '@apiKey',
      '@userId',
      '@featureFlag',
      '@isRedeemed',
      '@redeemCode',
      '@dynamicAccounts',
      '@seenProfile',
      '@seenLoan',
      '@seenZakat',
    ])
      .then((response) => {
        const data = {
          accessToken: response[0][1] ?? '',
          phoneNumber: response[1][1] ?? '',
          refreshToken: response[2][1] ?? '',
          userName: response[3][1],
          employeeId: JSON.parse(response[4][1]),
          employerId: JSON.parse(response[5][1]),
          userRoles: JSON.parse(response[6][1]),
          isGajitimEmployee: JSON.parse(response[7][1]),
          bURL: response[8][1],
          apiKey: response[9][1],
          userId: response[10][1],
        };

        batch(() => {
          dispatch(setCurrentBpSelectedAccount(findAccountByName(ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT)));
          dispatch(
            setCurrentTransactionPageSelectedAccount(
              findAccountByName(ACCOUNT_LIST, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT),
            ),
          );
          dispatch(setDataAccounts(ACCOUNT_LIST));
          if (response[13][1]) {
            dispatch(setRedeemCode(JSON.parse(response[13][1])));
            dispatch(setIsValidReferral(false, false));
          }

          if (!JSON.parse(response[12][1] ?? false)) {
            dispatch(setIsTransacted(false));
            dispatch(setReferralLoading(true));
          }

          if (response[14][1]) {
            dispatch(setDynamicAccounts(JSON.parse(response[14][1]) ?? []));
          }
          dispatch(setFeatureFlag(JSON.parse(response[11][1]) ?? []), true);
          dispatch(setInitalAuthData(data));
          dispatch(setSeenProfile(JSON.parse(response[15][1]) || false));
          dispatch(setSeenLoan(JSON.parse(response[16][1]) || false));
          dispatch(setSeenZakat(JSON.parse(response[17][1]) || false));
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  // LOCATION DATA FOR SETTING LAT/LONG
  const getLocation = async () => {
    try {
      const response = await getCurrentLocationCoordinate();
      if (response?.latitude && response?.longitude) {
        AmplitudeHelper.setLocation(response?.latitude.toString(), response?.longitude.toString());
        // Call Amplitude here with lat lng
      } else {
        AmplitudeHelper.setLocation('null', 'null');
      }
    } catch (error) {
      AmplitudeHelper.setLocation('null', 'null');
      // Call Amplitude here with failure for location
    }
  };
  useEffect(() => {
    getLocation();
    getLocalStorageData();
  }, []);

  // HANDLING TOAST
  useEffect(() => {
    if (respMessage.message != '') {
      toastRef.current.show(
        <ToastView message={respMessage.message} type={respMessage.type} />,
        respMessage.toastDuration,
        () => dispatch(respReset()),
      );
    }
  }, [respMessage]);

  useEffect(() => {
    dispatch(hideToast());
  }, [toast.visible]);

  const Toast = ({ visible, message, duration, gravity }) => {
    if (visible) {
      ToastAndroid.showWithGravity(message, gravity ?? ToastAndroid.LONG, duration ?? ToastAndroid.BOTTOM);
      return null;
    }
    return null;
  };

  return (
    <>
      {accessToken === '' ? (
        <LoginScene />
      ) : (
        <HomeSceneStack.Navigator
          mode="modal"
          screenOptions={{ headerShown: false }}
          initialRouteName="HomeScene"
          animationType="slide"
        >
          <HomeSceneStack.Screen name="HomeScene" component={HomeScene} />
          <HomeSceneStack.Screen name="TransactionList" component={TransactionStack} />
          <HomeSceneStack.Screen name="ProductStack" component={ProductStack} />
          <HomeSceneStack.Screen name="PhoneEnrollment" component={PhoneEnrollStack} />
          <HomeSceneStack.Screen name="ZakatStack" component={ZakatStack} />
          <HomeSceneStack.Screen name="BankStack" component={BankStack} />
          <HomeSceneStack.Screen name="SalaryInfoStack" component={SalaryInfoStack} />
        </HomeSceneStack.Navigator>
      )}
      <CustomToast
        ref={toastRef}
        type={respMessage.type}
        positionType={respMessage.positionType}
        positionValue={respMessage.positionValue}
      />
      <Toast {...toast} />

      <Modal type={modalType} />
    </>
  );
};

export default RootScene;
