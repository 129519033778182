import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

const styles = StyleSheet.create({
  textReason: {
    marginHorizontal: 10,
  },
  container: {
    borderRadius: 10,
    overflow: 'hidden',
    justifyContent: 'center',
    elevation: 5,
    marginTop: -5,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    backgroundColor: colors.white,
  },
});

export default styles;
