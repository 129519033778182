import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionList, View } from 'react-native';
import MyText from '@atoms/MyText';
import MainParentWrapper from '@components/MainParentWrapper';
import Tiers from '@components/Tier/Tiers';
import { useLazyQuery } from '@apollo/client';
import { MISSION_DATA_QUERY } from '@gqlQuery/GGMissions';
import {
  getCurrentTierMission,
  getNewMissionSectionList,
  getTiersAndMissions,
  handleNavigation,
} from '@gaji-gesa/gg-react-shared/src/utils/MissionUtils';
import RewardPoints from '@components/Tier/RewardPoints';
import { setMissionData } from '@redux/action/Missions';
import { useDispatch, useSelector } from 'react-redux';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { normalize } from '@style/helper';
import GamificationItem from '@components/GamificationItem';
import AmplitudeHelper from '@services/amplitude';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import Divider from '@atoms/Divider';
import styles from './style';
import { TIER_STATUS_TYPE } from '@utils/Constants';
import { useNavigation, useRoute } from '@react-navigation/native';

const Gamification = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [activeTierIdx, setActiveTierIdx] = useState(0);
  const { tiers, missions } = useSelector((state) => state.Missions);
  const { employeeId } = useSelector((state) => state.Authentication);
  const [loading, setLoading] = useState(route.params?.source !== AMPLITUDE_EVENT_SOURCES.poin_page);
  const dispatch = useDispatch();

  const [getAllMissions, { error: errorMission }] = useLazyQuery(MISSION_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      employeeId,
    },
    onCompleted: (data) => {
      // Tiers and Missions
      const { tiers, missions } = getTiersAndMissions(data);

      // Mission Section List
      const newMission = [];
      getNewMissionSectionList(missions, newMission);

      // Sort missions by COMPLETED and Display order
      newMission.sort((a, b) => Number(a.title.displayOrder) - Number(b.title.displayOrder));

      dispatch(setMissionData(tiers, newMission));
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const onReloadHandler = () => {
    setLoading(true);
    getAllMissions();
  };

  useEffect(() => {
    if (route.params?.source !== AMPLITUDE_EVENT_SOURCES.poin_page) getAllMissions();
  }, []);

  const { noOfCompleted, tierMissions, sectionListFeed } = useMemo(() => {
    if (tiers.length === 0 || tiers[activeTierIdx].status === TIER_STATUS_TYPE.DISABLED)
      return { noOfCompleted: 0, tierMissions: [], sectionListFeed: [], currentTier: [] };
    return getCurrentTierMission(tiers[activeTierIdx].tierId, missions, tiers);
  }, [activeTierIdx, tiers, missions]);

  const onPressInfoHandler = () => {
    const e = AMPLITUDE_CONSTANTS.missions.information_click;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.mission_page,
    });
    navigation.navigate('RewardInfo', {
      employeeId,
    });
  };

  const renderItem = useCallback(
    ({ item }) => (
      <GamificationItem item={item} onPress={() => handleNavigation(navigation, item.mission.description)} />
    ),
    [],
  );

  const renderSectionHeader = ({ section }) => (
    <MyText h3 customStyle={{ marginStart: normalize(12) }} isRegular={false}>
      {section.title.categoryName}
    </MyText>
  );

  const keyExtractor = (item, i) => item.id;

  const emptyList = useCallback(
    () => (
      <View style={styles.emptyList}>
        <View>{SVG_ICONS.gamification.emptyMountain}</View>
        <MyText isRegular={false} style={styles.emptyListText}>
          Nantikan Misi Selanjutnya!
        </MyText>
      </View>
    ),
    [],
  );

  function shouldShowTotalRewardCard() {
    if (tiers.length > 0 && tiers[activeTierIdx].status !== TIER_STATUS_TYPE.DISABLED)
      return (
        <>
          <RewardPoints totalReward={tiers[activeTierIdx]?.tier?.tierReward} />
          <Divider horizontal customStyle={styles.dividerStyle} />
        </>
      );
    return null;
  }

  return (
    <MainParentWrapper
      isScrollable
      loading={loading}
      isError={errorMission || (!loading && tiers.length === 0)}
      onReload={onReloadHandler}
    >
      <Tiers
        onPressInfo={onPressInfoHandler}
        tiers={tiers}
        activeTierIdx={activeTierIdx}
        noOfCompleted={noOfCompleted}
        totalMissions={tierMissions.length}
        setActiveTierIdx={setActiveTierIdx}
      />
      {shouldShowTotalRewardCard()}
      <SectionList
        //   keyboardShouldPersistTaps="never"
        keyExtractor={keyExtractor}
        sections={sectionListFeed}
        renderItem={renderItem}
        ListEmptyComponent={emptyList}
        renderSectionHeader={renderSectionHeader}
        contentContainerStyle={styles.scrolLStyle}
      />
    </MainParentWrapper>
  );
};

Gamification.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
    }),
  }).isRequired,
};
Gamification.defaultProps = {};
export default Gamification;
