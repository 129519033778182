import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
import { Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import UserPhoneList from '@screens/PhoneEnrollments/UserPhoneList';
import AddPhoneNo from '@screens/PhoneEnrollments/AddPhoneNo';
import { useNavigation } from '@react-navigation/native';

const { height: screenHeight } = Dimensions.get('window');
const Stack = createStackNavigator();

const PhoneEnrollStack = () => {
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: colors.blue,
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: colors.blue,
      height: screenHeight / 15,
      elevation: 0,
    },
    headerTitleStyle: {
      fontFamily: Font.Nunito_Bold,
      fontSize: normalize(14),
    },
  };
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="PhoneEnrollmentList"
        component={UserPhoneList}
        options={({ route }) => ({ headerTitle: route?.params?.headerTitle || 'Pilih Nomor HP', ...optionStyle })}
      />

      <Stack.Screen
        name="AddPhoneNo"
        component={AddPhoneNo}
        options={{ headerTitle: 'Tambah Nomor HP', ...optionStyle }}
      />
    </Stack.Navigator>
  );
};

export default PhoneEnrollStack;
