import mainAPIGT from "./apiGajiTim";
import { batch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  setAttendanceData,
  setAttendanceSubmissionToday,
  setPaymentCycles,
  setSalaryType,
  setSummaryData,
  setTodayAttendance,
} from "@redux/action/Attendance";
import moment from "moment";
import { setEmpPaymentCycle } from "@redux/action/TransactionDetails";

// export const requestUserId = async () => {
// 	let userProfileResp = await mainAPI('/user/me', 'get')
// 	return userProfileResp.data.data.id
// }

// Get Payment Cycles
export const getPaymentCycles = () => {

  return async (dispatch) => {
    try {
      let cycles = [];
      let employeeId = await AsyncStorage.getItem("@employeeId");
      let resp = await mainAPIGT(
        `/employees/${employeeId}/payment-cycle-summaries`,
        "get"
      );
      let empCycle = [];
      resp.data.data.forEach((item) => {
        empCycle.push({
          id: item.payment_cycle_id,
          startDate: item.start_date,
          endDate: item.end_date,
        });
        cycles.push({
          startDate: item.start_date,
          endDate: moment(item.end_date).isAfter(moment().format("YYYY-MM-DD"))
            ? moment().format("YYYY-MM-DD")
            : item.end_date,
        });
      });
      dispatch(setEmpPaymentCycle(empCycle));
      dispatch(setPaymentCycles(cycles));
      return {
        cycles,
        empCycle,
      };
    } catch (error) {
      throw error;
    }
  };
};

// Get Attendance Data
export const getAttendanceData = (data) => {
  return async (dispatch) => {
    try {
      let summary = {};
      let attendances = [];
      let employeeId = await AsyncStorage.getItem("@employeeId");
      let resp = await mainAPIGT(
        `/employees/${employeeId}/attendances?start=${data.startDate}&end=${data.endDate}`
      );
      if (resp.data?.data?.salary_type == "HOURLY") {
        summary = {
          firstRow: [
            {
              label: resp.data?.data?.summary[1]?.label,
              value: resp.data?.data?.summary[1]?.value,
            },
            {
              label: resp.data?.data?.summary[3]?.label,
              value: resp.data?.data?.summary[3]?.value,
            },
            {
              label: resp.data?.data?.summary[5]?.label,
              value: resp.data?.data?.summary[5]?.value,
            },
          ],
          secondRow: [
            {
              label: resp.data?.data?.summary[7]?.label,
              value: resp.data?.data?.summary[7]?.value,
            },
            {
              label: resp.data?.data?.summary[8]?.label,
              value: resp.data?.data?.summary[8]?.value,
            },
            {
              label: resp.data?.data?.summary[2]?.label,
              value: resp.data?.data?.summary[2]?.value,
            },
          ],
        };
      } else {
        summary = {
          firstRow: [
            {
              label: resp.data?.data?.summary[0]?.label,
              value: resp.data?.data?.summary[0]?.value,
            },
            {
              label: resp.data?.data?.summary[3]?.label,
              value: resp.data?.data?.summary[3]?.value,
            },
          ],
          secondRow: [
            {
              label: resp.data?.data?.summary[5]?.label,
              value: resp.data?.data?.summary[5]?.value,
            },
            {
              label: resp.data?.data?.summary[8]?.label,
              value: resp.data?.data?.summary[8]?.value,
            },
            {
              label: resp.data?.data?.summary[7]?.label,
              value: resp.data?.data?.summary[7]?.value,
            },
          ],
        };
      }

      const todayAttendance = resp.data.data.attendances.findIndex(
        (x) => x.attendance_date == moment().format("YYYY-MM-DD")
      );
      attendances = resp.data.data.attendances;
      if (todayAttendance !== undefined) {
        attendances = attendances.filter(
          (item) => item != resp.data.data.attendances[todayAttendance]
        );
      }

      batch(() => {
        dispatch(
          setTodayAttendance(resp.data.data.attendances[todayAttendance])
        );
        dispatch(setSummaryData(summary));
        dispatch(setAttendanceData(attendances));
        dispatch(setSalaryType(resp.data?.data?.salary_type));
      });
    } catch (error) {
  
    }
  };
};

export const submissionAttendance = (data) => {
  return async (dispatch, getState) => {
    try {
      const attendanceId = getState().Attendance.todayAttendance.attendance_id;
      const response = await mainAPIGT(
        `/attendances/${attendanceId}/submission`,
        "put",
        data
      );
    
      dispatch(setAttendanceSubmissionToday(response.data?.data));
    } catch (error) {

      throw error;
    }
  };
};
