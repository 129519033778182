import React from 'react';
import IconButton from '@atoms/IconButton';
import { Linking, Text, TouchableOpacity, View } from 'react-native';
import MyText from '@atoms/MyText';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { COMMON_CONSTANTS, BP_NAMES } from '@utils/Constants';
import MCI from '@expo/vector-icons/MaterialCommunityIcons';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';
import { styles } from './style';

export function shouldShowName(name) {
  if (name) {
    return (
      <MyText isRegular={false} h5 customStyle={styles.iconName}>
        {name}
      </MyText>
    );
  }
  return null;
}

function openContactsScreen(navigation, { phoneNumber, name, txnCategory }) {
  const e = AMPLITUDE_CONSTANTS.PhoneEnroll.change_number_clicked;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.source]: formatUnderScoreLowerCase(BP_NAMES[txnCategory]),
    [e.attributes.transaction_category_id]: txnCategory,
  });

  navigation.navigate('PhoneEnrollment', {
    screen: 'PhoneEnrollmentList',
    params: { isTransact: true, phoneNumber, ownerName: name, txnCategory },
  });
}

export function shouldShowButtonBasedOnFeatureFlag(isFeatureAvailale, navigation, userMeta) {
  if (isFeatureAvailale) {
    return (
      <TouchableOpacity style={styles.Chips} onPress={() => openContactsScreen(navigation, userMeta)}>
        <View style={styles.Frame1}>
          <Text style={styles.Default}>Ganti</Text>

          <View style={styles.containerIcon}>
            <MCI name="contacts" style={styles.Contacts} />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  return (
    <IconButton
      testId="accountSupportButton"
      icon="times-circle"
      onPress={() => Linking.openURL(COMMON_CONSTANTS.support)}
      style={styles.support}
      iconStyle={{ color: colors.orange }}
    />
  );
}
