import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '@style/helper';

export const styles = StyleSheet.create({
  principleRow: {
    flexDirection: 'row',
    paddingLeft: normalize(12),
    justifyContent: 'space-between',
    paddingVertical: normalize(10),
  },
  principleAmtStyle: { color: colors.success_cactus, marginLeft: normalize(12) },
  metaRow: {
    flexDirection: 'row',
    marginTop: normalize(2),
    backgroundColor: colors.neutral_mist,
    justifyContent: 'space-evenly',
    marginHorizontal: normalize(10),
    marginVertical: normalize(8),
    borderRadius: normalize(12),
    paddingVertical: normalize(8),
  },
  metaTitleStyle: { fontSize: normalize(12), marginBottom: normalize(5) },
  metaTagStyle: { fontSize: normalize(9), color: colors.warning_darkSunflower },
  metaInfoStyle: { fontSize: normalize(12), marginTop: normalize(3) },
  tenureBubble: {
    borderRadius: normalize(38),
    height: normalize(20),
    width: normalize(20),
    backgroundColor: colors.warning_softSunflower,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tenureTxtStyle: {
    fontSize: normalize(12),
    color: colors.blackColor,
  },
  containerAdj: { marginLeft: normalize(18) },
  installAmtTxt: { fontSize: normalize(12), marginTop: normalize(3) },
  bottomButtonContainer: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: normalize(5),
    backgroundColor: colors.neutral_cotton,
    width: '100%',
    elevation: 0.5,
    shadowColor: colors.blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    flex: 1,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingVertical: normalize(2),
  },
  copyButtonStyle: {
    borderWidth: 1.2,
    borderColor: colors.primary_carnation,
    borderRadius: normalize(50),
    width: '90%',
    alignSelf: 'center',
    backgroundColor: colors.primary_carnation,
  },

  pressableStyleContainer: { flex: 1, alignItems: 'center', justifyContent: 'center' },
  pressableLinkContainer: {
    flexDirection: 'row',
    width: '88%',
    alignItems: 'center',
    paddingBottom: normalize(3),
  },
  pressableTitle: {
    fontSize: normalize(13),
    color: colors.secondary_orchid,
    textDecoration: 'underline',
    marginLeft: normalize(8),
  },
});
