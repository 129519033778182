import MyText from '@atoms/MyText';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import React from 'react';
import { View } from 'react-native';
import { FlatList } from 'react-native';
import { normalize } from '@style/helper';
import VoucherCard from '../VoucherCard';
import styles from './style';

const VoucherLists = ({ route }) => {
  const { voucherListData, metaData } = route;
  const renderItem = ({ item }) => (
    <VoucherCard
      voucherCode={item.voucherCode}
      status={item.status}
      expiryDate={item.expiryDate}
      transactionCategoryId={metaData.transactionCategoryId}
    />
  );
  const renderEmptyContent = () => (
    <View style={styles.emptyComponentContainer}>
      {SVG_ICONS.voucherInventory.icVoucherEmpty}
      <MyText isRegular={false} customStyle={styles.emptyTopTextStyle}>
        Tidak Ada Voucher
      </MyText>
      <MyText>Yuk, beli voucher telekonsultasi sekarang!</MyText>
    </View>
  );

  return (
    <View style={styles.containerStyle}>
      <FlatList
        data={voucherListData}
        maxToRenderPerBatch={5}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        ListEmptyComponent={renderEmptyContent}
        contentContainerStyle={styles.flatlistContainerStyle}
      />
    </View>
  );
};

export default VoucherLists;
