import Amplitude from 'amplitude-js';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import { trackEngagement } from '@utils/engagementUtils';

const amplitude = Amplitude.getInstance();

const AmplitudeHelper = {
  init: () => {
    amplitude.init(config.AMPLITUDE_API_KEY);
    // amplitude().setEventUploadPeriodMillis(10000);
    // amplitude.setEventUploadMaxBatchSize(10);
    // amplitude.setEventUploadThreshold(10);
  },
  logEvent: (eventName, data) => {
    try {
      amplitude.logEvent(eventName, data);
      trackEngagement(data, eventName);
    } catch (error) {}
  },
  setUserId: (userID, employerID) => {
    try {
      // set the user id
      // const amplitude = new RNAmplitude('0f127543d2b24040956d3a7536a2c495')
      const sendingUserId = userID;
      amplitude.setUserId(sendingUserId.toString());
      amplitude.setUserProperties({
        environment: config.ENV_SENTRY,
        employerId: employerID,
      });
    } catch (error) {}
  },
  setLocation: async (lat, lng) => {
    // const amplitude = new RNAmplitude('0f127543d2b24040956d3a7536a2c495')
    amplitude.setUserProperties({
      Latitude: lat,
      Longitude: lng,
    });
  },
  setUserProperties: (data) => {
    // const amplitude = new RNAmplitude('0f127543d2b24040956d3a7536a2c495')
    amplitude.setUserProperties(data);
  },
  setCustomAttributes: (data) => {
    RNInsider.getCurrentUser().set;
  },
  clearUserProperties: () => {
    amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
