import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';

export const styles = StyleSheet.create({
  indicatorStyle: {
    height: normalizeHeight(4.2),
    zIndex: 10,
    width: normalize(23),
    alignSelf: 'center',
    position: 'absolute',
    backgroundColor: colors.orange,
    borderRadius: normalize(27),
  },
  newBadgeContainer: {
    height: normalize(12),
    zIndex: 10,
    width: normalize(25),
    alignSelf: 'center',
    position: 'absolute',
    marginTop: normalize(-5),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.orange,
    borderRadius: normalize(27),
  },
  textStyle: { fontSize: normalize(8), color: '#fff', marginLeft: normalize(-2) },
});
