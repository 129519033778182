import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import React, { useMemo } from 'react';
import { Image, Pressable, TouchableOpacity, View } from 'react-native';
import Close from '@expo/vector-icons/AntDesign';
import MIcon from '@expo/vector-icons/MaterialIcons';
import { ANDROID_RIPPLE_COLOR } from '@utils/Constants';
import TransactionDetailItem from '@molecules/TransactionDetailItem';
import { getStatusAndColor } from '@utils/UtilFunctions';
import LoadingButton from '@atoms/LoadingButton';
import styles from './style';

export const voucherCodeComponent = (token, redeemUrl, voucherCode, onPress) => {
  const title = token || voucherCode ? 'Kode Voucher' : 'Klik disini untuk tukar voucher';
  const getValue = () => {
    if (token) return token;
    if (redeemUrl && redeemUrl !== 'N/A') return redeemUrl;
    if (voucherCode) return voucherCode;
    return '';
  };
  const value = getValue();
  const onPressCopy = () => {
    onPress(value);
  };

  if (redeemUrl) {
    return (
      <View style={styles.voucherCodeContainer}>
        <View>
          <MyText h4 isRegular={false}>
            {value}
          </MyText>
        </View>

        <Close onPress={onPressCopy} name="arrowright" color={colors.blue} size={20} />
      </View>
    );
  }

  if (token || voucherCode)
    return (
      <View style={styles.voucherCodeContainer}>
        <View>
          <MyText h2>{title}</MyText>
          <MyText h4 isRegular={false}>
            {value}
          </MyText>
        </View>

        <MIcon onPress={onPressCopy} name="file-copy" color={colors.blue} size={20} />
      </View>
    );

  return null;
};

export const ModalHeader = ({ showHeader, modalizeRef, activeIndex, reasonsCarousel, setActiveIndex, data }) => {
  const onCloseModal = () => {
    const {
      current: { close },
    } = modalizeRef;
    if (close) close();
  };

  const onPressPrev = () => {
    reasonsCarousel.current.snapToPrev();
    setActiveIndex((index) => index - 1);
  };

  const onPressNext = () => {
    reasonsCarousel.current.snapToNext();
    setActiveIndex((index) => index + 1);
  };

  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity onPress={onCloseModal} style={styles.closeButtonContainer}>
        <Close name="close" size={25} color={colors.grey} />
      </TouchableOpacity>
      <MyText isRegular={false} customStyle={styles.headerTitle}>
        Alasan Penarikan Uang:
      </MyText>
      <View style={styles.headerSubContainer}>
        {activeIndex ? (
          <TouchableOpacity onPress={onPressPrev} style={styles.leftButton}>
            <MIcon name="double-arrow" size={19} color={colors.orange} style={{ transform: [{ rotate: '180deg' }] }} />
            <MyText customStyle={styles.buttonText}>Sebelumnya</MyText>
          </TouchableOpacity>
        ) : (
          <View />
        )}
        {activeIndex !== data.length - 1 && (
          <TouchableOpacity onPress={onPressNext} style={styles.rightButton}>
            <MIcon name="double-arrow" size={18} color={colors.orange} />
            <MyText style={styles.buttonText}>Selanjutnya</MyText>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export const ModalContent = (onCloseModal, onCloseImagePicker, imageUri) => {
  if (!imageUri?.uri) {
    return (
      <View style={styles.modalContainer}>
        <TouchableOpacity onPress={onCloseModal} style={styles.closeButton}>
          <Close name="close" size={20} color={colors.black} />
        </TouchableOpacity>
        <MyText h5 isRegular={false}>
          Upload Bukti Pembayaran
        </MyText>
        <MyText customStyle={styles.modalText}>
          Hanya bisa mengupload 1 bukti transaksi, jadi gabungkan bukti transaksi menjadi 1 file
        </MyText>
        <Pressable onPress={onCloseImagePicker} android_ripple={ANDROID_RIPPLE_COLOR} style={styles.modalButton}>
          <MIcon name="photo" size={20} />
          <MyText customStyle={styles.modalButtonText}>Dari Galeri</MyText>
        </Pressable>
      </View>
    );
  }
  return (
    <View style={styles.imageModalContainer}>
      <TouchableOpacity onPress={onCloseModal} style={styles.closeButton}>
        <Close name="close" size={20} color={colors.white} />
      </TouchableOpacity>
      <Image resizeMode="contain" style={styles.modalImage} source={{ uri: imageUri?.uri }} />
    </View>
  );
};

export const returnTransactionStatus = (reasonId, status) => {
  if (reasonId !== 34) {
    const transactionStatus = getStatusAndColor(status);
    return (
      <TransactionDetailItem
        isStatus
        statusColor={transactionStatus.color}
        title="Status"
        value={transactionStatus.text}
      />
    );
  }
  return null;
};

export const buttons = (hasMilvikVoucherCode, loadingDelete, editTransactionItem, onPressConsultancy) => {
  if (hasMilvikVoucherCode) {
    return (
      <View style={styles.twoButtonContainer}>
        <LoadingButton
          buttonColor={colors.orange}
          loading={loadingDelete}
          onPress={editTransactionItem}
          container={styles.simpanButton}
          text="Simpan"
        />
        <LoadingButton
          container={styles.consultancyButton}
          buttonColor={colors.orange}
          onPress={onPressConsultancy}
          text="Konsultasi"
        />
      </View>
    );
  }

  return (
    <LoadingButton
      buttonColor={colors.orange}
      container={styles.saveButton}
      loading={loadingDelete}
      onPress={editTransactionItem}
      text="Simpan"
    />
  );
};
