import React, { useState } from 'react';
import { FlatList, Image, Pressable, TouchableOpacity, View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';
import LoadingButton from '@atoms/LoadingButton';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import ToolTip from '@atoms/CustomTooltip';
//import ToolTip from '../ToolTip';
import { TOOLTIP_CONTENT } from '@utils/Constants';

const ReferralApplied = ({ text, container, toolTip }) => {
  const [localToolTip, setLocalToolTip] = useState(false);

  const openTooltip = () => setLocalToolTip(true);
  const claseTooltip = () => setLocalToolTip(false);
  return (
    <Pressable onPress={openTooltip} style={{ ...styles.container, ...container }}>
      <ToolTip
        positionValue={12}
        isVisible={localToolTip}
        placement={'DOWN'}
        description={toolTip.visible ? TOOLTIP_CONTENT['rewardFailed'] : TOOLTIP_CONTENT['reward']}
        onClose={claseTooltip}
      >
        <View>{SVG_ICONS.giftSmall}</View>
      </ToolTip>
      {text ? (
        text
      ) : (
        <MyText customStyle={{ width: '80%', marginStart: 5 }} h2>
          Kode referral berhasil dipakai. <MyText isRegular={false}>GajiGesa Poin</MyText> akan ditambahkan setelah
          transaksi ini sukses
        </MyText>
      )}
    </Pressable>
  );
};

export default React.memo(ReferralApplied);
