import { StyleSheet } from 'react-native';
import { normalize } from '@style/helper';
import { Font } from '@style/Fonts';
import { colors } from '@style/colors';

const styles = StyleSheet.create({
  text: {
    color: '#292929',
    textAlign: 'center',
    fontSize: normalize(10),
    marginTop: 3,
    maxWidth: normalize(80),
    fontFamily: Font.Nunito_Regular,
  },
  textPressed: {
    color: colors.blue,
    textAlign: 'center',
    fontSize: normalize(10),
    marginTop: 3,
    maxWidth: normalize(80),
    fontFamily: Font.Nunito_Regular,
  },
  list: {
    // justifyContent: "center",
    alignItems: 'flex-start',
    alignSelf: 'center',
  },
  item: {
    margin: 6,
    marginVertical: 8,
    width: 100,
    height: 90,
    padding: 12,
    paddingTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#BFBFBF',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  highlight: {
    borderWidth: 2,
    borderColor: colors.orange,
    elevation: 7,
  },
  itemIcon: {
    height: 40,
    width: 40,
    color: '#3863F0',
    // borderWidth: 1,
    // borderColor: '#3863F0',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    color: '#454545',
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 14,
    marginTop: 5,
    fontFamily: Font.Nunito_Bold,
  },
  circleBox: {
    position: 'absolute',
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colors.orange,
    top: 5,
    right: 5,
  },
});

export default styles;
