import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StatusBar, useWindowDimensions, Linking } from 'react-native';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MainParentWrapper from '@components/MainParentWrapper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import Info from '@components/VoucherInventory/Info';
import VoucherLists from '@components/VoucherInventory/VoucherList';
import LoadingButton from '@atoms/LoadingButton';
import { FETCH_EMPLOYEE_VOUCHER } from '@gqlQuery/milvikVoucher';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { respError } from '@redux/action/Common';
import LoadingModal from '@components/LoadingModal';
import {
  BP_DYNAMIC_ACCOUNTS,
  COMMON_CONSTANTS,
  DYNAMIC_ACCOUNTS_TYPE,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  TOOLTIP_CONTENT,
  VOUCHER_INV_STATUS,
} from '@utils/Constants';
import Popup from '@components/VoucherInventory/Popup';
import {
  accountByRuleVoucherScreen,
  getAvailableBalance,
  getDynamicAccountListByAccount,
  getListOfDynamicAccounts,
  subtractFromWorkAllowance,
} from '@utils/UtilFunctions';
import RequestTransactionSheet from '@components/RequestTransactionSheet';
import AmplitudeHelper from '@services/amplitude';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getProductListByCategory } from '@services/TransactionApi';
import { store } from '@enhancedStore/store';
import { clearReferral } from '@redux/action/Referral';
import { confirmBpTransaction, preCheckBp, redeemPoins, requestBillPaymentTransaction } from '@services/TransactionApi';
import { setCoinBalance } from '@redux/action/Coins';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { setDataAccounts } from '@redux/action/DynamicAccounts';
import { formatUnderScoreLowerCase, setBillPaymentReason } from '@utils/UtilFunctions';
import { sortVouchersByPurchase } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import styles from './style';

const renderScene = SceneMap({
  infoScreen: Info,
  voucherList: VoucherLists,
});
const renderTabBar = (props) => (
  <TabBar
    {...props}
    getLabelText={({ route }) => route.title}
    style={styles.tabBarStyle}
    activeTintColor={colors.neutral_cotton}
    inactiveTintColor={colors.secondary_orchid}
    tabStyle={styles.tabStyle}
    labelStyle={styles.labelStyle}
    inactiveColor={styles.inActiveTabbarStyle}
    indicatorStyle={styles.indicatorStyle}
    indicatorContainerStyle={{ paddingEnd: 20 }}
  />
);
const VoucherInventoryScreen = ({ route, navigation }) => {
  const layout = useWindowDimensions();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [oosDisabled, setOOSDisabled] = useState(false);
  const [vouchersData, setVouchersData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const { employeeId } = useSelector((state) => state.Authentication);

  const { useCoin } = route.params;

  const { item } = route.params;

  const { isValidCode, redeemCode, isTransacted } = useSelector((state) => state.Referral);

  const { coinBalance } = useSelector((state) => state.Coins);

  const [selectedNominal, setNominalValue] = useState([]);

  const [isError, setError] = useState(false);

  const {
    usedBalance,
    todayBalanceFloor,
    workingDays,
    workedDays,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    lockPeriod,
  } = useSelector((state) => state.Withdrawal);

  const [referralToolTip, setReferralToolTip] = useState({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
  const [referralPrecheckFail, setReferralPrecheckFail] = useState(false);
  const { dynamicAccounts, dataAccounts, currentBpSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const { workAllowanceBillPaymentList } = useSelector((state) => state.NewBillPayment);
  const currentSelectedAccountName = currentBpSelectedAccount.constantAccountName;

  const [routes, setRoutes] = useState([
    { key: 'infoScreen', title: 'Produk' },
    {
      key: 'voucherList',
      title: 'Voucher Saya',
      metaData: { transactionCategoryId: formatUnderScoreLowerCase(item.prefix) },
    },
  ]);

  const newAvailableBalance = useMemo(() => {
    return getAvailableBalance(
      salaryWithCappedAmount,
      todayBalanceFloor,
      workingDays,
      workedDays,
      maxWithdrawalAmount,
      usedBalance,
    );
  }, [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.BillPayment.ProductPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route.params?.source ?? 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });

    return () => {
      const e1 = AMPLITUDE_CONSTANTS.BillPayment.userClickedBack;
      AmplitudeHelper.logEvent(e1.name, {
        [e1.attributes.source]: route.params?.source ?? 'other_product_page',
        [e1.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
        [e1.attributes.referral_code]: store.getState().Referral.redeemCode || null,
      });
    };
  }, [route.params]);

  useEffect(() => {
    if (index === 0) {
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.milvik_product_tab_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: route.params?.source ?? 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      });
    }
    if (index === 1) {
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.milvik_vouchers_tab_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: route.params?.source ?? 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      });
    }
  }, [index]);

  const [accounts, setAccounts] = useState([]);

  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);

  const accountByRule = useCallback(
    accountByRuleVoucherScreen(
      dataAccounts,
      lockPeriod,
      newAvailableBalance,
      dynamicAccountDetails,
      currentSelectedAccountName,
    ),
    [lockPeriod, newAvailableBalance, dynamicAccountDetails, dataAccounts],
  );

  const getAccoutByTxnResponse = useCallback(
    (response) => {
      let isFromPoins = useCoin && coinBalance > 0;
      let isSubtotalWithinPoin = response.data.data.subtotal <= coinBalance;
      let isSubtotalLessOfEWABalance = response.data.data.subtotal > newAvailableBalance;

      if (lockPeriod || (isFromPoins && isSubtotalWithinPoin) || isSubtotalLessOfEWABalance) return accounts[1];
      return accounts[0];
    },
    [coinBalance, useCoin, newAvailableBalance, lockPeriod],
  );

  const [loadingTransactionReq, setLoadingTransactionReq] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(accountByRule());
  const { phoneNumber } = useSelector((state) => state.Authentication);
  const [transactionReq, setTransactionReq] = useState();
  const [transactionReqModal, setTransactionReqModal] = useState(false);

  const [isFeatureAvailale] = useFeatureFlag({ featureName: FEATURE_FLAGS.DYNAMIC_ACCOUNTS_POINS });

  const setAccountList = useCallback(() => {
    const dynamicAccountList = getDynamicAccountListByAccount(
      dataAccounts,
      BP_DYNAMIC_ACCOUNTS,
      DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    );
    // Checking if current selected product category is eligible for work allowance
    let productFoundUnderWorkAllowance = false;
    let accountList = [];
    const findCategory = workAllowanceBillPaymentList.find((e) => e.title === item.menu);
    if (findCategory) {
      const findProduct = findCategory.data[0].list.findIndex((e) => e.prefix === item.prefix);
      if (findProduct !== -1) {
        accountList = dynamicAccountList;
        setAccounts(dynamicAccountList);
        productFoundUnderWorkAllowance = true;
      } else
        accountList = dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
      setAccounts(accountList);
    } else {
      accountList = dynamicAccountList;
      setAccounts(dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE));
    }

    setSelectedAccount(accountByRule(productFoundUnderWorkAllowance, accountList));
  }, [dataAccounts, workAllowanceBillPaymentList, item]);

  const checkBlackoutPeriod = () => {
    if (lockPeriod && coinBalance == 0) {
      setLoadingTransactionReq(false);
      dispatch(respError('Transaksi hanya bisa dilakukan pada masa penarikan'));
      return true;
    }
    return false;
  };
  const navParamsHandler = (prevState, routeIdx, dataLoad) => {
    const newPrevState = prevState;
    newPrevState[routeIdx] = { ...prevState[routeIdx], ...dataLoad };
    return newPrevState;
  };

  useEffect(() => {
    setAccountList();
  }, [dataAccounts]);

  const [fetchEmployeeVoucher] = useLazyQuery(FETCH_EMPLOYEE_VOUCHER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const sortedVouchers = sortVouchersByPurchase(data?.voucher_list ?? []);
      setRoutes((prevState) => navParamsHandler(prevState, 1, { voucherListData: sortedVouchers }));
      setVouchersData(data?.voucher_list);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const getNominalAmount = useCallback(async () => {
    try {
      const resp = await getProductListByCategory(item.id, phoneNumber);
      if (resp.status == 200 || resp.status == 201) {
        if (
          typeof resp.data.data === 'object' &&
          !Array.isArray(resp.data.data) &&
          Object.keys(resp.data.data).length > 0
        ) {
          const nominal = Object.keys(resp.data.data) || [];
          setRoutes((prevState) =>
            navParamsHandler(prevState, 0, {
              displayNominal: resp.data?.data[nominal][0]?.amount ?? 0,
              item: {
                phoneNumber,
                transaction_category_id: formatUnderScoreLowerCase(resp.data.data[nominal][0].categoryPrefix),
                product_code: resp.data.data[nominal][0]?.code,
                reason: formatUnderScoreLowerCase(setBillPaymentReason(resp.data.data[nominal][0].biller).reason),
                reason_id: setBillPaymentReason(resp.data.data[nominal][0].biller).id,
              },
            }),
          );
          setNominalValue(resp.data.data[nominal][0]);
          fetchEmployeeVoucher({ variables: { productCode: resp.data.data[nominal][0]?.code } });
        } else {
          setLoading(false);
          setError(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, [item, phoneNumber]);

  useEffect(() => {
    getNominalAmount();
  }, []);

  const onRefreshHandler = () => {
    setLoading(true);
    setError(false);
    getNominalAmount();
  };

  const referralPrecheck = useCallback(async () => {
    try {
      referralPrecheckFail && setReferralPrecheckFail(false);
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
      if (checkBlackoutPeriod()) return;
      setLoadingTransactionReq(true);
      const response = await preCheckBp(selectedNominal, phoneNumber, redeemCode);

      requestSelectedTransaction();
    } catch (error) {
      setReferralPrecheckFail(true);
      setReferralToolTip({ visible: true, message: error?.response?.data?.message });
      requestSelectedTransaction();
    }
  }, [
    phoneNumber,
    redeemCode,
    referralPrecheckFail,
    selectedNominal,
    accounts,
    coinBalance,
    selectedAccount,
    lockPeriod,
  ]);

  const requestSelectedTransaction = useCallback(async () => {
    try {
      setLoadingTransactionReq(true);

      const response = await requestBillPaymentTransaction(
        selectedNominal,
        phoneNumber,
        selectedAccount.constantAccountName,
        dynamicAccounts,
      );

      setTransactionReq(response.data.data);
      setLoadingTransactionReq(false);

      //setSelectedAccount(getAccoutByTxnResponse(response));

      setTransactionReqModal(true);
      let e = AMPLITUDE_CONSTANTS.BillPayment.billPaymentTransactionSummary;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.account_number]: phoneNumber,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal.categoryPrefix),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedNominal?.code),
        [e.attributes.reason]: formatUnderScoreLowerCase(setBillPaymentReason(selectedNominal.biller).reason),
        [e.attributes.reason_id]: setBillPaymentReason(selectedNominal.biller).id,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    } catch (error) {
      setLoadingTransactionReq(false);
      dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
    }
  }, [phoneNumber, selectedNominal, accounts, coinBalance, selectedAccount, lockPeriod]);

  const confirmTransaction = async () => {
    try {
      setTransactionReqModal(false);
      setLoadingTransactionReq(true);
      let response = null;
      let confirmBPResponse = null;
      let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedNominal?.code ?? ''),
        [e.attributes.reason]: formatUnderScoreLowerCase(setBillPaymentReason(selectedNominal.biller).reason),
        [e.attributes.reason_id]: setBillPaymentReason(selectedNominal.biller).id,
        [e.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.source]: 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal?.categoryPrefix),
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });

      if (isFeatureAvailale.show) {
        if (selectedAccount.optionChangedByUser) {
          response = await requestBillPaymentTransaction(
            selectedNominal,
            phoneNumber,
            selectedAccount.constantAccountName,
            dynamicAccounts,
          );
        }
        const newTxnReqId = selectedAccount.optionChangedByUser ? response.data.data.id : transactionReq.id;

        confirmBPResponse = await confirmBpTransaction(
          newTxnReqId,
          selectedNominal.biller,
          redeemCode,
          selectedAccount.constantAccountName,
        );

        dispatch(clearReferral());
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));

        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.POINTS) {
          if (coinBalance - transactionReq.subtotal >= 0) {
            dispatch(setCoinBalance(coinBalance - transactionReq.subtotal));
          }
        } else if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.WORK_ALLOWANCE) {
          const newData = subtractFromWorkAllowance(dataAccounts, transactionReq.subtotal);
          dispatch(setDataAccounts(newData));
        }
      } else {
        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.POINTS) {
          await dispatch(
            redeemPoins(transactionReq.subtotal, selectedNominal.categoryPrefix, selectedNominal.code, phoneNumber),
          );
        } else {
          confirmBPResponse = await confirmBpTransaction(
            transactionReq.id,
            selectedNominal.biller,
            redeemCode,
            selectedAccount.constantAccountName,
          );
          dispatch(clearReferral());
          AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        }
      }

      setLoadingTransactionReq(false);

      const e2 = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;
      navigation.popToTop();

      navigation.navigate('TransactionList', {
        screen: 'ProductPurchaseConfirm',
        params: {
          productName: transactionReq.productName,
          category: item.name,
          voucherData: confirmBPResponse?.data?.data,
          [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
            selectedNominal?.categoryPrefix ?? item.prefix,
          ),
          [e2.attributes.product_code]: formatUnderScoreLowerCase(
            selectedNominal?.code ?? transactionReq?.productCode ?? '',
          ),
          [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
          [e.attributes.reason]: formatUnderScoreLowerCase(setBillPaymentReason(selectedNominal.biller).reason),
          [e.attributes.reason_id]: setBillPaymentReason(selectedNominal.biller).id,
          [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
          [e2.attributes.fee_charged]: transactionReq.fee,
          [e2.attributes.source]: 'other_product_page',
          [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
        },
      });

      AmplitudeHelper.logEvent(e2.name, {
        [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(
          selectedNominal?.categoryPrefix ?? item.prefix,
        ),
        [e2.attributes.product_code]: formatUnderScoreLowerCase(
          selectedNominal?.code ?? transactionReq?.productCode ?? '',
        ),
        [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.reason]: formatUnderScoreLowerCase(setBillPaymentReason(selectedNominal.biller).reason),
        [e.attributes.reason_id]: setBillPaymentReason(selectedNominal.biller).id,
        [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e2.attributes.fee_charged]: transactionReq.fee,
        [e2.attributes.source]: 'other_product_page',
        [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
      });

      setTransactionReqModal(false);
      setLoadingTransactionReq(false);
    } catch (error) {
      setLoadingTransactionReq(false);
      setTransactionReqModal(false);
      if (error?.response?.data?.message) {
        if (error?.response?.data?.message === 'Balance tidak tersedia!') {
          dispatch(respError('Saldo Gaji dan Poin belum mencukupi untuk transaksi ini'));
          return;
        }
        dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
      }

      if (error?.response?.data?.error === 'REFERRAL_CODE_NOT_APPLICABLE') {
        dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        dispatch(setIsTransacted(true));
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'not first transaction',
        });
      } else if (error?.response?.data?.error === 'INVALID_REFERRAL_CODE') {
        dispatch(setIsTransacted(false));
        dispatch(setIsValidReferral(false, false));
        dispatch(setRedeemCode(''));
        dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'invalid code',
        });
      } else if (isValidCode) {
        if (isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        else dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'system error',
        });
      } else {
        dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
      }

      let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;

      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal?.categoryPrefix),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedNominal?.code ?? ''),
        [e.attributes.product_price]: transactionReq.requestAmount,
        [e.attributes.reason]: formatUnderScoreLowerCase(setBillPaymentReason(selectedNominal.biller).reason),
        [e.attributes.reason_id]: setBillPaymentReason(selectedNominal.biller).id,
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        failure_message: formatUnderScoreLowerCase(
          error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.',
        ),
      });
    }
  };

  const onPressModal = () => {
    setTransactionReqModal(false);
    let e = AMPLITUDE_CONSTANTS.BillPayment.userCanceledBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.button]: 'batal',
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedNominal?.categoryPrefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
  };
  const changeSelectedAccount = (account) => {
    const sourceAccount = accountByRule();
    account.optionChangedByUser = sourceAccount.constantAccountName !== account.constantAccountName;
    setSelectedAccount(account);
    if (account.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
    }
  };

  const openKonsulatasi = () => {
    Linking.openURL(COMMON_CONSTANTS.productMIlvik);
    const e = AMPLITUDE_CONSTANTS.VoucherInventory.consult_now_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'product_page',
      [e.attributes.status]: 'success',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
    });
  };

  const hasNoPurchasedVoucher = () => {
    const purchasedVouchers = vouchersData.filter((v) => v.status === VOUCHER_INV_STATUS.PURCHASED);
    return purchasedVouchers.length === 0;
  };

  const onPressConsult = () => {
    if (hasNoPurchasedVoucher()) {
      const e = AMPLITUDE_CONSTANTS.VoucherInventory.consult_now_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'product_page',
        [e.attributes.status]: 'fail',
        [e.attributes.failure_reason]: 'vouchers_not_purchased',
      });
      setPopupVisible(true);
      return;
    }
    openKonsulatasi();
  };
  const onPressVoucherPurchase = () => {
    setLoadingTransactionReq(true);
    referralPrecheck();
  };
  const onPopupCloseHandler = (type) => {
    const e = AMPLITUDE_CONSTANTS.VoucherInventory.consult_popup_closed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: type,
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
    });
    setPopupVisible(false);
  };

  return (
    <View style={styles.mainContainer}>
      <StatusBar translucent backgroundColor="transparent" />
      <View style={styles.upperContainer}>
        <View style={styles.assetThreeStyling}>{SVG_ICONS.voucherInventory.milvikLogo}</View>
        <View style={styles.textContainer}>
          <MyText isRegular={false} customStyle={styles.textStyling}>
            Telekonsultasi Dokter 24/7 Tanpa Batas
          </MyText>
        </View>
        <View style={styles.assetOneStyling}>{SVG_ICONS.voucherInventory.milvikLogo}</View>
        <View style={styles.assetFourStyling}>{SVG_ICONS.voucherInventory.backgroundLay}</View>
      </View>
      <MainParentWrapper
        isError={isError}
        isScrollable={false}
        scrollContainer={styles.scrollableContainerStyle}
        loading={false}
        onReload={onRefreshHandler}
        isRounded
        parentStyle={{ backgroundColor: 'transparent' }}
      >
        <TabView
          lazy
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={{ width: layout.width }}
        />
      </MainParentWrapper>

      <View style={styles.bottomButtonContainerStyle}>
        <LoadingButton
          testId="cekData"
          textTransform="none"
          onPress={onPressConsult}
          text={'Konsultasi'}
          textStyle={{ ...styles.loginButtonTextStyle, ...styles.leftButtonText }}
          container={[styles.loginButtonStyle, styles.leftButtonStyle]}
        />
        <LoadingButton
          testId="cekData"
          textTransform="none"
          onPress={onPressVoucherPurchase}
          text={oosDisabled ? 'Voucher Habis' : 'Beli Voucher'}
          //   disabled={false}
          disabledStyle={styles.loginButtonDisable}
          textStyle={styles.loginButtonTextStyle}
          container={styles.loginButtonStyle}
        />
      </View>
      <LoadingModal loading={loading || loadingTransactionReq} />
      <RequestTransactionSheet
        selectedAccount={selectedAccount}
        setSelectedAccount={changeSelectedAccount}
        accounts={accounts}
        visible={transactionReqModal}
        transactionReq={transactionReq}
        lockPeriod={lockPeriod}
        onPressModal={onPressModal}
        prefix={item.prefix}
        productId={item.id}
        onPressConfirm={confirmTransaction}
        isValidCode={isValidCode}
        toolTip={referralToolTip}
        setTooltip={setReferralToolTip}
      />
      <Popup visible={popupVisible} onCloseModal={onPopupCloseHandler} />
    </View>
  );
};

VoucherInventoryScreen.propTypes = {};
VoucherInventoryScreen.defaultProps = {};
export default VoucherInventoryScreen;
