import React from 'react';
import { View, Text, Dimensions, Platform, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import { normalize, normalizeHeight } from '@style/helper';
import { Font } from '@style/Fonts';
import { colors } from '@style/colors';
import { MenuIcons } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import Indicator from '@molecules/TabBar/Indicator/index';
import HeaderRight from '@components/HeaderRight';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

// HEADER STYLES
const ScreenOptions = {
  headerTitle: null,
  title: '',
  cardStyle: { flex: 1 },
  headerLeft: () => (
    <Text
      style={{
        color: '#FFF',
        fontSize: normalize(17),
        marginLeft: 30,
        fontFamily: Font.Nunito_Bold,
      }}
    >
      GajiGesa
    </Text>
  ),
  headerStyle: {
    backgroundColor: '#3863F0',
    borderBottomColor: '#3863F0',
    shadowRadius: 0,
    shadowOffset: {
      height: 0,
    },
    // height: screenHeight/15
    height: screenHeight / 10,
  },

  headerRight: () => <HeaderRight />,
};

//NAVBAR STYLES
const NavBarOptions = {
  style: {
    borderTopStartRadius: normalize(14),
    borderTopEndRadius: normalize(14),
    backgroundColor: colors.blue,
    elevation: 10,
    //  marginTop: -12,
    borderColor: '#909090',
    paddingTop: 8,
  },
  labelStyle: {
    fontSize: normalize(12),
    color: '#909090',
    paddingBottom: 5,
    fontFamily: Font.Nunito_Regular,
  },
};

const shouldRenderTabBarIcon = ({ focused }, name) => {
  const Component = MenuIcons[name];
  const svgProps = focused ? { fill: colors.orange } : null;

  const shouldRenderIcon = () => (
    <View style={{ paddingTop: normalize(7) }}>
      <Component {...svgProps} />
    </View>
  );

  return (
    <View>
      <Indicator focused={focused} name={name} />
      {shouldRenderIcon()}
    </View>
  );
};

const shouldRenderTabBarBackground = () => (
  <View
    style={{
      borderWidth: 0.2,
      flex: 1,
      borderColor: '#F4F4F4',
      borderTopStartRadius: 14,
      borderTopEndRadius: 14,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.3,
      shadowRadius: 4.65,
      elevation: 8,
      zIndex: 10,
      backgroundColor: colors.white,
    }}
  />
);

const shouldRenderLabel = ({ focused }, labelName) => (
  <MyText
    customStyle={{
      marginBottom: normalize(12),
      fontSize: normalize(11),
      color: focused ? colors.orange : '#909090',
    }}
  >
    {labelName}
  </MyText>
);

const tabIconOptions = (name, labelName) => ({
  tabBarIcon: (props) => shouldRenderTabBarIcon(props, name),
  headerShown: false,
  tabBarButtonComponent: (props) => <Pressable {...props} />,
  tabBarBackground: shouldRenderTabBarBackground,
  tabBarLabel: (props) => shouldRenderLabel(props, labelName),
});

// BACKGROUND
const background = {
  borderTopColor: '#3863F0',
  borderTopWidth: screenHeight / 4,
  width: screenWidth,
  position: 'absolute',
  right: 0,
};

// LOGIN FORM
const formStyle = {
  backgroundColor: '#FFF',
  borderColor: '#DEDEDE',
  borderStyle: 'solid',
  borderRadius: 30,
  maxHeight: screenHeight * 0.45,
  // maxHeight: screenHeight * 0.4,
  width: screenWidth * 0.9,
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowRadius: 10,
  shadowColor: '#5c5c5c',
  shadowOffset: { width: 2, height: 2 },
  shadowOpacity: 0.7,
  elevation: 8,
  marginTop: 30,
};
const tallerFormStyle = {
  backgroundColor: '#FFF',
  borderColor: '#DEDEDE',
  borderStyle: 'solid',
  borderRadius: 30,
  maxHeight: screenHeight * 0.4,
  width: screenWidth * 0.9,
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowRadius: 10,
  shadowColor: '#5c5c5c',
  shadowOffset: { width: 2, height: 2 },
  shadowOpacity: 1,
  elevation: 8,
  marginTop: 30,
};
const inputStyle = {
  inputContainer: {
    borderColor: '#D1D1D1',
    borderWidth: 1,
    flexDirection: 'row',
    borderRadius: 25,
    overflow: 'hidden',
    width: '90%',
    height: normalizeHeight(50),
  },
  leftSubContainer: {
    borderRightWidth: 1,
    width: '28.5%',
    borderColor: '#D1D1D1',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  codeTextStyle: {
    fontSize: normalize(17),
    color: '#F56B57',
  },
  textContainer: {
    color: '#F56B57',
    flex: 1,
    fontFamily: Font.Nunito_Bold,
    fontSize: normalize(14),
    paddingLeft: '6%',
    paddingRight: '6%',
    backgroundColor: '#f8f9f9',
  },
  phoneNumberStyle: {
    color: '#F56B57',
  },
};
const otpStyle = {
  fieldStyle: {
    width: '85%',
    height: normalizeHeight(50),
    marginBottom: normalizeHeight(25),
    marginLeft: '8%',
  },
  numStyle: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: 'black',
    color: 'black',
    fontSize: normalize(20),
    width: normalize(50),
    // height: normalizeHeight(20),
    height: normalizeHeight(45),
    paddingTop: 8,
  },
};

// INFORMASI
const infoIcon = {
  padding: 10,
  backgroundColor: '#F56B57',
  borderColor: '#F56B57',
  borderRadius: 50,
  fontSize: normalize(15),
  maxWidth: normalize(40),
  maxHeight: normalizeHeight(40),
  color: 'white',
  marginRight: 10,
};

/* BACKGROUND AND CARDS */
const container = {
  backgroundColor: '#fff',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: screenHeight > 700 ? screenHeight : screenHeight * 1.1,
  marginTop: -5,
};
// ONLY USED IN BILL PAYMENTS
const backgroundCard = {
  backgroundColor: 'white',
  width: '100%',
  alignSelf: 'center',
  paddingBottom: normalize(10),
  borderTopEndRadius: 20,
  borderTopStartRadius: 20,
  shadowColor: '#000000',
  shadowOffset: {
    width: 0,
    height: 7,
  },
  paddingTop: normalize(15),
  shadowOpacity: 0.2,
  shadowRadius: 4,
  elevation: 5,
  flex: 1,
};
const LgCard = {
  height: screenHeight / 1.7,
  minHeight: normalizeHeight(300),
  width: screenWidth > 600 ? normalize(screenWidth * 0.7) : normalize(screenWidth * 0.8),
  backgroundColor: '#FFF',
  borderRadius: 16,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
  bottom: '37%',
  marginTop: 10,
};
const XlCard = {
  maxHeight: normalize(450),
  width: screenWidth / 1.1,
  backgroundColor: '#FFF',
  borderRadius: 16,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
  marginTop: 10,
  bottom: '33%',
};
const MdCard = {
  height: Platform.OS === 'ios' ? normalizeHeight(screenHeight / 2.8) : normalizeHeight(screenHeight / 2.7),
  minHeight: normalizeHeight(250),
  width: screenWidth / 1.1,
  backgroundColor: '#FFF',
  borderRadius: 16,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
};
const SmCard = {
  height: Platform.OS === 'ios' ? screenHeight / 14 : screenHeight / 13.5,
  width: screenWidth / 1.1,
  backgroundColor: '#FFF',
  borderRadius: 16,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
  top: -(screenHeight / 4.3),
  marginBottom: screenHeight / 10,
};

/* PAYSLIP STYLES */
const payslip = {
  backgroundCard: {
    backgroundColor: 'white',
    width: '100%',
    alignSelf: 'center',
    paddingBottom: 50,
    borderTopEndRadius: 20,
    borderTopStartRadius: 20,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    paddingTop: normalize(25),
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
    flex: 1,
    marginTop: screenHeight > 700 ? screenHeight / 10 : screenHeight / 9,
  },
  text4: {
    fontFamily: Font.Nunito_Regular,
    textAlign: 'center',
    alignSelf: 'flex-start',
    fontSize: normalize(12),
    color: '#454545',
  },
  text3: {
    fontFamily: Font.Nunito_Regular,
    textAlign: 'center',
    alignSelf: 'flex-start',
    fontSize: normalize(12),
    color: '#454545',
    marginBottom: 5,
  },
  text2: {
    fontSize: normalize(16),
    fontFamily: 'Nunito Regular',
    color: '#365D9D',
    flex: 0.7,
    marginLeft: 7,
  },
  textContainer3: {
    flexDirection: 'column',
    marginTop: 10,
    marginLeft: '5%',
  },
  text1: {
    fontSize: normalize(16),
    fontFamily: Font.Nunito_Bold,
    color: '#365D9D',
    flex: 0.25,
    borderRightWidth: 1,
  },

  loadingContainer: {
    marginVertical: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: '#0053DC',
    fontSize: 18,
  },

  textContainer1: {
    flexDirection: 'row',
    marginTop: 10,
    marginHorizontal: '5%',
    marginBottom: '10%',
  },
  subtextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1.9,
  },
  subtext1: {
    fontFamily: Font.Nunito_Regular,
    textAlign: 'center',
    alignSelf: 'flex-start',
    fontSize: normalize(12),
    marginBottom: 5,
    color: '#979797',
  },

  subtext2: {
    fontFamily: Font.Nunito_Regular,
    textAlign: 'center',
    alignSelf: 'flex-start',
    fontSize: normalize(12),
    color: '#979797',
  },
  textContainer2: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginHorizontal: '5%',
    paddingBottom: 8,
    borderBottomWidth: 2,
    borderBottomColor: '#2CBE96',
  },
  slipContainer: {
    flexDirection: 'column',
    marginTop: 20,
    marginLeft: '5%',
  },
  slipText: {
    fontFamily: Font.Nunito_Regular,
    flex: 1,
    alignSelf: 'flex-start',
    fontSize: normalize(12),
    color: '#454545',
    marginBottom: 5,
    marginRight: 50,
    textAlign: 'left',
  },
  slipDetailContainer: {
    marginTop: 20,
    marginHorizontal: '5%',
  },
  slipDetailText: {
    fontSize: normalize(16),
    fontFamily: Font.Nunito_Bold,
    color: '#365D9D',
    flex: 0.25,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    paddingBottom: 8,
  },
  linearGradientContainer: {
    flexDirection: 'row',
    marginHorizontal: normalize(50),
    alignSelf: 'center',
    alignItems: 'center',
    marginVertical: normalize(15),
  },
  gradientMainContainer: {
    flexDirection: 'column',
    //   alignItems: 'center'
  },
  linearGradient: {
    borderWidth: 1,
    borderRadius: 14,
    padding: 10,

    marginHorizontal: 8,
    minWidth: normalize(50),
  },
  gradientText: {
    fontSize: normalize(20),
    textAlign: 'center',
    // paddingHorizontal: 10,
  },
  gradientOutText: {
    marginTop: 15,
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(10),
    color: '#454545',
    textAlign: 'center',
  },
  mapContainer: {
    flexDirection: 'row',
    marginHorizontal: '2%',
    marginTop: 10,
  },
  mapText1: {
    justifyContent: 'flex-start',
    flex: 1,
    fontFamily: Font.Nunito_Bold,
    fontSize: normalize(12),
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginEnd: 3,

    textAlign: 'center',
  },
  kehadiran: {
    justifyContent: 'space-around',
    flex: 1.2,
    fontFamily: Font.Nunito_Bold,
    fontSize: normalize(12),
    marginHorizontal: 2,
    paddingVertical: 5,
    alignSelf: 'center',
    textAlign: 'center',
    height: '100%',
  },
  map2Container: {
    flexDirection: 'column',
    paddingVertical: 5,

    marginHorizontal: '2%',
    marginTop: 10,
    marginBottom: '20%',
  },
  map2Text1: {
    flex: 0.5,
    fontFamily: Font.Nunito_Regular,
    alignItems: 'center',
    fontSize: normalize(11),
    marginBottom: 10,
    textAlign: 'center',
  },
  mapSubContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#F2F5F8',
    marginHorizontal: '5%',
    //    paddingBottom: 8,
    paddingBottom: 5,
  },
  maptextContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    width: '30%',
    marginLeft: '2%',
  },
  maplabel: {
    fontSize: normalize(10),
    alignSelf: 'flex-start',
    borderRadius: 20,
    paddingVertical: 2,
    paddingHorizontal: 12,

    fontFamily: Font.Nunito_Bold,
  },
  map3SubContainer: {
    // marginTop: '-8%',
    marginTop: '0%',
    // paddingTop: 10,
    flexDirection: 'row',
    // paddingBottom: 15,
  },
  map3Text1: {
    fontSize: normalize(11),
    fontFamily: Font.Nunito_Regular,
    justifyContent: 'space-between',
    flex: 2.2,
    alignSelf: 'center',
    marginLeft: '3%',
  },
  iconContainer: {
    marginBottom: 10,
    marginStart: 2,
    marginEnd: 2,
  },
  iconText: {
    fontFamily: Font.Nunito_Regular,
    color: '#979797',
  },
  iconIndicator: {
    flexDirection: 'row',
    marginHorizontal: normalize(15),
    alignItems: 'center',
  },
  iconIndicationContainer: {
    flexDirection: 'row',

    alignItems: 'center',
    marginVertical: normalize(5),
  },
};

/* Table Styles */
const tableStyles = {
  header: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    backgroundColor: '#F7F7F7',
    maxHeight: normalizeHeight(45),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    paddingVertical: 5,
  },
  headerTransaksiDetail: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    backgroundColor: '#F7F7F7',
    height: normalizeHeight(55),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    paddingTop: 10,
    paddingLeft: 10,
    textAlign: 'center',
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Bold,
    color: '#454545',
    maxWidth: normalize(70),
    marginLeft: 6,
  },
  headerTransaksiDetailText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: normalize(11),
    fontFamily: Font.Nunito_Bold,
    color: '#454545',
    maxWidth: normalize(70),
    marginHorizontal: 6,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingVertical: 10,
    height: normalizeHeight(60),
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
  },
  reason: {
    fontSize: normalize(15),
    alignSelf: 'center',
    marginTop: 7,
    padding: 3,
    borderRadius: 5,
    borderWidth: 1,
    overflow: 'hidden',
  },
  rowTextColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};

export {
  ScreenOptions,
  NavBarOptions,
  tabIconOptions,
  formStyle,
  tallerFormStyle,
  inputStyle,
  otpStyle,
  background,
  SmCard,
  LgCard,
  XlCard,
  MdCard,
  infoIcon,
  backgroundCard,
  container,
  payslip,
  tableStyles,
};
