import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ArrowButton } from '@components/Button';
import { normalizeHeight } from '@style/helper';

const DateSelector = ({ date, leftDisabled, rightDisabled, dateLeft, dateRight, isOrange = false, containerStyle }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <ArrowButton facing={'left'} isOrange={isOrange} disabled={leftDisabled} onPress={dateLeft} />
      <Text style={styles.date}>{date}</Text>
      <ArrowButton facing={'right'} isOrange={isOrange} disabled={rightDisabled} onPress={dateRight} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '95%',
    height: 30,
    alignSelf: 'center',
    marginTop: normalizeHeight(5),
  },
  date: {
    width: '70%',
    textAlign: 'center',
    fontFamily: 'Nunito-Bold',
    color: 'white',
    fontSize: 14,
    lineHeight: 21,
  },
});

export default DateSelector;
