import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import { colors } from '@style/colors';
import {
  DRAWER_NAVIGATION_TYPES,
  COMMON_CONSTANTS,
  VOUCHER_TEXT_CONSTANTS,
  VOUCHER_BILLER_DATA,
  WITHDRAW_REASONS,
  USER_ROLES,
  PAYSLIP,
  ATTENDANCE_COLORS,
  ATTENDANCE_ICONS,
  KETENTUAN_LAYANAN,
  KETENTUAN_LAYANAN_TITLES,
  KETENTUAN_LAYANAN_LIST_1,
  KETENTUAN_LAYANAN_LIST_2,
  KETENTUAN_LAYANAN_LIST_3,
  KETENTUAN_LAYANAN_LIST_4,
  KETENTUAN_LAYANAN_LIST_5,
  TRANSACTION_HISTORY,
  SAVINGS_TOUR_TYPES,
  TERMS_N_CONDITIONS,
  TOOLTIP_CONTENT,
  TERMS,
  LIST_TYPES,
  CATEGORY,
  POSTPAID_VALIDATION_VALUE,
  BP_CATEGORY_NAMES,
  FEATURE_FLAGS,
  PWA_REFFERAL_LINK,
  BP_TOP_TAB_LABEL,
  STORIES_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  REWARD_INFO_ROUTE,
  DYNAMIC_ACCOUNTS_TYPE,
  ERROR_CODES,
  LOGIN_INFO_SHEET_KEYS,
  MESSAGGE_TYPE,
  CHECK_DATA_DROPDOWN_LIST,
  MISSION_STATUS,
  MISSION_GOAL_TYPE,
  TIER_STATUS_TYPE,
  UPDATE_TYPE,
  MILVIK_CONST,
  VOUCHER_INV_STATUS,
  VOUCHER_INV_ASSETS,
  OTHER_ACCOUNT_NAMES,
  DYNAMIC_ACCOUNT_NAMES,
  TRANSACTION_LIST_DYNAMIC_ACCOUNTS,
  HOMEPAGE_DYNAMIC_ACCOUNTS,
  TRANSACTION_SCREEN_ACCOUNT_NAME,
  BP_DYNAMIC_ACCOUNTS,
  TRANSACTION_TYPE,
  TRANSACTION_DETAILS_KEYS,
  TRANSACTION_DETAIL_CONFIG,
  LOAN_ATTENTION_TEXT,
  BOTTONSHEET_KEYS,
  LOAN_TERMS,
  BP_NAMES,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import {
  ACCOUNT_LIST,
  BOTTOMSHEET_CONTENT,
  LOAN_ICON_INFO,
  LOAN_CATEGORY_INFO,
  LOAN_APPLICATION_STATUS,
  LOAN_STATUS_TYPES,
  BANK_CATEGORY_INFO,
  BANK_TRANSFER_ICON_INFO,
  ZAKAT_CATEGORY_INFO,
  ZAKAT_ICON_INFO,
} from '@gaji-gesa/gg-ui-shared/src/uiUtils/uiConstants';

export const FIREBASE_DYNAMIC_LINK_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${config.DYNAMICLINK_API_KEY}`;
export const JSON_URLS = {
  marketingBanner: `${config.MEDIA_STORAGE}marketing_banners/BannersV3.json`,
};

export const GAMIFICATION_DE_PALLETE = {
  1: {
    currentTier: {
      backColor: colors.clover,
      circleColor: colors.darkClover,
      icon: SVG_ICONS.gamification.tierIcon.tierOne,
      progressFiller: colors.warning,
    },
    mainTier: {
      backColor: colors.clover,
      circleColor: colors.darkClover,
      icon: SVG_ICONS.gamification.tierIcon.tierOne,
      progressFiller: colors.warning,
    },
  },
  2: {
    currentTier: {
      backColor: colors.warning,
      circleColor: colors.sunset,
      icon: SVG_ICONS.gamification.tierIcon.tierTwo,
      progressFiller: colors.secondaryColor,
    },
    mainTier: {
      backColor: colors.warning,
      circleColor: colors.sunset,
      icon: SVG_ICONS.gamification.tierIcon.tierTwo,
      progressFiller: colors.secondaryColor,
    },
  },
  3: {
    currentTier: {
      backColor: colors.lightRose,
      circleColor: colors.softCarnation,
      icon: SVG_ICONS.gamification.tierIcon.tierThree,
      progressFiller: colors.warning,
    },
    mainTier: {
      backColor: colors.lightRose,
      circleColor: colors.softCarnation,
      icon: SVG_ICONS.gamification.tierIcon.tierThree,
      progressFiller: colors.warning,
    },
  },
  4: {
    currentTier: {
      backColor: colors.black,
      circleColor: colors.mist,
      icon: SVG_ICONS.gamification.tierIcon.tierFour,
      progressFiller: colors.warning,
    },
    mainTier: {
      backColor: colors.black,
      circleColor: colors.mist,
      icon: SVG_ICONS.gamification.tierIcon.tierFour,
      progressFiller: colors.warning,
    },
  },
  soon: {
    currentTier: {
      backColor: colors.black,
      circleColor: colors.mist,
      icon: SVG_ICONS.gamification.tierIcon.comingSoonLock,
      progressFiller: colors.warning,
    },
    mainTier: {
      backColor: colors.black,
      circleColor: colors.mist,
      icon: SVG_ICONS.gamification.tierIcon.comingSoonLock,
      progressFiller: colors.warning,
    },
  },
};

export const LOGIN_INFO_SHEET_CONTENT = {
  [LOGIN_INFO_SHEET_KEYS['GG-429']]: {
    title: 'Harap Tunggu Sebentar',
    description: 'Kami menerima terlalu banyak permintaan OTP ke nomor ponsel ini. Coba lagi setelah 1 jam ya!',
    buttonText: 'Oke',
    otpScreenError: 'Terlalu banyak permintaan kode verifikasi. \nMohon tunggu 1 jam untuk mencoba kembali',
    icon: SVG_ICONS.bottomSheet.waiting,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-500']]: {
    title: 'Kembali Lagi Nanti Ya!',
    description: 'Kami sedang membereskan beberapa masalah nih, jangan khawatir kami akan segera kembali',
    buttonText: 'Oke',
    icon: SVG_ICONS.bottomSheet.comeback,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-4031']]: {
    title: 'Wah, Akses Akun Anda Terkunci',
    description:
      'Saat ini, akses akun Anda sudah diblokir berdasarkan permintaan HR kantor Anda. Jika ini sebuah kesalahan, harap hubungi HR Anda untuk informasi lebih lanjut',
    buttonText: 'Oke',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-4032']]: {
    title: 'Mohon Maaf, Kak!',
    description:
      'Saat ini, akses akun Anda sedang terkunci sementara. Hubungi HR Anda untuk info lebih lanjut. Kami akan memberi kabar jika akun Anda sudah aktif kembali',
    buttonText: 'Oke',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-4033']]: {
    title: 'Waduh…',
    description:
      'Ada yang salah, nih. Tapi, bukan salah siapa-siapa kok. Lebih baik hubungi HR Anda untuk informasi lebih lanjut',
    buttonText: 'Oke',
    icon: SVG_ICONS.bottomSheet.suspend,
  },
  [LOGIN_INFO_SHEET_KEYS['GG-404']]: {
    title: 'Nomor HP Tidak Terdaftar',
    description: 'Sepertinya, nomor HP ',
    description2:
      ' ini tidak ada di database kami. Cek ulang nomor yang Anda masukkan atau klik Cek Data Anda supaya kami bisa lakukan pengecekan.',
    buttonText: 'Coba Lagi',
    icon: SVG_ICONS.bottomSheet.unregistered,
    hasTwoButtons: true,
    buttonText2: 'Cek Data Anda',
  },
  [LOGIN_INFO_SHEET_KEYS.IN_PROCESS]: {
    title: 'Data Sedang Diproses',
    description:
      'Proses ini bisa membutuhkan waktu 5 hari kerja. Hubungi tim GajiGesa jika belum ada info lebih lanjut',
    buttonText: 'Oke, Mengerti!',
    icon: SVG_ICONS.bottomSheet.error,
  },
  [LOGIN_INFO_SHEET_KEYS.REGISTERED_USER]: {
    title: 'Nomor HP Sudah Terdaftar',
    description: 'Silahkan coba masuk dengan nomor HP',
    buttonText: 'Masuk Kembali',
    icon: SVG_ICONS.bottomSheet.registered,
  },
  [LOGIN_INFO_SHEET_KEYS.RESEND_OTP]: {
    title: 'Kode Verifikasi Terkirim',
    description: 'Cek kode verifikasi Anda yang kami kirim ke nomor HP ',
    description2: ' . Anda dapat meminta kode verifikasi kembali setelah 30 detik',
    buttonText: 'Oke',
    icon: SVG_ICONS.bottomSheet.otp,
  },
  [LOGIN_INFO_SHEET_KEYS.LOGOUT]: {
    title: 'Anda Yakin Ingin Keluar Dari Aplikasi?',
    description:
      'Jika Anda keluar dari aplikasi Anda tidak bisa tarik gaji lebih awal dan akan kelewatan promo promo menarik loh! ',
    buttonText: 'Tetap Keluar',
    buttonText2: 'Batalkan',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.logout,
  },
};

export const VOUCHER_INV_CTA_ICONS = {
  [VOUCHER_INV_STATUS.REDEEMED]: {
    ctaIcon: SVG_ICONS.voucherInventory.checkSign,
    ctaColor: colors.grey,
  },
  default: {
    ctaIcon: SVG_ICONS.voucherInventory.clipboard,
    ctaColor: colors.red,
  },
};

export const INFO_SHEET_TYPES = {
  SD_ACCOUNT_INFO: {
    name: 'SD_ACCOUNT_INFO',
    title: 'Tunggu Dulu',
    description:
      'Pada periode ini, kamu hanya bisa melakukan pencairan sisa gajimu bulan ini. Silakan pilih Pencairan Gaji.',
    buttonText: 'Oke, Saya Mengerti',
    icon: SVG_ICONS.dynamicAccounts.artInfo,
  },
  SD_ACCOUNT_OPEN_INFO: {
    name: 'SD_ACCOUNT_OPEN_INFO',
    title: 'Tunggu Dulu',
    description:
      'Pada periode ini, kamu hanya bisa melakukan pencairan sisa gajimu bulan ini. Silakan pilih Pencairan Gaji.',
    buttonText: 'Oke, Saya Mengerti',
    icon: SVG_ICONS.dynamicAccounts.artInfo,
  },
};

export const ANDROID_RIPPLE_COLOR = { color: colors.lightGrey };

export {
  DRAWER_NAVIGATION_TYPES,
  COMMON_CONSTANTS,
  VOUCHER_TEXT_CONSTANTS,
  VOUCHER_BILLER_DATA,
  WITHDRAW_REASONS,
  USER_ROLES,
  PAYSLIP,
  ATTENDANCE_COLORS,
  ATTENDANCE_ICONS,
  KETENTUAN_LAYANAN,
  KETENTUAN_LAYANAN_TITLES,
  KETENTUAN_LAYANAN_LIST_1,
  KETENTUAN_LAYANAN_LIST_2,
  KETENTUAN_LAYANAN_LIST_3,
  KETENTUAN_LAYANAN_LIST_4,
  KETENTUAN_LAYANAN_LIST_5,
  TRANSACTION_HISTORY,
  SAVINGS_TOUR_TYPES,
  TERMS_N_CONDITIONS,
  TOOLTIP_CONTENT,
  TERMS,
  LIST_TYPES,
  CATEGORY,
  POSTPAID_VALIDATION_VALUE,
  BP_CATEGORY_NAMES,
  FEATURE_FLAGS,
  PWA_REFFERAL_LINK,
  BP_TOP_TAB_LABEL,
  STORIES_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  REWARD_INFO_ROUTE,
  DYNAMIC_ACCOUNTS_TYPE,
  ERROR_CODES,
  LOGIN_INFO_SHEET_KEYS,
  MESSAGGE_TYPE,
  CHECK_DATA_DROPDOWN_LIST,
  MISSION_STATUS,
  MISSION_GOAL_TYPE,
  TIER_STATUS_TYPE,
  UPDATE_TYPE,
  MILVIK_CONST,
  VOUCHER_INV_STATUS,
  VOUCHER_INV_ASSETS,
  OTHER_ACCOUNT_NAMES,
  DYNAMIC_ACCOUNT_NAMES,
  TRANSACTION_LIST_DYNAMIC_ACCOUNTS,
  HOMEPAGE_DYNAMIC_ACCOUNTS,
  TRANSACTION_SCREEN_ACCOUNT_NAME,
  BP_DYNAMIC_ACCOUNTS,
  TRANSACTION_TYPE,
  TRANSACTION_DETAILS_KEYS,
  TRANSACTION_DETAIL_CONFIG,
  ACCOUNT_LIST,
  LOAN_ATTENTION_TEXT,
  BOTTOMSHEET_CONTENT,
  LOAN_ICON_INFO,
  LOAN_CATEGORY_INFO,
  LOAN_APPLICATION_STATUS,
  LOAN_STATUS_TYPES,
  LOAN_TERMS,
  BANK_CATEGORY_INFO,
  BANK_TRANSFER_ICON_INFO,
  BP_NAMES,
  ZAKAT_ICON_INFO,
  ZAKAT_CATEGORY_INFO,
};
