import React from 'react';
import { Linking } from 'react-native';
import { styles } from './style';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import ImageButton from '@atoms/ImageButton';
import { COMMON_CONSTANTS } from '@utils/Constants';

const ContactSupportButton = () => {
  const onPressContactSupport = () => {
    // const e = AMPLITUDE_CONSTANTS.authentication.help_clicked;
    // AmplitudeHelper.logEvent(e.name, {
    //   [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    // });
    Linking.openURL(COMMON_CONSTANTS.support);
  };

  return (
    <ImageButton
      onPress={onPressContactSupport}
      pressableStyle={styles.pressableStyle}
      styleContainer={styles.buttonContainer}
      isBorder
      iconLeft={SVG_ICONS.whatsapp}
      text="Butuh Bantuan Kami?"
      textStyle={styles.textStyle}
    />
  );
};

export default ContactSupportButton;
