import colors from '@gaji-gesa/gg-react-shared/src/colors';

import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral_cotton,
    paddingBottom: normalize(50),
  },
  titleStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: normalize(16),
    paddingVertical: normalize(6),
  },
  titleTextStyle: { fontSize: normalize(14) },
  dividerStyle: { height: normalize(5), marginVertical: normalize(8), marginBottom: normalize(4) },
  descContainer: { paddingHorizontal: normalize(16), marginTop: normalizeHeight(4), flexDirection: 'row' },
  descTextStyle: { fontSize: normalize(12), marginLeft: normalize(4) },
  headerTextContainer: { paddingHorizontal: normalize(18) },
  headerTextStyle: { fontSize: normalize(12) },
  flatlistPadding: { height: 0 },
  collapsibleInnerContainer: { paddingBottom: normalize(10) },
});

export default styles;
