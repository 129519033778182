import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';

/* Styles import */
import { ScreenOptions } from '@style/Themes';
import ProfileScreen from '@screens/ProfileScreen';

const Stack = createStackNavigator();

const OtherDashboardStack = () => (
  <Stack.Navigator>
    <Stack.Screen name="ProfilePage" component={ProfileScreen} options={ScreenOptions} />
  </Stack.Navigator>
);

export default OtherDashboardStack;
