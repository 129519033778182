import React, { useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity, Text, Dimensions, Linking, ScrollView } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { flatten, times } from 'lodash';
import MainParentWrapper from '@components/MainParentWrapper';
import Header from '@atoms/Header';
import Details from './Details';
import Summary from './Summary';
import { Font } from '@style/Fonts';
import Footer from './Footer';
import { useLazyQuery } from '@apollo/client';
import { GET_SAVING_AMOUNT, GET_SAVING_AMOUNT1, GET_SAVING_GRAPH_DATA, GET_SAVING_SUMMARY } from '@gqlQuery/query';
import MyText from '@atoms/MyText';
import Back from '@expo/vector-icons/MaterialIcons';
// import { Modalize } from "react-native-modalize"
import {
  categoryIndex,
  compare,
  extractMonthlyData,
  getChartEmptyBlock,
  getEmptyBlock,
  getHorizontalLine,
  getLowerLabel,
  getSelectedCycle,
  getUpperLabel,
  groupByMonthlyData,
} from './utils';
import { useSelector } from 'react-redux';
import { listDateFormer, numberWithDots } from '@utils/UtilFunctions';
import moment from 'moment';
import translate from './translate';
import { normalize } from '@style/helper';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import AmplitudeHelper from '@services/amplitude';
import { styles } from './style';
import ButtonColor from '@atoms/ButtonColor';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { useNavigation } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();
const t = translate();
const CURRENT_YEAR = 2022;
const PAGE_LIMIT = 10;
const getTime = (date) => new Date(date).getTime();
const getMonth = (date) => new Date(date).getMonth() + 1;
const getYear = (date) => new Date(date).getFullYear();
const getMonthYearQuery = (date1, date2) => {
  const mDate1 = moment(date1).format('YYYY-MM');
  const mDate2 = moment(date2).format('YYYY-MM');

  return {
    monthFrom: mDate1,
    monthTo: mDate2,
  };
};
const Saving = () => {
  const navigation = useNavigation();
  const [savingAmount, setSavingAmount] = useState(route?.params?.savingAmount ?? 0);
  const eventSource = route?.params?.eventSource;
  const [graphData, setGraphData] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [loadingSaving, setLoadingSaving] = useState(true);
  // const modalizeRef = useRef(null);
  const [modalData, setModalData] = useState(null);
  const [collapsed, setCollapsed] = React.useState(true);
  const [loadingList, setLoadingList] = useState(false);
  const [page, setPage] = useState(1);
  const { activeSavingDetailTab } = useSelector((state) => state.Common);
  const { employeeId } = useSelector((state: any) => state.Authentication);
  const paymentCycles = useSelector((state: any) =>
    state.TransactionDetails.paymentCycles.filter((cycle) => moment(cycle.endDate).year() >= CURRENT_YEAR),
  );
  const defaultCondition = {
    from: paymentCycles[paymentCycles.length - 1]?.startDate ?? new Date(),
    to: paymentCycles[0]?.endDate ?? new Date(),
  };
  const [cycles, setCycles] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [getSavingAmount, { variables: amountVariables }] = useLazyQuery(GET_SAVING_AMOUNT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setSavingAmount(
        data?.campaign?.aggregate?.sum?.amount +
          data?.saving?.aggregate?.sum?.compareamount -
          data?.saving?.aggregate?.sum?.amount,
      );
    },
    onError: (err) => {
      setError(true);
    },
  });
  const [getSavingGraphData, { refetch, loading, variables }] = useLazyQuery(GET_SAVING_GRAPH_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      evaluateGraphData(data);
      setLoadingSaving(false);
    },
    onError: (err) => {
      setLoadingSaving(false);
      setError(true);
    },
  });
  const [campaignData, setCampaignData] = useState({});
  const [getSavingSummary, { variables: summaryVariables }] = useLazyQuery(GET_SAVING_SUMMARY, {
    fetchPolicy: 'no-cache',
    variables: { ...defaultCondition, offset: 0, limit: PAGE_LIMIT },
    onCompleted: (data) => {
      // const filterDetails = data.gg_transaction.filter(txn => !!txn.offer || txn.campaign?.length ) ?? [];
      const filterDetails = data.gg_transaction ?? [];
      const _campaignData = { ...campaignData };

      if (filterDetails.length) {
        filterDetails?.forEach((txn) => {
          if (txn?.campaign?.length) {
            txn.month = getMonth(txn.createdAt);
            txn.year = getYear(txn.createdAt);
            txn.campaign.forEach((campaign) => {
              const key = `${campaign?.offer?.id}-${txn.month}-${txn.year}`;
              if (!_campaignData[key]?.length) _campaignData[key] = [];
              _campaignData[key].push({ ...txn, campaign });
            });
          }
        });
      }
      setCampaignData(_campaignData);
      let _details = [];
      // filterDetails.forEach((txn, index) => {
      //   _details.push(txn);
      // if (index) {
      //   if (getMonth(txn.createdAt) != getMonth(details[index - 1].createdAt)) {
      //     let _campaignData = {...campaignData};
      //     Object.keys(_campaignData).forEach(camp => {
      //       if (parseInt(camp.split('-')[1]) === (getMonth(txn.createdAt) + 1)) {
      //         _details.push(_campaignData[camp]);
      //         delete campaignData[camp];
      //       }
      //     })
      //   }
      // }
      // })
      _details = filterDetails.filter((txn) => txn.offer);

      if (!data.gg_transaction.length) {
        _details.push(...Object.values(_campaignData));
        setCampaignData({});
      }
      setTransactionDetails((details) => [...details, ..._details]);
      setPage((page) => page + 1);
      setLoadingList(false);
      const length = transactionDetails.length + _details.length;

      if (data.gg_transaction.length && length < PAGE_LIMIT) {
        setTimeout(() => setLoadingList(true), 100);
      }
    },
    onError: (err) => {
      setLoadingList(false);
      setError(true);
    },
  });

  const onSelectFilterCycle = (item) => {
    const findIndex = cycles?.findIndex((e) => e.id === item.id);
    let newCycles: any = cycles?.slice() ?? [];
    if (findIndex !== -1) {
      if (findIndex === 0) {
        newCycles.splice(findIndex, 1);
      } else {
        newCycles.splice(findIndex, cycles.length - findIndex);
      }
    } else {
      if (!newCycles.length) {
        newCycles.push(item);
      } else if (getTime(item.startDate) > getTime(newCycles[0].startDate)) {
        const startTime = getTime(item.startDate);
        const endTime = getTime(newCycles[0].startDate);
        const _cycles = paymentCycles.filter((cycle) => {
          const c = getTime(cycle.startDate);
          return endTime < c && c < startTime;
        });
        newCycles.unshift(...[item, ..._cycles]);
      } else {
        const startTime = getTime(item.startDate);
        const endTime = getTime(newCycles[newCycles.length - 1].startDate);
        const _cycles = paymentCycles.filter((cycle) => {
          const c = getTime(cycle.startDate);
          return startTime < c && c < endTime;
        });
        newCycles.push(...[..._cycles, item]);
      }
    }
    setCollapsed(true);
    setCycles(newCycles);
  };

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.savingsInsightPage.name, {
      [AMPLITUDE_CONSTANTS.Savings.savingsInsightPage.attributes.source]: eventSource,
      [AMPLITUDE_CONSTANTS.Savings.savingsInsightPage.attributes.tab]: activeSavingDetailTab,
    });
  }, [activeSavingDetailTab]);

  const getData = () => {
    let dateCondition: any = defaultCondition;
    if (cycles.length) {
      dateCondition = { from: cycles[cycles.length - 1].startDate, to: cycles[0].endDate };
    }
    setLoadingSaving(true);
    setLoadingList(true);
    setPage(1);
    if (transactionDetails.length) {
      setTransactionDetails([]);
      setCampaignData({});
    }
    if (error) {
      setError(false);
    }

    // getSavingAmount({ variables: dateCondition });
    getSavingGraphData({ variables: dateCondition });
    getSavingSummary({ variables: { ...dateCondition, offset: 0, limit: PAGE_LIMIT } });
  };

  useEffect(getData, [cycles]);

  useEffect(() => {
    if (loadingList) {
      let dateCondition: any = defaultCondition;
      if (cycles.length) {
        dateCondition = { from: cycles[cycles.length - 1].startDate, to: cycles[0].endDate };
      }
      getSavingSummary({ variables: { ...dateCondition, offset: (page - 1) * PAGE_LIMIT, limit: PAGE_LIMIT } });
    }
  }, [loadingList]);

  const evaluateGraphData = (data: any) => {
    if (!data) return;
    const info: any = {};
    const infoCycle = cycles.length === 1 ? cycles : !cycles.length ? paymentCycles : cycles;
    infoCycle.map((cycle, index) => {
      info[`cycle_${index}`] = [];
    });
    data['gg_transaction_campaign_group_view'].forEach((item) => extractMonthlyData(infoCycle, info, item));
    data['gg_transaction_saving_group_view'].forEach((item) => extractMonthlyData(infoCycle, info, item));
    const totalSaving = groupByMonthlyData(info);
    setSavingAmount(totalSaving);
    if (!flatten(Object.values(info)).length) setGraphData([]);
    else {
      if (cycles?.length === 1) {
        const values: any = Object.values(info)[0];
        const graphData = values?.map((item: any) => {
          return {
            id: categoryIndex[item.category],
            data: [
              {
                id: `label-${item.category}`,
                type: 'label',
                value: `Rp${numberWithDots(parseInt(item.amount))}`,
                style: styles.upperLabel,
              },
              {
                id: `chart-${item.category}`,
                type: 'chart',
                value: item.amount,
                color: item.color,
                style: {
                  borderTopEndRadius: 10,
                  borderBottomStartRadius: 10,
                  borderBottomEndRadius: 10,
                  borderTopStartRadius: 10,
                },
              },
              { id: `line-${item.category}`, type: 'line', value: 30, color: '#77BE43' },
            ],
          };
        });
        const sortGraphData = graphData?.sort(compare);
        const categoryLen = Object.keys(categoryIndex).length;
        sortGraphData.push(
          ...times(categoryLen - graphData.length, (index) => ({
            id: `empty_${index}`,
            data: getEmptyBlock(index),
          })),
        );
        setGraphData(sortGraphData);
      } else {
        const keys = Object.keys(info);
        const graphData = keys?.map((val: any, index: number) => {
          const item = info[val] ?? [];
          const cycleIndex: number = item?.[0]?.cycleIndex ?? index;
          const monthCycle = infoCycle[cycleIndex];

          const upperLabel = getUpperLabel(item, index);
          const lowerLabel = getLowerLabel(item, index, monthCycle);
          const line = getHorizontalLine(index);

          const len = item?.length;
          const chartData = item?.map((x, ind: number) => ({
            id: `chart-${x.category}-${x.month}`,
            type: 'chart',
            value: x.amount,
            amount: `Rp${numberWithDots(parseInt(x.amount))}`,
            color: x.color,
            style: [
              ind === len - 1 ? { borderBottomStartRadius: 10, borderBottomEndRadius: 10 } : null,
              ind === 0 ? { borderTopStartRadius: 10, borderTopEndRadius: 10 } : null,
            ],
          })) ?? [getEmptyBlock(val)];
          return {
            id: `graph_${index + 1}`,
            data: [upperLabel, ...chartData, line, lowerLabel],
          };
        });
        const len = graphData.length;
        if (graphData.length < 3) {
          graphData.unshift({
            id: `graph__1`,
            data: getEmptyBlock('-1'),
          });
        }
        if (graphData.length < 4) {
          graphData.push({
            id: `graph_${len + 1}`,
            data: getEmptyBlock(len + 1),
          });
        }

        setGraphData(graphData);
      }
    }
  };

  const getDescription = () => {
    if (!cycles.length) return t['select_cycle'];
    return cycles?.length > 1
      ? `${cycles.length} ${t['multiple_cyle']}`
      : `${listDateFormer(moment(getSelectedCycle(paymentCycles, cycles)?.startDate))} - ${listDateFormer(
          moment(getSelectedCycle(paymentCycles, cycles)?.endDate),
        )}`;
  };

  const closeModal = (item) => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.click_X.name, {
      [AMPLITUDE_CONSTANTS.Savings.click_X.attributes.offer_id]: item.offerId,
      [AMPLITUDE_CONSTANTS.Savings.click_X.attributes.transaction_id]: item.id,
      [AMPLITUDE_CONSTANTS.Savings.click_X.attributes.source]: eventSource,
    });
    AmplitudeHelper.logEvent('Click_X', {
      source: 'bottom sheets savings insight',
      transactionId: item.id,
      employeeId: item.employeeId,
      offerid: item.offerId,
    });
    // modalizeRef.current?.close();
  };

  const onOpen = (item) => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.savingsListClicked.name, {
      [AMPLITUDE_CONSTANTS.Savings.savingsListClicked.attributes.offer_id]: item.offerId,
      [AMPLITUDE_CONSTANTS.Savings.savingsListClicked.attributes.transaction_id]: item.id,
      [AMPLITUDE_CONSTANTS.Savings.savingsListClicked.attributes.source]: eventSource,
    });
    setModalData(item);
    // modalizeRef.current?.open();
  };

  const headerModal = () => {
    if (!modalData) return null;
    const totalAmount = numberWithDots(parseInt(modalData.transTotalAmount));
    const compareAmount = numberWithDots(parseInt(modalData?.offer?.compareamount));
    return (
      <View style={{ marginTop: 25, marginHorizontal: 16 }}>
        <TouchableOpacity onPress={() => closeModal(modalData)} style={styles.modalIcon}>
          <Back name="close" size={normalize(25)} color="#979797" />
        </TouchableOpacity>
        <View style={{ marginTop: 40 }}>
          <MyText h4 customStyle={styles.modalHeading} isRegular={false}>
            {modalData?.displayname}
          </MyText>
          {modalData.type === 'campaign' ? (
            modalData?.txn.map((_t, index) => {
              const date = moment(_t.createdAt).format('DD');
              const month = moment(_t.createdAt).format('MM');
              const category = _t.category.displayname;
              return (
                <MyText h2 customStyle={styles.modalText}>
                  {category} ({`${date} / ${month}`}) : Rp{numberWithDots(parseInt(_t.transTotalAmount))}
                </MyText>
              );
            })
          ) : (
            <>
              <MyText h2 customStyle={styles.modalText}>
                Kamu Bayar: Rp{totalAmount}
              </MyText>
              <MyText h2 customStyle={styles.modalText}>
                Harga rata-rata di platform lain: Rp{compareAmount}
              </MyText>
            </>
          )}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 30,
              paddingHorizontal: 10,
            }}
          >
            <View style={{ marginRight: 10 }}>{SVG_ICONS['moneyWings']}</View>
            <Text
              style={{
                fontFamily: Font.Nunito_Regular,
                fontSize: 12,
                lineHeight: 20,
                color: '#454545',
              }}
            >
              {modalData.type === 'saving' ? `Estimated savings` : `Cashback`} ={' '}
            </Text>
            <Text
              style={{
                fontFamily: Font.Nunito_Bold,
                fontSize: 12,
                lineHeight: 20,
                color: '#F56B57',
                paddingHorizontal: 5,
              }}
            >
              Rp{modalData.totalSaving}
            </Text>
          </View>
          {modalData.url && (
            <View style={{ alignItems: 'center', marginTop: 16 }}>
              <ButtonColor
                textStyle={{
                  fontFamily: Font.Nunito_Bold,
                  fontSize: 16,
                  lineHeight: 22,
                  textAlign: 'center',
                  textTransform: 'capitalize',
                }}
                isRounded
                buttonStyle={{
                  backgroundColor: '#F56B57',
                  borderRadius: 100,
                  width: 150,
                }}
                title={`Lihat Promo`}
                onPress={() => {
                  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Savings.CampaignButtonClicked.name, {
                    [AMPLITUDE_CONSTANTS.Savings.CampaignButtonClicked.attributes.offer_id]: modalData.offerId,
                    [AMPLITUDE_CONSTANTS.Savings.CampaignButtonClicked.attributes.source]: 'bottom_sheets',
                    [AMPLITUDE_CONSTANTS.Savings.CampaignButtonClicked.attributes.transaction_id]: modalData.id,
                  });

                  Linking.openURL(modalData.url);
                }}
              />
            </View>
          )}
        </View>
      </View>
    );
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', (e) => {
      // Prevent default behavior
      e.preventDefault();

      // Do something manually
      // ...
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <MainParentWrapper loading={loadingSaving} isError={error} onReload={getData}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Header
          savingAmount={savingAmount}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          paymentCycles={paymentCycles}
          cycles={cycles}
          setCycles={setCycles}
          onSelectFilterCycle={onSelectFilterCycle}
          getDescription={getDescription}
        />
        <Tab.Navigator
          swipeEnabled={true}
          style={styles.navigator}
          tabBarOptions={{
            activeTintColor: '#F56B57',
            inactiveTintColor: '#45454580',
            labelStyle: {
              fontFamily: Font.Nunito_Regular,
              fontSize: 12,
              lineHeight: 16,
              textAlign: 'center',
            },
            indicatorStyle: {
              color: '#F56B57',
              backgroundColor: '#F56B57',
              height: 5,
            },
          }}
        >
          <Tab.Screen
            name="summary"
            options={{ tabBarLabel: 'Ringkasan' }}
            children={() => <Summary graphData={graphData} loadingSaving={loadingSaving} />}
          />
          <Tab.Screen
            name="details"
            options={{ tabBarLabel: 'Rincian' }}
            children={() => (
              <Details
                onOpen={onOpen}
                transactionList={transactionDetails}
                page={page}
                setLoadingList={setLoadingList}
                loadingList={loadingList}
              />
            )}
          />
        </Tab.Navigator>
        <View style={styles.divider} />
        <Footer navigate={navigation.navigate} />
      </ScrollView>
      {/* <Modalize
        onClosed={() => {}}
        HeaderComponent={headerModal}
        // ref={modalizeRef}
        snapPoint={Dimensions.get('screen').height / 3}
      ></Modalize> */}
    </MainParentWrapper>
  );
};

export default Saving;
