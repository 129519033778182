import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';
import ImageButton from '@atoms/ImageButton';
import LoadingButton from '@atoms/LoadingButton';
import { colors } from '@style/colors';

export function shouldRenderAddBottom(isTransact, primaryPressBottomButton, onPressAddPhoneNo) {
  if (isTransact) return null;

  return (
    <View style={styles.buttonContainer}>
      <ImageButton
        onPress={primaryPressBottomButton}
        styleContainer={styles.imageButton}
        pressableStyle={styles.pressable}
        textStyle={styles.buttonStyle}
        text={'Verifikasi Sekarang'}
      />
      <LoadingButton
        container={styles.loadingButton}
        textStyle={styles.loadingButtonText}
        buttonColor={colors.orange}
        text={'Tambah Nomor HP'}
        textColor={colors.white}
        textTransform="none"
        onPress={onPressAddPhoneNo}
      />
    </View>
  );
}

export function shouldRenderTransactFlow(isTransact, goBackToTransaction) {
  if (isTransact) {
    return (
      <View style={[styles.buttonContainer, styles.transactButtonContainer]}>
        <MyText isRegular={false} customStyle={styles.titleStyle}>
          Tenang saja...
        </MyText>

        <MyText isRegular={true} customStyle={styles.descStyle}>
          {'Kamu masih bisa transaksi untuk \nnomormu sendiri kok!'}
        </MyText>
        <LoadingButton
          container={styles.loadingButton}
          textStyle={styles.loadingButtonText}
          buttonColor={colors.orange}
          text={'Transaksi Sekarang'}
          textColor={colors.white}
          textTransform="none"
          onPress={goBackToTransaction}
        />
      </View>
    );
  }

  return null;
}
