import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
const styles = StyleSheet.create({
  divider: {
    width: '90%',
    alignSelf: 'center',
  },
  flatlist: { flexGrow: 1, backgroundColor: colors.white, height: 0 },
  item: {
    padding: 20,
  },
  inputSearch: {
    fontFamily: Font.Nunito_Regular,
    color: colors.black,
    flex: 1,
    paddingVertical: 12,
    outlineWidth: 0,
  },
  outerContainer: {
    flex: 1,
    backgroundColor: colors.blue,
  },
  inputTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderRadius: 10,
    paddingHorizontal: 15,
  },
  blueBg: {
    backgroundColor: colors.blue,
    padding: 20,
  },
});

export default styles;
