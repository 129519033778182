import React, { useEffect, useMemo, useState } from 'react';
import { FlatList, Linking, View } from 'react-native';
import MyText from '@atoms/MyText';
import Eye from '@expo/vector-icons/Ionicons';
import { colors } from '@style/colors';
import InfoTextComponent from '@atoms/InfoTextComponent';
import Divider from '@atoms/Divider';
import BankItem from '@atoms/BankCards/BankItem';
import ImageButton from '@atoms/ImageButton';
import LoadingButton from '@atoms/LoadingButton';
import MainParentWrapper from '@components/MainParentWrapper';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_ALL_BANKS, DELETE_USER_BANK, EDIT_BANK_ACCOUNTS } from '@gqlQuery/BankAccount';
import {
  getUserBanksFromQuery,
  masker,
  onClickSecondaryBottomSheet,
  onCompleteDeleteBank,
  returnBottomSheetContent,
} from '@utils/UtilFunctions';
import BottomSheet from '@components/LoginInfoSheet';
import { BOTTONSHEET_KEYS } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { BOTTOMSHEET_CONTENT, COMMON_CONSTANTS } from '@utils/Constants';
import LoadingModal from '@components/LoadingModal';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setUserBankData, changeUserReadStatus, setCommonUserReadStatus } from '@redux/action/UserBanks';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { RefreshControl } from 'react-native-web-refresh-control';
import PropTypes from 'prop-types';
import { useNavigation, useRoute } from '@react-navigation/native';
import styles from './style';

const UserBankList = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { source } = route.params;
  const [maskDefaultBank, setMaskDefaultBank] = useState(true);
  const [currentSelectedBankId, setCurrentSelectedBankId] = useState(null);
  const { defaultBankAccount, userBanks } = useSelector((state) => state.UserBank);
  const dispatch = useDispatch();
  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(BOTTONSHEET_KEYS.BANK_DELETE, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.BANK_DELETE]),
    errorCode: BOTTONSHEET_KEYS.BANK_DELETE,
  });
  const [deleteUserBank, { loading: loadingDelete }] = useMutation(DELETE_USER_BANK, {
    onCompleted: () => {
      onCompleteDeleteBank(userBanks, currentSelectedBankId, dispatch);
    },
  });

  const [setBankUserRead] = useMutation(EDIT_BANK_ACCOUNTS);

  const [getUserBanks, { loading, error }] = useLazyQuery(GET_USER_ALL_BANKS, {
    onCompleted: (data) => {
      getAllUserRead(data?.user_enrolled_bank);
      if (data.gg_employee.length > 0) {
        const dispatchData = getUserBanksFromQuery(data);
        dispatch(setUserBankData(dispatchData.defaultBank, dispatchData.userBanks, dispatchData.commonUserReadStatus));
      }
    },
    fetchPolicy: 'no-cache',
  });
  const getAllUserRead = (data) => {
    const userRead = data?.filter((e) => e.isUserRead === false).map((e) => e.id);
    if (userRead.length > 0) {
      setBankUserRead({
        variables: {
          accountId: userRead,
        },
      });
      const newUserBank = userBanks.slice().map((e) => ({
        ...e,
        isUserRead: true,
      }));
      batch(() => {
        dispatch(changeUserReadStatus(newUserBank));
        dispatch(setCommonUserReadStatus(true));
      });
    }
  };

  const onPressDelete = (item) => {
    setCurrentSelectedBankId(item.id);
    if (item.status === 'REJECTED') {
      onClickSecondaryBottomSheet(setInfoModal, infoModal, deleteUserBank, item.id);
      return;
    }
    setInfoModal({
      data: returnBottomSheetContent(BOTTONSHEET_KEYS.BANK_DELETE, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.BANK_DELETE]),
      errorCode: BOTTONSHEET_KEYS.BANK_DELETE,
      visible: true,
    });
  };

  const renderItem = ({ item }) => (
    <BankItem
      onMasked={() => {
        const e = AMPLITUDE_CONSTANTS.bankRegistration.eye_icon_clicked;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.source]: source,
          [e.attributes.bank_account_id]: item.id,
          [e.attributes.action]: AMPLITUDE_EVENT_SOURCES.bankRegistration.action.to_hide,
        });
      }}
      onUnMasked={() => {
        const e = AMPLITUDE_CONSTANTS.bankRegistration.eye_icon_clicked;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.source]: source,
          [e.attributes.bank_account_id]: item.id,
          [e.attributes.action]: AMPLITUDE_EVENT_SOURCES.bankRegistration.action.to_show,
        });
      }}
      item={item}
      onPressDelete={onPressDelete}
    />
  );

  useEffect(() => {
    if (defaultBankAccount === null) getUserBanks();
    else getAllUserRead(userBanks);

    const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: source,
    });
  }, []);

  const defaultBankAccountNumber = useMemo(() => {
    if (!!defaultBankAccount?.bankAccountNo) {
      if (maskDefaultBank) {
        return masker(defaultBankAccount?.bankAccountNo);
      }

      return defaultBankAccount?.bankAccountNo ?? '';
    }
    return {};
  }, [defaultBankAccount, maskDefaultBank]);

  const header = () => (
    <>
      <MyText>Rekening Terdaftar (Utama)</MyText>
      <MyText isRegular={false} customStyle={styles.bankNameDefault}>
        {defaultBankAccount?.bankName}
      </MyText>
      <View style={styles.rowContainer}>
        <MyText>{defaultBankAccountNumber}</MyText>
        <Eye
          onPress={() => setMaskDefaultBank(!maskDefaultBank)}
          name={maskDefaultBank ? 'eye-off' : 'eye'}
          size={20}
          color={colors.balck}
          style={{ marginStart: 10 }}
        />
      </View>
      <MyText customStyle={styles.beneficiaryDefault}>{defaultBankAccount?.beneficiaryName}</MyText>
      <InfoTextComponent infoText="Rekening utama merupakan nomor rekening bank yang didaftarkan tempat kerjamu di GajiGesa." />
      <Divider horizontal customStyle={styles.divider} />
      <MyText h4>Rekening Terdaftar Lainnya</MyText>
      <MyText h2 customStyle={{ color: colors.philippineGray }}>
        (Maks. 10 rekening lainnya)
      </MyText>
    </>
  );

  const keyExtractor = (item) => item.id.toString();

  const onPressCreateBank = () => {
    if (userBanks.length < 10) {
      const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
      });
      navigation.navigate('BankCreateDetail', { source: AMPLITUDE_EVENT_SOURCES.bank_page });
      return;
    }
    const e = AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_failed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.bank_page,
      [e.attributes.failure_message]:
        AMPLITUDE_EVENT_SOURCES.bankRegistration.failureMessage.bank_account_limit_exceeded,
    });
    setInfoModal({
      visible: true,
      data: returnBottomSheetContent(BOTTONSHEET_KEYS.MAX_LIMIT, BOTTOMSHEET_CONTENT[BOTTONSHEET_KEYS.MAX_LIMIT]),
      errorCode: BOTTONSHEET_KEYS.MAX_LIMIT,
    });
  };

  const onPressSecondaryBottomSheet = () => {
    setInfoModal({ ...infoModal, visible: false });
  };

  const onPressPrimaryBottomSHeet = () => {
    if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
      onClickSecondaryBottomSheet(setInfoModal, infoModal, deleteUserBank, currentSelectedBankId);
    } else {
      setInfoModal({ ...infoModal, visible: false });
    }
  };

  const renderDivider = () => <Divider horizontal />;

  return (
    <MainParentWrapper
      loading={loading}
      isError={error}
      onReload={() => getUserBanks()}
      containerStyle={{ paddingTop: 0, height: 0 }}
    >
      <FlatList
        refreshControl={<RefreshControl colors={[colors.blue]} refreshing={loading} onRefresh={getUserBanks} />}
        data={userBanks}
        ListHeaderComponent={header}
        style={{ padding: 10, height: 0 }}
        contentContainerStyle={{ paddingBottom: '40%' }}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={renderDivider}
      />
      <View style={styles.buttonContainer}>
        <ImageButton
          onPress={() => {
            const e = AMPLITUDE_CONSTANTS.bankRegistration.help_clicked;
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.source]: source,
            });
            Linking.openURL(COMMON_CONSTANTS.bankTransferSupport);
          }}
          styleContainer={styles.imageButton}
          pressableStyle={styles.pressable}
          textStyle={styles.buttonStyle}
          text="Verifikasi Sekarang"
        />
        <LoadingButton
          container={styles.loadingButton}
          textStyle={styles.loadingButtonText}
          buttonColor={colors.orange}
          text="Tambah Rekening"
          textColor={colors.white}
          textTransform="none"
          onPress={onPressCreateBank}
        />
      </View>
      <BottomSheet
        onPressPrimary={onPressPrimaryBottomSHeet}
        onPressSecondary={onPressSecondaryBottomSheet}
        loginInfo={infoModal}
        hasTwoButtons={infoModal.data.hasTwoButtons}
        onClose={() => setInfoModal({ ...infoModal, visible: false })}
      />
      <LoadingModal loading={loadingDelete} />
    </MainParentWrapper>
  );
};

UserBankList.defaultProps = {};

UserBankList.propTypes = {
  navigation: PropTypes.shape({
    navigation: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default UserBankList;
