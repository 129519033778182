import LoadingModal from '@components/LoadingModal';
import RootScene from '@scenes/RootScene';
import React, { useEffect, useState } from 'react';
import AuthService, { onQueryParamActionLogin } from '@services/AuthService';
import { useDispatch } from 'react-redux';

const WelcomeComponent = () => {
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const dispatch = useDispatch();
  const checkToken = async () => {
    const { search } = window.location;
    if (search) {
      const accessToken = new URLSearchParams(search).get('accessToken');
      const refreshToken = new URLSearchParams(search).get('refreshToken');
      if (accessToken && refreshToken) {
        localStorage.setItem('@refreshToken', refreshToken);
        localStorage.setItem('@token', accessToken);
        let res = await AuthService.getProfile();
        if (res.status === 200) {
          const mobileNumber = res?.data?.data?.mobileNumber;
          localStorage.setItem(
            '@phoneNumber',
            JSON.stringify({
              mobileNumber,
            }),
          );
          await dispatch(onQueryParamActionLogin(res, {}, mobileNumber, accessToken, refreshToken));
          setShouldShowLoader(false);
        }
      } else {
        setShouldShowLoader(false);
      }
    } else {
      setShouldShowLoader(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return shouldShowLoader ? <LoadingModal loading={shouldShowLoader} /> : <RootScene />;
};

export default WelcomeComponent;
