import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { useSelector } from 'react-redux';

import config from '@gaji-gesa/gg-ui-shared/src/config/config';

function ChatScreen() {
  const { employeeId } = useSelector((state) => state.Authentication);

  let CHAT_GPT_SUPPORT_URL = 'https://gpt-ui.stg.gajigesa-infra.com/';

  if (config.ENV_SENTRY === 'production') CHAT_GPT_SUPPORT_URL = 'https://gpt-ui.gajigesa-infra.com/';

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: 'position', android: 'position' })}
      enabled
      contentContainerStyle={{ flex: 1 }}
      keyboardVerticalOffset={Platform.select({ android: 100 })}
      style={{ flex: 1 }}
    >
      <iframe
        src={`${CHAT_GPT_SUPPORT_URL}${employeeId}`}
        width="100%"
        height="100%"
        style={{ flex: 1 }}
        title="Gajigesa Finance Chat"
      />
    </KeyboardAvoidingView>
  );
}

ChatScreen.propTypes = {};
ChatScreen.defaultProps = {};
export default ChatScreen;
