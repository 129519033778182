import React, { useState, useEffect } from 'react';
import { Text, View, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setUserAccountNumber } from '@redux/action/Transactions';
import { getRegisteredAccount } from '@services/BillPaymentApi';

/* Style Import */
import { smallerText } from '@style/Layouts';

/* Component Import */
import DropdownInput from '@atoms/DropdownInput';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const AccountInput = ({ show, isPdam, prefix }) => {
  const pageFailed = useSelector((state) => state.Common.pageFailed);
  const registeredAccountsPrepaid = useSelector((state) => state.BillPayments.registeredAccountsPrepaid);

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('input');
  const [accountNumber, setAccountNumber] = useState('');

  useEffect(() => {
    !isPdam && dispatch(setUserAccountNumber(accountNumber));
  }, [accountNumber]);
  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    dispatch(getRegisteredAccount('BP-05'));
  }, []);

  const onChanged = (text) => {
    setAccountNumber(text.replace(/[^0-9]/g, ''));
  };

  const handleAfterEdit = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
  };

  const handleFocus = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
  };

  const handleClickInput = () => {
    // AmplitudeHelper.logEvent('userClickedInputBaru')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('input');
  };

  const handleClickId = () => {
    // AmplitudeHelper.logEvent('userClickedIdTerdaftar')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.registeredIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('id');
  };

  const handleSelect = (index, value) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.account_number]: registeredAccountsPrepaid[index]['accountNumber'],
    });
    setAccountNumber(registeredAccountsPrepaid[index]['accountNumber']);
  };

  if (!show) {
    return <></>;
  }

  return (
    <>
      <Text style={[smallerText, styles.title]}>ID Pelanggan</Text>
      <View style={styles.tabContainer}>
        <TouchableOpacity
          accessibilityLabel="newAccountTab"
          style={[
            styles.tabButton,
            {
              borderBottomWidth: currentTab == 'input' ? 3 : 1,
              borderBottomColor: currentTab == 'input' ? '#f56b57' : '#d4d4d4',
            },
          ]}
          onPress={handleClickInput}
        >
          <Text style={[smallerText, { color: currentTab == 'input' ? '#f56b57' : '#d4d4d4' }]}>Input Baru</Text>
        </TouchableOpacity>
        <TouchableOpacity
          accessibilityLabel="savedAccountTab"
          style={[
            styles.tabButton,
            {
              borderBottomWidth: currentTab == 'id' ? 3 : 1,
              borderBottomColor: currentTab == 'id' ? '#f56b57' : '#d4d4d4',
            },
          ]}
          onPress={handleClickId}
        >
          <Text style={[smallerText, { color: currentTab == 'id' ? '#f56b57' : '#d4d4d4' }]}>ID Terdaftar</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.container}>
        {currentTab == 'input' ? (
          <View style={styles.inputView}>
            <TextInput
              accessibilityLabel={'accountInput'}
              style={[styles.input, { borderColor: pageFailed != '' ? 'red' : '#d4d4d4' }]}
              placeholder="Masukkan ID pelanggan"
              placeholderTextColor="#d4d4d4"
              keyboardType="numeric"
              onChangeText={(x) => onChanged(x)}
              onEndEditing={handleAfterEdit}
              onFocus={handleFocus}
              value={accountNumber}
            />
            <Text style={styles.pageFailed}>{pageFailed}</Text>
          </View>
        ) : (
          <DropdownInput
            style={styles.dropDown}
            dropStyle={styles.dropDownDrop}
            textStyle={{ color: accountNumber == '' ? '#d4d4d4' : '#272727' }}
            defaultValue={'Pilih ID pelanggan'}
            styleFocus={{ width: '90%' }}
            options={registeredAccountsPrepaid && registeredAccountsPrepaid.map((item) => item.accountNumber)}
            onSelect={(index, value) => handleSelect(index, value)}
            value={accountNumber}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: 'row', width: '100%', marginLeft: '10%' },
  tabContainer: { flexDirection: 'row', width: '100%', marginTop: '5%', marginLeft: '15%', marginBottom: '5%' },
  inputView: { flexDirection: 'column', width: '90%' },
  title: { alignSelf: 'flex-start', marginLeft: '5%' },
  tabButton: { flex: 0.4, alignItems: 'center' },
  input: {
    borderWidth: 1,
    borderRadius: 12,
    height: normalizeHeight(40),
    paddingHorizontal: 10,
    outlineColor: 'transparent',
  },
  pageFailed: { fontSize: normalize(12), color: '#d40e0b', fontFamily: 'Nunito-Regular', marginLeft: 10 },
  dropDown: {
    borderWidth: 3.5,
    borderRadius: 12,
    borderColor: '#d4d4d4',
    minHeight: '90%',
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  dropDownDrop: { width: '90%', marginLeft: -12 },
});

export default AccountInput;
