import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
import { Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import ZakatScreen from '@screens/ZakatScreen';

const { height: screenHeight } = Dimensions.get('window');
const Stack = createStackNavigator();

const ZakatStack = () => {
  const optionStyle = {
    headerTintColor: 'white',
    headerStyle: {
      backgroundColor: colors.blue,
      shadowRadius: 0,
      shadowOffset: {
        height: 0,
      },
      borderBottomColor: colors.blue,
      height: screenHeight / 15,
      elevation: 0,
    },
    headerTitleStyle: {
      fontFamily: Font.Nunito_Bold,
      fontSize: normalize(14),
    },
  };
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ZakatScreen"
        component={ZakatScreen}
        options={({ route }) => ({ headerTitle: 'Zakat Fitrah', ...optionStyle })}
      />
    </Stack.Navigator>
  );
};

export default ZakatStack;
