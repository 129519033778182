import React from 'react';
import { Text, View, StyleSheet, Dimensions } from 'react-native';
const { width: screenWidth, height: screenHeight } = Dimensions.get('window');
import { normalizeHeight } from '@style/helper';
import { SvgUri } from 'react-native-svg';

const ModalLoan = () => {
  return (
    <>
      <View style={styles.centeredView}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <SvgUri uri="https://storage.googleapis.com/gajitim-assets/loan-process.svg" />
            <Text style={styles.textStyle}>Dalam Proses</Text>
            <Text style={styles.modalText}>Cek status pinjaman kamu secara berkala di aplikasi GajiGesa.</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0.98,
    position: 'absolute',
    maxHeight: screenHeight,
    minWidth: screenWidth,
    top: 0,
    bottom: 0,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    height: screenHeight > 700 ? screenHeight / 3 : screenHeight / 2.4,
    width: screenWidth / 1.1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    fontSize: 14,
    fontFamily: 'Nunito-Regular',
    color: '#3863F0',
    marginTop: 5,
  },
  modalText: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 14,
    color: '#F56B57',
  },
  containerLogout: {
    position: 'absolute',
    top: normalizeHeight(10),
    alignSelf: 'flex-end',
    right: 5,
  },
});

export default ModalLoan;
