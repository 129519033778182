import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@utils/UtilFunctions';
import styles from './style';

const VoucherNominal = ({ nominalAmount }) => {
  function showAbsoluteStyleCircle(customStyle) {
    return <View style={[styles.circle, customStyle]} />;
  }

  return (
    <View style={styles.container}>
      <MyText customStyle={styles.labelStyle}>Total voucher konsultasi senilai</MyText>
      <MyText isRegular={false} customStyle={styles.nominalStyle}>
        {`Rp${numberWithDots(nominalAmount)}`}
      </MyText>
      {showAbsoluteStyleCircle(styles.topCircle)}
      {showAbsoluteStyleCircle(styles.bottomCircle)}
      {showAbsoluteStyleCircle(styles.rightTopCircle)}
      {showAbsoluteStyleCircle(styles.rightBottomCircle)}
    </View>
  );
};

VoucherNominal.propTypes = {
  nominalAmount: PropTypes.number.isRequired,
};

export default VoucherNominal;
