import React, { useState, useEffect } from 'react';
import { View, Dimensions, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/browser';
import { StackActions, useIsFocused } from '@react-navigation/native';

/* Services */
import { requestProductList, selectProduct } from '@services/BillPaymentApi';

/* Redux */
import { useDispatch, useSelector, batch } from 'react-redux';
import { respError, updateShowModal, setPageFailed, setGlobalLoading } from '@redux/action/Common';
import { resetTransaction } from '@redux/action/Transactions';
import { updateProduct, updateBiller, resetProduct } from '@redux/action/BillPayments';

/* Styles */
import { background, LgCard, XlCard } from '@style/Themes';
import { centerView } from '@style/Layouts';
import AmplitudeHelper from '@services/amplitude';
const { height: screenHeight } = Dimensions.get('window');

/* Screen import */
import Loading from '@screens/Loading';

/* Component Import */
import AccountInput from '@components/AccountInput';
import DropPick from '@components/DropPick';
import DropPickVoucher from '@components/DropPickVoucher';
import ParentWrapper from '@components/ParentWrapper';

/* Product Import */
import ListrikPostpaid from '@components/ListrikPostpaid';
import PDAMPostpaid from '@components/PDAMPostpaid';
import PulsaPostpaid from '@components/PulsaPostpaid';
import NotAvailable from '@components/products/NotAvailable';
import EMoney from '@components/products/EMoney';
import Voucher from '@components/products/Voucher';
import Listrik from '@components/products/Listrik';
import PaketData from '@components/products/PaketData';
import Pulsa from '@components/products/Pulsa';
import Asuransi from '@components/products/Asuransi';
import Account from '@components/Account';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const Product = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.Common);
  const billPayments = useSelector((state) => state.BillPayments);
  const trans = useSelector((state) => state.Transactions);
  const { employeeId, phoneNumber } = useSelector((state) => state.Authentication);
  const { item: params } = route.params;
  const isFocused = useIsFocused();
  const [accountNumber, setAccountNumber] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [providerName, setProviderName] = useState('');
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const clickedBack = navigation.addListener('blur', () => {
      // AmplitudeHelper.logEvent("userClickedBack", {
      //   screen: `Product - ${params.name}`,
      // });

      let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedBack;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
      });
    });
    return clickedBack;
  }, [navigation]);

  useEffect(() => {
    // AmplitudeHelper.logEvent("ProductPage", { category: params.name });

    let e = AMPLITUDE_CONSTANTS.BillPayment.ProductPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
    });

    return () => {
      batch(() => {
        dispatch(resetTransaction());
        dispatch(resetProduct());
        dispatch(setGlobalLoading(false));
      });
      dispatch(setPageFailed(''));
    };
  }, []);

  useEffect(() => {
    if (isFocused) {
      refreshPage();
    }
  }, [isFocused]);

  const refreshPage = async () => {
    try {
      setError(false);
      setLoading(true);
      await getProductList();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (common.confirmed && common.parentPage === 'billpayment') {
      navigation.navigate('TransactionList', {
        screen: 'Settlement',
        params: {
          timeTaken: '5 menit',
          productName: trans.productName,
          category: `Top-Up ${billPayments.selectedProduct.biller}`,
          customerId: trans.inquiry.accountNumber,
          parentPage: common.parentPage,
        },
      });
    }
  }, [common.confirmed, common.parentPage]);

  useEffect(() => {
    setLoading(common.globalLoading);
  }, [common.globalLoading]);

  const getProductList = async () => {
    try {
      let phoneNumber = await AsyncStorage.getItem('@phoneNumber');
      let num = '';
      if ([7, 10, 15, 16, 8, 13].indexOf(params.id) != -1) {
        num = phoneNumber;
      } else if (params.id == 5 || params.id == 17 || params.id == 6) {
        num = '123';
      }

      let resp = await requestProductList(params.id, num);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setProductList(resp.data.data);
        if (params.id == 8) {
          setProviderName(resp.data.data[0].name);
        }
      } else {
        setLoading(false);
        dispatch(respError(resp.response.data.message));
      }
    } catch (error) {
      if (error.response?.status == 401) {
        setLoading(false);
        throw error;
      } else {
        throw error;
        // dispatch(respError('Server tidak merespon'))
      }
    }
  };

  const localSelectProduct = async (val) => {
    setLoading(true);
    await dispatch(selectProduct(val));
    setLoading(false);
  };

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 20,
      height: params.id === 5 ? screenHeight / 1.2 : screenHeight / 1.4,
      bottom: '30%',
      width: '100%',
      paddingBottom: 35,
      overflow: 'scroll',
    },
  });

  const handleLihatDetail = async (val, accountNum) => {
    dispatch(updateProduct(val));
    // AmplitudeHelper.logEvent("userClickedLihatDetail", {
    //   product: billPayments.selectedProduct,
    // });

    let phoneNumber = await AsyncStorage.getItem('@phoneNumber');
    let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedLihatDetail;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(val?.item.code),
      [e.attributes.reason]: 'tagihan_dan_utilitas',
      [e.attributes.reason_id]: 2,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.product_price]: val?.item.amount,
      [e.attributes.account_number]: phoneNumber,
    });

    dispatch(updateShowModal('paket'));
  };

  // PRODUCT COMPONENT
  return (
    <>
      <View style={[centerView, background]}>
        <Account show={[7, 10, 15, 8].indexOf(params.id) != -1} />
        <View style={[styles.container, XlCard]}>
          <ParentWrapper loading={loading} isError={error} onReload={refreshPage}>
            <>
              <NotAvailable show={[5, 7, 10, 15, 16, 17, 6, 8, 13].indexOf(params.id) == -1} />
              <AccountInput
                show={params.id == 5}
                isPdam={params.id == 6}
                accountNum={accountNumber}
                prefix={params.prefix}
              />
              <ListrikPostpaid
                show={params.id == 17}
                accountNum={accountNumber}
                dataList={productList['PLN Postpaid']}
                navigation={navigation}
                prefix={params.prefix}
                employeeId={employeeId}
              />
              <PDAMPostpaid
                show={params.id == 6}
                accountNum={accountNumber}
                dataList={productList}
                navigation={navigation}
                prefix={params.prefix}
                employeeId={employeeId}
              />
              <PulsaPostpaid
                show={params.id == 8}
                accountNum={accountNumber}
                dataList={productList}
                navigation={navigation}
                providerName={providerName}
                employeeId={employeeId}
              />
              <EMoney
                show={params.id == 15}
                dataList={productList}
                onPress={(val) => {
                  //setLoading(true)
                  let e = AMPLITUDE_CONSTANTS.BillPayment.providerSelected;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(val[0]?.categoryPrefix),
                    [e.attributes.provider]: val[0]?.biller,
                  });
                  batch(() => {
                    dispatch(updateBiller(val));
                    dispatch(updateProduct(val[0]));
                  });
                }}
                employeeId={employeeId}
              />
              <Voucher
                show={params.id == 13}
                dataList={productList}
                onPress={(val) => {
                  batch(() => {
                    dispatch(updateBiller(val));
                    dispatch(updateProduct(val[0]));
                  });
                }}
                employeeId={employeeId}
              />
              <DropPick
                show={params.id == 15 && billPayments.selectedBiller}
                items={billPayments.selectedBiller}
                onPress={(val) => {
                  localSelectProduct(val);
                }}
                onChange={(val) => dispatch(updateProduct(val))}
                selected={billPayments.selectedProduct}
                employeeId={employeeId}
              />
              <DropPickVoucher
                show={params.id == 13 && billPayments.selectedBiller}
                items={billPayments.selectedBiller}
                onPress={(val) => {
                  localSelectProduct(val);
                }}
                onChange={(val) => dispatch(updateProduct(val))}
                selected={billPayments.selectedProduct}
                navigation={navigation}
                employeeId={employeeId}
              />
              <PaketData
                show={params.id == 7}
                dataList={productList}
                onPress={(val) => {
                  let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
                    [e.attributes.product_code]: formatUnderScoreLowerCase(val?.item.code),
                    [e.attributes.reason]: 'tagihan_dan_utilitas',
                    [e.attributes.reason_id]: 2,
                    [e.attributes.source]: 'other_product_page',
                    [e.attributes.product_price]: val?.item.amount,
                    [e.attributes.account_number]: phoneNumber,
                  });

                  localSelectProduct(val.item);
                }}
                onDetail={handleLihatDetail}
                employeeId={employeeId}
              />
              <Pulsa
                show={params.id == 10}
                dataList={productList}
                onPress={(val) => {
                  let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
                    [e.attributes.product_code]: formatUnderScoreLowerCase(val?.item.code),
                    [e.attributes.reason]: 'tagihan_dan_utilitas',
                    [e.attributes.reason_id]: 2,
                    [e.attributes.source]: 'other_product_page',
                    [e.attributes.product_price]: val?.item.amount,
                    [e.attributes.account_number]: phoneNumber,
                  });

                  localSelectProduct(val.item);
                }}
                employeeId={employeeId}
              />
              <Asuransi dataList={productList} show={params.id == 16} />
              <Listrik
                dataList={productList}
                show={params.id == 5}
                onPress={(val, accountNum) => {
                  // AmplitudeHelper.logEvent("userChoosesNominal", {
                  //   product: val.item,
                  // });

                  let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(params.prefix),
                    [e.attributes.product_code]: formatUnderScoreLowerCase(val?.item.code),
                    [e.attributes.reason]: 'tagihan_dan_utilitas',
                    [e.attributes.reason_id]: 2,
                    [e.attributes.source]: 'other_product_page',
                    [e.attributes.product_price]: val?.item.amount,
                    [e.attributes.account_number]: accountNum,
                  });

                  // let e2 = AMPLITUDE_CONSTANTS.BillPayment.verifyingBillPaymentTransaction
                  // AmplitudeHelper.logEvent(e.name, {
                  //   [e.attributes.transaction_category_id] : '',
                  //   [e.attributes.product_price] : '',
                  //   [e.attributes.reason] : 'tagihan_dan_utilitas',
                  //   [e.attributes.reason_id] : 2,
                  //   [e.attributes.fee_charged]: '',
                  //   [e.attributes.payment_method]: '',
                  //   [e.attributes.source] : 'other_product_page',
                  //   [e.attributes.product_code]: ''
                  // })
                  dispatch(updateProduct(val.item));
                }}
                employeeId={employeeId}
              />
            </>
          </ParentWrapper>
        </View>
      </View>
    </>
  );
};

export default Product;
