import React, { useCallback, useEffect } from 'react';
import { View, StatusBar, FlatList, Pressable } from 'react-native';
import MainParentWrapper from '@components/MainParentWrapper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import Close from '@expo/vector-icons/AntDesign';
import { normalize } from '@style/helper';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import styles from './style';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { LOAN_TERMS } from '@utils/Constants';
import AmplitudeHelper from '@services/amplitude';
import { useNavigation, useRoute } from '@react-navigation/native';

const LoanTerms = () => {
  const navigation = useNavigation();
  const route = useRoute();
  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.Loans.loan_tnc_view_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: route?.params?.loanProductCode,
    });
  }, []);

  const navigateBack = (isAccepted) => {
    navigation.navigate('ProductStack', { screen: 'LoanDetails', params: { ...route.params, isAccepted } });
  };

  const onCloseHandler = () => {
    navigateBack(false);
  };

  const onButtonPressHandler = () => {
    navigateBack(true);
  };

  const renderItem = useCallback(({ item, index }) => {
    return (
      <View style={styles.contentCardContainer}>
        <MyText customStyle={styles.cardContentSrnStyle}>{index + 1}. </MyText>
        <MyText customStyle={styles.cardContentStyle}>{item}</MyText>
      </View>
    );
  }, []);

  return (
    <View style={styles.mainContainer}>
      <StatusBar translucent backgroundColor={colors.secondary_orchid} />
      <View style={styles.upperContainer}>
        <Pressable style={styles.closeStyle} onPress={onCloseHandler}>
          <Close name="close" size={normalize(22)} color={colors.neutral_cotton} />
          <MyText isRegular={false} customStyle={styles.titleStyle}>
            Syarat & Ketentuan
          </MyText>
        </Pressable>
      </View>
      <MainParentWrapper
        isError={false}
        isScrollable
        scrollContainer={styles.scrollableContainerStyle}
        loading={false}
        containerStyle={styles.mainContentStyle}
      >
        <FlatList
          data={LOAN_TERMS}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderItem}
          contentContainerStyle={styles.contentScrollStyle}
        />
      </MainParentWrapper>

      <LoadingButton
        testId="cekReward"
        textTransform="none"
        onPress={onButtonPressHandler}
        text={'Saya Setuju'}
        disabled={false}
        textStyle={styles.loginButtonTextStyle}
        container={styles.loginButtonStyle}
      />
    </View>
  );
};

export default LoanTerms;
