import React, { useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavBarOptions, tabIconOptions } from '@style/Themes';
import AmplitudeHelper from '@services/amplitude';

import loadable from '@loadable/component';

/* REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { setIsGajiGesa, setIsGajiTim } from '@redux/action/Authentication';

/* Page Imports */
// const WithdrawStack = loadable(() => import('@stacks/WithdrawStack'));
// const BantuanStack = loadable(() => import('@stacks/BantuanStack'));
// const TransaksiStack = loadable(() => import('@stacks/TransaksiStack'));
// const BillPaymentStack = loadable(() => import('@stacks/BillPaymentStack'));
// const LoanStack = loadable(() => import('@stacks/LoanStack'));
// const OtherDashboardStack = loadable(() => import('@stacks/OtherDashboardStack'));
import WithdrawStack from '@stacks/WithdrawStack';
import BantuanStack from '@stacks/BantuanStack';
import TransaksiStack from '@stacks/TransaksiStack';
import BillPaymentStack from '@stacks/BillPaymentStack';
import LoanStack from '@stacks/LoanStack';
import OtherDashboardStack from '@stacks/OtherDashboardStack';

/* Component Import */
import Modals from '@components/Modals';
import { normalize } from '@style/helper';

const NavBar = createBottomTabNavigator();

const NAV_SCREENS = {
  home: <NavBar.Screen name="Home" component={WithdrawStack} options={tabIconOptions('home', 'Beranda')} />,
  transaction: (
    <NavBar.Screen name="Transaksi" component={TransaksiStack} options={tabIconOptions('transaction', 'Transaksi')} />
  ),
  billpayment: (
    <NavBar.Screen
      name="Pembayaran"
      component={BillPaymentStack}
      options={tabIconOptions('billpayment', 'Pembayaran')}
    />
  ),
  other: <NavBar.Screen name="Lainnya" component={OtherDashboardStack} options={tabIconOptions('profile', 'Profil')} />,
  loan: <NavBar.Screen name="Pinjaman" component={LoanStack} options={tabIconOptions('loan', 'Pinjaman')} />,
  bantuan: <NavBar.Screen name="Bantuan" component={BantuanStack} options={tabIconOptions('bantuan', 'Bantuan')} />,
};

const HomeScene = () => {
  const dispatch = useDispatch();
  const { phoneNumber, userRoles, isGajitimEmployee, isGajiTim, isGajiGesa } = useSelector(
    (state) => state.Authentication,
  );

  const setRolesHandler = () => {
    if (userRoles) {
      userRoles.map((item) => {
        if (item === 'employee') {
          dispatch(setIsGajiGesa(item));
          AmplitudeHelper.setUserProperties({ 'GG User': true });
        } else if (item === 'gajitim employee') {
          dispatch(setIsGajiTim(item));
          AmplitudeHelper.setUserProperties({ 'GT User': true });
        }
      });
    }
  };

  useEffect(() => {
    setRolesHandler();
  }, []);

  const shouldRenderScreen = (phoneNumber, userRoles, isGajiTim, isGajiGesa, isGajitimEmployee) => {
    if (phoneNumber.charAt(0) === '1') return [NAV_SCREENS.loan, NAV_SCREENS.bantuan];

    if (userRoles && isGajiTim && !isGajiGesa && isGajitimEmployee) return [NAV_SCREENS.other];

    if (userRoles && isGajiGesa && isGajitimEmployee)
      return [NAV_SCREENS.home, NAV_SCREENS.transaction, NAV_SCREENS.billpayment, NAV_SCREENS.other];

    return [NAV_SCREENS.home, NAV_SCREENS.transaction, NAV_SCREENS.billpayment, NAV_SCREENS.other];
  };

  return (
    <>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <NavBar.Navigator
          tabBarOptions={NavBarOptions}
          screenOptions={{
            tabBarStyle: {
              height: normalize(52),
              borderTopWidth: 0,
            },
          }}
        >
          {shouldRenderScreen(phoneNumber, userRoles, isGajiTim, isGajiGesa, isGajitimEmployee)}
        </NavBar.Navigator>
      </SafeAreaView>
      <Modals />
    </>
  );
};

export default HomeScene;
