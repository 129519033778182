import { StyleSheet, Dimensions } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';
const { width: screenWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  sectionContainer: {
    width: '94%',
    // flex: 1,
    alignSelf: 'center',
  },
  placeholder: {
    width: '20%',
    height: normalizeHeight(50),
    marginRight: normalize(10),
    marginLeft: normalize(7),
    marginTop: normalizeHeight(22),
  },
});

export default styles;
