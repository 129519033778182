import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, TouchableOpacity, Dimensions, View, StyleSheet } from 'react-native';
import Dialog from 'react-native-dialog';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import {
  setCanContinue,
  setDisabledDate,
  setDisabledSubmit,
  setDisabledToday,
  resetModalType,
  updateShowModal,
} from '@redux/action/Common';
import { setReason } from '@redux/action/Transactions';

/* Component Import */
import ReasonsModal from './ReasonsModal.js';
import PaketModal from './PaketModal.js';
import PulsaModal from './PulsaModal.js';
import EMoneyModal from './EMoneyModal';
import EWAModal from './dashboard/EWAModal';
import { normalize } from '@style/helper';
import AmplitudeHelper from '@services/amplitude';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { store } from '@enhancedStore/store';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants.js';

/* Style Import */
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const Modal = ({ type }) => {
  let myModal = useRef();

  const [showTextBox, setShowTextBox] = useState(false);
  const common = useSelector((state) => state.Common);
  const { redeemCode } = store.getState().Referral;
  const { selectedReason, selectedReasonId, transSource } = useSelector((state) => state.Transactions);
  const { currentHomePageSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const attendanceDate = useSelector((state) => state.Attendance.attendanceModalDate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (common?.showModal && myModal?.current) {
      myModal?.current?.snapTo(0);
    }
  }, [common?.showModal]);
  useEffect(() => {
    if (!common?.showModal && myModal?.current) {
      myModal?.current?.snapTo(1);
      myModal = useRef();
    }
  }, [common?.showModal]);

  const handleModalClose = () => {
    dispatch(updateShowModal(''));
    dispatch(setDisabledSubmit(false));
    dispatch(setDisabledToday(false));
    dispatch(setDisabledDate(false));
  };

  const handleChange = (val) => {
    dispatch(
      setReason({
        reason: `Kebutuhan Lainnya (${val})`,
        reasonId: 18,
        reasonColor: '#4292ed',
        description: val,
      }),
    );
  };

  const handleNoReason = (message) => {
    Alert.alert(message, '', [
      {
        text: 'OK',
        style: 'cancel',
      },
    ]);
  };

  const updateTextBox = (val) => {
    handleModalClose();
    setShowTextBox(val);
  };

  const isDynamicAccountSelected = useMemo(
    () =>
      currentHomePageSelectedAccount?.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT ||
      currentHomePageSelectedAccount?.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
    [currentHomePageSelectedAccount],
  );

  return (
    <>
      {common.showModal && (
        <View style={styles.container}>
          <TouchableOpacity style={styles.button} onPress={() => handleModalClose()} />
        </View>
      )}
      {type === 'reasons' ? (
        <ReasonsModal
          reduceHeight={isDynamicAccountSelected}
          ref={myModal}
          showModal={common.showModal}
          handleClose={handleModalClose}
          handleNoReason={(message) => handleNoReason(message)}
          eventSource={transSource}
          updateTextBox={updateTextBox}
          redeemCode={redeemCode}
        />
      ) : type === 'paket' ? (
        <PaketModal ref={myModal} handleClose={handleModalClose} />
      ) : type === 'pulsa' ? (
        <PulsaModal ref={myModal} handleClose={handleModalClose} />
      ) : type === 'emoney' ? (
        <EMoneyModal ref={myModal} handleClose={handleModalClose} />
      ) : null}
      <Dialog.Container visible={showTextBox}>
        <Dialog.Title>Kebutuhan Lainnya</Dialog.Title>
        <Dialog.Input
          accessibilityLabel="otherInput"
          onChangeText={(val) => handleChange(val)}
          maxLength={49}
          autoFocus
          wrapperStyle={styles.wrapper}
        />
        <Dialog.Button
          accessibilityLabel="otherCancelButton"
          color="#F56B57"
          label="Batalkan"
          onPress={() => {
            const e = AMPLITUDE_CONSTANTS.attendancePaySlip.reasons_description_cancelled;
            AmplitudeHelper.logEvent(e.name, { [e.attributes.source]: 'others_reason_description' });
            setShowTextBox(false);
          }}
        />
        <Dialog.Button
          accessibilityLabel="otherLanjutButton"
          color="#F56B57"
          label="Lanjut"
          onPress={() => {
            // AmplitudeHelper.logEvent("customReason", {
            //   reason: selectedReason,
            // });

            AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.customReason.name, {
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.reason]: formatUnderScoreLowerCase(
                selectedReason ?? null,
              ),
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.reason_id]: selectedReasonId ?? null,
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.source]: formatUnderScoreLowerCase(
                transSource ?? null,
              ),
              [AMPLITUDE_CONSTANTS.Withdrawal.customReason.attributes.referral_code]: redeemCode || null,
            });

            setShowTextBox(false);
            dispatch(resetModalType());
            dispatch(setCanContinue(true));
          }}
        />
      </Dialog.Container>
    </>
  );
};

const styles = StyleSheet.create({
  container: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
  button: {
    height: screenHeight,
    width: screenWidth,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#d1d1d1',
    borderRadius: 10,
    fontSize: normalize(25),
    paddingLeft: 10,
  },
});

export default Modal;
