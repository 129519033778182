import React, { useEffect, useRef, useState } from 'react';
// import { Modalize } from 'react-native-modalize';
import { useDispatch, useSelector } from 'react-redux';
import EWAModal from '../dashboard/EWAModal';
import Reasons from './Reasons';

const Modals = () => {
  const { showEWAModal, showReasonsModal } = useSelector((state: any) => state.Modals);
  const ewaRef = useRef<Modalize>();
  const reasonRef = useRef<Modalize>();

  useEffect(() => {
    if (showEWAModal) {

      ewaRef?.current?.open();
    } else {
      ewaRef?.current?.close();
    }
  }, [showEWAModal]);

  useEffect(() => {
    if (showReasonsModal) {
      reasonRef?.current?.open();
    } else {
      reasonRef?.current?.close();
    }
  }, [showReasonsModal]);

  return (
    <>
      <EWAModal ref={ewaRef} />
      <Reasons />
    </>
  );
};

export default Modals;
