import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
const styles = StyleSheet.create({
  divider: {
    height: 3,
    marginBottom: 20,
  },
  mainContainer: { paddingHorizontal: '5%' },
  walletContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    borderWidth: 0.8,
    marginVertical: 15,
    borderColor: colors.gainsboro,
  },
  amountContainer: {
    marginStart: 15,
    flex: 1,
  },
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    position: 'absolute',
    bottom: 0,
    paddingVertical: 10,
    justifyContent: 'center',
    borderRadius: 30,
    marginVertical: 15,
    width: '90%',
  },
  disabledButton: { backgroundColor: colors.gainsboro },
  bankUiContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10%',
  },
  imageButton: {
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.philippineGray,
    alignSelf: 'center',
    width: '70%',
    marginTop: 10,
  },
  buttonStyle: { fontSize: 12, color: colors.black },
  pressableStyle: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoBackStyle: { backgroundColor: colors.mist },
});

export default styles;
