import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';

const CIRCLE_SIZE = 14;
const styles = StyleSheet.create({
  pillarStyle: {
    position: 'absolute',
    height: '100%',
    width: 30,
    zIndex: 10,
    paddingVertical: normalizeHeight(4),
    justifyContent: 'space-around',
  },
  circleStyle: {
    width: normalize(CIRCLE_SIZE),
    height: normalize(CIRCLE_SIZE),
    borderRadius: normalize(CIRCLE_SIZE) / 2,
    backgroundColor: colors.neutral_cotton,
    borderWidth: 0.7,
  },
});

export default styles;
