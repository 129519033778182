import React from "react";
import { Text, View, Image, StyleSheet } from "react-native";

import { normalize } from '@style/helper'

  const NotAvailable = ({show}) => {

      if(!show){
          return(<></>)
      }
      return(
            <View style={styles.container}>
                <Image source={require('@assets/not-available.png')}/>
                <Text style={styles.text}>
                    Ups!! Ada yang salah. Harap hubungi dukungan pelanggan kami atau coba lagi nanti.
                </Text>
            </View>
  )
  }

  const styles = StyleSheet.create({
        container: {marginTop:'25%',alignItems:'center'},
        text: {fontSize:normalize(20), maxWidth: '75%', textAlign: 'center', color: '#454545', fontFamily: 'Nunito-Bold'}
  })

	export default NotAvailable

