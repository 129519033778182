import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import TransactionItem from '@molecules/TransactionItem';
import { setFilter } from '@redux/action/TransactionDetails';
import { getPaymentCycles } from '@services/AttendanceApi';
import { getTransactionHistory, getTransactionSummary } from '@services/TransactionApi';
import { colors } from '@style/colors';
import { SVG_ICONS, Wallet } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { numberWithDots } from '@utils/UtilFunctions';
import { styles } from './style';
import { useNavigation } from '@react-navigation/native';

const AccountCard = ({ title, disabled, amount, floatingBg, containerStyle, icon, onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      android_ripple={{ color: colors.greyButton }}
      style={[cardStyle.container, disabled ? { backgroundColor: colors.slightLightGrey } : null, containerStyle]}
    >
      <View style={{ ...cardStyle.floatingBg, ...floatingBg }} />
      <View style={cardStyle.iconContainer}>
        <View style={cardStyle.iconSubContainer}>{icon}</View>
        <MyText h3 customStyle={{ color: colors.white, marginStart: 5 }}>
          {title}
        </MyText>
      </View>
      <MyText h4 isRegular={false} customStyle={{ color: colors.white, marginTop: 5, marginEnd: 5 }}>
        Rp{amount}
      </MyText>
    </Pressable>
  );
};
const cardStyle = StyleSheet.create({
  iconSubContainer: {
    padding: 3,
    borderRadius: 5,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  floatingBg: {
    position: 'absolute',
    height: 100,
    width: 100,
    backgroundColor: '#F8C8C1',
    opacity: 0.5,
    zIndex: 5,
    end: -55,
    top: -55,
    borderRadius: 100 / 2,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    borderRadius: 5,
    backgroundColor: colors.orange,
    paddingVertical: 15,
    minWidth: '40%',
    paddingStart: 20,
    elevation: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    overflow: 'hidden',
  },
});

const NewTransaksi = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTransaction, setLoadingTransaction] = useState(true);

  const getSomeTransactions = async () => {
    try {
      const { empCycle } = await dispatch(getPaymentCycles());
      dispatch(
        setFilter({
          cycles: [
            {
              id: empCycle[0].id,
              firstWorkingDate: empCycle[0].startDate,
              endWorkingDate: empCycle[0].endDate,
            },
          ],
        }),
      );
      const response = await dispatch(getTransactionSummary(3, 1));
      setBalance(response?.data ?? null);
      setLoading(false);
      const responseTransaction = await dispatch(getTransactionHistory(3, 1));
      setTransactions(responseTransaction?.data?.rows ?? []);
      setLoadingTransaction(false);
    } catch (error) {}
  };

  useEffect(() => {
    getSomeTransactions();
  }, []);

  const renderItem = useCallback(({ item }) => {
    return <TransactionItem item={item} />;
  }, []);

  return (
    <MainParentWrapper loading={loading}>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20 }}>
        <MyText isRegular={false} h5 customStyle={{ color: colors.blue }}>
          Saldo Tersisa:{' '}
        </MyText>
        <MyText isRegular={false} h3 customStyle={{ color: colors.blue }}>
          Rp
        </MyText>
        <MyText isRegular={false} h5 customStyle={{ color: colors.blue }}>
          {numberWithDots(Math.round(balance?.balance ?? 0))}
        </MyText>
      </View>

      <MyText isRegular h3 customStyle={{ color: colors.blue, marginTop: 15, paddingHorizontal: 20 }}>
        Kantong Dana
      </MyText>

      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <AccountCard
          onPress={() => navigation.navigate('AccountDetails', { data: balance, accountType: 'GajiGesa' })}
          title={'GajiGesa'}
          amount={numberWithDots(Math.round(balance?.balance ?? 0))}
          icon={<Image source={require('@assets/logo1.png')} style={{ height: 20, width: 20 }} />}
          floatingBg={{ backgroundColor: '#F8C8C1' }}
        />
        <AccountCard
          title={'Akun 1'}
          amount={'0'}
          disabled
          icon={<Wallet />}
          floatingBg={{ backgroundColor: '#F2F2F2' }}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <MyText isRegular h3 customStyle={{ color: colors.blue }}>
          Transaksi
        </MyText>
        <TouchableOpacity
          style={styles.seeAllButton}
          activeOpacity={0.6}
          onPress={() => navigation.navigate('TransactionList')}
        >
          <MyText customStyle={{ color: colors.white }} h2>
            See All
          </MyText>
        </TouchableOpacity>
      </View>
      {loadingTransaction ? (
        <ActivityIndicator color={colors.blue} size="large" style={{ marginTop: 30 }} />
      ) : (
        <FlatList
          data={transactions}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          style={{ marginTop: 15 }}
        />
      )}
    </MainParentWrapper>
  );
};

export default NewTransaksi;
