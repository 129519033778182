import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import MainParentWrapper from '@components/MainParentWrapper';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import InfoTextComponent from '@atoms/InfoTextComponent';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { useSelector } from 'react-redux';
import { numberWithDots, getAccruedSalary, getAvailableBalance } from '@utils/UtilFunctions';
import AbsoluteCard from '@atoms/SalaryInfo/AbsoluteCard';
import TitleText from '@atoms/SalaryInfo/TitleText';
import StatusBox from '@atoms/SalaryInfo/StatusBox';
import ToolTipModal from '@atoms/SalaryInfo/ToolTipModal';
import FAQSection from '@atoms/SalaryInfo/FAQSection';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import {
  getMaxWithdrawalAmount,
  getClosePeriodDate,
  getDateRange,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import styles from './style';

const FAQ_ONE = {
  title: 'Apa itu Akun Gaji?',
  data: [
    {
      icon: SVG_ICONS.salaryInfo.checkSalaryInfo,
      desc: 'Akun Gaji adalah dana yang bisa kamu akses per siklus gaji. Jumlah saldo ini bertambah tiap harinya, menyesuaikan dengan jumlah hari kerjamu.',
    },
    {
      icon: SVG_ICONS.salaryInfo.calculator,
      desc: 'Saldo ini bisa digunakan untuk tarik gaji langsung, bayar tagihan, isi pulsa, dan/atau top-up saldo e-money. Jumlah ini menyesuaikan dengan total pengeluaranmu.',
    },
    {
      icon: SVG_ICONS.salaryInfo.refreshSalaryInfo,
      desc: 'Jumlah Akun Gaji ini teratur-ulang saat masa transaksi sudah aktif kembali. Saldo tersisa akan ditransfer ke rekeningmu bersamaan dengan gajimu.',
    },
  ],
};

const FAQ_TWO = {
  title: 'Apa Itu Masa Transaksi?',
  data: [
    {
      icon: SVG_ICONS.salaryInfo.phoneCheck,
      desc: 'Saat masa transaksi aktif, kamu bisa mengakses gaji. Dengan demikian, kamu bisa melakukan penarikan gaji atau pembayaran tagihan dengan Saldo GajiGesa.',
    },
    {
      icon: SVG_ICONS.salaryInfo.phoneLock,
      desc: 'Saat masa transaksimu nonaktif, kamu hanya bisa melakukan transaksi dengan GajiGesa Poin. ',
    },
    {
      icon: SVG_ICONS.salaryInfo.phoneVocal,
      desc: 'Masa transaksi ini biasanya berdekatan dengan tanggal gajianmu. Hubungi HR-mu untuk informasi lebih lanjut terkait masa transaksi.',
    },
  ],
};

const SalaryInfo = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [firstFaqCollapse, setFirstFaqCollapse] = useState(true);
  const [secondFaqCollapse, setSecondFaqCollapse] = useState(true);

  const handleColllapsePressHandler = () => setCollapsed((prevState) => !prevState);
  const handleFIrstFaqCollapse = () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.faq_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.question]: FAQ_ONE.title,
      [e.attributes.action]: firstFaqCollapse ? 'collapse' : 'expand',
    });

    setFirstFaqCollapse((prevState) => !prevState);
  };
  const handleSecondFaqCollapse = () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.faq_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.question]: FAQ_TWO.title,
      [e.attributes.action]: setFirstFaqCollapse ? 'collapse' : 'expand',
    });
    setSecondFaqCollapse((prevState) => !prevState);
  };

  const {
    workedDays,
    salaryWithCappedAmount,
    todayBalanceFloor,
    usedBalance,
    maxWithdrawalAmount,
    workingDays,
    lockPeriod,
    endWorkingDate,
    numberOfLockPeriod,
    withdrawalLockType,
    firstWorkingDate,
    startWithdrawalDate,
  } = useSelector((state) => state.Withdrawal);

  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        salaryWithCappedAmount,
        todayBalanceFloor,
        workingDays,
        workedDays,
        maxWithdrawalAmount,
        usedBalance,
      ),
    [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance],
  );
  const todaySalaryFloor = Math.floor(salaryWithCappedAmount / workingDays);

  const [isTootipVisible, setTooltipVisible] = useState(false);

  const handleVisible = () => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.limit_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
    });

    setTooltipVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo;
    if (isTootipVisible)
      AmplitudeHelper.logEvent(e.limit_popup_appear.name, {
        [e.limit_popup_appear.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
    else
      AmplitudeHelper.logEvent(e.limit_popup_closed.name, {
        [e.limit_popup_closed.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
  }, [isTootipVisible]);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.salaryInfo.salary_info_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.others_page,
    });
  }, []);

  useEffect(
    () => () => {
      const e = AMPLITUDE_CONSTANTS.salaryInfo.back_button_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.salary_information_page,
      });
    },
    [],
  );

  return (
    <MainParentWrapper
      parentStyle={styles.parentStyle}
      containerStyle={styles.parentContainer}
      isScrollable
      isRounded={false}
    >
      <View style={styles.poinsContainer} />

      <View style={styles.absoluteAdj}>
        <AbsoluteCard
          isCollapsed={isCollapsed}
          handleColllapsePress={handleColllapsePressHandler}
          ewaBalance={`Rp${numberWithDots(newAvailableBalance ?? '')}`}
          withdrawalLimit={`Rp${getMaxWithdrawalAmount(maxWithdrawalAmount, salaryWithCappedAmount)}`}
          totalAccuredSalary={`Rp${numberWithDots(
            getAccruedSalary(salaryWithCappedAmount, workingDays, workedDays, maxWithdrawalAmount),
          )}`}
          workingDays={workedDays}
          totalExpense={`-Rp${numberWithDots(usedBalance ?? 0)}`}
          daySalary={`Rp${numberWithDots(todaySalaryFloor)}`}
          handleLimitPress={handleVisible}
        />

        <TitleText title="Status Masa Transaksi" />

        <StatusBox
          displayText={!lockPeriod ? 'Aktif Sampai' : 'Sedang Tidak Aktif'}
          isActive={!lockPeriod}
          timeStamp={getClosePeriodDate(endWorkingDate, withdrawalLockType, numberOfLockPeriod)}
        />

        <InfoTextComponent
          customTextStyle={styles.lockPeriodBannerTextStyle}
          iconContainer={styles.lockPeriodBannerIconContainerStyle}
          containerStyle={styles.lockperiodBannerContainerStyle}
          iconName="query-builder"
          infoText="Masa transaksimu sedang tidak aktif. Coba cek akun poin, uang kantor atau uang kas kamu jika ada"
          size={normalize(22)}
          iconColor={colors.primary_carnation}
          hide={!lockPeriod}
        />

        <InfoTextComponent
          iconContainer={styles.lockPeriodBannerIconContainerStyle}
          containerStyle={[styles.periodContainer, { alignItems: 'center' }]}
          iconName="info-outline"
          infoText={`Periode transaksi kamu adalah ${getDateRange(
            firstWorkingDate,
            endWorkingDate,
            startWithdrawalDate,
            withdrawalLockType,
            numberOfLockPeriod,
          )}`}
          customTextStyle={styles.bannerTextStyle}
          size={normalize(22)}
          iconColor={colors.secondary_darkOrchid}
          hide={lockPeriod}
        />

        <TitleText title="Edukasi GajiGesa" />
        <FAQSection
          title={FAQ_ONE.title}
          data={FAQ_ONE.data}
          handleCollapse={handleFIrstFaqCollapse}
          collapse={firstFaqCollapse}
          containerStyle={{ marginTop: normalize(14) }}
        />
        <FAQSection
          title={FAQ_TWO.title}
          data={FAQ_TWO.data}
          handleCollapse={handleSecondFaqCollapse}
          collapse={secondFaqCollapse}
        />
      </View>
      <ToolTipModal
        isVisible={isTootipVisible}
        handleVisible={handleVisible}
        withdrawalLimit={getMaxWithdrawalAmount(maxWithdrawalAmount, salaryWithCappedAmount)}
      />
    </MainParentWrapper>
  );
};

export default SalaryInfo;
