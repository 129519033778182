import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@style/helper';

export const styles = StyleSheet.create({
  title: { marginBottom: 10 },
  icon: { alignSelf: 'center' },
  closeButton: {
    alignSelf: 'flex-end',
    padding: 15,
  },
  innerContainer: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: normalize(5),
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  textContainer: {
    marginVertical: 10,
    width: '95%',
    alignSelf: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15,
    width: '95%',
    alignSelf: 'center',
  },
  cta: {
    width: '95%',
    borderRadius: 30,
    marginVertical: 15,
    alignSelf: 'center',
  },
});
