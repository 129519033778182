import React from 'react';
// import BottomSheet from 'reanimated-bottom-sheet';

/* Context Import */

/* Component Import */
import Header from '@atoms/Header';
import EMoneyContent from './EMoneyContent';
import { Dimensions } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';

const { height } = Dimensions.get('screen');
const EMoneyModal = React.forwardRef(({ handleClose }, ref) => {
  const titleStyle = {
    fontSize: normalize(20),
    fontFamily: 'Nunito-Bold',
    marginBottom: normalizeHeight(15),
    color: '#3863f0',
    alignSelf: 'flex-start',
  };

  return (
    <></>
    //     <BottomSheet
    //       ref={ref}
    //       snapPoints={[height * 0.6, 0]}
    //       initialSnap={1}
    //       borderRadius={10}
    //       renderContent={() => <EMoneyContent />}
    //       renderHeader={() => <Header title={'Pilih Produk'} handleClose={handleClose} titleStyle={titleStyle} />}
    //       enabledInnerScrolling
    //     />
  );
});

export default EMoneyModal;
