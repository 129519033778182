import React from "react";
import { FlatList, Linking, Image, TouchableOpacity, ScrollView, Dimensions, StyleSheet } from "react-native";

import { normalizeHeight } from '@style/helper'
import AmplitudeHelper from "@services/amplitude";
import { AMPLITUDE_CONSTANTS } from "@utils/AmplitudeConstants";
const { width: screenWidth } = Dimensions.get('window')

const Asuransi = ({dataList, show}) => {

	const utmToData = (url) => {
		return JSON.parse(`{${
				url
				.split('/')
				.reverse()[0]
				.split('?')
				.reverse()[0]
				.split('&')
				.map(item => {return `"${item.split('=')[0]}":"${item.split('=')[1]}"`})
		}}`)
	}

	const handleBannerClick = (row) => {
		// AmplitudeHelper.logEvent('qoalaBannerClick', utmToData(row.item.description))
		let e = AMPLITUDE_CONSTANTS.BillPayment.Asuransi.qoalaBannerClick
		AmplitudeHelper.logEvent(e.name, {
			[e.attributes.transaction_category_id] : 'bp-16',
			[e.attributes.banner_name] : utmToData(row?.item?.description)
		})
		Linking.openURL(row?.item?.description)
	}

	if(!show){
		return(<></>)
	}
	return(
		<ScrollView contentContainerStyle={{paddingBottom: '15%'}}>
				<FlatList 
				data={dataList["Qoala"]}
				renderItem={(row) => {
					return(
					<>
						<TouchableOpacity 
							accessibilityLabel='asuransiProduct'
							style={styles.button}
							onPress={() => handleBannerClick(row)} 
							>
							<Image style={styles.image} source={{uri: row.item.imageUrl}}/>
						</TouchableOpacity>
					</>
					)}
				}
				numColumns={1}
				/> 
		</ScrollView>
	)
}

const styles = StyleSheet.create({
	button: {
		borderRadius: 10,
		borderWidth: 1,
		borderBottomWidth: 6,
		borderColor: '#d1d1d1',
		overflow: 'hidden',
		width: screenWidth * 0.8,
		height: normalizeHeight(90),
		marginTop: normalizeHeight(10),
		marginBottom: normalizeHeight(10)
	},
	image: {
		width: '100%', 
		height: '100%', 
		resizeMode: 'stretch'
	}
})


export default Asuransi
