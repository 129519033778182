import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerStyle: { margin: 0, justifyContent: 'flex-end' },
  outerContainer: {
    backgroundColor: colors.neutral_cotton,
    width: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: normalize(17),
  },
  closeContainer: { alignSelf: 'flex-end', marginTop: normalize(18), marginRight: normalize(4) },
  textStyleOne: { fontSize: normalize(19), marginTop: normalize(10) },
  textStyleTwo: { marginTop: normalize(8), fontSize: normalize(13) },
  laodingContainerStyle: {
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primary_carnation,
    color: colors.neutral_charcoal,
    width: '100%',
    marginHorizontal: 0,
    marginVertical: normalize(16),
    marginTop: normalize(25),
    borderRadius: normalize(30),
  },
});

export default styles;
