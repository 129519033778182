import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

export const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    marginVertical: 10,
    justifyContent: 'center',
  },
  inputText: {
    borderWidth: 0.8,
    borderColor: colors.lightGrey,
    borderRadius: 12,
    paddingVertical: 8,
    paddingHorizontal: 10,
    fontFamily: Font.Nunito_Regular,
    color: colors.black,
    width: '90%',
    outlineColor: 'transparent',
  },
  container: {
    flex: 1,
    paddingBottom: 10,
  },
  childContainer: {
    width: '95%',
    flex: 1,
    backgroundColor: colors.white,
    alignSelf: 'center',
    marginTop: 20,
    elevation: 5,
    borderRadius: 20,
    overflow: 'hidden',
  },
  blueBg: {
    height: '30%',
    width: '100%',
    backgroundColor: colors.blue,
    position: 'absolute',
    zIndex: -5,
  },
});
