import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Image, Pressable, TouchableOpacity, View } from 'react-native';
import { colors } from '@style/colors';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import { styles } from './style';
import Close from '@expo/vector-icons/AntDesign';
import { numberWithDots } from '@utils/UtilFunctions';
import LoadingButton from '@atoms/LoadingButton';

const PacketDataConfirmSheet = ({ visible, onPressModal, item, onPressConfirm }) => {
  return (
    <MyModal isVisible={visible} onPress={onPressModal} containerStyle={styles.modal}>
      <View style={styles.container}>
        <TouchableOpacity onPress={onPressModal} style={{ alignSelf: 'flex-end', padding: 15 }}>
          <Close name="close" size={20} color={colors.grey} />
        </TouchableOpacity>

        <View style={{ justifyContent: 'space-evenly', flex: 1, paddingHorizontal: 20 }}>
          <MyText h4 isRegular={false}>
            Detail Produk
          </MyText>
          <View style={{ marginTop: -15 }}>
            <MyText h5 isRegular={false}>
              {item?.name}
            </MyText>
            <MyText>Harga Rp {numberWithDots(item?.amount)} + GajiGesa Fee</MyText>
          </View>

          <LoadingButton
            text={'Konfirmasi'}
            buttonColor={colors.orange}
            loadingColor={colors.white}
            container={{ borderRadius: 40 }}
            onPress={() => onPressConfirm(item)}
          />
        </View>
      </View>
    </MyModal>
  );
};

export default React.memo(PacketDataConfirmSheet);
