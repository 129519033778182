import React from 'react';
import MainParentWrapper from '@components/MainParentWrapper';
import LoanMetaList from '@atoms/Loan/LoanMetaList';

const LoanInfo = () => {
  return (
    <MainParentWrapper isRounded={false} isError={false} loading={false} containerStyle={{ flex: 1 }}>
      <LoanMetaList />
    </MainParentWrapper>
  );
};

export default LoanInfo;
