import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, StyleSheet } from 'react-native';
import { numberWithDots } from '@utils/UtilFunctions';

/* Styles */
import { LgButton } from '@components/Button';
import { centerView, subTitleText } from '@style/Layouts';
import { background, XlCard } from '@style/Themes';
const { height: screenHeight } = Dimensions.get('window');
import { normalize, normalizeHeight } from '@style/helper';
import Loading from '@screens/Loading';
import { SvgUri } from 'react-native-svg';

/* Context Import */
import AmplitudeHelper from '@services/amplitude';
import { useDispatch } from 'react-redux';
import { setConfirmed } from '@redux/action/Common';
import { setAdminFee, setAmountReceived, setLoanCalculate } from '@redux/action/Loan';
import { loan } from '@services/LoanService';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';

const SettlementLoan = ({ route, navigation }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { timeTaken, category, productName } = route.params;

  useEffect(() => {
    AmplitudeHelper.logEvent('selamatPage', { withdrawal_amount: productName });
    //AmplitudeHelper.trackEvent('selamatPage')
  }, []);

  const handlePress = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(setConfirmed(false));
      dispatch(setAmountReceived(0));
      dispatch(setLoanCalculate({}));
      dispatch(setAdminFee(0));
      dispatch(loan());
      navigation.popToTop();
    }, 2000);
  };

  return (
    <>
      <View style={[centerView, background]}>
        <View style={[styles.container, XlCard]}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <View style={styles.logoContainer}>
                <View style={styles.logo}>{SVG_ICONS.clockIcon}</View>
                <Text style={[styles.cardTitle, subTitleText]}>Dalam Proses</Text>
                <Text style={[styles.timeTaken, subTitleText]}>{timeTaken}</Text>
                <Text style={[styles.amountText, subTitleText]}>
                  {`Pinjaman ke Perusahaan sebesar Rp ${numberWithDots(productName)}`}
                </Text>
                <Text style={[styles.checkStatus, subTitleText]}>
                  {`Cek status ${category} kamu secara berkala di aplikasi GajiGesa.`}
                </Text>
              </View>
              <LgButton title="OK" style={{ marginBottom: normalizeHeight(70) }} onPress={handlePress} />
            </>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  cardTitle: {
    color: '#3863f0',
    fontWeight: 'bold',
    alignSelf: 'center',
    marginTop: -5,
    fontSize: normalize(18),
    textAlign: 'center',
  },
  amountText: {
    alignSelf: 'center',
    color: '#f56b57',
    marginBottom: 0,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: normalize(16),
    marginTop: 25,
  },
  container: { flex: 1, alignItems: 'center', height: normalizeHeight(screenHeight / 1.2) },
  logoContainer: { maxWidth: '85%', flex: 1, alignItems: 'center', marginLeft: '-7%' },
  logo: {
    flex: 0.5,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    marginLeft: normalize(25),
    marginTop: normalizeHeight(20),
  },
  timeTaken: {
    color: 'black',
    alignSelf: 'center',
    marginTop: -10,
    fontSize: normalize(14),
    textAlign: 'center',
  },
  checkStatus: {
    color: 'black',
    marginLeft: '10%',
    fontSize: normalize(14),
    textAlign: 'center',
    minWidth: '100%',
  },
});

export default SettlementLoan;
