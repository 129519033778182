import { batch } from 'react-redux';
import { setConfirmed } from '@redux/action/Common';
import { setAdminFee, setAmountReceived, setLoan, setLoanCalculate, setStatusLoan } from '@redux/action/Loan';
import mainAPI from './api'

const LoanService = {
 
  getLoan: async function() {
    return await mainAPI('loans', 'get')
  },

  calculateLoan: async function(data) {
    return await mainAPI('loans/calculator', 'post', data)
  },

  confirmationLoan: async function(data) {
    return await mainAPI('loans', 'post', data)
  }
}

export const loan =()=>{

  return async (dispatch)=>{
    try {
      const response = await mainAPI('loans', 'get')

      dispatch(setLoan(response.data?.data))
      dispatch(setStatusLoan(response.data?.data?.status))
  
    } catch (error) {

      throw error
    }
  }

}

export const loanCalc =(tenureId)=>{

  return async (dispatch)=>{
    try {
      const resp = await mainAPI('loans/calculator', 'post', tenureId)
      batch(() => {
        dispatch(setLoanCalculate(resp.data?.data))
        dispatch(setAdminFee(resp.data?.data?.adminFee))
        dispatch(setAmountReceived(resp.data?.data?.amountReceived))
      })  
  
    } catch (error) {

      throw error
    }
  }

}

export const loanConfirm =(data)=>{

  return async (dispatch)=>{
    try {
      const resp = await mainAPI('loans', 'post', data)
      dispatch(setConfirmed(true))
  
    } catch (error) {

      throw error
    }
  }

}

export default LoanService;