import React, { useState, useEffect } from 'react';
import { Text, View, Dimensions, ScrollView, StyleSheet } from 'react-native';
import Icon from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import { numberWithDots } from '@utils/UtilFunctions';

/* Styles */
import { centerView } from '@style/Layouts';
import { background, XlCard, tableStyles } from '@style/Themes';
import { CheckBox, CheckedBox } from '@components/Button';
import TransactionService from '@services/TransactionService';
const { height: screenHeight } = Dimensions.get('window');
import { normalize, normalizeHeight } from '@style/helper';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { respError } from '@redux/action/Common';

/* Screen Import */
import Loading from './Loading';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const TransaksiDetail = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.Authentication);

  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState('');
  const [totalReceived, setTotalReceived] = useState('');
  const [totalFee, setTotalFee] = useState('');
  const [filterOptions, setFilterOptions] = useState([
    { name: 'Penarikan', selected: false },
    { name: 'eMoney', selected: false },
    { name: 'Top-Up', selected: false },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const clickedBack = navigation.addListener('blur', () => {
      AmplitudeHelper.logEvent('userClickedBack', { screen: 'Transaksi Detail' });
    });

    return clickedBack;
  }, [navigation]);

  useEffect(() => {
    AmplitudeHelper.logEvent('transaksiDetail');
  }, []);

  useEffect(() => {
    filterTable(filters);
  }, [filters]);

  useEffect(() => {
    updateFilters();
  }, [filterOptions]);

  const addDots = (x) => {
    if (x) {
      return numberWithDots(x);
    } else {
      return '0';
    }
  };

  const head = ['Tanggal Transaksi', 'Jumlah Penarikan\n(Rp)', `Biaya Admin\n(Rp)`, `Jumlah\nDiterima\n(Rp)`];
  const { month, startDate, endDate } = route.params;

  const getTransactionMonthlyDetailFeed = () => {
    TransactionService.getTransactions(startDate, endDate, ['pending', 'completed'])
      .then((resp) => {
        if (resp.status == 200 || resp.status == 201) {
          if (resp.data.data.length == 0) {
            setLoading(false);
            dispatch(respError(resp.data.message));
          } else {
            setLoading(false);
            setTotalTransactions(resp.data.data.totalRequest);
            setTotalFee(resp.data.data.totalFee);
            setTotalReceived(resp.data.data.totalReceive);
            setData(resp.data.data.rows);
            setBackupData(resp.data.data.rows);
          }
        } else {
          updateLoading(false);
        }
      })
      .catch((e) => {
        updateLoading(false);
        if (e.response) {
          console.log(e.response);
        }
      });
  };

  useEffect(() => {
    getTransactionMonthlyDetailFeed();
  }, []);

  const filterTable = (array) => {
    let filteredData = array.length == 0 ? backupData : backupData.filter((row) => array.includes(row.category));
    setData(filteredData);
  };

  const updateFilters = () => {
    let updatedData = [];
    filterOptions.map((item) => {
      if (item.selected) {
        updatedData.push(item?.name);
      }
    });
    setFilters(updatedData);
  };

  const toggleModal = () => {
    AmplitudeHelper.logEvent('userClickedFilter');
    setModalVisible(!modalVisible);
  };

  const updateSelection = (option) => {
    let updatedOptions = filterOptions.map((item) => {
      if (item?.name == option) {
        if (item.selected) {
          AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterRemoved.name, {
            [AMPLITUDE_CONSTANTS.TransactionPage.filterReset.attributes.source]: 'transaction_page',
            [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_parameter]: item,
            [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_input]: item?.name,
          });
          //  AmplitudeHelper.logEvent('userRemovedFilter', {filter: item?.name})
        } else {
          AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.name, {
            [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.source]: 'transaction_page',
            [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_parameter]: item,
            [AMPLITUDE_CONSTANTS.TransactionPage.filterSaved.attributes.filter_input]: item?.name,
          });
          //   AmplitudeHelper.logEvent('userAddedFilter', {filter: item?.name})
        }
        item.selected = !item.selected;
      }
      return item;
    });
    setFilterOptions(updatedOptions);
  };

  const resetSelections = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterReset.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.filterReset.attributes.source]: 'transaction_page',
    });

    let updatedOptions = filterOptions.map((item) => {
      item.selected = false;
      return item;
    });
    setFilterOptions(updatedOptions);
  };

  return (
    <>
      <View style={[centerView, background, { borderTopWidth: screenHeight / 1.8 }]}>
        <View style={[styles.container, XlCard]}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>Transaksi {month}</Text>
              </View>
              <View style={styles.userNameContainer}>
                <View style={styles.userName}>
                  <Text style={styles.userNameText}>{userName ? userName : 'User'}</Text>
                </View>
                <View style={styles.totalsContainer}>
                  <Text style={styles.totalPenarikan}>Total Penarikan : Rp {addDots(totalTransactions)}</Text>
                  <Text style={styles.totalBiaya}>Total Biaya Admin : Rp {addDots(totalFee)}</Text>
                  <Text style={styles.totalPenerimaan}>Total Penerimaan : Rp {addDots(totalReceived)}</Text>
                </View>
              </View>
              <View style={styles.filterContainer}>
                <Text style={styles.filterButton} onPress={() => toggleModal()}>
                  FILTER <Icon name="caret-down" style={{ color: 'black' }} />
                </Text>
                {filters.length == 0 ? (
                  <Text style={styles.noFilter}>Tidak ada filter</Text>
                ) : (
                  filters.map((o) => {
                    return (
                      <>
                        <Text style={styles.filterLabel} onPress={() => updateSelection(o)}>
                          {o}{' '}
                          <Text onPress={() => updateSelection(o)}>
                            <Icon name="close" style={{ fontSize: normalize(15) }} />
                          </Text>
                        </Text>
                      </>
                    );
                  })
                )}
              </View>
              <View style={{ ...tableStyles.headerTransaksiDetail, paddingLeft: 15 }}>
                {head.map((title) => {
                  return (
                    <Text
                      style={{
                        ...tableStyles.headerTransaksiDetailText,
                        flex: 1,
                        minWidth: normalize(65),
                      }}
                    >
                      {title}
                    </Text>
                  );
                })}
              </View>
              <>
                {data && (
                  <ScrollView style={{ width: '100%' }}>
                    {data.map((row, index) => {
                      return (
                        <>
                          <View key={index} style={{ ...tableStyles.row, paddingLeft: normalize(20) }}>
                            <Text style={styles.createdAt}>{moment(row.createdAt).format('DD/MM')}</Text>
                            <View style={{ ...tableStyles.rowTextColumn, flex: 0.5 }}>
                              <Text style={styles.rowAmount}>{addDots(row.amount)}</Text>
                              <Text
                                style={{
                                  ...tableStyles.reason,
                                  backgroundColor: row.categoryColor,
                                  borderColor: row.categoryColor,
                                  fontSize: normalize(10),
                                  color: row.categoryColor == '#000000' ? 'white' : 'black',
                                }}
                              >
                                {row.category}
                              </Text>
                            </View>
                            <Text style={styles.rowFee}>{addDots(row.fee)}</Text>
                            <Text style={styles.rowTotal}>{addDots(row.total)}</Text>
                          </View>
                        </>
                      );
                    })}
                  </ScrollView>
                )}
              </>
            </>
          )}
        </View>
        {/* } */}
      </View>
      {modalVisible && (
        <View style={styles.filterViewContainer}>
          <View style={styles.filterView}>
            <View style={styles.filterList}>
              {filterOptions.map((o) => {
                return (
                  <View style={styles.filterItem}>
                    {o.selected ? (
                      <CheckedBox onPress={() => updateSelection(o.name)} />
                    ) : (
                      <CheckBox onPress={() => updateSelection(o.name)} />
                    )}
                    <Text style={styles.filterName}>{o.name}</Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.resetButton}>
              <Text
                style={styles.resetText}
                onPress={() => {
                  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.filterRemoved.name, {
                    [AMPLITUDE_CONSTANTS.TransactionPage.filterRemoved.attributes.source]: 'transaction_page',
                  });
                  resetSelections();
                }}
              >
                RESET
              </Text>
              <Text
                style={styles.cancelButton}
                onPress={() => {
                  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.userCanceledFilters.name);
                  resetSelections();
                  setModalVisible(false);
                }}
              >
                CANCEL
              </Text>
              <Text
                style={styles.applyButton}
                onPress={() => {
                  AmplitudeHelper.logEvent('userAppliedFilters', { filters_used: filters });
                  setModalVisible(false);
                }}
              >
                APPLY
              </Text>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    minHeight: screenHeight / 1.4,
    maxHeight: screenHeight / 1.4,
    overflow: 'hidden',
    top: -(screenHeight / 1.9),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    padding: 10,
    marginTop: normalizeHeight(15),
  },
  title: { color: '#f56b57', fontSize: normalize(17), alignSelf: 'flex-end', fontFamily: 'Nunito-Bold' },
  userNameContainer: {
    width: '95%',
    maxHeight: '15%',
    borderWidth: 1,
    borderColor: '#f7f7f7',
    borderRadius: 16,
    backgroundColor: '#f7f7f7',
    marginTop: normalizeHeight(5),
    display: 'flex',
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.52,
    shadowRadius: 5.46,
    elevation: 12,
    marginBottom: normalize(5),
  },
  userName: {
    width: '35%',
    borderRightWidth: 1,
    borderRightColor: '#d4d4d4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userNameText: {
    fontSize: normalize(11),
    maxWidth: normalize(170),
    textAlign: 'center',
    fontFamily: 'Nunito-Bold',
  },
  totalsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    height: '90%',
    paddingLeft: 10,
  },
  totalPenarikan: { fontSize: normalize(11), fontFamily: 'Nunito-Bold' },
  totalBiaya: { fontSize: normalize(11), fontFamily: 'Nunito-Bold' },
  totalPenerimaan: { fontSize: normalize(11), fontFamily: 'Nunito-Bold' },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '7%',
    marginLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
  },
  filterButton: {
    marginTop: 50,
    fontSize: normalize(12),
    minWidth: normalize(50),
    color: '#f56b57',
    marginRight: 10,
    height: normalizeHeight(50),
  },
  noFilter: { marginTop: 12, fontSize: normalize(12), color: '#c4c4c4', fontFamily: 'Nunito-Regular' },
  filterLabel: {
    marginTop: normalizeHeight(20),
    fontSize: normalize(12),
    borderWidth: 1,
    borderColor: '#d4d4d4',
    backgroundColor: '#f7f7f7',
    borderRadius: 8,
    padding: 2,
    marginRight: 7,
    minHeight: normalizeHeight(20),
    fontFamily: 'Nunito-Regular',
  },
  createdAt: { fontSize: normalize(12), flex: 0.15, alignSelf: 'baseline' },
  rowAmount: { fontSize: normalize(12), alignSelf: 'center' },
  rowFee: { fontSize: normalize(12), flex: 0.3, alignSelf: 'baseline' },
  rowTotal: { fontSize: normalize(12), flex: 0.25, alignSelf: 'baseline' },
  filterViewContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: '100%',
    height: screenHeight / 1.75,
    maxHeight: normalizeHeight(420),
  },
  filterView: {
    marginLeft: '7%',
    width: normalize(250),
    height: normalizeHeight(160),
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#d4d4d4',
    borderRadius: 8,
  },
  filterList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5%',
    marginLeft: '5%',
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '65%',
    alignSelf: 'flex-start',
    marginBottom: normalizeHeight(10),
  },
  filterName: { marginLeft: 10, fontSize: normalize(17), color: '#454545', fontFamily: 'Nunito-Regular' },
  resetButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: normalizeHeight(10),
  },
  resetText: { color: '#454545', padding: 7, fontWeight: '400', fontFamily: 'Nunito-Bold', fontSize: normalize(12) },
  cancelButton: {
    backgroundColor: '#c4c4c4',
    color: 'black',
    padding: 7,
    fontWeight: '400',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(12),
    borderRadius: 10,
  },
  applyButton: {
    backgroundColor: '#f56b57',
    color: 'white',
    padding: 7,
    fontWeight: '400',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(12),
    borderRadius: 10,
  },
});

export default TransaksiDetail;
