import React from 'react';
// import BottomSheet from 'reanimated-bottom-sheet'
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

/* Component Import */
import Header from '@atoms/Header';
import PaketContent from './PaketContent';

const PaketModal = React.forwardRef(({ handleClose }, ref) => {
  const handleModalClose = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.detailsModalClosed;
    AmplitudeHelper.logEvent(e.name);
    handleClose();
  };

  return (
    <>
      {/* <BottomSheet
        ref={ref}
        snapPoints={[400, -250]}
        initialSnap={1}
        borderRadius={10}
        renderContent={() => <PaketContent handleClose={handleModalClose} />}
        renderHeader={() => <Header handleClose={handleModalClose} />}
      /> */}
    </>
  );
});

export default PaketModal;
