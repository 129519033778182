import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

export const styles = StyleSheet.create({
  transactionContainer: {
    elevation: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: colors.white,
    marginTop: 10,
    padding: 15,
    borderRadius: 3,
  },
  textTransaction: {
    fontFamily: Font.Nunito_Regular,
    color: '#979797',
    marginBottom: 20,
  },
  detailButton: {
    alignSelf: 'flex-end',
    marginTop: 8,
  },
  textDetail: {
    fontFamily: Font.Nunito_Bold,
    color: colors.orange,
    textDecorationLine: 'underline',
  },
  seeAllButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: colors.blue,
    borderRadius: 20,
  },
});
