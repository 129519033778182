import React, { useState, useEffect, useCallback } from 'react';
import { FlatList } from 'react-native';
import Divider from '@atoms/Divider';
import { styles } from './style';
import MainParentWrapper from '@components/MainParentWrapper';
import LoanHeader from '@atoms/Loan/LoanHeader';
import InfoBar from '@atoms/Loan/InfoBar';
import ListTitle from '@atoms/Loan/ListTitle';
import { respError } from '@redux/action/Common';
import { useLazyQuery } from '@apollo/client';
import { GET_LOAN_PRODUCTS } from '@gqlQuery/loan';
import LoanItem from '@atoms/Loan/LoanItem';
import { useDispatch, useSelector } from 'react-redux';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { useNavigation, useRoute } from '@react-navigation/native';

const LoanListing = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [loans, setLoanlist] = useState([]);
  const [empStatus, setEmpStatus] = useState(false);
  const { employeeId } = useSelector((state) => state.Authentication);

  const dispatch = useDispatch();

  const [getLoanProducts] = useLazyQuery(GET_LOAN_PRODUCTS, {
    fetchPolicy: 'no-cache',
    variables: { empId: employeeId },
    onCompleted: (data) => {
      setLoanlist(data.loan_eligibility);

      setEmpStatus(data.gg_employee[0].status);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    getLoanProducts();
    let e = AMPLITUDE_CONSTANTS.Loans.loan_offers_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route?.params?.source,
    });
  }, []);

  const onLoanProductPress = (item) => {
    let e = AMPLITUDE_CONSTANTS.Loans.loan_selection_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.loan_product_code]: item.loan_product_eligibility.loanCode,
    });

    if (empStatus !== 'active') {
      dispatch(
        respError('Maaf, kamu tidak bisa bertransaksi saat ini. Hubungi HR-mu jika ingin bertransaksi kembali.'),
      );
      return;
    }

    navigation.navigate('ProductStack', {
      screen: 'LoanDetails',
      params: {
        loanProductCode: item.loan_product_eligibility.loanCode,
        appId: null,
        isNewApply: true,
      },
    });
  };

  const renderItem = useCallback(
    ({ item }) => {
      const loanData = item.loan_product_eligibility;

      return (
        <LoanItem
          principalAmt={loanData.principal}
          receivable={loanData.principal - loanData.upfrontFee}
          tenure={loanData.tenure}
          installmentAmount={loanData.installmentValue}
          onPressItem={() => onLoanProductPress(item)}
        />
      );
    },
    [empStatus],
  );

  const onPressHeaderLink = () => {
    let e = AMPLITUDE_CONSTANTS.Loans.additional_loan_info_clicked;
    AmplitudeHelper.logEvent(e.name);

    navigation.navigate('ProductStack', { screen: 'LoanInfo' });
  };

  const Header = () => (
    <>
      <LoanHeader
        headerText={'Ajukan dana cepat sesuai kebutuhanmu dengan e-Kasbon. Cicil hingga 3 kali, langsung tuntas!'}
        onLinkPress={onPressHeaderLink}
      />
      <InfoBar />
      <Divider horizontal customStyle={styles.dividerStyle} />
      <ListTitle />
    </>
  );

  return (
    <MainParentWrapper isRounded={false} isError={false} loading={false} containerStyle={{ flex: 1, paddingTop: 0 }}>
      <FlatList
        contentContainerStyle={styles.listingContainer}
        data={loans}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        isScrollable={false}
        ListHeaderComponent={Header}
      />
    </MainParentWrapper>
  );
};

export default LoanListing;
