import tw from '@utils/tailwind';
import {
  twSharedSharedDateContainerStyles,
  twSharedBalanceComponent,
  twSharedBalanceStyles,
  twSharedUangKamuTxtStyle,
  twSharedBalanceTextStyle,
  twSharedProratedDetailParentStyles,
  twSharedRefreshStyle,
  twSharedFilterBtnParentStyle,
  twSharedFilterBtnStyle,
  twSharedFilterRefreshContainerStyle,
  twSharedFilterCountStyles,
  twSharedIncExpContainerStyle,
  twSharedIncExpDividerStyle,
  twSharedActivityIndicatorStyle,
  twSharedFlexRow,
  twSharedMarginLeft7,
  twSharedWidth1,
  twSharedPaddingTop0,
  twSharedModalContainerStyle,
  twSharedMarginTop15,
  twSharedPaddingBottom,
  twSharedSelfCenter,
  twSharedRPContainerStyles,
  twSharedRPParentStyles,
  twSharedTextColorWhite,
  twSharedFilterModalParent,
  twSharedMarginLeft10,
  twSharedProratedTextStyle,
  twSharedInfoIconStyle,
  twSharedFilterPressable,
  twSharedselectedIndicator,
} from '@gaji-gesa/gg-react-shared/src/organisms/transactionListStyles';

const twDateContainerStyles = tw`${twSharedSharedDateContainerStyles}`;
const twBalanceComponent = tw`${twSharedBalanceComponent}`;
const twBalanceStyles = tw`${twSharedBalanceStyles}`;
const twUangKamuTxtStyle = tw`${twSharedUangKamuTxtStyle}`;
const twBalanceTextStyle = tw`${twSharedBalanceTextStyle}`;
const twProratedDetailParentStyles = tw`${twSharedProratedDetailParentStyles}`;
const twRefreshStyle = tw`${twSharedRefreshStyle}`;
const twFilterBtnParentStyle = tw`${twSharedFilterBtnParentStyle}`;
const twFilterBtnStyle = tw`${twSharedFilterBtnStyle}`;
const twFilterRefreshContainerStyle = tw`${twSharedFilterRefreshContainerStyle}`;
const twFilterCountStyles = tw`${twSharedFilterCountStyles}`;
const twIncExpContainerStyle = tw`${twSharedIncExpContainerStyle}`;
const twIncExpDividerStyle = tw`${twSharedIncExpDividerStyle}`;
const twActivityIndicatorStyle = tw`${twSharedActivityIndicatorStyle}`;
const twOrchidColorStyle = tw.color('bg-secondary_orchid');
const twFlexRow = tw`${twSharedFlexRow}`;
const twMarginLeft7 = tw`${twSharedMarginLeft7}`;
const twWidth1 = tw`${twSharedWidth1}`;
const twPaddingTop0 = tw`${twSharedPaddingTop0}`;
const twModalContainerStyle = tw`${twSharedModalContainerStyle}`;
const twMarginTop15 = tw`${twSharedMarginTop15}`;
const twPaddingBottom = tw`${twSharedPaddingBottom}`;
const twSelfCenter = tw`${twSharedSelfCenter}`;
const twRPContainerStyles = tw`${twSharedRPContainerStyles}`;
const twRPParentStyles = tw`${twSharedRPParentStyles}`;
const twTextColorWhite = tw`${twSharedTextColorWhite}`;
const twFilterModalParent = tw`${twSharedFilterModalParent}`;
const twMarginLeft10 = tw`${twSharedMarginLeft10}`;
const twProratedTextStyle = tw`${twSharedProratedTextStyle}`;
const twInfoIconStyle = tw`${twSharedInfoIconStyle}`;
const twFilterPressable = tw`${twSharedFilterPressable}`;
const selectedIndicatortColor = tw.color('bg-error_rose');
const twselectedIndicator = tw`${twSharedselectedIndicator}`;

const styles = {
  twActivityIndicatorStyle,
  twBalanceComponent,
  twBalanceStyles,
  twBalanceTextStyle,
  twDateContainerStyles,
  twFilterBtnParentStyle,
  twFilterBtnStyle,
  twFilterCountStyles,
  twFilterRefreshContainerStyle,
  twIncExpContainerStyle,
  twIncExpDividerStyle,
  twOrchidColorStyle,
  twProratedDetailParentStyles,
  twRefreshStyle,
  twUangKamuTxtStyle,
  twFlexRow,
  twMarginLeft7,
  twWidth1,
  twPaddingTop0,
  twModalContainerStyle,
  twMarginTop15,
  twPaddingBottom,
  twSelfCenter,
  twRPContainerStyles,
  twRPParentStyles,
  twTextColorWhite,
  twFilterModalParent,
  twMarginLeft10,
  twProratedTextStyle,
  twInfoIconStyle,
  twFilterPressable,
  twselectedIndicator,
  selectedIndicatortColor,
};

export default styles;
