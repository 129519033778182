import React from 'react';
import { Text, View, Dimensions, TouchableOpacity, StyleSheet } from 'react-native';

/* Styles */
import { subTitleText } from '@style/Layouts';
import { LgButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
import AmplitudeHelper from '@services/amplitude';
const { height: screenHeight } = Dimensions.get('window');
import FaIcon from '@expo/vector-icons/FontAwesome5';
import { useDispatch } from 'react-redux';
import { updateShowModal } from '@redux/action/Common';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils//AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils//UtilFunctions';

const DropPick = ({ items, show, onPress, selected, employeeId }) => {
  const dispatch = useDispatch();

  if (show && selected) {
    return (
      <>
        <View style={{ maxHeight: screenHeight / 4, width: '100%', bottom: 0 }}>
          <Text style={[subTitleText, styles.title]}> Pilih Produk </Text>
          <View style={{ width: '85%', alignSelf: 'center' }}>
            <TouchableOpacity
              accessibilityLabel="selectProduct"
              style={styles.modalButton}
              onPress={() => {
                let e = AMPLITUDE_CONSTANTS.BillPayment.dropdownSelected;
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.transaction_category_id]: 'bp-15',
                });
                dispatch(updateShowModal('emoney'));
              }}
            >
              <Text style={styles.name}>{selected.name}</Text>
              <Text style={styles.icon}>
                <FaIcon name="angle-down" style={{ fontSize: normalize(20) }} />
              </Text>
            </TouchableOpacity>
            <LgButton
              testId={'eMoneyKonfirmasiButton'}
              style={styles.konfirmasi}
              title="Konfirmasi"
              onPress={() => {
                onPress(selected);

                // AmplitudeHelper.logEvent("userClickedPilihProduk", {
                //   data: selected,
                // });
                let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedProduct;
                AmplitudeHelper.logEvent(e.name, {
                  // [e.attributes.employee_id]:employeeId,
                  [e.attributes.source]: 'other_product_page',
                  [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selected?.categoryPrefix),
                });
              }}
            />
          </View>
        </View>
      </>
    );
  } else {
    return <></>;
  }
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(16),
    fontFamily: Font.Nunito_Bold,
    marginBottom: 0,
    marginTop: 10,
    width: '100%',
  },
  modalButton: {
    height: normalizeHeight(40),
    borderBottomWidth: 1,
    borderBottomColor: '#c4c4c4',
    marginBottom: normalizeHeight(10),
    marginTop: normalizeHeight(15),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
    width: '90%',
    alignSelf: 'center',
  },
  name: {
    fontSize: normalize(14),
    marginTop: normalizeHeight(10),
    fontFamily: Font.Nunito_Regular,
  },
  icon: { fontSize: normalize(17), marginTop: normalizeHeight(10) },
  konfirmasi: { marginBottom: 15, alignSelf: 'center' },
});

export default DropPick;
