import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingTop: normalizeHeight(8),
    paddingBottom: normalizeHeight(80),
    backgroundColor: colors.neutral_cotton,
  },

  dividerStyle: {
    height: normalizeHeight(4),
    marginTop: normalizeHeight(20),
    color: colors.blackColor,
  },

  bottomButtonContainer: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: normalize(5),
    backgroundColor: colors.neutral_cotton,
    flexDirection: 'row',
    width: '100%',
    elevation: 0.5,
    shadowColor: colors.blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    flex: 1,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingVertical: normalize(2),
  },
  copyButtonStyle: {
    borderWidth: 1.2,
    borderColor: colors.primary_carnation,
    borderRadius: normalize(50),
    width: '85%',
    backgroundColor: 'transparent',
  },
  shareButtonStyle: {
    borderWidth: 1,
    borderRadius: normalize(50),
    width: '45%',
    borderColor: colors.primary_carnation,
    backgroundColor: colors.primary_carnation,
  },

  pressableStyleContainer: { flex: 1, alignItems: 'center', justifyContent: 'center' },

  benifitContainer: {
    flexDirection: 'row',
    paddingHorizontal: normalize(10),
    marginTop: normalize(16),
  },
});
