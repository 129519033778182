import { StyleSheet, Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize } from '@style/helper';

export const styles = StyleSheet.create({
  scene: {
    marginHorizontal: 20,
    marginTop: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontFamily: Font.Nunito_Bold,
    fontSize: 16,
    lineHeight: 22,
    color: '#262626',
    marginRight: 4,
  },
  subTitle: {
    fontFamily: Font.Nunito_Regular,
    fontSize: 10,
    lineHeight: 14,
    color: '#979797',
  },
  cardContainer: {
    flexDirection: 'row',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginRight: 16,
    marginTop: 16,
    borderRadius: 20,
    marginBottom: 26,
  },
  cardLeft: {
    backgroundColor: '#F56B57',
    borderTopStartRadius: 10,
    borderBottomStartRadius: 10,
    padding: 10,
    paddingRight: 18,
    textAlign: 'left',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  cardLeftTitle: {
    fontFamily: Font.Nunito_Bold,
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'left',
    color: '#FFFFFF',
  },
  cardLeftSubTitle: {
    fontFamily: Font.Nunito_Regular,
    fontSize: 12,
    lineHeight: 16,
    textAlign: 'left',
    color: '#FFFFFF',
  },
  cardLeftButton: {
    marginTop: 10,
    backgroundColor: '#F4A32F',
    borderWidth: 1,
    borderColor: '#FED27A',
    textAlign: 'left',
    paddingHorizontal: normalize(10),
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: normalize(4),
    borderRadius: 20,
    marginStart: normalize(0),
    minWidth: normalize(70),
  },
  cardLeftButtonText: {
    fontSize: 10,
    fontFamily: Font.Nunito_Regular,
    lineHeight: 14,
  },
  cardLeftIcon: {
    right: 0,
  },
  cardRight: {
    backgroundColor: '#FFFFFF',
    borderTopEndRadius: 10,
    borderBottomEndRadius: 10,
  },
  cardRightIcon: {
    marginHorizontal: 20,
    marginTop: 18,
    marginBottom: 12,
    height: 30,
  },
  cardRightTitle: {
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 8,
    lineHeight: 11,
    textAlign: 'center',
  },
  cardRightTriangle: {
    borderTopColor: '#3863F0',
    borderLeftColor: 'transparent',
    borderTopWidth: 25,
    borderLeftWidth: 25,
    borderRightColor: '#6AA0F6',
    borderRightWidth: 0,
    position: 'absolute',
    right: 0,
  },
  cardRightTopIcon: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
});
