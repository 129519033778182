import React, { useState } from "react";
import { FlatList, Text, View } from "react-native";

/* Styles */
import {
  subTitleText,
  boxCard,
  table,
  loanActiveStyle
} from "@style/Layouts";
import { SvgUri } from 'react-native-svg'
import {  } from "react";


const LoanActive = ({ dataLoan, statusLoan }) => {
  const [headerActive] = useState(['Tanggal Pembayaran', 'Jumlah Tagihan', 'Status'])
  const [bodyActive] = useState(['-', '-', '-'])

  return (
    <>
      <>
        <View style={boxCard.body}>
            <View style={boxCard.row}>
              <View style={boxCard.column}>
                <View style={boxCard.wrapper}>
                  <Text style={[boxCard.title, {marginBottom: 5}]}>Status Pinjaman</Text>
                  <View style={boxCard.borderStatus}>
                    <Text style={boxCard.borderStatusText}>{statusLoan === "INPROGRESS" ? 'ACTIVE' : statusLoan}</Text>
                  </View>
                </View>
                <View style={boxCard.wrapper}>
                  <Text style={[boxCard.title, {marginBottom: -10}]}>
                    Jumlah Pinjaman
                  </Text>
                  <Text style={[subTitleText, {color: "#454545"}]}>
                    {`Rp ${dataLoan.amount && dataLoan.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
                  </Text>
                </View>
                <View style={boxCard.wrapper}>
                  <Text style={[boxCard.title, {marginBottom: -10}]}>
                    Jangka Waktu Pinjaman
                  </Text>
                  <Text style={[subTitleText, {color: "#454545"}]}>
                    {dataLoan.tenureText}
                  </Text>
                </View>
              </View>
              <SvgUri style={{marginTop: 10}} uri="https://storage.googleapis.com/gajigesa-public/icons/loan-active.svg"/>
            </View>
          </View>
          <View style={loanActiveStyle.reminderBox}>
            <View style={loanActiveStyle.reminderImage}>
              <SvgUri uri='https://storage.googleapis.com/gajitim-assets/calendar-loan.svg'/>
            </View>
            <View style={[loanActiveStyle.reminderTextAlign, {paddingVertical: 10}]}>
              <Text style={loanActiveStyle.reminderText}>Pembayaran Selanjutnya</Text>
              <Text style={loanActiveStyle.reminderText}>Tagihan:</Text>
              <Text style={loanActiveStyle.reminderTextBold}>{`Rp. ${dataLoan.nextInstallment && dataLoan.nextInstallment.totalInstallment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}</Text>
            </View>
            <View style={[loanActiveStyle.reminderTextAlign, {paddingBottom: 10, paddingTop: 30}]}>
              <Text style={loanActiveStyle.reminderText}>Jatuh Tempo:</Text>
              <Text style={loanActiveStyle.reminderTextBold}>{dataLoan.nextInstallment && dataLoan.nextInstallment.dueDate}</Text>
            </View>
          </View>
          <View style={table.box}>
            <FlatList
              data={headerActive}
              columnWrapperStyle={table.header}
              renderItem={(item) => {
                return (
                  <Text key={item.index} style={[loanActiveStyle.reminderText, {
                    textAlign: item.item == 'Status' ? 'right' : undefined, 
                    marginRight: item.item == 'Status' ? 10 : 8
                  }]}>{item.item}</Text>
                )
              }}
              numColumns={3}
            />
            {Object.keys(dataLoan).length == 0 && (
              <View style={table.body}>
                <FlatList
                  data={bodyActive}
                  renderItem={(item) => {
                    return (
                      <Text key={item.index} style={table.text}>{item.item}</Text>
                    )
                  }}
                />
              </View>
            )}
            <View style={{flexDirection: "column"}}>
              <FlatList
                data={dataLoan.installments && dataLoan.installments}
                renderItem={(item) => {
                  return (
                    <View key={item.index} style={[loanActiveStyle.installments, {backgroundColor: item.index%2 === 0 ? '#EBE7FF' : '#F1EEFF'}]}>
                      <Text style={[table.column, {flex: 0.45}]}>{item.item.dueDate}</Text>
                      <Text style={[table.column, {flex: 0.5}]}>{item.item.totalInstallment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Text>
                      <Text style={[table.label, {
                        flex: 0.3, 
                        fontSize: 12, 
                        textAlign: 'center', 
                        paddingHorizontal: 1, 
                        backgroundColor: item.item.status === 'COMPLETED' ? '#82BD6E' : '#F2A12D'
                      }]}>{item.item.status}</Text>
                  </View>
                  )
                }}
              />
            </View>
          </View>
      </>
    </>
  );
};

export default LoanActive;
