import React from 'react';
import { StyleSheet, Text, View, Button, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { HeaderBackButton } from '@react-navigation/elements';

/* Screens in stack */
import Transaksi from '@screens/Transaksi';
import TransaksiDetail from '@screens/TransaksiDetail';

/* Style Import */
import { ScreenOptions } from '@style/Themes';
import { useSelector } from 'react-redux';
import { normalize } from '@style/helper';
import NewTransaksi from '@screens/NewTransaksi';
import TransactionList from '@screens/TransactionList';
import AccountDetails from '@screens/AccountDetails';
import { Font } from '@style/Fonts';

const Stack = createStackNavigator();

const TransaksiStack = () => {
  const employerId = useSelector((state) => state.Authentication.employerId);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Transaksi"
        component={TransactionList}
        options={{
          ...ScreenOptions,
          headerLeft: () => (
            <Text
              style={{
                color: '#FFF',
                fontSize: normalize(17),
                marginLeft: 30,
                fontFamily: 'Nunito-Bold',
              }}
            >
              {employerId == 24870 ? 'Garuda Daya Pratama Sejahtera' : 'GajiGesa'}
            </Text>
          ),
          headerStyle: { ...ScreenOptions.headerStyle, elevation: 0 },
        }}
      />
      <Stack.Screen
        name="AccountDetails"
        component={AccountDetails}
        options={{
          headerTitle: Platform.OS == 'ios' ? null : 'Kantong Dana',
          headerBackTitle: 'Kantong Dana',
          headerTintColor: 'white',
          headerTitleStyle: {
            fontFamily: Font.Nunito_Bold,
          },
          headerStyle: {
            backgroundColor: '#3863F0',
            shadowRadius: 0,
            shadowOffset: {
              height: 0,
            },
            borderBottomColor: '#3863F0',
          },
        }}
      />
      <Stack.Screen
        name="Transaksi Detail"
        component={TransaksiDetail}
        options={{
          headerTitle: Platform.OS == 'ios' ? null : 'DETAIL TRANSAKSI',
          headerBackTitle: 'DETAIL TRANSAKSI',
          headerTintColor: 'white',
          headerStyle: {
            backgroundColor: '#3863F0',
            shadowRadius: 0,
            shadowOffset: {
              height: 0,
            },
            borderBottomColor: '#3863F0',
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default TransaksiStack;
