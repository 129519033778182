import { StyleSheet, Dimensions } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";

export const styles = StyleSheet.create({
  upperLabel: {
    fontFamily: Font.Nunito_Regular,
    fontSize: 8,
    lineHeight: 10,
    textAlign: 'center',
    color: '#979797',
    letterSpacing: .25,
    padding: 4,
  },
  lowerLabel: {
    fontFamily: Font.Nunito_Regular,
    fontSize: 8,
    lineHeight: 10,
    textAlign: 'center',
    color: '#979797',
    letterSpacing: .25,
    padding: 4,
  },
  divider: {
    height: 4,
    width: "100%",
    backgroundColor: "#F0F0F0",
    color: "#F0F0F0",
    marginTop: 5,
  },
  navigator: {
    zIndex:5,
    backgroundColor: colors.white,
    marginHorizontal: 20,
  },
  modalText: {
    paddingHorizontal: 10,
    fontFamily: Font.Nunito_Regular,
    fontSize: 12,
    lineHeight: 20,
    color: "#454545",
  },
  modalHeading: { 
    textTransform: 'capitalize',
    marginVertical: 5,
    paddingHorizontal: 10,
    fontFamily: Font.Nunito_Bold,
    fontSize: 14,
    lineHeight: 20,
    color: "#454545",
    letterSpacing: 0.2
  },
  modalIcon: {
    marginHorizontal: 10,
    position: 'absolute',
    right: -10,
    top: -5
  },
})