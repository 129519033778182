import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import { actionSetSalaryWorkDays, actionSetWithdrawAmount } from '@redux/action/WIthdrawal';
import AmplitudeHelper from '@services/amplitude';
import {
  applyForWithdrawal,
  applyWithdrawalForDynamicAccount,
  getDashboardDetailApi,
  getReasonsApi,
  preCheckWithdrawal,
} from '@services/DashboardService';
import {
  convertDotsToNum,
  getAvailableBalance,
  getinfoModalSheetContent,
  getListOfDynamicAccounts,
  getSliderInterval,
  numberWithDots,
  sliderMaximumValue,
  sliderMinValue,
} from '@utils/UtilFunctions';
import TarikGaji from '../TarikGaji';
import styles from './style';
import { setCanContinue } from '@redux/action/Common';
import LinearGradient from 'react-native-web-linear-gradient';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { useLazyQuery } from '@apollo/client';
import {
  setDynamicAccounts,
  setSdAmount,
  setWorkAllowanceMaxAmount,
  setWorkAllowanceAmount,
  setWorkAllowanceEditable,
  setUangKasAllowanceAmount,
  setUangKasMaxAmount,
  setUangKasEditable,
} from '@redux/action/DynamicAccounts';
import { DAILY_BALANCE_PAYMENTCYCLE_HOMEPAGE } from '@gqlQuery/QueryTransactionList';
import { WITHDRAWAL_REQUEST } from '@gqlQuery/dynamicAccountWithdrawal';
import SdAccountCard from '@molecules/SdAccountCard';
import DAWithdrawalCard from '@atoms/DAWithdrawalCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DYNAMIC_ACCOUNT_NAMES, INFO_SHEET_TYPES } from '@utils/Constants';

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

function getUangKantorComponent(
  dispatch,
  workAllowance: any,
  handleSubmit: () => Promise<void>,
  isModal: any,
  loading: boolean,
  currentHomePageSelectedAccount: any,
  props: any,
  dynamicAccountDetails: { length: any; sdAccount: any; workAllowance: any; uangKas: any },
) {
  const setworkAmount = (amount) => {
    if (amount?.length > 0 && isNaN(amount[0])) {
      dispatch(setWorkAllowanceAmount([]));
      return;
    }
    dispatch(setWorkAllowanceAmount(amount));
  };
  const setIsEditable = () => {
    dispatch(setWorkAllowanceEditable(!workAllowance.isEditable));
  };

  const reqTransaction = () => {
    dispatch(setWorkAllowanceEditable(false));
    handleSubmit();
  };

  return (
    <DAWithdrawalCard
      isModal={isModal}
      loading={loading}
      onPress={reqTransaction}
      amount={workAllowance.value ?? 0}
      onPressEdit={setIsEditable}
      isEditable={workAllowance.isEditable}
      selectedAccount={currentHomePageSelectedAccount}
      onPressAccountSelector={props.onPressAccountSelector}
      maximumValue={sliderMaximumValue(
        dynamicAccountDetails.workAllowance?.availableBalance ?? 0,
        workAllowance.minAmount,
      )}
      minimumValue={sliderMinValue(dynamicAccountDetails.workAllowance?.availableBalance ?? 0, workAllowance.minAmount)}
      onValueChange={setworkAmount}
      //onSlidingComplete={}
      step={getSliderInterval(dynamicAccountDetails.workAllowance?.availableBalance ?? 0)}
    />
  );
}

function getUangKasComponent(
  dispatch,
  uangKas: any,
  handleSubmit: () => Promise<void>,
  isModal: any,
  loading: boolean,
  currentHomePageSelectedAccount: any,
  props: any,
  dynamicAccountDetails: { length: any; sdAccount: any; workAllowance: any; uangKas: any },
) {
  const setUangKasAmount = (amount) => {
    if (amount?.length > 0 && isNaN(amount[0])) {
      dispatch(setUangKasAllowanceAmount([]));
      return;
    }
    dispatch(setUangKasAllowanceAmount(amount));
  };
  const setIsUangKasEditable = () => {
    dispatch(setUangKasEditable(!uangKas.isEditable));
  };

  const reqUangKasTransaction = () => {
    dispatch(setUangKasEditable(false));
    handleSubmit();
  };

  function getMinimumValue(): number {
    return sliderMinValue(dynamicAccountDetails.uangKas?.availableBalance ?? 0, uangKas.minAmount);
  }

  function getMaximumValue(): number {
    return sliderMaximumValue(dynamicAccountDetails.uangKas?.availableBalance ?? 0, uangKas.minAmount);
  }

  return (
    <DAWithdrawalCard
      isModal={isModal}
      loading={loading}
      onPress={reqUangKasTransaction}
      amount={uangKas.value ?? 0}
      onPressEdit={setIsUangKasEditable}
      isEditable={uangKas.isEditable}
      selectedAccount={currentHomePageSelectedAccount}
      onPressAccountSelector={props.onPressAccountSelector}
      maximumValue={getMaximumValue()}
      minimumValue={getMinimumValue()}
      onValueChange={setUangKasAmount}
      //onSlidingComplete={}
      step={getSliderInterval(dynamicAccountDetails.uangKas?.availableBalance ?? 0)}
    />
  );
}

const TarikGajiContainer = (props) => {
  const isFocused = useIsFocused();
  const { isModal, source } = props;
  const {
    workedDays,
    workingDays,
    maxSalary,
    withdrawAmount,
    salaryWorkDays,
    todayBalance,
    minBalance,
    firstWorkingDate,
    endWorkingDate,
    startWithdrawalDate,
    numberOfLockPeriod,
    withdrawalLockType,
    payDateWithdrawal,
    options,
    todayBalanceFloor,
    todaySalaryFloor,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    usedBalance,
    lockPeriod,
  } = useSelector((state: any) => state.Withdrawal);
  const {
    dynamicAccounts,
    currentHomePageSelectedAccount,
    [DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE]: workAllowance,
    [DYNAMIC_ACCOUNT_NAMES.UANG_KAS]: uangKas,
  } = useSelector((state: any) => state.DynamicAccounts);

  const daData = useSelector((state: any) => state.DynamicAccounts);

  const { employeeId } = useSelector((state: any) => state.Authentication);
  const { eventSource } = useSelector((state: any) => state.Common);
  const { redeemCode, isValidCode } = useSelector((state: any) => state.Referral);
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [error, setError] = useState(false);
  const [layoutHeight, setLayoutHeight] = useState(null);

  const [getUsedBalance] = useLazyQuery(DAILY_BALANCE_PAYMENTCYCLE_HOMEPAGE, {
    variables: {
      employeeId,
    },
    onCompleted: async (data) => {
      try {
        error && setError(false);
        // Updating Dynamic accounts
        const dynamicAccounts = [];
        let sdAccount = null;
        let workAllowanceAccount = null;
        let uangKas = null;
        data.employee_account.forEach((e) => {
          let obj = {
            ...e,
          };
          delete obj.account_type;
          obj.accountTypeName = e.account_type.accountTypeName;

          if (e.account_type.accountTypeName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) workAllowanceAccount = obj;
          if (e.account_type.accountTypeName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) sdAccount = obj;
          if (e.account_type.accountTypeName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS) uangKas = obj;
          dynamicAccounts.push(obj);
        });

        if (sdAccount && sdAccount?.availableBalance > 0) AsyncStorage.removeItem('@sdBalanceCleared');

        AsyncStorage.setItem('@dynamicAccounts', JSON.stringify(dynamicAccounts));
        batch(() => {
          if (sdAccount) dispatch(setSdAmount(sdAccount.availableBalance));
          if (workAllowanceAccount) {
            const initialSelectedAmount = Math.round(workAllowanceAccount.availableBalance * 0.75); // 75% of available balance as initial amount
            dispatch(setWorkAllowanceMaxAmount(workAllowanceAccount.availableBalance));
            dispatch(setWorkAllowanceAmount([initialSelectedAmount]));
          }
          if (uangKas) {
            const initialSelectedUangKasAmount = Math.round(uangKas.availableBalance * 1); // 100% of available balance as initial amount
            dispatch(setUangKasMaxAmount(uangKas.availableBalance));
            dispatch(setUangKasAllowanceAmount([initialSelectedUangKasAmount]));
          }
          dispatch(setDynamicAccounts(dynamicAccounts));
        });

        setLoading(false);
        setMainLoading(false);
      } catch (error) {
        setLoading(false);
        setMainLoading(false);
        setError(true);
      }
    },
    onError: (error) => {
      setError(true);
    },
    fetchPolicy: 'no-cache',
  });

  const [daWithdrawal] = useLazyQuery(WITHDRAWAL_REQUEST, {
    variables: { employeeId },
    onCompleted: async (data) => {
      await dispatch(applyWithdrawalForDynamicAccount(data));
    },
    onError: (error) => {
      setError(true);
    },
    fetchPolicy: 'no-cache',
  });

  const updateEditable = (val) => {
    if (withdrawAmount == 0 || withdrawAmount == 'NaN') {
      dispatch(actionSetWithdrawAmount(numberWithDots(minBalance)));
    } else if (convertDotsToNum(withdrawAmount) < minBalance) {
      dispatch(actionSetWithdrawAmount(numberWithDots(minBalance)));
    } else {
      updateAmount(withdrawAmount);
    }
    setIsEditable(val);
  };

  const newAvailableBalance = useMemo(() => {
    return getAvailableBalance(
      salaryWithCappedAmount,
      todayBalanceFloor,
      workingDays,
      workedDays,
      maxWithdrawalAmount,
      usedBalance,
    );
  }, [
    salaryWithCappedAmount,
    todayBalanceFloor,
    workingDays,
    workedDays,
    maxWithdrawalAmount,
    usedBalance,
    currentHomePageSelectedAccount,
  ]);

  const updateAmount = useCallback(
    (x) => {
      if (isEditable) {
        setIsEditable(false);
      }
      let amount = convertDotsToNum(x.toString());
      if (amount < minBalance) {
        amount = Math.floor(minBalance);
      } else if (amount > newAvailableBalance) {
        amount = Math.floor(newAvailableBalance);
      } else {
        amount = Math.floor(amount);
      }

      dispatch(actionSetWithdrawAmount(numberWithDots(amount)));
      let daily_salary = salaryWithCappedAmount / workingDays;
      let salary_work_days = Math.floor(convertDotsToNum(withdrawAmount) / daily_salary);
      if (salary_work_days > workingDays) {
        dispatch(actionSetSalaryWorkDays(31));
      } else {
        if (salary_work_days == NaN) {
          dispatch(actionSetSalaryWorkDays(0));
        }
        dispatch(actionSetSalaryWorkDays(salary_work_days));
      }
      return;
    },
    [workingDays, withdrawAmount, todayBalance, salaryWorkDays],
  );

  const handleTarik = async (isFromDynamicAccount) => {
    if (isFromDynamicAccount) {
      verifyDynamicAccountTransaction();
      return;
    }
    if (redeemCode.length > 0 && isValidCode) {
      referralPrecheck();
    } else {
      verifyTransaction();
    }
  };

  const referralPrecheck = useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(preCheckWithdrawal());
      verifyTransaction();
    } catch (error) {
      verifyTransaction();
    }
  }, [redeemCode]);

  const checkIfSdEnabled = useCallback(() => {
    if (lockPeriod) {
      const findSdAccount = dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT);
      if (findSdAccount !== -1 && findSdAccount?.availableBalance > 0) {
        return true;
      }
    }
    return false;
  }, [lockPeriod, dynamicAccounts]);

  const verifyDynamicAccountTransaction = async () => {
    try {


      let daAmount;
      if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) {
        daAmount = daData[currentHomePageSelectedAccount.constantAccountName]?.value;
      } else {
        daAmount = daData[currentHomePageSelectedAccount.constantAccountName]?.value[0]
      }

      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.requested_amount]: daAmount,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.transaction_category_id]: 'wd-01',
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });
      setLoading(true);
      daWithdrawal();
      setLoading(false);
    } catch (error) {

      setLoading(false);
    }
  };

  const verifyTransaction = async () => {
    try {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.requested_amount]: withdrawAmount,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.transaction_category_id]: 'wd-01',
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawal.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });

      // Checking if SD account is active and has some balance
      if (checkIfSdEnabled()) {
        props.setInfoModalSheet({
          visible: true,
          data: getinfoModalSheetContent(INFO_SHEET_TYPES.SD_ACCOUNT_OPEN_INFO.name),
        });
        return;
      }
      setLoading(true);
      await dispatch(applyForWithdrawal());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getReasonsList = async () => {
    try {
      await dispatch(getReasonsApi());
    } catch (error) {
      throw error;
    }
  };

  const getDashboardDetailNew = async () => {
    try {
      const _data = await dispatch(getDashboardDetailApi());
      setLoading(false);
      setMainLoading(false);
      getUsedBalance();
    } catch (error) {
      throw error;
    }
  };

  const refreshPage = async () => {
    try {
      setError(false);
      setLoading(true);
      setMainLoading(true);
      dispatch(setCanContinue(false));
      await getReasonsList();
      await getDashboardDetailNew();
    } catch (error) {
      setLoading(false);
      setMainLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (isFocused && !isModal) {
      refreshPage();
    }
  }, [isFocused, isModal]);

  useEffect(() => {
    props.showTour && props.start();
  }, [props.showTour]);

  const onLayoutHandler = (e) => {
    setLayoutHeight(e.nativeEvent.layout.height);
  };

  const handleSubmit = () => handleTarik(true);

  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);

  const returnWithdrawalCard = () => {
    switch (currentHomePageSelectedAccount?.constantAccountName) {
      case DYNAMIC_ACCOUNT_NAMES.SDACCOUNT:
        // SD Withdrawal Card

        const onPressSdCard = () => {
          handleSubmit();
        };

        return (
          <SdAccountCard
            isModal={isModal}
            loading={loading}
            onPress={onPressSdCard}
            amount={dynamicAccountDetails?.sdAccount?.availableBalance ?? 0}
            selectedAccount={currentHomePageSelectedAccount}
            onPressAccountSelector={props.onPressAccountSelector}
          />
        );
      case DYNAMIC_ACCOUNT_NAMES.UANG_KAS:
        return getUangKasComponent(
          dispatch,
          uangKas,
          handleSubmit,
          isModal,
          loading,
          currentHomePageSelectedAccount,
          props,
          dynamicAccountDetails,
        );
      case DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE:
        // Work Allowance Withdrawal Card (Uang Kantor)
        return getUangKantorComponent(
          dispatch,
          workAllowance,
          handleSubmit,
          isModal,
          loading,
          currentHomePageSelectedAccount,
          props,
          dynamicAccountDetails,
        );

      default:
        //EWA Withdrawal Card
        return (
          <TarikGaji
            withdrawAmount={withdrawAmount}
            updateEditable={updateEditable}
            todayBalance={todayBalance}
            minBalance={minBalance}
            updateAmount={updateAmount}
            maxSalary={maxSalary}
            handleTarik={handleTarik}
            firstWorkingDate={firstWorkingDate}
            endWorkingDate={endWorkingDate}
            startWithdrawalDate={startWithdrawalDate}
            numberOfLockPeriod={numberOfLockPeriod}
            withdrawalLockType={withdrawalLockType}
            payDateWithdrawal={payDateWithdrawal}
            options={options}
            isModal={isModal}
            loading={loading}
            source={source}
            eventSource={eventSource}
            todaySalaryFloor={todaySalaryFloor}
            todayBalanceFloor={todayBalanceFloor}
            salaryWithCappedAmount={salaryWithCappedAmount}
            maxWithdrawalAmount={maxWithdrawalAmount}
            workedDays={workedDays}
            workingDays={workingDays}
            usedBalance={usedBalance}
            onPressAccountSelector={props.onPressAccountSelector}
            selectedAccount={currentHomePageSelectedAccount}
            dynamicAccounts={dynamicAccounts}
            dataAccounts={props.dataAccounts}
            dynamicAccountDetails={dynamicAccountDetails}
          />
        );
    }
  };

  return (
    <>
      {mainLoading || props.loadingReferral ? (
        <ShimmerPlaceholder style={[styles.slimmer, layoutHeight && { height: layoutHeight }]} visible={false} />
      ) : (
        <View onLayout={onLayoutHandler} style={[isModal ? styles.modalContainer : styles.container]}>
          {returnWithdrawalCard()}
        </View>
      )}
    </>
  );
};

// export default withHoc(TarikGajiContainer, {
//   isArrowVisible: true,
//   svgMaskProps: { isFullWidth: false, widthStartAdj: 0, widthEndAdj: 10 },
//   verticalOffset: 35,
// });

export default TarikGajiContainer;
