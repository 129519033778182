import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  textItalic: {
    width: '80%',
    color: colors.red,
    fontStyle: 'italic',
    textAlignVertical: 'center',
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    minHeight: '80%',
    paddingHorizontal: normalize(5),
  },
  info: {
    paddingHorizontal: 15,
  },
  infoDetailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 2,
  },
  checkBoxContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: normalize(15),
    marginVertical: normalizeHeight(15),
  },
  checkBoxStyle: { width: normalize(22), height: normalize(22) },
  checkBoxTextStyle: { marginStart: 10 },
});
