import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';

/* REDUX */
import { useSelector } from 'react-redux';

/* Styles */
import { subTitleText } from '@style/Layouts';
import { VoucherButton } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
import { Font } from '@style/Fonts';

const Voucher = ({ dataList, onPress, show }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);

  if (!show) {
    return <></>;
  }

  const setSource = (item) => {
    switch (item.item) {
      case dataList[item.item][0]?.imageUrl:
        return dataList[item][0].imageUrl;
      case 'Voucher Alfamart':
        return 'https://storage.googleapis.com/gajigesa-public/icons/alfamart-logo.png';
      case 'Voucher Grab Transport':
        return 'https://storage.googleapis.com/gajigesa-public/icons/grab-logo.png';
      case 'Voucher Grab Food':
        return 'https://storage.googleapis.com/gajigesa-public/icons/grabfood-logo.png';
      case 'Voucher Indomaret':
        return 'https://storage.googleapis.com/gajigesa-public/icons/indomaret-logo.png';
      case 'Voucher Halodoc':
        return 'https://storage.googleapis.com/gajigesa-public/icons/halodoc-logo.png';
      case 'Voucher Sayur Box':
        return 'https://storage.googleapis.com/gajigesa-public/icons/sayurbox-logo.png';
      default:
        return 'local';
    }

    /* 
				(dataList[item.item] && dataList[item.item][0].imageUrl) ? 
				dataList[item.item][0].imageUrl : 
				item.item == 'Voucher Alfamart' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/alfamart-logo.png' : 
				item.item == 'Voucher Grab Transport' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/grab-logo.png' : 
				item.item == 'Voucher Grab Food' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/grabfood-logo.png' : 
				item.item == 'Voucher Indomaret' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/indomaret-logo.png' : 
				item.item == 'Voucher Halodoc' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/halodoc-logo.png' : 
				item.item == 'Voucher Sayur Box' ? 
				'https://storage.googleapis.com/gajigesa-public/icons/sayurbox-logo.png' : 
				'local'
			*/
  };

  return (
    <>
      <Text style={[subTitleText, styles.title]}>Pilih Voucher:</Text>
      <View style={{ height: '50%' }}>
        <FlatList
          data={Object.keys(dataList)}
          renderItem={(item) => {
            let imageUrl = setSource(item);
            return (
              <View style={styles.container}>
                <VoucherButton
                  accessibilityLabel="productVoucherButton"
                  source={imageUrl}
                  columns={3}
                  onPress={() => onPress(dataList[item.item])}
                  selected={JSON.stringify(dataList[item.item][0]) == JSON.stringify(selectedProduct)}
                />
                <Text style={styles.text}>{item.item}</Text>
              </View>
            );
          }}
          numColumns={3}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(16),
    fontFamily: Font.Nunito_Bold,
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
  container: { flexDirection: 'column', marginTop: normalizeHeight(10) },
  text: {
    fontSize: normalize(10),
    color: '#454545',
    alignSelf: 'center',
    textAlign: 'center',
    maxWidth: normalizeHeight(62),
  },
});

export default Voucher;
