import React from 'react';
import moment from 'moment';
import { ActivityIndicator, FlatList, View, TouchableOpacity, Pressable } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import MIcon from '@expo/vector-icons/MaterialIcons';
import MCIcon from '@expo/vector-icons/MaterialCommunityIcons';
import Down from '@expo/vector-icons/Entypo';
import { listDateFormer, numberWithDots } from '@utils/UtilFunctions';
import Info from '@expo/vector-icons/AntDesign';
import TransactionFilter from '@components/TransactionFilter';
import TransactionCategory from '@components/TransactionCategory';
import { colors } from '@style/colors';
import DateSelector from '@components/DateSelector';
import MyText from '@atoms/MyText';
import ToolTip from '@atoms/CustomTooltip';
import { TOOLTIP_CONTENT } from '@utils/Constants';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import Divider from '@atoms/Divider';
import styles from './styles';

export const LIMIT = 20;
export const MODAL_TYPE = {
  FILTER: 'FILTER',
  CATEGORY: 'CATEGORY',
};

const isFilterApplied = (filter, filterCheck) => {
  if (filterCheck.cycles && filter.cycles.length === 1) {
    return false;
  }
  return filterCheck.count > 0;
};

export function shouldGetFilterAppliedDate(filter, filterCheck, currentCycle) {
  return isFilterApplied(filter, filterCheck)
    ? '(berdasarkan filter)'
    : `${listDateFormer(moment(currentCycle?.startDate))} - ${listDateFormer(moment(currentCycle?.endDate))} ${moment(
        currentCycle?.endDate,
      ).year()}`;
}

function getBalanceAmountValue(filter, filterCheck, balance, newAvailableBalance) {
  return isFilterApplied(filter, filterCheck) ? numberWithDots(balance) : numberWithDots(newAvailableBalance);
}

export function shouldShowFilterModal(params) {
  const {
    modal,
    isCheckedReasons,
    reasons,
    onSelectFilterCycle,
    getCycleIds,
    onReset,
    filter,
    selectedRadio,
    currentCycle,
    setSelectedRadio,
    setdateRange,
    onCancel,
    collapsed,
    setCollapsed,
    closeModal,
    setModal,
    paymentCycles,
    openDate,
  } = params;
  return modal.type === MODAL_TYPE.FILTER ? (
    <TransactionFilter
      isAllCategorySelected={
        isCheckedReasons.expenseCount + isCheckedReasons.incomeCount === reasons.expense.length + reasons.income.length
      }
      reasonCount={isCheckedReasons.expenseCount + isCheckedReasons.incomeCount}
      onSelect={onSelectFilterCycle}
      cycles={getCycleIds}
      onReset={onReset}
      startDate={
        filter?.dateRange?.startDate && selectedRadio === 1
          ? `${listDateFormer(moment(filter.dateRange?.startDate))} ${moment(filter.dateRange?.startDate).year()}`
          : `${listDateFormer(moment(currentCycle?.startDate))} ${moment(currentCycle?.startDate).year()}`
      }
      endDate={
        filter?.dateRange?.endDate && selectedRadio === 1
          ? `${listDateFormer(moment(filter.dateRange?.endDate))} ${moment(filter.dateRange?.endDate).year()}`
          : `${listDateFormer(moment(currentCycle?.endDate))} ${moment(currentCycle?.endDate).year()}`
      }
      selectedRadio={selectedRadio}
      setSelectedRadio={(radio) => {
        setSelectedRadio(radio);
        setdateRange(radio);
      }}
      onCancel={onCancel}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      closeModal={closeModal}
      modal={modal}
      setModal={setModal}
      paymentCycles={paymentCycles}
      openDate={openDate}
    />
  ) : (
    <TransactionCategory
      isCheckedReasons={isCheckedReasons}
      income={reasons.income}
      expense={reasons.expense}
      onBackPress={() => setModal({ visible: true, type: MODAL_TYPE.FILTER })}
    />
  );
}

export function shouldShowFlatListOrLoader(params) {
  const {
    loadingList,
    listEmpty,
    refresh,
    onRefresh,
    setOnEndReachedCalledDuringMomentum,
    transactionList,
    renderItem,
    onEndReached,
    footer,
  } = params;
  return loadingList ? (
    <ActivityIndicator color={colors.blue} size="large" style={styles.twActivityIndicatorStyle} />
  ) : (
    <FlatList
      ListEmptyComponent={listEmpty}
      refreshControl={<RefreshControl colors={[colors.blue]} refreshing={refresh} onRefresh={onRefresh} />}
      onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false)}
      data={transactionList}
      renderItem={renderItem}
      onEndReached={onEndReached}
      ListFooterComponent={footer}
      onEndReachedThreshold={0.6}
      keyExtractor={(item, index) => index.toString()}
      style={styles.twMarginTop15}
      contentContainerStyle={styles.twPaddingBottom}
    />
  );
}

export function shouldShowDateSelectorForCurrentCycle(
  currentCycle,
  filter,
  filterCheck,
  cycleIndex,
  paymentCycles,
  dateLeft,
  dateRight,
) {
  return (
    currentCycle && (
      <DateSelector
        date={
          isFilterApplied(filter, filterCheck)
            ? '(berdasarkan filter)'
            : `${listDateFormer(moment(currentCycle?.startDate))} - ${listDateFormer(
                moment(currentCycle?.endDate),
              )} ${moment(currentCycle?.endDate).year()}`
        }
        rightDisabled={cycleIndex == 0 || isFilterApplied(filter, filterCheck)}
        leftDisabled={cycleIndex == paymentCycles.length - 1 || isFilterApplied(filter, filterCheck)}
        dateLeft={dateLeft}
        dateRight={dateRight}
        containerStyle={styles.twDateContainerStyles}
      />
    )
  );
}

export function onMainParentReload(paymentCycles, getDailyBalanceByCycle, currentCycle, getData) {
  if (paymentCycles.length > 0) {
    getDailyBalanceByCycle({
      variables: {
        paymentCycleId: currentCycle.id,
      },
    });
    getData(false, 1, false, true, false);
  } else {
    getDailyBalanceByCycle({
      variables: {
        paymentCycleId: currentCycle.id,
      },
    });
    getData(true, 1, false, true, true);
  }
}

export const balanceComponent = (
  toolTip,
  closeTooltip,
  openTooltip,
  filter,
  filterCheck,
  balance,
  newAvailableBalance,
  accountName = 'Akun Gaji',
) => (
  <>
    <View style={styles.twBalanceComponent}>
      <MCIcon name="wallet" size={14.25} color="black" style={styles.twSelfCenter} />
      <MyText isRegular={false} customStyle={styles.twUangKamuTxtStyle}>
        {accountName}{' '}
      </MyText>
      <ToolTip
        description={TOOLTIP_CONTENT['balance']}
        isVisible={toolTip.isVisible && toolTip.type == 'balance'}
        placement={'UP'}
        onClose={closeTooltip}
      >
        <View style={styles.twInfoIconStyle}>
          <Info onPress={onPressCallBack(openTooltip)} name="question" color={'white'} size={15} />
        </View>
      </ToolTip>
    </View>
    <View style={styles.twRPContainer}>
      <View style={styles.twRPParentStyles}>
        <MyText customStyle={styles.twBalanceTextStyle} isRegular={false}>
          Rp{getBalanceAmountValue(filter, filterCheck, balance, newAvailableBalance)}
        </MyText>
      </View>
    </View>
  </>
);

function onPressCallBack(openTooltip) {
  return () => {
    openTooltip('balance');
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.tooltipClicked.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.tooltipClicked.attributes.source]: 'balance_transaction_page',
    });
  };
}

export function shouldCheckLoading(loadingMain, loadingCycle) {
  return loadingMain || loadingCycle;
}

export function shouldCheckErrorCycle(error, errorCycle) {
  return error || errorCycle;
}

export function proratedDetailComponent(newAccruedSalary, accuredSalary, isDynamicAccountSelected = false) {
  const returnComponentText = () => {
    if (isDynamicAccountSelected) return 'Sisa dana dari periode sebelumnya';
    return isNaN(Math.round(accuredSalary))
      ? 'Hasil filter tidak termasuk Gaji Pro Rata'
      : 'Gaji prorata berdasarkan Limit Penarikan Gaji';
  };
  return (
    <View style={styles.twProratedDetailParentStyles}>
      {SVG_ICONS.emptyWallet}
      <View style={styles.twMarginLeft10}>
        <MyText isRegular={false} h2 customStyle={styles.twProratedTextStyle}>
          {isNaN(newAccruedSalary) ? accuredSalary : `Rp${numberWithDots(Math.round(newAccruedSalary))}`}
        </MyText>
        <MyText h2>{returnComponentText()}</MyText>
      </View>
    </View>
  );
}

export function filterTransactionListComponent(onPressFilter, selectedAccount, disabled) {
  return (
    <View style={[styles.twFilterRefreshContainerStyle, { alignSelf: 'stretch' }]}>
      <TouchableOpacity disabled={disabled} onPress={onPressFilter} style={styles.twFilterPressable}>
        <MyText>Berdasarkan {selectedAccount?.accountName}</MyText>
        <View style={[styles.twselectedIndicator, { backgroundColor: styles.selectedIndicatortColor }]} />
        <Down name="chevron-down" size={15} />
      </TouchableOpacity>
    </View>
  );
}
