import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';

const styles = StyleSheet.create({
  rewardContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: normalizeHeight(2),
    paddingBottom: normalizeHeight(16),
  },
  gamifyIcons: { flexDirection: 'row', alignItems: 'center' },
  textStyle: { fontSize: normalize(14), marginBottom: normalizeHeight(8) },
  rewardStyle: { fontSize: normalize(30), marginLeft: normalize(10) },
});

export default styles;
