import { StyleSheet, Dimensions } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize, normalizeHeight } from "@style/helper";

const { width: screenWidth } = Dimensions.get("window");
const styles = StyleSheet.create({
  background: {
    backgroundColor: "white",
    padding: 20,
    alignSelf: "center",
    // display: "flex",
  },
  pagination: {
    // flex: 1,
    paddingVertical: 0,
    paddingBottom: 20,
    // marginTop: 0,
    width: 40,
    alignSelf: "center",
  },
  lanjutContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 5,
    marginBottom: 20,
  },
  buttonText: {
    color: colors.orange,
    textDecorationLine: "underline",
    fontSize: normalize(14),
    lineHeight: normalizeHeight(19),
    fontFamily: Font.Nunito_Regular,
  },
  leftButton: {
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: colors.orange,
  },
  rightButton: {
    alignSelf: "center",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    color: colors.orange,
  },
});

export default styles;
