import React from 'react'
import { View } from 'react-native'
import { colors } from '@style/colors'
const CategoryItem =({icon})=>{
    return(
        <View
        style={{height:35, width:35, borderRadius:35/2, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:colors.blue}}
        >
            {icon}
        </View>
    )
}

export default CategoryItem