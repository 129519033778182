import axios from 'axios';
import url from '@gaji-gesa/gg-ui-shared/src/config/config';
import mainAPI from './api';

const TransactionService = {
  //   getTranscations: async function() {
  //       return await mainAPI('transaction-list', 'get')
  // //    return axios.get(url.API_URL+'/api/transaction-status',{ headers:  {"Authorization" : `Token ${token}`}});
  //   },
  getTransactions: async (startDate, endDate, status = [], limit = 6, page = 1) => {
    return await mainAPI(
      `transactions?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&status=${
        status.length == 0 ? '' : status.join(',')
      }`,
      'get',
    );
  },
  getBankDetail: async function (data) {
    return await mainAPI('transaction-info', 'post', data);
  },
  // verifyWithdrawal: async function(data){
  //   return await mainAPI('withdrawal-request', 'post', data)
  // },
  verifyTransaction: async function (type, data) {
    return await mainAPI(`transaction/request/${type}`, 'post', data);
  },
  // confirmation: async function(data) {
  //    return await mainAPI('confirmation', 'post', data)
  // },
  confirmation: async function (data) {
    return await mainAPI('transaction/confirmation', 'post', data);
  },
  getReasons: async function () {
    return await mainAPI('transactions/reasons', 'get');
  },
  getTransactionMonthly: async () => {
    return await mainAPI('transactions/summarizes/yearly', 'get');
  },
  getTransactionMonthDetail: async function (data) {
    return await mainAPI('transaction-month', 'post', data);
  },
};

export default TransactionService;
