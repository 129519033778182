import React from 'react';
import { Alert } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import AmplitudeHelper from '@services/amplitude';

/* Styles import */
import { LogoutButton } from '@components/Button';
import { ScreenOptions } from '@style/Themes';

/* Components in stack */
import Loan from '@screens/Loan';
import SettlementLoan from '@screens/SettlementLoan';
import { useDispatch } from 'react-redux';
import { autoLogout } from '@redux/action/Authentication';
// import RNInsider from 'react-native-insider';
import { clearReferral } from '@redux/action/Referral';
import logoutCallBack from '@utils/AuthenticationUtils';
import { logoutFromMoengage } from '@utils/engagementUtils';

/* Components import */

const Stack = createStackNavigator();

const LoanStack = () => {
  const dispatch = useDispatch();

  const pressHandler = () => {
    AmplitudeHelper.logEvent('logoutEvent');
    Alert.alert('Apakah Anda Yakin?', 'Anda mau keluar', [
      {
        text: 'OK',
        // onPress: () => signOut()
        onPress: () => {
          logoutFromMoengage();
          dispatch(autoLogout(logoutCallBack));
          dispatch(clearReferral());
        },
      },
      {
        text: 'Batalkan',
        style: 'cancel',
      },
    ]);
  };

  return (
    <Stack.Navigator headerMode="float">
      <Stack.Screen
        name="Loan"
        component={Loan}
        options={{ ...ScreenOptions, headerRight: () => <LogoutButton onPress={pressHandler} /> }}
      />
      {/* <Stack.Screen name="Settlement" component={Settlement} options={ScreenOptions}/> */}
      <Stack.Screen name="SettlementLoan" component={SettlementLoan} options={ScreenOptions} />
    </Stack.Navigator>
  );
};

export default LoanStack;
