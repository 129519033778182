import React, { useEffect, useMemo, useState } from 'react';
import { View, Pressable, Dimensions } from 'react-native';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';

/* Style Import */
import AmplitudeHelper from '@services/amplitude';
import TarikGajiContainer from '../TarikGajiContainer';
import MyText from '@atoms/MyText';
import styles from './style';
import { colors } from '@style/colors';
import Drop from '@expo/vector-icons/MaterialIcons';
import AntDesign from '@expo/vector-icons/AntDesign';
import { normalize } from '@style/helper';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithDots, getIndoMonthLong, getAccruedSalary, getAvailableBalance } from '@utils/UtilFunctions';
import { Modalize } from 'react-native-modalize';
import { hideEWAModal } from '@redux/action/Modals';
import Icon from '@expo/vector-icons/Ionicons';
import CloseHeader from '../../Modals/CloseHeader';
import mainAPI from '@services/api';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { store } from '@enhancedStore/store';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';

const { height, width } = Dimensions.get('window');

const EWAModal = React.forwardRef(({ isVisible }: any, ref: any) => {
  const dispatch = useDispatch();

  const [isCollapsed, setCollapsed] = useState(false);
  const { paymentCycles } = useSelector((state: any) => state.TransactionDetails);
  const { employeeId } = useSelector((state: any) => state.Authentication);
  const { eventSource } = useSelector((state: any) => state.Common);
  const {
    updatedDate,
    todayBalance,
    workedDays,
    salaryWithCappedAmount,
    todayBalanceFloor,
    usedBalance,
    amount,
    maxWithdrawalAmount,
    workingDays,
  } = useSelector((state: any) => state.Withdrawal);
  const { source } = useSelector((state: any) => state.Modals);
  const { redeemCode } = store.getState().Referral;
  const { currentHomePageSelectedAccount } = useSelector((state: any) => state.DynamicAccounts);
  const { appTourParams } = useSelector((state: any) => state.Common);
  const isSalaryAccountSelected =
    currentHomePageSelectedAccount?.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT;
  const [balance, setBalance] = useState({
    accuredSalary: 0,
    totalExpense: 0,
    totalIncome: 0,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.ewaModal.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.ewaModal.attributes.employee_id]: employeeId,
      [AMPLITUDE_CONSTANTS.Withdrawal.ewaModal.attributes.referral_code]: redeemCode || null,
    });
  }, []);

  const getCurrentTransactionSummary = async () => {
    if (!paymentCycles.length) return;
    setLoading(true);
    try {
      const filter = {
        cycles: [
          {
            id: paymentCycles[0].id,
            firstWorkingDate: paymentCycles[0].startDate,
            endWorkingDate: paymentCycles[0].endDate,
          },
        ],
      };

      const response = await mainAPI(`/employees/${employeeId}/transactions-summary`, 'post', filter);

      setBalance({
        accuredSalary: response.data?.data?.accruedSalary ?? 0,
        totalIncome: Math.round(response.data?.data?.totalIncome) ?? 0,
        totalExpense: Math.round(response.data?.data?.totalExpense) ?? 0,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  // useEffect(() => {
  //   getCurrentTransactionSummary();
  // }, [paymentCycles]);

  useEffect(() => {
    if (appTourParams.type == 'homepage' && appTourParams.step == 9) {
      ref.current.open();
    }
    if (appTourParams.type == 'homepage' && appTourParams.step == 8 && appTourParams.isPrevious == true) {
      ref.current.close();
    }
  }, [appTourParams.step]);

  const newAvailableBalance = useMemo(() => {
    return getAvailableBalance(
      salaryWithCappedAmount,
      todayBalanceFloor,
      workingDays,
      workedDays,
      maxWithdrawalAmount,
      usedBalance,
    );
  }, [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance]);

  const handleCloseHandler = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.CancelButtonClicked.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.CancelButtonClicked.attributes.source]: eventSource,
      [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.referral_code]: redeemCode || null,
    });
    ref.current.close();
    dispatch(hideEWAModal());
  };

  const Content = ({ start, showTour }) => {
    // useEffect(() => {

    //   if (showTour) {
    //     setCollapsed(false);
    //     setTimeout(() => start(), 250)
    //   }
    // }, [showTour])

    return (
      <>
        <View style={styles.contentContainer}>
          <Pressable style={styles.pressableContainer} onPress={handleCloseHandler}>
            <Icon name="close" onPress={handleCloseHandler} size={normalize(24)} style={styles.closeButtonStyling} />
          </Pressable>
          <TarikGajiContainer isModal={true} source={source} />
          {!loading ? (
            <View>
              <View
                style={{
                  backgroundColor: colors.lightGrey,
                  width,
                  height: 1,
                  left: 0,
                  top: 0,
                  position: 'absolute',
                }}
              />

              {/* <AppTourComponentWrapper
                order={9}
                text={APPTOURMETA[APP_TOUR_TYPE.homepage][9]}
                name={`${APP_TOUR_TYPE.homepage}9`}
                type={APP_TOUR_TYPE.homepage}
              > */}
              {isSalaryAccountSelected && (
                <Pressable
                  onPress={() => {
                    if (isCollapsed) {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.source]: eventSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.referral_code]:
                          redeemCode || null,
                      });
                    } else {
                      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationHide.name, {
                        [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.source]: eventSource,
                        [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.referral_code]:
                          redeemCode || null,
                      });
                    }
                    setCollapsed(!isCollapsed);
                  }}
                  android_ripple={{ color: colors.lightGrey }}
                  style={styles.container}
                >
                  <View style={styles.subContainer}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <AntDesign name="questioncircleo" size={normalize(18)} color={colors.grey} />
                      <MyText h3 isRegular={false} customStyle={{ marginStart: 10, color: colors.black }}>
                        Informasi Gaji
                      </MyText>
                    </View>
                  </View>
                  <View style={styles.subContainer}>
                    <Drop
                      name={isCollapsed ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
                      color={colors.blue}
                      size={30}
                    />
                  </View>
                </Pressable>
              )}
              {isSalaryAccountSelected && (
                <Collapsible collapsed={isCollapsed}>
                  <View style={{ margin: 20, marginTop: 10 }}>
                    <View style={styles.subContainer}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyText h3 isRegular={false}>
                          Saldo Tersisa{' '}
                        </MyText>
                        <MyText h2 isRegular={false} customStyle={{ color: colors.grey }}>
                          | {workedDays} Hari Kerja
                        </MyText>
                      </View>
                      <MyText h2 customStyle={{ color: colors.grey }}>
                        Diperbarui {moment().format('DD')} {getIndoMonthLong(moment().format('MMMM')?.substring(0, 3))}{' '}
                        {moment().format('YYYY')}
                      </MyText>
                    </View>
                    {/* <MyText h1>
                  (Total gaji prorata Anda hingga hari ini - total pengeluaran)
                </MyText> */}
                    <View>
                      <View style={[styles.subContainer, { marginTop: 10 }]}>
                        <MyText>Total Gaji per bulan* :</MyText>
                        <MyText isRegular={false}>Rp{numberWithDots(amount)}</MyText>
                      </View>

                      <View style={[styles.subContainer, { marginTop: 10 }]}>
                        <MyText>Limit Penarikan Gaji* :</MyText>
                        <MyText isRegular={false}>
                          Rp{numberWithDots(maxWithdrawalAmount > 0 ? maxWithdrawalAmount : salaryWithCappedAmount)}
                        </MyText>
                      </View>

                      <View style={styles.note}>
                        <MyText h2 customStyle={{ color: '#909090', textAlign: 'center' }}>
                          *Nominal Total Gaji dan Limit Penarikan Gaji disesuaikan dengan yang didaftarkan perusahaan
                          Anda pada GajiGesa
                        </MyText>
                      </View>

                      <View style={[styles.subContainer, { marginTop: 10, alignItems: undefined }]}>
                        <MyText customStyle={{ maxWidth: '50%' }}>
                          Gaji prorata berdasarkan Limit Penarikan Gaji:
                        </MyText>
                        <MyText isRegular={false}>
                          Rp
                          {numberWithDots(
                            getAccruedSalary(salaryWithCappedAmount, workingDays, workedDays, maxWithdrawalAmount),
                          )}
                        </MyText>
                      </View>

                      <View style={[styles.subContainer, { marginTop: 10 }]}>
                        <MyText>Total Pengeluaran :</MyText>
                        <MyText isRegular={false}>Rp{numberWithDots(usedBalance)}</MyText>
                      </View>

                      <View style={[styles.subContainer, { marginTop: 10 }]}>
                        <View style={styles.subContainer}>
                          {SVG_ICONS['moneyWings']}
                          <MyText customStyle={{ marginStart: 10 }} h2>
                            Saldo Tersisa ={' '}
                          </MyText>
                        </View>

                        <MyText isRegular={false}>Rp{numberWithDots(newAvailableBalance)}</MyText>
                      </View>

                      <View style={styles.viewHint}>
                        <MyText h2 customStyle={{ textAlign: 'center' }}>
                          (Total gaji prorata berdasarkan Limit Penarikan Gaji - total pengeluaran)
                        </MyText>
                      </View>
                    </View>
                  </View>
                </Collapsible>
              )}
              {/* </AppTourComponentWrapper> */}
              {isCollapsed ? (
                <View
                  style={{
                    height: 1,
                    width,
                    backgroundColor: colors.lightGrey,
                    position: 'absolute',
                    top: 50,
                  }}
                />
              ) : null}
            </View>
          ) : null}
        </View>
      </>
    );
  };

  //  const ContentWrapper = withHoc(Content, { isArrowVisible: true, svgMaskProps: { isFullWidth: false, widthStartAdj: 0, widthEndAdj: 10 }, verticalOffset: 35 });

  return (
    <Modalize
      onClosed={() => {
        dispatch(hideEWAModal());
      }}
      // HeaderComponent={
      //   <CloseHeader
      //     handleClose={() => {
      //       AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.CancelButtonClicked.name, {
      //         [AMPLITUDE_CONSTANTS.Withdrawal.CancelButtonClicked.attributes.source]: eventSource,
      //         [AMPLITUDE_CONSTANTS.Withdrawal.salaryInformationExpand.attributes.referral_code]: redeemCode || null,
      //       });
      //       ref.current.close();
      //       dispatch(hideEWAModal());
      //     }}
      //   />
      // }
      ref={ref}
      rootStyle={{ elevation: 6 }}
      snapPoint={Dimensions.get('screen').height / 1.5}
    >
      <Content showTour={appTourParams.type == 'homepage' && appTourParams.step == 9} />
    </Modalize>
  );
});

export default EWAModal;
