import { Platform, StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: 12,
  },
  inputContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: colors.philippineGray,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  textInput: {
    marginStart: 5,
    flex: 1,
    paddingVertical: 0,
    color: colors.black,
    outlineColor: 'transparent',
  },
  errorText: {
    color: colors.brightred,
    marginTop: 5,
  },
  mandatoryText: {
    color: colors.textRed,
  },
});
