import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const CIRCLE_SIZE = 110;
const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: colors.primaryColor,
    marginHorizontal: '4%',
    minHeight: normalizeHeight(95),
    borderRadius: normalize(11),
    marginVertical: normalizeHeight(10),
    paddingVertical: normalizeHeight(2),
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
  },
  circle: {
    width: normalize(CIRCLE_SIZE),
    height: normalize(CIRCLE_SIZE),
    backgroundColor: colors.darkPrimary,
    borderRadius: normalize(CIRCLE_SIZE) / 2,
    position: 'absolute',
    opacity: 0.2,
  },
  topCircle: { top: normalizeHeight(-45), left: normalize(-10) },
  bottomCircle: { bottom: normalizeHeight(2), left: normalize(-35) },
  rightTopCircle: { top: normalizeHeight(2), right: normalize(-35) },
  rightBottomCircle: { bottom: normalizeHeight(-45), right: normalize(-10) },
  textStyle: {
    fontSize: normalize(13),
    color: colors.white,
    marginTop: normalizeHeight(5),
  },
  tierTitle: {
    fontSize: normalize(18),
    color: colors.white,
    marginTop: normalizeHeight(9),
  },
  expiryText: {
    fontSize: normalize(9),
    color: colors.white,
    marginVertical: normalizeHeight(8),
  },
  progressContainer: {
    marginTop: normalizeHeight(8),
  },
  tierIconStyle: { position: 'absolute', zIndex: 5, top: normalizeHeight(11), right: normalize(11) },
  layerStyle: { position: 'absolute', zIndex: 3, height: '100%', width: '100%', paddingLeft: normalize(20) },
  comingLayerStyle: { position: 'absolute', zIndex: 3, height: '100%', width: '100%', flexDirection: 'row' },
  leftContainer: { width: '30%' },
  rightContainer: { flex: 1, justifyContent: 'center' },
  comingSoonText: { fontSize: normalize(20), color: colors.warning, marginLeft: normalize(-7) },
  comingSoonDesc: {
    fontSize: normalize(12),
    color: colors.white,
    marginLeft: normalize(7),
    width: '80%',
    marginTop: normalizeHeight(8),
  },
  comingLockContainer: {
    flexDirection: 'row',
    marginLeft: normalize(-10),
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tierComingSoon: { position: 'absolute', zIndex: 5, top: normalizeHeight(5), right: normalize(-3) },
});

export default styles;
