import { colors } from '@style/colors';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  failedText: {
    color: colors.red,
  },
  completedButton: {
    flexDirection: 'row',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 10,
    flex: 1,
    backgroundColor: colors.black,
  },
  failedButton: {
    borderStyle: 'dashed',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: colors.red,
    flex: 1,
  },
  cancel: { marginStart: 10 },
  container: {
    overflow: 'hidden',
    borderColor: colors.philippineGray,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    borderStyle: 'dashed',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 10,
    flex: 1,
  },
  name: {
    color: colors.white,
  },
});

export default styles;
