import React from 'react';
import { Text, View, TouchableOpacity, ScrollView, StyleSheet } from 'react-native';

/* Redux Import */
import { useDispatch, useSelector, batch } from 'react-redux';
import { updateShowModal } from '@redux/action/Common';
import { updateProduct } from '@redux/action/BillPayments';

/* Style Import */
import { subTitleText } from '@style/Layouts';

/* Context Import */
import { normalize, normalizeHeight } from '@style/helper';
import AmplitudeHelper from '@services/amplitude';
import { FlatList } from 'react-native';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const EMoneyContent = () => {
  const dispatch = useDispatch();
  const { selectedBiller, selectedProduct } = useSelector((state) => state.BillPayments);
  const { phoneNumber } = useSelector((state) => state.Authentication);

  let newArr =
    selectedBiller != null
      ? selectedBiller.map((item) => {
          return { label: item?.name, value: item };
        })
      : [];

  return (
    <View style={styles.container}>
      {/* <Text style={[subTitleText, styles.title]}>Pilih Produk</Text> */}

      <View>
        <FlatList
          data={newArr}
          keyExtractor={(item) => item.label}
          contentContainerStyle={{ paddingBottom: 300, paddingHorizontal: 15, marginTop: 20 }}
          renderItem={(x) => {
            return (
              <TouchableOpacity
                accessibilityLabel="chooseProduct"
                style={styles.button}
                onPress={() => {
                  // AmplitudeHelper.logEvent('userChoseProduct', {product: x.item.value})
                  let e = AMPLITUDE_CONSTANTS.BillPayment.userChoosesNominal;
                  AmplitudeHelper.logEvent(e.name, {
                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix),
                    [e.attributes.product_code]: formatUnderScoreLowerCase(selectedProduct?.code),
                    [e.attributes.reason]: 'tagihan_dan_utilitas',
                    [e.attributes.reason_id]: 2,
                    [e.attributes.source]: 'other_product_page',
                    [e.attributes.product_price]: selectedProduct?.amount,
                    [e.attributes.account_number]: phoneNumber,
                  });
                  batch(() => {
                    dispatch(updateProduct(x.item.value));
                    dispatch(updateShowModal(''));
                  });
                }}
              >
                <Text style={styles.buttonText}>{x.item.label}</Text>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: 'white', alignItems: 'center' },
  section: { alignSelf: 'flex-start', maxWidth: '90%' },
  title: {
    fontSize: normalize(20),
    fontFamily: 'Nunito-Bold',
    marginBottom: normalizeHeight(15),
    color: '#3863f0',
    alignSelf: 'flex-start',
  },
  scrollView: { width: '100%', marginLeft: 20 },
  button: {
    borderTopWidth: 1,
    borderTopColor: '#c4c4c4',
    paddingVertical: normalizeHeight(10),
    minWidth: '100%',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: normalize(17),
    fontFamily: 'Nunito-Regular',
    marginBottom: normalizeHeight(5),
    alignSelf: 'flex-start',
  },
});

export default EMoneyContent;
