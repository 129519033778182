import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';

const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: 12,
  },
  phoneInpuTitle: {
    fontSize: 12,
    color: colors.philippineGray,
  },
  loginButtonStyle: {
    position: 'absolute',
    bottom: normalize(14),
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primaryColor,
    color: colors.black,
    width: '98%',
    borderRadius: normalize(30),
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  loginButtonTextStyle: { textTransform: 'none' },
  loginButtonDisable: { backgroundColor: colors.gainsboro },
});

export default styles;
