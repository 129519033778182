import mainAPI from './api';
import { batch } from 'react-redux';
import { requestTransaction } from './TransactionApi';
import { verifyBillPayment, setReason } from '@redux/action/Transactions';
import {
  setRegisteredAccounts,
  setRegisteredAccountsPrepaid,
  setRegisteredAccountsWater,
  updateProduct,
} from '@redux/action/BillPayments';
import { respError, setModalType, setShowModal, setGlobalLoading } from '@redux/action/Common';
import {
  formatBillPayment,
  formatBillPaymentCategories,
  formatUnderScoreLowerCase,
  setBillPaymentReason,
} from '@utils/UtilFunctions';
import AmplitudeHelper from './amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import {
  setBillPaymentError,
  setBillPaymentList,
  setBillPaymentLoading,
  setWorkAllowanceBillPayment,
} from '@redux/action/NewBillPayment';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';

export const requestCategory = (params) => {
  let url = 'bill-payment/categories';
  if (params) {
    url += params;
  }
  return mainAPI(url, 'get');
};

export const getBillPaymentCategoriesByAccount = (employeeAccountNumber, accountType) => {
  return async (dispatch, getState) => {
    try {
      const { error, loading } = getState().NewBillPayment;
      !loading && dispatch(setBillPaymentLoading(true));
      error && dispatch(setBillPaymentError(false));
      const response = await mainAPI('bill-payment/categories', 'get', null, {
        employeeAccountNumber,
      });

      const billpaymentList = formatBillPayment(response.data?.data);
      if (accountType === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) {
        dispatch(setWorkAllowanceBillPayment(billpaymentList, false));
        dispatch(setBillPaymentLoading(false));
      } else {
        dispatch(setBillPaymentList(billpaymentList, false));
      }

      return {
        response: response.data.data,
        formattedBp: billpaymentList,
      };
    } catch (error) {
      dispatch(setBillPaymentError(true));
      throw error;
    }
  };
};

export const getBillPaymentCategories = () => {
  return async (dispatch, getState) => {
    try {
      const { error, loading } = getState().NewBillPayment;
      !loading && dispatch(setBillPaymentLoading(true));
      error && dispatch(setBillPaymentError(false));
      const response = await mainAPI('bill-payment/categories', 'get');
      const billpaymentList = formatBillPayment(response.data?.data);

      dispatch(setBillPaymentList(billpaymentList, false));

      return {
        response: response.data.data,
        formattedBp: billpaymentList,
      };
    } catch (error) {
      dispatch(setBillPaymentError(true));
      throw error;
    }
  };
};

export const requestProductList = (id, accountNumber) => {
  return mainAPI(`bill-payment/products?categoryId=${id}&accountNumber=${accountNumber}`, 'get');
};

export const requestBillPaymentTransaction = async (selectedItem, accountNumber) => {
  try {
    const data = {
      id: selectedItem.categoryId,
      transCategoryId: selectedItem.categoryPrefix,
      accountNumber,
      productCode: selectedItem.code,
    };
    const response = await mainAPI(`transaction/request/bill-payment`, 'post', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const selectProduct = (val, accountNum) => {
  return async (dispatch, getState) => {
    try {
      dispatch(updateProduct(val));
      const phoneNumber = getState().Authentication.phoneNumber;

      let resp = await requestTransaction('bill-payment', {
        id: val.categoryId,
        transCategoryId: val.categoryPrefix,
        amount: '',
        accountNumber: accountNum ? accountNum : phoneNumber,
        productCode: val.code,
      });
      if (resp.status == 201 || resp.status == 200) {
        // AmplitudeHelper.logEvent("BillPaymentSuccess", {
        //   id: val.categoryId,
        //   transCategoryId: val.categoryPrefix,
        //   amount: "",
        //   accountNumber: accountNum ? accountNum : phoneNumber,
        //   productCode: val.code,
        // });
        /* 
        attributes: {
          account_number: "account_number",
          transaction_category_id: "transaction_category_id",
          product_code: "product_code",
          reason: "reason",
          reason_id: "reason_id",
          source: "source",
        },
        */
        // let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess
        // AmplitudeHelper.logEvent(e.name, {
        //   // [e.attributes.account_number]: accountNum ? accountNum : phoneNumber,
        //   [e.attributes.transaction_category_id] : formatUnderScoreLowerCase(val?.categoryPrefix),
        //   [e.attributes.product_code] : val?.code,
        //   [e.attributes.product_price] : val?.amount,
        //   [e.attributes.reason] : 'tagihan_dan_utilitas',
        //   [e.attributes.reason_id] : 2,
        //   [e.attributes.payment_method]: 'accrued_salary',
        //   [e.attributes.fee_charged] : val?.adminFee,
        //   [e.attributes.source] : 'other_product_page'
        // })

        let selectedProduct = getState().BillPayments.selectedProduct;

        batch(() => {
          dispatch(
            setReason({
              reason: setBillPaymentReason(selectedProduct.biller).reason,
              reasonId: setBillPaymentReason(selectedProduct.biller).id,
              reasonColor: '#000000',
            }),
          );
          dispatch(verifyBillPayment(resp?.data?.data));
          dispatch(setModalType('pulsa'));
          dispatch(setShowModal(true));
        });
      }
    } catch (error) {
      dispatch(respError(error.response.data.message || 'Permintaan anda tidak tersedia.'));
    }
  };
};

export const getRegisteredAccount = (categoryId) => {
  return async (dispatch) => {
    try {
      let resp = await mainAPI(`bill-payment/accounts?transactionCategoryId=${categoryId}`, 'get');
      if (resp.status == 200 || resp.status == 201) {
        if (categoryId == 'BP-17') {
          dispatch(setRegisteredAccounts(resp.data.data));
        } else if (categoryId == 'BP-06') {
          dispatch(setRegisteredAccountsWater(resp.data.data));
        } else if (categoryId == 'BP-05') {
          dispatch(setRegisteredAccountsPrepaid(resp.data.data));
        }
      }
    } catch (error) {}
  };
};
