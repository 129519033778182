import React, { useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import Close from '@expo/vector-icons/AntDesign';
import { colors } from '@style/colors';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import styles from './styles';

const AppTourToolTip = ({
  isFirstStep,
  isLastStep,
  handleNext,
  handlePrev,
  handleStop,
  currentStep,
  onPressExit,
  onPressNext,
  onPressBack,
  hasIcon = true,
}) => {
  const onDismiss = useCallback(() => {
    handleStop();
    onPressExit(currentStep.order, currentStep.text.type, currentStep.text?.tourStep);
  }, [currentStep]);
  const onFinalEnd = useCallback(() => {
    handleStop();
    onPressExit(currentStep.order, currentStep.text.type, currentStep.text?.tourStep, true);
  }, [currentStep]);

  const onBackHandler = useCallback(() => {
    if (isFirstStep) {
      handleStop();
      onPressBack(currentStep.order, currentStep.text.type, currentStep.text?.tourStep);
      return;
    }
    if (currentStep.text.prevScroll && currentStep.text.prevScroll.includes(currentStep.order))
      setTimeout(() => {
        handlePrev();
      }, 500);
    else {
      handlePrev();
    }
    if (onPressBack) onPressBack(currentStep.order, currentStep.text.type, currentStep.text?.tourStep);
  }, [currentStep]);

  const onNextHandler = useCallback(() => {
    if (isLastStep) {
      handleStop();
      onPressNext(currentStep.order, currentStep.text.type, currentStep.text?.tourStep);
      return;
    }
    if (currentStep.text.nextScroll && currentStep.text.nextScroll.includes(currentStep.order))
      setTimeout(() => {
        handleNext();
      }, 500);
    else {
      handleNext();
    }
    if (onPressNext) onPressNext(currentStep.order, currentStep.text.type, currentStep.text?.tourStep);
  }, [currentStep]);

  const getButtonsBySteps = useCallback(() => {
    if (currentStep.text.flowEndStep) {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <LoadingButton
            textStyle={styles.buttonText}
            text={'Kembali'}
            onPress={onBackHandler}
            container={styles.buttonStyle}
          />

          <LoadingButton
            textStyle={styles.buttonText}
            text={'Selesai'}
            onPress={onFinalEnd}
            container={styles.buttonStyle}
          />
        </View>
      );
    }

    if (currentStep.order > 1) {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <LoadingButton
            textStyle={styles.buttonText}
            text={'Kembali'}
            onPress={onBackHandler}
            container={styles.buttonStyle}
          />

          <LoadingButton
            textStyle={styles.buttonText}
            text={'Lanjut'}
            onPress={onNextHandler}
            container={styles.buttonStyle}
          />
        </View>
      );
    }

    if (currentStep.order == 0)
      return (
        <LoadingButton textStyle={styles.buttonText} text={'Ok'} onPress={onDismiss} container={styles.buttonStyle} />
      );

    return (
      <LoadingButton
        textStyle={styles.buttonText}
        text={'Lanjut'}
        onPress={onNextHandler}
        container={styles.buttonStyle}
      />
    );
  }, [currentStep]);

  return (
    <View style={{ borderRadius: 10 }}>
      <View>
        <TouchableOpacity onPress={onDismiss} style={{ alignSelf: 'flex-end' }}>
          <Close name="close" size={20} color={colors.grey} />
        </TouchableOpacity>
      </View>

      <View style={styles.horizontalView}>
        {hasIcon && <View>{SVG_ICONS.gift}</View>}
        <View style={[{ marginStart: 5 }, hasIcon && { width: '80%' }]}>
          {currentStep?.text?.title ? (
            <MyText customStyle={{ marginBottom: 8 }} isRegular={false}>
              {currentStep.text.title}
            </MyText>
          ) : null}
          {React.isValidElement(currentStep?.text.description) ? (
            currentStep.text.description
          ) : (
            <MyText>{currentStep?.text.description}</MyText>
          )}
        </View>
      </View>
      {getButtonsBySteps()}
    </View>
  );
};

export default AppTourToolTip;
