import { StyleSheet } from 'react-native';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

const styles = StyleSheet.create({
  scrollableContainerStyle: {
    flexGrow: 1,
    paddingTop: normalize(10),
  },

  dividerStyle: { height: normalize(4.5), marginVertical: normalize(3) },
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    paddingVertical: normalize(9),
    justifyContent: 'center',
    borderRadius: normalize(30),
    marginVertical: normalize(15),
    width: '90%',
  },
  disabledButton: { backgroundColor: colors.disabled_smoke },
});

export default styles;
