import axios from 'axios';
import mainAPI from './api';
import url from '@gaji-gesa/gg-ui-shared/src/config/config';
import { batch } from 'react-redux';
import { times, chunk } from 'lodash';
import {
  numberWithDots,
  refferalDencryption,
  convertDotsToNum,
  getAvailableBalance,
  calculateSubTotal,
  findAccountByName,
} from '@utils/UtilFunctions';
import {
  actionSetInitialWithdrawal,
  actionSetReasonList,
  actionSetReferralPrecheck,
  actionSetReferralPrecheckFail,
} from '@redux/action/WIthdrawal';
import { setModalType, setShowModal, respError, setCanContinue, setConfirmed } from '@redux/action/Common';
import { verifyWithdrawal, setTransactionCategoryId, setTransactionSource } from '@redux/action/Transactions';
import { requestTransaction } from './TransactionApi';
import { autoLogout } from '@redux/action/Authentication';
import AmplitudeHelper from './amplitude';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { clearReferral } from '@redux/action/Referral';
import { DYNAMIC_ACCOUNT_NAMES, TOOLTIP_CONTENT } from '@utils/Constants';
import logoutCallBack from '@utils/AuthenticationUtils';
import { clearDynamicAccountReducer } from '@redux/action/DynamicAccounts';
import { actionSetWithdrawAmount } from '@redux/action/WIthdrawal';
const DashboardService = {
  getDashboardDetail: async function () {
    return mainAPI('salary-profile', 'get');
  },

  checkWithdrawalAount: async function (data) {
    let authToken = await getToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${authToken}`,
    };
    return axios.post(url.API_URL + '/api/withdrawal-request', data, {
      headers: headers,
    });
  },
};

//=================================NEW APIS===========================================================

export const getDashboardDetailApi = () => async (dispatch) => {
  try {
    const response = await mainAPI('salary-profile', 'get');

    if (response?.status == 200 || response?.status == 201) {
      if (Object.keys(response.data.data).length == 0) {
        respError(response.data.message);
        return;
      } else {
        const _data = response.data.data;
        const todayBalance = _data.salaryProfile.todayBalance < 0 ? 0 : _data.salaryProfile.todayBalance;

        const data = {
          todayBalance: _data.salaryProfile.todayBalance < 0 ? 0 : _data.salaryProfile.todayBalance,
          workingDays: _data.salaryProfile.circleDays,
          workedDays: _data.salaryProfile.totalWorkingDay < 0 ? 0 : _data.salaryProfile.totalWorkingDay,
          lockPeriod: _data.salaryProfile.isLockPeriod,
          minBalance: _data.salaryProfile.minWithdrawalAmount,
          updatedDate: _data.updated_at,
          firstWorkingDate: _data.salaryProfile.firstWorkingDate,
          endWorkingDate: _data.salaryProfile.endWorkingDate,
          startWithdrawalDate: _data.salaryProfile.startWithdrawalDate,
          numberOfLockPeriod: _data.salaryProfile.numberOfLockPeriod,
          withdrawalLockType: _data.salaryProfile.withdrawalLockType,
          payDateWithdrawal: _data.salaryProfile.payDateWithdrawal,
          salaryWithCappedAmount: Math.floor(_data.salaryProfile.salaryWithCappedAmount),
          todayBalanceFloor: Math.floor(_data.salaryProfile.todayBalance),
          //_data.salaryProfile.displayBalance, //Math.floor(_data.salaryProfile.todayBalance),
          todaySalaryFloor: _data.salaryProfile.todaySalaryFloor,
          amount: _data.salaryProfile.amount,
          maxWithdrawalAmount: Math.floor(_data.salaryProfile.maxWithdrawalAmount),
          usedBalance: _data.totalTransactions,
        };

        const availableBalance = getAvailableBalance(
          data.salaryWithCappedAmount,
          data.todayBalanceFloor,
          data.workingDays,
          data.workedDays,
          data.maxWithdrawalAmount,
          data.usedBalance,
        );
        let options = [];
        if (availableBalance > 0) {
          const minAmount = availableBalance * 0.25;
          options = times(2, (i) => Math.floor(minAmount * (i + 2)));
          options.push(availableBalance);
        }
        data.options = options;
        data.withdrawAmount = availableBalance <= 0 ? '0.0' : numberWithDots(Math.floor(availableBalance * 0.95));
        data.maxSalary = availableBalance < 0 ? 0 : availableBalance;
        dispatch(actionSetInitialWithdrawal(data));
      }
    } else {
      respError(response?.response?.data?.message);
    }
    return response.data.data;
  } catch (error) {
    if (error.toString().includes('504')) {
      // dispatch(respError('Error server internal'))
    } else {
      dispatch(autoLogout(logoutCallBack));
      dispatch(clearReferral());
      dispatch(clearDynamicAccountReducer());
    }
    throw error;
  }
};

export const getReasonsApi = () => {
  return async (dispatch) => {
    try {
      const response = await mainAPI('transactions/reasons', 'get', null, {
        filterBy: 'EXPENSE',
      });

      let chunkedArray = chunk(response.data.data, 12);
      dispatch(actionSetReasonList(chunkedArray));
    } catch (error) {
      throw error;
    }
  };
};

export const preCheckWithdrawal = () => {
  return async (dispatch, getState) => {
    try {
      let withdrawAmount, redeemCode;
      const referralPrecheckFail = getState().Withdrawal.referralPrecheckFail;
      withdrawAmount = getState().Withdrawal.withdrawAmount;
      redeemCode = getState().Referral.redeemCode;
      referralPrecheckFail && dispatch(actionSetReferralPrecheckFail(false));
      dispatch(actionSetReferralPrecheck(false, TOOLTIP_CONTENT['rewardFailed']));
      const data = {
        transCategoryId: 'WD-01',
        accountNumber: '',
        productCode: '',
        amount: convertDotsToNum(withdrawAmount),
        referralCode: refferalDencryption(redeemCode),
      };

      const resp = await mainAPI('transaction/request/withdrawal/precheck', 'post', data);

      return resp.data;
    } catch (error) {
      dispatch(actionSetReferralPrecheckFail(true));
      dispatch(actionSetReferralPrecheck(true, error?.response?.data?.message));
      throw error;
    }
  };
};

export const applyWithdrawalForDynamicAccount = (resp) => async (dispatch, getState) => {
  let mashedResponse = resp;
  const { currentHomePageSelectedAccount } = getState().DynamicAccounts;
  let withdrawAmount;
  if (currentHomePageSelectedAccount.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT) {
    withdrawAmount = getState().DynamicAccounts[currentHomePageSelectedAccount.constantAccountName]?.value;
  } else {
    withdrawAmount = getState()
      .DynamicAccounts[currentHomePageSelectedAccount.constantAccountName]?.value?.slice()
      ?.shift();
  }
  if (withdrawAmount <= 0) return;
  try {
    const data = {
      transCategoryId: 'WD-01',
      amount: withdrawAmount,
      employeeAccountNumber: currentHomePageSelectedAccount.id,
    };

    //Hardcoding as part of Bug-420
    const fee = 0; //get from hasura query
    const feeType = resp.gg_employee[0].salaryProfiles[0].feeType; //get from hasura query
    const subtotal = calculateSubTotal(withdrawAmount, fee, feeType);
    mashedResponse = {
      message: 'OK',
      data: {
        data: {
          requestAmount: withdrawAmount,
          fee,
          subtotal,
          bank: {
            id: resp.gg_employee[0].bankId, //get from hasura query
            bankName: resp.gg_employee[0].bank_name.bankName, //get from hasura query
            bankCode: resp.gg_employee[0].bank_name.bank_code, //get from hasura query
          },
          bankAccountNo: resp.gg_employee[0].bankAccountNo, //get from hasura query
          beneficiaryName: resp.gg_employee[0].beneficiaryName, //get from hasura query
        },
      },
    };
    // const resp = await requestTransaction('withdrawal', data, currentHomePageSelectedAccount);

    batch(() => {
      dispatch(verifyWithdrawal(mashedResponse.data.data));
      dispatch(setModalType('reasons'));
      dispatch(setShowModal(true));
    });
  } catch (error) {
    throw error;
  }
};

export const applyForWithdrawal = () => {
  return async (dispatch, getState) => {
    let withdrawAmount, eventSource, redeemCode;
    const currentHomePageSelectedAccount = getState().DynamicAccounts.currentHomePageSelectedAccount;
    try {
      withdrawAmount = getState().Withdrawal.withdrawAmount;
      eventSource = getState().Common.eventSource;
      redeemCode = getState().Referral.redeemCode;

      const data = {
        transCategoryId: 'WD-01',
        accountNumber: '',
        productCode: '',
        amount: convertDotsToNum(withdrawAmount),
      };
      batch(() => {
        dispatch(setCanContinue(false));
        dispatch(setConfirmed(false)); //Reseting state
        dispatch(setTransactionCategoryId('wd-01'));
        dispatch(setTransactionSource(AMPLITUDE_EVENT_SOURCES.home_page));
      });
      let resp = await requestTransaction('withdrawal', data, currentHomePageSelectedAccount);

      if (resp.status == 201 || resp.status == 200) {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.name, {
          [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.attributes.transaction_category_id]: 'wd-01',
          [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.attributes.source]: eventSource,
          [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.attributes.referral_code]: redeemCode || null,
          [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.attributes.dynamic_account_name]:
            currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
          [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalSuccess.attributes.requested_amount]:
            convertDotsToNum(withdrawAmount),
        });

        batch(() => {
          dispatch(verifyWithdrawal(resp.data.data));
          dispatch(setModalType('reasons'));
          dispatch(setShowModal(true));
        });
      } else {
      }
    } catch (error) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.transaction_category_id]: 'wd-01',
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.requested_amount]:
          convertDotsToNum(withdrawAmount),
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.failure_message]:
          error?.response?.data ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.verifyWithdrawalFailed.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
      });

      dispatch(respError(error?.response?.data?.message || error?.message));
      throw error;
    }
  };
};

export const applyForTransfer = (amount, bankAccountId) => {
  return async (dispatch, getState) => {
    let salaryAccount = null;
    try {
      const { dataAccounts } = getState().DynamicAccounts;
      salaryAccount = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
      const data = {
        transCategoryId: 'WD-02',
        amount: convertDotsToNum(amount),
        bankAccountId,
      };
      batch(() => {
        dispatch(setTransactionCategoryId('wd-02'));
        dispatch(setTransactionSource(AMPLITUDE_EVENT_SOURCES.transfer_page));
      });
      let resp = await requestTransaction('withdrawal', data, salaryAccount);

      const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_successful;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: 'wd-02',
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transfer_page,
        [e.attributes.referral_code]: getState().Referral.redeemCode || null,
        [e.attributes.dynamic_account_name]: salaryAccount?.amplitudeAccountName ?? '',
        [e.attributes.requested_amount]: convertDotsToNum(amount),
      });
      batch(() => {
        dispatch(actionSetWithdrawAmount(amount));
        dispatch(verifyWithdrawal(resp.data.data));
        dispatch(setModalType('reasons'));
        dispatch(setShowModal(true));
      });
    } catch (error) {
      const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: 'wd-02',
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transfer_page,
        [e.attributes.requested_amount]: convertDotsToNum(amount),
        [e.attributes.failure_message]: error?.response?.data ?? null,
        [e.attributes.referral_code]: getState().Referral.redeemCode || null,
        [e.attributes.dynamic_account_name]: salaryAccount?.amplitudeAccountName ?? '',
      });
      dispatch(respError(error?.response?.data?.message));
      throw error;
    }
  };
};

export default DashboardService;
