import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/browser';
import { ApolloClient, InMemoryCache, HttpLink, from, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import config from '@gaji-gesa/gg-ui-shared/src/config/config';
import { clearDynamicAccountReducer } from '@redux/action/DynamicAccounts';
import { store } from '@enhancedStore/store';
import { autoLogout } from '@redux/action/Authentication';
// import RNInsider from 'react-native-insider';
import { SentryHelper, SENTRY_BUG_CONSTANTS } from '@utils/SentryHelper';
import { clearReferral } from '@redux/action/Referral';
import logoutCallBack from '@utils/AuthenticationUtils';
import { logoutFromMoengage } from '@utils/engagementUtils';

// Declare customAxios
// const customAxios = axios.create({
//   baseURL: setDefaultParams().url,
//   headers: {
//     "x-api-key": setDefaultParams().apiKey,
//     "Content-Type": "application/json",
//   }
// })

// Set Default API Params
export const setDefaultParams = async () => {
  try {
    const phone = await AsyncStorage.getItem('@phoneNumber');
    if (phone) {
      return {
        baseURL: phone.charAt(0) == '1' ? config.API_LOAN_URL : config.API_URL,
        headers: {
          'x-api-key': phone.charAt(0) == '1' ? '888' : '87654321',
          'Content-Type': 'application/json',
        },
      };
    } else {
      return {
        baseURL: config.API_URL,
        headers: {
          'x-api-key': '87654321',
          'Content-Type': 'application/json',
        },
      };
    }
  } catch (error) {
    AsyncStorage.clear();
  }
};

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    'x-api-key': '87654321',
    'Content-Type': 'application/json',
  },
});

// Request interceptor
// customAxios.interceptors.request.use(
//   async (config) => {
//       // const token = store.getState().Login.accessToken
//       // get Token
//       let token = await AsyncStorage.getItem('@token')

//       let options = token ?
//       {
//        headers: {
//            "x-api-key": phone.charAt(0) == '1' ? "888" : "87654321",
//            "Content-Type": "application/json",
//            "Authorization": `Bearer ${token}`
//          }
//       } :
//       {
//        headers: {
//            "x-api-key": phone.charAt(0) == '1' ? "888" : "87654321",
//            "Content-Type": "application/json",
//        }
//       }
//     options = payload ? {...options, data: payload} : {...options}

//       // if(token !== null) {
//       //     config = {
//       //         ...config,
//       //         headers: {
//       //             "x-api-key": "030303",
//       //             "Authorization": `Bearer ${token}`,
//       //             "Content-Type": "application/json"
//       //         }
//       //     }
//       // }else{
//       //   config = {
//       //     ...config,
//       //     headers: {
//       //         "x-api-key": "030303",
//       //         "Content-Type": "application/json"
//       //     }
//       // }
//       // }
//       Sentry.addBreadcrumb({
//         category: 'log',
//         message: JSON.stringify({method: config?.method || "NO_METHOD", data: config?.data || "NO_DATA"})
//       })
//       return config;
//   },
//   (error) => {
//       return Promise.reject(error);
//   }
// )

//Response interceptor

//Check if the error is 401 or unauthorized token

//Refresh token

//Redo Requests

// MAIN API
const mainAPI = async (url, method, payload, params, signal, contentType, baseUrl) => {
  try {
    const token = await AsyncStorage.getItem('@token');

    let options = token
      ? {
          method,
          url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {
          method,
          url,
        };

    options = params ? { ...options, params } : { ...options };
    options = payload ? { ...options, data: payload } : { ...options };
    options = contentType
      ? { ...options, headers: { ...options.headers, ['Content-Type']: contentType } }
      : { ...options };
    options = signal ? { ...options, signal } : { ...options };
    options = baseUrl ? { ...options, baseURL: baseUrl } : { ...options };

    Sentry.addBreadcrumb({
      category: 'log',
      message: JSON.stringify({ method: options?.method || 'NO_METHOD', data: options?.data || 'NO_DATA' }),
    });

    return await axiosInstance(options);
  } catch (error) {
    if (error?.response?.status !== 401 && error?.response?.status !== 404) {
      SentryHelper.captureException(error);
    }

    if (error.code === 'ERR_NETWORK')
      error.message = 'Koneksi internet terputus. Cek jaringan internetmu dan harap coba kembali.';

    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      (error?.response?.status == 401 && error?.response?.data?.error == 'UNAUTHORIZED_ACCESS_TOKEN_JWT') ||
      (error?.response?.status == 401 && error?.response?.data?.code == 'UNAUTHORIZED_ACCESS')
    ) {
      try {
        const refreshToken = await AsyncStorage.getItem('@refreshToken');
        let result = await axios.post(`${config.API_URL}auth/refresh`, undefined, {
          headers: {
            'x-api-key': '87654321',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        if (result?.status === 401) {
          logoutFromMoengage();
          store.dispatch(autoLogout(logoutCallBack));
          store.dispatch(clearReferral());
          store.dispatch(clearDynamicAccountReducer());
          return Promise.reject(error);
        }
        let newToken = result?.data?.data?.accessToken;
        if (newToken != undefined || newToken != null) {
          await AsyncStorage.setItem('@token', newToken);
        }
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(error.config);
      } catch (error) {
        console.log(error, 'ERROR====>');
        // DISPATCH SHOULD BE HERE RIGHT?
        SentryHelper.captureCustomException(SENTRY_BUG_CONSTANTS.refreshTokenFailed, {
          code: error?.response?.status ?? 'NO CODE',
          data: error?.response?.data ?? 'NO DATA',
        });
        if (error?.response?.status == 401) {
          logoutFromMoengage();
          store.dispatch(autoLogout(logoutCallBack));
          store.dispatch(clearReferral());
          store.dispatch(clearDynamicAccountReducer());
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  },
);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const resolvePendingRequests = () => {
  subscribers.map((callback) => callback());
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err?.message?.includes('JWTExpired')) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;

          return fromPromise(
            refreshToken().catch((error) => {
              isAlreadyFetchingAccessToken = false;
              subscribers = [];
              return forward(operation);
            }),
          ).flatMap(() => {
            resolvePendingRequests();
            isAlreadyFetchingAccessToken = false;

            return forward(operation);
          });
        } else {
          //addSubscriber(()=>operation)

          return fromPromise(
            new Promise((resolve) => {
              addSubscriber(() => resolve());
            }),
          ).flatMap(() => {
            return forward(operation);
          });
        }
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: config.GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await AsyncStorage.getItem('@token');

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

export let gqClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

const refreshToken = async () => {
  try {
    const refreshTokenFromStorage = await AsyncStorage.getItem('@refreshToken');
    const response = await axios.post(`${config.API_URL}/auth/refresh`, undefined, {
      headers: {
        'x-api-key': '87654321',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshTokenFromStorage}`,
      },
    });
    const accessToken = response.data?.data?.accessToken;

    await AsyncStorage.setItem('@token', accessToken);
    return accessToken;
  } catch (error) {
    if (error?.response) {
      if (error.response.status == 401 || error.response.status == 422) {
        await gqClient.clearStore();
        store.dispatch(autoLogout(logoutCallBack));
        store.dispatch(clearReferral());
        store.dispatch(clearDynamicAccountReducer());
      }
    }
    throw error;
  }
};

export default mainAPI;
