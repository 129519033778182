import { StyleSheet } from 'react-native';
import { normalize } from '@style/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

const styles = StyleSheet.create({
  upperContainerStyle: { paddingTop: normalize(10) },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: normalize(14),
    paddingVertical: normalize(10),
    alignItems: 'center',
  },
  dividerStyle: { height: normalize(4.5), marginVertical: normalize(14) },
  logoutText: {
    fontSize: normalize(11),
    color: colors.error_rose,
  },
  logoutContainer: {
    width: '92%',
    borderWidth: 0,
    backgroundColor: colors.primary_softCarnation,
    borderRadius: normalize(14),
    marginTop: normalize(8),
    paddingVertical: normalize(10),
  },
  appVersionText: {
    marginTop: normalize(11),
    fontSize: normalize(10),
    color: colors.neutral_charcoal,
    alignSelf: 'center',
  },
  giftStyle: {
    top: normalize(-7),
  },
  iconSVGStyle: {
    height: normalize(25),
  },
  iconIphne: {
    height: normalize(20),
  },
});

export default styles;
