import React, { useRef, useState } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import { Dropdown as ModalDropdown } from 'react-native-element-dropdown';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { store } from '@enhancedStore/store';
import { formatUnderScoreLowerCase, removeNonNumeric } from '@utils/UtilFunctions';
import { DYNAMIC_ACCOUNTS_TYPE, DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';

const AccountList = ({ data, transactionRequest, value, setValue, prefix, employeeId, lockPeriod = false }) => {
  const ref = useRef();
  const accountName = value.constantAccountName;
  const valueAmount = value.amount;
  const checkIfDisable = (item) => {
    const amount = removeNonNumeric(item.amount);
    return (
      (lockPeriod && item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT) ||
      parseFloat(transactionRequest.subtotal) > amount
    );
  };

  const renderItem = (item) => {
    const amount = removeNonNumeric(item.amount);
    const currentSelectedAccount = accountName === item.constantAccountName;
    return (
      <Pressable
        onPress={() => onChangeItem(item, amount)}
        disabled={parseFloat(transactionRequest.subtotal) > amount}
        style={{
          flexDirection: 'row',
          backgroundColor: currentSelectedAccount ? colors.orange : colors.white,
          paddingHorizontal: 15,
          paddingVertical: 15,
          opacity: checkIfDisable(item, amount) ? 0.7 : 1,
        }}
      >
        <View style={{ marginTop: 3 }}>{item.icon}</View>

        <View style={{ marginStart: 10 }}>
          <MyText isRegular={false} h4 customStyle={{ color: currentSelectedAccount ? colors.white : colors.black }}>
            {item.accountName} | {item.amount}
          </MyText>
          {parseFloat(transactionRequest.subtotal) > amount && (
            <MyText customStyle={{ color: currentSelectedAccount ? colors.white : colors.black }} h2>
              {item.accountName} Tidak Mencukupi
            </MyText>
          )}
          {lockPeriod && item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT && (
            <MyText customStyle={{ color: currentSelectedAccount ? colors.white : colors.black }} h2>
              Tidak dalam masa Penarikan
            </MyText>
          )}
        </View>
      </Pressable>
    );
  };
  const onFocus = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.transactionSummary.paymentMethod;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'payment_summary_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
  };

  const onChangeItem = (item) => {
    if (!checkIfDisable(item)) {
      setValue(item);
      let e = AMPLITUDE_CONSTANTS.BillPayment.transactionSummary.pickPaymentMethod;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.employee_id]: employeeId,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
        [e.attributes.point_balance]: item.amount,
        [e.attributes.point_used]: transactionRequest.subtotal,
        [e.attributes.product_code]: transactionRequest.productCode,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.payment_method]: item.accountName === 'Poin' ? 'poin' : 'accrued_salary',
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
      ref.current.close();
    }
  };

  return (
    <ModalDropdown
      style={styles.dropdown}
      ref={ref}
      placeholderStyle={styles.placeholderStyle}
      selectedTextStyle={styles.selectedTextStyle}
      inputSearchStyle={styles.inputSearchStyle}
      iconStyle={styles.iconStyle}
      data={data}
      onFocus={onFocus}
      labelField="label"
      valueField="value"
      dropdownPosition="bottom"
      disable={data.length === 0}
      placeholder={data.length === 0 ? '' : `${value.accountName} | ${valueAmount}`}
      value={value}
      onChange={onChangeItem}
      renderLeftIcon={() => (
        <View
          android_ripple={{ color: colors.lightGrey }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 5,
            paddingHorizontal: 15,
            paddingVertical: 10,
          }}
        >
          {value.icon}
          <MyText isRegular={false} h4 customStyle={{ marginStart: 10 }}>
            {value.accountName} | {valueAmount}
          </MyText>
        </View>
      )}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  dropdown: {
    margin: 16,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: colors.textGrey,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

export default AccountList;
