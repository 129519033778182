import React from 'react';
import { Text, View, StyleSheet, Pressable, Linking } from 'react-native';
import { normalize } from '@style/helper';
import Icon from '@expo/vector-icons/Ionicons';
import Loading from '@screens/Loading';
import { COMMON_CONSTANTS } from '@utils/Constants';
import { colors } from '@style/colors';

const ParentWrapper = ({ onReload, loading, children, isError }) => {
  const onContact = () => {
    Linking.openURL(COMMON_CONSTANTS.support);
  };

  if (loading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Mohon Maaf terjadi kesalahan sistem. Mohon muat ulang halaman.</Text>
        <View style={styles.buttonGroup}>
          <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onContact}>
            <Text style={styles.buttonText}>
              <Icon style={[styles.buttonText, { fontSize: 15 }]} name="logo-whatsapp" /> BANTUAN
            </Text>
          </Pressable>
          <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onReload}>
            <Text style={styles.buttonText}>
              <Icon style={[styles.buttonText, { fontSize: 15 }]} name="refresh" /> MUAT ULANG
            </Text>
          </Pressable>
        </View>
      </View>
    );
  }

  return <>{children}</>;
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  text: {
    color: '#9e9e9e',
    fontFamily: 'Nunito-Regular',
    fontSize: normalize(14),
    textAlign: 'center',
    maxWidth: '75%',
  },
  buttonGroup: { marginVertical: '10%', width: '75%', flexDirection: 'row', justifyContent: 'space-evenly' },
  button: { borderWidth: 1, borderColor: '#3863F0', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 4 },
  buttonText: {
    color: '#3863F0',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(12),
    textAlign: 'center',
  },
});

export default ParentWrapper;
