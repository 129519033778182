import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  ActivityIndicator,
  RefreshControl,
  TouchableOpacity,
} from 'react-native';

/* Styles */
import { normalize, normalizeHeight } from '@style/helper';
import { GET_COINS, GET_COINS_LIST } from '@gqlQuery/query';
import {
  findAccountByName,
  formatUnderScoreLowerCase,
  numberWithDots,
  removeNonNumeric,
  setPointsValues,
  sortedPointsBp,
} from '@utils/UtilFunctions';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setCoinBalance, setCoinList } from '@redux/action/Coins';
import moment from 'moment';
import { colors } from '@style/colors';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import { LgButton } from '@components/Button';
import Close from '@expo/vector-icons/AntDesign';
import { Font } from '@style/Fonts';
import AmplitudeHelper from '@services/amplitude';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import MarketingBanner from '@components/MarketingBanner';
import {
  COMMON_CONSTANTS,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  JSON_URLS,
  POSTPAID_VALIDATION_VALUE,
} from '@utils/Constants';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { getBillPaymentCategories } from '@services/BillPaymentApi';
import { respError } from '@redux/action/Common';
//  import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
//  import CurrentTier from '@components/Tier/CurrentTier';
import PoinCard from '@atoms/PoinCard';
import { setCurrentBpSelectedAccount } from '@redux/action/DynamicAccounts';

const MAX_PAGE_LIMIT = 10;
const ShortBillPayment = ({ list, onPress }) => {
  const renderItem = ({ item, index }) => (
    <View style={style.bpItemContainer}>
      <Pressable
        onPress={() => onPress(item, index)}
        android_ripple={{ color: colors.lightGrey }}
        style={style.product}
      >
        <Image source={{ uri: item.imageUrl }} style={style.image} />
      </Pressable>
      <MyText h2 customStyle={style.titbpTitlele}>
        {item.name}
      </MyText>
    </View>
  );
  const header = () => (
    <MyText isRegular={false} h4 customStyle={{ marginTop: 15 }}>
      Tukar Poin
    </MyText>
  );

  const keyExtractor = (item) => item.id.toString();
  return (
    <FlatList
      data={list}
      keyExtractor={keyExtractor}
      numColumns={4}
      renderItem={renderItem}
      style={{ paddingStart: 10 }}
      ListHeaderComponent={header}
    />
  );
};

const formatShorBPList = (list, pointBalance) => {
  const concatenatedBpList = [];
  for (let index = 0; index < list.length; index++) {
    concatenatedBpList.push(...list[index].data[0].list);
  }

  let sortedConcatenatedList = [];

  sortedConcatenatedList = concatenatedBpList
    .filter((e) => {
      if (pointBalance < POSTPAID_VALIDATION_VALUE[e.prefix]?.value) return false;
      if (e.prefix === 'PR-01') return false;
      if (e.prefix === 'BP-15') return false;
      else return true;
    })
    .map((e) => sortedPointsBp(e));

  sortedConcatenatedList.sort((a, b) => {
    return a.priority > b.priority;
  });
  if (sortedConcatenatedList.length === 3) {
    let others = {
      id: '100000',
      name: 'Lainnya',
      imageUrl: COMMON_CONSTANTS.othersImageUrl,
      priority: 4,
    };
    sortedConcatenatedList.push(others);

    return sortedConcatenatedList;
  } else if (sortedConcatenatedList.length > 3) {
    let others = {
      id: '100000',
      name: 'Lainnya',
      imageUrl: COMMON_CONSTANTS.othersImageUrl,
      priority: 4,
    };
    const newConcatenatedList = sortedConcatenatedList.slice(0, 3);
    newConcatenatedList.sort((a, b) => {
      return a.priority > b.priority;
    });
    newConcatenatedList.push(others);
    return newConcatenatedList;
  }

  return sortedConcatenatedList;
};


const TransItem = ({ item }) => (
  <View style={style.itemContainer}>
    <View style={{ flexDirection: 'row', flex: 1 }}>
      <View style={[style.iconContainer, { borderColor: '#FFD700' }]}>{SVG_ICONS.coin}</View>
      <View style={{ flex: 1 }}>
        <Text style={[style.amount, { fontSize: 14, color: item.value < 0 ? colors.textRed : colors.textGreen }]}>
          {item.value > 0 ? '+' : ''}
          {numberWithDots(item.value)}
        </Text>
        <Text style={[style.title, { fontSize: normalize(12), color: colors.black, width: '70%' }]}>
          {item.description}
        </Text>
      </View>
    </View>
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
      <Text
        style={[
          style.amount,
          { alignSelf: 'flex-start' },
          { fontSize: normalize(12), color: colors.grey, marginStart: 30 },
        ]}
      >
        {moment(item.createdAt).format('DD MMM YY')}
      </Text>
    </View>
  </View>
);

const Points = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { employeeId } = useSelector((state) => state.Authentication);
  const { billpaymentList } = useSelector((state) => state.NewBillPayment);
  const dispatch = useDispatch();
  const source = route?.params?.source || null;
  const [offset, setOffset] = useState(-1);
  const [refresh, setRefresh] = useState(false);
  const { coinList } = useSelector((state) => state.Coins);
  const isFocused = useIsFocused();
  const [loadingMarketingBanners] = useState(true);

  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(false);
  const [marketingBanners] = useState([{ id: '1' }, { id: '2' }]);
  const [loadingCoin, setLoadingCoin] = useState(true);
  const { dataAccounts } = useSelector((state) => state.DynamicAccounts);
  const pointsAccount = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.POINTS);
  const pointsAccountAmount = pointsAccount?.amount;
  const coinBalance = useMemo(() => numberWithDots(removeNonNumeric(pointsAccountAmount ?? '0')), [dataAccounts]);
  const getBillPaymentCategory = async () => {
    try {
      await dispatch(getBillPaymentCategories());
    } catch (e) {}
  };

  const [getTotalPoints] = useLazyQuery(GET_COINS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: pointsAccount?.id ?? 1,
    },
    onCompleted: (data) => {
      if (billpaymentList.length === 0) {
        getBillPaymentCategory();
      }
      dispatch(setCoinBalance(data?.balance[0]?.availableBalance ?? 0));
      setLoadingCoin(false);
    },
  });

  const [getCoins] = useLazyQuery(GET_COINS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      id: employeeId,
      limit: MAX_PAGE_LIMIT,
      offset: offset + 1,
      accountId: pointsAccount?.id ?? 1,
    },
    onCompleted: (data) => {
      if (data.points_txn.length > 0) {
        setPointsValues(data, dispatch, coinList, setOffset, offset, setCoinList);
      }
      if (refresh) setRefresh(false);
    },
  });
  const [isModalOpen, setModalState] = useState(false);

  const footer = useCallback(() => {
    if (loadingCoin) {
      return <ActivityIndicator color={colors.orange} size="large" style={{ marginVertical: 10 }} />;
    }
    return null;
  }, [loadingCoin]);

  const onRefresh = () => {
    setOffset(-1);
    setRefresh(true);
    dispatch(setCoinList([]));
    getTotalPoints();
    getCoins({
      variables: {
        id: employeeId,
        limit: MAX_PAGE_LIMIT,
        offset: 0,
        accountId: pointsAccount?.id ?? 1,
      },
    });
  };

  const onEndReachHandle = () => {
    if (!onEndReachedCalledDuringMomentum) {
      if (!loadingCoin) {
        getCoins({
          variables: {
            id: employeeId,
            limit: MAX_PAGE_LIMIT,
            offset: offset + 1,
          },
        });
      }
      setOnEndReachedCalledDuringMomentum(true);
    }
  };
  const closeModal = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.CancelButtonClicked.name, {
      [AMPLITUDE_CONSTANTS.Poin.CancelButtonClicked.attributes.employee_id]: employeeId,
      [AMPLITUDE_CONSTANTS.Poin.CancelButtonClicked.attributes.source]: 'terms_point_page',
      [AMPLITUDE_CONSTANTS.Poin.CancelButtonClicked.attributes.point_balance]: coinBalance,
    });
    setModalState(false);
  };

  useEffect(() => {
    if (isFocused) {
      setOffset(-1);
      getTotalPoints();
      getCoins({
        variables: {
          id: employeeId,
          limit: MAX_PAGE_LIMIT,
          offset: 0,
        },
      });
    }
    return () => dispatch(setCoinList([]));
  }, [isFocused]);

  useEffect(() => {
    // [CORE-1282]: Removing as part of product feedback for PWA */
    // getMarketingBannerData();
  }, []);

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.Poin.PoinPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.poin_balance]: coinBalance,
      [e.attributes.source]: source || AMPLITUDE_EVENT_SOURCES.poin_page,
    });
  }, []);

  const navigateToBp = (item, index) => {
    const findPointsAccount = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.POINTS);
    if (findPointsAccount) dispatch(setCurrentBpSelectedAccount(findPointsAccount));
    if (index == 3 || !item) {
      navigation.navigate('Pembayaran', {
        screen: 'Billpayment',
        initial: false,
        params: {
          screen: 'BillPaymentTab',
        },
      });
      return;
    }
    let e = AMPLITUDE_CONSTANTS.Poin.productTile;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(item.prefix),
      [e.attributes.source]: 'point_page',
    });
    navigation.navigate('Pembayaran', {
      screen: 'Product',
      initial: false,
      params: {
        useCoin: true,
        item,
      },
    });
  };

  // const onCurrentTierPress = () => {
  //   const e = AMPLITUDE_CONSTANTS.missions.mission_card_click;
  //   AmplitudeHelper.logEvent(e.name, {
  //     [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.poin_page,
  //   });
  //   navigation.navigate('Gamification', { source: AMPLITUDE_EVENT_SOURCES.poin_page });
  // };

  const shortBpList = useMemo(() => formatShorBPList(billpaymentList, coinBalance), [billpaymentList, coinBalance]);

  const header = useCallback(
    () => (
      <View>
        {/* // [CORE-1282]: Removing as part of product feedback for PWA */}
        {/* {isFeatureAvailale[FEATURE_FLAGS.GAMIFICATION].show && currentTier.length > 0 && tierMissions.length > 0 && (
          <CurrentTier
            totalMissions={tierMissions.length}
            noOfCompleted={noOfCompleted}
            isComingSoon={tierMissions.length === 0}
            srNo={currentTier[0].tier.displayOrder}
            onPress={onCurrentTierPress}
          />
        )} */}
        {billpaymentList.length > 0 && (
          <ShortBillPayment list={shortBpList} onPress={(item, index) => navigateToBp(item, index)} />
        )}
        {/* // [CORE-1282]: Removing as part of product feedback for PWA */}
        {/* <MyText h4 customStyle={{ paddingHorizontal: 15, marginTop: 10 }}>
          Tertarik untuk tambah poin lagi?{' '}
        </MyText> */}
        <MyText h1 customStyle={{ color: colors.textGrey, paddingHorizontal: 15 }}>
          Transaksi dengan GajiGesa
        </MyText>
        {/* [CORE-1282]: Removing as part of product feedback for PWA */}
        {/* <MarketingBanner
          hideLoader={!loadingMarketingBanners}
          data={marketingBanners}
          setData={setMarketingBanners}
          eventSource={'poin_page'}
          coinBalance={coinBalance}
          navigation={navigation}
        /> */}
        <MyText h4 customStyle={{ paddingHorizontal: 15, paddingVertical: 5 }}>
          Riwayat GajiGesa Poin Kamu
        </MyText>
      </View>
    ),
    [marketingBanners, loadingMarketingBanners, billpaymentList, shortBpList],
  );

  const ModalHandler = useCallback(
    () => (
      <MyModal containerStyle={style.turangModalContainer} onPress={closeModal} isVisible={isModalOpen}>
        <View
          style={{
            backgroundColor: colors.white,
            borderTopStartRadius: 8,
            borderTopEndRadius: 8,
          }}
        >
          <TouchableOpacity style={{ alignSelf: 'flex-end', marginRight: 10, marginVertical: 15 }} onPress={closeModal}>
            <Close name="close" onPress={closeModal} size={20} color={colors.black} />
          </TouchableOpacity>

          <View style={{ marginHorizontal: 16, marginBottom: 37 }}>
            {/* {headerModal()} */}
            <MyText customStyle={style.modalTitleLable}>Syarat & Ketentuan:</MyText>
            <MyText customStyle={[style.modalSubText, { marginTop: 4 }]}>1 GajiGesa Poin senilai 1 Rupiah</MyText>
            <MyText customStyle={[style.modalSubText, { marginTop: 20 }]}>
              GajiGesa Poin hanya bisa digunakan untuk membayar tagihan dan top-up saldo
            </MyText>
            <MyText customStyle={[style.modalSubText, { marginTop: 20 }]}>GajiGesa Poin tidak bisa diuangkan</MyText>
            <MyText customStyle={[style.modalSubText, { marginTop: 20 }]}>
              GajiGesa Poin tidak bisa digunakan untuk tarik gaji dan top-up e-money
            </MyText>
            {/* Confirm Button */}
            <LgButton
              style={style.buttonTurangStyle}
              textStyle={{ fontSize: normalize(14) }}
              testId="withdrawalButton"
              title="Tukar Sekarang"
              onPress={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.RedeemNowClicked.name, {
                  [AMPLITUDE_CONSTANTS.Poin.RedeemNowClicked.attributes.employee_id]: employeeId,
                  [AMPLITUDE_CONSTANTS.Poin.RedeemNowClicked.attributes.source]: 'terms_poin_page',
                  [AMPLITUDE_CONSTANTS.Poin.RedeemNowClicked.attributes.point_balance]: coinBalance,
                });
                closeModal();
                navigateToBp();
              }}
            />
          </View>
        </View>
      </MyModal>
    ),
    [isModalOpen],
  );

  const listEmpty = () => <MyText customStyle={{ marginStart: 15 }}>Belum ada transaksi dengan Poin</MyText>;

  const onPoinPressHandler = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Poin.RedeemPoinClicked.name, {
      [AMPLITUDE_CONSTANTS.Poin.RedeemPoinClicked.attributes.employee_id]: employeeId,
      [AMPLITUDE_CONSTANTS.Poin.RedeemPoinClicked.attributes.source]: 'terms_poin_page',
      [AMPLITUDE_CONSTANTS.Poin.RedeemPoinClicked.attributes.point_balance]: coinBalance,
    });

    if (coinBalance === 0) {
      dispatch(respError('GajiGesa Poin tidak mencukupi untuk bayar tagihan'));
      return;
    }

    if (shortBpList.length === 0) {
      dispatch(respError('Pembayaran tagihan dengan GajiGesa Poin hanya berlaku di perusahaan tertentu'));
      return;
    }

    setModalState(true);
  };

  return (
    <View style={style.mainContainer}>
      <View style={style.poinsContainer}>
        <PoinCard coinBalance={coinBalance} onPoinPressHandler={onPoinPressHandler} />
      </View>

      <FlatList
        refreshControl={<RefreshControl refreshing={refresh} colors={[colors.blue]} onRefresh={onRefresh} />}
        data={coinList}
        ListHeaderComponent={header}
        keyExtractor={({ item, index }) => item?.createdAt ?? index}
        renderItem={TransItem}
        style={{ margin: 0, backgroundColor: 'white' }}
        contentContainerStyle={{ borderColor: '#d4d4d4' }}
        onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false)}
        onEndReached={onEndReachHandle}
        ListFooterComponent={footer}
        onEndReachedThreshold={0.4}
        ListEmptyComponent={listEmpty}
      />
      <ModalHandler />
    </View>
  );
};

const style = StyleSheet.create({
  titbpTitlele: {
    textAlign: 'center',
  },
  bpItemContainer: {
    width: '20%',
    marginEnd: 22,
    marginVertical: 20,
    alignItems: 'center',
  },
  product: {
    justifyContent: 'center',
    alignItems: 'center',
    height: normalizeHeight(50),
    width: '87%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    borderRadius: normalize(12),
    marginBottom: 8,
    backgroundColor: colors.white,
  },
  image: {
    height: normalizeHeight(25),
    width: normalize(25),
    alignSelf: 'center',
  },

  bpTitle: {
    flex: 1,
    textAlign: 'center',
  },
  iconContainer: {
    height: 40,
    width: 40,
    borderRadius: 40 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.blue,
    marginEnd: 8,
  },
  titleCard: {
    backgroundColor: '#FFF',
    borderRadius: 12,
    paddingHorizontal: 15,
    paddingVertical: 0,
    width: '90%',
    marginHorizontal: 15,

    marginVertical: 10,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  turangModalContainer: {
    margin: 0,
    alignItems: undefined,
    flex: 1,
    paddingTop: '75%',
    justifyContent: 'flex-end',
    borderRadius: 8,
  },
  buttonTurangStyle: { marginTop: 40, width: '60%', alignSelf: 'center' },
  modalSubText: { color: '#000000', fontFamily: Font.Nunito_Regular, fontSize: normalize(12) },

  modalTitleLable: { color: '#454545', fontFamily: Font.Nunito_Bold, fontSize: normalize(14) },
  modalTukarSarang: {
    margin: 0,
    alignItems: undefined,
    flex: 1,
    paddingTop: '85%',
    justifyContent: 'flex-end',
    borderRadius: 8,
  },
  modalContainerSarang: {
    height: '100%',
    marginTop: 100,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  title: {
    fontSize: normalize(14),
    fontFamily: 'Nunito-Bold',
    color: '#454545',
  },
  expiry: {
    fontSize: normalize(12),
    fontFamily: 'Nunito-Regular',
    color: '#979797',
  },
  amount: {
    fontSize: normalize(22),
    fontFamily: 'Nunito-Bold',
    color: '#454545',
  },
  buttonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    paddingVertical: 10,
    backgroundColor: 'white',
  },
  itemContainer: {
    backgroundColor: '#FFF',
    paddingHorizontal: 15,
    paddingVertical: 10,
    height: 'auto',
    width: '100%',
    borderTopWidth: 1,
    borderColor: '#d4d4d4',

    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  poinsContainer: { paddingVertical: '2%', backgroundColor: colors.secondaryColor },
  mainContainer: { flex: 1, backgroundColor: colors.white },
});

export default Points;
