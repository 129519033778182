import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { formatUnderScoreLowerCase, numberWithDots } from '@utils/UtilFunctions';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '@services/BillPaymentApi';
import { setPageFailed } from '@redux/action/Common';

/* Styles */
import { LgButton, BillPaymentProductCard } from '@components/Button';
import { normalize, normalizeHeight } from '@style/helper';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';

const Listrik = ({ dataList, show, onPress }) => {
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const userAccountNumber = useSelector((state) => state.Transactions.userAccountNumber);
  const dispatch = useDispatch();

  if (!show) {
    return <></>;
  }

  const ListrikItem = (row) => {
    return (
      <BillPaymentProductCard
        alignment={true}
        prop={{
          ...row.item,
          nominal: numberWithDots(Number.parseInt(row.item.code.match(/(\d+)/)) * 1000),
          amount:
            row.item.code == 'PLSID20'
              ? numberWithDots(Number.parseInt(row.item.code.match(/(\d+)/)) * 1000)
              : numberWithDots(row.item.amount),
        }}
        cardStyle={styles.card}
        titleStyle={styles.text}
        subTitleStyle={styles.subTitle}
        onPress={() => {
          onPress(row, userAccountNumber);
        }}
        selected={JSON.stringify(row.item) == JSON.stringify(selectedProduct)}
      />
    );
  };

  const handleConfirm = () => {
    if (userAccountNumber.length < 10) {
      dispatch(setPageFailed('Nomor meter minimal 10 digit.'));
    } else {
      dispatch(selectProduct(selectedProduct, userAccountNumber));
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Pilih Nominal</Text>
      <FlatList data={dataList['PLN Prepaid']} renderItem={ListrikItem} numColumns={2} />

      {userAccountNumber == '' ? (
        <LgButton title="Konfirmasi" disabled style={styles.buttonDisabled} />
      ) : (
        <LgButton
          testId={'listrikKonfirmasiButton'}
          title="Konfirmasi"
          onPress={handleConfirm}
          style={styles.buttonEnabled}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '90%',
  },
  title: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(18),
    fontFamily: 'Nunito-Bold',
    marginBottom: normalizeHeight(10),
    marginLeft: normalize(10),
    marginTop: normalizeHeight(5),
  },
  buttonDisabled: {
    backgroundColor: '#F89F92',
    alignSelf: 'center',
    width: normalize(200),
    marginBottom: normalizeHeight(15),
  },
  buttonEnabled: { alignSelf: 'center', width: normalize(200), marginBottom: normalizeHeight(15) },
  card: { width: '45%', marginBottom: normalizeHeight(10), marginHorizontal: normalize(5) },
  text: { flex: 1, fontSize: normalize(24), fontWeight: 'bold', marginBottom: 0 },
  subTitle: { flex: 1, fontSize: normalize(12) },
});

export default Listrik;
