import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '90%',
    paddingRight: normalize(35),
  },
  pathStyle: {
    height: normalizeHeight(4),
    borderRadius: normalize(12),
    marginRight: normalize(4),
    width: normalize(30),
    backgroundColor: colors.white,
  },
});

export default styles;
