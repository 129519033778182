import { colors } from '@style/colors';
import React, { useEffect, useState, useRef } from 'react';
import { ActivityIndicator, Animated, View, StyleSheet } from 'react-native';
// import LottieView from 'lottie-react-native';

const Loading = ({ style }) => {
  const progress = useRef(new Animated.Value(0));
  const loadingRef = useRef();

  useEffect(() => {
    cycleAnimation();
  }, []);

  const cycleAnimation = () => {
    Animated.sequence([
      Animated.timing(progress.current, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          progress.current.setValue(0);
          cycleAnimation();
        }
      }),
    ]);
  };

  return (
    // <View />
    // <LottieView
    //   style={{ ...style }}
    //   loop
    //   ref={loadingRef}
    //   progress={progress.current}
    //   source={require('@assets/7899-loading.json')}
    // />
    <View style={styles.activityContainerStyle}>
      <ActivityIndicator size={100} color={colors.orange} style={style} />
    </View>
  );
};

const styles = StyleSheet.create({
  activityContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: colors.white,
  },
});

export default Loading;
