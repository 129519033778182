import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
const styles = StyleSheet.create({
  disabledButton: { backgroundColor: colors.gainsboro },
  outerContainer: {
    flex: 1,
    backgroundColor: colors.blue,
  },
  innerContainer: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    zIndex: 10,
    backgroundColor: colors.white,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    marginTop: 5,
    padding: 20,
  },
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    paddingVertical: 10,
    justifyContent: 'center',
    borderRadius: 30,
    marginVertical: 15,
    width: '100%',
  },
});

export default styles;
