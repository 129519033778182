// import messaging from '@react-native-firebase/messaging';
// import { PermissionsAndroid, Platform } from 'react-native';
// import RNAndroidLocationEnabler from 'react-native-android-location-enabler';
// import Geolocation from 'react-native-geolocation-service';

// Request permission function
// const requestPermission = async () => {
//    const authStatus = await messaging().requestPermission()
//    const isActive = authStatus == messaging.AuthorizationStatus.AUTHORIZED || authStatus == messaging.AuthorizationStatus.PROVISIONAL
//    if(isActive){
//     getFCMToken()
//    }
// }

// Get Token function
// const getFCMToken = async () => {
//     const fcmToken = await messaging().getToken()
//     if(fcmToken){
//         //do nothing
//         return
//     } else {
//         console.log('FCM Token failed')
//     }
// }

export const LocationPermission = async () => {
  //   let isAndroid = Platform.OS === 'android';
  //   if (isAndroid) {
  //     return RNAndroidLocationEnabler.promptForEnableLocationIfNeeded({
  //       interval: 10000,
  //       fastInterval: 5000,
  //     })
  //       .then(async (resp) => {
  //         if (resp === 'enabled' || resp === 'already-enabled') {
  //           if (isAndroid && Platform.Version < 23) {
  //             return true;
  //           }
  //           const hasPermission = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
  //           if (hasPermission) return true;
  //           const status = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
  //           if (status === PermissionsAndroid.RESULTS.GRANTED) return true;
  //           if (status === PermissionsAndroid.RESULTS.DENIED) {
  //             return false;
  //           } else if (status === PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN) {
  //             return false;
  //           }
  //           return false;
  //         }
  //       })
  //       .catch((err) => {
  //         return 'GO_AHEAD';
  //       });
  //   }
  // For IOS ========>
  // else {
  //     const hasLocationPermission = await Geolocation.requestAuthorization('always')
  //     if (hasLocationPermission == 'denied' || hasLocationPermission == 'disabled' || hasLocationPermission == 'restricted') {
  //         return false
  //     }
  //     return true
  // }
};

export const getCurrentLocationCoordinate = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     const hasPermission = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
  //     if (hasPermission) {
  //       Geolocation.getCurrentPosition(
  //         (position) => {
  //           const location = {
  //             latitude: position.coords.latitude,
  //             longitude: position.coords.longitude,
  //             error: null,
  //           };
  //           resolve(location);
  //         },
  //         (error) => {
  //           resolve({ latitude: null, longitude: null, error: error['message'] });
  //         },
  //         { timeout: 20000, maximumAge: 10000, enableHighAccuracy: true },
  //       );
  //       return;
  //     }
  //     const status = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
  //     if (status === PermissionsAndroid.RESULTS.GRANTED) {
  //     }
  //     if (status === PermissionsAndroid.RESULTS.DENIED) {
  //       reject({ latitude: null, longitude: null, error: 'Location access not given' });
  //     } else if (status === PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN) {
  //       reject({ latitude: null, longitude: null, error: 'Location access not given' });
  //     }
  //     reject({ latitude: null, longitude: null, error: 'Location access not given' });
  //   });
};

//export default requestPermission
