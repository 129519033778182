import AsyncStorage from "@react-native-async-storage/async-storage";
import { batch } from "react-redux";
import { respError, respSuccess } from "@redux/action/Common";
import { setIsTransacted, setIsValidReferral, setReferralError, setReferralLoading, setRedeemCodeLoading, setRedeemCode } from "@redux/action/Referral";
import { AMPLITUDE_CONSTANTS } from "@utils/AmplitudeConstants";
import { refferalDencryption } from "@utils/UtilFunctions";
import AmplitudeHelper from "./amplitude";
import mainAPI from "./api";

export const checkReferralStatus = (source='home_page', triggered = false) => {

    return async (dispatch, getState)=>{
        try {
            const { redeemCode:referrerId, isValidCode} = getState().Referral
            const employeeId = getState().Authentication.employeeId
            const data ={
                referrerId:refferalDencryption(referrerId),
                employeeId
            }
            if(referrerId.length>0 && !isValidCode){
                dispatch(setRedeemCodeLoading(true));
            }
            if(data.referrerId==employeeId.toString()){
                dispatch(respError('Kode tidak berlaku untuk transaksi pribadi'))
                dispatch(setRedeemCodeLoading(false))
                dispatch(setIsValidReferral(false, false))
                dispatch(setRedeemCode(''))
                let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_failed
                AmplitudeHelper.logEvent(e.name, {
                  [e.attributes.referral_code] : getState().Referral?.redeemCode ?? '',
                  [e.attributes.source] : source,
                  [e.attributes.failure_message] : 'user used their own code'
                })
                return
            }


            const response = await mainAPI(
              'referral/validateCode',
              "post",
              data
            );

          if(response.status===200 || response.status===201){
              batch(()=>{
                dispatch(setRedeemCodeLoading(false))
                dispatch(setReferralLoading(false))
                if(response.data.data==='Valid Referral Code'){
                    AsyncStorage.setItem('@redeemCode', JSON.stringify(referrerId))
                    dispatch(setIsValidReferral(true, false));

                    (!isValidCode && triggered) && dispatch(respSuccess('Hore! Kode promo sudah bisa digunakan'));

                    let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_successful
                    AmplitudeHelper.logEvent(e.name, {
                      [e.attributes.referral_code] : getState().Referral?.redeemCode ?? '',
                      [e.attributes.source] : source,
                    })
                    
                }else{
                    dispatch(setIsTransacted(false))
                    let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_applicable
                    AmplitudeHelper.logEvent(e.name, {
                      [e.attributes.source] : source,
                    })
                }
              })

          }
          return response
          } catch (error) {
            let message = ''
            batch(()=>{
                if(error?.response?.data?.error==='REFERRAL_CODE_NOT_APPLICABLE'){
                    if(getState().Referral.appOpenedFromDeeplink)
                    dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'))

                    dispatch(setIsTransacted(true))
                    dispatch(setRedeemCode(''))
                    AsyncStorage.setItem('@isRedeemed', JSON.stringify(true))
                    message = 'not first transaction'
                }else if(error?.response?.data?.error==='INVALID_REFERRAL_CODE'){
                    dispatch(setIsTransacted(false))
                    dispatch(setIsValidReferral(false, false))
                    dispatch(setRedeemCode(''))
                    dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'))
                    message = 'invalid code'
                }else{
                    dispatch(setRedeemCode(''))
                    const isTransacted = getState().Referral.isTransacted
                    if(isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'))
                    else dispatch(respError('Terjadi kesalahan sistem. Harap coba kembali.'))
                    message = 'system error'
                    
                }
                dispatch(setReferralLoading(false))
                dispatch(setRedeemCodeLoading(false))
            })

            let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_code_failed
            AmplitudeHelper.logEvent(e.name, {
              [e.attributes.referral_code] : getState().Referral?.redeemCode ?? '',
              [e.attributes.source] : source,
              [e.attributes.failure_message] : 'apply'
            })

           throw error 
          }
    }
 
  
  };