import { StyleSheet } from 'react-native';
import { normalize } from '@style/helper';
import { colors } from '@style/colors';
export const styles = StyleSheet.create({
  pressableContainer: {
    borderRadius: 20,
    overflow: 'hidden',
    marginEnd: 15,
  },
  referralPressable: {
    backgroundColor: '#FF8540',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: { color: 'black', flexDirection: 'row', alignItems: 'center' },
  gradientContainer: {
    flexDirection: 'row',

    marginRight: normalize(16),
    borderRadius: 20,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconView: {
    backgroundColor: '#DB8311',
    height: 30,
    width: 30,
    borderRadius: 30 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  textStyle: {
    fontSize: normalize(11),
    color: colors.white,
    marginLeft: normalize(6),
    marginRight: normalize(9),
  },
});
