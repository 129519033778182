import React from 'react';
import { Dimensions, ScrollView, StyleSheet, View, Text, Pressable, Linking } from 'react-native';
// import Loading from '@screens/Loading';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize } from '@style/helper';
import { COMMON_CONSTANTS } from '@utils/Constants';
import Icon from '@expo/vector-icons/Ionicons';
import { useSelector } from 'react-redux';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
const MainParentWrapper = (
  { children, isScrollable, loading, isError, onReload, containerStyle, isRounded = true, parentStyle, scrollProps },
  ref,
) => {
  const { loading: featureFlagLoading } = useSelector((state) => state.FeatureFlag);
  const onContact = () => {
    Linking.openURL(COMMON_CONSTANTS.support);
  };

  if (loading || featureFlagLoading) {
    return (
      <View style={[styles.container, parentStyle]}>
        <View
          style={[
            styles.subContainer,
            {
              borderTopStartRadius: isRounded ? 20 : 0,
              borderTopEndRadius: isRounded ? 20 : 0,
            },
          ]}
        >
          {/* <Loading /> */}
        </View>
      </View>
    );
  }

  if (isError) {
    return (
      <View
        style={[
          styles.containerError,
          {
            borderTopStartRadius: isRounded ? 20 : 0,
            borderTopEndRadius: isRounded ? 20 : 0,
          },
        ]}
      >
        <Text style={styles.text}>Mohon Maaf terjadi kesalahan sistem. Mohon muat ulang halaman.</Text>
        <View style={styles.buttonGroup}>
          <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onContact}>
            <Text style={styles.buttonText}>
              <Icon style={[styles.buttonText, { fontSize: 15 }]} name="logo-whatsapp" /> BANTUAN
            </Text>
          </Pressable>
          <Pressable android_ripple={{ color: '#d1d1d1' }} style={styles.button} onPress={onReload}>
            <Text style={styles.buttonText}>
              <Icon style={[styles.buttonText, { fontSize: 15 }]} name="refresh" /> MUAT ULANG
            </Text>
          </Pressable>
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.container, parentStyle]}>
      {isScrollable ? (
        <ScrollView
          ref={ref}
          bounces={false}
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={{
            ...styles.subContainer,
            borderTopStartRadius: isRounded ? 20 : 0,
            borderTopEndRadius: isRounded ? 20 : 0,
            flex: undefined,
            flexGrow: 1,
            paddingBottom: 100,
            ...containerStyle,
          }}
          style={{ height: 0 }}
          {...scrollProps}
        >
          {children}
        </ScrollView>
      ) : (
        <View
          style={[
            styles.subContainer,
            {
              borderTopStartRadius: isRounded ? 20 : 0,
              borderTopEndRadius: isRounded ? 20 : 0,
            },
            containerStyle,
          ]}
        >
          {children}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  containerError: {
    height: '100%',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  subContainer: {
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    backgroundColor: colors.white,
    width: '100%',
    flex: 1,
    paddingTop: 30,
  },
  container: {
    height: '30%',
    backgroundColor: colors.blue,
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
  },
  text: {
    color: '#9e9e9e',
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(14),
    textAlign: 'center',
    maxWidth: '75%',
  },
  buttonGroup: {
    marginVertical: '10%',
    width: '75%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    borderWidth: 1,
    borderColor: '#3863F0',
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
  },
  buttonText: {
    color: '#3863F0',
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(12),
    textAlign: 'center',
  },
});

export default React.forwardRef(MainParentWrapper);
