import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { colors } from '@style/colors';
import InputComponent from '@atoms/InputComponent';
import { numberWithDots } from '@utils/UtilFunctions';
import MyText from '@atoms/MyText';
// import { Modalize } from 'react-native-modalize';
import MainParentWrapper from '@components/MainParentWrapper';
import { useNavigation, useRoute } from '@react-navigation/native';
const { width, height } = Dimensions.get('screen');

const AccountDetails = () => {
  const route = useRoute();
  return (
    <MainParentWrapper isScrollable>
      <MyText isRegular={false} customStyle={{ marginStart: 10 }}>
        Kantong Dana
      </MyText>
      <View style={{ marginHorizontal: 10, marginTop: 5 }}>
        <InputComponent disabled title="Nama Kantong Dana" value={route.params.accountType} />
        <InputComponent disabled title="Jumlah Saldo" value={numberWithDots(Math.round(route.params.data.balance))} />
        <InputComponent hasColorBox color={colors.orange} title="Warna" value={route.params.accountType} />
      </View>
    </MainParentWrapper>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    height: 55,
    width: 55,
    borderRadius: 55 / 2,
    alignSelf: 'center',
    marginBottom: 10,
    backgroundColor: '#ECF0FF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    backgroundColor: '#ECF0FF',
    paddingHorizontal: 7,
    borderRadius: 20,
    paddingVertical: 2,
  },
  semiCircle: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    position: 'absolute',
    borderTopStartRadius: height / 2,
    borderTopEndRadius: height / 2,
    zIndex: 3,
    transform: [{ scaleX: 2 }],
    top: 30,
  },
  linearGradient: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
  },
  scrollView: {
    position: 'absolute',
    zIndex: 5,
    top: '15%',
    bottom: 0,
    start: 0,
    end: 0,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: 'Gill Sans',
    textAlign: 'center',
    margin: 10,
    color: '#ffffff',
    backgroundColor: 'transparent',
  },
});

export default AccountDetails;
