import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import AmplitudeHelper from '@services/amplitude';
import { colors } from '@style/colors';
import { normalize } from '@style/helper';
import { SAVINGS_TOUR_TYPES } from '@utils/Constants';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import { numberWithDots } from '@utils/UtilFunctions';
import IncomeExpenseItem from '@molecules/IncomeExpenseItem';
import ButtonColor from '@atoms/ButtonColor';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import CommonCard from './CommonCard';
import Final from './Final';
import View1 from './View1';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const TourModal = ({
  isVisible,
  onPressButtonColor,
  onClose,
  description,
  title,
  hasCongrats,
  setTourVisible,
  type,
  numOfDots,
  date,
  balance,
  totalIncome,
  totalExpense,
  accuredSalary,
}) => {
  const returnModalStyle = useCallback(() => {
    switch (type) {
      case SAVINGS_TOUR_TYPES.TYPE3:
        return { justifyContent: 'flex-start', margin: 0 };

      case SAVINGS_TOUR_TYPES.TYPE4:
        return { justifyContent: 'center' };
      case SAVINGS_TOUR_TYPES.TYPE5:
        return { justifyContent: 'center' };

      case SAVINGS_TOUR_TYPES.TYPE6:
        return { justifyContent: 'flex-start', margin: 0 };
      default:
        return { justifyContent: 'flex-end', paddingBottom: 80 };
    }
  }, [type]);

  const returnViewByType = useCallback(() => {
    switch (type) {
      case SAVINGS_TOUR_TYPES.TYPE1:
        return (
          <View style={styles.dashboardTourContainer}>
            <View style={{ width: '60%' }}>
              <MyText h5 isRegular={false} customStyle={{ color: colors.blue }}>
                Atur keuanganmu!
              </MyText>
              <MyText h2 customStyle={{ marginTop: 10 }}>
                Langkah mudah untuk mencatat dan menganalisa transaksimu.
              </MyText>
              <ButtonColor
                isRounded
                textStyle={{ fontSize: 10 }}
                buttonStyle={styles.button}
                title={'COBA SEKARANG'}
                onPress={() => {
                  AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.appTourSavings.name, { page: 1, action: 'click_cta' });
                  setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE2 });
                }}
              />
            </View>
            {SVG_ICONS.walletColor}
          </View>
        );

      case SAVINGS_TOUR_TYPES.TYPE2:
        return (
          <CommonCard
            customTextWidth={'70%'}
            activeDot={0}
            closeModal={() => {
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.appTourSavings.name, { page: 1, action: 'click_x' });
              onClose();
            }}
            onPressButton={() => {
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.appTourSavings.name, { page: 1, action: 'click_back' });
              setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE1 });
            }}
            onPressButtonColor={() => {
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.appTourSavings.name, { page: 1, action: 'click_cta' });
              onPressButtonColor();
            }}
            buttonTitle1={'Kembali'}
            buttonTitle2={'Coba Sekarang'}
            description={'Kini kamu bisa melihat langsung daftar transaksimu di menu "Transaksi".'}
            title={'Ada Fitur Baru!'}
            hasCongrats={hasCongrats}
            numOfDots={0}
          />
        );

      case SAVINGS_TOUR_TYPES.TYPE3:
        return (
          <>
            <View style={styles.balanceContainer}>
              <MyText isRegular={false} customStyle={{ color: colors.blue }} h5>
                Uang Kamu
              </MyText>
              <View style={{ alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <MyText customStyle={{ color: colors.blue }} h3 isRegular={false}>
                    Rp
                  </MyText>
                  <MyText customStyle={{ color: colors.blue }} h5 isRegular={false}>
                    {numberWithDots(balance)}
                  </MyText>
                </View>

                <MyText customStyle={{ marginTop: 5 }} h1>
                  {date}
                </MyText>
              </View>
            </View>
            <CommonCard
              container={{ margin: 10, width: '95%' }}
              numOfDots={numOfDots}
              customTextWidth={'70%'}
              activeDot={1}
              closeModal={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 1,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_x',
                });
                onClose();
              }}
              onPressButtonColor={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 1,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_next',
                });

                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE4 });
              }}
              buttonTitle2={'Lanjut'}
              description={'Jumlah uang ini merupakan total pemasukan dikurangin pengeluaranmu'}
              title={'Uang Kamu 💰'}
            />
          </>
        );

      case SAVINGS_TOUR_TYPES.TYPE4:
        return (
          <>
            <View
              style={{
                height: 60,
                backgroundColor: colors.white,
                marginBottom: 15,
                marginTop: '30%',
                alignSelf: 'flex-start',
                width: 150,
              }}
            >
              <IncomeExpenseItem
                hasInfo={false}
                container={{ marginEnd: 5 }}
                isIncome
                title={'Total Pendapatan'}
                amount={`Rp${numberWithDots(totalIncome)}`}
              />
            </View>
            <CommonCard
              numOfDots={numOfDots}
              customTextWidth={'60%'}
              activeDot={2}
              closeModal={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 2,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_x',
                });
                onClose();
              }}
              onPressButton={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 2,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_back',
                });
                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE3 });
              }}
              onPressButtonColor={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 2,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_next',
                });

                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE5 });
              }}
              buttonTitle1={'Kembali'}
              buttonTitle2={'Lanjut'}
              hasTwoButtons
              description={'Nilai uang ini merupakan jumlah transaksi masuk ditambah gaji proratamu'}
              title={'Total Pendapatan 📥'}
            />
          </>
        );

      case SAVINGS_TOUR_TYPES.TYPE5:
        return (
          <>
            <View
              style={{
                height: 60,
                backgroundColor: colors.white,
                marginBottom: 15,
                marginTop: '30%',
                alignSelf: 'flex-end',
                width: 150,
              }}
            >
              <IncomeExpenseItem
                hasInfo={false}
                title={'Total Pengeluaran'}
                amount={`Rp${numberWithDots(totalExpense)}`}
              />
            </View>
            <CommonCard
              numOfDots={numOfDots}
              customTextWidth={'60%'}
              activeDot={3}
              closeModal={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 3,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_x',
                });
                onClose();
              }}
              onPressButton={() => {
                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE4 });

                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 3,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_back',
                });
              }}
              onPressButtonColor={() => {
                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE6 });
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 3,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_next',
                });
              }}
              buttonTitle1={'Kembali'}
              buttonTitle2={'Lanjut'}
              hasTwoButtons
              description={'Nilai uang ini merupakan total transaksi keluarmu'}
              title={'Total Pengeluaran 📤'}
            />
          </>
        );
      case SAVINGS_TOUR_TYPES.TYPE6:
        return (
          <>
            <View style={styles.accuredContainer}>
              {SVG_ICONS.accuredSalary}
              <View style={{ marginStart: 10 }}>
                <MyText isRegular={false} h2>
                  {isNaN(Math.round(accuredSalary)) ? accuredSalary : `Rp${numberWithDots(Math.round(accuredSalary))}`}
                </MyText>
                <MyText h2>Gaji prorata berdasarkan Limit Penarikan Gaji</MyText>
              </View>
            </View>
            <CommonCard
              container={{ margin: 10, width: '95%' }}
              numOfDots={numOfDots}
              customTextWidth={'60%'}
              activeDot={3}
              closeModal={() => {
                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 4,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_x',
                });

                onClose();
              }}
              onPressButton={() => {
                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE5 });

                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 4,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_back',
                });
              }}
              onPressButtonColor={() => {
                setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE7 });

                AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 4,
                  [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_next',
                });
              }}
              buttonTitle1={'Kembali'}
              buttonTitle2={'Lanjut'}
              hasTwoButtons
              description={'Nilai uang ini merupakan total upah hasil kerjamu sampai hari ini'}
              title={'Gaji Proratamu 🔥'}
            />
          </>
        );

      case SAVINGS_TOUR_TYPES.TYPE7:
        return (
          <Final
            closeModal={() => {
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 5,
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_x',
              });
              onClose();
            }}
            onPressButton={() => {
              setTourVisible({ visible: true, type: SAVINGS_TOUR_TYPES.TYPE6 });
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 5,
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_back',
              });
            }}
            onPressButtonColor={() => {
              AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.name, {
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.page]: 5,
                [AMPLITUDE_CONSTANTS.AppTour.AppTourSavings1.attributes.action]: 'click_finish',
              });
              onPressButtonColor();
            }}
          />
        );

      default:
        return null;
    }
  }, [type]);

  return (
    <MyModal containerStyle={returnModalStyle()} isVisible={isVisible}>
      {returnViewByType()}
    </MyModal>
  );
};

const styles = StyleSheet.create({
  accuredContainer: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F1F1F1',
    marginTop: '80%',
    width: '100%',
    marginBottom: 10,
  },
  button: {
    marginStart: 0,
    backgroundColor: colors.orange,
    marginTop: 10,
    width: '60%',
  },
  dashboardTourContainer: {
    width: '100%',
    backgroundColor: colors.white,
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderRadius: 20,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  balanceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginHorizontal: 15,
    backgroundColor: colors.white,
    width: '100%',
    paddingHorizontal: 10,
    marginBottom: 10,
    paddingVertical: 5,
    marginTop: '30%',
  },
});

export default TourModal;
