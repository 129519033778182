import { StyleSheet, Dimensions } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  textItalic: {
    width: '80%',
    color: colors.red,
    fontStyle: 'italic',
    textAlignVertical: 'center',
  },
  container: {
    width: screenWidth / 1.1,
    backgroundColor: colors.white,
    borderRadius: 15,
    alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    marginTop: normalizeHeight(25),
    height: '100%',
  },
  amountContainer: {
    width: '100%',
    borderBottomWidth: 0.5,
    borderBottomColor: colors.lightGrey,
    paddingBottom: normalizeHeight(15),
  },
  amountDetails: {
    justifyContent: 'space-between',
    marginHorizontal: normalize(20),
    flexDirection: 'row',
    maxHeight: normalizeHeight(20),
    marginBottom: normalizeHeight(5),
  },
  cardTitle: {
    alignSelf: 'flex-start',
    color: '#3B6AC0',
    fontSize: normalize(20),
    lineHeight: normalizeHeight(27),
    fontFamily: Font.Nunito_Bold,
    marginVertical: normalizeHeight(15),
    paddingLeft: normalize(20),
  },
  amountText: {
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(15),
    lineHeight: normalizeHeight(20),
    color: colors.black,
  },
  detailContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  cardSubtitle: {
    color: colors.black,
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(18),
    lineHeight: normalizeHeight(25),
  },
  bankTitle: {
    marginLeft: normalize(25),
    color: colors.black,
    fontFamily: Font.Nunito_Regular,
    fontSize: normalize(15),
    lineHeight: normalizeHeight(20),
  },
  detailSection: {
    marginVertical: normalizeHeight(20),
    marginLeft: normalize(25),
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedReason: {
    fontSize: normalize(15),
    fontFamily: Font.Nunito_Bold,
    maxWidth: normalize(150),
    color: colors.blue,
  },
  termsContainer: {
    flexDirection: 'row',
    width: '90%',
    marginVertical: 10,
  },
  loadingContainer: {
    position: 'absolute',
    height: screenHeight,
    width: screenWidth,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
});

export default styles;
