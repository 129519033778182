import { StyleSheet } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize, normalizeHeight } from "@style/helper";

export const styles = StyleSheet.create({
    logo: {

        padding: 30, 
        alignItems: 'center', 
        justifyContent: 'center', 
        display: 'flex', 
        alignSelf: 'center', 
        marginTop: normalizeHeight(20),
        marginRight:10,
        
      },
      container:{
          justifyContent:'center', 
          alignItems:'center'
      }

})