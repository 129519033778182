import React, { useState, useEffect } from 'react';
import { Text, View, FlatList, ScrollView, StyleSheet } from 'react-native';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import AmplitudeHelper from '@services/amplitude';
import * as Sentry from '@sentry/browser';

// API
import { requestCategory } from '@services/BillPaymentApi';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { respError } from '@redux/action/Common';

/* Styles */
import { backgroundCard } from '@style/Themes';
import { centerView, subTitleText } from '@style/Layouts';
import { CardIconButton } from '@components/Button';
import { normalize } from '@style/helper';
/* Components */
import ParentWrapper from '@components/ParentWrapper';
import { getFragmentFromSelection } from '@apollo/client/utilities';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const BillPayment = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState({});
  const { employeeId } = useSelector((state) => state.Authentication);

  useEffect(() => {
    if (isFocused) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.pembayaranPage;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: 'other_product_page',
      });
    }
    refreshPage();
  }, [isFocused]);

  const refreshPage = async () => {
    try {
      setError(false);
      setLoading(true);
      await getCategory();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const getCategory = async () => {
    try {
      let resp = await requestCategory();
      if (resp.status) {
        setLoading(false);
        if (JSON.stringify(categories) != JSON.stringify(resp.data.data)) {
          setCategories(resp.data.data);
        }
      } else {
        setLoading(false);
        dispatch(respError(resp.response.data.message));
      }
    } catch (e) {
      if (e.response?.status == 401) {
        setLoading(false);
        throw e;
        // dispatch(autoLogout())
      } else {
        setLoading(false);
        throw e;
        // dispatch(autoLogout())
      }
    }
  };

  return (
    <>
      <View style={{ ...centerView, backgroundColor: '#3863F0' }}>
        <View style={backgroundCard}>
          <ParentWrapper loading={loading} isError={error} onReload={refreshPage}>
            <ScrollView>
              <FlatList
                data={categories && Object.keys(categories)}
                renderItem={(section) => {
                  return (
                    <>
                      <Text style={[styles.sectionTitle, subTitleText]}>{section.item}</Text>
                      <View style={styles.sectionContainer}>
                        <FlatList
                          data={categories[section.item]}
                          renderItem={(item) => {
                            return (
                              <CardIconButton
                                prop={item?.item}
                                cardStyle={{ flex: 1 }}
                                iconStyle={{ flex: 1 }}
                                onPress={() => {
                                  let e = AMPLITUDE_CONSTANTS.BillPayment.userClickedProduct;
                                  AmplitudeHelper.logEvent(e.name, {
                                    [e.attributes.source]: 'other_product_page',
                                    // [e.attributes.employee_id]:employeeId,
                                    [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(
                                      item?.item?.prefix,
                                    ),
                                  });

                                  navigation.navigate('Product', {
                                    item: item?.item,
                                    useCoin: route.params?.useCoin ?? false,
                                  });
                                }}
                              />
                            );
                          }}
                          numColumns={4}
                          persistentScrollbar
                        />
                      </View>
                    </>
                  );
                }}
              />
            </ScrollView>
          </ParentWrapper>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  sectionTitle: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(18),
    fontFamily: Font.Nunito_Bold,
    marginBottom: 0,
    marginTop: 5,
  },
  sectionContainer: {
    width: '90%',
    flex: 1,
    alignSelf: 'center',
  },
});

export default BillPayment;
