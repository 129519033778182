import React from 'react';
import { createStackNavigator } from '@react-navigation/stack'


/* Screens in stack */
import Bantuan from '@screens/Bantuan'

/* Styles import */
import { ScreenOptions } from '@style/Themes'

const Stack = createStackNavigator();

const BantuanStack = () => {

  return (
      <Stack.Navigator>
        <Stack.Screen name="Bantuan" component={Bantuan} options={ScreenOptions}/>
       </Stack.Navigator>
  )
}

export default BantuanStack
