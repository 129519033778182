import React from 'react';
import { View, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import Close from '@expo/vector-icons/AntDesign';
import { colors } from '@style/colors';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import TransactionItem from '@molecules/TransactionItem';
import ButtonColor from '@atoms/ButtonColor';
import MyText from '@atoms/MyText';
import TourDot from './TourDot';

const DATA = [
  {
    transReason: 'Belanja Bulanan/Sembako',
    transactionReasonId: 6,
    transactionCategoryId: 'WD-01',
    transStatus: 'PENDING',
    transAmount: '500000.00',
    transRequestedAmount: '50000.00',
    transReceivedAmount: 50000,
    transFee: '0.0000',
    notes: 'WITHDRAWAL',
    moneyAccount: 'GajiGesa',
    transDate: '2022-03-24T15:14:38.000Z',
  },
  {
    transReason: 'Makanan & Minuman',
    transactionReasonId: 6,
    transactionCategoryId: 'WD-01',
    transStatus: 'FAILED',
    transAmount: '350000.00',
    transRequestedAmount: '50000.00',
    transReceivedAmount: 50000,
    transFee: '0.0000',
    notes: '',
    moneyAccount: 'GajiGesa',
    transDate: '2022-03-24T15:14:38.000Z',
    position: 'DECREASE',
  },
];

const Final = ({ closeModal, onPressButtonColor, onPressButton }) => {
  const renderItem = ({ item }) => {
    return <TransactionItem container={{ paddingHorizontal: 10 }} item={item} disabled />;
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={{ alignSelf: 'flex-end', marginVertical: 5 }} onPress={closeModal}>
        <Close name="close" size={20} color={colors.grey} />
      </TouchableOpacity>
      <View style={styles.subContainer}>
        <View style={{ alignSelf: 'center' }}>{SVG_ICONS.walletColorSmall}</View>

        <MyText h5 isRegular={false} customStyle={styles.title}>
          Rincian Transaksi
        </MyText>
        <MyText customStyle={{ textAlign: 'center' }}>Kamu bisa detail dari riwayat transaksi finansialmu.</MyText>
        <MyText customStyle={styles.textDots}>- - -</MyText>
        <View>
          <View style={styles.transactionContainer}>
            <View style={styles.accuredContainer}>
              {SVG_ICONS.accuredSalary}
              <View style={{ marginStart: 10 }}>
                <MyText isRegular={false} h2>
                  Rp1.000.000
                </MyText>
                <MyText h2>Gaji Prorata Anda Hingga Hari Ini</MyText>
              </View>
            </View>
            <FlatList data={DATA} keyExtractor={(item, index) => index.toString()} renderItem={renderItem} />
          </View>
        </View>
        <View style={styles.dotContainer}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {new Array(5).fill('x').map((e, i) => {
              return <TourDot key={i} hasMarginStart={i > 0} active={5 === i + 1} />;
            })}
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity onPress={onPressButton} style={{ marginEnd: 5 }}>
              <MyText h2>Kembali</MyText>
            </TouchableOpacity>
            <ButtonColor textStyle={{ fontSize: 12 }} title={'Selesai'} onPress={onPressButtonColor} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  transactionContainer: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 1,
    borderColor: colors.grey,
    width: '100%',
  },
  accuredContainer: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F1F1F1',

    width: '100%',
    marginBottom: 10,
  },
  textDots: {
    transform: [{ rotate: '90deg' }],
    marginTop: 8,
    alignSelf: 'center',
  },
  title: {
    color: colors.green,
    marginVertical: 10,
    alignSelf: 'center',
  },
  subContainer: {
    width: '100%',
  },
  container: {
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    maxHeight: '90%',
  },
});

export default Final;
