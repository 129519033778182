import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FlatList,
  Image,
  Pressable,
  ScrollView,
  SectionList,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { SvgUri } from 'react-native-svg';
import BillPaymentWrapper from '@components/BillPaymentWrapper';
import Divider from '@atoms/Divider';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import {
  BP_DYNAMIC_ACCOUNTS,
  DYNAMIC_ACCOUNTS_TYPE,
  DYNAMIC_ACCOUNT_NAMES,
  FEATURE_FLAGS,
  TOOLTIP_CONTENT,
  VOUCHER_BILLER_DATA,
  VOUCHER_TEXT_CONSTANTS,
} from '@utils/Constants';
import {
  accountByRuleVoucherScreen,
  formatUnderScoreLowerCase,
  getAvailableBalance,
  getDynamicAccountListByAccount,
  getListOfDynamicAccounts,
  numberWithDots,
  removeNonNumeric,
  setBillPaymentReason,
  subtractFromWorkAllowance,
} from '@utils/UtilFunctions';
import Clock from '@expo/vector-icons/Feather';
import Dot from '@expo/vector-icons/Entypo';
import styles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBpTransaction, preCheckBp, redeemPoins, requestBillPaymentTransaction } from '@services/TransactionApi';
import { respError } from '@redux/action/Common';
import Collapsible from 'react-native-collapsible';
import RequestTransactionSheet from '@components/RequestTransactionSheet';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import LoadingModal from '@components/LoadingModal';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { clearReferral, setIsTransacted, setIsValidReferral, setRedeemCode } from '@redux/action/Referral';
import { store } from '@enhancedStore/store';
import { setCoinBalance } from '@redux/action/Coins';
import useFeatureFlag from '@gaji-gesa/gg-ui-shared/src/customHooks/useFeatureFlag';
import { setDataAccounts } from '@redux/action/DynamicAccounts';
import { useNavigation, useRoute } from '@react-navigation/native';

const STEPS = {
  STEP1: 'STEP1',
  STEP2: 'STEP2',
  STEP3: 'STEP3',
};

const CollapsibleItem = ({ list, isCollapsed, setIsCollapsed, title }) => {
  const firstListElement = list.slice()[0];
  const withoutFirstElement = list.slice();
  withoutFirstElement.splice(0, 1);
  return (
    <>
      <MyText isRegular={false} customStyle={{ marginStart: 15 }}>
        {title}
      </MyText>
      <MyText h2 customStyle={{ width: '80%', marginStart: 15, marginVertical: 5 }}>
        <Dot name="dot-single" size={15} />
        {firstListElement}
      </MyText>
      <Collapsible collapsed={isCollapsed}>
        {withoutFirstElement.map((e, i) => {
          return (
            <MyText key={i} h2 customStyle={{ width: '80%', marginStart: 15, marginVertical: 5 }}>
              <Dot name="dot-single" size={15} />
              {e}
            </MyText>
          );
        })}
      </Collapsible>
      <TouchableOpacity style={{ marginStart: 15, marginTop: 5 }} onPress={() => setIsCollapsed(!isCollapsed)}>
        <MyText h2 customStyle={{ color: colors.blue }}>
          {isCollapsed ? 'Selengkapnya' : 'Sembunyikan'}
        </MyText>
      </TouchableOpacity>
    </>
  );
};

const returnContent = (title) => {
  switch (title) {
    case 'Voucher Grab Transport':
      return VOUCHER_TEXT_CONSTANTS['textGrab'];

    case 'Voucher Alfamart':
      return VOUCHER_TEXT_CONSTANTS['textAlfamart'];

    case 'Voucher Grab Food':
      return VOUCHER_TEXT_CONSTANTS['textGrabFood'];

    case 'Voucher Indomaret':
      return VOUCHER_TEXT_CONSTANTS['textIndomaret'];

    case 'Voucher Halodoc':
      return VOUCHER_TEXT_CONSTANTS['textHalodoc'];

    case 'Voucher Sayur Box':
      return VOUCHER_TEXT_CONSTANTS['textSayurbox'];

    case 'Go Car':
      return VOUCHER_TEXT_CONSTANTS['gocar'];

    case 'Go Food':
      return VOUCHER_TEXT_CONSTANTS['gofood'];

    case 'Go Ride':
      return VOUCHER_TEXT_CONSTANTS['goride'];

    case 'Kopi Dari Pada':
      return VOUCHER_TEXT_CONSTANTS['kopidaripada'];

    case 'Kopitiam':
      return VOUCHER_TEXT_CONSTANTS['kopitiam'];

    case 'KFC':
      return VOUCHER_TEXT_CONSTANTS['kfc'];

    case 'Yoshinoya':
      return VOUCHER_TEXT_CONSTANTS['yoshinoya'];

    case 'Voucher Ta Wan':
      return VOUCHER_TEXT_CONSTANTS['tawan'];

    case 'Moon Chicken':
      return VOUCHER_TEXT_CONSTANTS['moonchicken'];

    case 'Accha Resto':
      return VOUCHER_TEXT_CONSTANTS['acharesto'];

    case 'Nasi Ayam Kulit Bude Sari':
      return VOUCHER_TEXT_CONSTANTS['nasiayam'];

    case 'Fish Streat':
      return VOUCHER_TEXT_CONSTANTS['fishstreat'];

    case 'Kulina':
      return VOUCHER_TEXT_CONSTANTS['kulina'];

    case 'San Gyu':
      return VOUCHER_TEXT_CONSTANTS['sangyu'];

    case 'Carrefour Transmart':
      return VOUCHER_TEXT_CONSTANTS['carrefour'];

    default:
      break;
  }
};

const VOUCHER_CONFIG = {
  categoryName: 'Kebutuhan Sehari-hari',
  prefix: 'BP-13',
};

const VoucherScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const {
    data: { title, imageUrl, data },
    useCoin,
  } = route.params;
  const { isValidCode, redeemCode, isTransacted } = useSelector((state) => state.Referral);
  const { coinBalance } = useSelector((state) => state.Coins);
  const {
    usedBalance,
    todayBalanceFloor,
    workingDays,
    workedDays,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    lockPeriod,
  } = useSelector((state) => state.Withdrawal);

  const [referralToolTip, setReferralToolTip] = useState({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
  const [referralPrecheckFail, setReferralPrecheckFail] = useState(false);
  const { dynamicAccounts, dataAccounts, currentBpSelectedAccount } = useSelector((state) => state.DynamicAccounts);
  const { workAllowanceBillPaymentList } = useSelector((state) => state.NewBillPayment);
  const currentSelectedAccountName = currentBpSelectedAccount.constantAccountName;
  const selectedItem = useMemo(() => {
    const findIndex = data.findIndex((e) => e?.isSelected);
    if (findIndex !== -1) {
      return data[findIndex];
    }
    return null;
  }, []);

  const dispatch = useDispatch();

  const newAvailableBalance = useMemo(() => {
    return getAvailableBalance(
      salaryWithCappedAmount,
      todayBalanceFloor,
      workingDays,
      workedDays,
      maxWithdrawalAmount,
      usedBalance,
    );
  }, [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance]);

  const [accounts, setAccounts] = useState([]);

  const dynamicAccountDetails = useMemo(() => getListOfDynamicAccounts(dynamicAccounts), [dynamicAccounts]);

  const accountByRule = useCallback(
    accountByRuleVoucherScreen(
      dataAccounts,
      lockPeriod,
      newAvailableBalance,
      dynamicAccountDetails,
      currentSelectedAccountName,
    ),
    [lockPeriod, newAvailableBalance, dynamicAccountDetails, dataAccounts],
  );

  const getAccoutByTxnResponse = useCallback(
    (response) => {
      let isFromPoins = useCoin && coinBalance > 0;
      let isSubtotalWithinPoin = response.data.data.subtotal <= coinBalance;
      let isSubtotalLessOfEWABalance = response.data.data.subtotal > newAvailableBalance;
      if (lockPeriod || (isFromPoins && isSubtotalWithinPoin) || isSubtotalLessOfEWABalance) return accounts[1];
      return accounts[0];
    },
    [coinBalance, useCoin, newAvailableBalance],
  );

  const [loadingTransactionReq, setLoadingTransactionReq] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(accountByRule());
  const { phoneNumber } = useSelector((state) => state.Authentication);
  const [transactionReq, setTransactionReq] = useState();
  const [transactionReqModal, setTransactionReqModal] = useState(false);

  const setAccountList = useCallback(() => {
    const dynamicAccountList = getDynamicAccountListByAccount(
      dataAccounts,
      BP_DYNAMIC_ACCOUNTS,
      DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    );

    // Checking if current selected product category is eligible for work allowance
    let productFoundUnderWorkAllowance = false;
    let accountList = [];
    const findCategory = workAllowanceBillPaymentList.find((e) => e.title === VOUCHER_CONFIG.categoryName);
    if (findCategory) {
      const findProduct = findCategory.data[0].list.findIndex((e) => e.prefix === VOUCHER_CONFIG.prefix);
      if (findProduct !== -1) {
        accountList = dynamicAccountList;
        setAccounts(dynamicAccountList);
        productFoundUnderWorkAllowance = true;
      } else {
        accountList = dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE);
        setAccounts(accountList);
      }
    } else {
      accountList = dynamicAccountList;
      setAccounts(dynamicAccountList.filter((e) => e.constantAccountName !== DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE));
    }

    setSelectedAccount(accountByRule(productFoundUnderWorkAllowance, accountList));
  }, [dataAccounts, workAllowanceBillPaymentList]);

  useEffect(() => {
    setAccountList();
  }, [dataAccounts]);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(true);
  const [step3, setStep3] = useState(true);
  const [isFeatureAvailale] = useFeatureFlag({ featureName: FEATURE_FLAGS.DYNAMIC_ACCOUNTS_POINS });

  const checkBlackoutPeriod = () => {
    if (lockPeriod && coinBalance == 0) {
      setLoadingTransactionReq(false);
      dispatch(respError('Transaksi hanya bisa dilakukan pada masa penarikan'));
      return true;
    }
    return false;
  };

  const referralPrecheck = useCallback(async () => {
    try {
      referralPrecheckFail && setReferralPrecheckFail(false);
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
      if (checkBlackoutPeriod()) return;
      setLoadingTransactionReq(true);
      const response = await preCheckBp(selectedItem, phoneNumber, redeemCode);
      requestSelectedTransaction();
    } catch (error) {
      setReferralPrecheckFail(true);
      setReferralToolTip({ visible: true, message: error?.response?.data?.message });
      requestSelectedTransaction();
    }
  }, [phoneNumber, redeemCode, referralPrecheckFail, selectedItem, accounts, coinBalance, selectedAccount, lockPeriod]);

  const requestSelectedTransaction = useCallback(async () => {
    try {
      setLoadingTransactionReq(true);
      const response = await requestBillPaymentTransaction(
        selectedItem,
        phoneNumber,
        selectedAccount.constantAccountName,
        dynamicAccounts,
      );

      setTransactionReq(response.data.data);
      setLoadingTransactionReq(false);
      //setSelectedAccount(getAccoutByTxnResponse(response));
      setTransactionReqModal(true);
      let e = AMPLITUDE_CONSTANTS.BillPayment.billPaymentTransactionSummary;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.account_number]: phoneNumber,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem.categoryPrefix),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code),
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    } catch (error) {
      setLoadingTransactionReq(false);
      dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
    }
  }, [phoneNumber, selectedItem, accounts, coinBalance, selectedAccount, lockPeriod]);

  const confirmTransaction = async () => {
    try {
      setTransactionReqModal(false);
      setLoadingTransactionReq(true);
      let response = null;
      let e = AMPLITUDE_CONSTANTS.Poin.userConfirmedBillPaymentTransaction;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.source]: 'other_product_page',
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix),
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
      });

      if (isFeatureAvailale.show) {
        if (selectedAccount.optionChangedByUser) {
          response = await requestBillPaymentTransaction(
            selectedItem,
            phoneNumber,
            selectedAccount.constantAccountName,
            dynamicAccounts,
          );
        }
        let newTxnReqId = selectedAccount.optionChangedByUser ? response.data.data.id : transactionReq.id;

        await confirmBpTransaction(newTxnReqId, selectedItem.biller, redeemCode, selectedAccount.constantAccountName);
        dispatch(clearReferral());
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));

        // Subtracting amount from accounts in redux
        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.POINTS) {
          if (coinBalance - transactionReq.subtotal >= 0) {
            dispatch(setCoinBalance(coinBalance - transactionReq.subtotal));
          }
        } else if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.WORK_ALLOWANCE) {
          const newData = subtractFromWorkAllowance(dataAccounts, transactionReq.subtotal);
          dispatch(setDataAccounts(newData));
        }
      } else {
        //if No FeatueFlag
        if (selectedAccount.constantAccountName === DYNAMIC_ACCOUNTS_TYPE.POINTS) {
          await dispatch(
            redeemPoins(transactionReq.subtotal, selectedItem.categoryPrefix, selectedItem.code, phoneNumber),
          );
        } else {
          await confirmBpTransaction(
            transactionReq.id,
            selectedItem.biller,
            redeemCode,
            selectedAccount.constantAccountName,
          );
          dispatch(clearReferral());
          AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        }
      }

      let e2 = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentSuccess;
      navigation.popToTop();
      navigation.navigate('TransactionList', {
        screen: 'BillPaymentConfirm',
        params: {
          productName: transactionReq.productName,
          category: title,
          [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix),
          [e2.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
          [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
          [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
          [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
          [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
          [e2.attributes.fee_charged]: transactionReq.fee,
          [e2.attributes.source]: 'other_product_page',
          [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
        },
      });
      setLoadingTransactionReq(false);

      AmplitudeHelper.logEvent(e2.name, {
        [e2.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix),
        [e2.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
        [e2.attributes.product_price]: transactionReq?.requestAmount ?? '',
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e2.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e2.attributes.fee_charged]: transactionReq.fee,
        [e2.attributes.source]: 'other_product_page',
        [e2.attributes.referral_code]: store.getState().Referral.redeemCode,
      });
    } catch (error) {
      setLoadingTransactionReq(false);
      setTransactionReqModal(false);
      if (error?.response?.data?.message) {
        if (error?.response?.data?.message === 'Balance tidak tersedia!') {
          dispatch(respError('Saldo Gaji dan Poin belum mencukupi untuk transaksi ini'));
          return;
        }
        dispatch(respError(error?.response?.data?.message || 'Permintaan anda tidak tersedia.'));
      }

      if (error?.response?.data?.error === 'REFERRAL_CODE_NOT_APPLICABLE') {
        dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        dispatch(setIsTransacted(true));
        AsyncStorage.setItem('@isRedeemed', JSON.stringify(true));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'not first transaction',
        });
      } else if (error?.response?.data?.error === 'INVALID_REFERRAL_CODE') {
        dispatch(setIsTransacted(false));
        dispatch(setIsValidReferral(false, false));
        dispatch(setRedeemCode(''));
        dispatch(respError('Kode tidak valid. Harap cek ulang kode referral dari temanmu.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'invalid code',
        });
      } else if (isValidCode) {
        if (isTransacted) dispatch(respError('Kode hanya berlaku untuk transaksi pertamamu'));
        else dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
        let e = AMPLITUDE_CONSTANTS.redeemptionFlow.referral_transaction_page_failed;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.referral_code]: store.getState().Referral?.redeemCode ?? '',
          [e.attributes.action]: 'close',
          [e.attributes.failure_message]: 'system error',
        });
      } else {
        dispatch(respError(error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.'));
      }

      let e = AMPLITUDE_CONSTANTS.BillPayment.BillPaymentFailed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
        [e.attributes.product_price]: transactionReq.requestAmount,
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e.attributes.payment_method]: selectedAccount?.amplitudeAccountName ?? '',
        [e.attributes.fee_charged]: transactionReq.fee,
        [e.attributes.source]: 'other_product_page',
        [e.attributes.referral_code]: store.getState().Referral.redeemCode,
        [e.failure_message]: formatUnderScoreLowerCase(
          error?.response?.data?.message || 'Terjadi kesalahan sistem. Harap coba kembali.',
        ),
      });
    }
  };
  const onPressModal = () => {
    setTransactionReqModal(false);
    let e = AMPLITUDE_CONSTANTS.BillPayment.userCanceledBillPaymentTransaction;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.button]: 'batal',
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem.prefix),
      [e.attributes.referral_code]: store.getState().Referral.redeemCode,
    });
  };

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.detailsProductPage;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix),
      [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
      [e.attributes.product_price]: formatUnderScoreLowerCase(selectedItem.amount),
      [e.attributes.account_number]: phoneNumber,
      [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
      [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
    });
  }, []);

  const seeMoreClicked = (value, step) => {
    switch (step) {
      case STEPS.STEP1:
        setStep1(value);
        break;

      case STEPS.STEP2:
        setStep2(value);
        break;

      case STEPS.STEP3:
        setStep3(value);
        break;

      default:
        break;
    }

    if (!value) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeMoreClicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.account_number]: phoneNumber,
        [e.attributes.product_price]: selectedItem?.amount,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix ?? ''),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e.attributes.section]: 'how_to_redeem',
      });
    } else {
      let e = AMPLITUDE_CONSTANTS.BillPayment.Voucher.seeLessClicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.account_number]: phoneNumber,
        [e.attributes.product_price]: selectedItem?.amount,
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedItem?.categoryPrefix ?? ''),
        [e.attributes.product_code]: formatUnderScoreLowerCase(selectedItem?.code ?? ''),
        [e.attributes.reason]: setBillPaymentReason(selectedItem.biller).reason,
        [e.attributes.reason_id]: setBillPaymentReason(selectedItem.biller).id,
        [e.attributes.section]: 'how_to_redeem',
      });
    }
  };

  const changeSelectedAccount = (account) => {
    const sourceAccount = accountByRule();
    account.optionChangedByUser = sourceAccount.constantAccountName !== account.constantAccountName;
    setSelectedAccount(account);
    if (account.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) {
      setReferralToolTip({ visible: false, message: TOOLTIP_CONTENT['rewardFailed'] });
    }
  };

  return (
    <BillPaymentWrapper withBlueBg showAccount={false} withTabs={false} isFullScreen>
      <ScrollView style={styles.scrollViewStyle} contentContainerStyle={styles.scrollViewContainerStyle}>
        <Image source={{ uri: imageUrl }} style={styles.image} />
        <MyText isRegular={false} customStyle={styles.selectedItemText}>
          {selectedItem.name}
        </MyText>
        <View style={styles.viewTime}>
          <Clock name="clock" size={15} color={colors.textGrey} style={styles.clock} />
          <MyText customStyle={styles.timeText}>Tukarkan sebelum 7 hari dari tanggal pembelian voucher</MyText>
        </View>
        <Divider horizontal customStyle={styles.divider} />

        <CollapsibleItem
          isCollapsed={step1}
          setIsCollapsed={(value) => seeMoreClicked(value, STEPS.STEP1)}
          list={returnContent(title).text1}
          title={'Yang Kamu Dapatkan'}
        />
        <Divider horizontal customStyle={styles.divider} />
        <CollapsibleItem
          isCollapsed={step2}
          setIsCollapsed={(value) => seeMoreClicked(value, STEPS.STEP2)}
          list={returnContent(title).text2}
          title={'Syarat & Ketentuan'}
        />

        <Divider horizontal customStyle={styles.divider} />
        <CollapsibleItem
          isCollapsed={step3}
          setIsCollapsed={(value) => seeMoreClicked(value, STEPS.STEP3)}
          list={returnContent(title).text3}
          title={'Cara Redeem Voucher'}
        />

        <RequestTransactionSheet
          selectedAccount={selectedAccount}
          setSelectedAccount={changeSelectedAccount}
          accounts={accounts}
          visible={transactionReqModal}
          transactionReq={transactionReq}
          lockPeriod={lockPeriod}
          onPressModal={onPressModal}
          productId={selectedItem.categoryId}
          onPressConfirm={confirmTransaction}
          isValidCode={isValidCode}
          toolTip={referralToolTip}
          setTooltip={setReferralToolTip}
        />
        <LoadingModal loading={loadingTransactionReq} />
      </ScrollView>
      <LoadingButton
        text={'BELI SEKARANG'}
        buttonColor={colors.orange}
        loadingColor={colors.white}
        loading={loadingTransactionReq}
        container={styles.loadingButton}
        onPress={() => referralPrecheck()}
      />
    </BillPaymentWrapper>
  );
};

export default VoucherScreen;
