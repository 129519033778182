import LoadingButton from '@atoms/LoadingButton';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import { normalize } from '@style/helper';
import React from 'react';
import { View } from 'react-native';
import AccountSelector from '@atoms/AccountSelector';
import { numberWithDots } from '@utils/UtilFunctions';
import styles from './styles';

const SdAccountCard = ({ onPressAccountSelector, onPress, amount, selectedAccount, loading, isModal }) => (
  <View style={styles.cardContainer}>
    <View style={styles.subContainer}>
      <View>
        <MyText isRegular={false} customStyle={{ color: colors.philippineGray }}>
          Nominal Penarikan
        </MyText>
        <MyText isRegular={false} h4 customStyle={styles.textAmount}>
          Rp{numberWithDots(amount)}
        </MyText>
      </View>
      {!isModal && <AccountSelector selectedAccount={selectedAccount} onPress={onPressAccountSelector} />}
    </View>
    <LoadingButton
      container={styles.button}
      textStyle={{
        fontSize: normalize(16),
      }}
      disabledStyle={styles.disabledButton}
      disabled={amount === 0}
      loading={loading}
      onPress={onPress}
      buttonColor={colors.orange}
      text="Tarik Gaji Sekarang"
      textColor={colors.white}
      textTransform="none"
    />
  </View>
);

SdAccountCard.propTypes = {
  onPressAccountSelector: PropTypes.func,
  onPress: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  selectedAccount: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
};

SdAccountCard.defaultProps = {
  isModal: false,
  onPressAccountSelector: null,
};

export default SdAccountCard;
