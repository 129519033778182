import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardAvoidingView, StatusBar, Dimensions, Platform, Text } from 'react-native';
import PropTypes from 'prop-types';
import LoginInfoSheet from '@components/LoginInfoSheet';
import LoginInputCard from '@components/LoginInputCard';
import { ERROR_CODES, LOGIN_INFO_SHEET_CONTENT, LOGIN_INFO_SHEET_KEYS } from '@utils/Constants';
import { returnBottomSheetContent } from '@utils/UtilFunctions';
import {
  formatPhoneNumber,
  formatUnderScoreLowerCase,
  removeNonNumeric,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import styles from './style';
import { newLogin } from '@services/AuthService';
import CustomCarousel from '@components/CustomCarousel';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import MainParentWrapper from '@components/MainParentWrapper';
import { colors } from '@style/colors';
import { useNavigation } from '@react-navigation/native';
const { height: SCREEN_HEIGHT } = Dimensions.get('screen');

const NewLogin = () => {
  const navigation = useNavigation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [enableOtpButtons, setEnableOtpButtons] = useState(false);
  const [otpLoadingType, setOtpLoadingType] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputFieldError, setInputFieldError] = useState({ error: false, errorMessage: 'Nomor handphone tidak valid' });
  const [activeIdx, setActiveIdx] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(SCREEN_HEIGHT / 2);

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS.RESEND_OTP],
      phoneNumber,
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS.RESEND_OTP,
  });

  const onCloseModal = () => {
    if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-404']]) {
      const e = AMPLITUDE_CONSTANTS.authentication.login_modal_closed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      });
    }
    setInfoModal({ ...infoModal, visible: false });
  };

  const onChangePhoneNumber = (text) => {
    inputFieldError.error && setInputFieldError({ error: false, errorMessage: inputFieldError.errorMessage });
    setPhoneNumber(removeNonNumeric(text));
    if (removeNonNumeric(text).substring(0, 2) === '00') {
      const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: removeNonNumeric(text),
        [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error['invalid_number'],
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
      });
      setEnableOtpButtons(false);
      setInputFieldError({ error: true, errorMessage: inputFieldError.errorMessage });
    }
    if (formatPhoneNumber(removeNonNumeric(text), '62').length >= 8) {
      setEnableOtpButtons(true);
    } else {
      setEnableOtpButtons(false);
    }
  };

  const handleLoginError = (error) => {
    const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error[error],
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });

    setInfoModal({
      visible: true,
      data: returnBottomSheetContent(
        LOGIN_INFO_SHEET_KEYS[error],
        LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS[error]],
        formatPhoneNumber(phoneNumber),
      ),
      errorCode: LOGIN_INFO_SHEET_KEYS[error],
    });
  };

  const onPressOtpButton = useCallback(
    async (messagingType) => {
      try {
        const e = AMPLITUDE_CONSTANTS.authentication.otp_requested;
        AmplitudeHelper.logEvent(e.name, {
          [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
          [e.attributes.source]: formatUnderScoreLowerCase(messagingType),
        });
        setOtpLoadingType(messagingType);
        const { otpToken } = await newLogin(formatPhoneNumber(phoneNumber), messagingType);
        const e1 = AMPLITUDE_CONSTANTS.authentication.otp_successful;
        AmplitudeHelper.logEvent(e1.name, {
          [e1.attributes.phone_number]: formatPhoneNumber(phoneNumber),
        });
        navigation.navigate('Otp', { otpToken, phoneNumber: formatPhoneNumber(phoneNumber), messagingType });
      } catch (err) {
        if (err?.response?.data && err?.response?.status !== 400) {
          const { error } = err?.response?.data;
          handleLoginError(error);
        } else {
          const e = AMPLITUDE_CONSTANTS.authentication.otp_failed;
          AmplitudeHelper.logEvent(e.name, {
            [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
            [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error['GG-500'],
            [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
          });
        }
      } finally {
        setOtpLoadingType(null);
      }
    },
    [phoneNumber],
  );

  const onPressPrimaryModalButton = () => {
    if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-404']]) {
      const e = AMPLITUDE_CONSTANTS.authentication.login_retry;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      });
    }
    setInfoModal({
      visible: false,
      data: { ...infoModal.data },
      errorCode: infoModal.errorCode,
    });
  };

  const onPressSecondaryModalButton = () => {
    if (infoModal.errorCode === LOGIN_INFO_SHEET_KEYS[ERROR_CODES['GG-404']]) {
      const e = AMPLITUDE_CONSTANTS.authentication.login_check_data_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      });
    }

    setInfoModal({
      visible: false,
      data: { ...infoModal.data },
      errorCode: infoModal.errorCode,
    });
    setPhoneNumber('');

    // Navigate to check data page
    navigation.navigate('Signup', { phoneNumber });
  };

  const onFocus = (value) => {
    setInputFocused(value);
    const e = AMPLITUDE_CONSTANTS.authentication.phone_number_clicked;
    AmplitudeHelper.logEvent(e.name);
  };

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.authentication.login_page;
    AmplitudeHelper.logEvent(e.name);
  }, []);

  const navigateToSignup = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.registration_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });
    navigation.navigate('Signup');
  };

  const navigateToTermsPage = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.terms_clicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });
    navigation.navigate('Terms');
  };

  const onChangeItemHandler = (idx) => {
    setActiveIdx(idx);
  };

  const onSwipeMomentum = () => {
    const e = AMPLITUDE_CONSTANTS.authentication.banner_swiped;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    });
  };

  const onLayoutHandler = (e) => {
    setCarouselHeight(Math.round(e.nativeEvent.layout.height));
  };

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} enabled style={styles.container}>
      <MainParentWrapper
        isRounded={false}
        isScrollable
        containerStyle={{ paddingTop: 0 }}
        scrollProps={{ showsVerticalScrollIndicator: false }}
        container={{ backgroundColor: '#ffff' }}
        parentStyle={{ backgroundColor: colors.neutral_cotton, paddingTop: 0 }}
      >
        <StatusBar translucent backgroundColor="transparent" />
        <CustomCarousel
          carouselHeight={carouselHeight}
          setActiveIdx={onChangeItemHandler}
          activeIdx={activeIdx}
          onSwipeMomentum={onSwipeMomentum}
        />
        <LoginInputCard
          phoneNumber={phoneNumber}
          setPhoneNumber={onChangePhoneNumber}
          enableOtpButtons={enableOtpButtons}
          otpLoadingType={otpLoadingType}
          inputFocused={inputFocused}
          onPressOtpButton={onPressOtpButton}
          setInputFocused={onFocus}
          onPressTerms={navigateToTermsPage}
          errorMessage={inputFieldError.errorMessage}
          isError={inputFieldError.error}
          onPressSignUp={navigateToSignup}
          onLayout={onLayoutHandler}
        />
        <LoginInfoSheet
          onPressPrimary={onPressPrimaryModalButton}
          onPressSecondary={onPressSecondaryModalButton}
          loginInfo={infoModal}
          onClose={onCloseModal}
        />
      </MainParentWrapper>
    </KeyboardAvoidingView>
  );
};
NewLogin.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};
export default NewLogin;
