import React, { useState, useEffect } from 'react';
import { Text, View, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '@style/helper';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct, getRegisteredAccount } from '@services/BillPaymentApi';
import { setUserAccountNumber } from '@redux/action/Transactions';

/* Style Import */
import { smallerText } from '@style/Layouts';
import { LgButton } from '@components/Button';
import IconButton from '@atoms/IconButton';
/* Component Import */
import DropdownInput from '@atoms/DropdownInput';
import AmplitudeHelper from '@services/amplitude';

/* Screen Import */
import Loading from '@screens/Loading';
import { Font } from '@style/Fonts';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';

const PDAMPostpaid = ({ show, dataList, navigation, prefix }) => {
  const dispatch = useDispatch();
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const { userAccountNumber } = useSelector((state) => state.Transactions);
  const registeredAccountsWater = useSelector((state) => state.BillPayments.registeredAccountsWater);
  const pageFailed = useSelector((state) => state.Common.pageFailed);

  const [currentTab, setCurrentTab] = useState('input');
  const [accountNumber, setAccountNumber] = useState(userAccountNumber);
  const [selectedProductName, setSelectedProductName] = useState('Pilih Wilayah');
  const [listAccountSelected, setListAccountSelected] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedProduct && Object.keys(selectedProduct)?.length != 0) {
      setSelectedProductName(selectedProduct[0]?.name || selectedProduct?.name);
    } else {
      setLoading(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (pageFailed == 'Internal Server error') {
      navigation.navigate('PageFailed', {
        timeTaken: '24 jam',
        productName: selectedProduct.name,
        category: `Top-Up ${selectedProduct.biller}`,
      });
    }
  }, [pageFailed]);

  useEffect(() => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    dispatch(getRegisteredAccount('BP-06'));
  }, []);

  const onChanged = (text) => {
    dispatch(setUserAccountNumber(text.replace(/[^0-9]/g, '')));
    setAccountNumber(text.replace(/[^0-9]/g, ''));
  };

  const selectPDAM = async () => {
    if (currentTab == 'input') {
      setLoading(true);
      await dispatch(selectProduct(selectedProduct[0], accountNumber));
      setLoading(false);
    } else {
      setLoading(true);
      await dispatch(selectProduct(listAccountSelected));
      setLoading(false);
    }
  };

  if (!show) {
    return <></>;
  }

  const handleInputBaruClicked = () => {
    // AmplitudeHelper.logEvent('userClickedInputBaru')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedInputBaru;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.account_number]: accountNumber,
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('input');
  };

  const handleIdTerdaftarClicked = () => {
    // AmplitudeHelper.logEvent('userClickedIdTerdaftar')
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.registeredIdTabClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
    setCurrentTab('id');
  };

  const handleFocus = () => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.newCustomerIdBoxClicked;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
    });
  };

  const handleSelect = (index, value) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.AccountInput.userClickedIdTerdaftar;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: 'other_product_page',
      [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(prefix),
      [e.attributes.account_number]: registeredAccountsWater[index]['accountNumber'],
    });
    setAccountNumber(registeredAccountsWater[index]['accountNumber']);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <>
            <Text style={[smallerText, styles.title]}>ID Pelanggan</Text>
            <View style={styles.newInput}>
              <TouchableOpacity
                accessibilityLabel="newAccountTab"
                style={[
                  styles.button,
                  {
                    borderBottomWidth: currentTab == 'input' ? 3 : 1,
                    borderBottomColor: currentTab == 'input' ? '#f56b57' : '#d4d4d4',
                  },
                ]}
                onPress={handleInputBaruClicked}
              >
                <Text
                  style={{
                    ...smallerText,
                    color: currentTab == 'input' ? '#f56b57' : '#d4d4d4',
                  }}
                >
                  Input Baru
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                accessibilityLabel="savedAccountTab"
                style={[
                  styles.button,
                  {
                    borderBottomWidth: currentTab == 'id' ? 3 : 1,
                    borderBottomColor: currentTab == 'id' ? '#f56b57' : '#d4d4d4',
                  },
                ]}
                onPress={handleIdTerdaftarClicked}
              >
                <Text
                  style={{
                    ...smallerText,
                    color: currentTab == 'id' ? '#f56b57' : '#d4d4d4',
                  }}
                >
                  ID Terdaftar
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.inputContainer}>
              {currentTab == 'input' ? (
                <View style={{ flexDirection: 'column', width: '90%' }}>
                  <View style={{ flexDirection: 'column' }}>
                    <TextInput
                      accessibilityLabel={'accountInput'}
                      style={[styles.textInput, { borderColor: pageFailed != '' ? 'red' : '#d4d4d4' }]}
                      placeholder="Masukkan ID pelanggan"
                      placeholderTextColor="#d4d4d4"
                      keyboardType="numeric"
                      onChangeText={onChanged}
                      onFocus={handleFocus}
                      value={accountNumber}
                    />
                    <Text style={styles.pageFailed}>{pageFailed}</Text>
                  </View>
                  <TouchableOpacity
                    accessibilityLabel={'selectRegion'}
                    onPress={() => {
                      navigation.navigate('PageWilayah', dataList);
                    }}
                    style={[styles.pageFailedButton, { marginTop: pageFailed && pageFailed != '' ? 5 : 0 }]}
                  >
                    <Text
                      style={[
                        styles.productName,
                        {
                          color: selectedProduct && Object.keys(selectedProduct).length != 0 ? '#454545' : '#d4d4d4',
                        },
                      ]}
                    >
                      {selectedProductName}
                    </Text>
                    <IconButton icon={'chevron-right'} style={styles.iconButton} iconStyle={{ color: '#d4d4d4' }} />
                  </TouchableOpacity>
                </View>
              ) : (
                <DropdownInput
                  dropStyle={{ width: '90%', marginLeft: -12 }}
                  style={{
                    borderWidth: 3.5,
                    borderRadius: 12,
                    borderColor: '#d4d4d4',
                    minHeight: '90%',
                    paddingHorizontal: 10,
                    marginBottom: 10,
                  }}
                  styleFocus={{ width: '90%' }}
                  textStyle={{
                    color: accountNumber == '' ? '#d4d4d4' : '#272727',
                  }}
                  defaultValue={'Pilih ID pelanggan'}
                  options={registeredAccountsWater && registeredAccountsWater.map((item) => item.accountNumber)}
                  onSelect={(index, value) => handleSelect(index, value)}
                  value={listAccountSelected}
                />
              )}
            </View>
            <LgButton
              title="Konfirmasi"
              disabled={
                currentTab == 'input'
                  ? accountNumber == '' || (selectedProduct && Object.keys(selectedProduct).length == 0)
                    ? true
                    : false
                  : listAccountSelected && Object.keys(listAccountSelected).length == 0
                  ? true
                  : false
              }
              style={[
                styles.confirmButton,
                {
                  backgroundColor:
                    currentTab == 'input'
                      ? accountNumber == '' || (selectedProduct && Object.keys(selectedProduct).length == 0)
                        ? '#F89F92'
                        : '#F56B57'
                      : listAccountSelected && Object.keys(listAccountSelected).length == 0
                      ? '#F89F92'
                      : '#F56B57',
                },
              ]}
              onPress={selectPDAM}
            />
          </>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  title: { alignSelf: 'flex-start', marginLeft: '5%', marginTop: '5%' },
  newInput: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: '5%',
    maxHeight: '5%',
    marginLeft: '15%',
  },
  button: { flex: 0.4, alignItems: 'center' },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: '5%',
    maxHeight: '10%',
    marginLeft: '10%',
    marginBottom: '30%',
  },
  textInput: {
    borderWidth: 2,
    borderRadius: 12,
    maxHeight: '85%',
    paddingHorizontal: 10,
    minHeight: normalizeHeight(40),
  },
  pageFailed: {
    fontSize: normalize(12),
    color: '#d40e0b',
    fontFamily: Font.Nunito_Regular,
    marginLeft: 10,
    marginTop: 5,
    outlineColor: 'transparent',
  },
  pageFailedButton: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderWidth: 2,
    borderRadius: 12,
    borderColor: '#d4d4d4',
    maxHeight: '85%',
    paddingHorizontal: 10,
    minHeight: normalizeHeight(40),
  },
  productName: {
    borderWidth: 0,
    fontSize: normalize(16),
    height: '100%',
    width: '95%',
    margin: 0,
    padding: 0,
    alignItems: 'center',
  },
  iconButton: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  confirmButton: {
    alignSelf: 'center',
    width: normalize(200),
    marginBottom: normalizeHeight(15),
  },
});

export default PDAMPostpaid;
