import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  scrolLStyle: { paddingBottom: 1 },
  emptyList: { height: 220, justifyContent: 'center', alignItems: 'center' },
  emptyListText: { fontSize: normalize(12), color: colors.black },
  dividerStyle: { height: normalize(4), marginBottom: normalize(12) },
});

export default styles;
