import { StyleSheet } from 'react-native';
import { normalizeHeight } from '@style/helper';
import { colors } from '@style/colors';

const styles = StyleSheet.create({
  loadingButton: { borderRadius: 40, marginVertical: 10 },
  divider: { height: 2, marginVertical: 10 },
  timeText: { width: '80%', marginStart: 5, color: colors.textGrey },
  clock: { marginStart: 15, marginTop: 2 },
  viewTime: { flexDirection: 'row', marginVertical: 5, paddingHorizontal: 0 },
  selectedItemText: { paddingHorizontal: 15 },
  scrollViewStyle: { flexGrow: 1 },
  scrollViewContainerStyle: { paddingBottom: 70 },
  image: { height: 100, width: 100, resizeMode: 'contain', alignSelf: 'center' },
  logo: {
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    marginTop: normalizeHeight(20),
    marginRight: 10,
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
