import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import { normalize } from '@style/helper';

const styles = StyleSheet.create({
  consultancyButton: { flex: 1, borderRadius: 30, marginStart: 10 },
  simpanButton: { flex: 1, borderRadius: 30, width: '100%' },
  twoButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 15,
    width: '100%',
    alignSelf: 'center',
  },
  saveButton: {
    position: 'absolute',
    bottom: 15,
    width: '90%',
    borderRadius: 30,
  },
  imageContainer: {
    flex: 1,
    backgroundColor: colors.black,
  },
  modalImage: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  mainWrapper: { paddingHorizontal: 15 },
  modalButtonText: {
    marginStart: 15,
    paddingVertical: 10,
  },
  modalButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalText: {
    marginVertical: 10,
    color: colors.philippineGray,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  closeButton: {
    alignSelf: 'flex-end',
    padding: 15,
    position: 'absolute',
    top: 0,
    zIndex: 10,
  },
  imageModalContainer: {
    backgroundColor: colors.black,
    width: '100%',
    height: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  item: {
    margin: 6,
    marginVertical: 8,
    width: 100,
    height: 90,
    padding: 12,
    paddingTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.slightLightGrey,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  list: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'center',
  },
  paginationInactiveDot: {
    backgroundColor: colors.lightGrey,
    height: 12,
    width: 12,
    borderRadius: 6,
  },
  paginationDot: {
    backgroundColor: colors.blue,
    height: 12,
    width: 12,
    borderRadius: 6,
  },
  buttonText: {
    color: colors.orange,
    textDecorationLine: 'underline',
    lineHeight: 19,
  },
  headerSubContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 16,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: 25,
  },
  headerContainer: { paddingHorizontal: 15, marginTop: 20 },
  closeButtonContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 15,
    top: 0,
    zIndex: 5,
  },
  itemText: {
    textAlign: 'center',
    lineHeight: 14,
    marginTop: 5,
  },
  reasonContainer: { height: normalize(28) },
  itemCircleBox: {
    borderWidth: 1,
    borderColor: colors.grey,
    backgroundColor: colors.white,
  },
  detailsContainer: {
    backgroundColor: colors.mist,
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  descriptionTitle: { marginStart: 0 },
  title: { color: colors.greyShade, marginVertical: 15 },
  iconContainer: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    alignSelf: 'center',
    marginBottom: 10,
    backgroundColor: colors.aliceBlue,
    justifyContent: 'center',
    alignItems: 'center',
  },
  voucherCodeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.softOrchid,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginTop: 15,
  },
});

export default styles;
