import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable, View, FlatList, ActivityIndicator, Dimensions } from 'react-native';
import AIcon from '@expo/vector-icons/AntDesign';
import moment from 'moment';
import { colors } from '@style/colors';
import { getIconSavings, IconSavings } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@utils/UtilFunctions';
import { normalize } from '@style/helper';
import { styles } from './style';
import { NoData } from '../shared';
import { useIsFocused } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { setActiveSavingDetailsTab } from '@redux/action/Common';

const { width: screenWidth } = Dimensions.get('window');

const Details = (props: any) => {
  const { page, loadingList, transactionList, setLoadingList } = props;
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(false);
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const onEndReached = useCallback(() => {
    if (!onEndReachedCalledDuringMomentum) {
      if (!loadingList) {
        loadMore();
      }
      setOnEndReachedCalledDuringMomentum(true);
    }
  }, [onEndReachedCalledDuringMomentum, loadingList]);

  const loadMore = useCallback(() => {
    setLoadingList(true);
  }, [page]);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <TransactionItem
          item={item}
          index={index}
          totalItem={transactionList.length}
          onPress={(item) => props.onOpen(item)}
        />
      );
    },
    [transactionList],
  );

  const onMomemtumCall = () => {
    setOnEndReachedCalledDuringMomentum(false);
  };

  useEffect(() => {
    if (isFocused) {
      dispatch(setActiveSavingDetailsTab('detail'));
    }
  }, [isFocused]);

  const footer = useCallback(() => {
    if (loadingList) {
      return <ActivityIndicator color={colors.blue} size={'small'} style={{ marginVertical: 10 }} />;
    }
    return null;
  }, [loadingList]);

  if (!transactionList.length) return <NoData text={`Kamu tidak memiliki transaksi \npada periode ini.`} />;

  return (
    <View style={{ height: 360, flex: 1 }}>
      <FlatList
        onMomentumScrollBegin={onMomemtumCall}
        data={transactionList}
        renderItem={renderItem}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.4}
        keyExtractor={(item, index) => index.toString()}
        style={{ paddingTop: 15, backgroundColor: colors.white }}
        ListFooterComponent={footer}
        nestedScrollEnabled
      />
    </View>
  );
};

const TransactionItem = ({ item, onPress, disabled = false, container = {}, index = 0, totalItem = 0 }) => {
  let _item: any = {};

  if (item?.length) {
    _item.transTotalAmount = item.reduce((sum, txn) => sum + txn.transTotalAmount, 0);
    const campaign = item[0]?.campaign;
    _item.totalSaving = campaign?.offer?.amount ?? 0;
    _item.createdAt = campaign?.date;
    _item.categoryId = campaign?.offer?.categoryid;
    _item.displayname = campaign?.offer?.description;
    _item.type = 'campaign';
    _item.txn = item;
    _item.url = campaign?.offer?.url ?? null;
    _item.offerId = campaign.offer.id;
  } else {
    _item = item;
    _item.categoryId = item.offer?.categoryid;
    const compareAmount: number = Number(item?.offer?.compareamount ?? 0);
    const amount: number = Number(item?.offer?.amount ?? 0);
    _item.totalSaving = numberWithDots(compareAmount - amount);
    _item.displayname = item?.offer?.category?.displayname;
    _item.type = 'saving';
    _item.offerId = item.offer?.id;
  }
  // if (!_item.categoryId) return null
  // console.log(JSON.stringify(_item), "lets check");
  return (
    <Pressable
      android_ripple={{ color: colors.lightGrey }}
      style={[styles.container, container]}
      onPress={() => !!_item.categoryId && onPress(_item)}
      disabled={disabled}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.subContainer}>
          <View style={styles.iconContainer}>{getIconSavings(_item.categoryId)}</View>
          <View style={{ marginStart: 10, flex: 1 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <MyText
                  h2
                  isRegular
                  customStyle={{
                    color: '#454545',
                  }}
                >
                  Kamu Hemat:{' '}
                </MyText>
                <MyText h3 isRegular={false}>
                  Rp{_item?.totalSaving}
                  {` `}
                </MyText>
                <AIcon name="exclamationcircleo" size={normalize(10)} color="#979797" />
              </View>
              <MyText customStyle={{ color: colors.textGrey }} h2>
                {moment(_item?.createdAt).format('DD MMM YY')}
              </MyText>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <MyText
                h2
                isRegular={false}
                customStyle={{ color: '#454545', maxWidth: screenWidth / 2 }}
                numberOfLines={1}
              >
                {_item.displayname}
              </MyText>
              <MyText h1 isRegular customStyle={{ color: '#888888' }}>
                {`  |  Kamu Bayar: Rp`}
                {numberWithDots(parseInt(_item?.transTotalAmount ?? 0))}
              </MyText>
            </View>
          </View>
        </View>
        {index !== totalItem - 1 && (
          <View
            style={{
              height: 1,
              width: '100%',
              backgroundColor: '#E7E7E7',
              position: 'absolute',
              bottom: 0,
            }}
          />
        )}
      </View>
    </Pressable>
  );
};

export default Details;
