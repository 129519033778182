import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';
import { StyleSheet } from 'react-native';

const CIRCLE_SIZE = 110;
const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: colors.secondary_orchid,
    marginHorizontal: '4%',
    minHeight: normalizeHeight(60),
    borderRadius: normalize(11),
    marginVertical: normalizeHeight(10),
    paddingVertical: normalizeHeight(2),
    justifyContent: 'center',
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
  },
  circle: {
    width: normalize(CIRCLE_SIZE),
    height: normalize(CIRCLE_SIZE),
    backgroundColor: colors.variants_clover,
    borderRadius: normalize(CIRCLE_SIZE) / 2,
    position: 'absolute',
    opacity: 0.2,
  },
  topCircle: { top: normalizeHeight(-45), left: normalize(-10) },
  bottomCircle: { bottom: normalizeHeight(-32), left: normalize(-35) },
  rightTopCircle: { top: normalizeHeight(-32), right: normalize(-35) },
  rightBottomCircle: { bottom: normalizeHeight(-45), right: normalize(-10) },
  labelStyle: {
    color: colors.neutral_cotton,
    fontSize: normalize(12),
    alignSelf: 'center',
    marginBottom: normalizeHeight(5),
  },
  nominalStyle: {
    color: colors.neutral_cotton,
    fontSize: normalize(19),
    alignSelf: 'center',
  },
});

export default styles;
