import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import AmplitudeHelper from '@services/amplitude';
import BillPaymentTab from '@screens/BillPaymentTabs/BillPaymentTab';

/* Components import */

const Stack = createStackNavigator();

const BillPaymentCategoriesStack = () => {
  useEffect(() => {}, []);

  return (
    <Stack.Navigator>
      <Stack.Screen name="BillPaymentTab" component={BillPaymentTab} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

export default BillPaymentCategoriesStack;
