import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingTop: normalizeHeight(8),
    paddingBottom: normalizeHeight(80),
    backgroundColor: colors.neutral_cotton,
  },

  dividerStyle: {
    height: normalizeHeight(4),
    marginTop: normalizeHeight(20),
    color: colors.blackColor,
  },
  listingContainer: { height: 0 },
});
