import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import loadable from '@loadable/component';

/* Screen Imports */

// const LoginStack = loadable(() => import('@stacks/LoginStack'));
// const Informasi = loadable(() => import('@screens/Informasi'));
// const KetentuanLainnya = loadable(() => import('@screens/KetentuanLainnya'));

import LoginStack from '@stacks/LoginStack';
import Informasi from '@screens/Informasi';
import KetentuanLainnya from '@screens/KetentuanLainnya';

/* Style Imports */
import { ScreenOptions } from '@style/Themes';
import { BackButton } from '@components/Button';

const MainLoginStack = createStackNavigator();

const LoginScene = () => (
  <MainLoginStack.Navigator mode="modal" animationType="slide">
    <MainLoginStack.Screen name="Login" component={LoginStack} options={{ headerShown: false }} />
    <MainLoginStack.Screen
      name="MainInformasi"
      component={Informasi}
      options={({ navigation }) => ({
        ...ScreenOptions,
        headerRight: () => <BackButton onPress={() => navigation.goBack()} />,
      })}
    />
    <MainLoginStack.Screen
      name="KetentuanLainnya"
      component={KetentuanLainnya}
      options={({ navigation }) => ({
        ...ScreenOptions,
        headerRight: () => <BackButton onPress={() => navigation.goBack()} />,
      })}
    />
  </MainLoginStack.Navigator>
);

export default LoginScene;
