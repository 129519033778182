import React, { useState } from 'react';
import { Text, View, Dimensions, TouchableOpacity, ScrollView, TextInput, StyleSheet, FlatList } from 'react-native';

/* Redux Import */
import { useDispatch } from 'react-redux';
import { updateProduct } from '@redux/action/BillPayments';

/* Styles */
import { background, LgCard } from '@style/Themes';
import { centerView } from '@style/Layouts';
import IconButton from '@atoms/IconButton';
import { normalize, normalizeHeight } from '@style/helper';
const { height: screenHeight } = Dimensions.get('window');
import Loading from '@screens/Loading';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import { formatUnderScoreLowerCase } from '@utils/UtilFunctions';

const PageWilayah = ({ route, navigation }) => {
  const { params } = route;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [areaFiltered, setAreaFiltered] = useState('');

  const selectArea = (dataArea) => {
    let e = AMPLITUDE_CONSTANTS.BillPayment.PostPaid.pdamAreaSelected;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.product_code]: formatUnderScoreLowerCase(dataArea[0]?.code),
    });
    dispatch(updateProduct(dataArea));
    navigation.goBack();
  };

  let dataArea = Object.keys(params).filter((val) => {
    if (areaFiltered == '') {
      return val;
    } else if (val.toLowerCase().includes(areaFiltered.toLowerCase())) {
      return val;
    }
  });

  return (
    <>
      <View style={[centerView, background]}>
        <View style={[styles.container, LgCard]}>
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <View style={styles.inputContainer}>
                  <IconButton icon={'search'} style={styles.icon} iconStyle={{ color: '#d4d4d4' }} />
                  <TextInput
                    style={styles.input}
                    placeholder="Cari Wilayah"
                    placeholderTextColor="#d4d4d4"
                    onChangeText={(x) => setAreaFiltered(x)}
                  />
                </View>
                <ScrollView style={{ width: '90%', marginBottom: '12%' }}>
                  <FlatList
                    data={dataArea}
                    renderItem={(item) => {
                      return (
                        <TouchableOpacity onPress={() => selectArea(params[item.item])} style={styles.button}>
                          <Text style={{ color: '#454545', fontSize: normalize(12), fontFamily: 'Nunito-Regular' }}>
                            {item.item}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </ScrollView>
              </>
            )}
          </>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    height: screenHeight / 1.2,
    bottom: '31%',
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
    borderWidth: 2,
    borderRadius: 12,
    borderColor: '#d4d4d4',
    maxHeight: '85%',
    paddingHorizontal: 10,
    minHeight: normalizeHeight(40),
    marginVertical: 10,
  },
  icon: { justifyContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'center' },
  input: {
    borderWidth: 0,
    fontSize: normalize(16),
    height: '100%',
    width: '80%',
    margin: 0,
    padding: 0,
    outlineColor: 'transparent',
  },
  button: { paddingHorizontal: 10, paddingVertical: 15, borderBottomWidth: 1, borderBottomColor: '#d4d4d4' },
});

export default PageWilayah;
