import { StyleSheet } from 'react-native';
import { normalize } from '@style/helper';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';

export const styles = StyleSheet.create({
  dividerStyle: { height: normalize(4.5), width: '94%', alignSelf: 'center' },
  pressable: { width: '100%', justifyContent: 'center' },
  loadingButtonText: {
    fontSize: normalize(14),
  },
  loadingButton: {
    paddingVertical: 10,
    flex: 1,
    justifyContent: 'center',
    borderRadius: 30,
    marginStart: 10,
  },
  buttonStyle: { fontSize: normalize(14), color: colors.tertiaryColor },
  imageButton: {
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.tertiaryColor,
    alignSelf: 'center',
    flex: 1,
  },
  buttonContainer: {
    position: 'absolute',
    zIndex: 15,
    bottom: 0,
    elevation: 15,
    backgroundColor: colors.white,
    paddingHorizontal: normalize(9),
    paddingVertical: normalize(4),
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },

  listTitleStyle: { paddingLeft: normalize(14), marginTop: normalize(14), marginBottom: normalize(13) },

  titleStyle: { fontSize: normalize(14) },
  descStyle: {
    fontSize: normalize(14),
    marginTop: normalize(5),
    marginBottom: normalize(10),
    textAlign: 'center',
  },
  containerStyle: { paddingTop: 0, flex: 1, paddingBottom: normalize(65) },
  contantStyle: { paddingBottom: '10%', height: 0 },
  styleListTxn: { paddingBottom: normalize(105) },
  transactButtonContainer: { flexDirection: 'column', marginBottom: normalize(6) },
});
