import React, { useEffect, useState } from 'react';
import { SectionList, StatusBar, View } from 'react-native';
import { getTermsAndConditions } from '@services/s3Service';
import MainParentWrapper from '@components/MainParentWrapper';
import MyText from '@atoms/MyText';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import AmplitudeHelper from '@services/amplitude';
import styles from './style';

const TermsCondition = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState();

  const fetchTermsAndConditions = async () => {
    try {
      error && setError(false);
      const response = await getTermsAndConditions();
      setData(response);
    } catch (error) {
      setError(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.authentication.login_terms_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
    });

    fetchTermsAndConditions();

    return () => {
      const e = AMPLITUDE_CONSTANTS.authentication.back_arrow_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_terms_page,
      });
    };
  }, []);

  const header = () => {
    return (
      <MyText
        props={{
          selectable: true,
        }}
      >
        {data?.description}
      </MyText>
    );
  };

  const renderItem = ({ item, section }) => {
    return (
      <View style={{ marginVertical: 5 }}>
        <MyText
          props={{
            selectable: true,
          }}
        >
          {item.text}
        </MyText>
        {item?.list?.length > 0 &&
          item.list.map((e) => (
            <MyText
              props={{
                selectable: true,
              }}
              key={e}
              customStyle={styles.item}
            >
              {e}
            </MyText>
          ))}
        {section?.title?.footerDescription && section?.data?.length !== 0 && (
          <MyText
            props={{
              selectable: true,
            }}
            customStyle={styles.headerDescription}
          >
            {section.title.footerDescription}
          </MyText>
        )}
      </View>
    );
  };

  const renderSectionHeader = ({ section }) => {
    return (
      <View>
        <MyText
          props={{
            selectable: true,
          }}
          h2
          isRegular={false}
          customStyle={styles.headerTitle}
        >
          {section.title?.subTitle}
        </MyText>

        {section?.title?.footerDescription && section?.data?.length === 0 && (
          <MyText
            props={{
              selectable: true,
            }}
            customStyle={styles.headerDescription}
          >
            {section.title.footerDescription}
          </MyText>
        )}
      </View>
    );
  };

  const keyExtractor = (item) => item.text;

  return (
    <MainParentWrapper isError={error} isRounded={false} loading={loading}>
      <StatusBar barStyle="dark-content" />
      <View style={styles.textContainer}>
        <MyText isRegular={false} customStyle={styles.title}>
          Syarat dan Ketentuan
        </MyText>
        <MyText isRegular={false} customStyle={styles.lastUpdate} h2>
          Terakhir diperbarui {data?.lastupdatedDate}
        </MyText>

        <SectionList
          keyboardShouldPersistTaps="never"
          keyExtractor={keyExtractor}
          sections={data?.data ?? []}
          renderItem={renderItem}
          ListHeaderComponent={header}
          renderSectionHeader={renderSectionHeader}
          contentContainerStyle={styles.sectionList}
        />
      </View>
    </MainParentWrapper>
  );
};

export default TermsCondition;
