import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import AmplitudeHelper from '@services/amplitude';
import { useIsFocused, useNavigation } from '@react-navigation/native';

/* Screens Import */
import Loading from '@screens/Loading';

/* Components */
import LoanDashboard from '@components/LoanDashboard';
import LoanActive from '@components/LoanActive';
import ModalLoan from '@components/ModalLoan';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { respError } from '@redux/action/Common';

/* Services */
import { loan } from '@services/LoanService';

/* Styles */
import { backgroundLoan } from '@style/Layouts';

const Loan = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const stateLoan = useSelector((state) => state.Loan);
  const common = useSelector((state) => state.Common);

  const isFocused = useIsFocused();

  useEffect(() => {
    AmplitudeHelper.logEvent('loan');
    getLoan();
  }, [isFocused]);

  const getLoan = async () => {
    setLoading(true);
    try {
      setLoading(false);
      await dispatch(loan());
    } catch (error) {
      setLoading(false);
      dispatch(respError(error.response?.data?.message));
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ScrollView contentContainerStyle={{ flex: common.showTerms ? 1 : undefined }}>
            <View style={backgroundLoan}></View>
            {stateLoan.statusLoan == 'INPROGRESS' ? (
              <LoanActive dataLoan={stateLoan.loan} statusLoan={stateLoan.statusLoan} />
            ) : (
              <LoanDashboard navigation={navigation} dataLoan={stateLoan.loan} />
            )}
          </ScrollView>
          {stateLoan.statusLoan == undefined ? <ModalLoan /> : null}
        </>
      )}
    </>
  );
};

export default Loan;
