import React, { useEffect, useState } from 'react';
import { Text, View, Pressable } from 'react-native';
import { useDispatch, useSelector, batch } from 'react-redux';
// import { SvgUri } from 'react-native-svg';

import { formatUnderScoreLowerCase, numberWithDots } from '@utils/UtilFunctions';
import AmplitudeHelper from '@services/amplitude';
import Loading from '@screens/Loading';
import { setCanContinue, setConfirmed, setParentPage } from '@redux/action/Common';
import { updateProduct } from '@redux/action/BillPayments';
import { setInquiry, resetTransaction } from '@redux/action/Transactions';
import { setUseCoin } from '@redux/action/Coins';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import styles from './style';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import MyText from '@atoms/MyText';
import LoadingButton from '@atoms/LoadingButton';
import { store } from '@enhancedStore/store';
import { SVG_ICONS } from '@gaji-gesa/gg-ui-shared/src/uiUtils/SvgIcons';

const Settlement = ({ route, navigation }) => {
  const dispatch = useDispatch();

  const { actualAmount, selectedReason, selectedReasonId, feeAmount, inquiry } = useSelector(
    (state) => state.Transactions,
  );
  const { currentHomePageSelectedAccount } = store.getState().DynamicAccounts;
  const { useCoin } = useSelector((state) => state.Coins);
  const selectedProduct = useSelector((state) => state.BillPayments.selectedProduct);
  const { productName, timeTaken, parentPage, transactionCategoryId, eventSource } = route.params;
  const { redeemCode } = useSelector((state) => state.Referral);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (useCoin) {
      dispatch(setUseCoin(false));
    }

    if (inquiry?.id) {
      let e = AMPLITUDE_CONSTANTS.BillPayment.selamatPage;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.transaction_category_id]: formatUnderScoreLowerCase(selectedProduct?.categoryPrefix) ?? null,
        [e.attributes.product_price]: actualAmount ?? null,
        [e.attributes.reason]: 'tagihan_dan_utilitas',
        [e.attributes.reason_id]: 2,
        [e.attributes.payment_method]: useCoin?.useCoin ? 'poin' : 'accrued_salary',
        [e.attributes.source]: 'other_product_page',
        [e.attributes.fee_charged]: feeAmount ?? null,
        [e.attributes.product_code]: inquiry?.productCode ?? null,
      });
    } else {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.name, {
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.requested_amount]: actualAmount ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.transaction_category_id]: transactionCategoryId ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.reason]: selectedReason ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.reason_id]: selectedReasonId ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.fee_requested]: feeAmount ?? null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.source]: eventSource,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.referral_code]: redeemCode || null,
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.dynamic_account_name]:
          currentHomePageSelectedAccount?.amplitudeAccountName ?? '',
        [AMPLITUDE_CONSTANTS.Withdrawal.selamatPage.attributes.payment_method]: useCoin?.useCoin
          ? 'poin'
          : 'accrued_salary',
      });
    }
    return () => {
      let e = AMPLITUDE_CONSTANTS.Withdrawal.success_page_closed;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.product_price]: actualAmount ?? null,
        [e.attributes.transaction_category_id]: transactionCategoryId ?? null,
        [e.attributes.reason]: selectedReason ?? null,
        [e.attributes.reason_id]: selectedReasonId ?? null,
        [e.attributes.fee_charged]: feeAmount ?? null,
        [e.attributes.source]: eventSource,
        [e.attributes.payment_method]: useCoin?.useCoin ? 'poin' : 'accrued_salary',
      });
    };
  }, []);

  const handlePress = () => {
    setLoading(true);
    setTimeout(() => {
      batch(() => {
        dispatch(setCanContinue(false));
        dispatch(setConfirmed(false));
        dispatch(updateProduct({}));
        dispatch(setInquiry({}));
        dispatch(resetTransaction());
        dispatch(setParentPage(''));
        // dispatch(getDashboardDetailApi());
      });
      setLoading(false);

      if (parentPage === 'billpayment') {
        navigation.navigate('Pembayaran', {
          screen: 'Billpayment',
        });
      } else {
        navigation.navigate('Dashboard');
      }
    }, 1000);
  };

  const getAmount = () => {
    return `Rp.${numberWithDots(parentPage === 'billpayment' ? inquiry?.requestAmount : actualAmount)}`;
  };

  return (
    <View style={styles.scene}>
      <View style={styles.background} />
      <View style={styles.container}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <View style={styles.logoContainer}>
              <View style={styles.logo}>
                {/* <SvgUri uri="https://gg-app-stg.s3.ap-southeast-3.amazonaws.com/svg-icons/PaymentSuccess.svg" /> */}
                {SVG_ICONS.successIcon}
              </View>
              <View style={{ alignItems: 'center' }}>
                <Text style={styles.cardTitle}>Selamat!</Text>
                <MyText h5 isRegular={false}>
                  Penarikan Gaji Berhasil!
                </MyText>
                <MyText h5 customStyle={{ marginVertical: 10 }}>
                  Permintaan penarikan gaji Anda sebesar
                </MyText>
                <View style={styles.alignRowCenter}>
                  <Text style={styles.boldBlue}>{getAmount()}</Text>
                </View>
                <MyText h4 isRegular={false} customStyle={{ marginVertical: 10 }}>
                  sedang dalam proses.{' '}
                </MyText>
                <MyText h5 customStyle={styles.text5}>
                  Cek status transaksi Anda di laman Transaksi
                </MyText>
              </View>
            </View>
            <LoadingButton
              onPress={handlePress}
              text={'OK'}
              buttonColor={colors.orange}
              container={styles.buttonStyle}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default Settlement;
