import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '@style/colors';
import Arrow from '@expo/vector-icons/FontAwesome';
import Info from '@expo/vector-icons/AntDesign';
import { TOOLTIP_CONTENT } from '@utils/Constants';
import AmplitudeHelper from '@services/amplitude';
import { AMPLITUDE_CONSTANTS } from '@utils/AmplitudeConstants';
import tw from '@utils/tailwind';
import styles from '@screens/TransactionList/styles';
import MyText from '@atoms/MyText';
import ToolTip from '@atoms/CustomTooltip';

const IncomeExpenseItem = ({ container, amount, isIncome, title, hasInfo = true }) => {
  const [tooltip, setTooltip] = useState(false);

  const onCloseTooltip = () => setTooltip(false);
  const onInfoPress = () => {
    setTooltip(true);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.TransactionPage.tooltipClicked.name, {
      [AMPLITUDE_CONSTANTS.TransactionPage.tooltipClicked.attributes.source]: 'total_income_transaction_page',
    });
  };

  return (
    <View style={[localStyles.container, container]}>
      <MyText isRegular={false} customStyle={isIncome ? localStyles.income : localStyles.expense}>
        {amount}
      </MyText>
      <View style={localStyles.arrowContainer}>
        <Arrow
          name={isIncome ? 'long-arrow-right' : 'long-arrow-left'}
          color={isIncome ? colors.darkGreen : colors.red}
        />
        <MyText h1 customStyle={{ marginHorizontal: 5 }}>
          {title}
        </MyText>
        {hasInfo && (
          <ToolTip description={TOOLTIP_CONTENT['totalAmount']} isVisible={tooltip} onClose={onCloseTooltip}>
            <View style={styles.twInfoIconStyle}>
              <Info onPress={onInfoPress} name="question" color="white" size={15} />
            </View>
          </ToolTip>
        )}
      </View>
    </View>
  );
};

const localStyles = StyleSheet.create({
  arrowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingVertical: 5,
    borderRadius: 5,
  },
  income: {
    color: tw.color('neutral_darkMist'),
  },
  expense: {
    color: tw.color('neutral_darkMist'),
  },
});

export default IncomeExpenseItem;
