import { Dimensions, StyleSheet } from 'react-native';
import { colors } from '@style/colors';

const styles = StyleSheet.create({
  item: {
    marginStart: 10,
  },
  headerDescription: {
    marginTop: 5,
  },
  headerTitle: {
    marginVertical: 10,
  },
  sectionList: {
    paddingBottom: Dimensions.get('screen').width / 3,
    height: 0,
  },
  lastUpdate: {
    color: colors.philippineGray,
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  textContainer: {
    width: '90%',
    alignSelf: 'center',
    flex: 1,
  },
  mainContainer: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 0.7,
    borderColor: colors.gainsboro,
    justifyContent: 'center',
  },
  textStyle: {
    color: colors.black,
  },
  pressableStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    borderColor: colors.gainsboro,
  },
});

export default styles;
