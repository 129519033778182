import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/Fonts';
import { normalize, normalizeHeight } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    marginTop: 20,
    width: '95%',
    zIndex: 10,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: 10,
    justifyContent: 'space-between',
    paddingStart: 10,
    paddingEnd: 10,
    borderRadius: 12,
  },
  nameContainer: { display: 'flex', flexDirection: 'column' },
  iconButton: { display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' },
  iconName: {
    color: colors.orange,
  },
  iconPhoneNumber: {
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(17),
    color: colors.black,
    marginLeft: 10,
  },
  support: { flexDirection: 'column', alignSelf: 'center', alignItems: 'center' },
  Chips: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: normalize(62),
    marginRight: normalize(4),
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 12,
    // boxSizing: 'border-box',

    backgroundColor: 'rgba(235,239,254,1)',
  },
  Frame1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    height: '100%',
    flex: 1,
    // boxSizing: 'border-box',
  },
  Default: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginRight: 4,
    color: 'rgba(34,59,144,1)',
    fontSize: normalize(10),
    lineHeight: 15,
    fontFamily: 'Nunito, sans-serif',
    fontWeight: '700',
    textAlign: 'right',
  },
  Contacts: {
    color: 'rgba(34,59,144,1)',
    fontSize: normalize(14.5),
  },
  containerIcon: { justifyContent: 'center', alignItems: 'center' },
});
