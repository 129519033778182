import { StyleSheet } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";


export default StyleSheet.create({
    horizontalView:{
        flexDirection:'row',
        alignItems:'center',
    },
    buttonStyle: {
       
            paddingVertical: 8,
            marginTop: 15,
            width: "25%",
            alignSelf: "flex-end",
            marginEnd: 15,
         
    },
    buttonText:{
        fontSize:12
    }

})