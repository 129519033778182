import React  from 'react'
import {View,Text, Pressable} from 'react-native'
import styles from './styles'
import { copilot, walkthroughable, CopilotStep } from "react-native-copilot";
const CustomComponent = ({ copilot, children, props }) => (
    <View
    {...copilot}
    {...props}

            >
            {children}
               
            </View>
      );
      
const AppTourComponentWrapper = ({ children, order, text, name, props, type, active }) => {
      return (
            <CopilotStep
                  active={active}                              
                  text={{...text, type}}
                  order={order}
                  name={name}
                  
            >
                  <CustomComponent children={children} props={props} />
            </CopilotStep>

      )

}

export default AppTourComponentWrapper