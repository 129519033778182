import React from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import FaIcon from '@expo/vector-icons/FontAwesome5';
import MaIcon from '@expo/vector-icons/MaterialCommunityIcons';
import EIcon from '@expo/vector-icons/Entypo';

/* Styles import */
import { centerView, subTitleText, smallerText, rowView } from '@style/Layouts';
import { background, LgCard, infoIcon } from '@style/Themes';

const Informasi = () => {
  const features = [
    {
      iconSet: 'Fa5',
      icon: 'coins',
      text: 'Anda memiliki biaya tak terduga.',
    },
    {
      iconSet: 'MaIcon',
      icon: 'account-cash',
      text: 'Anda memilih jumlah gaji yang ingin Anda tarik.',
    },
    {
      iconSet: 'Fa5',
      icon: 'wallet',
      text: 'Anda menerima gaji yang diminta ke rekening bank Anda.',
    },
    {
      iconSet: 'EIcon',
      icon: 'cycle',
      text: 'Anda menerima sisa gaji pada hari gajian.',
    },
  ];

  return (
    <>
      <View style={[centerView, background]}>
        <View style={LgCard}>
          <View>
            <Text accessibilityLabel="informasiProductSection" style={{ ...subTitleText, color: '#3863F0' }}>
              <FaIcon name="info-circle" style={{ fontSize: 25 }} /> Informasi Produk
            </Text>
          </View>
          <Text style={styles.info}>
            GajiGesa adalah platform gaji fleksibel yang memberi akses pada gajimu kapanpun kamu mau.
          </Text>
          <FlatList
            data={features}
            renderItem={(feature) => {
              return (
                <View style={rowView}>
                  <View>
                    {feature.item.iconSet == 'Fa5' ? (
                      <FaIcon name={feature.item.icon} style={infoIcon} />
                    ) : feature.item.iconSet == 'MaIcon' ? (
                      <MaIcon name={feature.item.icon} style={infoIcon} />
                    ) : (
                      <EIcon name={feature.item.icon} style={infoIcon} />
                    )}
                  </View>
                  <Text style={styles.feature}>{feature.item.text}</Text>
                </View>
              );
            }}
          />
          <View style={styles.feeContainer}>
            <Text style={{ ...smallerText, textAlign: 'center' }}>
              Tingkat bunga 0%! Kami hanya mengambil sedikit biaya pemrosesan untuk transaksi
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  info: { maxWidth: '90%', marginLeft: 25, fontSize: 12, lineHeight: 22, fontFamily: 'Nuno-Regular' },
  feature: { fontSize: 13, maxWidth: 250, alignSelf: 'center', fontFamily: 'Nunito-Regular' },
  feeContainer: { ...rowView, marginTop: 30, marginBottom: 20, marginLeft: 20, maxWidth: 300 },
});

export default Informasi;
