import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { GET_USER_ALL_BANKS } from '@gqlQuery/BankAccount';
import styles from './style';
import {
  getAvailableBalance,
  getUserBanksFromQuery,
  numberWithDots,
  removeNonNumeric,
  validateNominal,
} from '@utils/UtilFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { setUserBankData } from '@redux/action/UserBanks';
import MainParentWrapper from '@components/MainParentWrapper';
import { useLazyQuery, useQuery } from '@apollo/client';
import MyText from '@atoms/MyText';
import { normalize } from '@gaji-gesa/gg-ui-shared/src/sharedStyle/helper';
import SalaryAccount from '@assets/Svg/DynamicAccounts/salaryAccount.svg';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import Divider from '@atoms/Divider';
import BankTransferInfo from '@atoms/BankCards/BankTransferInfo';
import { emptyBankUi, getPlaceholder, getTitle } from './SalaryTransferUtils';
import AmountInput from '@atoms/BankCards/AmountInput';
import InfoTextComponent from '@atoms/InfoTextComponent';
import LoadingButton from '@atoms/LoadingButton';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { respError } from '@redux/action/Common';
import AmplitudeHelper from '@services/amplitude';
import { applyForTransfer, applyForWithdrawal } from '@services/DashboardService';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { store } from '@enhancedStore/store';

const SalaryTransfer = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [selectedBank, setSelectedBank] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [nominal, setNominal] = useState('');
  const [errorInput, setErrorInput] = useState({ visible: false, message: '' });
  const { userBanks } = useSelector((state) => state.UserBank);
  const { redeemCode } = useSelector((state) => state.Referral);
  const canContinue = useSelector((state) => state.Common.canContinue);

  const dispatch = useDispatch();
  const {
    workedDays,
    workingDays,
    todayBalanceFloor,
    salaryWithCappedAmount,
    maxWithdrawalAmount,
    usedBalance,
    lockPeriod,
    minBalance,
  } = useSelector((state) => state.Withdrawal);
  const { loading: loadingOnce, error: errorOnce } = useQuery(GET_USER_ALL_BANKS, {
    onCompleted: (data) => {
      if (data.gg_employee.length > 0) {
        const dispatchData = getUserBanksFromQuery(data);
        dispatch(setUserBankData(dispatchData.defaultBank, dispatchData.userBanks, dispatchData.commonUserReadStatus));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getUserBanks, { loading, data, error }] = useLazyQuery(GET_USER_ALL_BANKS, {
    onCompleted: (data) => {
      if (data.gg_employee.length > 0) {
        const dispatchData = getUserBanksFromQuery(data);
        dispatch(setUserBankData(dispatchData.defaultBank, dispatchData.userBanks, dispatchData.commonUserReadStatus));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const newAvailableBalance = useMemo(
    () =>
      getAvailableBalance(
        salaryWithCappedAmount,
        todayBalanceFloor,
        workingDays,
        workedDays,
        maxWithdrawalAmount,
        usedBalance,
      ),
    [salaryWithCappedAmount, todayBalanceFloor, workingDays, workedDays, maxWithdrawalAmount, usedBalance],
  );

  const isSubmitButtonEnabled = useMemo(() => {
    if (newAvailableBalance === 0) return false;
    const newNominal = parseInt(removeNonNumeric(nominal));

    if (newNominal >= minBalance && newNominal <= newAvailableBalance && !!selectedBank?.id) return true;
    return false;
  }, [
    salaryWithCappedAmount,
    todayBalanceFloor,
    workingDays,
    workedDays,
    maxWithdrawalAmount,
    usedBalance,
    nominal,
    minBalance,
    newAvailableBalance,
    selectedBank,
  ]);

  const verifyTransaction = async () => {
    try {
      const e = AMPLITUDE_CONSTANTS.transfer.withdrawnow_button_clicked;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.requested_amount]: removeNonNumeric(nominal),
        [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.transfer_page,
        [e.attributes.transaction_category_id]: 'wd-02',
        [e.attributes.referral_code]: redeemCode || null,
        [e.attributes.dynamic_account_name]: AMPLITUDE_EVENT_SOURCES.dynamicAccounts.salary_account,
      });

      setSubmitLoading(true);
      await dispatch(applyForTransfer(nominal, selectedBank.id));
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const onPressSubmit = () => {
    if (lockPeriod) {
      dispatch(respError('Maaf, saat ini Anda belum berada pada masa transaksi/penarikan!'));
      return;
    }

    verifyTransaction();
  };

  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.transfer.product_page;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.referral_code]: store.getState().Referral?.redeemCode || null,
      [e.attributes.source]: route.params?.source ?? '',
      [e.attributes.transaction_category_id]: 'wd-02',
    });
  }, []);

  useEffect(() => {
    if (route.params?.selectedBank) {
      setSelectedBank(route.params.selectedBank);
    }
    if (canContinue) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'HomeScene' }],
        }),
      );
    }
  }, [route.params, canContinue]);

  const setNominalFromInput = (value) => {
    validateNominal(minBalance, errorInput, setErrorInput, newAvailableBalance, setNominal, value);
  };

  const onPressBankAccount = () => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.transfer.select_bank_account_clicked.name);
    navigation.navigate('RegisteredBankSelection', { source: '', selectedBank });
  };

  const onEndEditing = () => {
    const e = AMPLITUDE_CONSTANTS.transfer.transfer_manual_input;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: route.params?.source ?? '',
      [e.attributes.requested_amount]: removeNonNumeric(nominal),
    });
  };

  if (userBanks.length === 0) {
    const navigateToNewBankCreation = () => {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'SalaryTransfer' },
            { name: 'BankCreateDetail', params: { source: AMPLITUDE_EVENT_SOURCES.transfer_page } },
          ],
        }),
      );
    };

    return (
      <MainParentWrapper
        containerStyle={styles.mainContainer}
        loading={loadingOnce || loading}
        isError={error || errorOnce}
        isRounded
        onReload={getUserBanks}
      >
        {emptyBankUi(navigateToNewBankCreation)}
      </MainParentWrapper>
    );
  }

  return (
    <MainParentWrapper
      containerStyle={styles.mainContainer}
      loading={loadingOnce || loading}
      isError={error || errorOnce}
      isRounded
      onReload={getUserBanks}
    >
      <MyText>Sumber Dana</MyText>
      <View style={styles.walletContainer}>
        <SalaryAccount height={normalize(18)} width={normalize(18)} />
        <View style={styles.amountContainer}>
          <MyText isRegular={false} customStyle={{ color: colors.blue }} h2>
            Akun Gaji
          </MyText>
          <MyText isRegular={false}>Rp{numberWithDots(newAvailableBalance)}</MyText>
        </View>
      </View>
      <Divider horizontal customStyle={styles.divider} />
      <MyText>Transfer Dana</MyText>
      <BankTransferInfo
        accountNumber={selectedBank?.accountNumber}
        bankName={selectedBank?.bankName}
        beneficiaryName={selectedBank?.beneficiaryName}
        placeholder={getPlaceholder(selectedBank)}
        title={getTitle(selectedBank)}
        onPress={onPressBankAccount}
      />
      <AmountInput
        title={'Nominal Pengiriman'}
        nominal={numberWithDots(nominal)}
        setNominal={setNominalFromInput}
        error={errorInput}
        onEndEditing={onEndEditing}
      />
      <InfoTextComponent
        containerStyle={styles.infoBackStyle}
        iconColor={colors.black}
        infoText="Sumber dana untuk transfer ini berasal dari Akun Gaji GajiGesa."
      />

      <LoadingButton
        disabled={!isSubmitButtonEnabled}
        container={styles.loadingButton}
        textStyle={styles.loadingButtonText}
        buttonColor={colors.orange}
        text="Lanjutkan"
        textColor={colors.white}
        textTransform="none"
        disabledStyle={styles.disabledButton}
        onPress={onPressSubmit}
        loading={submitLoading}
      />
    </MainParentWrapper>
  );
};

SalaryTransfer.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      source: PropTypes.string,
      selectedBank: PropTypes.shape({
        id: PropTypes.number.isRequired,
        accountNumber: PropTypes.string.isRequired,
        bankName: PropTypes.string.isRequired,
        beneficiaryName: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
};
SalaryTransfer.defaultProps = {};
export default SalaryTransfer;
