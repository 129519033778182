import React, { useEffect, useRef } from 'react';
import { Dimensions, FlatList, Pressable, View } from 'react-native';
import { colors } from '@style/colors';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import LinearGradient from 'react-native-web-linear-gradient';
import Right from '@expo/vector-icons/Entypo';
import { DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';
import MyText from '@atoms/MyText';
import { numberWithDots, removeNonNumeric } from '@utils/UtilFunctions';
import { styles } from './style';

const { width: screenWidth } = Dimensions.get('screen');
const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

function getNAText(index) {
  return index === 1 ? 'N/A' : 'Rp N/A';
}

function getTextForAmountValue(item) {
  return item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS
    ? numberWithDots(removeNonNumeric(item.amount))
    : item.amount;
}

function shouldShowCardText(item, index) {
  return item?.amount ? getTextForAmountValue(item) : getNAText(index);
}

const AccountCard = ({
  start,
  showTour,
  showTouronPrevious,
  nextScroll,
  prevScroll,
  data,
  setData,
  hideLoader,
  onPress,
  coinBalance,
  disableEWAModal,
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (showTour && hideLoader) {
        scrollRef.current && scrollRef.current.scrollToOffset({ offset: 0, animated: true });
        setTimeout(() => start(), 250);
      }
      showTouronPrevious && start('homepage2');
    }, 200);
  }, [showTour, showTouronPrevious, hideLoader]);

  useEffect(() => {
    nextScroll && scrollRef.current && scrollRef.current.scrollToOffset({ offset: 150, animated: true });
    prevScroll && scrollRef.current && scrollRef.current.scrollToOffset({ offset: 0, animated: true });
  }, [nextScroll, prevScroll]);

  const keyExtractor = (item) => item.constantAccountName;

  const disableItem = (item) =>
    !item.hasOnPress || (disableEWAModal && item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);

  function shouldRenderArrow(item) {
    if (!item.hasOnPress || (disableEWAModal && item.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT))
      return null;
    return <Right name="chevron-right" size={20} />;
  }

  const renderItem = ({ item, index }) => (
    <ShimmerPlaceholder
      visible={hideLoader}
      style={{
        marginHorizontal: 10,
        width: screenWidth / 2.45,
        height: 70,
        borderRadius: 15,
      }}
    >
      {/* <AppTourComponentWrapper
          text={{ ...APPTOURMETA['homepage'][index + 1], nextScroll: [2], prevScroll: [3] }}
          order={index + 1}
          type={APP_TOUR_TYPE.homepage}
          name={APP_TOUR_TYPE.homepage + index}
        > */}
      <View style={styles.itemContainer}>
        <Pressable
          disabled={disableItem(item)}
          onPress={() => onPress(item)}
          android_ripple={{ color: colors.lightGrey }}
          style={{ paddingVertical: 10, paddingHorizontal: 15 }}
        >
          <View style={styles.rowContainer}>
            {item.icon}
            <MyText numberOfLines={1} isRegular={false} h2 customStyle={styles.title}>
              {item.accountName}
            </MyText>
          </View>
          <View style={[styles.rowContainer, { marginTop: 8 }]}>
            <MyText h3 isRegular={false}>
              {shouldShowCardText(item, index)}
            </MyText>
            {shouldRenderArrow(item)}
          </View>
        </Pressable>
      </View>
      {/* </AppTourComponentWrapper> */}
    </ShimmerPlaceholder>
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        ref={scrollRef}
        keyExtractor={keyExtractor}
        showsHorizontalScrollIndicator={false}
        renderItem={renderItem}
        horizontal
      />
    </View>
  );
};

// export default withHoc(AccountCard, {
//   isArrowVisible: true,
//   svgMaskProps: { isFullWidth: false, widthStartAdj: normalize(10), widthEndAdj: normalize(15) },
//   verticalOffset: 35,
// });

export default AccountCard;
