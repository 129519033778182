import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { View, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { flatten, times } from 'lodash';
import { respError } from '@redux/action/Common';
import AmplitudeHelper from '@services/amplitude';
import { requestCategory } from '@services/BillPaymentApi';
import { CardIconButton } from '@components/Button';
import styles from './style';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import LinearGradient from 'react-native-web-linear-gradient';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/AmplitudeConstants';
import { findAccountByName, formatUnderScoreLowerCase, getUpdatedCategories } from '@utils/UtilFunctions';
import withHoc from '../../AppTour/CopilotHoc';
import AppTourComponentWrapper from '../../AppTour/AppTourComponentWrapper';
import { APPTOURMETA, APP_TOUR_TYPE } from '@utils/AppTourConstants';
import { COMMON_CONSTANTS, DYNAMIC_ACCOUNT_NAMES } from '@utils/Constants';
import { setCurrentBpSelectedAccount } from '@redux/action/DynamicAccounts';
import { LOAN_ICON_INFO, BANK_TRANSFER_ICON_INFO, ZAKAT_ICON_INFO } from '@utils/Constants';

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);
const renderCategory = ({ item, loading, onPress, seenLoan, seenZakat }) => {
  // return <ShimmerPlaceholder visible={loading} style={styles.placeholder} />;
  return loading ? (
    <ShimmerPlaceholder visible={!loading} style={styles.placeholder} />
  ) : (
    <CardIconButton
      prop={item}
      cardStyle={{ flex: 1 }}
      iconStyle={{ flex: 1 }}
      onPress={onPress}
      showNew={!seenLoan && item.id === 100 || !seenZakat && item.id === 102}
    />
  );
};

const BillPaymentCategories = ({
  start,
  showTour,
  navigation: { navigate },
  route,
  dataAccounts,
  isLoan,
  onPressLoanHandler,
  seenLoan,
  onPressTransfer,
  shouldShowTransfer,
  shouldShowZakat,
  onPressZakatHandler,
  seenZakat
}) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { employeeId } = useSelector((state: any) => state.Authentication);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState(times(4, (index) => ({ id: index })));
  const defaultCategory: any = {
    id: 'product',
    name: 'Lainnya',
    description: '',
    imageUrl: COMMON_CONSTANTS.othersImageUrl,
    active: true,
    icon: null,
  };

  useEffect(() => {
    refreshPage();
  }, [isFocused]);

  useEffect(() => {
    showTour && setTimeout(() => start(), 300);
  }, [showTour]);

  const refreshPage = async () => {
    try {
      setError(false);
      setLoading(true);
      await getCategory();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };
  const updatedCategories = useMemo(() => {
    return getUpdatedCategories(
      categories,
      LOAN_ICON_INFO,
      isLoan,
      BANK_TRANSFER_ICON_INFO,
      shouldShowTransfer,
      shouldShowZakat,
      ZAKAT_ICON_INFO,
    );
  }, [isLoan, shouldShowZakat, shouldShowTransfer, categories]);


  const getCategory = async () => {
    try {
      let resp: any = await requestCategory(`?isHomePage=true`);
      if (resp.status) {
        setLoading(false);
        const { homePageIds } = resp.data.data;
        delete resp.data.data.homePageIds;
        const data: any[] = flatten(Object.values(resp.data.data));
        const filterCategories: any[] = data.filter((item) => homePageIds.includes(item.id));
        if (filterCategories.length > 0) {
          const newCategories = [...filterCategories, defaultCategory];
          setCategories(
            newCategories
          );
        }
      } else {
        setLoading(false);
        dispatch(respError(resp.response.data.message));
      }
    } catch (e) {
      if (e.response?.status == 401) {
        setLoading(false);
        throw e;
      } else {
        setLoading(false);
        throw e;
      }
    }
  };

  const renderItem = ({ item }) => {
    const onPress = (data) => {
      // Transfer Tile
      if (data.id === 101) return onPressTransfer();

      // Loan Tile
      if (data.id === 100) return onPressLoanHandler();

      if (data.id === 102) return onPressZakatHandler();

      const findSalaryAccount = findAccountByName(dataAccounts, DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
      if (findSalaryAccount) dispatch(setCurrentBpSelectedAccount(findSalaryAccount));
      if (item.id === 'product') {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Stories.pembayaranPage.name, {
          [AMPLITUDE_CONSTANTS.Stories.userClickedProduct.attributes.source]: 'home_page',
        });

        return navigate('Pembayaran', {
          screen: 'Billpayment',
          params: {
            screen: 'BillPaymentTab',
          },
        });
      } else {
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Stories.userClickedProduct.name, {
          // [AMPLITUDE_CONSTANTS.Stories.userClickedProduct.attributes.employee_id]:employeeId,
          [AMPLITUDE_CONSTANTS.Stories.userClickedProduct.attributes.source]: 'home_page',
          [AMPLITUDE_CONSTANTS.Stories.userClickedProduct.attributes.transaction_category_id]:
            formatUnderScoreLowerCase(item?.prefix),
        });

        return navigate('Pembayaran', {
          screen: 'Product',
          initial: false,
          params: { item, source: AMPLITUDE_EVENT_SOURCES.home_page },
        });
      }
    };
    return renderCategory({ item, loading, onPress, seenLoan, seenZakat });
  };

  return (
    // <AppTourComponentWrapper
    //   text={APPTOURMETA[APP_TOUR_TYPE.homepage][5]}
    //   order={5}
    //   name={`${APP_TOUR_TYPE.homepage}5`}
    //   type={APP_TOUR_TYPE.homepage}
    // >
    <View style={styles.sectionContainer}>
      <FlatList
        data={updatedCategories}
        renderItem={renderItem}
        numColumns={4}
        keyExtractor={(item) => `${item.id}`}
        persistentScrollbar
      />
    </View>
    // </AppTourComponentWrapper>
  );
};

// export default withHoc(BillPaymentCategories, {
//   isArrowVisible: true,
//   svgMaskProps: { isFullWidth: false, widthStartAdj: 0, widthEndAdj: 10 },
//   verticalOffset: 35,
// });

export default BillPaymentCategories;
